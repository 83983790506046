import React, { Component } from 'react';
import CanvasJSReact from '../../../assets/@canvasjs/react-charts';
import dropdownArrow from '../../../assets/images/dropdownArrow.svg';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class CompareLineChart extends Component {
	state = {
		dividerIndex: 0,
		dividertopDate: this.props.listOfPrices[0].values[0].date,
		dividermandiIndex: 0,
	};
	componentDidUpdate(prevProps) {
        if (prevProps.key1 !== this.props.key1 || prevProps.key2 !== this.props.key2 || prevProps.key3 !== this.props.key3) {
          this.setState({ dividerIndex: 0, dividermandiIndex: 0});
        }
      }
	formatDate = (date) => {
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear().toString();
		
		return `${year}-${month}-${day}`;
	}
	handleDataPointClick = (e) => {
		const { dataPointIndex } = e;
		const { onDataPointClick} = this.props;
		const { listOfPrices, colorData } = this.props;
	
		const dataPoints = listOfPrices.map((priceData, index) => {
			const { mandiName, values } = priceData;
			const colorObject = colorData.find((colorObj) => colorObj.mandi === mandiName);
			const color = colorObject ? colorObject.col : 'black';
			const seriesDataPoints = values.map((value, valueIndex) => ({
				x: new Date(value.date),
				y: value.close,
				lineThickness: 1, 
				markerType:'circle', 
				markerSize: 8,
				markerColor: color,
			}));

			return {
				type: 'line',		
				name: mandiName,
				toolTipContent: '{name}: {y}',
				lineColor: color,   
				markerSize: 8,
				markerType: 'circle',
				connectNullData: true,
				dataPoints: seriesDataPoints,
			};
		});

		const mandiNameOfClickedPoint = e && e.dataSeries ? e.dataSeries.name : "";
		const newDividerIndex = dataPointIndex;
		const newDividermandiIndex = dataPoints.findIndex(obj => obj.name === mandiNameOfClickedPoint);
	
		this.setState({ dividerIndex: newDividerIndex, dividermandiIndex: newDividermandiIndex }, () => {
			const selectedDataPoint = dataPoints[newDividermandiIndex].dataPoints[newDividerIndex];
			const formattedDate = this.formatDate(new Date(selectedDataPoint.x));
			this.setState({ dividertopDate: formattedDate });
			onDataPointClick(formattedDate);
		});
	};
	render() {
		const { listOfPrices,colorData } = this.props;
		const { dividerIndex } = this.state;

		const dataPoints = listOfPrices.map((priceData, index) => {
			const { mandiName, values } = priceData;
			const colorObject = colorData.find((colorObj) => colorObj.mandi === mandiName);
			const color = colorObject ? colorObject.col : 'black';
			const seriesDataPoints = values.map((value, valueIndex) => ({
				x: new Date(value.date),
				y: value.close,
				lineThickness: 1, 
				markerType:'circle', 
				markerSize: 8,
				markerColor: color,
			}));

			return {
				type: 'line',		
				name: mandiName,
				toolTipContent: '{name}: {y}',
				lineColor: color,   
                markerSize: 8,
				markerType: 'circle',
                connectNullData: true,
				dataPoints: seriesDataPoints,
				click: this.handleDataPointClick,
			};
		}); 
		
		const minPrice = Math.min(...dataPoints.map((series) => Math.min(...series.dataPoints.map((point) => point.y))));
		const maxPrice = Math.max(...dataPoints.map((series) => Math.max(...series.dataPoints.map((point) => point.y))));
		const yOffset = (maxPrice - minPrice) * 0.3;

		const dividerDataPoints = [
			{ x: dataPoints[this.state.dividermandiIndex].dataPoints[dividerIndex].x, y: minPrice - yOffset },
			{ x: dataPoints[this.state.dividermandiIndex].dataPoints[dividerIndex].x, y: maxPrice + yOffset },
		];

		const options = {
			animationEnabled: true,
			exportEnabled: false,
			theme: 'light2',
			title: {
				text: ''
			},
			axisY: {
				// title: '',
				// includeZero: false,
				// prefix: ''
                lineThickness: 0,
                tickLength: 0,
                gridThickness: 0,
                labelFormatter: () => ""
			},
			axisX: {
				// title: '',
				// valueFormatString: 'MMM DD, YYYY'
                lineThickness: 0,
                tickLength: 0,
                labelFormatter: () => ""
			},
			legend: {
				cursor: 'pointer',
				verticalAlign: 'top',
				fontSize: 16,
				fontColor: 'dimGrey',
				itemclick: (e) => {
					if (typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible) {
						e.dataSeries.visible = false;
					} else {
						e.dataSeries.visible = true;
					}
					this.chart.render();
				}
			},
			toolTip: {
				enabled: false, // Hide tooltips on hover
			},
			data: [...dataPoints, {
				type: 'line',
				toolTipContent: `Date {x}: {y}/${this.props.units}`,
				dataPoints: dividerDataPoints,
				color: '#D3D3D3',
				markerSize: 0,
				lineThickness: 1,
				markerType: 'none'
			}],
		};

		// const noteStyle = {
        //     left: `${((listOfPrices[0].values.length-dividerIndex) / listOfPrices[0].values.length) * 100}%`,
        // };

		const getNoteLeft = () => {
            const windowWidth = window.innerWidth;
            const dividerIndex = this.state.dividerIndex;
            const dataPointsLength = listOfPrices[this.state.dividermandiIndex].values.length;
      
            if (windowWidth < 450) {
              if (dividerIndex === 0) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 82;
              } else if (dividerIndex === dataPointsLength - 1) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 500;
              }
              else{
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 100;
              }
            } else {
              if (dividerIndex === 0) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 92;
              } else if (dividerIndex === dataPointsLength - 1) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 300;
              }
              else{
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 100;
              }
            }
          };
      
          const noteStyle = {
            left: `${getNoteLeft()}%`,
          };

        function formatDate(date) {
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
			const formattedDate = date.toLocaleDateString('en-US', options);
			return formattedDate;
        }

		return (
			<div className='chart-container'>
				<CanvasJSChart options={options} />
				<div className="dividerTopOuterDiv" style={noteStyle} >
					<div className='dividertop-innerDiv'>
						{`Date: ${formatDate(new Date(dataPoints[this.state.dividermandiIndex].dataPoints[dividerIndex].x))}`}
					</div>
            	</div>
			</div>
		);
	}
}

export default CompareLineChart;
