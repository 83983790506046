import React, { Component } from 'react';
import CustomInputText from "../../../components/CustomInputText";
import CustomButton from "../../../components/CustomButton";
import { history, numberWithCommas ,fireAjaxRequest} from '../../../utils/Utility';
import {GET_EMANDI_CASH_ACCOUNT} from '../../../utils/Api'
import { Scrollbars } from 'react-custom-scrollbars';
import TransactionStatusDialog from "./transactionStatusDialog";
import BankDetailsDialog from "./bankdetailDialog";
import RequestTransferOTP from "./requestTransferOTP";
import LogoutDialog from "./logoutDialog";
import { toast } from 'react-toastify';

import { InputNumber } from 'primereact/inputnumber';
class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Emandicashdetails:null,
            userInfo: JSON.parse(sessionStorage.getItem("userInfo"))
        }
    }

    componentDidMount(){
        this.getEMandiCash();
        console.log(this.state.userInfo,"ljk")
    }
        getEMandiCash=()=>{
            (async () => {
                try {
                    const resp = await fireAjaxRequest(`${GET_EMANDI_CASH_ACCOUNT}/${this.state.userInfo.user.id}`, {
                        method: 'GET',
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        }
                    });
                    if (resp) {
                        this.setState({Emandicashdetails:resp})
                    }
                } catch (err) {
                    // toast.error("Failed to load Emandi Cash !");
                    
                }
            })();
        }
    onViewTransactions = (e) => {
        history.push({
            pathname: '/account/transactions/${e}',
            state: { formData: this.props.formData }
        })
    }


    render() {
        let { state, handelInputChange, formData, updateBankDeatils, displayDialog, onUpdateBank, handelBankDetailsChange, addBankDetails, addNewBank, onAddDeposite, onRequestBtnClick, displayBankDetilsDialog, onAccept, onAcceptOTP, onAcceptLogout, OTPCounter,onhide } = this.props;

        return (<div className="account-detils">
            
            <TransactionStatusDialog status={true} visible={state.displayDialog} onHide={() => displayDialog(false)} onCancel={() => displayDialog(false)} />
            
            <BankDetailsDialog handelInputChange={handelInputChange} visible={state.displayBankDetailsDailog} onHide={()=>{}} onCancel={() => displayBankDetilsDialog(false)} onAccept={onAccept} formData={formData}/>
            
            <RequestTransferOTP handelInputChange={handelInputChange} OTPCounter={OTPCounter} resendOTPTime={state.resendOTPTime} visible={state.displayOTPDialog} onHide={()=>displayBankDetilsDialog(false)} onCancel={() => displayBankDetilsDialog(false)} onAccept={onAcceptOTP} sendOTP={onAccept} formData={formData} OTPAttempltremaining={state.OTPAttempltremaining} showwithdrawsubmitbutton={state.showwithdrawsubmitbutton}/>

            <LogoutDialog handelInputChange={handelInputChange} visible={state.displayLogoutDialog} onHide={onAcceptLogout} onCancel={() => onAcceptLogout} onAccept={onAcceptLogout} onhide={onhide}/>

            <Scrollbars className="account-detils-scrollbar" autoHide={false} style={{ width: "100%", height: '80vh' }}>
                <div className="funds-bank">
                    <h5 className="title">Funds</h5>
                    <p class="account-type">Deposits Made Till Date</p>
                    <p class="account-type-details">₹{formData.depositsTillDate ? numberWithCommas(formData.depositsTillDate.toFixed(2))  : 0}</p>
                    <p class="account-type">Deposits Used In Bid</p>
                    <p class="account-type-details">₹{formData.usedInBid ? numberWithCommas(formData.usedInBid.toFixed(2))  : 0}</p>
                    <p class="account-type">Deposits Available</p>
                    <p class="account-type-details">₹{formData.balance ? numberWithCommas(formData.balance.toFixed(2))  : 0}</p>

                    <p class="account-type">Due Payment</p>
                    <p class="account-type-details">₹{formData.due ? numberWithCommas(formData.due.toFixed(2))  : 0}</p>
                    <p className="view-tran" onClick={this.onViewTransactions.bind(this)}>View Transactions</p>

                    <h5 className="title">Bank Account</h5>
                    {
                        formData.bank ?
                            <>
                                {
                                    this.props.isNewBank ? "" :
                                        <div className="showBankDetails">
                                            <div className="bankInfo-cls">
                                                <div className="bank-info-label"><p>Bank Name:</p></div>
                                                <div className="bank-info"><p className="bank-info"> {formData.bank !== "string" ? formData.bank : ""}</p></div>
                                                <div><i className="pi pi-user-edit" onClick={() => { updateBankDeatils(true) }}></i></div>
                                            </div>
                                            <div className="bankInfo-cls">
                                                <div className="bank-info-label"><p>Account Number:</p></div>
                                                <div className="bank-info"><p> {formData.acNumber !== "string" ? formData.acNumber : ""}</p></div>
                                            </div>
                                            <div className="bankInfo-cls">
                                                <div className="bank-info-label"><p>IFSC:</p></div>
                                                <div className="bank-info"><p> {formData.ifsccode !== "string" ? formData.ifsccode : ""}</p></div>
                                            </div>
                                        </div>
                                }
                            </>
                            :
                            <div className="addBankDetails">
                                <p >Add Bank Details <i className="pi pi-user-edit" onClick={() => { updateBankDeatils(true) }}></i></p>
                            </div>
                    }
                </div>
                <div className="edit-bank-details">
                    {/* {this.props.isNewBank ? <p className="note">Note* : Provide the Account Details Before you Initiate the Payment.</p>:""} */}
                    <div className="add-deposit card">
                        <div className="add-withdraw-card">
                            <div className="add-new-deposit">
                                <span className='min-text'>
                                    <CustomInputText className="min-box" name={"addDeposit"} value={formData.addDeposit?`${formData.addDeposit}`:""} label="Add New Deposit" config={{
                                        onChange: handelInputChange,
                                        currency:"USD",
                                        keyfilter: "num"
                                    }} />
                                    <div className='min_wrapper'><p className='min_amount'>Min. Amount Rs.50 to Add via NetBanking</p></div>
                                </span>
                                
                                
                                <CustomButton className="add-deposit-amount" label="ADD DEPOSIT" onClick={onAddDeposite} />
                                
                            </div>
                            
                            <div className="withdraw-deposit">
                                <CustomInputText name={"Withdraw"} value={formData.Withdraw} label="Withdraw" config={{
                                    onChange: handelInputChange,
                                    keyfilter: "num",
                                    // disabled:true
                                }} />
                                 {/* <div className="adddeposite-cls">
                                 <p htmlFor="currency-india">Withdraw</p>
                                <InputNumber name={"Withdraw"} value={formData.Withdraw} inputId="currency-india"  onChange={handelInputChange} mode="currency" currency="INR"/>
                                </div> */}
                                <CustomButton className="withdraw-amount" label="REQUEST" onClick={onRequestBtnClick} config={{
                                    // disabled:true
                                }}/>
                            </div>
                            </div>
                    </div>
                    <div className="add-deposit card">
                             <p className='label-cls'>Emandi Cash</p>
                            <p className='supply-limit-cls'>Supply Limit Status :  <span>{this.state.Emandicashdetails && this.state.Emandicashdetails.statusOfRequest?this.state.Emandicashdetails.statusOfRequest:"NA"}</span></p>
                            <p className='supply-limit-cls'>Approved Supply Limit :  <span>{!this.state.Emandicashdetails ?"0.00":this.state.Emandicashdetails && this.state.Emandicashdetails.statusOfRequest=="Under process"?"0.00":this.state.Emandicashdetails && this.state.Emandicashdetails.assignedEmandiCashLimit && numberWithCommas(this.state.Emandicashdetails.assignedEmandiCashLimit)}</span></p>
                            <p className='supply-limit-cls'>Available supply Limit :  <span>{!this.state.Emandicashdetails ?"0.00":this.state.Emandicashdetails && this.state.Emandicashdetails.statusOfRequest=="Under process"?"0.00":this.state.Emandicashdetails && this.state.Emandicashdetails.availableEmandiCashLimit && numberWithCommas(this.state.Emandicashdetails.availableEmandiCashLimit)}</span></p>
                    </div>
                    {state.showbankDetailsFlag ? <div className="edit-bank card">
                        <i className="pi pi-times-circle" onClick={() => { updateBankDeatils(false) }}></i>
                        <div className="add-withdraw-card">
                            <div className="add-new-deposit">
                                <div className="input-wrapper">
                                    <CustomInputText name={"bank"} value={addBankDetails.bank} label={"Bank Name"} config={{
                                        onChange: handelBankDetailsChange
                                    }} />
                                    <CustomInputText name={"acNumber"} value={addBankDetails.acNumber} label={"A/C Number"} config={{
                                        onChange: handelBankDetailsChange,
                                        keyfilter:"num",
                                        type:"password",
                                        disabled:addBankDetails.bank?false:true
                                    }} />
                                    <CustomInputText name={"reAcNumber"} value={addBankDetails.reAcNumber} label={"Re-enter A/C Number"} config={{
                                        onChange: handelBankDetailsChange,
                                        keyfilter:"num",
                                        disabled:addBankDetails.acNumber?false:true
                                    }} />
                                    <CustomInputText name={"ifsccode"} value={addBankDetails.ifsccode} label={"IFSC Code"} config={{
                                        onChange: handelBankDetailsChange,
                                        disabled:addBankDetails.reAcNumber?false:true
                                    }} />
                                </div>
                                {
                                    this.props.isNewBank ?
                                        <CustomButton className="withdraw-amount" label="ADD" config={{ onClick: addNewBank,
                                        // disabled:state.addBankBtnFlag 
                                    }} />
                                        :
                                        <CustomButton className="withdraw-amount" label={formData.bank?"UPDATE":"ADD"} config={{ onClick: onUpdateBank,
                                            // disabled:state.addBankBtnFlag 
                                        }} />
                                }
                            </div>
                        </div>
                    </div> : ""}
                </div>
            </Scrollbars>
        </div>);
    }
}

export default List;