import React, { useState, useEffect } from 'react'
import { INSURANCE_LIMIT_API, BUYER_UNDER_WRITING, FILTER_TABLE_RECORDS_API } from '../../utils/Api';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Loader from "../../components/CustomLoader";
import ReactHtmlParser from 'html-react-parser';
var list = [];
var yearArray=[]
var name, email, phoneNo, pan, cinLLP, gstn, address, projectedSales,atpSegment,scoreDecils, existingLimit, creditPeriod, invoiceCurrency, relationshipWithBuyer, contactPerson, creditLimit, legalStatusOfBuyer, submissionDate = '',financialYear='',actionDate='',actionBy='';

export default function NewBuyerData(props) {

    const [isLoading, setLoader] = useState(false);
    const [Item, setItem] = useState([]);
    const [bData, setBuyerData] = useState([]);
    const [iLimit, setILimit] = useState('');
    const [encUserId, setEncUserId] = useState([]);
    const [page, setPage] = useState(true);

//     var today = new Date();
//     let t = today.toJSON();
//     var dd = today.getDate()
//     var mm = (today.getMonth() + 1) //January is 0!
//     var yyyy = today.getFullYear();
//     let d =dd+"-"+mm+"-"+yyyy;
//     var date =d;
    
// console.log(t);




    // showAlert = () => {
    //     Swal.fire({
    //         title: "Success",
    //         text: "Alert successful",
    //         icon: "success",
    //         confirmButtonText: "OK",
    //       });
    // }

    const history = useHistory();
    const newbuyerPage = () => {
        // history.push("/BuyerUnderwriting/newuser");
        history.push("/newbuyer");

    }
    const existingBuyerPage = () => {
        history.push("/existingBuyer")
    }

    let buyerId = window.location.href;
    buyerId = buyerId.slice(buyerId.lastIndexOf('/') + 1);
    // console.log(buyerId, "userId")

    useEffect(() => {

        setLoader(true);
        fetch(BUYER_UNDER_WRITING, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                "Content-Type": "application/json",
                'Accept': 'application/json',
            },
        }).then(resp =>
            resp.json()).then(res => {
                // console.log(x);
                let x = JSON.parse(JSON.stringify(res.data));

                x.map((item) => {
                    if (item.id == buyerId) {
                        list = [];
                        list.push(item);
                        setItem(list);
                        // console.log(list);
                    }
                    // console.log(list);
                })
                setLoader(false);
            }).catch(err => { toast.error("Connection Intrupted - Check your Internet connection") })
    }, []);

    const handleInsuranceChange = (e) => {
        e.preventDefault();
        const re = /^[+-]?\d*(?:[.,]\d*)?$/;

        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            setILimit(e.target.value)
               console.log(iLimit);
        }
        // else if(e.target.value == '' || e.target.value == 0){
        //     toast.error("Insurance Limit cannot be 0");
        // }
    }

    const handleiLimit = (e) => {
        e.preventDefault();
        if (iLimit == '' || iLimit == 0) {
            toast.error("Insurance Limit cannot be Null or 0")
        }
        else {

            setLoader(true);
            Item.map((item) => {
                name = item.buyerName;
                email = item.emailId;
                phoneNo = item.contactNumber;
                pan = item.pan;
                cinLLP = item.cinLLP;
                gstn = item.gstNo;
                address = item.buyersAddress;
                projectedSales = item.projectedSales;
                existingLimit = item.existingLimit;
                creditPeriod = item.creditPeriod;
                invoiceCurrency = item.invoiceCurrency;
                relationshipWithBuyer = item.relationshipWithBuyer;
                contactPerson = item.contactPerson;
                creditLimit = item.creditLimit;
                legalStatusOfBuyer = item.legalStatusOfBuyer;
                submissionDate = item.submissionDate;
                atpSegment = item.atpSegment;
                scoreDecils =item.scoreDecils;
                financialYear =item.financial_year;
                actionBy =item.userName;
                actionDate =item.dateTime;
            })

            const data = {

                "dataform": "mi8ga50exi",
                "filters": [
                    {
                        "field": "pan",
                        "op": "values",
                        "values": [
                            pan
                        ]
                    }
                ],
                "getfieldvalues": false,
                "use_field_slug": true

            }


            fetch(FILTER_TABLE_RECORDS_API, {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
                    "Content-Type": "application/json"
                }
            }).then(res => res.json()).then(resp => {
                // console.log(resp.objects[0]);

                var id = resp.objects[0];
                buyer_02(id.id);
            }).catch(e=>{
                toast.info("Buyer does not exist in Orgzit");
                setLoader(false);
            })


        }
    }

    function buyer_02(id) {

        const data ={"dataform": "hi5i5sqmc8",
        "dbvalues": {
            "buyer": id
        },
        "filters": [
            {
              "field": "buyer",
              "op": "values",
              "values": [
                id
              ]
            }
          ],
          "getfieldvalues": false,
          "use_field_slug": true
          }

          fetch(FILTER_TABLE_RECORDS_API, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
                "Content-Type": "application/json"
            }
        }).then(res => res.json()).then(resp =>{
            // console.log(resp.objects[0]);

            var x = resp.objects[0];
            var db = x.dbvalues
            insuranceUpdate(x.id, db, id);
        })
    }

    function insuranceUpdate(id, db, bId){
        console.log(db.origo_entity);
        const data ={
            "dataform": "/api/1/dataform/hi5i5sqmc8/",
            "dataform_id": "hi5i5sqmc8",
            "dbvalues": {
                "buyer": bId,
                "origo_entity": db.origo_entity
            },
            "fields": {
                "contract_signed": "No",
                "credit_limit_tolerance": 0,
                "approval_credit_limit": Math.round(creditLimit),
                "insurance_limit": Math.round(iLimit)
            },
            "use_field_slug": true
        }

        fetch(`${INSURANCE_LIMIT_API}${id}/`,{
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
                "Content-Type": "application/json"
            }
        }).then(res=>res.json()).then(resp=>{
            // console.log(resp);
            toast.success("Insurance Limit Updated Successfully");
            setLoader(false);
            statusUpdate();
        })
    }

    function statusUpdate() {


        const data = {
            "id": buyerId,
            "buyerName": name,
            "emailId": email,
            "contactNumber": phoneNo,
            "creditLimit": creditLimit,
            "buyersAddress": address,
            "legalStatusOfBuyer": legalStatusOfBuyer,
            "cinLLP": cinLLP,
            "pan": pan,
            "gstNo": gstn,
            "projectedSales": projectedSales,
            "existingLimit": existingLimit,
            "creditPeriod": creditPeriod,
            "invoiceCurrency": invoiceCurrency,
            "relationshipWithBuyer": relationshipWithBuyer,
            "contactPerson": contactPerson,
            "atpSegment":atpSegment,
            "scoreDecils":scoreDecils,
            "status": "SENT TO ORGZIT",
            "submissionDate": submissionDate
        }

        fetch(BUYER_UNDER_WRITING, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                "Content-Type": "application/json",
                'Accept': 'application/json',
            },
        }).then(resp =>
            resp.json()).then(res => {
                // console.log(res.data);
            })
    }



    return (
        <>

            <Loader loading={isLoading}>


                <div className="overflow-auto  bg-light" style={{ maxHeight: "100%" }} >
                    <div className="d-flex mt-4 mx-3 ">
                        <div className="p-2 flex-grow-1">
                            &nbsp;&nbsp;&nbsp;&nbsp;<span class="glyphicon glyphicon-arrow-left" style={{ color: "#5cb85c" }}></span>&nbsp;
                            {/* <button className='border-none' onClick={redirectPage}><strong>Back</strong></button> */}
                            <Link to="/BuyerUnderwriting" className="link-dark"><strong>Back</strong></Link>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-success btn-md" type="button" onClick={newbuyerPage}><strong>New Buyer</strong></button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-success btn-md " type="button" onClick={existingBuyerPage}><strong>Existing Buyer</strong></button>
                        </div>
                        <hr />
                    </div>
                    <div className='border-bottom my-4 mx-2'>    </div>
                    <div className='mx-5 '>
                        <h3><strong>New Buyer Data</strong></h3>

                        <br />
                    </div>

                    <div className="card-body mx-5">
                        {Item.map((item, key) => {
                            return ((
                                <>
{/* {console.log(item.submissionDate)} */}
                                    <div key={key} >

                                        <div className="flex-parent-element p-0">
                                            <div className="flex-child-element">
                                                <span>Buyer Name :</span>
                                                <p><strong>{item.buyerName}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>CIN/LLP Number :</span>
                                                <p><strong>{item.cinLLP}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Buyer PAN Number :</span>
                                                <p><strong>{item.pan}</strong></p>
                                            </div>


                                        </div>
                                        <hr className='mt-0' style={{ width: 950 }} />

                                        <div className="flex-parent-element ">
                                            <div className="flex-child-element">
                                                <span>Buyer Address :</span>
                                                <p><strong>{item.buyersAddress}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Legal Status of Buyer :</span>
                                                <p><strong>{item.legalStatusOfBuyer}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Email Id :</span>
                                                <p><strong>{item.emailId}</strong></p>
                                            </div>
                                        </div>
                                        <hr className='mt-0' style={{ width: 950 }} />
                                        <div className="flex-parent-element ">
                                            <div className="flex-child-element">
                                                <span>GST No :</span>
                                                <p><strong>{item.gstNo}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Existing Limit :</span>
                                                <p><strong>{item.existingLimit}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Projected sales (during next 12 month) :</span>
                                                <p><strong>{item.projectedSales}</strong></p>
                                            </div>
                                        </div>
                                        <hr className='mt-0' style={{ width: 950 }} />
                                        <div className="flex-parent-element">
                                            <div className="flex-child-element">
                                                <span>Credit Limit/Buyer Limit Request :</span>
                                                <p><strong>{item.creditLimit}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Credit Period/Payment Terms (Days) :</span>
                                                <p><strong>{item.creditPeriod}</strong></p>
                                            </div>
                                            <div className="flex-child-element">
                                                <span>Contact No :</span>
                                                <p><strong>{item.contactNumber}</strong></p>
                                            </div>
                                        </div>
                                        <hr className='mt-0' style={{ width: 950 }} />
                                        <div className="flex-parent-element">
                                            <div className="flex-child-element green">
                                                <span>Invoice Currency :</span>
                                                <p><strong>{item.invoiceCurrency}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span>Relationship with Buyer :</span>
                                                <p><strong>{item.relationshipWithBuyer}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span></span>
                                                <p><strong></strong></p>
                                            </div>
                                        </div>
                                        <hr className='mt-0' style={{ width: 950 }} />
                                        <div className="flex-parent-element">
                                            <div className="flex-child-element green">
                                                <span>ATP Segment :</span>
                                                <p><strong>{item.atpSegment}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span>Score Deciles :</span>
                                                <p><strong>{item.scoreDecils}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span></span>
                                                <p><strong></strong></p>
                                            </div>
                                        </div>

                                        <hr className='mt-0' style={{ width: 950 }} />
                                        <div className="flex-parent-element">
                                            <div className="flex-child-element green">
                                                <span>Financial Year :</span>
                                                
                                                <p><strong>{ReactHtmlParser(item.financialYear!=null?item.financialYear.replaceAll(",",'<br/>'):"NA")}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span>Action By:</span>
                                                <p><strong>{item.userName!=null?item.userName:"NA"}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span>Action Date:</span>
                                                <p><strong>{item.dateTime!=null?item.dateTime:"NA"}</strong></p>
                                            </div>
                                            <div className="flex-child-element green">
                                                <span></span>
                                                <p><strong></strong></p>
                                            </div>
                                        </div>

                                    </div>
                                </>))
                        })}
                    </div>
                    <hr className='mt-1' style={{ width: 720 }} />
                    <div className='mx-5 '>
                        <h3><strong>Insurance Limit</strong></h3>
                        <form className="row g-3 mt-3">
                            <div style={{ marginBottom: "5rem" }}>
                                <div className="col-md-3">
                                    {/* <label for="buyername" className="form-label">Buyer Name</label> */}
                                    {/* <input pattern="[0-9]*" className="form-control" name='insuranceLimit' value={iLimit} id="buyername" onChange={handleInsuranceChange} /> */}
                                    <input type="text" class="form-control" name='iLimit' value={iLimit} onChange={handleInsuranceChange} />
                                    {/* <input type="text" pattern="/^\d*[.]?\d*$/" class="form-control" value={iLimit} onChange={(e) => setILimit((v) => (e.target.validity.valid ? e.target.value : v))}/> */}
                                    {/* <div className=''></div> */}
                                </div>
                                <div className="col-md-3">
                                    {/* <label for="cpin" className="form-label">CPIN/LLPIN</label> */}
                                    {/* <input type="text" className="form-control" placeholder='Populated on selection of Buyer Name' id="cpin" /> */}
                                    <button type="button" className="btn btn-success mt-1 px-5" data-bs-toggle="modal" data-bs-target="#sendBuyerDetails" onClick={handleiLimit} ><strong>Send Details to Orgzit</strong></button>
                                </div>

                            </div>
                        </form>
                    </div>

                    {/* <!-- The Modal --> */}

                    {/* {
            page!= false?
                <div className="modal" tabindex="-1" id='sendBuyerDetails'>
                    <div className="modal-dialog modal-dialog-centered modal-sm" style={{ height: '100px', maxWidth: '500px' }}>
                        <div className="modal-content">
                            <div class="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                            <div className="modal-body">
                                <h5>Buyer Details Sent to Orgzit</h5>
                            </div>


                            {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> 
                        </div>
                    </div>
                </div>
                :""
            } */}
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
                        integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
                    <title>Origo e-Mandi</title>
                    <script src=
                        "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.0/sweetalert.min.js">
                    </script>
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
                    />
                </div>
            </Loader>
        </>
    )
}



{/* <div className="col-auto mx-4 mt-5 ">
<button type="button" data-bs-toggle="modal" data-bs-target="#myModal" className="btn btn-success px-5">Submit</button>
</div> */}