import React, { Component } from "react";

import Ticker from "../../LandingPage/view/ticker.js";
import auctionList from "./auctionList.json";
import CustomTable from "../../../components/CustomTable";
import CustomCalender from "../../../components/CustomCalendar";
import CustomButton from "../../../components/CustomButton/index.js";
import CustomDropdown from "../../../components/CustomDropdown/index.js";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import { Scrollbars } from "react-custom-scrollbars";
import { numberWithCommas } from "../../../utils/Utility";
import youtubelogo from "../../../assets/images/youtube.svg";
import DailyCommodityReport from "../../../assets/images/DailyCommodityReport.svg";
import eMandiNews from "../../../assets/images/eMandiNews.svg";
import MarketPrice from "../../../assets/images/MarketPrice.svg";
import Calendar from "../../../assets/images/calendar.svg";
import SeasonalCommodityReport from "../../../assets/images/SeasonalCommodityReport.svg";
import defaultcommodity from "../../../assets/images/defaultcommodity.png";
import { TabMenu } from "primereact/tabmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

import { Accordion, AccordionTab } from "primereact/accordion";
export class list extends Component {
    dailyCommodityReports = () => {
        let {
            handelInputChange,
            formData,
            onClickBack,
            commodityList,
            completeCommodityMethod,
            onClickTabView,
            onCommoditySelect,
            onCommodityUnselect,
            state,
            onDownloadClick,
            onViewClick,
            ondownload,
            hanglePageClick,
            handleNextPageBtn,
            handlePrevPageBtn,
        } = this.props;

        return (
            <div>
                <div className="daily-heading-pagination">
                    <h2>Daily Commodity Reports</h2>
                    <h3>{state.count} Reports</h3>
                </div>
                <div className="filters">
                    <CustomDropdown
                        name="commodityType"
                        value={formData.commodityType}
                        options={state.commodityList}
                        config={{ placeholder: "All Commodity ", showClear: true }}
                        onChange={handelInputChange}
                        className="weeklyCommodityDropDown"
                        multiselect
                    />
                    <CustomDropdown
                        name="time"
                        value={formData.time}
                        options={state.options}
                        onChange={handelInputChange}
                        config={{ placeholder: "All Time", showClear: true }}
                    />
                </div>
               
                <div className="product-list">
                    {state.commodityCardData &&
                        state.commodityCardData.map((product) => {
                            return (
                                <div className="product-item">
                                    <div className="top-cls">
                                       
                                        <div
                                            className={
                                                !product.imagePath ? "imagenotfound-cls" : "image-cls"
                                            }
                                        >
                                            <img
                                                src={
                                                    product.imagePath
                                                        ? product.imagePath
                                                        : defaultcommodity
                                                }
                                            />
                                        </div>
                                        <div className="commodity-info">
                                            <p className="commodity-name">{product.commodityName}</p>
                                            <p>
                                                <span className="commodity-date">
                                                    Uploaded on:
                                                    {product.lastUpdated ? (
                                                        <span>
                                                            {" "}
                                                            {product.created.slice(8, 10)}{" "}
                                                            {product.created.slice(5, 7) == "01"
                                                                ? "Jan"
                                                                : product.created.slice(5, 7) == "02"
                                                                    ? "Feb"
                                                                    : product.created.slice(5, 7) == "03"
                                                                        ? "Mar"
                                                                        : product.created.slice(5, 7) == "04"
                                                                            ? "Apr"
                                                                            : product.created.slice(5, 7) == "05"
                                                                                ? "May"
                                                                                : product.created.slice(5, 7) == "06"
                                                                                    ? "Jun"
                                                                                    : product.created.slice(5, 7) == "07"
                                                                                        ? "Jul"
                                                                                        : product.created.slice(5, 7) == "08"
                                                                                            ? "Aug"
                                                                                            : product.created.slice(5, 7) == "09"
                                                                                                ? "Sep"
                                                                                                : product.created.slice(5, 7) == "10"
                                                                                                    ? "Oct"
                                                                                                    : product.created.slice(5, 7) == "11"
                                                                                                        ? "Nov"
                                                                                                        : "Dec"}{" "}
                                                            {product.created.slice(2, 4)}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="commodity-qty">
                                            <p
                                                className="commodity-view"
                                                onClick={() => ondownload(product.fileLocation)}
                                            >
                                                View
                                            </p>
                                            {/* <p className="commodity-name" ><i class="pi pi-download" onClick={()=>ondownload(product.fileLocation)}></i></p> */}
                                            {/* <a href={product.fileLocation} onClick={()=>ondown(product)}>MyPDF</a> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>

        );
    };
    seasonalCommodityReports = () => {
        let {
            handelInputChange,
            formData,
            onClickBack,
            commodityList,
            completeCommodityMethod,
            onClickTabView,
            onCommoditySelect,
            onCommodityUnselect,
            state,
            onDownloadClick,
            onViewClick,
            ondownload,
            hanglePageClick,
            handleNextPageBtn,
            handlePrevPageBtn,
        } = this.props;

        return (
            <div>
                <div className="seasonal-heading-pagination">
                    <h2>Seasonal Commodity Reports</h2>
                    <h3>{state.count} Reports</h3>
                </div>
                <div className="filters">
                    <CustomDropdown
                        name="commodityType"
                        value={formData.commodityType}
                        options={state.commodityList}
                        config={{ placeholder: "All Commodity", showClear: true }}
                        onChange={handelInputChange}
                    />
                    <CustomDropdown
                        name="time"
                        value={formData.time}
                        options={state.options}
                        onChange={handelInputChange}
                        config={{ placeholder: "All Time", showClear: true }}
                    />
                    <CustomDropdown
                        name="harvestType"
                        className="type-cls"
                        value={formData.harvestType}
                        options={[
                            { label: "Pre - Harvest", value: "PRE_HARVEST" },
                            { label: "Post - harvest", value: "POST_HARVEST" },
                        ]}
                        onChange={handelInputChange}
                    />
                </div>
                <div className="product-list">
                    {state.commodityCardData &&
                        state.commodityCardData.map((product) => {
                            return (
                                <div className="product-item">
                                    <div className="top-cls">
                                        <div
                                            className={
                                                !product.imagePath ? "imagenotfound-cls" : "image-cls"
                                            }
                                        >
                                            <img
                                                src={
                                                    product.imagePath
                                                        ? product.imagePath
                                                        : defaultcommodity
                                                }
                                            />
                                        </div>
                                        <div className="commodity-info">
                                            <p className="commodity-name">{product.commodityName}</p>
                                            <span className="commodity-date">
                                                Uploaded on:
                                                {product.lastUpdated ? (
                                                    <span>
                                                        {" "}
                                                        {product.created.slice(8, 10)}{" "}
                                                        {product.created.slice(5, 7) == "01"
                                                            ? "Jan"
                                                            : product.created.slice(5, 7) == "02"
                                                                ? "Feb"
                                                                : product.created.slice(5, 7) == "03"
                                                                    ? "Mar"
                                                                    : product.created.slice(5, 7) == "04"
                                                                        ? "Apr"
                                                                        : product.created.slice(5, 7) == "05"
                                                                            ? "May"
                                                                            : product.created.slice(5, 7) == "06"
                                                                                ? "Jun"
                                                                                : product.created.slice(5, 7) == "07"
                                                                                    ? "Jul"
                                                                                    : product.created.slice(5, 7) == "08"
                                                                                        ? "Aug"
                                                                                        : product.created.slice(5, 7) == "09"
                                                                                            ? "Sep"
                                                                                            : product.created.slice(5, 7) == "10"
                                                                                                ? "Oct"
                                                                                                : product.created.slice(5, 7) == "11"
                                                                                                    ? "Nov"
                                                                                                    : "Dec"}{" "}
                                                        {product.created.slice(2, 4)}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                        </div>
                                        <div className="commodity-qty">
                                            <p
                                                className="commodity-view"
                                                onClick={() => ondownload(product.fileLocation)}
                                            >
                                                View
                                            </p>
                                            {/* <p className="commodity-name" ><i class="pi pi-download" onClick={()=>window.open(product.fileLocation)}></i></p> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };
    marketPrices = () => {
        let {
            handelInputChange,
            formData,
            onClickBack,
            commodityList,
            completeCommodityMethod,
            onClickTabView,
            onCommoditySelect,
            onCommodityUnselect,
            state,
            onDownloadClick,
            onViewClick,
            hanglePageClick,
            handleNextPageBtn,
            handlePrevPageBtn,
        } = this.props;

        return (
            <div>
                <div className="marketprice-heading-pagination">
                    <h3>Market Prices</h3>
                    <h3>{state.count} Reports</h3>
                </div>
                <div className="filters">
                    <CustomDropdown
                        name="commodityType"
                        value={formData.commodityType}
                        options={state.commodityList}
                        config={{ placeholder: "All Commodity", showClear: true }}
                        onChange={handelInputChange}
                    />
                    <CustomDropdown
                        name="time"
                        value={formData.time}
                        options={state.options}
                        onChange={handelInputChange}
                        config={{ placeholder: "All Time", showClear: true }}
                    />
                    <CustomDropdown
                        name="location"
                        value={formData.location}
                        options={state.locationList}
                        onChange={handelInputChange}
                        config={{ placeholder: "All Locations", showClear: true }}
                    />
                </div>
                <div className="product-list">
                    {/* Daily Commodity Reports */}
                    {state.commodityCardData &&
                        state.commodityCardData.map((product) => {
                            return (
                                <div className="product-item">
                                    <div className="top-cls">
                                        <div className="image_text">
                                            <div className={!product.imagePath ? "imagenotfound-cls" : "image-cls"}>
                                                <img
                                                    src={
                                                        product.imagePath
                                                            ? product.imagePath
                                                            : defaultcommodity
                                                    }
                                                />
                                            </div>
                                            <div className="commodity-info">
                                                <p className="commodity-name">{product.commodityName}</p>
                                                <span className="commodity-date">{product.location}</span>
                                            </div>
                                        </div>
                                        <div className="commodity-qty">
                                            <p className="commodity-label">{product.spotPrice}</p>
                                            <p className="updown-prices">
                                                {product.upDown > 0 ? (
                                                    <i className="pi pi-caret-up"></i>
                                                ) : product.upDown < 0 ? (
                                                    <i className="pi pi-caret-down"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span
                                                    className={`${product.upDown === 0
                                                        ? ""
                                                        : product.upDown > 0
                                                            ? "green"
                                                            : "red"
                                                        }`}
                                                >{`${product.upDown > 0 ? "+" : ""}${product.upDown
                                                    } `}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    weeklyCommodityReports = () => {
        let {
            handelInputChange,
            formData,
            onClickBack,
            commodityList,
            completeCommodityMethod,
            onClickTabView,
            onCommoditySelect,
            onCommodityUnselect,
            state,
            onDownloadClick,
            onViewClick,
            ondownload,
            hanglePageClick,
            handleNextPageBtn,
            handlePrevPageBtn,
        } = this.props;

        return (
            <div>
                <div className="weekly-heading-pagination">
                    <h2>Weekly Commodity Reports</h2>
                    <h3>{state.count} Reports</h3>
                </div>
                <div className="filters">
                    <CustomDropdown
                        name="commodityType"
                        value={formData.commodityType}
                        options={state.commodityList}
                        config={{ placeholder: "All Commodity ", showClear: true }}
                        onChange={handelInputChange}
                        className="weeklyCommodityDropDown"
                        multiselect
                    />
                    {/* <CustomDropdown name="commodityType" value={formData.commodityType} options={state.commodityList} config={{placeholder:"All Commodity ",showClear:true}} onChange={handelInputChange}  /> */}
                    <CustomDropdown
                        name="time"
                        value={formData.time}
                        options={state.options}
                        onChange={handelInputChange}
                        config={{ placeholder: "All Time", showClear: true }}
                    />
                    <CustomDropdown
                        name="language"
                        value={formData.language}
                        options={state.languages}
                        onChange={handelInputChange}
                        config={{ placeholder: "All Language", showClear: true }}
                    />
                </div>
                <div className="product-list">
                    {state.commodityCardData &&
                        state.commodityCardData.map((product) => {
                            return (
                                <div className="product-item">
                                    <div class="ribbon-wrapper">
                                        <div class="ribbon">
                                            {product.reportTypeLanguage !== null
                                                ? product.reportTypeLanguage
                                                : "ENGLISH"}
                                        </div>
                                    </div>
                                    <div className="top-cls">
                                        <div
                                            className={
                                                !product.commodityImagePath
                                                    ? "imagenotfound-cls"
                                                    : "image-cls"
                                            }
                                        >
                                            <img
                                                src={
                                                    product.commodityImagePath
                                                        ? product.commodityImagePath
                                                        : defaultcommodity
                                                }
                                            />
                                        </div>
                                        <div className="commodity-info">
                                            <p className="commodity-name">{product.commodityName}</p>
                                            <p>
                                                <span className="commodity-date">
                                                    Uploaded on:
                                                    {product.lastUpdated ? (
                                                        <span>
                                                            {" "}
                                                            {product.created.slice(8, 10)}{" "}
                                                            {product.created.slice(5, 7) == "01"
                                                                ? "Jan"
                                                                : product.created.slice(5, 7) == "02"
                                                                    ? "Feb"
                                                                    : product.created.slice(5, 7) == "03"
                                                                        ? "Mar"
                                                                        : product.created.slice(5, 7) == "04"
                                                                            ? "Apr"
                                                                            : product.created.slice(5, 7) == "05"
                                                                                ? "May"
                                                                                : product.created.slice(5, 7) == "06"
                                                                                    ? "Jun"
                                                                                    : product.created.slice(5, 7) == "07"
                                                                                        ? "Jul"
                                                                                        : product.created.slice(5, 7) == "08"
                                                                                            ? "Aug"
                                                                                            : product.created.slice(5, 7) == "09"
                                                                                                ? "Sep"
                                                                                                : product.created.slice(5, 7) == "10"
                                                                                                    ? "Oct"
                                                                                                    : product.created.slice(5, 7) == "11"
                                                                                                        ? "Nov"
                                                                                                        : "Dec"}{" "}
                                                            {product.created.slice(2, 4)}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="commodity-qty">
                                            <p
                                                className="commodity-view"
                                                onClick={() => ondownload(product.fileLocation)}
                                            >
                                                View
                                            </p>
                                            {/* <p className="commodity-name" ><i class="pi pi-download" onClick={()=>ondownload(product.fileLocation)}></i></p> */}
                                            {/* <a href={product.fileLocation} onClick={()=>ondown(product)}>MyPDF</a> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
    render() {
        let renderCurrentZone = {
            dailyCommodityReports: this.dailyCommodityReports(),
            seasonalCommodityReports: this.seasonalCommodityReports(),
            marketPrices: this.marketPrices(),
            weeklyCommodityReports: this.weeklyCommodityReports(),
            // eMandiTV: this.eMandiTV(),
            //eMandiNews: this.eMandiNews(),
        };
        let column = [
            {
                header: "Commodity",
                field: "commodityName",
                renderer: (rowData) => {
                    return (
                        <div className="commodity-name">
                            <p>{rowData.commodityName}</p>
                        </div>
                    );
                },
            },

            // {
            //     "header": "Source",
            //     "field": "source",

            // },
            {
                header: "Location",
                field: "location",
            },
            {
                header: "Spot Price(Rs.)",
                field: "spotPrice",
                renderer: (rowData) => {
                    return `${numberWithCommas(
                        Math.round(rowData.spotPrice).toFixed(2)
                    )}`;
                },
            },
            {
                header: "Up/Down(Rs.)",
                field: "upDown",
                renderer: (rowData) => {
                    return (
                        <div className="updown-prices">
                            {rowData.upDown > 0 ? (
                                <i className="pi pi-caret-up"></i>
                            ) : rowData.upDown < 0 ? (
                                <i className="pi pi-caret-down"></i>
                            ) : (
                                ""
                            )}
                            <p
                                className={`${rowData.upDown === 0
                                    ? ""
                                    : rowData.upDown > 0
                                        ? "green"
                                        : "red"
                                    }`}
                            >{`${rowData.upDown > 0 ? "+" : ""}${rowData.upDown} `}</p>
                        </div>
                    );
                },
            },
        ];
        let option = [
            {
                label: "Daily Commodity Report",
                value: "DAILY_COMMODITY_REPORTS",
            },
            {
                label: "Seasonal Commodity Report",
                value: "SEASONAL_COMMODITY_REPORTS",
            },
            {
                label: "Market Prices",
                value: "MARKET_PRICES",
            },
            {
                label: "weekly Commodity Reports",
                value: "WEEKLY_REPORT",
            },
        ];

        let {
            handelInputChange,
            formData,
            onClickBack,
            setCurrentState,
            commodityList,
            completeCommodityMethod,
            onClickTabView,
            onCommoditySelect,
            onCommodityUnselect,
            state,
            onDownloadClick,
            onViewClick,
            ondownload,
            hanglePageClick,
            handleNextPageBtn,
            handlePrevPageBtn,
        } = this.props;
        const localpages = [];
        for (let i = 2; i < Math.ceil(state.count / state.limit); i++) {
            localpages.push(i);
        }
        const renderPageNumbers = localpages.map((number) => {
            if (
                number < state.maxPageNumberLimit + 1 &&
                number > state.minPageNumberLimit
            ) {
                return (
                    <li
                        key={number}
                        id={number}
                        onClick={hanglePageClick}
                        className={state.current_page === number ? "active-page" : null}
                    >
                        {number}
                    </li>
                );
            } else {
                return null;
            }
        });

        let pageIncrementBtn = null;
        if (localpages.length >=state.maxPageNumberLimit) {
            pageIncrementBtn = (
                <li
                    onClick={() => {
                        handleNextPageBtn(localpages.length + 2);
                    }}
                >
                    {" "}
                    &hellip;{" "}
                </li>
            );
        }

        let pageDeccrementBtn = null;
        if (state.minPageNumberLimit > 1) {
            pageDeccrementBtn = <li onClick={handlePrevPageBtn}> &hellip; </li>;
        }
        return (
            <div className="commoditity-research-cls">
                <Ticker />
                <div className="header-cls">
                    <i className="pi pi-angle-left" onClick={onClickBack}></i>
                    {/* <img src={Logo} /> */}
                    <p>Commodity Research</p>
                </div>
                <div className="menu-cls">
                    <div className="list-cls">
                        <ul style={{ listStyleType: "none" }}>
                            <li
                                className={
                                    state.currentStage == "dailyCommodityReports"
                                        ? "active-cls"
                                        : "norma-cls"
                                }
                                onClick={() => setCurrentState("dailyCommodityReports")}
                            >
                                <img src={DailyCommodityReport} />
                                <span>Daily Commodity Reports</span>
                            </li>
                            <hr></hr>
                            <li
                                className={
                                    state.currentStage == "seasonalCommodityReports"
                                        ? "active-cls"
                                        : "norma-cls"
                                }
                                onClick={() => setCurrentState("seasonalCommodityReports")}
                            >
                                <img src={SeasonalCommodityReport} />
                                <span>Seasonal Commodity Reports</span>
                            </li>
                            <hr></hr>
                            <li
                                className={
                                    state.currentStage == "marketPrices"
                                        ? "active-cls"
                                        : "norma-cls"
                                }
                                onClick={() => setCurrentState("marketPrices")}
                            >
                                {" "}
                                <img src={MarketPrice} />
                                <span>Market Prices</span>
                            </li>
                            <hr></hr>

                            {/* <li onClick={() => setCurrentState("eMandiTV")}>eMandi TV </li> */}
                            {/* <li className={state.currentStage=="eMandiNews"?"active-cls":"norma-cls"} onClick={() => setCurrentState("eMandiNews")}> <img src={eMandiNews}/><span>eMandi News</span><span className='justluanched'>Just Launched</span></li>  */}
                            {/* <span className={state.currentStage=="justluanched"?"active-cls":"norma-cls"} onClick={() => setCurrentState("eMandiNews")}>Just Launched</span></li> */}

                            {/* <hr></hr> */}
                            <li
                                className={
                                    state.currentStage == "weeklyCommodityReports"
                                        ? "active-cls"
                                        : "norma-cls"
                                }
                                onClick={() => setCurrentState("weeklyCommodityReports")}
                            >
                                {" "}
                                <img src={Calendar} />
                                <span>Weekly Commodity Reports</span>
                            </li>
                            <hr></hr>
                        </ul>
                    </div>
                    <Scrollbars
                        className="contact-us-scrollbar"
                        autoHide={false}
                        style={{ width: "100%", height: "55vh" }}
                    >
                        <div className="content-cls">
                            {renderCurrentZone[state.currentStage]}
                        </div>
                    </Scrollbars>

                    {/* <Scrollbars autoHide={true} style={{ width: "60em", height: "50px" }}>
                            <TabMenu model={state.items} activeItem={state.activeItem} onTabChange={onClickTabView} />
                        </Scrollbars> */}
                </div>
                <div>
                    {state.count > 0 ?
                        <ul className="pageNumbers">
                            <li onClick={handlePrevPageBtn}>
                                {/* &lt;&lt; */}
                                <FontAwesomeIcon icon={faBackward} />
                            </li>
                            {state.count > 0 ?
                                <li
                                    key={1}
                                    id={1}
                                    onClick={hanglePageClick}
                                    className={state.current_page === 1 ? "active-page" : null}
                                >
                                    {1}
                                </li>
                                : ""}

                            {pageDeccrementBtn}
   
                            {renderPageNumbers}
 
                            {pageIncrementBtn}
                            {state.count > state.limit ? <li
                                key={localpages.length + 2}
                                id={localpages.length + 2}
                                onClick={hanglePageClick}
                                className={state.current_page === localpages.length + 2 ? "active-page" : null}
                            >
                                {localpages.length + 2}
                            </li>:""}
                            <li
                                onClick={() => {
                                    handleNextPageBtn(localpages.length + 2);
                                }}
                            >
                                {/* &gt;&gt; */}
                                <FontAwesomeIcon icon={faForward} />
                            </li>
                        </ul> : ""}
                </div>


            </div>
        );
    }
}

export default list;
