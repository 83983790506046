import React, { Component } from 'react';
import CustomInputText from '../../../../components/CustomInputText';
import CustomButton from '../../../../components/CustomButton';
import CustomCalender from '../../../../components/CustomCalendar';
import UploadDailog from './UploadDailog';
import uploadIcon from '../../../../assets/images/ic_upload.svg';
import deleteIcon from '../../../../assets/images/ic_del.svg';
import pdfIcon from '../../../../assets/images/pdfIcon.png';
import './styles.scss';
import { toast } from 'react-toastify';
import CustomDropdown from '../../../../components/CustomDropdown';
import { convertDate, convertKYCDate, fireAjaxRequest, history } from '../../../../utils/Utility';
import { AADHAAR_CONSENT_API, AADHAAR_OFFLINE_API, GET_AADHAAR_API, GET_PERSONAL_INFO, SET_KYC_API } from '../../../../utils/Api';
import { SET_KYC_FILE_UPLOAD_API } from '../../../../utils/Api'
import axios from "axios";
import { detect } from 'detect-browser';
import CustomLoader from '../../../../components/CustomLoader';
import NameDialog from "../../../EditProfile/view/nameDialog";
import moment from "moment";
import { Scrollbars } from 'react-custom-scrollbars';

class KYCInfo extends Component {
    state = {
        kycData: {},
        isPDF: false,
        fileResponse: {},
        dropdownList: [
            {
                type: 'Choose ID Type',
            }
        ],
        kycTypeList: [
            {
                type: 'Choose ID Type',
                label: 'Choose ID Type',
                value: 'Choose ID Type',
            },
            {
                type: 'PAN',
                label: "PAN",
                value: 'PAN',
                name: "",
                panNumber: '',
                dob: "",
                panIDImage: "",
                imgType: ""

            },
            {
                type: 'VOTER_ID',
                label: 'Voter ID',
                value: 'VOTER_ID',
                name: "",
                id: "",
                dob: "",
                voterIDImage: "",
                imgType: ""
            },
            {
                type: 'DRIVING_LICENSE',
                label: 'Driving Licence',
                value: 'DRIVING_LICENSE',
                licenseNumber: "",
                dob: "",
                drivingIDImage: "",
                imgType: ""
            },
            {
                type: 'PASSPORT',
                label: 'Passport',
                value: 'PASSPORT',
                name: '',
                fileNumber: "",
                dob: "",
                passportIDImage: "",
                imgType: ""
            },
            {
                type: 'AADHAR',
                label: 'Aadhaar',
                value: 'AADHAR',
                aadhaarNumber: "",
                aadharIDImage: "",
                dob: "",
                imgType: ""
            },
            {
                type: 'BANK_ACCOUNT',
                label: 'Bank Statement with Address',
                value: 'BANK_ACCOUNT',
                accNumber: "",
                ifscCode: "",
                bankIDImage: "",
                dob: "",
                imgType: ""
            },
        ],
        showDailog: false,
        shareCode: "",
        accessKey: "",
        caseId: "",
        showOTP: false,
        showUpload: false,
        uploadedAadhaar: "",
        aadhaarFormData: "",
        userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
        displayPopUp: false,
        showsubmitbtn:false
    }

    componentDidMount() {
        (async () => {
            try {
                const resp = await fireAjaxRequest(`${GET_PERSONAL_INFO}/${this.state.userInfo.user.id}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`
                    },
                });
                if (resp && resp.phoneNo) {
                    this.setState({ personalInfoData: resp, KYCInfo: resp.kyc, loading: false });
                } else {
                    toast.error("Failed to Fetch User Details !");
                    this.setState({ loading: false })
                }
                this.setState({ loading: false })
            } catch (err) {
                toast.error("Failed to Fetch User Details !");
                this.setState({ loading: false })
            }
        })();
    }

    onSelectFile = (e) => {
        e.persist();
        this.setState({
            previewFile: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null
        });
    }

    handleDropdownChange = (e, item, index, name) => {
        this.setState({showsubmitbtn:true})
        if (e.target.name.includes("IDImage")) {

            this.setState({ showDailog: true })
            e.persist();
            const { name, value } = e.target;
            const list = [...this.state.dropdownList];
            list[index][name] = e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null;
            list[index]["imgType"] = e.target.files[0] && e.target.files[0].type
            this.setState({ dropdownList: list })
            var bodyFormData = new FormData();
            bodyFormData.append('userId', JSON.parse(sessionStorage.getItem("userInfo")).user.id);
            bodyFormData.append('file', e.target.files[0]);
            e.target.name === "drivingIDImage" && bodyFormData.append('docType', "dl");

            (async () => {
                try {
                    axios.post(SET_KYC_FILE_UPLOAD_API, bodyFormData, {
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "multipart/form-data"
                        }
                    })
                        .then(response => {
                            var addProperties = {
                                "ifscCode": "",
                                "fileNumber": "",
                                "doi": ""
                            }
                            if (response && response.data[0] && response.data[0].details) {
                                this.setState({ fileResponse: response.data[0].details })
                                const data = response.data[0].details;
                                if (response.data[0].type === "Pan") {
                                    list[index]["value"] = data.panNo;
                                    list[index]["name"] = data.name;
                                    let tempDate = data.date;
                                    tempDate = `${tempDate.slice(3, 5)}/${tempDate.slice(0, 2)}/${tempDate.slice(6, 10)}`;
                                    list[index]["tempDate"] = tempDate;
                                    list[index]["dob"] = data.date;
                                    list[index]["userId"] = JSON.parse(sessionStorage.getItem("userInfo")).user.id;
                                    list[index]["additionalProperties"] = addProperties;
                                }
                                if (response.data[0].type.includes("Aadhaar")) {
                                    list[index]["value"] = data.aadhaar;
                                    list[index]["name"] = data.name;
                                    list[index]["userId"] = JSON.parse(sessionStorage.getItem("userInfo")).user.id;
                                    list[index]["additionalProperties"] = addProperties;
                                }
                                if (response.data[0].type.includes("Voterid")) {
                                    list[index]["value"] = data.voterid;
                                    list[index]["name"] = data.name;
                                    let tempDate = data.date;
                                    tempDate = `${tempDate.slice(3, 5)}/${tempDate.slice(0, 2)}/${tempDate.slice(6, 10)}`;
                                    list[index]["tempDate"] = tempDate;
                                    list[index]["dob"] = data.date;
                                    list[index]["userId"] = JSON.parse(sessionStorage.getItem("userInfo")).user.id;
                                    list[index]["additionalProperties"] = addProperties;
                                }
                                if (response.data[0].type.includes("DL")) {
                                    list[index]["value"] = data.dlNo;
                                    list[index]["name"] = data.name;
                                    let tempDate = data.date;
                                    tempDate = `${tempDate.slice(3, 5)}/${tempDate.slice(0, 2)}/${tempDate.slice(6, 10)}`;
                                    list[index]["tempDate"] = tempDate;
                                    list[index]["dob"] = data.date;
                                    list[index]["userId"] = JSON.parse(sessionStorage.getItem("userInfo")).user.id;
                                    list[index]["additionalProperties"] = addProperties;
                                }
                                if (response.data[0].type.includes("Passport")) {
                                    list[index]["value"] = data.passportNum;
                                    let tempDate = data.dob;
                                    tempDate = `${tempDate.slice(3, 5)}/${tempDate.slice(0, 2)}/${tempDate.slice(6, 10)}`;
                                    list[index]["tempDate"] = tempDate;
                                    list[index]["dob"] = data.dob !== null ? data.dob : "";
                                    list[index]["name"] = `${data.surname} ${data.givenName}`;
                                    list[index]["userId"] = JSON.parse(sessionStorage.getItem("userInfo")).user.id;
                                    addProperties = {
                                        "ifscCode": "",
                                        "fileNumber": response.data[1].details.fileNum,
                                        "doi": response.data[0].details.doi
                                    }
                                    list[index]["additionalProperties"] = addProperties;
                                }

                                this.setState({ dropdownList: list, })
                            }
                            this.setState({ showDailog: false })
                        }).catch(error => {
                            let err = error.toString();
                            if (err) {
                                if (err.includes("400")) {
                                    toast.error("Mandatory fields are missing / invalid")
                                }
                                else if (err.includes("401")) {
                                    toast.error("API Key is missing or invalid.")
                                }
                                else if (err.includes("402")) {
                                    toast.error("Credits to access the APIs expired.")
                                }
                                else if (err.includes("500")) {
                                    toast.error("Internal processing error of Karza.")
                                }
                                else if (err.includes("503")) {
                                    toast.error("The source for authentication is down for maintenance or inaccessible.")
                                }
                                else if (err.includes("504")) {
                                    toast.error("The response latency from the source for authentication is >30sec.")
                                }
                            }
                            this.setState({ showDailog: false })
                        });
                } catch (err) {
                    this.setState({ showDailog: false })
                }
            })();
        }
        else {
            const { name, value } = e.target;
            const list = [...this.state.dropdownList];
            if (e.target.name === "dob") {
                list[index][name] = convertDate(value, "yymmdd");
                this.setState({ dropdownList: list, })
            }
            else if (e.target.name === "type") {
                const list = [...this.state.dropdownList];
                var kycNames = []
                list.map((item) => {
                    kycNames.push(item.type)
                });
                if (kycNames && kycNames.length > 0 && kycNames.includes(e.target.value) && e.target.value !== "Choose ID Type") {
                    toast.error(` '${e.target.value}' is Already Selected. Choose other ID'`)
                }
                else {
                    list[index][name] = value;
                    list[index]["name"] = "";
                    list[index]["value"] = "";
                    list[index]["dob"] = "";
                    list[index]["previewFile"] = "";
                }
                if (e.target.value === "PASSPORT") {
                    list[index]["additionalProperties"] = {
                        doi: "",
                    }
                }
                this.setState({ dropdownList: list, })
            }
            else if (e.target.name === "value") {
                if (item && item.type === "AADHAR") {
                    if (value.length <= 12) {
                        list[index][name] = value;
                    }
                }
                else {
                    list[index][name] = value;
                }
                this.setState({ dropdownList: list, })
            } else if (e.target.name === "doi") {
                console.log(list)
                let doi = {
                    doi: e.target.value
                }
                list[index].additionalProperties = doi;
                console.log(list)
            }
            else {
                list[index][name] = value;
                this.setState({ dropdownList: list, })
            }
        }
    }

    onClickDelete = (name, index) => {
        const list = [...this.state.dropdownList];
        console.log(list)
        let type = list[index]["type"];
        list[index] = {};
        list[index]["type"] = type;
        // list[index]["value"] = "";
        // list[index]["dob"] = "";
        // list[index]["name"] = "";
        // list[index]["previewFile"] = "";
        this.setState({ dropdownList: list, })
    }

    onClickGetOTP = () => {
        const browser = detect();
        this.setState({ loading: true })
        if (browser) {
            const { dropdownList } = this.state;
            var sendBody = dropdownList.filter((item, index) => item.type === "AADHAR");
            const sendDetails = {
                "value": sendBody[0].value,
                "userAgent": browser.name,
                "consent": "Y",
                "name": sendBody[0].name,
                "consentText": "I hereby grant consent to..",
                "consentTime": "1610369914",
                // "ipAddress": "192.168.43.96",
                "lat": "17.300166",
                "longitude": "78.526495",
                "clientData": {
                    "caseId": "a341fafb"
                },
                "deviceId": "5FA01EE3-B9AB-42A3-ACB1-B150D0C2CA07"
            };
            (async () => {
                try {
                    const resp = await fireAjaxRequest(AADHAAR_CONSENT_API, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(sendDetails)
                    });
                    //console.log("1st API ", resp);
                    this.setState({ loading: false });
                    if (resp && resp.data) {

                        if (resp.message.includes("not")) {
                            toast.error(resp.message);
                            this.setState({ shareCode: "", showOTP: false, caseId: "", accessKey: "" })
                        }
                        else {
                            toast.success(resp.message);
                            this.setState({ shareCode: resp.shareCode, caseId: resp.data.clientData.caseId, accessKey: resp.data.result.accessKey, showOTP: true });
                        }
                    }
                    else if (resp) {
                        this.setState({ shareCode: "", showOTP: false, caseId: "", accessKey: "", loading: false });
                        if (resp.message.includes("400")) {
                            toast.error("Mandatory fields are missing / invalid")
                        }
                        else if (resp.message.includes("401")) {
                            toast.error("API Key is missing or invalid.")
                        }
                        else if (resp.message.includes("104")) {
                            toast.error("Internal processing error of Karza.")
                        }
                        else if (resp.message.includes("402")) {
                            toast.error("Credits to access the APIs expired.")
                        }
                        else if (resp.message.includes("500")) {
                            toast.error("Internal processing error of Karza.")
                        }
                        else if (resp.message.includes("503")) {
                            toast.error("The source for authentication is down for maintenance or inaccessible.")
                        }
                        else if (resp.message.includes("504")) {
                            toast.error("The response latency from the source for authentication is >30sec.")
                        }
                    }
                } catch (err) {
                    // this.setState({ maxSizeFlag: true });
                    this.setState({ loading: false })
                }
            })();
        }
    }

    onClickAadhaarUpload = () => {
        if (this.state.updatePersonalInfoAdhar === "YES"){
            this.setState({ displayPopUp:true, aadharUploaded:true });
        }else{
            this.setState({ loading: true });
        axios({
            url: `${AADHAAR_OFFLINE_API}`,
            method: 'POST',
            data: this.state.aadhaarFormData,
            headers: {
                "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                "Content-Type": "multipart/formdata"
            },
            // responseType: 'blob', // Important
        }).then((resp) => {
            this.setState({ loading: false })
            if (resp && resp.data && resp.data.data) {
                toast.success(resp.message);
                this.setState({ showUpload: false });
                sessionStorage.setItem("kycUploaded", true);
                history.push(`/auction`);
            }
            else if (resp) {
                this.setState({ shareCode: "", showOTP: false, caseId: "", accessKey: "", loading: false });
                if (resp.data.message.includes("400")) {
                    toast.error("Mandatory fields are missing / invalid")
                }
                else if (resp.data.message.includes("401")) {
                    toast.error("API Key is missing or invalid.")
                }
                else if (resp.data.message.includes("402")) {
                    toast.error("Credits to access the APIs expired.")
                }
                else if (resp.data.message.includes("104")) {
                    toast.error("Internal processing error of Karza.")
                }
                else if (resp.data.message.includes("102")) {
                    toast.error("Internal processing error of Karza.")
                }
                else if (resp.data.message.includes("500")) {
                    toast.error("Internal processing error of Karza.")
                }
                else if (resp.data.message.includes("503")) {
                    toast.error("The source for authentication is down for maintenance or inaccessible.")
                }
                else if (resp.data.message.includes("504")) {
                    toast.error("The response latency from the source for authentication is >30sec.")
                }else if (resp.data.message.includes("302")) {
                    toast.error("Your ID has been added in the system, You can add other ID's");
                }
            }
        })
            .catch(error => {
                this.setState({ loading: false });
            })
        }
        
    }
    downloadZip = (filename, data) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + data);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    }
    onChangeZip = (e) => {
        e.persist();
        var bodyFormData = new FormData();
        bodyFormData.append('file', e.target.files[0]);
        bodyFormData.append('consent', "Y");
        bodyFormData.append('shareCode', this.state.shareCode);
        this.setState({ aadhaarFormData: bodyFormData, uploadedAadhaar: e.target.files[0] ? e.target.files[0] : this.state.uploadedAadhaar })
    }

    onClickVerifyOTP = () => {
        const { KYCInfo, formData,personalInfoData } = this.state;
        let personalInformationName = personalInfoData.name.toLowerCase();
        let updatePersonalInfo = "YES";
        const { dropdownList } = this.state;
        this.setState({ loading: true });
        var sendBody = dropdownList.filter((item, index) => item.type === "AADHAR");
        const verifyOTPDetails = {
            "otp": sendBody[0].otp,
            "shareCode": this.state.shareCode,
            "accessKey": this.state.accessKey,
            "clientData": {
                "caseId": this.state.caseId
            }
        };

        KYCInfo && KYCInfo.length && KYCInfo.map((element) => {
            if (element.type === "AADHAR") {
                if (element.name === personalInformationName) {
                    updatePersonalInfo = "NO";
                    return null
                }
            }
        })

        //Taking name from verified kyc
        for (let i = 0; i < KYCInfo.length; i++) {
            if (KYCInfo[i].status === "Verified" && KYCInfo[i].name) {
                updatePersonalInfo = "NO";
                break;
            }
        }

        this.setState({ updatePersonalInfoAdhar:updatePersonalInfo  });
            (async () => {
                try {
                    const resp = await fireAjaxRequest(GET_AADHAAR_API, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(verifyOTPDetails)
                    });
                    //console.log("2nd API ", resp)
                    this.setState({ loading: false });
                    if (resp && resp.data && resp.data.dataFromAadhaar) {
                        this.downloadZip("aadhaar.zip", resp.data.dataFromAadhaar.file);
                        this.setState({ showOTP: false, showUpload: true })
                    }
                    else if (resp) {
                        this.setState({ shareCode: "", showOTP: false, caseId: "", accessKey: "", loading: false });
                        if (resp.message.includes("400")) {
                            toast.error("Mandatory fields are missing / invalid")
                        }
                        else if (resp.message.includes("401")) {
                            toast.error("API Key is missing or invalid.")
                        }
                        else if (resp.message.includes("402")) {
                            toast.error("Credits to access the APIs expired.")
                        }
                        else if (resp.message.includes("104")) {
                            toast.error("Internal processing error of Karza.")
                        }
                        else if (resp.message.includes("102")) {
                            toast.error("Internal processing error of Karza.")
                        }
                        else if (resp.message.includes("500")) {
                            toast.error("Internal processing error of Karza.")
                        }
                        else if (resp.message.includes("503")) {
                            toast.error("The source for authentication is down for maintenance or inaccessible.")
                        }
                        else if (resp.message.includes("504")) {
                            toast.error("The response latency from the source for authentication is >30sec.")
                        }
                    }
                } catch (err) {
                    this.setState({ loading: false });
                }
            })();

    }

    onClickSubmit = (e) => {
        const { dropdownList } = this.state;
        this.setState({ loading: true })
        const newArr = dropdownList.reduce((a, e) => {
            a[e.type] = ++a[e.type] || 1;
            return a;
        }, {});
        const entries = newArr && Object.entries(newArr);
        const duplicateReason = [];
        entries && entries.map((item, index) => {
            if (item[1] > 1 && item[0] !== "Choose ID Type") {
                duplicateReason.push(item);
                toast.error(` '${item[0]}' ID Type selected ${item[1]} times. Please choose any one of '${item[0]}'`)
            }
        })

        var sendBody = dropdownList.filter((item, index) => item.type !== "Choose ID Type" && item.type !== "AADHAR" && !item.id && item.type != "");

        var isPassportError = false;
        var isPanError = false;
        var isVoterError = false;
        var isAadharError = false;
        var isDLError = false;
        var isBankError = false;
        sendBody.map((item, index) => {
            if (item.type !== "" && duplicateReason && duplicateReason.length === 0) {
                var alphaNumericRegex = /^[A-Z0-9]+$/;
                var numericRegex = /^[0-9]+$/;
                if (item.type === "PASSPORT") {
                    if (!item.hasOwnProperty("name")) {
                        toast.error(`Please enter ${item.type} Name`);
                        isPassportError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("value")) {
                        toast.error(`Please enter ${item.type} Number`);
                        isPassportError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("dob")) {
                        toast.error(`Please enter ${item.type} DOB`);
                        isPassportError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.dob === "NaN-aN-aN") {
                        toast.error(`Please enter ${item.type} DOB`);
                        isPassportError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("additionalProperties")) {
                        toast.error(`Please enter ${item.type} File Number and DOI`);
                        isPassportError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("additionalProperties")) {
                        if (!item.additionalProperties.hasOwnProperty("fileNumber")) {
                            toast.error(`Please enter ${item.type} File Number`);
                            isPassportError = true;
                            this.setState({ loading: false });
                        }
                        else if (!item.additionalProperties.hasOwnProperty("doi")) {
                            toast.error(`Please enter ${item.type} DOI`);
                            isPassportError = true;
                            this.setState({ loading: false });
                        }
                    }
                    else {
                        isPassportError = false
                    }
                    item.additionalProperties.doi = moment(item.additionalProperties.doi).format('DD/MM/YYYY');
                }
                else if (item.type === "PAN") {
                    if (!item.hasOwnProperty("name")) {
                        toast.error(`Please enter ${item.type} Name`);
                        isPanError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("dob")) {
                        toast.error(`Please enter ${item.type} DOB`);
                        isPanError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("dob") && item.dob === "NaN-aN-aN") {
                        toast.error(`Please enter ${item.type} DOB`);
                        isPanError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("value")) {
                        toast.error(`Please enter ${item.type} Number`);
                        isPanError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("value")) {
                        if (!alphaNumericRegex.test(item.value) || item.value.length > 10) {
                            toast.error("PAN number Accepts only UPPER CASE letters,Numbers and Max length should be 10")
                            isPanError = true;
                            this.setState({ loading: false });
                        }
                    }
                }
                else if (item.type === "VOTER_ID") {
                    if (!item.hasOwnProperty("name")) {
                        toast.error(`Please enter Voter ID Name`);
                        isVoterError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("dob")) {
                        toast.error(`Please enter DOB Voter ID details`);
                        isVoterError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("dob") && item.dob === "NaN-aN-aN") {
                        toast.error(`Please enter DOB Voter ID details`);
                        isVoterError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("value")) {
                        toast.error(`Please enter Voter Number`);
                        isVoterError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("value")) {
                        if (!alphaNumericRegex.test(item.value) || item.value.length > 17) {
                            toast.error("VOTER ID number Accepts only UPPER CASE letters,Numbers and Max length should be 17")
                            isVoterError = true;
                            this.setState({ loading: false });
                        }
                    }
                }
                else if (item.type === "AADHAR") {
                    if (!item.hasOwnProperty("value")) {
                        toast.error(`Please enter Aadhaar Number`);
                        isAadharError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("dob")) {
                        toast.error(`Please enter ${item.type} DOB`);
                        isAadharError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("value")) {
                        if (!numericRegex.test(item.value) || item.value.length > 12) {
                            toast.error("AADHAR number Accepts only Numbers and Max length should be 12");
                            isAadharError = true;
                            this.setState({ loading: false });
                        }
                        else {
                            isAadharError = false;
                        }
                    }
                    else {
                        isAadharError = false;
                    }
                }
                else if (item.type === "DRIVING_LICENSE") {
                    if (!item.hasOwnProperty("name")) {
                        toast.error(`Please enter Name in Driving Licence Details`);
                        isDLError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("value")) {
                        toast.error(`Please enter Driving Licence Number`);
                        isDLError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("dob")) {
                        toast.error(`Please enter Date of Birth in Driving Licence Details`);
                        isDLError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("dob") && item.dob === "NaN-aN-aN") {
                        toast.error(`Please enter Date of Birth in Driving Licence Details`);
                        isDLError = true;
                        this.setState({ loading: false });
                    }
                    else if (item.hasOwnProperty("value")) {
                        if (!alphaNumericRegex.test(item.value) || item.value.length > 50) {
                            toast.error("Driving Licence number Accepts only UPPER CASE letters,Numbers and Max length should be 50")
                            isDLError = true;
                            this.setState({ loading: false });
                        }
                    }
                }
                else if (item.type === "BANK_ACCOUNT") {
                    if (!item.hasOwnProperty("value") || (item.hasOwnProperty("value") && (!numericRegex.test(item.value) || item.value.length > 16))) {
                        toast.error(`Please enter Bank Account Number and It accepts Only Numbers and Max length is 16`);
                        isBankError = true;
                        this.setState({ loading: false });
                    }
                    else if (!item.hasOwnProperty("ifscCode") || (item.hasOwnProperty("ifscCode") && (!alphaNumericRegex.test(item.ifscCode) || item.ifscCode.length > 11))) {
                        toast.error(`Please enter Bank IFSC Code and It Accepts only UPPER CASE letters,Numbers and Max length should be 11`);
                        isBankError = true;
                        this.setState({ loading: false });
                    }
                }

            }
        });
        if (!isPassportError && !isPanError && !isVoterError && !isDLError && !isBankError) {
            var additionalProperties = {
                doi: "",
                fileNumber: "",
                ifscCode: ""
            }
            let accountTypeOrgPanFlag = true;

            sendBody.map((item, index) => {
                item.dob = item.dob && convertKYCDate(item.dob, "yymmdd") && convertKYCDate(item.dob, "yymmdd").replace(/undefined-/g, "");
                item["userId"] = JSON.parse(sessionStorage.getItem("userInfo")).user.id;
                if (item.type !== "PASSPORT") {
                    item["additionalProperties"] = additionalProperties;
                }

            });

            //Check for pan in account type organzation
            if (this.state.userInfo.user.accountType == "ORGANIZATION_TRADER") {
                accountTypeOrgPanFlag = false;
                sendBody.map((element) => {
                    if (element.type === "PAN") {
                        accountTypeOrgPanFlag = true;
                    }
                })
                KYCInfo && KYCInfo.length && KYCInfo.map((element) => {
                    if (element.status === "Verified" && element.type === "PAN") {
                        accountTypeOrgPanFlag = true;
                    }
                })

                if (!accountTypeOrgPanFlag) {
                    toast.error("In account type organization pan card is mandatory")
                }
            }

            //Check KYC name with personal information name
            let validateKYCName = this.validateKYCName(sendBody);
            { console.log(this.state.userInfo.user.accountType, "sad") }
            if (this.state.userInfo.user.accountType == "ORGANIZATION_TRADER") {
                validateKYCName = false;
            }
            if (validateKYCName) {
                this.setState({ displayPopUp: true, KycSendBodyParam: sendBody, duplicateReason: duplicateReason, validateKYCName: validateKYCName });
            } else {
                sendBody.length > 0 && accountTypeOrgPanFlag && duplicateReason && duplicateReason.length == 0 && (async () => {
                    try {
                        const resp = await fireAjaxRequest(`${SET_KYC_API}?updatePersonalInfo=${validateKYCName ? "YES" : "NO"}`, {
                            method: 'POST',
                            body: JSON.stringify(sendBody),
                            headers: {
                                "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                                "Content-Type": "application/json"
                            }
                        });
                        this.setState({ loading: false });
                        if (resp && resp.length > 0) {
                            toast.success("KYC Uploaded");
                            sessionStorage.setItem("kycUploaded", true);
                            history.push(`/auction`);
                        }
                    } catch (error) {
                        let err = error;
                        if (err) {
                            if (err.code == "400") {
                                toast.error("Mandatory fields are missing / invalid")
                            }
                            else if (err.code == "401") {
                                toast.error("API Key is missing or invalid.")
                            }
                            else if (err.code == "402") {
                                toast.error("Credits to access the APIs expired.")
                            }
                            else if (err.code == "422") {
                                toast.error("Recheck the Entered Details")
                            }
                            else if (err.code == "500") {
                                toast.error("Internal processing error of Karza.")
                            }
                            else if (err.code == "503") {
                                toast.error("The source for authentication is down for maintenance or inaccessible.")
                            }
                            else if (err.code == "504") {
                                toast.error("The response latency from the source for authentication is >30sec.")
                            }else if (err.code == "302") {
                                toast.error("Your ID has been added in the system, You can add other ID's");
                            }
                        }
                        this.setState({ loading: false })
                    }
                })();
            }

        }
    }

    validateKYCName = (sendBody) => {
        // let { KYCInfo, personalInfoData } = this.state;
        // let personalInformationName = personalInfoData.name.toLowerCase();
        // let verifiedKYCName, upoladedKYCName;

        // //Taking name from verified kyc
        // for (let i = 0; i < KYCInfo.length; i++) {
        //     if (KYCInfo[i].status === "Verified" && KYCInfo[i].name) {
        //         verifiedKYCName = KYCInfo[i].name;
        //         break;
        //     }
        // }

        // //Taking name from newly uploaded kyc
        // for (let i = 0; i < sendBody.length; i++) {
        //     if (sendBody[i].name) {
        //         upoladedKYCName = sendBody[i].name.toLowerCase();
        //         break;
        //     }
        // }

        // if (verifiedKYCName) {
        //     console.log("name is same verifiedKYCName");
        //     return false; //name is same
        // } else {
        //     if (personalInformationName === upoladedKYCName) {
        //         console.log("name is same");
        //         return false; //name is same
        //     } else {
        //         console.log("name is diffrent");
        //         return true; //name is diffrent
        //     }
        // }

        let { KYCInfo, personalInfoData } = this.state;
        let personalInformationName = personalInfoData.name.toLowerCase();
        let verifiedKYCName, upoladedKYCName, namePresent;

        //Taking name from verified kyc
        for (let i = 0; i < KYCInfo.length; i++) {
            if (KYCInfo[i].status === "Verified" && KYCInfo[i].name) {
                verifiedKYCName = KYCInfo[i].name;
                break;
            }
        }

        //Taking name from newly uploaded kyc
        for (let i = 0; i < sendBody.length; i++) {
            if (sendBody[i].name) {
                namePresent = true;
                upoladedKYCName = sendBody[i].name.toLowerCase();
                break;
            }
        }

        console.log(sendBody,"sendBody.type");

        if (namePresent){
            if (verifiedKYCName) {
                console.log("name is same verifiedKYCName");
                return false; //name is same
            } else {
                if (personalInformationName === upoladedKYCName) {
                console.log("name is same");
                return false; //name is same
                } else {
                console.log("name is diffrent");
                return true; //name is diffrent
                }
            }
        }else{
            console.log("name is same verifiedKYCName");
            return false;
        }
    }

    onAcceptKYCNameDialog = () => {
        let { KycSendBodyParam, duplicateReason, validateKYCName, aadharUploaded, verifyOTPDetails } = this.state;

        if (aadharUploaded){
            this.setState({ loading: true });
        axios({
            url: `${AADHAAR_OFFLINE_API}?updatePersonalInfo=YES`,
            method: 'POST',
            data: this.state.aadhaarFormData,
            headers: {
                "Authorization": `Bearer ${this.state.userInfo.token}`,
                "Content-Type": "multipart/formdata"
            },
            // responseType: 'blob', // Important
        }).then((resp) => {
            this.setState({ loading: false, displayPopUp:false });
            if (resp && resp.data && resp.data.data) {
                // toast.success(resp.message);
                toast.success(resp.message);
                this.setState({ showUpload: false });
                sessionStorage.setItem("kycUploaded", true);
                history.push(`/auction`);
            }
            else if (resp) {
                if (resp.data.message.includes("400")) {
                    toast.error("Mandatory fields are missing / invalid")
                }
                else if (resp.data.message.includes("401")) {
                    toast.error("API Key is missing or invalid.")
                }
                else if (resp.data.message.includes("402")) {
                    toast.error("Credits to access the APIs expired.")
                }
                else if (resp.data.message.includes("104")) {
                    toast.error("Internal processing error of Karza.")
                }
                else if (resp.data.message.includes("102")) {
                    toast.error("Internal processing error of Karza.")
                }
                else if (resp.data.message.includes("500")) {
                    toast.error("Internal processing error of Karza.")
                }
                else if (resp.data.message.includes("503")) {
                    toast.error("The source for authentication is down for maintenance or inaccessible.")
                }
                else if (resp.data.message.includes("504")) {
                    toast.error("The response latency from the source for authentication is >30sec.")
                }else if (resp.data.message.includes("302")) {
                    toast.error("Your ID has been added in the system, You can add other ID's");
                }
                this.setState({ showUpload: false, loading: false })
            }
        })
            .catch(error => {
                this.setState({ loading: false, showUpload: false })
            })
            
        } else {
            KycSendBodyParam.length > 0 && duplicateReason && duplicateReason.length == 0 && (async () => {
                try {
                    const resp = await fireAjaxRequest(`${SET_KYC_API}?updatePersonalInfo=${validateKYCName ? "YES" : "NO"}`, {
                        method: 'POST',
                        body: JSON.stringify(KycSendBodyParam),
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        }
                    });
                    this.setState({ loading: false });
                    if (resp && resp.length > 0) {
                        toast.success("KYC Uploaded");
                        sessionStorage.setItem("kycUploaded", true);
                        history.push(`/auction`);
                    }
                } catch (error) {
                    let err = error;
                    if (err) {
                        if (err.code == "400") {
                            toast.error("Mandatory fields are missing / invalid")
                        }
                        else if (err.code == "401") {
                            toast.error("API Key is missing or invalid.")
                        }
                        else if (err.code == "402") {
                            toast.error("Credits to access the APIs expired.")
                        }
                        else if (err.code == "422") {
                            toast.error("Recheck the Entered Details")
                        }
                        else if (err.code == "500") {
                            toast.error("Internal processing error of Karza.")
                        }
                        else if (err.code == "503") {
                            toast.error("The source for authentication is down for maintenance or inaccessible.")
                        }
                        else if (err.code == "504") {
                            toast.error("The response latency from the source for authentication is >30sec.")
                        }else if (err.code == "302") {
                            toast.error("Your ID has been added in the system, You can add other ID's");
                        }
                    }
                    this.setState({ loading: false })
                }
            })()
        }


    }

    onClickAddRow = (item, index) => {
        var dropdownList = this.state.dropdownList;
        dropdownList = [...dropdownList, { type: 'Choose ID Type' }];
        this.setState({ dropdownList })
    }

    showUploadImageBlock = (item, index, name) => {
        return (
            <div className="uploadImageCls" style={{ display: "flex" }}>
                <div className="addPicture-cls" style={{
                    height: 43, border: '1px dashed #707070', cursor: 'pointer',
                    width: 85, backgroundColor: "#fff"
                }}>

                    <div className={"block1-cls"}>
                        <label htmlFor={name} className="uploadFile-cls" style={{
                            borderRadius: 10, opacity: 1,
                            width: 25,
                            height: 25
                        }}>
                            {
                                !item[name] ?
                                    <div className="chequedd-pic-wrap-cls">
                                        <div className="prePicture-cls" style={{
                                            width: 15,
                                            height: 15,
                                            border: '1px solid black',
                                            borderWidth: '1px 0px 0px 1px',
                                            cursor: 'pointer',
                                            margin: '5px 15px'
                                        }}></div>
                                        <div className="onClickPicture-cls" style={{
                                            width: 43,
                                            height: 22,
                                            background: '#ebf6e1',
                                            border: '1px solid #f5f5f5',
                                            margin: '-15px 20px',
                                            cursor: 'pointer',

                                        }}><p style={{ fontSize: 10, color: "#bdbdbd", fontWeight: "bold", marginTop: 5 }}>ID-Front</p></div>
                                        <div className="postPicture-cls" style={{
                                            width: 15,
                                            height: 15,
                                            border: '1px solid black',
                                            margin: '-2px 53px',
                                            borderWidth: '0px 1px 1px 0px',
                                            cursor: 'pointer'
                                        }}></div>
                                        <input hidden id={name} type="file" name={name} style={{ width: 25, height: 20 }} onChange={(e) => this.handleDropdownChange(e, item, index)} />
                                    </div>
                                    :
                                    <div className="previewImage" style={{ textAlign: 'center', width: 85 }}>
                                        <img src={item.imgType === "application/pdf" ? pdfIcon : item && item[name]} alt="preview" style={{ width: 43, height: 43 }} />
                                        {/* <input hidden id={name} name={name} type="file" accept="image/*" onChange={(e) => this.handleDropdownChange(e, item, index)} /> */}
                                    </div>
                            }
                        </label>
                    </div>
                </div>
                <div className={"block2-cls"}>
                    <div className="uploadImgCls" style={{ height: '50%' }}>
                        <img src={uploadIcon} alt={"uploadIcon"} style={{ marginLeft: 10, marginBottom: 5, cursor: "pointer" }} />
                        <input id={name} type="file" name={name} accept="image/*" style={{
                            width: 25, height: 20, opacity: 0, position: "relative",
                            left: -15, cursor: "pointer"
                        }} onChange={(e) => this.handleDropdownChange(e, item, index)} />
                    </div>
                    <div className="deleteImgCls" style={{ height: '50%', marginTop: 5 }}>
                        <img src={deleteIcon} alt={"deleteIcon"} style={{ marginLeft: 10, cursor: "pointer" }} onClick={() => this.onClickDelete(name, index)} />
                    </div>

                </div>
            </div>
        )
    }

    showImageHeader = () => {
        return (
            <div className="header-cls" style={{ display: "flex", alignItems: "center", marginBottom: 5, }}>
                <p style={{
                    fontSize: 11, color: "#00000099", marginRight: 5,
                    fontWeight: "bold"
                }}>Upload ID-Image</p><span style={{
                    fontSize: 11, color: "#aeaeae",
                    fontWeight: "bold"
                }}> - or - </span><p
                    style={{
                        fontSize: 11, color: "#00000099", marginLeft: 5,
                        fontWeight: "bold"
                    }}
                >Fill Details Below</p>
            </div>
        )
    }

    handelInputChange = (e, item, index) =>{
        let {dropdownList} = this.state;
        if (e.target.name ==="doi"){
            dropdownList[index].additionalProperties.doi = e.target.value;
        }else{
            dropdownList[index].additionalProperties.fileNumber = e.target.value;
        }
        this.setState({ dropdownList:dropdownList  });
    }

    renderValues = (item, index) => {
        const { kycData } = this.state;
        if (item.type === "PAN") {
            console.log(item)
            return (
                <div className="pan-cls">
                    {this.showImageHeader()}
                    {this.showUploadImageBlock(item, index, "panIDImage")}
                    <div className="input-cls panName-cls">
                        <CustomInputText key="panName" value={(item && item.name)}
                            label="Name" name="name" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="input-cls panNumber-cls">
                        <CustomInputText key="panNumber" value={(item && item.value)}
                            label="ID Value" name="value" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="calendar-cls dob-cls">
                        {console.log(item)}
                        {item.tempDate ? <CustomCalender key="panDOB" dateFormat="yy-mm-dd" value={new Date(`${item.dob.slice(3, 5)}/${item.dob.slice(0, 2)}/${item.dob.slice(6, 10)}`)}
                            label="Date of Birth" name="dob" showIcon={false} className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />
                            :
                            <CustomCalender key="panDOB" dateFormat="yy-mm-dd" value={new Date(item.dob)}
                                label="Date of Birth" name="dob" showIcon={false} className="formCls"
                                config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />}
                        {/* <CustomCalender key="panDOB" dateFormat="yy-mm-dd" value={new Date(`${item.dob.slice(3, 5)}/${item.dob.slice(0, 2)}/${item.dob.slice(6, 10)}`) || new Date(item.dob)}
                            label="Date of Birth" name="dob" showIcon={false} className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} /> */}
                    </div>
                </div>
            )

        } else if (item.type === "VOTER_ID") {
            return (
                <div className="pan-cls">

                    {this.showImageHeader()}
                    {this.showUploadImageBlock(item, index, "voterIDImage")}
                    {/* <div className="input-cls panName-cls">
                        <CustomInputText key="voterName" value={(item && item.name)}
                            label="Name" name="name" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div> */}
                    <div className="input-cls panNumber-cls">
                        <CustomInputText key="voterNumber" value={(item && item.value)}
                            label="ID Value" name="value" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    {/* <div className="calendar-cls dob-cls">
                        <CustomCalender key="voterDob" dateFormat="yy-mm-dd" value={(new Date(item.dob))}
                            label="Date of Birth" name="dob" showIcon={false} className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />
                    </div> */}
                </div>
            )

        } else if (item.type === "DRIVING_LICENSE") {
            return (
                <div className="pan-cls">
                    {this.showImageHeader()}
                    {this.showUploadImageBlock(item, index, "drivingIDImage")}
                    <div className="input-cls panName-cls">
                        <CustomInputText key="dlName" value={(item && item.name)}
                            label="Name" name="name" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="input-cls panName-cls">
                        <CustomInputText key="dlNumber" value={(item && item.value)}
                            label="Driving Licence Number" name="value" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="calendar-cls dob-cls">
                        {item.tempDate ? <CustomCalender key="dlDOB" dateFormat="yy-mm-dd" value={new Date(`${item.dob.slice(3, 5)}/${item.dob.slice(0, 2)}/${item.dob.slice(6, 10)}`)}
                            label="Date of Birth" name="dob" className="formCls" showIcon={false}
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />
                            :
                            <CustomCalender key="dlDOB" dateFormat="yy-mm-dd" value={new Date(item.dob)}
                                label="Date of Birth" name="dob" className="formCls" showIcon={false}
                                config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />}

                    </div>
                </div>
            )

        } else if (item.type === "PASSPORT") {
            return (
                <div className="pan-cls">
                    {this.showImageHeader()}
                    <div style={{ display: "flex" }}>
                        {this.showUploadImageBlock(item, index, "passportIDImage")}
                    </div>
                    <div className="input-cls panName-cls">
                        <CustomInputText key="passportName" value={(item && item.name)}
                            label="Name" name="name" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="input-cls panNumber-cls">
                        <CustomInputText key="passportNumber" value={(item && item.value)}
                            label="Passport Number" name="value" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="input-cls panNumber-cls">
                        <CustomInputText key="passportFileNumber" value={(item && item.additionalProperties && item.additionalProperties.fileNumber)}
                            label="Passport File Number" name="fileNumber" className="formCls"
                            config={{ onChange: (e) => this.handelInputChange(e, item, index) }} />
                    </div>
                    {console.log(item && item.additionalProperties && item.additionalProperties.doi, item && item.additionalProperties && item.additionalProperties, "item.additionalProperties.doi")}
                    <div className="calendar-cls dob-cls">
                        {/* <CustomInputText key="passportDOI" value={(item && item.additionalProperties && item.additionalProperties.doi)}
                            label="DOI" name="doi" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} /> */}
                        
                        <CustomCalender key={"doi"} className="formCls" value={item["additionalProperties"] && new Date(item["additionalProperties"].doi)} label="DOI" name="doi" showIcon={false} config={{
                            onChange: (e) => this.handelInputChange(e, item, index),
                        }} />
                    </div>
                    <div className="calendar-cls dob-cls">
                        {item.tempDate ? <CustomCalender key="passportDOB" dateFormat="yy-mm-dd" value={new Date(`${item.dob.slice(3, 5)}/${item.dob.slice(0, 2)}/${item.dob.slice(6, 10)}`)}
                            label="Date of Birth" name="dob" className="formCls" showIcon={false}
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />
                            :
                            <CustomCalender key="passportDOB" dateFormat="yy-mm-dd" value={new Date(item.dob)}
                                label="Date of Birth" name="dob" className="formCls" showIcon={false}
                                config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} style={{ height: 100 }} />}

                    </div>

                </div>
            )
        } else if (item.type === "AADHAR") {
            return (
                <div className="pan-cls">
                    {this.showImageHeader()}
                    {this.showUploadImageBlock(item, index, "aadharIDImage")}
                    <div className="input-cls panName-cls">
                        <CustomInputText key="aadharName" value={(item && item.name)}
                            label="Name" name="name" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="aadhaarGetOTP">
                        <div className="aadhaarNumber-cls">
                            <CustomInputText key="aadharNumber" value={(item && item.value)}
                                label="Aadhaar Number" name="value" className="formCls"
                                config={{ onChange: (e) => this.handleDropdownChange(e, item, index), keyfilter: "num" }} />
                        </div>
                        <div className="getOTPButton-cls">
                            <CustomButton className="getOTP" label="Get OTP" onClick={this.onClickGetOTP.bind(this)} config={{ 
                                keyfilter: "num", 
                                disabled: item.value && item.value.length ? item.value.length === 12 ? false : true : true, 
                                style: { backgroundColor: item.value && item.value.length === 12 ? "#e43137" : "#b8b2b2", color: item.value && item.value.length === 12 ? "#FFFFFF" : "#877c7c" } }} />
                        </div>
                    </div>
                    {
                        this.state.showOTP ?
                            <div className="aadhaarGetOTP">
                                <div className="aadhaarNumber-cls">
                                    <CustomInputText key="otp" value={(item && item.otp)}
                                        label="OTP" name="otp" className="formCls"
                                        config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                                </div>
                                <div className="getOTPButton-cls">
                                    <CustomButton className="getOTP" label="Verify OTP" onClick={this.onClickVerifyOTP.bind(this)} config={{ disabled: (item.value && item.otp) ? false : true, style: { backgroundColor: (item.value && item.otp) ? "#e43137" : "#b8b2b2", color: (item.value && item.otp) ? "#FFFFFF" : "#877c7c" } }} />
                                </div>
                            </div>
                            : ""}
                    {
                        item.status ? "" :
                            this.state.showUpload ?
                                <div className="upload-cls1">
                                    <CustomInputText name="aadhaarName" value={this.state.uploadedAadhaar && this.state.uploadedAadhaar.name} className="formCls" />
                                    <div className="insideAadhaar-cls">
                                        <img src={uploadIcon} alt="upload" />

                                        <input name="Upload Downloaded Zip" rightIcon={"pi-upload"} type="file" onChange={(e) => this.onChangeZip(e)} />

                                    </div>
                                    <div className="uploadButton-cls">
                                        <CustomButton className="getOTP" label="Upload" onClick={this.onClickAadhaarUpload.bind(this)} config={{
                                            disabled: (item.value && item.otp) ? false : true,
                                            style: { backgroundColor: this.state.uploadedAadhaar && this.state.uploadedAadhaar.name ? "#e43137" : "#b8b2b2", color: this.state.uploadedAadhaar && this.state.uploadedAadhaar.name ? "#FFFFFF" : "#877c7c" }
                                        }} />
                                    </div>
                                </div>
                                : ""}
                </div>
            )

        } else if (item.type === "BANK_ACCOUNT") {
            return (
                <div className="pan-cls">
                    <div className="input-cls panName-cls">
                        <CustomInputText key="accountNumber" value={(item && item.value)}
                            label="Account Number" name="value" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                    <div className="input-cls panNumber-cls">
                        <CustomInputText key="ifscCode" value={(item && item.ifscCode)}
                            label="IFSC Code" name="ifscCode" className="formCls"
                            config={{ onChange: (e) => this.handleDropdownChange(e, item, index) }} />
                    </div>
                </div>
            )
        }
    }
    onClickConfirm = () => {
        this.setState({ showDailog: false })
    }

    displayPopUp = (value) => {
        this.setState({ displayPopUp: value });
    }

    render() {
        const { KYCInfo, dropdownList, kycTypeList, loading, userInfo } = this.state;
        let KYCdropdown = kycTypeList;
        console.log(userInfo.user.accountType, "userInfo")
        if (userInfo && userInfo.user.accountType === "ORGANIZATION_TRADER") {
            KYCdropdown = [{
                type: 'Choose ID Type',
                label: 'Choose ID Type',
                value: 'Choose ID Type',
            }, {
                type: 'PAN',
                label: "PAN",
                value: 'PAN',
                name: "",
                panNumber: '',
                dob: "",
                panIDImage: "",
                imgType: ""

            }, {
                type: 'BANK_ACCOUNT',
                label: 'Bank Statement with Address',
                value: 'BANK_ACCOUNT',
                accNumber: "",
                ifscCode: "",
                bankIDImage: "",
                dob: "",
                imgType: ""
            },]
        }
        return (
            <div className="kycInfoBlock-cls">
                <CustomLoader loading={loading || false}>
                    <div className="kycInfo-cls">
                        <div className="kycInfoDetails-cls">

                            <UploadDailog visible={this.state.showDailog} onHide={this.onClickConfirm.bind(this)} onCancel={this.onClickConfirm.bind(this)} />
                            <NameDialog visible={this.state.displayPopUp} onHide={() => this.displayPopUp(false)} onCancel={() => this.displayPopUp(false)} onAcceptKYCNameDialog={this.onAcceptKYCNameDialog} />


                            <div className="block1-cls">
                                <Scrollbars autoHide={true} style={{ width: "100%", height: '41vh' }} >
                                    {
                                        dropdownList && dropdownList.map((item, index) => {
                                            return (
                                                <div key={index} className="multiDropdown-cls">
                                                    <div className="renderLabels-cls">
                                                        <div className="dropdown-cls">
                                                            <p>ID Type</p>
                                                            <CustomDropdown name={"type"} value={item.type} options={KYCdropdown} onChange={(e) => this.handleDropdownChange(e, item, index)} placeholder={kycTypeList && kycTypeList[0] && kycTypeList[0].label} />
                                                            {/* <Dropdown key={item.type} name="type" value={item.type} options={kycTypeList}
                                                        onChange={(e) => this.handleDropdownChange(e, item, index)} placeholder={kycTypeList && kycTypeList[0] && kycTypeList[0].label} /> */}
                                                        </div>
                                                        <div>
                                                            {this.renderValues(item, index)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Scrollbars>
                            </div>
                            {this.state.dropdownList && this.state.dropdownList.length < 3 &&
                                <div className="addRow-cls" onClick={() => this.onClickAddRow()}>
                                    <p className="add-id"><i className="pi pi-plus"></i> Add Another ID</p>
                                </div>
                            }
                        </div>
                        <div className="personalInfoButtons-cls">
                            <div className="skipButton">
                                <CustomButton label="SKIP TO VIEW AUCTIONS" onClick={this.props.onSkip} />
                                <p>This information will be required to place bids</p>
                            </div>
                            <div className="continueButton">
                                {this.state.showsubmitbtn?<CustomButton label="SUBMIT" onClick={this.onClickSubmit.bind(this)} />:""}
                            </div>
                        </div>
                    </div>
                </CustomLoader>
            </div>
        )
    }
}

export default KYCInfo;