import React, { useEffect, useState } from 'react'
import '../style/Header.css'
import vectorCheck from '../../../assets/icons/vector_check.png';
import vectorHome from '../../../assets/icons/vector_home.png';
import Gst from "./gstNumber";
import { toast } from 'react-toastify';
import {
  POST_PAN_DATA, GET_EMANDI_CASH_USER_DETAILS, KYC_VERIFY_PAN, IS_ELIGIBLE
} from "../../../utils/Api";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

function PanNumber() {
 
  const [gst, setGst] = useState(false);
  const [panResult, setPanResult] = useState('');
  const [panInput, setPanInput] = useState('');
  const [panVerified, setPanVerified] = useState(false);
  const [name, setName] = useState('');
  const [traderId, setTraderId] = useState('');
  const [requestId, setRequestId] = useState("");
  const [orgType, setOrgType] = useState("");
  const [contactNo, setContactNo] = useState('');
  const [panNumber, setPanNumber] = useState('');

  const forceUpdate = useForceUpdate();

  useEffect(()=>{
   document.getElementById("btnback").style.display="none";
  })
  const getUsername = (phoneNo) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      "phoneNo": phoneNo
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(GET_EMANDI_CASH_USER_DETAILS, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result);
        if (result.code == 200) {
          setTraderId(result.data.id);
          setContactNo(result.data.contactNo);
          sessionStorage.setItem("traderID", result.data.id)
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
    
          fetch(IS_ELIGIBLE
             + "userId=" + result.data.id
              , requestOptions)
            .then(response => response.text())
            .then(result => 
              {
                result = JSON.parse(result)
                console.log('res-------', result)
                if(result.userHistory.length > 0 ) {
                  let panNo = result.userHistory.map((u) => u.panNo)[0]
                  setPanNumber(panNo)
                  document.getElementById("pan-auto").value = panNo
                  verifyPAN(panNo)
                  document.getElementById("pan-auto").setAttribute("readonly","readonly")
                }
              }
              )
            .catch(error => console.log('error', error));
          forceUpdate();
        }
        else {
          toast.error("User is not registered with this Phone No.")
          document.getElementById("pan-auto").value = ""
          document.getElementById("pan-auto").removeAttribute("readonly")
        }
      })
      .catch(error => console.log('error', error));

  }

  const handleChangeInputNumber = (e) => {
    document.getElementById("pan-auto").value = ""
    document.getElementById("pan-auto").removeAttribute("readonly")
    if (e.target.value.length == 10) {
      getUsername(e.target.value);

    }
  }

  const handleChangeInput = (e) => {
    if (e.target.value.length > 9) {
      verifyPAN(e.target.value)
    }
  }

  const verifyPAN = (e) => {
   
      var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(KYC_VERIFY_PAN + "pan=" + e, requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result.replace("status-code", "statusCode"));
          setPanResult(result);
          if (result.message == null) {
            setPanVerified(true);
            setPanInput(e);
            setName(result.data.name)
            sessionStorage.setItem("traderName", result.data.name)
          }
          else {
            toast.error(`${result.message}`);
            setPanVerified('');
            setPanInput('');
            setName('')
            sessionStorage.setItem("", "")
          }
        }
        )
        .catch();
    
  }

  const handleClickContinue = () => {
    let type = ''
    if (panInput.substring(3, 4).toUpperCase() == 'P') {
      // setOrgType("Individual");
      // forceUpdate();
      type = "Proprietorship";
    }
    else if (panInput.substring(3, 4).toUpperCase() == 'C') {
      type = "Company";


    }
    else if (panInput.substring(3, 4).toUpperCase() == 'F') {
      if (name.toLowerCase().includes("llp")) {
        type = "LLP"
      }
      else {
        type = "Partnership";
      }
    }
    if (panInput.substring(3, 4).toUpperCase() == 'P' || panInput.substring(3, 4).toUpperCase() == 'C' || panInput.substring(3, 4).toUpperCase() == 'F') {
      setGst(true);
    }
    sessionStorage.setItem('panNumber', panInput);
    setOrgType(type);
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      "traderId": traderId,
      "pan": panInput,
      "name": name,
      "contactNo": contactNo,
      "limitApplied": "75Lakh",
      "isLimitApproved": false,
      "organizationType": type
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(POST_PAN_DATA, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        if (result.statusCode == 400) {
          toast.success(result.message);
          let rId = result?.message?.substring(result?.message.indexOf("[") + 1, result?.message.indexOf("]"));
          setRequestId(rId);
        }
        else {
          let rId = result?.requestId;
          setRequestId(rId);
        }
      }
      )
      .catch(error => console.log('error', error));
  }


  return (
    <>
      {gst === false ?
        <div className="bottom-section">
          <div className="entity-css">
            <label className='breadcrumbsLabel' for="name">
              Entity Details
              <span
                aria-hidden="true"
                class="required"
                style={{ color: '#04B23D', marginLeft: '5px' }}
              >
                *
              </span>
            </label>
          </div>
          <div class="form-group col-xs-4">
            <fieldset class="legend-css">
              <input class="form-control input-lg input-css-1 input-outline" maxLength={10} onChange={handleChangeInputNumber} placeholder="Enter Registered Mobile No."  type="text" />
            </fieldset>
          </div>
          <div class="form-group col-xs-4">
            <fieldset class="legend-css">
              <input class="form-control input-lg input-css-1 input-outline" maxLength={10} onChange={
                handleChangeInput} placeholder="Enter Business PAN" id="pan-auto" type="text" />
            </fieldset>

          </div>
          <div style={{ marginTop: '20px', color: '#0DB442', fontSize: '12px' }} className='breadcrumbsLabel'>
            <span style={{ marginRight: "20px" }}>{name}</span>
            <span>
              {panInput.substring(3, 4).toUpperCase() === 'P' ? <span> <img src={vectorHome} /> </span> : panInput.substring(3, 4).toUpperCase() === 'C' ? <span>  <img src={vectorHome} /> </span> : panInput.substring(3, 4).toUpperCase() === 'F' ? <span>  <img src={vectorHome} /> </span> : ""}
            </span>
            &nbsp;
            {panInput.substring(3, 4).toUpperCase() === 'P' ? "Individual" : panInput.substring(3, 4).toUpperCase() === 'C' ? "Company" : panInput.substring(3, 4).toUpperCase() === 'F' ? "Limited Liability Partnership" : ""}
            &nbsp;
            <span>
              {panInput.substring(3, 4).toUpperCase() === 'P' ? <img src={vectorCheck} /> : panInput.substring(3, 4).toUpperCase() === 'C' ? <img src={vectorCheck} /> : panInput.substring(3, 4).toUpperCase() === 'F' ? <img src={vectorCheck} /> : ""}
            </span>

          </div>
          <div class="continue-topheader">
            {
              panVerified != false && traderId != '' ?
                <button type="submit" class="btn btn-primary mt-3 continue-button-1" id='continue-btn' onClick={handleClickContinue}>
                  Continue
                </button> :
                <button type="submit" class="btn btn-secondary mt-3 disabled continue-button-1" id='continue-btn' >
                  Continue
                </button>
            }
          </div>

        </div>
        : <Gst rId={requestId} oType={orgType} pan={panInput} />}

    </>
  )
}

export default PanNumber;