import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import "./index.scss";
class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<div className="terms-cls">
            <Scrollbars autoHide={false} style={{ width: "100%", height: '80vh' }} >
                <p className="heading" style={{fontSize:"24px"}}>Terms and Conditions</p>
                <div style={{padding:"0px 60px", fontSize:"18px"}}>
                    <p>
                    1. I/we declare that I/we am/are citizen of India and all particulars and information given in this
                    application form are true, correct and complete and no material information has been withheld
                    or suppressed by me/us.</p>
                    <p>
                    2. I/we shall inform Origo Finance Private Limited or any of its Group Companies, Associates,
                    Subsidiaries or Holding Companies or any other lender referred by Origo Group in writing of
                    any change in my/our Residential or Employment /Business Address. </p>
                    <p>    
                    3. I/we confirm that funds sanctioned by lenders referred by Origo Group under this
                    arrangement shall be used for the stated purpose and will not be used for speculative, political
                    or anti-social activities. </p>
                    <p>   
                    4. I/we authorize Origo Finance Private Limited or any other lender referred by Origo Group to
                    conduct such credit checks as it consider necessary in its sole discretion to make any enquiries
                    with other finance companies/register credit bureau/other institution regarding my application
                    and to release such or any other information its records for the purpose of credit
                    appraisal/sharing for any other purpose. Origo Finance Private Limited or any applicable
                    lender reserves the rights to retain the photographs and documents submitted with the
                    application and will not return the same to the applicant. </p>
                    <p>   
                    5. I/we understand that sanction of this facility is at the sole discretion of Origo Finance Private
                    Limited or any other respective lender referred by Origo Group and upon my/our executing
                    necessary securities and other formalities as required by Origo Finance Private Limited or any
                    other respective lender referred by Origo Group. I/we further agree that my/our arrangement
                    shall be governed by the rules of Origo Finance Private Limited or any of its Group
                    Companies, Associates, Subsidiaries or Holding Companies or any other lender referred by
                    Origo Group which may be enforced and implemented from time to time. </p>
                    <p>    
                    6. Payment: No cash/bearer cheque has been collected from me/us upfront towards processing
                    of this arrangement application. All payments made to Origo Finance Private Limited or any
                    of its Group Companies, Associates, Subsidiaries or Holding Companies or any other lender
                    referred by Origo Group shall be in the name of respective company/lender only. </p>
                    <p>    
                    7. I/we will not be entitled to avail of any benefits/discounts/free gifts or any other benefits,
                    which is not documented in the arrangement application. </p>
                    <p>    
                    8. I/we confirm that I/we have no insolvency proceedings initiated/pending against me/us nor
                    have I /we ever been adjudicated insolvent. </p>
                    <p>    
                    9. I/we confirm that I/we am/are not related to any of the Directors/Senior Officers of Origo
                    Finance Private Limited or any of its Group Companies, Associates, Subsidiaries or Holding
                    Companies or any other lender referred by Origo Group. </p>
                    <p>    
                    10. Please note that all the documents submitted along with the application, including photograph
                    etc will be retained by Origo Finance Private Limited or any of its Group Companies,
                    Associates, Subsidiaries or Holding Companies or any other lender referred by Origo Group
                    for record purposes and will not be returned. </p>
                    <p>    
                    11. I/we hereby provide our consent to receive information/services etc. for Marketing purpose
                    through telephone/mobile/sms/email by Origo Finance Private Limited or any of its Group
                    Companies, Associates, Subsidiaries or Holding Companies or any other lender referred by
                    Origo Group /its employees/agents.  </p>
                    <p>    
                    12. I/we confirm that I/we have read and understood the above declaration and that the details
                    provided on the form is correct. </p>
                </div>
            </Scrollbars>
        </div>);
    }
}

export default Terms;