import vectorRight from '../../../assets/icons/vector_right.png';
import Admin from './Admin';
import 'react-bootstrap-accordion/dist/index.css';
import React, { useState } from 'react';
import '../style/Header.css';
import vectorHalfRectangle from '../../../assets/icons/vector_half_rectangle.png';
import vectorArrow from '../../../assets/icons/vector_arrow.png';
import 'react-bootstrap-accordion/dist/index.css';
import { toast } from 'react-toastify';
import { fireAjaxRequest } from "../../../utils/Utility"
import { UPLOAD_FILE, SAVE_ADD_DOCS } from "../../../utils/Api"
import pdfIcon from '../../../assets/icons/pdf_icon.png'
import closeIcon from '../../../assets/icons/close_icon.png'
import CongratulationsTwoCrore from './congratulations_two_cr';
import { history } from '../../../utils/Utility';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
}

function TwoCrore(props) {
    const forceUpdate = useForceUpdate();
    const [congratulations, setCongratulations] = useState(false);
    const [gif, setGif] = useState(false);
    const [additionalDocs, setAdditionDocs] = useState([]);
    const [docType, setDocType] = useState('')
    const additonalDocs = props.dataContent;
    const orgType = props.orgType;
    const reqId = props.rId;
    console.log(reqId, "NewRID");

    const changeText = (e, prefix, docType) => {
        setDocType(docType);
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            // you can use this method to get file and perform respective operations
            let files = Array.from(input.files);
            document.getElementById(e).innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Add More';
            onClickFile(files[0], prefix, docType)
        };
        input.click();
    }

    const onClickFile = (e, prefix, docType) => {
        var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
        if (e.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.type == "video/mp4") {
            toast.error("Please Upload the correct file format")
        } else if (e.size > 10485760) {
            toast.error("Uploaded file is more than 10MB")
        }
        else {
            let formData = new FormData();
            formData.append("file", e);
            formData.append("prefix", prefix);

            (async () => {
                try {
                    const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        body: formData
                    });
                    if (resp && resp.filePath) {
                        if (prefix === "document") {
                            let arr = additionalDocs;
                            const i = arr.findIndex(_element => _element[1] === docType);
                            if (i > -1) {
                                let elem = arr[i];
                                let additionalCount = elem[0].split(",");
                                if (additionalCount.length < 5) {
                                    elem[0] = elem[0] + "," + resp.filePath
                                    setAdditionDocs(arr);
                                    forceUpdate();
                                }
                                else {
                                    toast.error("Only 5 documents are allowed")
                                }
                            }
                            else {
                                let obj = []
                                obj.push(resp.filePath);
                                obj.push(docType);
                                arr.push(obj);
                                setAdditionDocs(arr);
                                forceUpdate();
                            }
                        }

                    } else {
                        toast.error("Failed to upload file !");
                        setAdditionDocs([])
                        forceUpdate();
                    }
                }
                catch (err) {
                    if (prefix === "aadhar") {
                        forceUpdate();
                    }
                }
            })();
        }
    }

    const ContinueOnClick = () => {
        // setCongratulations(true);
        let totalCountofDocs = additionalDocs.length
        let fieldFound = false;
        let docUploadedCount = 0;

        for (let v = 0; v < additonalDocs.length; v++) {
            // if (additionalDocs.length == 0) {
            //     toast.error("Please Upload " + additonalDocs[v].docName);

            // }
            if(additonalDocs[v].docName.toLowerCase().includes("ledger_with_anchor"))
            {
                ++docUploadedCount
                fieldFound = true;
            }
            for (let k = 0; k < additionalDocs.length; k++) {
                
               
                if (additonalDocs[v].docName == additionalDocs[k][1]) {
                    ++docUploadedCount
                    fieldFound = true;
                }
            }
        }
        document.getElementById("continue-btn").disabled = true;

        if (docUploadedCount < additonalDocs.length) {
            toast.error("Please Uplaod all addditional Documents");
            document.getElementById("continue-btn").disabled = false;

        }
        else {
            var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

            var links = []
            additionalDocs.map((item) => {
                var items = {}
                items["docType"] = item[1];
                items["docLink"] = [item[0]];
                items["docNo"] = ""
                links.push(items);
            })
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + token);

            var raw = JSON.stringify({
                "traderId": sessionStorage.getItem("traderID"),
                "ecashRequestId": reqId,
                "isAdditionalDocRequest": true,
                "documents":
                    links
            })

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(SAVE_ADD_DOCS, requestOptions)
                .then(response => response.text())
                .then(result => {
                    result = JSON.parse(result)
                    if (result.statusCode == "200") {
                        toast.success("Successfully updated")
                        setCongratulations(true);
                    }
                    else {
                        document.getElementById("continue-btn").disabled = false;
                        toast.error("document not uploaded yet")
                        toast.error(result.message)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    const removeFile = (e) => {
        let arr = additionalDocs;

        arr.splice(e, 1)
        setAdditionDocs(arr);
        forceUpdate();
    }

    const removefromMutliFile = (e, path) => {

        let arr = additionalDocs;
        const i = arr.findIndex(_element => _element[0].includes(path));
        if (i > -1) {
            let elem = arr[i];
            elem[0] = elem[0].replace("," + path, "")
        }
        setAdditionDocs(arr);
        forceUpdate();
    }

    const backHome = () => {
        history.push('/twoCrore');
    };

    return (
        congratulations == false ?
            <>

                <div className="main-section" style={{ backgroundColor: '#F2F2F2', height: "auto", minHeight: '720px' }}>
                    <nav aria-label="" style={{ paddingLeft: '10px' }}>
                        <div className="container container-css ">
                            <div className="container" style={{ padding: "20px", float: "left", width: "40%" }}>
                                <p className="backbtn" onClick={() => backHome()}>
                                    <i className="fa fa-arrow-left"></i>
                                    <span style={{ color: '#757575' }} className='breadcrumbsLabel'>&nbsp; Back &nbsp;</span>
                                </p>
                            </div>
                            <ol class="" style={{ float: "right", width: '400px', fontSize: "13px", marginTop: '20px' }}>
                                <a className='breadcrumbsLabel'
                                    href="#/eCash"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#757575',
                                        fontSize: "13px"
                                    }}
                                >
                                    eCash &nbsp;{' '}
                                </a>
                                <img src={vectorRight}></img>
                                <span style={{ color: '#04B23D' }} className='breadcrumbsLabel'> &nbsp; Entity Details</span>
                            </ol>
                        </div>
                    </nav>
                    <div className="body-css" style={{
                        height: 'auto', minHeight: "600px", marginTop: '15px',
                        background: 'white'
                    }}>
                        <div className="top-section"
                        >
                            <div class="top_section">
                                <br />
                                <span class="increase_limit">
                                    Upload additional documents to unlock limit
                                </span>
                                <br />
                                <span>
                                </span>
                                <br /> <br />
                            </div>
                        </div>
                        <div class="accordion" id="accordionExample">
                            <br />
                            <span class="upload_btn">
                                Upload documents
                            </span>
                            <br /> <br />
                            {
                                additonalDocs?.map((item, key) => (
                                    <>
                                        <div class="accordion-item" key={key}>
                                            <h2 class="accordion-header" id={"heading" + key}>
                                                <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + key} aria-expanded="false" aria-controls="collapseFour">
                                                    {item.docName} {item.docName.toLowerCase().includes("ledger_with_anchor")?"":<span>*</span>} <i title={"Upload "+item.docName} class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true" ></i>
                                                    <button class="btn btn-sm btn-success btn-upload" style={{ width: "30px", float: "right", marginLeft: "380px", position: "absolute" }}>
                                                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                                                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                    </button>
                                                </button>
                                            </h2>
                                            <div id={"collapse" + key} class="accordion-collapse collapse" aria-labelledby={"heading" + key} data-bs-parent="#accordionExample">
                                                <div class="accordion-body">

                                                    {
                                                        additionalDocs.map((items, key) => (
                                                            item.docName == items[1] ?
                                                                <>
                                                                    {
                                                                        items[0].includes(",") ?
                                                                            <>
                                                                                {
                                                                                    items[0].split(",").map((p) => (
                                                                                        <>
                                                                                            <a title={items[1]} className='panel_links' href={p} target='_blank'><i>
                                                                                                <img style={{ width: '5%' }} src={pdfIcon} />
                                                                                                {items[1].substring(0, 30)}
                                                                                            </i></a>
                                                                                            <i>
                                                                                                <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removefromMutliFile(key, p) }} />
                                                                                            </i><br />
                                                                                        </>
                                                                                    ))}
                                                                            </>
                                                                            : <>
                                                                                <a title={items[1]} className='panel_links' href={items[0]} target='_blank'><i>
                                                                                    <img style={{ width: '5%' }} src={pdfIcon} />
                                                                                    {items[1].substring(0, 30)}
                                                                                </i></a>
                                                                                <i>
                                                                                    <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeFile(key) }} />
                                                                                </i><br />
                                                                            </>
                                                                    }
                                                                </> : ""
                                                        ))
                                                    }
                                                    {
                                                        additionalDocs.filter(x => x[1] == item.docName).length < 5 ? <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnUpload"
                                                            onClick={(e) => {
                                                                changeText("btnUpload", "document", item.docName)
                                                            }}
                                                        >
                                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                            <span style={{ marginLeft: '5px' }} >
                                                                Upload
                                                            </span>
                                                        </button> : ""
                                                    }


                                                    <div className='clearfix' style={{ height: "30px" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                        <div class="continue-topheader-css"
                        >
                            <button id="continue-btn" type="button" class="btn btn-primary mt-3 continue-button-1" onClick={ContinueOnClick}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </>
            : <CongratulationsTwoCrore />
    );
}

export default TwoCrore;
