const initialState = {
};

function configReducer(state = initialState, action) {
    switch (action.type) {

        default:
            return state;
    }
}

export default configReducer;