import React, { Component } from 'react';

//@primereact components
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import sucesstick from '../../assets/images/sucesstick.png'
//@custom styles
import './style.scss';

//@class component
class CustomDialogTwo extends Component {
    renderHeader() {
        return (
            <div>
               <img src={sucesstick}></img>
               <span>Done</span>
            </div>
        );
    }
    renderFooter() {
        let { btn1 = "No", btn2 = "Yes", onAccept, onCancel, arrayOfButton } = this.props;

        if (arrayOfButton) {
            return (
                <div>
                    {arrayOfButton.map((element, index) => {
                        return <Button key={index} label={element.label} onClick={element.onClick} className={`login p-button-text ${element.className ? element.className : ""}`} />
                    })}
                </div>
            )
        } else {
            return (
                <div>
                    <Button label={btn1} onClick={onCancel} className="cancel p-button-text" />
                    <Button label={btn2} onClick={onAccept} autoFocus className="accept p-button-text" />
                </div>
            );
        }
    }

    render() {
        let { header, onHide, width = "50vw", visible, children, className } = this.props;

        return (
            <div className={`custom-dialog ${className || ""}`}>
                <Dialog header={this.renderHeader()} visible={visible || false} style={{ width }} 
                    footer={this.renderFooter()} onHide={onHide}>
                    {children}
                </Dialog>
            </div>
        )
    }
}

export default CustomDialogTwo;