import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from '../../../components/CustomInputText';
import eMandiCash from '../../../assets/images/eMandi Cash.svg'

// import './styles.scss';
import './PopUp.scss';


import CustomButton from "../../../components/CustomButton";
import { fireAjaxRequest } from '../../../utils/Utility';
import { SET_CONTACTUS_API } from '../../../utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from '../../../components/CustomLoader';


class EmandiCashList extends Component {
constructor(props) {
  super(props)

  this.state = {
    userInfo : JSON.parse(sessionStorage.getItem("userInfo"))
  }
}
    render() {
        const { visible,onAccept, handelInputChange, formData, onSubmitBuynow, state,path,onHide } = this.props;

        return (
            <div className="popup-cls">
                <div className="emandi-icon-popUp">
                    <CustomDialog visible={visible} footer={() => { }} header={<p>Emandi Cash</p>} onHide={onHide} onCancel={onHide} width="43vw">
                                <div className="heading-cls">
                                <iframe width="520" height="315"
                                        src={path && path[0].content}
                                        allowfullscreen="allowfullscreen"
                                         mozallowfullscreen="mozallowfullscreen" 
                                         msallowfullscreen="msallowfullscreen" 
                                         oallowfullscreen="oallowfullscreen" 
                                         webkitallowfullscreen="webkitallowfullscreen">
                                    </iframe>
                                    <div className="btns-cls">
                                        <CustomButton label="Close" className="cancel" config={{ onClick: onHide }} />
                                        {/* <CustomButton label="Submit" className="send" config={{ onClick: onHidePopup }} /> */}
                                    </div>
                                </div>  
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default EmandiCashList;