import React, { Component } from 'react'
import './styles.scss';
import List from './View/List'
import { history, fireAjaxRequest } from "../../utils/Utility";
import { toast } from 'react-toastify';
import {GET_EMANDI_NEWS} from '../../utils/Api'
export class EmandinewsList extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        listData:[],
        formData:{},
        offset :0,
        limit: 20,
        count:0,
        current_page:1,
        pageNumberLimit:3,
        maxPageNumberLimit:3,
        minPageNumberLimit:0,
        options: [{ "label": "All time", "value": "" },
        { "label": "Last 15 days", "value": "15days" },
        { "label": "Last 1 Month", "value": "1Month" },
        { "label": "Last 3 Month", "value": "3Month" },
        { "label": "Last 6 Month", "value": "6Month" },
        { "label": "Last 1 Year", "value": "1Year" }]
      }
    }
    componentDidMount(){
      this.getEmandiNewslist();
    }
    hanglePageClick = (e) =>{
      this.setState({current_page : Number(e.target.id) , offset : Number(e.target.id)*this.state.limit - this.state.limit},()=>{
          this.getEmandiNewslist();
      });
  }
  handleNextPageBtn = (totalPages) =>{
      if(this.state.current_page + 1 > totalPages || this.state.count<this.state.limit )
      {
          return;
      }
      this.setState({current_page : this.state.current_page + 1 , offset : (this.state.current_page+1)*this.state.limit - this.state.limit},()=>{
          this.getEmandiNewslist();
      });
      if(this.state.current_page + 1 >this.state.maxPageNumberLimit && (this.state.current_page + 1) !== totalPages)
      {
          this.setState({maxPageNumberLimit : this.state.maxPageNumberLimit + this.state.pageNumberLimit, minPageNumberLimit : this.state.minPageNumberLimit + this.state.pageNumberLimit})
      }
      if(this.state.current_page === 1 && this.state.minPageNumberLimit >1 && this.state.maxPageNumberLimit >3)
      {
          this.setState({maxPageNumberLimit : 3, minPageNumberLimit : 0})
      }
      console.log(this.state.minPageNumberLimit, this.state.maxPageNumberLimit)
  }
  handlePrevPageBtn= () =>{
      if((this.state.current_page - 1)===0 )
      {
          return;
      }
      this.setState({current_page : this.state.current_page - 1 , offset : (this.state.current_page-1)*this.state.limit - this.state.limit},()=>{
          this.getEmandiNewslist();
      });
       if((this.state.current_page - 1)%this.state.pageNumberLimit ===0)
      {
          this.setState({maxPageNumberLimit : this.state.current_page - 1, minPageNumberLimit : this.state.current_page - 1 - this.state.pageNumberLimit})
      }
      if(this.state.current_page === Math.ceil(this.state.count/this.state.limit) && this.state.maxPageNumberLimit< this.state.current_page-1)
      {
          if(this.state.current_page%this.state.pageNumberLimit===0)
          {
              this.setState({maxPageNumberLimit : this.state.current_page - 1, minPageNumberLimit : this.state.current_page - this.state.pageNumberLimit})
          }else{
              this.setState({maxPageNumberLimit : this.state.current_page - 1 , minPageNumberLimit : this.state.current_page - (this.state.current_page%this.state.pageNumberLimit)})
          } 
      }
      console.log(this.state.minPageNumberLimit, this.state.maxPageNumberLimit)
  }
    getEmandiNewslist = () => {
      let { formData, offset, limit } = this.state;
      let newsListUrl = `${GET_EMANDI_NEWS}/get?offset=${offset}&limit=${limit}`
      if (formData.time) {
          newsListUrl = `${newsListUrl}&fromDate=${formData.time}`

      }
      (async () => {
          // this.setLoading(true);
          try {

              let apiUrl = `${newsListUrl}`;
              let { reportResponce } = this.state;
              const response = await fireAjaxRequest(`${apiUrl}`, {
                  method: 'GET',
                  headers: {
                      "Content-Type": "application/json",
                      // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                  },
              });
                // this.setLoading(false);
                if (response) {
                    reportResponce = response;
                    this.setState({ emandinewsdata: response.data , count: response.count});

                } else {

                }
            } catch (err) {
                // this.setLoading(false);
                //console.log("Failed to load trader Details !");
                toast.error(err.message || "Failed to load !");

            }
        })();
    }
    handelInputChange = (e) => {
      let { formData, currentStage } = this.state;
      formData[e.target.name] = e.target.value;
           this.setState({ formData: formData , offset:0, current_page:1, maxPageNumberLimit:3, minPageNumberLimit:0},()=>{
            this.getEmandiNewslist();
           });
           
  }
    onClickBack = () => {
        history.push('/eAuction');
    }
    onReadnewClick=(location)=>{
        history.push(`/Emandinews/${location}`)
    }
  render() {
    return (
      <List onClickBack={this.onClickBack} 
      onReadnewClick={this.onReadnewClick}
      formData={this.state.formData}
      handelInputChange={this.handelInputChange}
      state={this.state}
      hanglePageClick={this.hanglePageClick}
      handleNextPageBtn={this.handleNextPageBtn}
      handlePrevPageBtn={this.handlePrevPageBtn}
      />
    )
  }
}

export default EmandinewsList