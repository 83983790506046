import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from '../../../components/CustomInputText';

// import './styles.scss';
import './PopUp.scss';


import CustomButton from "../../../components/CustomButton";
import { fireAjaxRequest } from '../../../utils/Utility';
import { SET_CONTACTUS_API } from '../../../utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from '../../../components/CustomLoader';


class buynowPopUp extends Component {

    render() {
        const { visible, onHide, onAccept, handelInputChange, formData, onSubmitBuynow, state } = this.props;
        console.log(this.props.state.buynowPopUp,"via")
        return (
            <div className="popup-cls">
                <div className="buy-now-dialog">
                    <CustomDialog visible={visible} footer={() => { }} header={<p>Buy Now Pay Later</p>} onHide={onHide} onCancel={onHide} width="35vw">
                        <CustomLoader loading={state.loading || false}>
                            <div className="buynow-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                {/* <div className="header-cls">
                                    <div className="first-div">Buy Now Pay Later</div>
                                </div> */}
                                <div className="message-us">

                                    <div className="label-cls">Please provide the details</div>
                                    <div className="names-div">
                                        <div className="inputfield-cls">
                                            <p>NAME</p>
                                            <div className="input-cls">
                                                <CustomInputText name="buynowfullname" value={formData.buynowfullname} config={{
                                            placeholder: "Full Name",
                                            onChange: handelInputChange,
                                                }} />
                                            </div>

                                        </div>
                                        <div className="inputfield-cls">
                                            <p>ENTER MOBILE NUMBER</p>
                                            <div class="input-container">
                                                <span class="icon">+91</span>
                                                <CustomInputText name="buynownumber" value={formData.buynownumber} config={{
                                                    onChange: handelInputChange, keyfilter: "pnum", maxLength: "10",
                                                    placeholder: "Mobile Number",
                                                }} />
                                            </div>
                                            {/* <div className="input-cls">
                                                <CustomInputText name="buynownumber" value={formData.buynownumber} config={{
                                                    onChange: handelInputChange, keyfilter: "pnum", maxLength: "10"
                                                }} />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="btns-cls">
                                        <CustomButton label="Cancel" className="cancel" config={{ onClick: onHide }} />
                                        <CustomButton label="Submit" className="send" config={{ onClick: onSubmitBuynow }} />
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                        </CustomLoader>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default buynowPopUp;