/**
 * Singleton reuse functionalities
 */

/**
 * Usage -> To verify whether value is empty
 * @param val -> string | Object | array
 */
import axios from "axios";
import { GET_PERSONAL_INFO } from './Api'
import { toast } from 'react-toastify';
import { createHashHistory } from "history";
export const history = createHashHistory();
export const isValEmpty = (val) => {
    let bool = false;
    if (typeof (val) === "object") {
        if (JSON.stringify(val) === "{}" || JSON.stringify(val) === "[]") {
            bool = true;
        } else if (!val) {
            bool = true;
        } else {
            //do nothing
        }
    } else if (((typeof (val) === "string") && (!val.trim())) || (typeof (val) === "undefined")) {
        bool = true;
    } else {
        //do nothing
    }

    return bool;
};

/**
 * Usage -> use of ajax request as reusable purpose
 * @param url -> string
 * @param options -> object(contains method,headers,body,onlyJSON(true/false), ...) (Optional)
 */

 const getUseractiveStatus =async () => {
    let status = true;
    let userInfo=sessionStorage.getItem("userInfo") && JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
        const headers = {
            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            "Content-Type": "application/json"
        };
        await axios.get(`${GET_PERSONAL_INFO}/${userInfo.user.id}`, { headers })
            .then(response => {
                if (response && response.data.active) {
                }

            })
            .catch(error => {
                if (error.response && error.response.data.code === "403") {
                    toast.error("Please Login Again")
                    status=false;
                }
            });
            return status;
    }
    else {
        return true
    }

};

export const fireAjaxRequest = async (url, options) => {
    let status =await getUseractiveStatus();
    // console.log(status,"status")
    if (status) {
        if (url) {
            return fetch(url, options)
                .then(async (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }

                    let res = await parseJSON(response) || {};
                    const error = new Error(res.FailResponse && res.FailResponse.Message || "");

                    throw res

                    // const error = new Error(res.statusText);
                    // console.log(error,"error")
                    // error.response = res;
                    // throw error;
                })
                .then((resp) => {
                    if (resp.status === 204 || resp.status === 205) {
                        return null;
                    }

                    return resp.json();
                });
        } else {
            console.log("No request URL specified");
        }
    }
    else {
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("isAdmin");
        history.push("/signin");
        window.location.reload();
    }

};

function parseJSON(response) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json()
    } else {
        return response.text()
    }
}

// async function checkStatus(response) {
//     if (response.status >= 200 && response.status < 300) {
//         return response;
//     }
//     let res = await parseJSON(response) || {};
//     const error = new Error(res.FailResponse && res.FailResponse.Message || "");

//     throw error;
// }

// export const fireAjaxRequests = (url, options) => {
//     fetch(url, options)
//     .then(checkStatus)
//     .then(parseJSON);       

// };

// fetch(url, _options)
//     .then(checkStatus)
//     .then(parseJSON);

export const createDropDown = (lists, key, val) => {
    if (lists && lists.length) {
        return lists.map((ele) => { return { "label": ele[key], "value": ele[val] }; });
    }
};
export const convertKYCDate = (str, isTime, isMonthName) => {
    const date = new Date(str);
    const twoLevelDigit = (val) => {
        return ("0" + val).slice(-2);
    };
    let splitDate;
    console.log(str, 'strDate');
    if (str.includes("-")) {
        splitDate = str && str.split("-")
    } else {
        splitDate = str && str.split("/")
    }

    if (splitDate[0].length === 4) {
        let tempDate = splitDate[0];
        splitDate[0] = splitDate[2];
        splitDate[2] = tempDate;
    }

    console.log(splitDate, "splitDate");

    if (isTime === "yymmdd") {
        if (splitDate) {
            if (splitDate.includes("undefined")) {
                console.log("Yesssss")
            }
            return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
        }
    }
}
export const convertDate = (str, isTime, isMonthName) => {
    const date = new Date(str);
    const twoLevelDigit = (val) => {
        return ("0" + val).slice(-2);
    };

    const month = (isMonthName) ?
        [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ][date.getMonth()] : twoLevelDigit(date.getMonth() + 1);
    const day = twoLevelDigit(date.getDate());

    if (isTime && isTime !== "DateTime" && isTime !== "standard" && isTime !== "dayFirst" && isTime !== "kycFormat" && isTime !== "yymmdd") {
        return `${[day, month, date.getFullYear().toString().substring(2)].join("/")} ${[twoLevelDigit(((date.getHours() >= 12) ? (date.getHours() - 12) : date.getHours())), twoLevelDigit(date.getMinutes())].join(":")} ${((date.getHours() >= 12) ? "PM" : "AM")}`;
    }
    else if (isTime === "DateTime" && isTime !== "standard" && isTime !== "dayFirst" && isTime !== "kycFormat" && isTime !== "yymmdd") {
        return `${[day, month, date.getFullYear()].join("-")} ${[twoLevelDigit(((date.getHours() >= 12) ? (date.getHours() - 12) : date.getHours())), twoLevelDigit(date.getMinutes())].join(":")} ${((date.getHours() >= 12) ? "PM" : "AM")}`;
    } else if (isTime === "standard") {
        return [date.getFullYear(), month, day];
    } else if (isTime === "dayFirst") {
        return `${day}/${month}/${date.getFullYear()}`
    }
    else if (isTime === "kycFormat") {
        return `${date.getFullYear()}-${day}-${month}`
    }
    else if (isTime === "yymmdd") {
        return `${date.getFullYear()}-${month}-${day}`
    }
    else {
        return [day, month, date.getFullYear()].join("/");
    }
};

export const downloadURI = (res, filename, contentType) => {
    var blob = new Blob([res.data], { type: (contentType || res.headers['content-type']) });

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

export const docHeightGetAdjustment = (reductVal) => {
    const innerHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);

    return (reductVal) ? (innerHeight - reductVal) : innerHeight;
};

export const getChipList = (data, name) => {
    return ((data && data.length && data.map((element) => {
        return element[name];
    })) || []);
};

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//////////////////////////////////////////////////////////////////////////////////////


