import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';

//@primereact component
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

//@custom components
import CustomLoader from "../CustomLoader";

//@custom styles
import "./style.scss";

//@class component
class CustomTable extends Component {

    state = {
        formData: {
        },
    }

    onStatusFilterChange = (e,fieldName) =>{
        this.dt.filter(e.value, fieldName , 'equals');
        let {formData} = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData:formData  });
    }

    filterElement = (element) =>{
        if(element.renderFilter && element.renderFilter.type === "dropDown"){
            console.log(this.state.formData[element.renderFilter.name])
            return (
                <div className="table-filter-dropdown">
                    <Dropdown options={element.renderFilter.options} name={element.renderFilter.name} value={this.state.formData[element.renderFilter.name]} onChange={(e)=>this.onStatusFilterChange(e,element.renderFilter.fieldName)} placeholder="Select a Status" className="p-column-filter"/>
                </div>
            );
        }
        
    }

    render() {
        const { data, limit, config = {}, columns, loading, paginator, totalCount, className,selectionMode="multiple" } = this.props;

        config.resizableColumns = true;
        config.columnResizeMode = "fit";

        let dynamicColumns = columns && columns.map((element, index) => {
            if(element.renderer) {
                return <Column key={index} field={element.field} header={element.header || ""} style={{ width: element.width }} body={element.renderer} filter = {element.filter} sortable = {element.sortable} filterElement={this.filterElement(element)}/>;
            } if (element.field) {
                return <Column key={index} field={element.field} header={element.header || ""} style={{ width: element.width }} filter = {element.filter} sortable = {element.sortable} filterElement={this.filterElement(element)}/>;
            } else if (element.selectionMode) {
                return <Column key={index} selectionMode={selectionMode} style={{ width: '3em' }} />
            } else {
                return "";
            }
        });

        return (
            <CustomLoader loading={loading || false}>
                <div className={`${className || ""} custom-table`}>
                    <DataTable ref={(el) => this.dt = el} value={data} paginator={paginator || false} paginatorLeft={this.props.locales.locales.itemsPerPage} rows={limit} rowsPerPageOptions={[10, 25, 50]} emptyMessage="No result found." scrollable={true} totalRecords={totalCount || 0} {...config}>
                        {dynamicColumns}
                    </DataTable>
                </div>
            </CustomLoader>
        );
    }
}

//@for required/optional props
CustomTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array
};


const mapStateToProps=(state)=>{
    return{
        locales:state.AppReducer
    }
}

export default connect(mapStateToProps)(CustomTable);