import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import './buyer.css';
import Loader from "../../components/CustomLoader";
import { BUYER_UNDER_WRITING_MAIL, SEND_EMAIL_OTP } from '../../utils/Api';
import { toast } from 'react-toastify';
import { fireAjaxRequest } from '../../utils/Utility';
import { BUYER_UNDER_WRITING, INSURANCE_LIMIT_API } from '../../utils/Api';

var list = [];
var yx = [];
// var relationshipWithBuyer = '';
let id;
var name, email, phoneNo, pan, cinLLP, gstn, address, projectedSales, atpSegment, scoreDecils, existingLimit, creditPeriod, invoiceCurrency, relationshipWithBuyer, contactPerson, creditLimit, legalStatusOfBuyer = '',financialYear='';
var content = '';
export default function ModelForm({ isLoading }) {
    const userName =JSON.parse(sessionStorage.getItem("userInfo")).user.name
    const [dataFetched, setDataFetched] = useState([]);
    // const [timeStamp, setTime]= useState('');
    const [page, setPage] = useState(true);
    const [Item, setItem] = useState([]);
    const [erpRefID, setERPRefId] = useState(0);
    // const [isLoading, setLoader] = useState(false);
    const history = useHistory();
    const changeHandler = (e) => {
        // setData({ ...data, [e.target.name]: [e.target.value] });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        history.push("/newbuyerdata");
    }
    let buyerId = window.location.href;
    buyerId = buyerId.slice(buyerId.lastIndexOf('/') + 1);
    // console.log(buyerId, "userId")

    const erpChangeHandler=(e)=>{
        setERPRefId(e.target.value)
    }

    const sendToInsurerHandled = (e) => {
        if(erpRefID==0)
        {
            toast.error("Please enter ERP ref ID");
            document.getElementById("myModal").style.display = "block";
            return false
        }

        document.getElementById("myModal").style.display = "none";
        var buyerName = document.getElementById("buyername").value;
        var buyerNameEmail = buyerName.replace("&", "and");
        var pan = document.getElementById("panNumber").value;
        var cin = document.getElementById("cin").value;
        var amount = document.getElementById("amount").value; // credit limit
        var cL = Math.round(amount)
        var contact_number = document.getElementById("contactNumber").value;
        var buyerAddress = document.getElementById("buyerAddress").value;

        buyerAddress = buyerAddress.replace("&", "and");
        buyerAddress = buyerAddress.replace("#", " ");
        buyerAddress = buyerAddress.replace("!", " ");
        buyerAddress = buyerAddress.replace("%", " ");
        buyerAddress = buyerAddress.replace("$", " ");
        buyerAddress = buyerAddress.replace("*", " ");
        buyerAddress = buyerAddress.replace("^", " ");
        buyerAddress = buyerAddress.replace("~", " ");

        var buyerStatus = document.getElementById("buyerStatus").value;
        var email = document.getElementById("email").value;
        var gst = document.getElementById("gstNo").value;
        var limit = document.getElementById("amount").value; //existing limit
        var projected_sales = document.getElementById("sales").value;
        var currency = document.getElementById("currency").value;
        var payment_terms = document.getElementById("payment").value;
        var date = document.getElementById("date").value;
        var contact_person = document.getElementById("contact_person").value;
        var atpSeg = document.getElementById("atpSegment").value;
        var score = document.getElementById("score").value;
        var financialYear=document.getElementById("financialYear").value;
        var dt=new Date();
        var dateTime=String(dt.getDate()).padStart(2,0)+"/"+String((dt.getMonth()+1)).padStart(2,0)+"/"+dt.getFullYear()+" "+String(dt.getHours()).padStart(2,0)+":"+String(dt.getMinutes()).padStart(2,0)+":"+String(dt.getSeconds()).padStart(2,0)+":"+dt.getMilliseconds();
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();


        today = dd + '/' + mm + '/' + yyyy;
        content = "Dear Team,<br/>";
        content += "Please find the Buyer Application as on " + today + " - Origo Commodities India Pvt Ltd.<br/>";
        content += "<table border='1'>";
        content += "<tr><th>Sr. No</th><th>Buyer Name</th><th>Address of the Buyer</th><th>Legal Status of Buyer</th><th>Projected Sales</th><th>Existing Limit</th><th>Buyer Limit Requested</th><th>Invoice Currency</th><th>Payment Terms</th><th>Relationshiop with the buyer</th><th>GST</th><th>Contact Person</th><th>Financial Year</th><th>Entered By</th><th>DateTime</th></tr>";
        content += "<tr>";
        content += "<td>1</td>"
        // content += "<td>" + buyerName + "</td>";
        // content += "<td>" + buyerAddress + "</td>";
        // content += "<td>" + buyerStatus + "</td>";
        // content += "<td>" + projected_sales + "</td>";
        // content += "<td>0</td>";
        // content += "<td>" + limit + "</td>";
        // content += "<td>" + currency + "</td>";
        // content += "<td>" + payment_terms + "</td>";
        // content += "<td>" + date + "</td>";
        // content += "<td>" + gst + "</td>";
        // content += "<td>" + contact_person + "</td>";
        content += "<td>" + (buyerNameEmail != '' ? buyerNameEmail : "-") + "</td>";
        content += "<td>" + (buyerAddress != '' ? buyerAddress : "-") + "</td>";
        content += "<td>" + (buyerStatus != '' ? buyerStatus : "-") + "</td>";
        content += "<td>" + (projected_sales != null ? projected_sales : "-") + "</td>";
        content += "<td>0</td>";
        content += "<td>" + (limit != null ? limit : "-") + "</td>";
        content += "<td>" + (currency != '' ? currency : "-") + "</td>";
        content += "<td>" + (payment_terms != null ? payment_terms : "-") + "</td>";
        content += "<td>" + date + "</td>";
        content += "<td>" + (gst != '' ? gst : "-") + "</td>";
        content += "<td>" + (contact_person != '' ? contact_person : "-") + "</td>";
        content += "<td>" + financialYear + "</td>";
        content += "<td>" + userName + "</td>";
        content += "<td>" + dateTime +"</td>";
        content += "</tr>";
        content += "</table>";
        content += "</br>Regards<br/>";
        content += "Origo-Emandi";
        console.log(content);


        var receiptList = [];
        // receiptList.push('abid.sefi@credextechnology.com');
        //receiptList.push('nikhil.verma@credextechnology.com');
        // receiptList.push('abid.sefi@credextechnology.com');

        const { location: { origin  } } = window;
        if(origin==="https://www.origoemandi.com"){
            receiptList.push('samit.mehta@prudentbrokers.com');
            receiptList.push('prakash.singh@prudentbrokers.com');
            receiptList.push('chinkita.gaur@prudentbrokers.com');

            receiptList.push(' martin.balasagar@origofin.com');
            receiptList.push('risk@origoindia.com');
            receiptList.push('suni.chandran@origoindia.com'); 
        }else{
            receiptList.push('abid.sefi@credextechnology.com');
            receiptList.push('subhajit.saha@origoindia.com');
            receiptList.push('amitkumar.sharma@origoindia.com');
            receiptList.push('garima.pargain@origoindia.com');
        }
        //call post api

        fetch(BUYER_UNDER_WRITING, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                "Content-Type": "application/json",
                'Accept': 'application/json',
            },
        }).then(resp =>
            resp.json()).then(res => {
                // console.log(res);
                // JSON.parse(JSON.stringify(result.items))
                let x = JSON.parse(JSON.stringify(res.data));
                // console.log("data",x);
                setDataFetched(x);
                // console.log(pan);
                var X = [];
                X = x.filter((d) => {
                    return (d ? pan == d.pan : 'no data found')
                }
                )
                yx.push(X);
                // console.log(X);
                if (X == '') {

                    // toast.success("success");

                    const data = {
                        "buyerName": buyerName,
                        "pan": pan,
                        "cinLLP": cin,
                        "emailId": email,
                        "contactNumber": contact_number,
                        "creditLimit": amount,
                        "buyersAddress": buyerAddress,
                        "legalStatusOfBuyer": buyerStatus,
                        "gstNo": gst,
                        "projectedSales": projected_sales,
                        "existingLimit": limit,
                        "creditPeriod": payment_terms,
                        "invoiceCurrency": currency,
                        "scoreDecils": score,
                        "atpSegment": atpSeg,
                        "relationshipWithBuyer": "NA",
                        "contactPerson": contact_person,
                        "financial_year": financialYear,
                        "userName": userName,
                        "dateTime": dateTime,
                        "status": "Sent to Orgzit"
                    }
                    console.log(data);
                    fetch(BUYER_UNDER_WRITING, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json",
                            'Accept': 'application/json',
                        },
                    }).then(resp =>
                        resp.json()).then(res => {
                            console.log(res);
                            // JSON.parse(JSON.stringify(result.items))
                            // let x=JSON.parse(JSON.stringify(res.data));
                            // console.log("data",x);
                            // setItem(x);
                            buyer_01();
                        })
                    try {
                        var response = [];
                        for (var i = 0; i < receiptList.length; i++) {
                            const resp = fireAjaxRequest(BUYER_UNDER_WRITING_MAIL+'?email=' + receiptList[i] + '&subject=Origo Emandi Buyer Underwriting&body=' + content, {
                                method: 'GET',
                                // body: JSON.stringify(sendemailBody),
                                headers: {
                                    "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                                    "Content-Type": "application/json"
                                }
                            });
                            response = resp
                            // if (resp) {
                            //     //this.setState({emailtotp:resp.otp,showemailediticon:true,showotpfields:true,disableemailfield:true,showsendotpbtn:false});
                            //     toast.success('Mail sent Successfully');
                            // }
                        }
                        if (response) {
                            toast.success('Mail sent Successfully');
                        }
                    }
                    catch (err) {

                        toast.error('Failed to Send Mail');
                    }
                }
                else {
                    toast.info(`Record already available for PAN - ${pan}`);
                }

            })


        function buyer_01() {

            const buyerData_01 = {
                "dataform": "/api/1/dataform/mi8ga50exi/",
                "dataform_id": "mi8ga50exi",
                "fields": {
                    "name": buyerName,
                    "pan": pan,
                    "email_id": email,
                    "insurance_limit": 0,
                    "approval_credit_limit": cL,
                    "erp_reference_id": erpRefID,
                    "is_active": "Yes"
                },
                "use_field_slug": true
            }
            

            fetch(INSURANCE_LIMIT_API, {
                method: 'POST',
                body: JSON.stringify(buyerData_01),
                headers: {
                    'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
                    "Content-Type": "application/json"
                },
            }).then(resp =>
                resp.json()).then(res => {
                    // console.log(res);
                    try {
                        var x = res.id;
                        // setEncUserId(x)
                        // console.log(encUserId.id);
                        buyerData_02(x);

                    }
                    catch (e) {
                        var error = JSON.parse(JSON.stringify(res.errors[0]));
                        console.log(error.error);
                        if (error.error.includes("PAN")) {

                            // alert();
                            toast.error(`Record with Pan - ${pan} already available in orgzit`)
                            setPage(false);
                        }
                        else {
                            toast.error(error.error);
                        }
                    }
                })
        }

        function buyerData_02(id) {

            // setLoading(true);
            // alert();
            // id.map((item) => {
            //     id = item.id;
            // })        
            const dataBody =
            {
                "dataform": "/api/1/dataform/hi5i5sqmc8/",
                "dataform_id": "hi5i5sqmc8",
                "dbvalues": {
                    "buyer": id,
                    "origo_entity": "de3yizolf6"
                },
                "fields": {
                    "contract_signed": "No",
                    "insurance_limit": 0,
                    "credit_limit_tolerance": 0,
                    "approval_credit_limit": cL
                },
                "use_field_slug": true

            }
            // console.log(dataBody);
            fetch(INSURANCE_LIMIT_API, {
                method: 'POST',
                body: JSON.stringify(dataBody),
                headers: {
                    'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
                    "Content-Type": "application/json"
                },
            }).then(resp =>
                resp.json()).then(res => {
                    // console.log(res);
                    // var x = res.id;
                    BuyerGst_03(id);
                })


        }
        function BuyerGst_03(id) {

            const dataBody =
            {
                "dataform": "/api/1/dataform/avd0gexlq2/",
                "dataform_id": "avd0gexlq2",
                "dbvalues": {
                    "buyer": id,
                    "state": "lk1byyu1s9"
                },
                "fields": {
                    "gstn": gst,
                    "address": buyerAddress
                },
                "use_field_slug": true

            }
            // console.log(dataBody);
            fetch(INSURANCE_LIMIT_API, {
                method: 'POST',
                body: JSON.stringify(dataBody),
                headers: {
                    'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
                    "Content-Type": "application/json"
                },
            }).then(resp =>
                resp.json()).then(res => {
                    // console.log(res);
                    toast.success("Buyer Details Sent to Orgzit.")
                    statusUpdate();
                })


        }
       
        function statusUpdate() {

            fetch(BUYER_UNDER_WRITING, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                },
            }).then(resp =>
                resp.json()).then(res => {
                    console.log(res);
                    let x = JSON.parse(JSON.stringify(res.data));
                    setItem(x);
                    var t = Item.filter((item) =>

                        item ? pan == item.pan : "no data found"


                    )

                    if (t == '') {

                    } else {
                        t.map((item) => {
                            name = item.buyerName;
                            email = item.emailId;
                            phoneNo = item.contactNumber;
                            pan = item.pan;
                            cinLLP = item.cinLLP;
                            gstn = item.gstNo;
                            address = item.buyersAddress;
                            projectedSales = item.projectedSales;
                            existingLimit = item.existingLimit;
                            creditPeriod = item.creditPeriod;
                            invoiceCurrency = item.invoiceCurrency;
                            relationshipWithBuyer = item.relationshipWithBuyer;
                            contactPerson = item.contactPerson;
                            creditLimit = item.creditLimit;
                            legalStatusOfBuyer = item.legalStatusOfBuyer;
                            atpSegment = item.atpSegment;
                            scoreDecils = item.scoreDecils;
                            financialYear = item.financial_year;
                            id = item.id;
                            
                        })
                        const data = {
                            "id": id,
                            "buyerName": name,
                            "emailId": email,
                            "contactNumber": phoneNo,
                            "creditLimit": creditLimit,
                            "buyersAddress": address,
                            "legalStatusOfBuyer": legalStatusOfBuyer,
                            "cinLLP": cinLLP,
                            "pan": pan,
                            "gstNo": gstn,
                            "projectedSales": projectedSales,
                            "existingLimit": existingLimit,
                            "creditPeriod": creditPeriod,
                            "invoiceCurrency": invoiceCurrency,
                            "relationshipWithBuyer": relationshipWithBuyer,
                            "contactPerson": contactPerson,
                            "scoreDecils": scoreDecils,
                            "atpSegment": atpSegment,
                            "financial_year": financialYear,
                            "userName": userName,
                            "dateTime": dateTime,
                            "status": "SENT TO ORGZIT"
                        }
                        console.log(data);
                        fetch(BUYER_UNDER_WRITING, {
                            method: "PUT",
                            body: JSON.stringify(data),
                            headers: {
                                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                                "Content-Type": "application/json",
                                'Accept': 'application/json',
                            },
                        }).then(resp =>
                            resp.json()).then(res => {
                                // console.log(res);
                            })

                    }
                })
        }

    }
    return (
        <Loader loading={isLoading}>
            <div>
                {/* <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
                <form style={{ maxWidth: "1250px" }}>
                    <div className='row py-1 mb-2'>
                        <div className='col-md-6'>
                            <label for="buyername" className="form-label">Buyer Name</label>
                            <input type="text" disabled className="form-control" placeholder='Buyer Name' id="buyername"
                                name='buyername' onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="buyername" className="form-label">PAN Number</label>
                            <input type="text" disabled className="form-control" placeholder='PAN Number' id="panNumber"
                                name='buyername' onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="cin/LLP" className="form-label">cin/LLP</label>
                            <input type="text" disabled className="form-control" placeholder='CPIN/LLP' id="cin"
                                name='buyername' onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="amount" className="form-label">Credit Limit (Buyer Limit Request)</label>
                            <input type="text" disabled className="form-control" placeholder='Rs.75,00,000' id="amount"
                                name='creditLimit' onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-6'>
                            <label for="contactNumber" className="form-label">Contact Number</label>
                            <input type="text" className="form-control" placeholder='+910000000000' id="contactNumber"
                                name='contactNumber' disabled onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="buyerAddress" className="form-label">Buyer Address</label>
                            <input type="text" className="form-control" placeholder='Enter Address' id="buyerAddress"
                                name='buyerAddress' disabled onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-12'>
                            <label for="buyerStatus" className="form-label">Legal Status of buyer</label>
                            <input type="text" className="form-control" placeholder='Public Limited Indian Non-Government Company' id="buyerStatus"
                                name='status' disabled onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-6'>
                            <label for="email" className="form-label">Email Id</label>
                            <input type="email" className="form-control" placeholder='buyer@gmail.com' id="email"
                                name='email' disabled onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="gstNo" className="form-label">GST NO</label>
                            <input type="text" className="form-control" placeholder='ACVSDW342' id="gstNo"
                                name='gstNo' disabled onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-6'>
                            <label for="sales" className="form-label">Projected sales (during next 12 month)</label>
                            <input type="text" className="form-control" id="sales"
                                name='projectedSales' disabled onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="limit" className="form-label">Existing Limit</label>
                            <input type="text" className="form-control" placeholder='Rs. 75,00,000' id="limit"
                                name='existingLimit' value="0" disabled onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-6'>
                            <label for="payment" className="form-label">Credit Period / Payment Terms (Days)</label>
                            <input type="text" disabled className="form-control" value="60" id="payment"
                                name='paymentTerms' onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="currency" className="form-label">Invoice Currency</label>
                            <input type="text" disabled className="form-control" id="currency"
                                name='invoiceCurrency' value="INR" onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-6'>
                            <label for="payment" className="form-label">ATP Segment</label>
                            <input type="text" disabled className="form-control" id="atpSegment"
                                name='paymentTerms' onChange={changeHandler}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label for="currency" className="form-label">Score Decils</label>
                            <input type="text" disabled className="form-control" id="score"
                                name='invoiceCurrency' onChange={changeHandler}
                            />
                        </div>
                    </div>
                    <div className='row p-1 mb-2'>
                        <div className='col-md-6 '>
                            <label for="relation" className="form-label">Relationship with the Buyer (Month / Year)</label>
                            {/* <input type="text" className="form-control datepicker" data-date-format="mm/dd/yyyy" placeholder='Select Date' onfocus="(this.type='date')" onblur="(this.type='text')" id="relation" /> */}
                            <div class="md-form md-outline input-with-post-icon datepicker">
                                <input placeholder="Select date" disabled type="text" value="NA" id="date" class="form-control"
                                    name='relationWithBuyer' onChange={changeHandler}
                                />

                            </div>
                        </div>
                        <div className='col-md-6'>
                            <label for="relation" className="form-label">Contact Person</label>
                            {/* <input type="text" className="form-control datepicker" data-date-format="mm/dd/yyyy" placeholder='Select Date' onfocus="(this.type='date')" onblur="(this.type='text')" id="relation" /> */}
                            <div class="md-form md-outline input-with-post-icon datepicker">
                                <input type="text" id="contact_person" class="form-control"
                                    name='contact_person' disabled onChange={changeHandler}
                                />

                            </div>
                        </div>


                        <div className='col-md-6'>
                            <label for="relation" className="form-label">ERP Reference ID</label>
                            {/* <input type="text" className="form-control datepicker" data-date-format="mm/dd/yyyy" placeholder='Select Date' onfocus="(this.type='date')" onblur="(this.type='text')" id="relation" /> */}
                            <div class="md-form md-outline input-with-post-icon">
                                <input type="text" id="erpRefId" class="form-control"
                                    name='erpRefId'  onChange={erpChangeHandler}
                                />

                            </div>
                        </div>

                        <div className='col-md-6'>
                            <label for="relation" className="form-label">Financial Year</label>
                            {/* <input type="text" className="form-control datepicker" data-date-format="mm/dd/yyyy" placeholder='Select Date' onfocus="(this.type='date')" onblur="(this.type='text')" id="relation" /> */}
                            <div class="md-form md-outline input-with-post-icon">
                                <input type="text" id="financialYear" disabled class="form-control disabled"
                                    name='financialYear'  
                                />

                            </div>
                        </div>
                    </div>

                    <div className='row p-1 mb-2'>
                        <div className='col-md-7 '>

                        </div>
                        <div className='col-md-5'>
                            <button type="button" className=" btn btn-success mt-4 px-5" data-bs-dismiss="modal" onClick={sendToInsurerHandled} >Send Details to Insurer</button>
                        </div>
                    </div>
                </form>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
                    integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous" />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
                <title>Origo e-Mandi</title>
                <script src=
                    "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.0/sweetalert.min.js">
                </script>
                <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
                />
            </div>
        </Loader>
    )
}
