import React, { Component } from 'react'
import List from './view/list.js';
import axios from 'axios';
import "./styles.scss";
import { history,fireAjaxRequest } from "../../utils/Utility";
import { CASHANDCARRY_ARBITAGE,GET_CASHANDCARRY_ARBITAGE_RECORDS,} from '../../utils/Api'
import { toast } from 'react-toastify';

class index extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        cashandcarryList:[],
        cashandCarryPopUp: false,
        formData:{},
        sucessDailog:false
      };
    }
    componentDidMount(){
        axios.get(GET_CASHANDCARRY_ARBITAGE_RECORDS, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                this.setState({ cashandcarryList: response.data.data.records })
            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
            // toast.error(error.response.data.message);
        })
    }   
    onClickBack=()=>{
        history.push('/homePage');
    
    } 
    hideCashandCarryPopUp = () => {
        let {formData}=this.state;
        formData.cashandcarryfullname="";
        formData.cashandcarrynumber="";
        formData.financeneeded="";
        this.setState({ cashandCarryPopUp: false,formData })

    }
    onSubmitcashandcarryPopUp = () => {
        let { formData } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo) {
            formData.cashandcarryfullname = userInfo.user.name || "Trader";
            formData.cashandcarrynumber = userInfo.user.phoneNo
        }
        if (!formData.cashandcarryfullname) {
            toast.error("Please enter Name")
        }
        else if (!formData.cashandcarrynumber) {
            toast.error("Please enter Mobile Number")
        }
        else if (formData.cashandcarrynumber.length < 10) {
            toast.error("Phone Number should contain 10 digits")
        }
        else if (!formData.financeneeded) {
            toast.error("Please select Finance needed")
        }
        else {
            const sendBody = {
                "commodityName": formData.arbitageCommodityName,
                "rateOfReturn": formData.arbitagerateOfreturn,
                "lockInPeriod": formData.arbitagelockInperiod,
                "name": formData.cashandcarryfullname,
                "mobileNo": formData.cashandcarrynumber,
                "financeNeeded": formData.financeneeded == "Yes" ? true : false

            };
            (async () => {
                // this.setState({ loading: true });
                try {
                    const resp = await fireAjaxRequest(CASHANDCARRY_ARBITAGE, {
                        method: 'POST',
                        body: JSON.stringify(sendBody),
                        headers: {
                            "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : "",
                            "Content-Type": "application/json"
                        }
                    });
                    // this.setState({ loading: false });
                    if (resp && resp.data) {
                        formData.dailogContend = [`Your request has been sent successfully and your request id is ${resp && resp.data.requestId}.You will receive a call shortly!`]
                        this.setState({ sucessDailog: true, cashandCarryPopUp: false, requestId: resp && resp.data.requestId })

                    }
                } catch (err) {
                    // this.setState({ loading: false });
                }
            })();


        }
    }
    handelInputChange = (e) => {
        let { formData } = this.state;
        let alphabetOnly = ["commidityname", "location", "fullname", "buynowfullname"];
        if (e.target.type == "checkbox") {
            formData.financeneeded = e.target.value
            this.setState({ formData: formData })
        }
        alphabetOnly.map((element) => {
            if (e.target.name === element) {
                e.target.value = e.target.value.replace(/[^a-z A-Z]+/g, "");
            }
        })

        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });

        if (e.target.name === "auctionType" || e.target.name === "commodityName" || e.target.name === "locationName") {
            this.setState({activeAuctionList:[],scheduledAuctionList:[]})
            this.onAuctionFilterChange(formData);
        }


    }
    onclickAvailnow = (e) => {
        let { formData, setAlertAuctionId } = this.state;
        formData.arbitageCommodityName = e.commodityName;
        formData.arbitagelockInperiod = e.lockInPeriod;
        formData.arbitagerateOfreturn = e.rateOfReturn;
        this.setState({ cashandCarryPopUp: true, formData: formData })
    }
    hideConfirmPopUp = () => {
        this.setState({sucessDailog:false})
	}
  render() {
    return (
      <List state={this.state} onClickBack={this.onClickBack} hideCashandCarryPopUp={this.hideCashandCarryPopUp}
      cashandCarryPopUp={this.state.cashandCarryPopUp} onSubmitcashandcarryPopUp={this.onSubmitcashandcarryPopUp}
      handelInputChange={this.handelInputChange} onclickAvailnow={this.onclickAvailnow} formData={this.state.formData}
      hideConfirmPopUp={this.hideConfirmPopUp}
      />
    )
  }
}

export default index