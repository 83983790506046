import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import CustomButton from "../../../components/CustomButton";
import CustomLoader from "../../../components/CustomLoader";
import { GET_EMANDI_TV_ID, GET_EMANDI_TV_LIST } from "../../../utils/Api";
import { fireAjaxRequest } from "../../../utils/Utility";

export default function ShowSingleVideos({ path }) {
  const [content, setContent] = useState("");
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    getEmandiTVlist();
  }, []);

  console.log("****?", Number(pathname.split("/")[2]));

  const getEmandiTVlist = () => {
    let emandiURL = `${GET_EMANDI_TV_LIST}/getById?tvId=${Number(
      pathname.split("/")[2]
    )}`;

    (async () => {
      try {
        let apiUrl = `${emandiURL}`;
        const response = await fireAjaxRequest(`${apiUrl}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
          },
        });
        if (response) {
          console.log("--->", response);
          setContent(response);
        } else {
        }
      } catch (err) {
        toast.error(err.message || "Failed to load !");
      }
    })();
  };

  const handlebackButton = () => {
    history.push("/EmandiTV");
  };

  return (
    <>
      <div className="emandi-tv-cls">
        <i className="pi pi-angle-left" onClick={handlebackButton}>
          <span>Back</span>
        </i>
        <CustomLoader>
          <div className="buynow-cls">
            <div className="message-us">
              <div className="label-cls"></div>
              <p className="heading">
                {content.tittle} <span>{content.uploadedOn}</span>
              </p>
              <iframe width="600" height="315" src={content.content}></iframe>
              <div className="btns-cls"></div>
            </div>{" "}
          </div>
        </CustomLoader>
      </div>
    </>
  );
}
