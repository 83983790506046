import React, { Component } from 'react';

import "./style.scss";

import { InputText } from 'primereact/inputtext';

class CustomInputOTB extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ["","","",""],
        };
    }

    onChange = (e,index) =>{
        let { value } = this.state;

        value[index] = e.target.value.charAt(e.target.value.length - 1);
        let selected = document.getElementsByClassName("OTP-class");
        if (index<=2 && e.target.value){
            selected[index + 1].focus();
        }else{
            value[index] = e.target.value.charAt(e.target.value.length-1);
        }
        // this.setState({ value: value  });
        
        if (this.props.handleOTPChange){
            this.props.handleOTPChange(value, e, index)
        }
    }

    onKeyUp = (e, index) =>{

        if (e.keyCode === 8 && index>0){
            let selected = document.getElementsByClassName("OTP-class");
            selected[index - 1].focus();
        }
        // if(this.props.value){
        //     console.log("Enter ",this.props.value)
        // }
        console.log("Enter ",this.props.value)
    }

    render() {

        let { className = "", required, label,onChangeOTP,onKeyUp,disableOTP,formId=null} = this.props;
        return (
            <div className={`inputOTP-content-section ${className}`}>
                {this.props.value && this.props.value.map((element,index)=>{
                    return(<React.Fragment>
                        <InputText value={element} disabled={disableOTP?disableOTP:false} style={{backgroundColor:disableOTP?"#e4e4e4":"#fff"}} onChange={(e) => onChangeOTP(e, index)} onKeyUp={(e) => onKeyUp(e, index,formId)} className={"OTP-class"} keyfilter="int"/>
                    </React.Fragment>)
                })}
            </div>
        )
    }
}

export default CustomInputOTB;
