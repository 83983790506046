import React, { Component } from 'react';
import tickerData from "../../../containers/LandingPage/view/tickerData.json";
import Marque from "react-fast-marquee";
import "../../../containers/LandingPage/view/ticker.scss";
import axios from "axios";
import { SPOT_TICKER, MCX, NCDEX } from "../../../utils/Api";
import { RadioButton } from 'primereact/radiobutton';
import { numberWithCommas } from '../../../utils/Utility';
// import infoIcon from "../../../assets/icons';
// import infoIcon from "../../../assets/icons/info_icon';
import { Client } from '@stomp/stompjs';
import './Marquee.scss';
import info from "../../../assets/icons/info_icon.png"
import CallUsPopup from "../../../containers/HomeNew/callUs";



class Marquee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: true,
            direction: "left",
            spotInfo: [],
            type: "S",
            serverTime: [],
            mcx: [],
            ncdex: [],
        }
    }

    componentDidMount() {

        this.getSpotInfo();
        // this.getDerivativesData();
        this.getAllData();
        this.onloadData();

    }
    onloadData = async () => {
        try {
            const resp = await axios.get(MCX);
            // console.log(resp.data);
            if (resp) {
                this.setState({ mcx: resp.data });
            }
            const response = await axios.get(NCDEX);
            // console.log(response.data);
            if (response) {
                this.setState({ ncdex: response.data });
            }
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    getAllData = () => {
        // console.log('Component did mount');
        this.client = new Client();
        this.client.configure({
            // brokerURL: 'ws://115.247.13.130:8088/stomp',
            brokerURL: 'ws://service.origoemandi.com:8088/stomp',
            onConnect: () => {
                // console.log('onConnect');
                this.client.subscribe('/queue/mcx', message => {
                    //   console.log(message);
                    let body = JSON.parse(message.body || "[{}]");
                    this.setState({ mcx: body });
                });
                this.client.subscribe('/queue/ncdex', message => {
                    //   console.log(message);
                    let body = JSON.parse(message.body || "[{}]");
                    this.setState({ ncdex: body });
                });

            },
            // Helps during debugging, remove in production

        });

        this.client.activate();
    }
    // getDerivativesData=()=>{
    //     console.log('Component did mount');
    //     this.client = new Client();
    //     this.client.configure({
    //       brokerURL: 'ws://115.247.13.130:8088/stomp',
    //       onConnect: () => {
    //         console.log('onConnect');
    //         this.client.subscribe('/queue/now', message => {
    //           console.log(message.body);
    //           let body=JSON.parse(message.body || "[{}]");
    //           this.setState({serverTime: body});
    //         });


    //         this.client.subscribe('/topic/greetings', message => {
    //         //   alert(message.body);
    //         });
    //       },
    //       // Helps during debugging, remove in production
    //       debug: (str) => {
    //         // console.log(new Date(), str);
    //       }
    //     });

    //     this.client.activate();
    // }
    // clickHandler = () => {
    //     this.client.publish({destination: '/app/greetings', body: 'Hello world'});
    //   }

    handelInputChange = (e) => {
        this.setState({ type: e.value });
    }

    getSpotInfo = () => {
        axios.get(SPOT_TICKER, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                let spotInfo = response.data.data;
                this.setState({ spotInfo: spotInfo });
                // console.log(spotInfo)
                setTimeout(function () {
                    this.getSpotInfo();
                }, 3000 * 60 * 30); //sec min no._of_min
            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
        })
    }

    render() {

        let { play, direction, spotInfo, serverTime, mcx, ncdex } = this.state;

        return (
            <>
                <div className="marqueDiv">
                    <div class="innerMarqueDiv">
                        <div class="title">
                            <h5><span>SPOT:</span> &nbsp;<span class="head-title"> eMandi</span></h5>
                        </div>
                        <div class="news">
                            {this.state.type == "D" ? <Marque gradient={false} direction={direction} play={play}>
                                {mcx && mcx.length ? mcx.map((element) => {
                                    return (
                                        <div className="ticker-warpper">
                                            <div className="ticker-header">
                                                <p className="commodity-name" style={{ marginRight: "20px" }}>{element.symbol}</p>
                                                <p className="location">{element.expiry}</p>
                                            </div>
                                            <div className="ticker-footer">
                                                <p className="source">{numberWithCommas(element.ltp)}</p>
                                                <p className={`upDown ${element.change > 0 ? "green" : "red"}`}>{element.change > 0 ? <i className="pi pi-caret-up"></i> : <i className="pi pi-caret-down"></i>}{element.change > 0 ? `+${numberWithCommas(element.change)}` : numberWithCommas(element.change)}</p>
                                            </div>
                                        </div>
                                    )
                                }) : <p className='loader'>Loading...</p>}
                            </Marque> : this.state.type == "N" ? <Marque gradient={false} direction={direction} play={play}>
                                {ncdex && ncdex.length ? ncdex.map((element) => {
                                    return (
                                        <div className="ticker-warpper">
                                            <div className="ticker-header">
                                                <p className="commodity-name" style={{ marginRight: "20px" }}>{element.symbol}</p>
                                                <p className="location">{element.expiry}</p>
                                            </div>
                                            <div className="ticker-footer">
                                                <p className="source"> {numberWithCommas(element.ltp)}</p>
                                                <p className={`upDown ${element.change > 0 ? "green" : "red"}`}>{element.change > 0 ? <i className="pi pi-caret-up"></i> : <i className="pi pi-caret-down"></i>}{element.change > 0 ? `+${numberWithCommas(element.change)}` : numberWithCommas(element.change)}</p>
                                            </div>
                                        </div>
                                    )
                                }) : <p className='loader'>Loading...</p>}
                            </Marque> : <Marque gradient={false} direction={direction} play={play}>
                                {spotInfo && spotInfo.length ? spotInfo.map((element) => {
                                    return (
                                        <div className="ticker-warpper">
                                            <div className="ticker-header">
                                                <p className="commodity-name" style={{ marginRight: "20px" }}>{element.commodityName}</p>
                                                <p className="location">{element.location}</p>
                                            </div>
                                            <div className="ticker-footer">
                                                <p className="source">{numberWithCommas(element.spotPrice.toFixed(2))}</p>
                                                <p className={`upDown ${element.upDown > 0 ? "green" : "red"}`}>{element.upDown > 0 ? <i className="pi pi-caret-up"></i> : <i className="pi pi-caret-down"></i>}{element.upDown > 0 ? `+${numberWithCommas(element.upDown)}` : numberWithCommas(element.upDown)}</p>
                                            </div>
                                        </div>
                                    )
                                }) : <p className='loader'>Loading...</p>}
                            </Marque>}

                            {/* <marquee class="news-content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                            </marquee> */}



                        </div>
                        <div class="icon">
                            <img src={info}  style={{ padding: '-2px 2.5em', height: '38px'}} className="iconI"/>
                        </div>
                    </div>
                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                </div>

                
                
            </>

        );
    }
}

export default Marquee;