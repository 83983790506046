import React, { useState,useEffect } from 'react'
import '../style/Header.css';
import 'react-bootstrap-accordion/dist/index.css';
import Documents from "./uploadDocuments";
import UploadNonGST from './upload_nonGST_Docs';
import {
  POST_GST_DATA, KYC_VERIFY_GST
} from "../../../utils/Api";
import { toast } from 'react-toastify';
import { history } from '../../../utils/Utility';
import Pan from './panNumber';


//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}
function GstNumber(props) {
  // call your hook here
  const forceUpdate = useForceUpdate();
  const [documents, setDocuments] = useState(false);
  const [gst, setGst] = useState(false);
  const [gstInput, setGstInput] = useState('');
  const [nongst, setNonGst] = useState(false);
  const [radioButton, setRadioButton] = useState("Yes");
  const [GstVerified, setGstVerified] = useState(false);
  const [vintageDate, setVintageDate] = useState('')
  const [regDate, setRegDate] = useState('');
  const [orgName, setOrgName] = useState('');
  const [addr, setAddr] = useState('');
  const [stateValue, setState] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [city, setCity] = useState('');
  const [reqId, setRequestId] = useState('');
  const [orgType, setOrgType] = useState('');
  const [Pan, setPan] = useState('');

  let panNumber = sessionStorage.getItem('panNumber');
  let renderPan = "No"
  const backHome = () => {
    history.push("/addECash");
  };
  const handleNonGST = () => {
    setRequestId(props.rId);
    setOrgType(props.oType);
    setPan(props.pan);
    setNonGst(true);
  }

  useEffect(()=>{
    document.getElementById("btnback").style.display="block";
   })

  const handleChange = (e) => {
    setRequestId(props.rId);
    setOrgType(props.oType);

    if (e.target.value.length > 14) {
      if (e.target.value.toLowerCase().includes(panNumber.toLowerCase())) {
        setGst(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(KYC_VERIFY_GST + "consent=" + "Y" + "&additionalData=" + false + "&gstin=" + e.target.value, requestOptions)
          .then(response => response.text())
          .then(result => {
            result = JSON.parse(result)
            let stateVar1 = result?.result?.stj.substring(result?.result?.stj.indexOf('-') + 2);
            let stateVar2 = stateVar1?.split(",")
            let pinVar = result?.result?.pradr?.adr?.substring(result?.result?.pradr?.adr?.length - 6)
            let cityArray = result.result.pradr.adr.split(",")
            let city = cityArray[cityArray.length - 3]
            var date1 = new Date();
            var dd = String(date1.getDate()).padStart(2, '0');
            var mm = String(date1.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date1.getFullYear();
            date1 = mm + '/' + dd + '/' + yyyy;
            var date2 = result?.result?.rgdt;
            var Difference_In_Time = new Date(date1).getTime() - new Date(date2).getTime();
            var numberOfDays = Difference_In_Time / (1000 * 3600 * 24);
            var years = Math.floor(numberOfDays / 365);
            var months = Math.floor(numberOfDays % 365 / 30);
            var days = Math.floor(numberOfDays % 365 % 30);

            let vintageD = years + " year, " + months + " month, " + days + " day";
            setVintageDate(vintageD)

            if (result.statusCode == '101') {
              setGstVerified(true);
              setGstInput(e.target.value)
              setOrgName(result.result.tradeNam)
              setAddr(result.result.pradr.adr)
              setState(stateVar2[0])
              setPinCode(pinVar)
              setCity(city)
              setRegDate(result.result.rgdt)
              forceUpdate()
            }
            else if (result.statusCode == '402') {
              toast.error("Insufficient Credits");
              setGstVerified(false);
            }
            else {
              toast.error("Something went Wrong");
              setGstVerified(false);
            }
          })
          .catch(error => {
            setGstVerified(false);
            console.log('error', error)
          }
          );
      }
      else {
        toast.error("GST and PAN doesn't matched")
      }
    }
  }

  const handleRadioButton = (e) => {
    setRadioButton(e.target.value)
  }

  const handleClickGSTNumber = () => {
    document.getElementById("enterDetailsLabel").style.color = "#04B23D";

    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    let isMsme = true;
    if (radioButton.toLowerCase() == "no") {
      isMsme = false;
    }
    else if (radioButton.toLowerCase() == "yes") {
      isMsme = true;
    }
    var raw1 = JSON.stringify({
      "traderId": sessionStorage.getItem("traderID"),
      "requestId": reqId,
      "GST": gstInput,
      "isMSMEregistered": isMsme,
      "vintage": vintageDate,
      "registrationDate": regDate,
      "organizationName": orgName,
      "address": addr,
      "city": city,
      "state": stateValue,
      "pincode": pinCode
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw1,
      redirect: 'follow'
    };

    fetch(POST_GST_DATA, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        console.log('resu-----', result)
        if (result.statusCode == 400) {
          toast.error(result.message);
        }
        if (result.statusCode == 200) {
          setDocuments(true);
        }
        else {
          //sessionStorage.setItem("requestId", requestId)
          toast.error(result.message)
        }
      }
      )
      .catch(error => console.log('error', error));
  }



  return (
    <>
      {

        nongst ? <UploadNonGST requestId={reqId} oType={orgType} /> : <>{documents === false ?
          <div className="bottom-section">

            <div className="entity-css">
              <label for="name" className='breadcrumbsLabel'>
                Entity Details
                <span
                  aria-hidden="true"
                  class="required"
                  style={{ color: '#04B23D', marginLeft: '5px' }}
                >
                  *
                </span>
              </label>
            </div>
            <div class="form-group col-xs-4 " >
              <input
                class=" input-lg input-css input-field "
                placeholder="Enter GST No."
                id="inputlg"
                type="text"
                maxLength={15}
                onChange={handleChange}
              />
            </div>
            <>
              {gstInput.length > 14 && !GstVerified ?
                <div class="breadcrumbsLabel">
                  <span> Fetching Details ... </span>
                </div>
                :
                ""
              }
            </>

            {gst ?
              <div className='breadcrumbsLabel' style={{ marginTop: '20px', fontSize: '13px' }}>
                Are you registered as an MSME?
                &nbsp;
                <i class="fa fa-info-circle info-class" style={{ marginLeft: "20px", fontSize: '5px' }} aria-hidden="true"></i>
                <div className="breadcrumbsLabel" style={{ marginTop: '10px' }}>
                  <label style={{ marginRight: '20px' }}>
                    <input type="radio" value="Yes" name='radio' checked onClick={(e) => handleRadioButton(e)} />
                    &nbsp; Yes
                  </label>
                  <label>
                    <input type="radio" value="No" name='radio' onClick={(e) => handleRadioButton(e)} />
                    &nbsp; No
                  </label>
                </div>
              </div>
              :
              <div onClick={handleNonGST} style={{ marginTop: '20px', color: '#0DB442', cursor: "pointer", fontSize: "14px" }} className='breadcrumbsLabel'>
                I don't have a GST number
                &nbsp;
              </div>
            }
            <>
              {GstVerified === true ?
                <div class="continue-topheader">
                  <button type="submit" class="btn btn-success mt-3 continue-button-1" onClick={handleClickGSTNumber}>
                    Continue
                  </button>
                </div>
                :
                <div class="continue-topheader">
                  <button type="submit" class="btn btn-secondary mt-3 continue-button-1" disabled onClick={handleClickGSTNumber}>
                    Continue
                  </button>
                </div>
              }
            </>

          </div>

          : <Documents msme={radioButton} requestId={reqId} oType={orgType} />}</>
      }
    </>
  )
}

export default GstNumber;
