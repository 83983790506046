import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//@custom styles
import "./styles.scss";

//@static constants
let count = 1;
var intervalSet;
const staticLists = [100, 80, 60, 40, 20];

//@fuctional component
const CustomLoader = ({ loading, children, loadText, className }) => {
    const [lists, setLists] = useState(staticLists);

    useEffect(() => {
        const intervalFn = () => {
            setLists(staticLists.slice(0, count));

            count = ((count === 5) ? 1 : ++count);
        };

        if(loading) {
            intervalSet = setInterval(intervalFn, 360);
        } else if(!loading && intervalSet) {
            count = 1;
            setLists(staticLists);
            clearInterval(intervalSet);
        } else {
            //do nothing
        }

        return () => {
            count = 1;
            setLists(staticLists);
            clearInterval(intervalSet);
        };
    }, [loading]);

    return (
        <div className={`${(loading) ? "load-mask-cls" : ""} ${className || ""}`}>
            {children}
            {(loading) ? 
                (
                    <div className="load-cont-cls">
                        {lists.map((item, index) => {
                                return <div key={index} className="load-list-item-cls" style={{ opacity: `${item}%` }}></div>;
                        })}
                        <div className="custom-load-text-cls">{loadText || "Loading..."}</div>
                    </div>
                )
             : ""}
        </div>
    );
};

//@for required/optional props
CustomLoader.propTypes= {
    loading: PropTypes.bool,
    triLoader: PropTypes.bool,    
    className: PropTypes.string
};

export default CustomLoader;