const urlLists = [{
    name: "AuctionDetails",
    title: "AuctionDetails",
    routeId: "/auction/auctionDetails",
    component: "AuctionDetails",
},
{
    name: "TraderDetails",
    title: "TraderDetails",
    routeId: "/traders/traderDetails",
    component: "TraderDetails",  
},
{
    name: "NewCommodity",
    title: "NewCommodity",
    routeId: "/commodity/newCommodity",
    component: "NewCommodity",
},
{
    name: "OrderDetails",
    title: "OrderDetails",
    routeId: "/orders/orderDetails",
    component: "OrderDetails",
},
{
    name: "PurchaseOrder",
    title: "PurchaseOrder",
    routeId: "/orders/orderDetails/purchaseOrder",
    component: "Orders/OrderDetails/PurchaseOrder",
},
{
    name: "SupplyOrder",
    title: "SupplyOrder",
    routeId: "/orders/orderDetails/supplyOrder",
    component: "Orders/OrderDetails/SupplyOrder",
},
{
    name: "NewTrader",
    title: "NewTrader",
    routeId: "/traders/newTrader",
    component: "Traders/NewTrader",
}

];

export default urlLists;