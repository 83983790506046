import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from '../../../components/CustomInputText';

// import './styles.scss';
import './PopUp.scss';


import CustomButton from "../../../components/CustomButton";
import { fireAjaxRequest } from '../../../utils/Utility';
import { SET_CONTACTUS_API } from '../../../utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from '../../../components/CustomLoader';
import bell from '../../../assets/images/bellicon.png'
class SetalertPopUp extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { visible, onHide, onAccept, handelInputChange, formData, onSubmitsetalertPopUp, onClickRegister, loading } = this.props;

        return (
            <div className="popup-cls">
                <div className="setalert-dialog">
                    <CustomDialog visible={visible} footer={() => { }} header={
                        <div className="titlebar-cls">
                        <div className="first-div">
                            <img className="bell-icon" src={bell} /><p>Set Alert</p></div>
                    </div>
                    } onHide={onHide} onCancel={onHide} width="52vw">
                        <CustomLoader loading={loading || false}>
                            <div className="setalert-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                {/* <div className="header-cls">
                                    <div className="first-div">Set Alert</div>
                                </div> */}
                                <div className="message-us">
                                    <div className="register-label-cls">To participate in auction kindly <span onClick={onClickRegister} >Register</span></div>
                                    <div className="label-cls">Please provide the details to set alert for upcoming auction</div>
                                    <div className="names-div">
                                        <div className="inputfield-cls">
                                            <p>NAME</p>
                                            <div className="input-cls">
                                                <CustomInputText name="setalertfullname" value={formData.setalertfullname} config={{
                                                    placeholder: "Full Name",
                                                    onChange: handelInputChange,
                                                }} />
                                            </div>
                                        </div>
                                        <div className="inputfield-cls">
                                            <p>ENTER MOBILE NUMBER</p>
                                            <div class="input-container">
                                                <span class=" icon">+91</span>
                                                <CustomInputText name="setalertnumber" value={formData.setalertnumber} config={{
                                                    placeholder: "Mobile Number",
                                                    onChange: handelInputChange, keyfilter: "pnum", maxLength: "10",
                                                }} />
                                            </div>
                                            {/* <div className="input-cls">
                                                <CustomInputText name="setalertnumber" value={formData.setalertnumber} config={{
                                                    placeholder: "Mobile Number",
                                                    onChange: handelInputChange, keyfilter: "pnum", maxLength: "10",
                                                }} />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="btns-cls">
                                        <CustomButton label="Cancel" className="cancel" config={{ onClick: onHide }} />
                                        <CustomButton label="Submit" className="send" config={{ onClick: onSubmitsetalertPopUp }} />
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                        </CustomLoader>
                    </CustomDialog>
                </div>
            </div>);
    }
}

export default SetalertPopUp;