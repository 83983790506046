import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import FixedAppDirectingFooter from '../FixedAppDirectingFooter/FixedAppDirectingFooter'
import Navbar from '../Navbar/Navbar'
import MarketPrice from './MarketPrice'
import eresearchImage from '../../../pre-reg/img/eResearchImg.png'
import scanner from '../../../pre-reg/img/WAQR3x.png'
import people from '../../../pre-reg/img/peopleCropped.png'
import Scrollbars from 'react-custom-scrollbars'
import './EResearch.css'
import commodity from '../../../assets/images/maize.svg'
import view from '../../../assets/images/view.svg'
import download from '../../../assets/images/download.svg'
import { COMMODITY_LIST_API, COMMODITY_RESEARCH, COMMODITY_RESEARCH_SEASONAL_REPORTS, COMMODITY_RESEARCH_TOTAL_COUNTS, GET_COMMODITY_GRID_DATA, GET_WEEKLY_REPORT } from '../../../utils/Api'
import { fireAjaxRequest, history } from '../../../utils/Utility'
import { toast } from 'react-toastify'
import forward from '../../../assets/images/forward.svg'
import backward from '../../../assets/images/backward.svg'
import dropdownArrow from '../../../assets/images/dropdownArrow.svg'
import backArrowEResearch from '../../../assets/images/backArrowEResearch.svg'


function EResearch() {

    const [activeTab, setActiveTab] = useState('daily');
    const [time, setTime] = useState('1Year');

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const [totalDailyCount, setTotalDailyCount] = useState(0);
    const [totalSeasonalDataCount, setTotalSeasonalDataCount] = useState(0);
    const [totalWeeklyDataCount, setTotalWeeklyDataCount] = useState(0);

    const [dailyData, setDailyData] = useState([]);
    const [seasonalData, setSeasonalData] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);

    const [dailyDataCount, setDailyDataCount] = useState(0);
    const [seasonalDataCount, setSeasonalDataCount] = useState(0);
    const [weeklyDataCount, setWeeklyDataCount] = useState(0);

    const [commodityList, setCommodityList] = useState([]);
    const [selectedCommodities, setSelectedCommodities] = useState([]);

    const [harvestType, setHarvestType] = useState('ALL');
    const [langType, setLangType] = useState('ALL');

    const userInfo = sessionStorage.getItem('userInfo');
    const userInfoObj = JSON.parse(userInfo);

    const [currentpage, setCurrentpage] = useState(1);  
    const [pageNumberLimit, setPageNumberLimit] = useState(3);  
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);  
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0); 
    const [pageIncrementBtn , setPageIncrementBtn] = useState(false);
    const [pageDeccrementBtn , setPageDeccrementBtn] = useState(false);
    const [localpages , setLocalpages] = useState([]);
    
    const timePeriod_listt=[
        {
            id:'15days',
            name: 'Last 15 days'
        },
        {
            id:'1Month',
            name: 'This month'
        },
        {
            id:'3Months',
            name: 'Last Quarter'
        },
        {
            id:'1Year',
            name: '1 year'
        },
        {
            id:'alltime',
            name: 'All Time'
        }
        
    ]


    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setTime('1Year');
        setHarvestType('ALL');
        setLangType('ALL');
        setCurrentpage(1);
        setMaxPageNumberLimit(3);
        setMinPageNumberLimit(0);
        setPageNumberLimit(3);
        setOffset(0);
        setSelectedCommodities([]);
      };
    const handleDropdownItemClick = (selectedtimeItem, selectedId) => {
        setTime(selectedId);
    };

    const getTotalReportsCount = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                Authorization: `Bearer ${userInfoObj.token}`,
                "Content-Type": "application/json",
            },
          };
          
        fetch(COMMODITY_RESEARCH_TOTAL_COUNTS, requestOptions)
        .then(response => response.json())
        .then(result => {
            setTotalDailyCount(result.data.dailyReportCount);
            setTotalSeasonalDataCount(result.data.seasonalReportCount);
            setTotalWeeklyDataCount(result.data.weeklyReportCount)
        })
        .catch(error => {
            setTotalDailyCount(0);
            setTotalSeasonalDataCount(0);
            setTotalWeeklyDataCount(0);
        });
    }

    const getDailyReportCommodity = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };

        if(selectedCommodities.length!==0){
            fetch(COMMODITY_RESEARCH+"?offset="+offset+"&limit="+limit+"&reportType=DAILY_COMMODITY_REPORTS&fromDate="+time+"&commodityId="+selectedCommodities+"", requestOptions)
            .then(response => response.json())
            .then(dailyresult => {
                setDailyData(dailyresult.data);
                setDailyDataCount(dailyresult.recordCount);
            } )
            .catch(error => console.log('error', error));
        }
        else if(time=== "alltime"){
            fetch(COMMODITY_RESEARCH+"?offset="+offset+"&limit="+limit+"&reportType=DAILY_COMMODITY_REPORTS", requestOptions)
            .then(response => response.json())
            .then(dailyresult => {
                setDailyData(dailyresult.data);
                setDailyDataCount(dailyresult.recordCount);
            } )
            .catch(error => console.log('error', error));
        }
        else{
            fetch(COMMODITY_RESEARCH+"?offset="+offset+"&limit="+limit+"&reportType=DAILY_COMMODITY_REPORTS&fromDate="+time+"", requestOptions)
            .then(response => response.json())
            .then(dailyresult => {
                setDailyData(dailyresult.data);
                setDailyDataCount(dailyresult.recordCount);
            } )
            .catch(error => console.log('error', error));
        }
         
    }
    const getSeasonalReportCommodity = async () => {
       
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${userInfoObj.token}`,
                "Content-Type": "application/json",
            },
          };
          
          if(harvestType==="ALL"){
            if(selectedCommodities.length!==0){
                await fetch(COMMODITY_RESEARCH_SEASONAL_REPORTS+"?offset="+offset+"&limit="+limit+"&fromDate="+time+"&commodityId="+selectedCommodities+"", requestOptions)           
                .then(response => response.json())
                .then(seasonalresult => {
                    setSeasonalData(seasonalresult.data);
                    setSeasonalDataCount(seasonalresult.recordCount);
                } )
                .catch(error => console.log('error', error));
            }
            else{
                await fetch(COMMODITY_RESEARCH_SEASONAL_REPORTS+"?offset="+offset+"&limit="+limit+"&fromDate="+time+"", requestOptions)           
                .then(response => response.json())
                .then(seasonalresult => {
                    setSeasonalData(seasonalresult.data);
                    setSeasonalDataCount(seasonalresult.recordCount);
                } )
                .catch(error => console.log('error', error));
            }
          }
          else{
            if(selectedCommodities.length!==0){
                await fetch(COMMODITY_RESEARCH_SEASONAL_REPORTS+"?offset="+offset+"&limit="+limit+"&fromDate="+time+"&harvestProcess="+harvestType+"&commodityId="+selectedCommodities+"", requestOptions)           
            .then(response => response.json())
                .then(seasonalresult => {
                    setSeasonalData(seasonalresult.data);
                    setSeasonalDataCount(seasonalresult.recordCount);
                } )
                .catch(error => console.log('error', error));
            }
            else{
                await fetch(COMMODITY_RESEARCH_SEASONAL_REPORTS+"?offset="+offset+"&limit="+limit+"&fromDate="+time+"&harvestProcess="+harvestType+"", requestOptions)           
            .then(response => response.json())
                .then(seasonalresult => {
                    setSeasonalData(seasonalresult.data);
                    setSeasonalDataCount(seasonalresult.recordCount);
                } )
                .catch(error => console.log('error', error));
            }
                
          }
          
    }
    const getWeeklyReportCommodity = async () => {
       
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          if(selectedCommodities.length!==0){
            await fetch(GET_WEEKLY_REPORT+"/get?offset="+offset+"&limit="+limit+"&fromDate="+time+"&reportTypeLanguage="+langType+"&commodityId="+selectedCommodities+"", requestOptions)
            .then(response => response.json())
            .then(result => {
                setWeeklyData(result.data)
                setWeeklyDataCount(result.recordCount)
            })
            // .then(result => this.setState({weeklyCommodityCardData:result.data}) )
            .catch(error => console.log('error', error));
        }
        else{
            await fetch(GET_WEEKLY_REPORT+"/get?offset="+offset+"&limit="+limit+"&fromDate="+time+"&reportTypeLanguage="+langType+"", requestOptions)
            .then(response => response.json())
            .then(result => {
                setWeeklyData(result.data)
                setWeeklyDataCount(result.recordCount)
            })
            // .then(result => this.setState({weeklyCommodityCardData:result.data}) )
            .catch(error => console.log('error', error));
        }
          
    }
    const getAllCommodityList = async ()=>{
            try {
                const resp = await fireAjaxRequest(`${COMMODITY_LIST_API}?offset=0&limit=500`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${userInfoObj.token}`,
                      "Content-Type": "application/json",
                    },
                  }
                );
                if (resp && resp.totalCount > -1) {
                    setCommodityList(resp.items);
                } else {
                  toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setCommodityList([]);
            }
    }

    const formatDate = (givenDate) =>{
        const formattedDate = new Date(givenDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        return formattedDate;
    }

    const changeHarvestType = (type) =>{
        setHarvestType(type);
    }
    const changeLangType = (type) =>{
        setLangType(type);
    }

    const hanglePageClick = (e) =>{
        setCurrentpage(Number(e.target.id))
        setOffset(Number(e.target.id)*limit - limit)
        // getDailyReportCommodity();
    }   
    const handleNextPageBtn = async (totalPages) => {
        if (currentpage + 1 > totalPages || (activeTab === "daily" ? (dailyDataCount < limit) : (activeTab=== "seasonal" ? (seasonalDataCount < limit) : (weeklyDataCount<limit)))){
          return;
        }
        
        setCurrentpage(currentpage + 1);
        setOffset((currentpage + 1) * limit - limit);
        // getDailyReportCommodity(); /
        
        if (currentpage + 1 > maxPageNumberLimit && (currentpage + 1) !== totalPages) {
          setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
        
        if (currentpage === 1 && minPageNumberLimit > 1 && maxPageNumberLimit > 3) {
          setMaxPageNumberLimit(3);
          setMinPageNumberLimit(0);
        }
    }
    const handlePrevPageBtn= () =>{
        if((currentpage - 1)===0)
        {
            return;
        }
        setCurrentpage(currentpage-1);
        setOffset((currentpage-1)*limit - limit);
        // getDailyReportCommodity();
       
        if((currentpage - 1)% pageNumberLimit === 0 )
        {
            setMaxPageNumberLimit(currentpage-1);
            setMinPageNumberLimit(minPageNumberLimit-pageNumberLimit)
        }
        if(currentpage === Math.ceil((activeTab === "daily" ? (dailyDataCount/limit) : (activeTab=== "seasonal" ? (seasonalDataCount/limit) : (weeklyDataCount/limit)))) && maxPageNumberLimit< currentpage-1)
        {
            if(currentpage%pageNumberLimit===0)
            {
                setMaxPageNumberLimit(currentpage-1);
                setMinPageNumberLimit(currentpage-pageNumberLimit);
            }else{
                setMaxPageNumberLimit(currentpage-1);
                setMinPageNumberLimit(currentpage-(currentpage % pageNumberLimit));
            } 
        }
    }
    const downloadEresearchFile = (fileURL, filename) => {
        fetch(fileURL).then(response => {
          response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = filename;
            alink.click();
          })
        })
    }

    useEffect(()=>{
        getTotalReportsCount();
        getAllCommodityList();
    },[])

    useEffect(()=>{
        if(activeTab==="weekly")
            getWeeklyReportCommodity();
        else if(activeTab==="daily")
            getDailyReportCommodity();
        else if(activeTab==="seasonal")
            getSeasonalReportCommodity();
    },[time,langType,harvestType, offset, limit,selectedCommodities])

    useEffect(()=>{
        setCurrentpage(1);
        setOffset(0);
    },[time,langType,harvestType,selectedCommodities])

    useEffect(()=>{
        if(activeTab=== "daily" && dailyDataCount===0){
            return
        }
        if(activeTab=== "seasonal" && seasonalDataCount===0){
            return
        }
        if(activeTab=== "weekly" && weeklyDataCount===0){
            return
        }
        const arr=[];
        for(let i=2 ;i<Math.ceil((activeTab === "daily" ? (dailyDataCount/limit) : (activeTab=== "seasonal" ? (seasonalDataCount/limit) : (weeklyDataCount/limit))));i++){
            arr.push(i);
        }
        setLocalpages(arr);
    },[dailyDataCount, seasonalDataCount, weeklyDataCount, activeTab])

    useEffect(()=>{
        if(localpages.length +1 > maxPageNumberLimit)
        {
            setPageIncrementBtn(true);
        }
        else{
            setPageIncrementBtn(false);
        }
        if(minPageNumberLimit >1)
        {
            setPageDeccrementBtn(true);
        }
        else{
            setPageDeccrementBtn(false);
        }

    },[maxPageNumberLimit,minPageNumberLimit])

    
    useEffect(()=>{
        // custom commodity multiselect dropdown--------------------------------------------------------------------
        const dropdown = document.querySelector('.multiselect-commodity-dropdown');
        const dropdownList = dropdown.querySelector('.dropdown-list');
       

        const timedropdown = document.querySelector('.custom-time-dropdown');
        const timeDropdownList = timedropdown.querySelector('.dropdown-list');
       

        dropdownList.style.display = 'none';
        dropdown.addEventListener('click', function () {
        dropdownList.style.display = dropdownList.style.display === 'none' ? 'block' : 'none';
        });

        timeDropdownList.style.display ='none'
        timedropdown.addEventListener('click', function () {
            timeDropdownList.style.display = timeDropdownList.style.display === 'none' ? 'block' : 'none';
        });

        window.addEventListener('click', function(event) {
            // Check if the clicked element is outside the timedropdown
            if (!timedropdown.contains(event.target)) {
                timeDropdownList.style.display = 'none';
            }
            
            // Check if the clicked element is outside the dropdown, excluding the search input and checkboxes
            if (!dropdown.contains(event.target)) {
                dropdownList.style.display = 'none';
            }
        });

    },[activeTab])

    useEffect(() => {
        if (commodityList.length === 0) {
            return; 
        }
        // call us popup
        let chatOverlay = document.querySelector('.chatOverlay')
        let callBtn = document.querySelectorAll('.call')
        let chatCross = document.querySelector('.chatRight > div > svg:last-of-type')

        callBtn.forEach((ele) => {
        ele.addEventListener('click', () => {
            chatOverlay.classList.add('chatDisplay')
            document.body.style.overflow = 'hidden'
            let elm = document.querySelector('.toaster')
             elm.style.display= "none";
        })
        })

        chatCross.addEventListener('click', () => {
        document.body.style.overflow = 'auto'
        chatOverlay.classList.remove('chatDisplay')
        })
        let callUsNumber = document.querySelector('.chatContent>div:first-of-type')
        
        callUsNumber.addEventListener('click', () => {
        let elm = document.querySelector('.toaster')
        elm.style.display= "none";
        var newone = elm.cloneNode(true);
        elm.parentNode.replaceChild(newone, elm);
        })

        // custom commodity multiselect dropdown--------------------------------------------------------------------
        const dropdown = document.querySelector('.multiselect-commodity-dropdown');
        const selectedItems = dropdown.querySelector('.selected-items');
        const placeholder = selectedItems.querySelector('.commodity-placeholder span');
        const arrow = selectedItems.querySelector('.arrow');
        const dropdownList = dropdown.querySelector('.dropdown-list');
        const searchInput = dropdown.querySelector('.search-box');
        const dropdownItems = dropdown.querySelectorAll('.dropdown-itemm input[type="checkbox"]');
        const dropdownLabels = dropdown.querySelectorAll('.dropdown-itemm label');

        // dropdownList.style.display = 'none';
        // dropdown.addEventListener('click', function () {
        // dropdownList.style.display = dropdownList.style.display === 'none' ? 'block' : 'none';
        // });

        dropdownItems.forEach(function (checkbox, index) {
        checkbox.addEventListener('change', function (event) {
            if (checkbox.id === 'all') {
                handleAllCheckboxChange(checkbox);
            } else {
                updateSelectedItemsLabel();
            }
        });
        });
        dropdownItems.forEach(function (checkbox) {
            checkbox.addEventListener('click', function (event) {
                event.stopPropagation();
            });
        });

        function handleAllCheckboxChange(allCheckbox) {
            const isChecked = allCheckbox.checked;

            dropdownItems.forEach(function (checkbox) {
                checkbox.checked = isChecked;
            });

            updateSelectedItemsLabel();
        }

        function updateSelectedItemsLabel() {
        const selectedObjects = Array.from(dropdownItems)
            .filter(function (checkbox) {
            return checkbox.checked && checkbox.id !== 'all';
            })
            .map(function (checkbox, index) {
                const matchedCommodity = commodityList.filter((commodity)=>{
                    return commodity.id == checkbox.id;
                }) 
                if (matchedCommodity) {
                    return matchedCommodity;
                  }
            });

        const selectedItemsIDS = selectedObjects.map((obj) => obj[0].id);
        const selectedItems = selectedObjects.map((obj) => obj[0].name);
        setSelectedCommodities(selectedItemsIDS);

        if (selectedItems.length === dropdownItems.length - 1) {
            dropdownItems[0].checked = true;
        } else {
            dropdownItems[0].checked = false;
        }

        if (selectedItems.length > 0 && selectedItems.length < dropdownItems.length-1) {
            placeholder.textContent = selectedItems.join(', ');
        } else {
            placeholder.textContent = 'All';
        }
    }

    searchInput.addEventListener('input', function (event) {
        event.stopPropagation();
        const searchTerm = this.value.toLowerCase().trim();
        dropdownItems.forEach(function (checkbox) {
            const label = checkbox.previousElementSibling;
            const itemName = label.textContent.toLowerCase();
            const itemMatches = itemName.includes(searchTerm);
    
            checkbox.parentNode.style.display = itemMatches ? 'flex' : 'none';
        });
    });
    searchInput.addEventListener('click', function (event) {
        event.stopPropagation(); // Prevent click event from propagating
    });
      
// ---------------------------------------------------------------------------------------------------------------------
//custom time dropdown------------------------------------------------------------------------------------------------

    const timedropdown = document.querySelector('.custom-time-dropdown');
    const timeDropdownList = timedropdown.querySelector('.dropdown-list');
    const selectedtime = timedropdown.querySelector('.selected-item');
    const timedropdownItems = timeDropdownList.querySelectorAll('.dropdown-itemm');

    timedropdownItems.forEach((item) => {
        item.addEventListener('click', function() {
          const selectedtimeItem = this.querySelector('label').innerText;
          const spanElement = selectedtime.querySelector('span');
          spanElement.innerText = selectedtimeItem;
      });
    });

    // timeDropdownList.style.display ='none'
    // timedropdown.addEventListener('click', function () {
    //     timeDropdownList.style.display = timeDropdownList.style.display === 'none' ? 'block' : 'none';
    // });
// ---------------------------------------------------------------------------------------------------------------------

    window.addEventListener('click', function(event) {
        // Check if the clicked element is outside the timedropdown
        if (!timedropdown.contains(event.target)) {
            timeDropdownList.style.display = 'none';
        }
        
        // Check if the clicked element is outside the dropdown, excluding the search input and checkboxes
        if (!dropdown.contains(event.target)) {
            dropdownList.style.display = 'none';
        }
    });
    
    },[commodityList, activeTab])


  return (
    <div class="mainContainer">
        <div class="innerContainer postInnerContainer">
            <div class="chatOverlay">
                <div class="toaster">
                    Phone number copied successfully!
                </div>
                <div class="loginContainer">
                    <div class="loginLeft">
                        <svg width="85" height="33" viewBox="0 0 85 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M39.9681 5.00335C39.9681 5.73383 39.8752 6.3983 39.6895 6.99676C39.5038 7.58643 39.2252 8.09688 38.8538 8.52813C38.4913 8.95938 38.0358 9.28941 37.4876 9.51824C36.9481 9.74706 36.3203 9.86148 35.604 9.86148C34.8611 9.86148 34.2156 9.74706 33.6673 9.51824C33.119 9.28061 32.6636 8.95058 32.301 8.52813C31.9384 8.09688 31.6687 7.58203 31.4919 6.98356C31.315 6.3851 31.2266 5.72062 31.2266 4.99014C31.2266 4.02204 31.3857 3.17715 31.7041 2.45547C32.0225 1.73379 32.5044 1.17053 33.15 0.765684C33.8044 0.36084 34.6268 0.158418 35.6172 0.158418C36.5634 0.158418 37.3593 0.36084 38.0049 0.765684C38.6504 1.16173 39.1368 1.72499 39.464 2.45547C39.8001 3.17715 39.9681 4.02644 39.9681 5.00335ZM32.4867 5.00335C32.4867 5.79543 32.5973 6.47751 32.8183 7.04957C33.0394 7.62163 33.3799 8.06168 33.8397 8.36971C34.3084 8.67775 34.8965 8.83177 35.604 8.83177C36.3203 8.83177 36.9039 8.67775 37.3549 8.36971C37.8148 8.06168 38.1552 7.62163 38.3763 7.04957C38.5974 6.47751 38.7079 5.79543 38.7079 5.00335C38.7079 3.81522 38.4603 2.88672 37.9651 2.21784C37.4699 1.54017 36.6872 1.20133 35.6172 1.20133C34.9009 1.20133 34.3084 1.35535 33.8397 1.66338C33.3799 1.96261 33.0394 2.39826 32.8183 2.97032C32.5973 3.53359 32.4867 4.21126 32.4867 5.00335Z"
                                fill="#0B0B0B" />
                            <path
                                d="M47.4351 2.52148C47.5677 2.52148 47.7092 2.53028 47.8595 2.54788C48.0187 2.55668 48.1558 2.57428 48.2707 2.60068L48.1248 3.67C48.0099 3.6436 47.8816 3.6216 47.7402 3.60399C47.6075 3.58639 47.4793 3.57759 47.3555 3.57759C47.0813 3.57759 46.8205 3.6348 46.5728 3.74921C46.3252 3.86362 46.1042 4.02644 45.9096 4.23766C45.7151 4.44008 45.5603 4.68651 45.4453 4.97694C45.3392 5.26737 45.2862 5.59301 45.2862 5.95385V9.72946H44.1189V2.65349H45.0739L45.2066 3.94723H45.2596C45.41 3.6832 45.5913 3.44558 45.8035 3.23435C46.0157 3.01433 46.2589 2.84271 46.5331 2.7195C46.8072 2.58748 47.1079 2.52148 47.4351 2.52148Z"
                                fill="#0B0B0B" />
                            <path
                                d="M52.9765 2.65349V9.72946H51.8092V2.65349H52.9765ZM52.4061 0C52.5829 0 52.7377 0.0616067 52.8704 0.18482C53.0118 0.299233 53.0826 0.484053 53.0826 0.739281C53.0826 0.985707 53.0118 1.17053 52.8704 1.29374C52.7377 1.41695 52.5829 1.47856 52.4061 1.47856C52.2115 1.47856 52.0479 1.41695 51.9153 1.29374C51.7826 1.17053 51.7163 0.985707 51.7163 0.739281C51.7163 0.484053 51.7826 0.299233 51.9153 0.18482C52.0479 0.0616067 52.2115 0 52.4061 0Z"
                                fill="#0B0B0B" />
                            <path
                                d="M59.9601 2.52148C60.4288 2.52148 60.8489 2.60949 61.2203 2.7855C61.6005 2.96152 61.9233 3.22995 62.1886 3.59079H62.2549L62.4141 2.65349H63.3427V9.84828C63.3427 10.5171 63.2277 11.076 62.9978 11.5249C62.7678 11.9825 62.4185 12.3257 61.9498 12.5546C61.4812 12.7834 60.8842 12.8978 60.1591 12.8978C59.6462 12.8978 59.1731 12.8582 58.7398 12.779C58.3153 12.7086 57.935 12.5986 57.599 12.449V11.3796C57.8289 11.4941 58.0809 11.5909 58.3551 11.6701C58.6292 11.7581 58.9255 11.8241 59.2438 11.8681C59.5622 11.9121 59.8894 11.9341 60.2254 11.9341C60.8356 11.9341 61.3131 11.7537 61.658 11.3928C62.0117 11.0408 62.1886 10.5568 62.1886 9.94069V9.66346C62.1886 9.55784 62.193 9.40823 62.2019 9.21461C62.2107 9.01218 62.2196 8.87137 62.2284 8.79216H62.1753C61.9277 9.153 61.6182 9.42143 61.2468 9.59745C60.8842 9.77347 60.4598 9.86148 59.9734 9.86148C59.0537 9.86148 58.333 9.54024 57.8112 8.89777C57.2983 8.2553 57.0419 7.3576 57.0419 6.20468C57.0419 5.44779 57.1568 4.79652 57.3867 4.25086C57.6255 3.6964 57.9616 3.26956 58.3949 2.97032C58.8282 2.67109 59.3499 2.52148 59.9601 2.52148ZM60.1193 3.49838C59.7214 3.49838 59.3809 3.60399 59.0979 3.81522C58.8238 4.02644 58.6115 4.33447 58.4612 4.73932C58.3197 5.14416 58.249 5.63702 58.249 6.21788C58.249 7.08917 58.4081 7.75805 58.7265 8.2245C59.0537 8.68215 59.5268 8.91097 60.1458 8.91097C60.5084 8.91097 60.8179 8.86697 61.0744 8.77896C61.3308 8.68215 61.5431 8.53693 61.7111 8.34331C61.8791 8.14089 62.0029 7.88566 62.0825 7.57763C62.1621 7.26959 62.2019 6.90435 62.2019 6.48191V6.20468C62.2019 5.56221 62.1267 5.04295 61.9764 4.64691C61.8349 4.25086 61.6094 3.96043 61.2999 3.77561C60.9904 3.59079 60.5968 3.49838 60.1193 3.49838Z"
                                fill="#0B0B0B" />
                            <path
                                d="M73.993 6.17827C73.993 6.76794 73.9134 7.2916 73.7542 7.74925C73.6039 8.1981 73.3828 8.58094 73.091 8.89777C72.808 9.21461 72.4587 9.45663 72.0431 9.62385C71.6363 9.78227 71.1808 9.86148 70.6768 9.86148C70.2081 9.86148 69.7748 9.78227 69.3768 9.62385C68.9789 9.45663 68.634 9.21461 68.3422 8.89777C68.0503 8.58094 67.8204 8.1981 67.6524 7.74925C67.4932 7.2916 67.4136 6.76794 67.4136 6.17827C67.4136 5.39499 67.5463 4.73492 67.8116 4.19806C68.0769 3.6524 68.4571 3.23875 68.9524 2.95712C69.4476 2.66669 70.0356 2.52148 70.7166 2.52148C71.3621 2.52148 71.9281 2.66669 72.4145 2.95712C72.9097 3.23875 73.2944 3.6524 73.5685 4.19806C73.8515 4.73492 73.993 5.39499 73.993 6.17827ZM68.6207 6.17827C68.6207 6.73274 68.6915 7.21679 68.833 7.63043C68.9833 8.03528 69.2132 8.34771 69.5227 8.56774C69.8323 8.78776 70.2258 8.89777 70.7033 8.89777C71.1808 8.89777 71.5744 8.78776 71.8839 8.56774C72.1934 8.34771 72.4189 8.03528 72.5604 7.63043C72.7107 7.21679 72.7859 6.73274 72.7859 6.17827C72.7859 5.61501 72.7107 5.13536 72.5604 4.73932C72.4101 4.34327 72.1801 4.03964 71.8706 3.82842C71.5699 3.60839 71.1764 3.49838 70.69 3.49838C69.9649 3.49838 69.4387 3.73601 69.1115 4.21126C68.7843 4.68651 68.6207 5.34218 68.6207 6.17827Z"
                                fill="#0B0B0B" />
                            <path
                                d="M16.5181 30.4931V13.3503H21.7694L24.9226 25.0439L28.0402 13.3503H33.3035V30.4931H30.0436V16.9987L26.594 30.4931H23.2156L19.7779 16.9987V30.4931H16.5181Z"
                                fill="#0B0B0B" />
                            <path
                                d="M39.4749 21.8632L36.4521 21.3253C36.7919 20.1248 37.3767 19.2361 38.2065 18.6592C39.0363 18.0823 40.2691 17.7939 41.905 17.7939C43.3907 17.7939 44.4971 17.9693 45.2242 18.3201C45.9512 18.6631 46.4609 19.1036 46.7533 19.6415C47.0536 20.1716 47.2038 21.1499 47.2038 22.5765L47.1682 26.412C47.1682 27.5034 47.2196 28.3103 47.3223 28.8326C47.433 29.3471 47.6345 29.9006 47.9269 30.4931H44.6315C44.5445 30.2748 44.4378 29.9513 44.3114 29.5225C44.2561 29.3276 44.2166 29.199 44.1929 29.1366C43.6239 29.6823 43.0153 30.0916 42.3673 30.3644C41.7193 30.6373 41.0278 30.7737 40.2929 30.7737C38.9968 30.7737 37.9734 30.4268 37.2226 29.733C36.4798 29.0392 36.1084 28.1621 36.1084 27.1019C36.1084 26.4003 36.2783 25.7767 36.6181 25.231C36.9579 24.6775 37.4321 24.2565 38.0406 23.9681C38.657 23.6718 39.5421 23.4146 40.6959 23.1963C42.2527 22.9079 43.3315 22.6389 43.9321 22.3894V22.062C43.9321 21.4306 43.774 20.9823 43.4579 20.7173C43.1418 20.4444 42.5451 20.308 41.6679 20.308C41.0752 20.308 40.6129 20.4249 40.281 20.6588C39.9491 20.8849 39.6804 21.2863 39.4749 21.8632ZM43.9321 24.5294C43.5053 24.6697 42.8296 24.8373 41.905 25.0322C40.9804 25.2271 40.3758 25.4181 40.0913 25.6052C39.6567 25.9092 39.4394 26.2951 39.4394 26.7628C39.4394 27.2228 39.6132 27.6203 39.9609 27.9556C40.3087 28.2908 40.7512 28.4584 41.2886 28.4584C41.8892 28.4584 42.4622 28.2635 43.0074 27.8737C43.4105 27.5775 43.6752 27.215 43.8017 26.7862C43.8886 26.5056 43.9321 25.9716 43.9321 25.1842V24.5294Z"
                                fill="#0B0B0B" />
                            <path
                                d="M62.171 30.4931H58.84V24.1552C58.84 22.8143 58.7689 21.949 58.6266 21.5592C58.4844 21.1616 58.2512 20.8537 57.9272 20.6354C57.6111 20.4171 57.2278 20.308 56.7774 20.308C56.2005 20.308 55.6828 20.4639 55.2245 20.7757C54.7661 21.0876 54.45 21.5007 54.2762 22.0152C54.1102 22.5298 54.0272 23.4808 54.0272 24.8685V30.4931H50.6962V18.0745H53.7901V19.8987C54.8886 18.4955 56.2716 17.7939 57.9391 17.7939C58.674 17.7939 59.3458 17.9264 59.9543 18.1915C60.5628 18.4487 61.0211 18.78 61.3293 19.1854C61.6455 19.5908 61.8628 20.0507 61.9813 20.5652C62.1078 21.0798 62.171 21.8165 62.171 22.7753V30.4931Z"
                                fill="#0B0B0B" />
                            <path
                                d="M77.3278 30.4931H74.2339V28.6689C73.7203 29.3783 73.1117 29.9084 72.4084 30.2592C71.713 30.6022 71.0096 30.7737 70.2984 30.7737C68.8522 30.7737 67.6114 30.2007 66.5762 29.0548C65.5488 27.901 65.0352 26.2951 65.0352 24.237C65.0352 22.1322 65.537 20.5341 66.5406 19.4427C67.5443 18.3435 68.8127 17.7939 70.3458 17.7939C71.7525 17.7939 72.9695 18.3708 73.9968 19.5245V13.3503H77.3278V30.4931ZM68.4373 24.0148C68.4373 25.3401 68.623 26.299 68.9944 26.8914C69.5318 27.749 70.2826 28.1777 71.2467 28.1777C72.0133 28.1777 72.6652 27.8581 73.2026 27.2189C73.74 26.5718 74.0087 25.6091 74.0087 24.3306C74.0087 22.904 73.7479 21.8788 73.2263 21.2552C72.7048 20.6237 72.037 20.308 71.223 20.308C70.4327 20.308 69.7689 20.6198 69.2315 21.2435C68.702 21.8593 68.4373 22.7831 68.4373 24.0148Z"
                                fill="#0B0B0B" />
                            <path
                                d="M80.844 16.3907V13.3503H84.175V16.3907H80.844ZM80.844 30.4931V18.0745H84.175V30.4931H80.844Z"
                                fill="#0B0B0B" />
                            <path
                                d="M11.5401 21.8275C11.5401 22.1802 11.4327 22.5887 11.2178 23.0531C11.0029 23.5089 10.6075 23.9949 10.0315 24.5109C9.46421 25.0269 8.64759 25.4827 7.58168 25.8783C6.52437 26.274 5.19199 26.5449 3.58454 26.6911L3.27508 26.7169C3.20631 27.0609 3.17193 27.3791 3.17193 27.6715C3.17193 28.308 3.32236 28.7681 3.62322 29.0519C3.93268 29.3271 4.37967 29.4647 4.9642 29.4647C6.2536 29.4647 7.48713 28.7681 8.66478 27.3748H10.0315C9.49859 28.1919 8.69057 29.0003 7.60747 29.8001C6.52437 30.6 5.23927 30.9999 3.75216 30.9999C2.72064 30.9999 1.83525 30.7247 1.09599 30.1743C0.36533 29.6152 0 28.7982 0 27.7231C0 26.4073 0.36533 25.1301 1.09599 23.8916C1.82665 22.6446 2.82379 21.634 4.0874 20.86C5.35102 20.0774 6.72638 19.686 8.21349 19.686C9.40833 19.686 10.2593 19.8881 10.7665 20.2924C11.2823 20.6966 11.5401 21.2083 11.5401 21.8275ZM8.81951 21.7759C8.81951 21.4921 8.72065 21.2341 8.52295 21.0019C8.32524 20.7611 8.05446 20.6407 7.71062 20.6407C7.1089 20.6407 6.55446 20.8514 6.04729 21.2728C5.54873 21.6856 5.12752 22.1759 4.78368 22.7435C4.43984 23.3111 4.15617 23.8873 3.93268 24.4722C3.71777 25.057 3.58454 25.4182 3.53296 25.5558C5.27795 25.2634 6.59314 24.7689 7.47853 24.0723C8.37251 23.3756 8.81951 22.6102 8.81951 21.7759Z"
                                fill="#04B23D" />
                        </svg>
                        <p>Join the <span>revolution</span></p>
                        <p>Join the <span>Origo</span> Community</p>
                        <img src={people} alt=""/>
                    </div>

                    <div class="loginRight chatRight">
                        <div>
                            <svg width="14" height="13" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.499999 4.99993C0.503649 4.60535 0.662633 4.2281 0.942499 3.94993L4.16 0.724931C4.30052 0.585242 4.49061 0.506836 4.68875 0.506836C4.88689 0.506836 5.07698 0.585242 5.2175 0.724931C5.2878 0.794653 5.34359 0.877604 5.38167 0.968998C5.41974 1.06039 5.43935 1.15842 5.43935 1.25743C5.43935 1.35644 5.41974 1.45447 5.38167 1.54586C5.34359 1.63726 5.2878 1.72021 5.2175 1.78993L2.75 4.24993H10.25C10.4489 4.24993 10.6397 4.32895 10.7803 4.4696C10.921 4.61025 11 4.80102 11 4.99993C11 5.19884 10.921 5.38961 10.7803 5.53026C10.6397 5.67091 10.4489 5.74993 10.25 5.74993H2.75L5.2175 8.21743C5.35873 8.35767 5.43846 8.54826 5.43917 8.74728C5.43987 8.9463 5.36148 9.13745 5.22125 9.27868C5.08102 9.41991 4.89042 9.49965 4.6914 9.50035C4.49238 9.50105 4.30123 9.42266 4.16 9.28243L0.942499 6.05743C0.660809 5.77742 0.501674 5.39711 0.499999 4.99993Z"
                                    fill="#545050" />
                            </svg>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.8045 0.195191C15.6795 0.0702103 15.51 0 15.3332 0C15.1564 0 14.9869 0.0702103 14.8619 0.195191L7.99986 7.05719L1.13786 0.195191C1.01284 0.0702103 0.8433 0 0.666524 0C0.489748 0 0.320209 0.0702103 0.195191 0.195191C0.0702103 0.320209 0 0.489748 0 0.666524C0 0.8433 0.0702103 1.01284 0.195191 1.13786L7.05719 7.99986L0.195191 14.8619C0.0702103 14.9869 0 15.1564 0 15.3332C0 15.51 0.0702103 15.6795 0.195191 15.8045C0.320209 15.9295 0.489748 15.9997 0.666524 15.9997C0.8433 15.9997 1.01284 15.9295 1.13786 15.8045L7.99986 8.94252L14.8619 15.8045C14.9869 15.9295 15.1564 15.9997 15.3332 15.9997C15.51 15.9997 15.6795 15.9295 15.8045 15.8045C15.9295 15.6795 15.9997 15.51 15.9997 15.3332C15.9997 15.1564 15.9295 14.9869 15.8045 14.8619L8.94252 7.99986L15.8045 1.13786C15.9295 1.01284 15.9997 0.8433 15.9997 0.666524C15.9997 0.489748 15.9295 0.320209 15.8045 0.195191Z"
                                    fill="#757575" />
                            </svg>
                        </div>
                        <div class="chatContent">
                            <p>Call Us here:</p>
                            <div onClick={()=>{navigator.clipboard.writeText('+91 8338047777')}} onPointerDown={()=>{navigator.clipboard.writeText('+91 8338047777')}}>
                                <span>+91 8338047777</span>
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.66665 13.3333C8.55038 13.3323 9.39761 12.9808 10.0225 12.3559C10.6474 11.731 10.9989 10.8837 11 10V4.16201C11.001 3.81158 10.9325 3.46444 10.7984 3.14069C10.6642 2.81694 10.4672 2.52304 10.2186 2.27601L8.72398 0.781345C8.47695 0.532794 8.18305 0.335747 7.8593 0.201625C7.53555 0.0675032 7.18841 -0.0010253 6.83798 1.15931e-05H3.66665C2.78292 0.00107016 1.93568 0.3526 1.31079 0.977491C0.685901 1.60238 0.334372 2.44961 0.333313 3.33334V10C0.334372 10.8837 0.685901 11.731 1.31079 12.3559C1.93568 12.9808 2.78292 13.3323 3.66665 13.3333H7.66665ZM1.66665 10V3.33334C1.66665 2.80291 1.87736 2.2942 2.25243 1.91913C2.62751 1.54406 3.13621 1.33334 3.66665 1.33334C3.66665 1.33334 6.94598 1.34268 6.99998 1.34934V2.66668C6.99998 3.0203 7.14046 3.35944 7.3905 3.60949C7.64055 3.85954 7.97969 4.00001 8.33331 4.00001H9.65065C9.65731 4.05401 9.66665 10 9.66665 10C9.66665 10.5304 9.45593 11.0392 9.08086 11.4142C8.70579 11.7893 8.19708 12 7.66665 12H3.66665C3.13621 12 2.62751 11.7893 2.25243 11.4142C1.87736 11.0392 1.66665 10.5304 1.66665 10ZM13.6666 5.33334V12.6667C13.6656 13.5504 13.3141 14.3976 12.6892 15.0225C12.0643 15.6474 11.217 15.999 10.3333 16H4.33331C4.1565 16 3.98693 15.9298 3.86191 15.8047C3.73688 15.6797 3.66665 15.5102 3.66665 15.3333C3.66665 15.1565 3.73688 14.987 3.86191 14.8619C3.98693 14.7369 4.1565 14.6667 4.33331 14.6667H10.3333C10.8637 14.6667 11.3725 14.456 11.7475 14.0809C12.1226 13.7058 12.3333 13.1971 12.3333 12.6667V5.33334C12.3333 5.15653 12.4035 4.98696 12.5286 4.86194C12.6536 4.73692 12.8232 4.66668 13 4.66668C13.1768 4.66668 13.3464 4.73692 13.4714 4.86194C13.5964 4.98696 13.6666 5.15653 13.6666 5.33334Z"
                                        fill="#757575" />
                                </svg>
                            </div>
                            <div>
                                <span>Or Scan:</span>
                                <img src={scanner} style={{cursor: "pointer"}} onclick="window.open('https://wa.me/message/MAJZQZV5VM7DD1?src=qr')" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar />
            <Scrollbars autoHide={false} style={{ width: "100%", height: "93vh" }} className="scrollbars-wrapper">
                <div className='eresearch-breadcrumbs'>
                    <img src={backArrowEResearch} onClick={()=>{history.push('/postreg')}}></img>
                    <div className='breads' onClick={()=>{history.push('/postreg')}}>
                        Home &gt; &nbsp;
                        <span style={{color: "#04B23D"}} onClick={(e)=>{e.stopPropagation(); history.push('/eResearch')}}>eResearch</span>
                    </div>
                </div>
                <MarketPrice/>
                <div className='eresearch-container'>
                    <div className='eResearch-heading'>
                        <div class="small-box  yellow mt-2" style={{borderRadius:"0px 5px 5px 0px"}}></div>
                        <p className='mx-3 big mt-1 ' style={{ fontWeight: '600',fontSize:"17pt",fontFamily:'MetropolisRegular'}}>Commodity Research</p>
                    </div>
                    <div>
                        <div className='eresearch-tabs-container'>
                            <div className="ereaseach-tabs">
                                <div
                                    className={`ereaseach-tab ${activeTab === 'daily' ? 'active-eresearch-tab' : ''}`}
                                    onClick={() => handleTabClick('daily')}
                                >
                                Daily Reports ({totalDailyCount})
                                </div>
                                <div
                                    className={`ereaseach-tab ${activeTab === 'seasonal' ? 'active-eresearch-tab' : ''}`}
                                    onClick={() => handleTabClick('seasonal')}
                                >
                                Seasonal Reports ({totalSeasonalDataCount})
                                </div>
                                <div
                                    className={`ereaseach-tab ${activeTab === 'weekly' ? 'active-eresearch-tab' : ''}`}
                                    onClick={() => handleTabClick('weekly')}
                                >
                                Weekly Reports ({totalWeeklyDataCount})
                                </div>
                            </div>
                        </div>
                        {/* Render different content based on the active tab */}
                        {activeTab === 'daily' && 
                            <div className='eresearch-daily-reports'> 
                                <p>Latest reports available for commodities:</p>
                                <div className='eresearch_filters'>
                                    <div class="multiselect-commodity-dropdown">
                                            <div class="selected-items">
                                                <div class="commodity-placeholder">Commodity : <span>All</span></div>
                                                <div class="arrow"><img src={dropdownArrow}/></div>
                                            </div>
                                            <div class="dropdown-list"> 
                                                <input type="text" className="search-box" placeholder="Search commodity"/>
                                                <div class="scrollable-list">
                                                <div class="dropdown-itemm">
                                                    <label for="select-all">All</label>
                                                    <input type="checkbox" id="all"/>
                                                </div>
                                                {
                                                commodityList.map(function (commodity) {
                                                    return <div class="dropdown-itemm">
                                                            <label for="${commodity.id}">{commodity.name}</label>
                                                            <input type="checkbox" id={commodity.id}/>
                                                        </div>
                                                })
                                                }
                                                </div>
                                            </div>
                                    </div>
                                    <div class="custom-time-dropdown">
                                        <div class="selected-item">
                                            <div class="commodity-placeholder">Time: <span>1 Year</span></div>
                                            <div class="arrow"><img src={dropdownArrow}/></div>
                                        </div>
                                        <div class="dropdown-list"> 
                                            <div class="scrollable-list">
                                            {
                                                timePeriod_listt.map(function (time) {
                                                    return <div 
                                                            class="dropdown-itemm time-dropdown-item"
                                                            onClick={() => handleDropdownItemClick(time.name, time.id)}
                                                            >
                                                        <label for="${time.id}">{time.name}</label>
                                                    </div>
                                                })
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className='eresearch-cards-div'>
                                    {
                                        dailyData? dailyData.map((card)=>{
                                            return  <div className='eresearch-card'>
                                                <div className='eresearch-card-upper-div'>
                                                    <img className="eResearch-commodity-img" src={card.imagePath}></img>
                                                    <div className="eResearch-commodityName-view-download">
                                                        <span className="commodity-name">{card.commodityName}</span>
                                                        <div>
                                                            <a href={card.fileLocation} target='_blank'><img className="eresearch-view" src={view}></img></a>
                                                            <img className="eresearch-download" src={download} onClick={()=>{downloadEresearchFile(card.fileLocation, card.commodityName)}}></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='eresearch-card-lower-div'>
                                                    <span className='eresearch-date'>{formatDate(card.created)}</span>
                                                </div>
                                            </div>
                                        }):<div className='no-reports'>No reports available</div>
                                    }
                                </div>
                            </div>
                        }
                        {activeTab === 'seasonal' && 
                            <div className='eresearch-daily-reports seasonal-report'> 
                            <p>Latest reports available for commodities:</p>
                            <div className='eresearch_filters'>
                                <div className="harvest-filters">
                                    <button className={harvestType ==='ALL' ? "activeFilter" : ""} onClick={()=>{changeHarvestType('ALL')}}>All</button>
                                    <button className={harvestType ==='PRE_HARVEST' ? "activeFilter" : ""} onClick={()=>{changeHarvestType('PRE_HARVEST')}}>Pre-Harvest</button>
                                    <button className={harvestType ==='POST_HARVEST' ? "activeFilter" : ""} onClick={()=>{changeHarvestType('POST_HARVEST')}}>Post-Harvest</button>
                                </div>
                                <div class="separator"></div>
                                <div class="multiselect-commodity-dropdown">
                                        <div class="selected-items">
                                            <div class="commodity-placeholder">Commodity : <span>All</span></div>
                                            <div class="arrow"><img src={dropdownArrow}/></div>
                                        </div>
                                        <div class="dropdown-list"> 
                                            <input type="text" className="search-box" placeholder="Search commodity"/>
                                            <div class="scrollable-list">
                                            <div class="dropdown-itemm">
                                                <label for="select-all">All</label>
                                                <input type="checkbox" id="all"/>
                                            </div>
                                            {
                                            commodityList.map(function (commodity) {
                                                return <div class="dropdown-itemm">
                                                        <label for="${commodity.id}">{commodity.name}</label>
                                                        <input type="checkbox" id={commodity.id}/>
                                                    </div>
                                            })
                                            }
                                            </div>
                                        </div>
                                </div>
                                <div class="custom-time-dropdown">
                                    <div class="selected-item">
                                        <div class="commodity-placeholder">Time: <span>1 Year</span></div>
                                        <div class="arrow"><img src={dropdownArrow}/></div>
                                    </div>
                                    <div class="dropdown-list"> 
                                        <div class="scrollable-list">
                                        {
                                            timePeriod_listt.map(function (time) {
                                                return <div 
                                                        class="dropdown-itemm time-dropdown-item"
                                                        onClick={() => handleDropdownItemClick(time.name, time.id)}
                                                        >
                                                    <label for="${time.id}">{time.name}</label>
                                                </div>
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className='eresearch-cards-div'>
                                {
                                    seasonalData ? seasonalData.map((card)=>{
                                        return  <div className='eresearch-card'>
                                            <div className='eresearch-card-upper-div'>
                                                <img className="eResearch-commodity-img" src={card.imagePath}></img>
                                                <div className="eResearch-commodityName-view-download">
                                                    <span className="commodity-name">{card.commodityName}</span>
                                                    <div>
                                                        <a href={card.fileLocation} target='_blank'><img className="eresearch-view" src={view}></img></a>
                                                        <img className="eresearch-download" src={download} onClick={()=>{downloadEresearchFile(card.fileLocation, card.commodityName)}}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='eresearch-card-lower-div'>
                                                <span className='eresearch-date'>{formatDate(card.created)}</span>
                                            </div>
                                        </div>
                                    }):<div className='no-reports'>No reports available</div>
                                }
                            </div>
                            </div>
                        }
                        {activeTab === 'weekly' && 
                            <div className='eresearch-daily-reports weekly-report'> 
                            <p>Reports available for commodities:</p>
                            <div className='eresearch_filters'>
                                <div className="harvest-filters language-filters">
                                    <button className={langType ==='ALL' ? "activeFilter" : ""} onClick={()=>{changeLangType('ALL')}}>All</button>
                                    <button className={langType ==='ENGLISH' ? "activeFilter" : ""} onClick={()=>{changeLangType('ENGLISH')}}>English</button>
                                    <button className={langType ==='HINDI' ? "activeFilter" : ""} onClick={()=>{changeLangType('HINDI')}}>Hindi</button>
                                </div>
                                <div class="separator"></div>
                                <div class="multiselect-commodity-dropdown">
                                        <div class="selected-items">
                                            <div class="commodity-placeholder">Commodity : <span>All</span></div>
                                            <div class="arrow"><img src={dropdownArrow}/></div>
                                        </div>
                                        <div class="dropdown-list"> 
                                            <input type="text" className="search-box" placeholder="Search commodity"/>
                                            <div class="scrollable-list">
                                            <div class="dropdown-itemm">
                                                <label for="select-all">All</label>
                                                <input type="checkbox" id="all"/>
                                            </div>
                                            {
                                            commodityList.map(function (commodity) {
                                                return <div class="dropdown-itemm">
                                                        <label for="${commodity.id}">{commodity.name}</label>
                                                        <input type="checkbox" id={commodity.id}/>
                                                    </div>
                                            })
                                            }
                                            </div>
                                        </div>
                                </div>
                                <div class="custom-time-dropdown">
                                    <div class="selected-item">
                                        <div class="commodity-placeholder">Time: <span>1 Year</span></div>
                                        <div class="arrow"><img src={dropdownArrow}/></div>
                                    </div>
                                    <div class="dropdown-list"> 
                                        <div class="scrollable-list">
                                        {
                                            timePeriod_listt.map(function (time) {
                                                return <div 
                                                        class="dropdown-itemm time-dropdown-item"
                                                        onClick={() => handleDropdownItemClick(time.name, time.id)}
                                                        >
                                                    <label for="${time.id}">{time.name}</label>
                                                </div>
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className='eresearch-cards-div'>
                                {
                                    weeklyData ? weeklyData.map((card)=>{
                                        return  <div className='eresearch-card'>
                                            <div className='eresearch-card-upper-div'>
                                                <img className="eResearch-commodity-img" src={card.imagePath}></img>
                                                <div className="eResearch-commodityName-view-download">
                                                    <span className="commodity-name">{card.commodityName}</span>
                                                    <div>
                                                        <a href={card.fileLocation} target='_blank'><img className="eresearch-view" src={view}></img></a>
                                                        <img className="eresearch-download" src={download} onClick={()=>{downloadEresearchFile(card.fileLocation, card.commodityName)}}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='eresearch-card-lower-div weekly-lower-div'>
                                                <span className='eresearch-date'>{formatDate(card.created)}</span>
                                                <div class="weekly-lower-div-separator"></div>
                                                <span className='eresearch-Language'>{card.reportTypeLanguage.charAt(0).toUpperCase() + card.reportTypeLanguage.slice(1).toLowerCase()}</span>
                                                
                                            </div>
                                        </div>
                                    }):<div className='no-reports'>No reports available</div>
                                }
                            </div>
                            </div>
                        }

                        <div className="pagination-container">
                            {dailyDataCount>0 && activeTab === "daily"?
                                <ul className='paginationList'>
                                    <li onClick={handlePrevPageBtn}>
                                        {/* &lt;&lt; */}
                                        <img src={backward}></img>
                                    </li>
                                    {dailyDataCount>0?
                                    <li
                                        key={1}
                                        id={1}
                                        onClick={hanglePageClick}
                                        className={currentpage === 1 ? "active-page" : null}
                                        >
                                        {1}
                                    </li>
                                    :""}
                                    {pageDeccrementBtn ? <li onClick={handlePrevPageBtn}> &hellip; </li> : ""}

                                    {
                                        localpages.map((number)=>{
                                            if(number < maxPageNumberLimit + 1 && number > minPageNumberLimit)
                                            {
                                                return (
                                                    <li key={number} id={number} onClick={hanglePageClick} className={currentpage === number ? "active-page" : null}>
                                                        {number}
                                                    </li>
                                                );
                                            }else{
                                                return null;
                                            }
                                        })
                                    }

                                    {pageIncrementBtn || localpages.length +1 > maxPageNumberLimit ? <li onClick={()=>{handleNextPageBtn(localpages.length+2)}}> &hellip; </li> : ""}
                                    {
                                    dailyDataCount>limit?
                                        <li
                                            key={localpages.length+2}
                                            id={localpages.length+2}
                                            onClick={hanglePageClick}
                                            className={currentpage === localpages.length+2 ? "active-page" : null}
                                            >
                                            {localpages.length+2}
                                        </li>
                                        :""}
                                    <li
                                        onClick={() => {
                                            handleNextPageBtn(localpages.length+2);
                                        }}
                                    >
                                        {/* &gt;&gt; */}
                                        <img src={forward}></img>
                                    </li>
                                </ul> 
                            :""}
                            {weeklyDataCount>0 && activeTab === "weekly"?
                                <ul className='paginationList'>
                                    <li onClick={handlePrevPageBtn}>
                                        {/* &lt;&lt; */}
                                        <img src={backward}></img>
                                    </li>
                                    {weeklyDataCount>0?
                                    <li
                                        key={1}
                                        id={1}
                                        onClick={hanglePageClick}
                                        className={currentpage === 1 ? "active-page" : null}
                                        >
                                        {1}
                                    </li>
                                    :""}
                                    {pageDeccrementBtn ? <li onClick={handlePrevPageBtn}> &hellip; </li> : ""}

                                    {
                                        localpages.map((number)=>{
                                            if(number < maxPageNumberLimit + 1 && number > minPageNumberLimit)
                                            {
                                                return (
                                                    <li key={number} id={number} onClick={hanglePageClick} className={currentpage === number ? "active-page" : null}>
                                                        {number}
                                                    </li>
                                                );
                                            }else{
                                                return null;
                                            }
                                        })
                                    }

                                    {pageIncrementBtn || localpages.length +1 > maxPageNumberLimit ? <li onClick={()=>{handleNextPageBtn(localpages.length+2)}}> &hellip; </li> : ""}
                                    {
                                    weeklyDataCount>limit?
                                        <li
                                            key={localpages.length+2}
                                            id={localpages.length+2}
                                            onClick={hanglePageClick}
                                            className={currentpage === localpages.length+2 ? "active-page" : null}
                                            >
                                            {localpages.length+2}
                                        </li>
                                        :""}
                                    <li
                                        onClick={() => {
                                            handleNextPageBtn(localpages.length+2);
                                        }}
                                    >
                                        {/* &gt;&gt; */}
                                        <img src={forward}></img>
                                    </li>
                                </ul> 
                            :""}
                            {seasonalDataCount>0 && activeTab === "seasonal"?
                                <ul className='paginationList'>
                                    <li onClick={handlePrevPageBtn}>
                                        {/* &lt;&lt; */}
                                        <img src={backward}></img>
                                    </li>
                                    {seasonalDataCount>0?
                                    <li
                                        key={1}
                                        id={1}
                                        onClick={hanglePageClick}
                                        className={currentpage === 1 ? "active-page" : null}
                                        >
                                        {1}
                                    </li>
                                    :""}
                                    {pageDeccrementBtn ? <li onClick={handlePrevPageBtn}> &hellip; </li> : ""}

                                    {
                                        localpages.map((number)=>{
                                            if(number < maxPageNumberLimit + 1 && number > minPageNumberLimit)
                                            {
                                                return (
                                                    <li key={number} id={number} onClick={hanglePageClick} className={currentpage === number ? "active-page" : null}>
                                                        {number}
                                                    </li>
                                                );
                                            }else{
                                                return null;
                                            }
                                        })
                                    }

                                    {pageIncrementBtn || localpages.length +1 > maxPageNumberLimit ? <li onClick={()=>{handleNextPageBtn(localpages.length+2)}}> &hellip; </li> : ""}
                                    {
                                    seasonalDataCount>limit?
                                        <li
                                            key={localpages.length+2}
                                            id={localpages.length+2}
                                            onClick={hanglePageClick}
                                            className={currentpage === localpages.length+2 ? "active-page" : null}
                                            >
                                            {localpages.length+2}
                                        </li>
                                        :""}
                                    <li
                                        onClick={() => {
                                            handleNextPageBtn(localpages.length+2);
                                        }}
                                    >
                                        {/* &gt;&gt; */}
                                        <img src={forward}></img>
                                    </li>
                                </ul> 
                            :""}
                        </div>
                        
                    </div>
                </div>
                <Footer />
            </Scrollbars>
            <FixedAppDirectingFooter/>
        </div>
    </div>
  )
}

export default EResearch