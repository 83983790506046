import React, { Component } from "react";
import CustomDialog from "../../components/CustomDialog";
import { Scrollbars } from "react-custom-scrollbars";
import CustomInputText from "../../components/CustomInputText";

import "./styles.scss";
import "./contactus.scss";

import callImage from "../../assets/icons/ic_con_call.svg";
import mailImage from "../../assets/icons/ic_con_mail.svg";
import addressImage from "../../assets/icons/ic_con_address.svg";
import CustomInputArea from "../../components/CustomInputArea";
import CustomButton from "../../components/CustomButton";
import { fireAjaxRequest } from "../../utils/Utility";
import { SET_CONTACTUS_API } from "../../utils/Api";
import { toast } from "react-toastify";
import CustomLoader from "../../components/CustomLoader";

class ContactUsPopUp extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    formData: {},
    loading: false,
  };
  handelInputChange = (e) => {
    let formData = this.state.formData;
    if (e.target.name === "phone") {
      if (e.target.value && e.target.value.length < 11) {
        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });
      } else if (e.target.value === "") {
        formData[e.target.name] = "";
        this.setState({ formData: formData });
      }
    } else {
      formData[e.target.name] = e.target.value;
      this.setState({ formData: formData });
    }
  };
  onClickSend = () => {
    var validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (
      !this.state.formData.email &&
      !this.state.formData.name &&
      !this.state.formData.message &&
      !this.state.formData.phone
    ) {
      toast.error("Please enter all mandatory fields");
    } else if (!this.state.formData.name) {
      toast.error("Please enter name");
    } else if (
      !this.state.formData.phone ||
      this.state.formData.phone.length < 10
    ) {
      toast.error("Phone Number should contain 10 digits");
    } else if (!this.state.formData.email) {
      toast.error("Please enter email address");
    } else if (!this.state.formData.email.match(validRegex)) {
      toast.error("Please enter valid email address");
    } else if (!this.state.formData.message) {
      toast.error("Please enter message");
    } else {
      const sendPersonalInfoBody = {
        id: 0,
        name: this.state.formData.name,
        phoneNo: parseInt(this.state.formData.phone),
        email: this.state.formData.email,
        message: this.state.formData.message,
      };
      (async () => {
        this.setState({ loading: true });
        try {
          const resp = await fireAjaxRequest(SET_CONTACTUS_API, {
            method: "POST",
            body: JSON.stringify(sendPersonalInfoBody),
            headers: {
              // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
              "Content-Type": "application/json",
            },
          });
          this.setState({ loading: false });
          if (resp) {
            toast.success("Thanks for contacting us");
            this.setState({ formData: {} });
            this.props.onHide();
          }
        } catch (err) {
          this.setState({ loading: false });
          toast.error("Failed to sent your Query");
        }
      })();
    }
  };
  render() {
    const { visible, onHide, onAccept } = this.props;
    let { formData, loading } = this.state;
    return (
      <div className="contact-us-dialog">
        <CustomDialog
          visible={visible}
          footer={() => {}}
          header={
            <strong>
              <i className="pi pi-info-circle"></i> Contact Us
            </strong>
          }
          onHide={onHide}
          onCancel={onHide}
          width="65vw"
        >
          <CustomLoader loading={loading || false}>
            <div className="contact-us">
              {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
              <div className="location-details">
                <div className="contact-details-wrapper">
                  <img src={callImage} alt="" />
                  <p>+91 9797970202</p>
                </div>
                <div className="contact-details-wrapper">
                  <img src={mailImage} alt="" />
                  <p>Sales@origoemandi.com</p>
                </div>
                <div className="contact-details-wrapper">
                  <img className="address" src={addressImage} alt="" />
                  <p>
                    Registered Office: Fk-06, Somdatt Chamber -<br></br>l, 5,
                    <br></br>Bhikaji Cama Place, New Delhi
                    <br></br>South Delhi, Delhi- 110066
                    <br></br>CIN No. U51103DL2011PTC213352
                  </p>
                  {/* <p >Hyderabad<br /> 1st Floor, Kapil Towers,<br /> Financial District, Gachibowli,<br /> Hyderabad - 500032<br /> Phone: 040 - 67773000</p> */}
                </div>
              </div>
              <div className="message-us">
                <p>Message Us</p>
                <CustomInputText
                  name={"name"}
                  value={formData.name}
                  placeholder={{ name: "* Your name" }}
                  config={{
                    onChange: this.handelInputChange.bind(this),
                  }}
                />
                <CustomInputText
                  name={"phone"}
                  value={formData.phone}
                  placeholder={{ name: "* Phone number" }}
                  config={{
                    onChange: this.handelInputChange.bind(this),
                    keyfilter: "num",
                  }}
                />
                <CustomInputText
                  name={"email"}
                  value={formData.email}
                  placeholder={{ name: "* Email" }}
                  config={{
                    onChange: this.handelInputChange.bind(this),
                  }}
                />
                <CustomInputArea
                  name={"message"}
                  value={formData.message}
                  placeholder={{ name: "* Message" }}
                  config={{
                    onChange: this.handelInputChange.bind(this),
                  }}
                />
                <CustomButton
                  label="SEND"
                  config={{ onClick: this.onClickSend.bind(this) }}
                />
              </div>
              {/* </Scrollbars> */}
            </div>
          </CustomLoader>
        </CustomDialog>
      </div>
    );
  }
}

export default ContactUsPopUp;
