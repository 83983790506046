import React, { useState, useEffect } from 'react';
import '../style/CircularPercentageLoader.css';

function CircularPercentageLoader({ percentage }) {
const radius = 8; // Adjust the radius as needed
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <div className="circular-loader">
      <svg className="loader-fill">
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          fill="transparent"
          stroke="#04B23D"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </div>
  );
}

export default CircularPercentageLoader