import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from '../../../components/CustomInputText';
import CustomCheckbox from '../../..//components/CustomCheckbox'
// import './styles.scss';
import './PopUp.scss';


import CustomButton from "../../../components/CustomButton";
import { fireAjaxRequest } from '../../../utils/Utility';
import { SET_CONTACTUS_API } from '../../../utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from '../../../components/CustomLoader';


class CashandCarryPopUp extends Component {
    constructor(props) {
        super(props);

    }
    state = {
        formData: {},
        loading: false
    }

    render() {
        const { visible, onHide, onAccept, onSubmitcashandcarryPopUp, handelInputChange, formData,state } = this.props;
        let { loading } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        console.log(formData,"formData")
        return (
            <div className="popup-cashandcarry-cls">
                <div className="cashandcarry-dialog">

                    <CustomDialog visible={visible} footer={() => { }} header={<p>Cash & Carry Arbitrage</p>} onHide={onHide} onCancel={onHide} width="60vw">
                        <CustomLoader loading={state.loading || false}>
                            <div className="cashandcarry-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                {/* <div className="header-cls">
                                    <div className="first-div">Cash & Carry Arbitrage</div>
                                </div> */}
                                <div className="type-cls">
                                    <div className="cls">
                                        <span className="label-type">COMMODITY</span>
                                        <p className="item-type">{formData.arbitageCommodityName}</p>
                                    </div>
                                    <div className="cls">
                                        <span className="label-type">RATE OF RETURN</span>
                                        <p className="item-type">{formData.arbitagerateOfreturn} {formData.arbitagerateOfreturn>0?"%":""}</p>
                                    </div>
                                    <div className="cls">
                                        <span className="label-type">LOCK IN PERIOD</span>
                                        <p className="item-type">{formData.arbitagelockInperiod} {formData.arbitagelockInperiod>1?"days":formData.arbitagelockInperiod==1?"day":""}</p>
                                    </div>
                                </div>
                                <div className="financeneeded-cls" >
                                    <label htmlFor="Quality">Finance needed</label>
                                    <div>
                                        <CustomCheckbox name="financeneeded" value="Yes" checked={formData.financeneeded === 'Yes'} config={{ onChange: handelInputChange }} />
                                        <label htmlFor="Quality">Yes</label>
                                    </div>
                                    <div>
                                        <CustomCheckbox name="financeneeded" value="No" checked={formData.financeneeded === 'No'} config={{ onChange: handelInputChange }} />
                                        <label htmlFor="Quality">No</label>
                                    </div>
                                </div>
                               
                                <div className="message-us">
                                    {userInfo?"":<div className="names-div">
                                        <div className="inputfield-cls">
                                            <p>ENTER FULL NAME</p>
                                            <div className="input-cls">
                                                <CustomInputText name="cashandcarryfullname" value={formData.cashandcarryfullname} config={{
                                                    placeholder: "Full Name",
                                                    onChange: handelInputChange,
                                                }} />
                                            </div>

                                        </div>
                                        <div className="inputfield-cls">
                                            <p>ENTER MOBILE NUMBER</p>
                                            <div class="input-container">
                                                <span class="icon">+91</span>
                                                <CustomInputText name="cashandcarrynumber" value={formData.cashandcarrynumber} config={{
                                                    onChange: handelInputChange, keyfilter: "pnum", maxLength: "10",
                                                    placeholder: "Mobile Number",
                                                }} />
                                            </div>
                                            {/* <div className="input-cls">
                                                <CustomInputText name="cashandcarrynumber" value={formData.cashandcarrynumber} config={{
                                                    onChange: handelInputChange, keyfilter: "pnum", maxLength: "10"
                                                }} />
                                            </div> */}
                                        </div>
                                    </div>}
                                    
                                    <div className="btns-cls">
                                        <CustomButton label="Cancel" className="cancel" config={{ onClick: onHide }} />
                                        <CustomButton label="Submit" className="send" config={{ onClick: onSubmitcashandcarryPopUp }} />
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                        </CustomLoader>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default CashandCarryPopUp;