import React, { Component } from 'react';

class Timer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			timeleft: ""
		}
	}

	componentDidMount() {
		// this.timer = setInterval((this.calculatetime,1000))
		this.calculatetime();
	}

	calculatetime = () => {
		let { dateFuture } = this.props
		// var countDownDate = new Date(dateFuture).getTime();
		// let totaltime=""
		let timeleft;
	let difference = "";

		// Update the count down every 1 second
		setInterval(() => {
if(new Date(dateFuture).getDate()-new Date().getDate()==1){
	difference = "Closes    Tomorrow"
}
else{
	var countDownDate = new Date(dateFuture).getTime();
	var now = new Date().getTime();
	 timeleft = countDownDate - now;
	if (timeleft > 0) {
		// Calculating the days, hours, minutes and seconds left
		let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
		let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
		// days = days * 24
		// var totalHours = days + hours

		var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
		if (days >= 2){
			// difference = dateFuture;
			difference = `Closes on ${dateFuture.slice(8, 10)}-${dateFuture.slice(5, 7)=="01"?"Jan":dateFuture.slice(5, 7)=="02"?"Feb":dateFuture.slice(5, 7)=="03"?"Mar":dateFuture.slice(5, 7)=="04"?"Apr":dateFuture.slice(5, 7)=="05"?"May":dateFuture.slice(5, 7)=="06"?"Jun":dateFuture.slice(5, 7)=="07"?"Jul":dateFuture.slice(5, 7)=="08"?"Aug":dateFuture.slice(5, 7)=="09"?"Sep":dateFuture.slice(5, 7)=="10"?"Oct":dateFuture.slice(5, 7)=="11"?"Nov":"Dec"}`
		}else if(days >= 1){
			difference = "Closes    Tomorrow"
		}else{
			difference = `Closes in `
			difference += (hours === 1) ? ` ${hours}hr ` : ` ${hours > 1?` ${hours}hrs `:""}`;
			difference += (minutes === 0 || hours === 1) ? ` ${minutes}mins ` : ` ${minutes}mins  `;
		}
		// difference += (days === 1) ? `${days}day ` : `${days > 1?` ${days}days `:""}`;
		// difference += (hours === 1) ? ` ${hours}hr ` : ` ${hours > 1?` ${hours}hrs `:""}`;
		// difference += (minutes === 0 || hours === 1) ? ` ${minutes}mins ` : ` ${minutes}mins  `;
		
		// difference += (seconds === 0 || seconds === 1) ? `${seconds}S ` : `${seconds}S `;

	}
		// If the count down is over, write some text 
		if (timeleft < 0) {
			// clearInterval(x);
		}
		// this.setState({ timeleft: difference })
}
			
this.setState({ timeleft: difference })
		
		}, 1000);
		// return totaltime;
		
	}
	render() {
		return <div>
			<p style={{"textAlign":"left", }}>{this.state.timeleft.slice(0,9)}</p>
			<p>{this.state.timeleft.slice(9)}</p>
			
		</div>;
	}
}

export default Timer;
