import React from 'react';
import { Link, useHistory } from "react-router-dom";
import './Carousel.css';
import image from "../../../assets/images/farmer.png";
import farmerbg from "../../../assets/images/farmerBg.png";
import image1 from "../../../assets/images/form1.svg";
import image2 from "../../../assets/images/form2.svg";
import image3 from "../../../assets/images/form3.svg";
import image4 from "../../../assets/images/Vector.svg";
import vector_arrow from "../../../assets/images/vector_arrow.png";

function Carousel() {

    const history = useHistory();
  const emandiCashPage = () => {
    // history.push("/EmandiCash");
    history.push('/ecashComingSoon');
  }

    return (
        <div className='carousel-body'>

            <div class="card text-white" >
                <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel" >
                    <div class="carousel-inner">
                        <div class="carousel-item active" onClick={()=>emandiCashPage()} data-bs-interval="10000">
                            <img src={farmerbg} class="d-block w-100" className="backgroundImage" alt="..."></img>
                            <div class="carousel-caption d-none d-md-block carousel-css">
                                <h2 className='carousel-text'><span className="icon-e" style={{ color: '#04B23D',textShadow:"2px 2px 5px #000",fontSize:"25pt",marginRight:"5px",display:"inline-block" }}>e</span><span style={{ color: '#fff',textShadow:"2px 2px 5px black" }}>Cash</span></h2>
                                <p className='carousel-text carousel-text-css-2 instant-text'>Instant Cash for your Commmodity Supplies</p>
                                <hr
                                    class=" mt-0 d-inline-block mx-auto hr-line"
                                    // className='yellow-hr'
                                    // style={{width: '26%',
                                    // background: '#F4C430',
                                    // height: '2px',
                                    // border: '1px solid #F4C430'}}
                                />
                                <p className='carouselText carousel-css-1'>
                                    Get Limit for your Supplies upto ₹2 Crores &nbsp;<br />
                                    with <span className='span-css-1 yellow-ecash' style={{color: '#FDE96C'}}>eCash

                                    </span>
                                         {/* <i class='fa fas fa-chevron-circle-right i-css' style = {{fontSize: '28px', color: '#f5f5f7 !important' }} ></i> */}
                                         <span class = "button-right-arrow">
                                   <img src={vector_arrow} class="img-css-3"  style = {{marginBottom: '6px'}} alt="..." />
                        <button type="button" class="btn btn-light btn-circle btn-sm btn-css-2" style = {{marginBottom: '6px'}}></button>
                        </span>

                                </p>
                                <div className='containerrr'>
                                    <div className="containerInnerDiv">
                                        <div className="">
                                            <p className='formText formtext-css-1'>
                                                <img src={image1} alt="" />&nbsp;

                                                Zero colateral
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className='formText '>
                                                <img src={image2} alt="" />&nbsp;
                                                Starting from 0.7% (15 days)</p>
                                        </div>
                                        <div className="">
                                            <p className='formText '>
                                                <img src={image3} alt="" />&nbsp;

                                                Minimum Documentation </p>
                                        </div>
                                        <div className="">
                                            <p className='formText'>
                                                <img src={image4} alt="" />&nbsp;
                                                Dedicated Relationship Management
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="2000" >
                            <img src={farmerbg} class="d-block w-100" alt="..." style={{height:"100%"}} className="backgroundImage"/>
                        </div>
                        <div class="carousel-item">
                            <img src={farmerbg} class="d-block w-100" alt="..." style={{height:"100%"}} className="backgroundImage"/>
                        </div>
                    </div>
                    <button class="carousel-control-prev " type="button" data-bs-target="#carouselExampleInterval" style = {{paddingRight: '11px'}} data-bs-slide="prev">
                        <img src={vector_arrow} class="img-css-1" alt="..." />
                        <span>
                        <button type="button" class="btn btn-light btn-circle btn-sm btn-css-1" style = {{borderRadius: '100px !important'}}></button>
                        </span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval"  style = {{paddingLeft: '11px'}} data-bs-slide="next">
                        <img src={vector_arrow} class="img-css-2" alt="..." />
                        <span>
                        <button type="button" class="btn btn-light btn-circle btn-sm btn-css-1" style = {{borderRadius: '100px !important'}}></button>
                        </span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
           

            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </div>
    )
}

export default Carousel