import React, { Component } from 'react';
import CustomDialogTwo from "./components/CustomDialogTwo";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from './components/CustomInputText';
// import sucesstick from './assets/images/sucesstick'
// import './styles.scss';
import './CallusPopUp.scss';


import CustomButton from "./components/CustomButton";

import { toast } from 'react-toastify';
import CustomLoader from './components/CustomLoader';

export class SuccessPopUp extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData:{},
         loading:false
      }
    }
    
  render() {
    const { visible, onHide, onAccept,requestId } = this.props;
    let { formData, loading } = this.state;
    return (
        
        <div className="success-dialog">

            <CustomDialogTwo visible={visible} footer={() => { }} header={"Done"} onHide={onHide} onCancel={onHide} width="45vw">
                <CustomLoader loading={loading || false}>
                    <div className="cashandcarryconfirm-cls">
                        {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                    
                        <div className="message-us">
                            <div className="names-div">
                                <p>Your request has been sent successfully and your request id is <span style={{"fontWeight":"bold"}}>{requestId}</span> </p>
                                <p>You will receive a call shortly!</p>
                            </div>
                            
                        </div>
                        {/* </Scrollbars> */}
                    </div>
                </CustomLoader>
            </CustomDialogTwo>

        </div>
    );
  }
}

export default SuccessPopUp;
