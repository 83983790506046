const Lists = [
    {
        name: "Buyer UnderWriting",
        title: "Buyer UnderWriting",
        routeId: "/BuyerUnderWriting",
        component: "BuyerUnderWriting",
        localeName: "BuyerUnderWriting",
        titleLocaleName: "BuyerUnderWriting",
        type:"riskadmin/admin",
        image: "resources/icons/menu_ic_transactions.svg",
        selecImg: "resources/icons/menu_ic_transactions_selected.svg",
    },
    {
        name: "ECash",
        title: "ECash Admin",
        routeId: "/eCash",
        component: "eCash",
        localeName: "eCash",
        titleLocaleName: "eCash",
        type:"riskadmin/admin",
        image: "resources/icons/menu_ic_trader.svg",
        selecImg: "resources/icons/menu_ic_trader_selected.svg",
    },
    {
        name: "Profin",
        title: "Profin Admin",
        routeId: "/profin",
        component: "Profin",
        localeName: "Profin",
        titleLocaleName: "profin",
        type:"riskadmin/admin",
        image: "resources/icons/menu_ic_trader.svg",
        selecImg: "resources/icons/menu_ic_trader_selected.svg",
    },
    {
    name: "Auction",
    title: "Auction",
    routeId: "/auction",
    component: "Auction",
    localeName: "Auction",
    type:"admin/trader",
    image: "resources/icons/menu_ic_auction.svg",
    selecImg: "resources/icons/menu_ic_auction_selected.svg",
    children: [
        {
            name: "AuctionDetails",
            title: "AuctionDetails",
            routeId: "/auction/auctionDetails",
            component: "Auction/AuctionDetails",
        },
        {
            name: "NewAuction",
            title: "NewAuction",
            routeId: "/auction",
            component: "Auction/NewAuction",
        },
        {
            name: "NewAuction",
            title: "NewAuction",
            routeId: "/auction/newAuction",
            component: "Auction/NewAuction",
        },
        {
            name:"EditProfile",
            title: "AuctionDetails",
            routeId: "/editProfile",
            component: "EditProfile",
        },
        {
            name: "ViewOrder",
            title: "ViewOrder",
            routeId: "/auction/auctionDetails/viewOrder",
            component: "Auction/AuctionDetails/ViewOrder",
        },
    ]
}, {
    name: "Orders",
    title: "Orders",
    routeId: "/orders",
    component: "Orders",
    localeName: "Orders",
    titleLocaleName: "Orders",
    type: "admin/trader",
    image: "resources/icons/menu_ic_order.svg",
    selecImg: "resources/icons/menu_ic_order_selected.svg",
    children: [
        {
            name: "OrderDetails",
            title: "OrderDetails",
            routeId: "/orders/orderDetails",
            component: "Orders/OrderDetails",
        }
    ],
    children: [
        {
            name: "PurchaseOrder",
            title: "PurchaseOrder",
            routeId: "/orders/orderDetails/purchaseOrder",
            component: "Orders/OrderDetails/PurchaseOrder",
        },
        {
            name: "SupplyOrder",
            title: "SupplyOrder",
            routeId: "/orders/orderDetails/supplyOrder",
            component: "Orders/OrderDetails/SupplyOrder",
        }

    ]
},{
    name: "Account",
    title: "Account",
    routeId: "/account",
    component: "Account",
    localeName: "Account",
    titleLocaleName: "Account",
    type:"trader",
    image: "resources/icons/menu_ic_account.svg",
    selecImg: "resources/icons/menu_ic_account_selected.svg",
    children: [
        {
            name: "ViewTransactions",
            title: "ViewTransactions",
            routeId: "/account/transactions",
            component: "Account/ViewTransactions",
        }
    ]
}, {
    name: "Invoice",
    title: "Invoice",
    routeId: "/invoice",
    component: "Invoice",
    localeName: "Invoice",
    titleLocaleName: "Invoice",
    type:"admin/trader",
    image: "resources/icons/menu_ic_invoice.svg",
    selecImg: "resources/icons/menu_ic_invoice_selected.svg"
}, {
    name: "Settings",
    title: "Settings",
    routeId: "/settings",
    component: "Settings",
    localeName: "Settings",
    titleLocaleName: "Settings",
    type:"admin/trader",
    image: "resources/icons/menu_ic_settings.svg",
    selecImg: "resources/icons/menu_ic_settings_selected.svg"
}, {
    name: "Contact Us",
    title: "ContactUs",
    routeId: "/contactUs",
    component: "ContactUs",
    localeName: "ContactUs",
    titleLocaleName: "ContactUs",
    type:"trader",
    image: "resources/icons/menu_ic_contact.svg",
    selecImg: "resources/icons/menu_ic_contact_selected.svg"
},{
    name: "Transactions",
    title: "Transactions",
    routeId: "/transactions",
    component: "Transactions",
    localeName: "Transactions",
    titleLocaleName: "Transactions",
    type:"admin",
    image: "resources/icons/menu_ic_transactions.svg",
    selecImg: "resources/icons/menu_ic_transactions_selected.svg"
},{
    name: "Traders",
    title: "Traders",
    routeId: "/traders",
    component: "Traders",
    localeName: "Traders",
    titleLocaleName: "Traders",
    type:"admin",
    image: "resources/icons/menu_ic_trader.svg",
    selecImg: "resources/icons/menu_ic_trader_selected.svg",
    children: [
        {
            name: "TraderDetails",
            title: "TraderDetails",
            routeId: "/traders/traderDetails",
            component: "Traders/TraderDetails",
        },
        {
            name: "NewTrader",
            title: "NewTrader",
            routeId: "/traders",
            component: "Traders/NewTrader",
        }
    ]
},{
    name: "Commodity",
    title: "Commodity",
    routeId: "/commodity",
    component: "Commodity",
    localeName: "Commodity",
    titleLocaleName: "Commodity",
    type:"admin",
    image: "resources/icons/menu_ic_commodity.svg",
    selecImg: "resources/icons/menu_ic_commodity_selected.svg",
    children: [
        {
            name: "NewCommodity",
            title: "NewCommodity",
            routeId: "/commodity/commodityDetails",
            component: "Commodity/NewCommodity",
        }
    ]
   
},
{
    name: "Reports",
    title: "Reports",
    routeId: "/Reports",
    component: "Reports",
    localeName: "Reports",
    titleLocaleName: "Reports",
    type:"admin",
    image: "resources/icons/menu_ic_transactions.svg",
    selecImg: "resources/icons/menu_ic_transactions_selected.svg"
},
// {
//     name: "EmandiCash",
//     title: "EmandiCashLimit",
//     routeId: "/EmandiCashLimit",
//     component: "EmandiCashLimit",
//     localeName: "EmandiCash",
//     titleLocaleName: "EmandiCash",
//     type:"riskadmin/admin",
//     image: "resources/icons/menu_ic_trader.svg",
//     selecImg: "resources/icons/menu_ic_trader_selected.svg",
//     children: [
//         {
//             name: "EmandiCashLimit",
//             title: "EmandiCashLimit",
//             routeId: "/EmandiCashLimit/EmandiCashLimitDetails",
//             component: "EmandiCashLimit/EmandiCashLimitDetails",
//         },
//     ]
// },
// {
//     name: "Lender",
//     title: "Lender",
//     routeId: "/Lender",
//     component: "Lender",
//     localeName: "Lender",
//     titleLocaleName: "lender",
//     type:"admin",
//     image: "resources/icons/menu_ic_transactions.svg",
//     selecImg: "resources/icons/menu_ic_transactions_selected.svg"
// },
{
    name: "Lender",
    title: "Lender",
    routeId: "/AddLenderPage",
    component: "AddLender",
    localeName: "AddLender",
    titleLocaleName: "Add Lender",
    type:"riskadmin/admin",
    image: "resources/icons/menu_ic_transactions.svg",
    selecImg: "resources/icons/menu_ic_transactions_selected.svg"
},

 ];

export default Lists;