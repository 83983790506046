import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import successImage from "../../../assets/images/success.png";
import errorImage from "../../../assets/images/error.png";


class TransactionStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { onHide, visible = false, onCancel, onAcceptKYCNameDialog } = this.props;

        return (<div className="KYC-name-status">
            <CustomDialog className="name-error-msg" visible={visible} onHide={onHide} arrayOfButton={[{label:"OK",onClick:onAcceptKYCNameDialog}]}  btn2={"OK"} onAccept={onAcceptKYCNameDialog} onCancel={onCancel} header={"Alert"}>
                <p>The name entered in the personal info and the name in the KYC does not match. The name will be updated in the personal info as per the KYC.</p>
            </CustomDialog>
        </div>);


    }
}

export default TransactionStatusDialog;