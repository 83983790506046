import React, { useState } from 'react';
import { ENQUIRY_FORM } from '../../../utils/Api';
import successImage from "../../../assets/images/successImage.svg";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import './Form.css';
import CommodityItems from "./commodity.json";

function Form(props) {

    const history = useHistory();
    
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const [commodity, setCommodity] = useState("Wheat");
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [callModalIsOpen, setCallModalIsOpen] = React.useState(false);

    const [commodityError, setCommodityError] = useState('');

    const [success, setSuccess] = useState(false);

    let text = "We would love to hear from you."
    let text2 = "Get in touch!"

    const handleNameChange = (e) => {
        e.preventDefault();
        
        if(e.target.value!='')
        {
          setName(e.target.value.replace(/[^a-zA-Z\s]/g, ''));
            setNameError('');
        }
        else
        {
          setName('')
            setNameError('Please Enter Name');
        }
       
    }
    
    const _onClickCallUsLink = (e) => {
        e.preventDefault();
        setCallModalIsOpen(true);
      }
    const handlePhoneChange = (e) => {
        e.preventDefault();
        const re = /^[+ 0-9\b]+$/;
       
        if(e.target.value!='' && re.test(e.target.value))
        {
            setPhone(e.target.value.replace(/[^0-9]/g, ''))
            setPhoneError(''); 
        }
       else     
       {
          setPhone('')
         setPhoneError('Please Enter Phone');
       }

        //    const regex = /^[0-9\b]+$/;
        //    if (e.target.value === "" || regex.test(e.target.value)) {
        //     setPhone(e.target.value);
        //    }
    }
    const handleCommonityChange = (e) => {
        e.preventDefault();
        if (e.target.value=='Select') {
            setCommodityError('Please Select Commodity');
        }
        else
        {
            setCommodity(e.target.value);
            setCommodityError('');
        }
    }



    const formSubmit = (e) => {
        e.preventDefault();
      
        if (
          (name !== "" && name !== null) &&
          (phone !== "" && phone !== null) &&
          (commodity !== "" && commodity !== null && commodity !== "Select")
        ) {
          // Calling Enquiry API
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
      
          var raw = JSON.stringify({
            name: name,
            phoneNo: phone,
            interest: commodity,
            enquiryFor: props.page,
          });
      
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
      
          fetch(ENQUIRY_FORM, requestOptions)
            .then((response) => response.text())
            .then((result) => setSuccess(true))
            .catch((error) => toast.error(error));
          // Enquiry End
        } else {
          if (name === "" || name === null) {
            setNameError("Please Enter Name");
          }
          if (phone === "" || phone === null) {
            setPhoneError("Please Enter Phone");
          }
          if (commodity === "" || commodity === null || commodity === "Select") {
            setCommodityError("Please Select Commodity");
          }
          return;
        }
      };
      

    const scrollToTop = () =>{
        console.log('working');
        window.location.reload();
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };    
    return (
        <div class="formCard form-css" style={{marginTop:"-50px"}}>
            <div class="card-body formCardBody" style={{ height: 'auto' }}>
                {
                    success === true ?
                        <>
                            <div className='imageBody mt-5'>
                                <img src={successImage} alt="" className='image' />
                            </div>
                            <div className='mt-4 mx-2'>
                                <p className="Thanks" style = {{marginLeft: '30px'}}>
                                    Thank you for your interest!
                                </p>
                                <p className="getBackToU" style = {{fontWeight: 'bold', marginLeft: '30px'}}>
                                    Our representative will get back to you<br /> as soon as possible.
                                </p>
                            </div>
                            <div>
                                <button type="" class="btn btn-primary mt-3" className="backToTopBtn" onClick={()=>scrollToTop()} style={{background:'#fff', marginLeft: '30px', marginTop : '30px', marginBottom: '20px', width: '100%',  color:'#04B23D', border:'1px solid #04B23D'}}>Back to Top</button>
                            </div>
                        </>
                        :
                        <form className='mt-3 mx-4'>
                            {/* <p>
                                <span>
                                    {
                                        props.text
                                    }
                                </span>
                                <br />
                                <span style={{ color: '#04B23D' }}>
                                    {
                                        props.text2
                                    }
                                </span>
                            </p> */}
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Your Name</label>
                                <input type="text" class="form-control mt-2" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder='What is your name?' style={{ height: '50px' }}
                                    onChange={(e) => handleNameChange(e)} value={name}
                                />
                                <p className="redValidation" style={{ color: 'red' }}><small><small>{nameError}</small></small></p>
                            </div>
                            <div class=" mb-3" >
              <p style={{fontWeight: 'bold', fontSize: 'Medium'}} className="enterMobileLabel"> Enter your Phone Number </p>
              <div class = "input-box-1" >
              <div className="form-control form-css-1" style={{padding: "0.6rem 2.75rem" , borderRadius: "8px"}}>
                {/* 
                <label for="exampleInputPassword1" class="form-label">Enter your phone number</label> 
                */}
                <div>

                <span class="prefix-1" style = {{
                  color:
                  '#757575', 
                  fontSize: 'Medium'
                }}>+91 |</span>
                <input
                  type="text"
                  placeholder="Phone Number"
                  minLength="10"
                  class = "input-text-css "
                  className='inner-input'
                  maxLength="10"
                  value={phone}
                  style = {{ fontFamily: 'MetroPolisExtraLight', marginBottom: '0px !important' }}
                  onChange={(e) => handlePhoneChange(e)}
                  />            
                  </div>
              </div>
              <p className="redValidation" style={{ color: "red" }}>
                  {/* <small> */}
                  {/* &nbsp; */}
                  <table>
  {/* <tr> <td>Tel.</td>  <td>012345</td> </tr> */}
                  {/* {phoneError !== ''} */}
                    <small>{phoneError}</small>
  <td></td>
</table>
                    {/* <br></br> */}

                  {/* </small> */}
                </p>
                  </div>
            </div>
                            <div class="mb-3 mt-4 ">
                                <label for="exampleInputPassword1" class="form-label" className='whichCommodityLabel'>Which commodities are you interested in?</label>
                                <select required name="interest" id="field" className='form-control form-commodity-css' onChange={(e) => handleCommonityChange(e)} aria-label="Default select example" style={{ height: '50px', color: 'grey' }}>
                                {
                                    CommodityItems.map((item)=>(
                                        <option style={{color:"#000"}} value={item.name}>{item.name}</option>
                                    ))
                                }
                                </select>
                                <p className="redValidation" style={{ color: 'red' }}><small><small>{commodityError}</small></small></p>


                            </div>
                            <button class="btn btn-primary mt-3" onClick={formSubmit}>Submit</button>
                            <div style={{height:"20px"}}></div>
                        </form>
                }

            </div>
           
        </div>
    )
}

export default Form