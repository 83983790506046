import React, { useEffect } from 'react';
import '../style/Header.css';
import vectorHalfRectangle from '../../../assets/icons/vector_half_rectangle.png';
import vectorArrow from '../../../assets/icons/vector_arrow.png';
import 'react-bootstrap-accordion/dist/index.css';
import Congratulations from './congratulations';
import { toast } from 'react-toastify';
import { fireAjaxRequest, history } from "../../../utils/Utility"
import { GET_DETAILS_BY_PIN, UPLOAD_FILE, SAVE_ADD_DOCS, AADHAAR_CONSENT_API, KYC_VERIFY_UAN, SIGNATORY_ADD_DOCS, GET_AADHAAR_API, PARTNER_ADD_DOCS, GET_PINCODE_DETAILS, PUT_MSME_DETAILS } from "../../../utils/Api"
import pdfIcon from '../../../assets/icons/pdf_icon.png'
import closeIcon from '../../../assets/icons/close_icon.png'
import { useState } from 'react';
import Gif from './gif';

//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}
function UploadNonGSTDocuments(props) {
  let num = 5;
  const [numVal, setNumVal] = useState(num);
  let rId = '';
  let org = '';
  const forceUpdate = useForceUpdate();
  const [udyamAadharLink, setUdhyamAadharLink] = useState([]);
  const [aadharLink, setAadharLink] = useState([]);
  const [panLink, setPanLink] = useState([])
  const [aadharInput, setAadharInput] = useState('');
  const [aadharName, setAadharName] = useState('');
  const [passportLink, setPassportLink] = useState([])
  const [gstLink, setGstLink] = useState([])
  const [voterIdLink, setVoterIDLink] = useState([])
  const [cibilConsentLink, setCibilConsentLink] = useState([])
  const [shopEstLink, setShopEst] = useState([])
  const [mandiLicenceLink, setMandiLicence] = useState([])
  const [utilityLinks, setUtility] = useState([])
  const [fileTypeLink, setFileTypeLink] = useState([])
  const [fileName, setFileName] = useState([])
  const [fileType, setFileType] = useState([])
  const [docNo, setDocNo] = useState('')
  const [aadharOtpSent, setAadharOTPSent] = useState(false);
  const [aadharOtpVerify, setAadharOtpVerify] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [caseId, setCaseId] = useState('');
  const [shareCode, setShareCode] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [aadharDropdown, setAadharDropdown] = useState(false)
  const [voterIdDropdown, setVoterIdDropdown] = useState(false)
  const [passportDropdown, setPassportDropdown] = useState(false)
  const [partner, setPartner] = useState("Partner 1")
  const [partnerDocs, setPartnerDocs] = useState([])
  const [signatoryDocs, setSignatoryDocs] = useState([])
  const [documentUploded, setDocumentUploaded] = useState(false)
  const [upload, setUpload] = useState(false);
  const [reqId, setRequestId] = useState('');
  const [orgType, setOrgType] = useState('');
  const [vintage, setVintage] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  // const [isMsmeRegistere]
  const [organizationName, setOrganization] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPin] = useState('')

  rId = props.requestId
  org = props.oType


  sessionStorage.setItem("requestID", rId)
  sessionStorage.setItem("orgType", org);


  useEffect(() => {
    //document.getElementById("partnerDrp").selectedIndex = 0
      document.getElementById("btnback").style.display="block";
    setRequestId(rId);
    setOrgType(org);
  })

  const msme = props.msme;

  const changeText = (e, prefix, docType) => {
    orgType == "Proprietorship" ? setNumVal(1) : setNumVal(5)

    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      document.getElementById(e).innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Add More';
      document.getElementById("docDrp").value = "Select Documents";

      onClickFile(files[0], prefix, docType)
    };
    input.click();
  }
  const [documents, setDocuments] = React.useState(false);

  const onClickFile = (e, prefix, docType) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    setFileName(e.name);
    setFileType(e.type);
    if (e.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.type == "video/mp4") {
      toast.error("Please Upload the correct file format")
    } else if (e.size > 10485760) {
      toast.error("Uploaded file is more than 10MB")
    }
    else {
      let formData = new FormData();

      formData.append("file", e);
      formData.append("prefix", prefix);
      (async () => {

        try {
          const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
            method: 'POST',
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: formData
          });
          if (resp && resp.filePath) {
            if (prefix === "udhyamaadhar") {
              let arr = udyamAadharLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(docNo);
              arr.push(obj);
              setUdhyamAadharLink(arr);
              forceUpdate();
              setUpload(false);
              // document.getElementById("uan_div").style.display = 'none';
              document.getElementById("btnUdyog").style.display = 'none';
              document.getElementById("lblVerified").style.display = 'none';
              //document.getElementById("partnerDrp").value="Select Signatory";


            }
            else if (prefix === "pan") {
              let arr = panLink;
              let obj = [];
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setPanLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            } else if (prefix === "gst") {
              let arr = gstLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setGstLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            } else if (prefix === "passport") {
              try {
                var selIndex = document.getElementById("partnerDrp").selectedIndex;
                if (selIndex == 0) {
                  toast.error("Please " + document.getElementById("partnerDrp").value)
                  return false;
                }
              }
              catch
              {

              }
              let arr = passportLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(partner);
              arr.push(obj)
              setPassportLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0;
              }
              catch {

              }
            } else if (prefix === "VoterID") {
              try {
                var selIndex = document.getElementById("partnerDrp").selectedIndex;
                if (selIndex == 0) {
                  toast.error("Please " + document.getElementById("partnerDrp").value)
                  return false;
                }
              }
              catch {

              }

              let arr = voterIdLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(partner);
              arr.push(obj)

              setVoterIDLink(arr)
              forceUpdate();

              try {
                document.getElementById("partnerDrp").selectedIndex = 0;
              }
              catch {

              }
            }
            else if (prefix === "cibilConsent") {
              let arr = cibilConsentLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setCibilConsentLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "shopest") {
              let arr = shopEstLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setShopEst(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "mandiLicence") {
              let arr = mandiLicenceLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setMandiLicence(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "utility") {
              let arr = utilityLinks;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setUtility(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "aadhar") {
              try {
                var selIndex = document.getElementById("partnerDrp").selectedIndex;
                if (selIndex == 0) {
                  toast.error("Please " + document.getElementById("partnerDrp").value)
                  return false;
                }
              }
              catch
              {

              }

              let arr = aadharLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(partner);
              arr.push(obj)
              setAadharLink(arr);
              forceUpdate();
              verifyAadharConsent(aadharInput, "")
              document.getElementById("aadharName").value = ""
              document.getElementById("aadharNo").value = ""
              try {
                document.getElementById("partnerDrp").selectedIndex = 0;
              }
              catch {

              }
            }
          } else {
            toast.error("Failed to upload file !");
            if (prefix === "udhyamaadhar") {
              setUdhyamAadharLink([])
              forceUpdate();
            }
            else if (prefix === "pan") {
              setPanLink([])
              forceUpdate();
            } else if (prefix === "gst") {
              setGstLink([])
              forceUpdate();
            } else if (prefix === "Passport") {
              setPassportLink([])
              forceUpdate();
            } else if (prefix === "VoterId") {
              setVoterIDLink([])
              forceUpdate();
            } else if (prefix === "cibilConsent") {
              setCibilConsentLink([])
              forceUpdate();
            }
            else if (prefix === "aadhar") {
              setAadharLink([])
              forceUpdate();
            }
          }
        }
        catch (err) {
          toast.error(err.message);
          if (prefix === "udhyamaadhar") {
            setUdhyamAadharLink([])
            forceUpdate();
          }
          else if (prefix === "pan") {
            setPanLink([])
            forceUpdate();
          } else if (prefix === "gst") {
            setGstLink([])
            forceUpdate();
          } else if (prefix === "Passport") {
            setPassportLink([])
            forceUpdate();
          } else if (prefix === "VoterId") {
            setVoterIDLink([])
            forceUpdate();
          } else if (prefix === "cibilConsent") {
            setCibilConsentLink([])
            forceUpdate();
          }
          else if (prefix === "aadhar") {
            setAadharLink([])
            forceUpdate();
          }
        }
      })();
    }
  }

  const aadharNameChanged = (e) => {
    setAadharName(e.target.value)
    forceUpdate()
  }

  const handleDropdown = (e) => {
    setAadharDropdown(false);
    setPassportDropdown(false);
    setVoterIdDropdown(false);
    if (e.target.value === 'Aadhaar Card') {
      setAadharDropdown(true);
      forceUpdate()
    }
    else if (e.target.value === 'Voter_Id') {
      setVoterIdDropdown(true);
      forceUpdate()
    }
    else if (e.target.value === 'Passport') {
      setPassportDropdown(true);
      forceUpdate()
    }
    forceUpdate()
  }

  const handlePartner = (e) => {
    setPartner(e.target.value);
    forceUpdate()
  }

  const aadharNoChanged = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setAadharInput(e.target.value)
      forceUpdate()
      if (e.target.value.length == 12) {
        verifyAadharConsent(e.target.value, aadharName)
      }
    }
    else {
      toast.error("Only numeric allowed")
      e.target.value = e.target.value.substring(0, e.target.value.length - 1)
      return false;
    }
  }

  const handleOtpInput = (e, index) => {

    let selected = document.getElementsByClassName("input-css-3");
    if (index <= 5 && e.target.value) {
      selected[index].focus();
    } else {
      // OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
    }
    setOtpInput(otpInput + e.target.value);
    forceUpdate();
  }

  const handleKeyDown = (event) => {
    try {
      if (event.key === 'Backspace') {
        let selected = document.getElementsByClassName("input-css-3");
        selected[event.target.id - 2].focus();
        selected[event.target.id - 1].value = '';

        // document.getElementById(event.target.id - 2)?.focus();
      }
      var k = event.which;
      /* numeric inputs can come from the keypad or the numeric row at the top */
      if (event.key == "@" || event.key == "!" || event.key == "#" || event.key == "$" || event.key == "%" || event.key == "^" || event.key == "&" || event.key == "*" || event.key == ")" || event.key == "(") {
        event.preventDefault();
        return false;
      }

      if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
        event.preventDefault();
        return false;
      }


    }
    catch {

    }
  };
  const verifyOTP = () => {
    const verifyOTPDetails = {
      "consent": "Y",
      "otp": otpInput,
      "shareCode": shareCode,
      "aadhaarNo": aadharInput,
      "accessKey": accessKey,
      "clientData": {
        "caseId": "a341fafb"
      }
    };

    (async () => {
      try {
        const resp = await fireAjaxRequest(`${GET_AADHAAR_API}`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(verifyOTPDetails)
        });

        if (resp.status === true) {
          setAadharOtpVerify(true)
          setAadharOTPSent(false)

          toast.success("Aadhar Successfully Verified!")
        }
        else {
          setAadharOtpVerify(false)
          //setAadharOTPSent(false)

          toast.success("Invalid OTP")
        }


        if (resp) {
          setOtpInput('');
          if (resp.message.includes("400")) {
            toast.error("Mandatory fields are missing / invalid")
          }
          else if (resp.message.includes("401")) {
            toast.error("API Key is missing or invalid.")
          }
          else if (resp.message.includes("402")) {
            toast.error("Credits to access the APIs expired.")
          }
          else if (resp.message.includes("104")) {
            toast.error("Internal processing error of Karza.")
          }
          else if (resp.message.includes("102")) {
            toast.error("Internal processing error of Karza.")
          }
          else if (resp.message.includes("500")) {
            toast.error("Internal processing error of Karza.")
          }
          else if (resp.message.includes("503")) {
            toast.error("The source for authentication is down for maintenance or inaccessible.")
          }
          else if (resp.message.includes("504")) {
            toast.error("The response latency from the source for authentication is >30sec.")
          }
          //this.setState({ loading: false, showOTP: false, showUpload: false })
        }
        // this.setState({ loading: false })
      } catch (err) {
        toast.error(err.message)
        //this.setState({ loading: false })
      }
    })();
  }

  const handleInputChangeField = (e) => {
    setDocNo(e.target.value)
    forceUpdate();

    if (e.target.value.length == 12) {
      var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(KYC_VERIFY_UAN + "consent=" + "Y" + "&uanNo=" + e.target.value, requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result)
          console.log('res----', result);

          if (result.statusCode == "101") {
            setUpload(true)
            setRegistrationDate(result.result.dateOFCommencement)
            setOrganization(result.result.nameofEnterPrise)
            forceUpdate();
            document.getElementById("btnUdyog").style.display = "block";
            document.getElementById("lblVerified").style.display = "block";
            // setAddress(result.result.businessAddress)
            // setCity(result.result.district)
            // setState(result.result.state)
          }
          else {
            setUpload(false)
          }
        })
        .catch(error => console.log('error', error));
    }
  }


  const handleInputPinField = (e) => {
    setPin(e.target.value)
    if (e.target.value.length == 6) {
      var raw = "";
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(GET_DETAILS_BY_PIN + e.target.value, requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result);
          setState(result[0].PostOffice[0].State)
          setCity(result[0].PostOffice[0].District)
        })
        .catch(error => console.log('error', error));
      forceUpdate();
    }
  }

  function calculateVinatge(dt) {
    var date1 = new Date();
    var dd = String(date1.getDate()).padStart(2, '0');
    var mm = String(date1.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date1.getFullYear();
    date1 = mm + '/' + dd + '/' + yyyy;
    var date2 = dt;
    var Difference_In_Time = new Date(date1).getTime() - new Date(date2).getTime();
    var numberOfDays = Difference_In_Time / (1000 * 3600 * 24);
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);

    let vintageD = years + " year, " + months + " month, " + days + " day";
  }
  const handleInputAddressField = (e) => {
    setAddress(e.target.value)
    forceUpdate();

    // if (e.target.value.length == 12) {
    //   var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    //   var myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append("Authorization", "Bearer " + token);

    //   var requestOptions = {
    //     method: 'GET',
    //     headers: myHeaders,
    //     redirect: 'follow'
    //   };

    //   fetch(KYC_VERIFY_UAN + "consent=" + "Y" + "&uanNo=" + e.target.value, requestOptions)
    //     .then(response => response.text())
    //     .then(result => {
    //       result = JSON.parse(result)
    //       if (result.statusCode == "101") {
    //         setUpload(true)
    //       }
    //       else {
    //         setUpload(false)
    //       }
    //     })
    //     .catch(error => console.log('error', error));
    // }
  }
  const handleClick = () => {
    setDocuments(true);
    document.getElementById("uploadDocsLabel").style.color = "#04B23D"
  }
  const removeFile = (e) => {
    let arr = udyamAadharLink;
    arr.splice(e, 1)
    setUdhyamAadharLink(arr);
    setUpload(false);
    forceUpdate();
    document.getElementById("btnUdyog").style.display = "none";
    document.getElementById("lblVerified").style.display = "none";
    if (udyamAadharLink.length == 0) {
      try
      {
      document.getElementById("btnUdyog").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {
        
      }
    }
  }

  const removePanFile = (e) => {
    let arr = panLink;
    arr.splice(e, 1)
    setPanLink(arr);
    forceUpdate();
    if (panLink.length == 0) {
      try
      {
      document.getElementById("btnPan").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeGstFile = (e) => {
    let arr = gstLink;
    arr.splice(e, 1)
    setGstLink(arr);
    forceUpdate();
    // if(gstLink.length==0)
    // {
    //   document.getElementById("btnGst").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
    // }
  }

  const removeVoterIdFile = (e) => {
    let arr = voterIdLink;
    arr.splice(e, 1)
    setVoterIDLink(arr);
    forceUpdate();
    if (voterIdLink.length == 0) {
      try
      {
      document.getElementById("btnVoterId").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removePassportFile = (e) => {
    let arr = passportLink;
    arr.splice(e, 1)
    setPassportLink(arr);
    forceUpdate();
    if (passportLink.length == 0) {
      try
      {
      document.getElementById("btnPassport").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeAadharFile = (e) => {
    let arr = aadharLink;
    arr.splice(e, 1)
    setAadharLink(arr);
    forceUpdate();
    if (aadharLink.length == 0) {
      try
      {
      document.getElementById("btnAadhar").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeCibilFile = (e) => {
    let arr = cibilConsentLink;
    arr.splice(e, 1)
    setCibilConsentLink(arr);
    forceUpdate();
    if (cibilConsentLink.length == 0) {
      try
      {
      document.getElementById("btnCibil").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
    catch
    {

    }
    }
  }
  const removeShopestFile = (e) => {
    let arr = shopEstLink;
    arr.splice(e, 1)
    setShopEst(arr);
    forceUpdate();

    if (shopEstLink.length == 0) {
      try
      {
      document.getElementById("btnShop").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeMandiLicence = (e) => {
    let arr = mandiLicenceLink;
    arr.splice(e, 1)
    setMandiLicence(arr);
    forceUpdate();
    if (mandiLicenceLink.length == 0) {
      try
      {
      document.getElementById("btnMandiLicence").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeUtility = (e) => {
    let arr = utilityLinks;
    arr.splice(e, 1)
    setUtility(arr);
    forceUpdate();
    if (utilityLinks.length == 0) {
      try
      {
      document.getElementById("btnUtility").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }
  const verifyAadharConsent = (aadharNo, name) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    // const browser = detect();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      "value": aadharNo,
      "userAgent": "Chrome",
      "consent": "Y",
      "name": name,
      "consentText": "I hereby grant consent to..",
      "consentTime": "1610369914",
      "lat": "17.300166",
      "longitude": "78.526495",
      "clientData": {
        "caseId": "a341fafb"
      },
      "deviceId": "5FA01EE3-B9AB-42A3-ACB1-B150D0C2CA07"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(AADHAAR_CONSENT_API, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result);
        toast.success(result.message);
        toast.success(result.data.result.message);
        setAccessKey(result.data.result.accessKey);
        setShareCode(result.shareCode);
        setAadharOTPSent(true);
        forceUpdate();
      })
      .catch(error => console.log('error', error));
  }
  const handleClickContinue = () => {

    document.getElementById("uploadDocsLabel").style.color = "#04B23D"
  

    if (udyamAadharLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload Udyam Aadhar Document")
      return false;
    }
    if (pincode.length < 6) {
      toast.error("Please Enter Valid Pincode");
      return false
    }
    if (panLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;
      toast.error("Please Upload PAN Document")
      return false;
    }
    if (aadharLink.length == 0 && voterIdLink.length == 0 && passportLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload Aadhar/Voter Id / Passport Document")
      return false;
    }
    if (cibilConsentLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload CIBIL Consent")
      return false;
    }
    if (shopEstLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload Shop & Establisment Certificate")
      return false;
    }
    if (mandiLicenceLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload Mandi Licence")
      return false;
    }
    if (utilityLinks.length == 0) {
      document.getElementById("continue-btn").disabled = false;
      toast.error("Please Upload Utility Bill")
      return false;
    }
    document.getElementById("continue-btn").disabled = true;

    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var links = []
    udyamAadharLink.map((item) => {
      links.push(item[0])
    })
    var dLinks = JSON.stringify(links);
    dLinks = JSON.parse(dLinks);

    var plinks = []
    panLink.map((item) => {
      plinks.push(item[0])
    })
    var pan_links = JSON.stringify(plinks);
    pan_links = JSON.parse(pan_links);

    var glinks = []
    gstLink.map((item) => {
      glinks.push(item[0])
    })
    var gst_links = JSON.stringify(glinks);
    gst_links = JSON.parse(gst_links);


    var partnerDocuments = partnerDocs

    voterIdLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["partnerName"] = v[2];
      item["documents"] = [docs];
      partnerDocuments.push(item)
      setPartnerDocs(partnerDocuments)
      forceUpdate()
    })

    aadharLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["partnerName"] = v[2];
      item["documents"] = [docs];
      partnerDocuments.push(item)
      setPartnerDocs(partnerDocuments)
      forceUpdate()
      document.getElementById("aadharName").value = ""
      document.getElementById("aadharNo").value = ""
    })

    passportLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["partnerName"] = v[2];
      item["documents"] = [docs];
      partnerDocuments.push(item)
      setPartnerDocs(partnerDocuments)
      forceUpdate()
    })


    var signatoryDocuments = signatoryDocs
    setSignatoryDocs(signatoryDocuments)

    forceUpdate()
    voterIdLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["signatoryName"] = v[2];
      item["documents"] = [docs];
      signatoryDocuments.push(item)
      setSignatoryDocs(signatoryDocuments)
      forceUpdate()
    })

    aadharLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["signatoryName"] = v[2];
      item["documents"] = [docs];
      signatoryDocuments.push(item)
      setSignatoryDocs(signatoryDocuments)
      forceUpdate()
      document.getElementById("aadharName").value = ""
      document.getElementById("aadharNo").value = ""
    })

    passportLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["signatoryName"] = v[2];
      item["documents"] = [docs];
      signatoryDocuments.push(item)
      setSignatoryDocs(signatoryDocuments)
      forceUpdate()
    })

    var clinks = []
    cibilConsentLink.map((item) => {
      clinks.push(item[0])
    })
    var cibil_links = JSON.stringify(clinks);
    cibil_links = JSON.parse(cibil_links);

    var slinks = []
    shopEstLink.map((item) => {
      slinks.push(item[0])
    })
    var shopest_links = JSON.stringify(slinks);
    shopest_links = JSON.parse(shopest_links);

    var mlinks = []
    mandiLicenceLink.map((item) => {
      slinks.push(item[0])
    })
    var mandi_links = JSON.stringify(mlinks);
    mandi_links = JSON.parse(mandi_links);

    var ulinks = []
    utilityLinks.map((item) => {
      ulinks.push(item[0])
    })
    var utility_links = JSON.stringify(ulinks);
    utility_links = JSON.parse(utility_links);

    var raw1 = JSON.stringify({
      "traderId": sessionStorage.getItem("traderID"),
      "requestId": reqId,
      "msmeNo": docNo,
      "msmeDocLink": dLinks[0],
      "vintage": calculateVinatge(registrationDate),
      "registrationDate": registrationDate,
      "isMSMEregistered": true,
      "organizationName": organizationName,
      "address": address,
      "city": city,
      "state": state,
      "pincode": pincode
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw1,
      redirect: 'follow'
    };

    fetch(PUT_MSME_DETAILS, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));


    var raw = JSON.stringify({
      "traderId": sessionStorage.getItem("traderID"),
      "ecashRequestId": reqId,
      "isAdditionalDocRequest": false,
      "documents": [
        {
          "id": 0,
          "docType": "MSME/UDYAM_CERTIFICATE",
          "docName": fileName,
          "docNo": docNo,
          "docLink": dLinks
        },
        {
          "id": 0,
          "docType": "PAN",
          "docName": fileName,
          "docNo": '',
          "docLink": pan_links
        },
        {
          "id": 0,
          "docType": "GST_CERTIFICATE",
          "docName": fileName,
          "docNo": '',
          "docLink": gst_links
        },
        {
          "id": 0,
          "docType": "CIBIL_REPORT",
          "docName": fileName,
          "docNo": '',
          "docLink": cibil_links
        },
        {
          "id": 0,
          "docType": "SHOPS_AND_ESTABLISHMENT_CERTIFICATE",
          "docName": fileName,
          "docNo": '',
          "docLink": shopest_links
        },
        {
          "id": 0,
          "docType": "MANDI_LICENSE",
          "docName": fileName,
          "docNo": '',
          "docLink": mandi_links
        },
        {
          "id": 0,
          "docType": "UTILITY_BILL",
          "docName": fileName,
          "docNo": '',
          "docLink": utility_links
        }
      ]
    });
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(SAVE_ADD_DOCS, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        if (result.statusCode == "200") {
          toast.success("Successfully updated")
          setDocumentUploaded(true)
          history.push('/gif')
        }
        else {
          document.getElementById("continue-btn").disabled = false;
          toast.error("document not uploaded yet")
        }
      }
      )
      .catch(error => console.log('error', error));
    if (orgType === "Partnership") {
      var raw1 = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "ecashRequestId": reqId,
        "partnerDocuments": partnerDocs
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw1,
        redirect: 'follow'
      };

      fetch(PARTNER_ADD_DOCS, requestOptions)
        .then(response => response.text())
        .then(result =>

          console.log('result', result))
        .catch(error => console.log('error', error));
    }
    if (orgType === "Company" || orgType === "LLP") {

      var raw2 = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "ecashRequestId": reqId,
        "signatoryDocuments": signatoryDocs
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw2,
        redirect: 'follow'
      };

      fetch(SIGNATORY_ADD_DOCS, requestOptions)
        .then(response => response.text())
        .then(result => console.log('result', result))
        .catch(error => console.log('error', error));
    }
  }


  return (
    <>
      {
        documentUploded === false ?
          <div className="bottom-section">
            <div className="entity-css">
              <label for="name" className='breadcrumbsLabel' style={{ marginLeft: "20px" }}>
                Upload Documents
                <span
                  aria-hidden="true"
                  class="required"
                  style={{ color: '#04B23D', marginLeft: '5px' }}
                >
                  *
                </span>
              </label>
              <div className='gap'></div>
              <div class="accordion eCashAccordian" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      MSME/Udyam Certificate
                      <i title="Upload MSME/Udyam Certificate" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }} >
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body" style={{ marginLeft: '-22px' }}>
                      {
                        udyamAadharLink.map((item, key) => (
                          <div key={key} className="panel_copy" style={{
                            marginLeft: '20px',
                            marginBottom: '10px'
                          }}>
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeFile(key) }} />
                            </i><br /></div>
                        ))
                      }

                      {
                        udyamAadharLink.length == 1 ? <></> :
                          <>
                            <input class="form-control  input-css-class"
                              placeholder='Enter Udyog Aadhar No.' id="txtUdyog" onChange={(e) => { handleInputChangeField(e) }} style={{ marginBottom: '20px' }} />
                          </>
                      }
                      <>
                        <input class="form-control  input-css-class"
                          placeholder='Enter Business Address.' onChange={(e) => { handleInputAddressField(e) }} style={{ marginBottom: '20px' }} />
                        <input maxLength={6} class="form-control  input-css-class"
                          placeholder='Enter PinCode' onChange={(e) => { handleInputPinField(e) }} />
                      </>

                      {
                        <div id="uan_div">

                          <div className='clearfix' style={{ height: "10px" }}></div>

                          <span id="lblVerified" style={{ fontSize: "12px", display: "none", marginLeft: "20px", color: '#04B23D' }}>
                            Verified
                          </span>
                          <button style={{ display: "none" }} class="btn btn-sm btn-success btn-upload " id="btnUdyog" type="file" name={"Aadhar"} onClick={(e) => {
                            changeText("btnUdyog", "udhyamaadhar", "MSME/UDYAM_CERTIFICATE")
                          }}>
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }}
                            >
                              Upload
                            </span>
                          </button>
                        </div>
                      }
                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      {
                        orgType == "Proprietorship" ? "PAN Card" : orgType == "Partnership" ? "PAN Card of all Partners" : "PAN Card of all authorized signatory"
                      }
                      <i title="Upload PAN" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="``btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {
                        panLink.map((item, key) => (
                          <div className="panel_copy">
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removePanFile(key) }} />
                            </i><br /></div>
                        ))
                      }
                      {
                        panLink.length == numVal ?
                          <></>
                          :
                          <button class="btn btn-sm btn-success btn-upload" id="btnPan" onClick={(e) => { changeText("btnPan", "pan", "PAN") }}>
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }}
                            >
                              Upload
                            </span>
                          </button>
                      }
                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Aadhaar/ Passport/Voter ID <i title="Upload Aadhaar/ Passport/Voter ID" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }} >
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>

                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    {
                      orgType == "Proprietorship" ? <>
                        <div class="accordion-body">


                          <select name="limit" id="docDrp" onChange={(e) => { handleDropdown(e) }} className="limit-css-1 limit-1">
                            <option className="limit-css-1" value="Select Documents">Select Documents</option>
                            <option className="limit-css-1" value="Aadhaar Card">Aadhaar Card</option>
                            <option className="limit-css-1" value="Voter_Id">Voter Id</option>
                            <option className="limit-css-1" value="Passport">Passport</option>
                          </select>
                          <div>
                            {
                              aadharLink.map((item, key) => (
                                <div className="panel_copy">

                                  <a className='panel_links' href={item[0]} target='_blank'>
                                    <img style={{ width: '5%' }} src={pdfIcon} />
                                    {item[1]}
                                  </a>
                                  <i>
                                    <img className='removeBtn' src={closeIcon}
                                      onClick={(e) => { removeAadharFile(key) }}
                                    />
                                  </i><br /></div>
                              ))
                            }
                            {
                              aadharDropdown === true ? <div>

                                <input id="aadharName" onChange={(e) => { aadharNameChanged(e) }} class="form-control input-css-2"
                                  placeholder='Enter Aadhar Name' />
                                <input id="aadharNo" onChange={(e) => { aadharNoChanged(e) }} class="form-control  input-css-2" maxLength={12}
                                  placeholder='Enter Aadhar No.' />
                                {
                                  aadharOtpSent === true ?
                                    <div class="otp-section">
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 1) }} maxLength={1} id="1" class="form-control OTP-Class input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 2) }} maxLength={1} id="2" class="form-control OTP-Class input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 3) }} maxLength={1} id="3" class="form-control OTP-Class input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 4) }} maxLength={1} id="4" class="form-control OTP-Class input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 5) }} maxLength={1} id="5" class="form-control OTP-Class input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 6) }} maxLength={1} id="6" class="form-control  OTP-Class input-css-3"
                                        placeholder='X' />
                                      <button onClick={(e) => { verifyOTP(e) }} class="btn btn-sm btn-success btn-upload">
                                        <span style={{ marginLeft: '5px  ' }}

                                        >
                                          Verify
                                        </span>
                                      </button>
                                    </div>

                                    : ""
                                }
                                {
                                  aadharOtpVerify === true ?
                                    aadharLink.length == 1 ?
                                      <> </>
                                      :
                                      <>
                                        <button class="btn btn-sm btn-success btn-upload" id="btnAadhar" onClick={(e) => { changeText("btnAadhar", "aadhar", "AADHAAR") }}>
                                          <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                          <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                          <span style={{ marginLeft: '5px' }}
                                          >
                                            Upload
                                          </span>
                                        </button>
                                      </>
                                    : ""
                                }
                              </div>
                                :
                                ""
                            }
                            {
                              <div>
                                {
                                  voterIdLink.map((item, key) => (
                                    <div className="panel_copy">

                                      <a className='panel_links' href={item[0]} target='_blank'>
                                        <img style={{ width: '5%' }} src={pdfIcon} />
                                        {item[1]}
                                      </a>
                                      <i>
                                        <img className='removeBtn' src={closeIcon}
                                          onClick={(e) => { removeVoterIdFile(key) }}
                                        />
                                      </i><br /></div>
                                  ))
                                }
                                {
                                  voterIdLink.length == 1 ?
                                    <></>
                                    : voterIdDropdown === true ?
                                      <>
                                        <br />
                                        <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnVoterId" onClick={(e) => { changeText("btnVoterId", "VoterID", "VOTER_ID") }}>
                                          <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                          <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                          <span style={{ marginLeft: '5px' }}
                                          >
                                            Upload
                                          </span>
                                        </button>
                                      </> : ""
                                }
                              </div>

                            }
                            {
                              <div>
                                {
                                  passportLink.map((item, key) => (
                                    <div className="panel_copy">

                                      <a className='panel_links' href={item[0]} target='_blank'>
                                        <img style={{ width: '5%' }} src={pdfIcon} />
                                        {item[1]}
                                      </a>
                                      <i>
                                        <img className='removeBtn' src={closeIcon}
                                          onClick={(e) => { removePassportFile(key) }}
                                        />
                                      </i><br /></div>
                                  ))
                                }
                                {
                                  passportLink.length == 1 ?
                                    <></>
                                    : passportDropdown === true ?
                                      <>
                                        <br />
                                        <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnPassport" onClick={(e) => { changeText("btnPassport", "passport", "PASSPORT") }}>
                                          <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                          <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                          <span style={{ marginLeft: '5px' }}
                                          >
                                            Upload
                                          </span>
                                        </button>
                                      </> : ""
                                }
                              </div>

                            }
                          </div>

                          <div className='clearfix' style={{ height: "30px" }}></div>

                          <div className='clearfix' style={{ height: "30px" }}></div>
                        </div>
                      </> : <>
                        {/* For Company and Partnership */}
                        <div class="accordion-body">

                          {

                            orgType == "Partnership" ?
                              <select name="limit" id="partnerDrp" className="limit-css-1 limit-1" onChange={(e) => { handlePartner(e) }}>
                                <option className="limit-css-1" value="Select Partner" selected>Select Partner</option>
                                <option className="limit-css-1" value="Partner 1">Partner 1</option>
                                <option className="limit-css-1" value="Partner 2">Partner 2</option>
                                <option className="limit-css-1" value="Partner 3">Partner 3</option>
                                <option className="limit-css-1" value="Partner 4">Partner 4</option>
                                <option className="limit-css-1" value="Partner 5">Partner 5</option>
                              </select>
                              :
                              <select name="limit" id="partnerDrp" className="limit-css-1 limit-1" onChange={(e) => { handlePartner(e) }}>
                                <option className="limit-css-1" value="Select Signatory" selected>Select Signatory</option>
                                <option className="limit-css-1" value="Signatory 1">Signatory 1</option>
                                <option className="limit-css-1" value="Signatory 2">Signatory 2</option>
                                <option className="limit-css-1" value="Signatory 3">Signatory 3</option>
                                <option className="limit-css-1" value="Signatory 4">Signatory 4</option>
                                <option className="limit-css-1" value="Signatory 5">Signatory 5</option>
                              </select>
                          }
                          <br />
                          <select name="limit" id="docDrp" onChange={(e) => { handleDropdown(e) }} className="limit-css-1 limit-1">
                            <option className="limit-css-1" value="Select Documents">Select Documents</option>
                            <option className="limit-css-1" value="Aadhaar Card">Aadhaar Card</option>
                            <option className="limit-css-1" value="Voter_Id">Voter Id</option>
                            <option className="limit-css-1" value="Passport">Passport</option>
                          </select>
                          <div>
                            {
                              aadharLink.map((item, key) => (
                                <div className="panel_copy">
                                  <h3>{item[2]}</h3>
                                  <a className='panel_links' href={item[0]} target='_blank'>
                                    <img style={{ width: '5%' }} src={pdfIcon} />
                                    {item[1]}
                                  </a>
                                  <i>
                                    <img className='removeBtn' src={closeIcon}
                                      onClick={(e) => { removeAadharFile(key) }}
                                    />
                                  </i><br /></div>
                              ))
                            }
                            {
                              aadharDropdown === true ? <div>

                                <input onChange={(e) => { aadharNameChanged(e) }} class="form-control input-css-2"
                                  placeholder='Enter Aadhar Name' />
                                <input onChange={(e) => { aadharNoChanged(e) }} class="form-control  input-css-2" maxLength={12}
                                  placeholder='Enter Aadhar No.' />
                                {
                                  aadharOtpSent === true ?
                                    <div class="otp-section">
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 1) }} maxLength={1} id="1" class="form-control  input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 2) }} maxLength={1} id="2" class="form-control  input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 3) }} maxLength={1} id="3" class="form-control  input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 4) }} maxLength={1} id="4" class="form-control  input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 5) }} maxLength={1} id="5" class="form-control  input-css-3"
                                        placeholder='X' />
                                      <input onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 6) }} maxLength={1} id="6" class="form-control  input-css-3"
                                        placeholder='X' />
                                      <button onClick={(e) => { verifyOTP(e) }} class="btn btn-sm btn-success btn-upload">
                                        <span style={{ marginLeft: '5px  ' }}

                                        >
                                          Verify
                                        </span>
                                      </button>
                                    </div>

                                    : ""
                                }
                                {
                                  aadharOtpVerify === true ?
                                    aadharLink.length == 5 ?
                                      <> </>
                                      :
                                      <>
                                        <button class="btn btn-sm btn-success btn-upload" id="btnAadhar" onClick={(e) => { changeText("btnAadhar", "aadhar", "AADHAAR") }}>
                                          <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                          <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                          <span style={{ marginLeft: '5px' }}
                                          >
                                            Upload
                                          </span>
                                        </button>
                                      </>
                                    : ""
                                }
                              </div>
                                :
                                ""
                            }
                            {
                              <div>
                                {
                                  voterIdLink.map((item, key) => (
                                    <div className="panel_copy">
                                      <h3>{item[2]}</h3>
                                      <a className='panel_links' href={item[0]} target='_blank'>
                                        <img style={{ width: '5%' }} src={pdfIcon} />
                                        {item[1]}
                                      </a>
                                      <i>
                                        <img className='removeBtn' src={closeIcon}
                                          onClick={(e) => { removeVoterIdFile(key) }}
                                        />
                                      </i><br /></div>
                                  ))
                                }
                                {
                                  voterIdLink.length == 5 ?
                                    <></>
                                    : voterIdDropdown === true ?
                                      <>
                                        <br />
                                        <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnVoterId" onClick={(e) => { changeText("btnVoterId", "VoterID", "VOTER_ID") }}>
                                          <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                          <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                          <span style={{ marginLeft: '5px' }}
                                          >
                                            Upload
                                          </span>
                                        </button>
                                      </> : ""
                                }
                              </div>

                            }
                            {
                              <div>
                                {
                                  passportLink.map((item, key) => (
                                    <div className="panel_copy">
                                      <h3>{item[2]}</h3>
                                      <a className='panel_links' href={item[0]} target='_blank'>
                                        <img style={{ width: '5%' }} src={pdfIcon} />
                                        {item[1]}
                                      </a>
                                      <i>
                                        <img className='removeBtn' src={closeIcon}
                                          onClick={(e) => { removePassportFile(key) }}
                                        />
                                      </i><br /></div>
                                  ))
                                }
                                {
                                  passportLink.length == 5 ?
                                    <></>
                                    : passportDropdown === true ?
                                      <>
                                        <br />
                                        <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnPassport" onClick={(e) => { changeText("btnPassport", "passport", "PASSPORT") }}>
                                          <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                          <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                          <span style={{ marginLeft: '5px' }}
                                          >
                                            Upload
                                          </span>
                                        </button>
                                      </> : ""
                                }
                              </div>

                            }
                          </div>

                          <div className='clearfix' style={{ height: "30px" }}></div>

                          <div className='clearfix' style={{ height: "30px" }}></div>
                        </div></>
                    }



                  </div>
                </div>
                {/* <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      GST Certificate <i class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true" ></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {
                        gstLink.map((item, key) => (
                          <div className="panel_copy">
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeGstFile(key) }} />
                            </i><br /></div>
                        ))
                      }
                      {
                        gstLink.length == 5 ?
                          <></>
                          :
                          <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnGst" onClick={(e) => { changeText("btnGst", "gst", "GST_CERTIFICATE") }}  >
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }} >
                              Upload
                            </span>
                          </button>
                      }
                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div> */}
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Cibil Consent <i title="Upload Cibil Consent" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }} >
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {
                        cibilConsentLink.map((item, key) => (
                          <div className="panel_copy">
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeCibilFile(key) }} />
                            </i><br /></div>
                        ))
                      }
                      {
                        cibilConsentLink.length == 5 ?
                          <></>
                          :
                          <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnCibil" onClick={(e) => { changeText("btnCibil", "cibilConsent", "CIBIL_REPORT") }}  >
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }} >
                              Upload
                            </span>
                          </button>
                      }
                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseFive">
                      Shop & Establishment Certificate <i title="Shop & Establishment Certificate" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {
                        shopEstLink.map((item, key) => (
                          <div className="panel_copy">
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeShopestFile(key) }} />
                            </i><br /></div>
                        ))
                      }
                      {
                        shopEstLink.length == 5 ?
                          <></>
                          :
                          <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnShop" onClick={(e) => { changeText("btnShop", "shopest", "SHOPS_AND_ESTABLISHMENT_CERTIFICATE") }} >
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }}>
                              Upload
                            </span>
                          </button>
                      }

                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSix">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Mandi Licence <i title="Upload Mandi Licence" class="fa fa-info-circle info-class " style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {
                        mandiLicenceLink.map((item, key) => (
                          <div className="panel_copy">
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeMandiLicence(key) }} />
                            </i><br /></div>
                        ))
                      }
                      {
                        mandiLicenceLink.length == 5 ?
                          <></>
                          :
                          <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnMandiLicence" onClick={(e) => { changeText("btnMandiLicence", "mandiLicence", "MANDI_LICENSE") }}  >
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }}>
                              Upload
                            </span>
                          </button>
                      }

                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSeven">
                    <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      Utility Bill <i title="Upload Utility Bill" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                      <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                        <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                        <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                      </button>
                    </button>
                  </h2>
                  <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {
                        utilityLinks.map((item, key) => (
                          <div className="panel_copy">
                            <h3>{item[2]}</h3>
                            <a className='panel_links' href={item[0]} target='_blank'>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </a>
                            <i>
                              <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeUtility(key) }} />
                            </i><br /></div>
                        ))
                      }
                      {
                        utilityLinks.length == 5 ?
                          <></>
                          :
                          <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnUtility" onClick={(e) => { changeText("btnUtility", "utility", "UTILITY_BILL") }}  >
                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                            <span style={{ marginLeft: '5px' }}>
                              Upload
                            </span>
                          </button>
                      }

                      <div className='clearfix' style={{ height: "30px" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="continue-topheader">
              <button id="continue-btn" type="submit" class="btn btn-primary mt-3 continue-button-1" onClick={handleClickContinue}
              >
                Continue
              </button>
            </div>
          </div>
          :
          <Gif requestId={reqId} oType={orgType} />
      }

    </>
  )
}

export default UploadNonGSTDocuments;
