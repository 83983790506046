import React, { Component } from 'react';
import CanvasJSReact from '../../../assets/@canvasjs/react-charts';
import forwardCircleArrow from '../../../assets/images/forward-circle-arrow.svg'
import backwardCircleArrow from '../../../assets/images/backward-circle-arrow.svg'
import dropdownArrow from '../../../assets/images/dropdownArrow.svg';
 
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class CandlestickChart extends Component {
    state = {
        // dividerIndex: this.props.listOfPrices && this.props.listOfPrices[0].values && this.props.listOfPrices[0].values.length - 1,
        dividerIndex : 0,
        openDividerTop : false
    };
    componentDidUpdate(prevProps) {
        if (prevProps.key1 !== this.props.key1 || prevProps.key2 !== this.props.key2) {
          this.setState({ dividerIndex: 0, openDividerTop : false });
        }
      }
    handleMoveDivider = (direction) => {
        const { dividerIndex } = this.state;
        const { listOfPrices } = this.props;
    
        if (direction === 'forward') {
          if (dividerIndex < listOfPrices.length - 1) {
            this.setState({ dividerIndex: dividerIndex + 1 });
          }
        } 
        else if (direction === 'backward') {
          if (dividerIndex > 0) {
            this.setState({ dividerIndex: dividerIndex - 1 });
          }
        }
    };
    handleCandleClick = (e) => {
        const { dataPointIndex } = e;
        this.setState({ dividerIndex: dataPointIndex });
    };
    handleDividerTopClick = () => {
        this.setState({openDividerTop : !this.state.openDividerTop})
    }
	render() {
        const { dividerIndex } = this.state;
        const { listOfPrices } = this.props;

        const dataPoints = listOfPrices.map(price => {
            const color = price.close > price.open ? "green" : "red";
			return {
                x: new Date(price.date),
                y: [price.open, price.high, price.low, price.close],
                color: color
			};
		});

        const minPrice = Math.min(...dataPoints.map(point => point.y[2]))-2000;
        const maxPrice = Math.max(...dataPoints.map(point => point.y[1]))+2000;
        const yOffset = (maxPrice - minPrice) * 0.1;

        const dividerDataPoints = [
            { x: dataPoints[dividerIndex].x, y: minPrice},
            { x: dataPoints[dividerIndex].x, y: maxPrice}
            ];

		const options = {
			theme: "light2", // "light1", "light2", "dark1", "dark2"
			animationEnabled: true,
			exportEnabled: false,
			title:{
				text: ""
			},
			axisX: {
				// valueFormatString: "MMM",

                lineThickness: 0, 
				tickThickness: 0, 
				labelFormatter: () => "",
                interval: 1,
			},
			axisY: {
				// includeZero:false,
				// prefix: "$",
				// title: "Price (in USD)",
                lineThickness: 0,
				tickThickness: 0,
				labelFormatter: () => "",
                gridThickness: 0
			},
            axisY2: {
                title: "",
                includeZero: false,
                suffix: "",
                // lineThickness: 0,
                tickLength: 0,
                gridThickness: 0,
                // labelFormatter: () => "",
                stripLines: [],
                position: "outside"
            },
            zoomEnabled: false,
			data: [
            {
				type: "candlestick",
				showInLegend: true,
				name: "",
				yValueFormatString: "$###0.00",
				xValueFormatString: "DD MMMM YY",
				dataPoints: dataPoints,
                risingColor: "green",
				fallingColor: "red",
                dataPointWidth: 0.5,
                hoverBorderColor: "transparent", 
                hoverColor: null,
                click: this.handleCandleClick,
                axisYType: "secondary",
			},
            {
                type: 'line',
                toolTipContent: `Date {x} : {y}/${this.props.units}`,
                dataPoints: dividerDataPoints,
                color: '#D3D3D3',
                markerSize: 0,
                lineThickness: 1,
            }
		  ],
          toolTip: {
            enabled: false,
          },
		}

        const getNoteLeft = () => {
            const windowWidth = window.innerWidth;
            const dividerIndex = this.state.dividerIndex;
            const dataPointsLength = dataPoints.length;
      
            if (windowWidth < 450) {
              if (dividerIndex === 0) {
                return 25 + ((dataPoints.length-dividerIndex) / dataPoints.length) * 55;
              }
              else{
                return 25 + ((dataPoints.length-dividerIndex) / dataPoints.length) * 75;
              }
            } else {
              if (dividerIndex === 0) {
                return 25 + ((dataPoints.length-dividerIndex) / dataPoints.length) * 65;
              }
              else{
                return 25 + ((dataPoints.length-dividerIndex) / dataPoints.length) * 75;
              }
            }
          };
      
          const noteStyle = {
            left: `${getNoteLeft()}%`,
          };
        // const noteStyle = {
        //     left: `${25 + ((dataPoints.length-dividerIndex) / dataPoints.length) * 75}%`,
        // };
        function formatDate(date) {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString().slice(-2);
            
            return `${day}/${month}/${year}`;
        }
		
		return (
		<div className='candle-chart-div'>
			<CanvasJSChart options = {options} id="chartContainer"/>
            <div className="button-container">
                <img className="lineChart_divider_move_btn" src={backwardCircleArrow} onClick={() => this.handleMoveDivider('forward')}></img>
                <img className="lineChart_divider_move_btn" src={forwardCircleArrow} onClick={() => this.handleMoveDivider('backward')}></img>
            </div>
            <div className='divider-top' style={noteStyle} onClick={this.handleDividerTopClick}>
                <div className={`divider-top-first ${this.state.openDividerTop ? 'opensize-divider-top-first' : ''}`}>
                    {`₹ ${dataPoints[dividerIndex].y[3]}/${this.props.units} | ${formatDate(new Date(dataPoints[dividerIndex].x))}  `}
                    <img src={dropdownArrow} style={{height: "6px" , marginLeft: "7px"}}></img>
                </div>
                { this.state.openDividerTop ? <div className='divider-top-second'>
                                        <p>Open: {`₹${listOfPrices[dividerIndex].open}/${this.props.units}`}</p>
                                        <p>Close: {`₹${listOfPrices[dividerIndex].close}/${this.props.units}`}</p>
                                        <p>Max: {`₹${listOfPrices[dividerIndex].high}/${this.props.units}`}</p>
                                        <p>Min: {`₹${listOfPrices[dividerIndex].low}/${this.props.units}`}</p>
                                    </div>
                                :
                                ""
                }
            </div>
		</div>
		);
	}
}

export default CandlestickChart;