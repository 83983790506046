import React, { lazy, Suspense, useState, useEffect } from "react";
import './App.css';
import { Route, BrowserRouter, Router, Switch } from "react-router-dom";
import { history } from "./utils/Utility"
import { toast } from "react-toastify";
import NaveList from "./containers/NavMenu"
import Lists from "./containers/NavMenu/navigationlists";
import URLList from "./containers/NavMenu/urlList";
import LandingPage from "./containers/Home";
import Loader from "./components/CustomLoader";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Profile from "./containers/Profile";
import IdleTimer from 'react-idle-timer';
import LOGO from "../src/assets/images/e-Mandi White logo.png";
import e from "../src/assets/images/e.png";
import { useLongPress } from 'use-long-press';
import Terms from "./containers/Terms";
import SessionTimeOut from "./sessionTimeOut";
import Register from "./containers/Register";
import CallusPopUp from './CallusPopUp'
import Home from "./containers/LandingPage";
import PostReg from "./containers/HomeNew/HomeNew";
import UpcomingAuctions from './containers/UpcomingAuctions'
import CommodityResearch from './containers/CommodityResearch'
import CashandCarryArbitage from './containers/CashandCarryArbitage'
import EmandiCash from './containers/EmandiCash'
import EmandiTV from './containers/EmandiTV'
import Emandinews from './containers/Emandinews'

import NewEmandi from "./NewEmandi/index";


import NewEmandiNews from "./containers/HomeNew/view/NewEmandiNews";
import NewEmandiFullNews from "./containers/HomeNew/view/NewEmandiFullNews";
import EmandiTVNew from "./containers/HomeNew/view/emandiTVNew";
// import EResearch from "./containers/HomeNew/view/EResearch";
import EResearch from "./components/HomeNewPageComponents/EResearch/EResearch";
import EHedge from "./components/HomeNewPageComponents/EHedge/EHedge";
import ESpot from "./components/HomeNewPageComponents/ESpot/ESpot";


import phone from './assets/images/phone.png'
import EmandiCashTerms from './containers/EmandiCashTerms'
import Disclaimer from './containers/Disclaimer/';
import ReactGA from 'react-ga';
import EmandinewsList from "./containers/EmandinewsList";
import EmanditvList from "./containers/EmanditvList";
import ShowSingleVideos from "./containers/EmandiTV/view/ShowSingleVideos";
// Lender Component (Custom)
import Lender from './containers/Lender'
import LenderPage from './containers/Lender/index'

// Buyer Under Writing Component (Custom)
import BuyerUnderwriting from "./containers/BuyerUnderwriting";
import ViewAll from './containers/eCash/Components/ViewAll';
import Gif from './containers/eCash/Components/gif';
import GifTwoCrore from './containers/eCash/Components/gifTwoCrore';
import TwoCrore from './containers/eCash/Components/two_crore';
import addECash from './containers/eCash/Components/addECash';
import uploadAdditionalDocuments from './containers/eCash/Components/uploadAdditionalDocuments';
import uploadAdditionalDocsTwoCrore from './containers/eCash/Components/uploadAdditionalDocsTwoCrore';
import TwoCroreRequest from './containers/eCash/Components/two_crore_request';
import Details from './containers/eCash/Components/Details';
import NewBuyerPage from "./containers/BuyerUnderwriting/NewBuyerPage";
import NewBuyerData from "./containers/BuyerUnderwriting/NewBuyerData";
import ExistingBuyerPage from "./containers/BuyerUnderwriting/ExistingBuyerPage";
import PreRegHome from "./pre-reg/PreRegHome";
import '@glidejs/glide/dist/css/glide.core.min.css';
import PreRegAboutUs from "./pre-reg/preRegComponents/aboutus/PreRegAboutUs";
import PreRegEresearch from "./pre-reg/preRegComponents/eResearch/PreRegEresearch";
import PreRegEHedge from "./pre-reg/preRegComponents/ehedge/PreRegEHedge";
import PreRegESpot from "./pre-reg/preRegComponents/espot/PreRegESpot";
import PreRegECash from "./pre-reg/preRegComponents/ecash/PreRegECash";
import CorporateGovernancePolicy from "./pre-reg/preRegComponents/corporategovernancePolicy/CorporateGovernancePolicy";
// import AboutUs from "./components/HomeNewPageComponents/AboutUs/AboutUs";
import AboutUssPost from "./components/HomeNewPageComponents/AboutUss/AboutUssPost";
import CorporateGovernancePolicyy from "./components/HomeNewPageComponents/CorporateGovernancePolicy/CorporateGovernancePolicyy";

//eCash Admin
import ECashAdmin from "./containers/eCash/index"

import { Fragment } from "react";

import Lenders from './containers/Lenders'
import AddLenderPage from './containers/Lenders/index'
import AddLender from "./containers/Lenders/Components/addLender";
import UpdateLender from "./containers/Lenders/Components/UpdateLender";
import EcashPostReg from "./components/HomeNewPageComponents/EcashPostReg/Components/EcashPostReg";
import ECashGst from "./components/HomeNewPageComponents/EcashPostReg/Components/eCashGst";
import ECashDocuments from "./components/HomeNewPageComponents/EcashPostReg/Components/eCashDocuments";
import ProfileSectionPostReg from "./containers/HomeNew/view/ProfileSectionPostReg/ProfileSectionPostReg";


import ViewAllProfin from './containers/Profin/Components/ViewAllProfin';
import Profin from '../src/containers/Profin/Components/index';
import AddBuyer from "./containers/Profin/Components/AddBuyer";
import BuyerDetail from "./containers/Profin/Components/BuyerDetail";

import Account from "./containers/Account/view/list"

const AppDirectingPage = React.lazy(() => import('./AppDirectingPage/AppDirectingPage'));

const TRACKING_ID = "G-MBEDTHK83Y"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
	let listComps = [], routeComps = [];
	const [selected, setSelected] = useState(history.location.pathname);
	let menuList = [], showMenu, showLandingPage = false;
	let idleTimer = null;
	let logout = false;
	let [count, setCount] = useState(0);
	let isAdmin = JSON.parse(sessionStorage.getItem("isAdmin"));
	let isRiskAdmin = '';
	let headerMenuList = [];
	// console.log(isAdmin);

	let [stateValue, setStateValue] = useState({
		visible: false,
		remainingTime: -1,
		idleTimer: 9,
		isWarning: false,
		CallusPopUp: false,
		sucessDailog: false
	})


	useEffect(() => {

		ReactGA.pageview(window.location.pathname + window.location.search);
		const historyListenFn = history.listen((location) => {
			setSelected(location.pathname);
			ReactGA.set({ page: location.pathname }); // Update the user's current page
			ReactGA.pageview(location.pathname); // Record a pageview for the given page
		});
		isRiskAdmin = JSON.parse(sessionStorage.getItem("isRiskAdmin"));
		// let isAdmin = JSON.parse(sessionStorage.getItem("isAdmin"));
		let a = selected.split('/')
		console.log(selected, a);
		// console.log(isRiskAdmin)

		if (isRiskAdmin && selected === "/prereg") {
			// history.push("/EmandiCashLimit");
			// setSelected("/EmandiCashLimit");
			history.push("/auction");
			setSelected("/auction");
		}
		else if (isAdmin && selected === "/prereg") {
			console.log("0");
			history.push("/auction");
			setSelected("/auction");
		} else if ((selected === "/homePage" || selected === "/homepage")) {
			console.log("1");
			history.push("/prereg");
			setSelected("/prereg");
		} else if ((selected === "/postreg")) {
			console.log("1");
			history.push("/postreg");
			setSelected("/postreg");
		} else if ((selected === "/prereg")) {
			console.log("1");
			history.push("/prereg");
			setSelected("/prereg");
		} else if ((selected === "/aboutuss")) {
			console.log("1");
			history.push("/aboutuss");
			setSelected("/aboutuss");
		} else if ((selected === "/eresearchh")) {
			console.log("1");
			history.push("/eresearchh");
			setSelected("/eresearchh");
		} else if ((selected === "/ecash")) {
			console.log("1");
			history.push("/eCash");
			setSelected("/eCash");
		} else if ((selected === "/eCashh")) {
			console.log("1");
			history.push("/eCashh");
			setSelected("/eCashh");
		} else if ((selected === "/eHedgee")) {
			console.log("1");
			history.push("/eHedgee");
			setSelected("/eHedgee");
		} else if ((selected === "/eSpott")) {
			console.log("1");
			history.push("/eSpott");
			setSelected("/eSpott");
		} else if ((selected === "/corporategovernancePolicy")) {
			console.log("1");
			history.push("/corporategovernancePolicy");
			setSelected("/corporategovernancePolicy");
		}
		else if ((selected === "/corporategovernancePolicyy")) {
			console.log("1");
			history.push("/corporategovernancePolicyy");
			setSelected("/corporategovernancePolicyy");
		} else if ((selected === "/AboutUsPost")) {
			console.log("1");
			history.push("/AboutUsPost");
			setSelected("/AboutUsPost");
		} else if ((selected === "/AboutUss")) {
			console.log("1");
			history.push("/AboutUss");
			setSelected("/AboutUss");
		} else if ((selected === "/terms")) {
			console.log("2");
			history.push("/terms");
			setSelected("/terms");
		} else if ((selected === "/Disclaimer")) {
			console.log("2");
			history.push("/Disclaimer");
			setSelected("/Disclaimer");
		}
		else if ((selected === "/register")) {
			console.log("3");
			history.push("/register");
			setSelected("/register");
		}
		else if ((selected === "/liveAuction") || (selected === "/scheduleAuction")) {
			console.log("4");
			if (selected === "/liveAuction") {
				history.push("/liveAuction");
				setSelected("/liveAuction");
			} else {
				history.push("/scheduleAuction");
				setSelected("/scheduleAuction");
			}
		}
		else if ((selected === "/CommodityResearch")) {
			console.log("4");
			history.push("/CommodityResearch");
			setSelected("/CommodityResearch");
		} 
		else if ((selected === "/EmandiCash")) {
			console.log("5");
			history.push("/EmandiCash");
			setSelected("/EmandiCash");
		}
		else if ((selected === "/Emandinews")) {
			console.log("5");
			history.push("/Emandinews");
			setSelected("/Emandinews");
		}
		else if ((selected === "/eMandiNews")) {
			console.log("5");
			history.push("/eMandiNews");
			setSelected("/eMandiNews");
		}
		else if ((selected === "/emandiTVNew")) {
			console.log("5");
			history.push("/emandiTVNew");
			setSelected("/emandiTVNew");
		}
		else if ((selected === "/eResearch")) {
			console.log("5");
			history.push("/eResearch");
			setSelected("/eResearch");
		}
		else if ((selected === "/eSpot")) {
			console.log("5");
			history.push("/eSpot");
			setSelected("/eSpot");
		}
		else if ((selected === "/ecashComingSoon")) {
			console.log("5");
			history.push("/ecashComingSoon");
			setSelected("/ecashComingSoon");
		}
		else if ((selected === "/eHedge")) {
			console.log("5");
			history.push("/eHedge");
			setSelected("/eHedge");
		}
		else if ((selected === "/eMandiFullNews")) {
			console.log("5");
			history.push("/eMandiFullNews");
			setSelected("/eMandiFullNews");
		}
		else if ((selected === "/profile")) {
			console.log("5");
			history.push("/profile");
			setSelected("/profile");
		}
		else if ((selected === "/CashandCarryArbitage")) {
			console.log("5");
			history.push("/CashandCarryArbitage");
			setSelected("/CashandCarryArbitage");
		} else if (selected === "/EmandiTV") {
			console.log("5");
			history.push("/EmandiTV");
			setSelected("/EmandiTV");
		}
		else if ((selected === "/EmandinewsList")) {
			console.log("5");
			history.push("/EmandinewsList");
			setSelected("/EmandinewsList");
		}
		else if (a[1] === "Emandinews") {
			console.log("5");
			history.push(`/Emandinews/${a[2]}`);
			setSelected(`/Emandinews/${a[2]}`);
		}

		else if (a[1] === "eMandiFullNews") {
			console.log("5");
			history.push(`/eMandiFullNews/${a[2]}`);
			setSelected(`/eMandiFullNews/${a[2]}`);
		}

		else if ((selected === "/") || (!sessionStorage.getItem("userInfo"))) {
			console.log("5");
			history.push("/prereg");
			setSelected("/prereg");
		}
		else if((selected==="/eAuction")){
			console.log("in eAuction");
			history.push("/eAuction");
			setSelected("/eAuction");
		}

		else {
			console.log("6");
			history.push(selected);
			if (selected == "/BuyerUnderWriting") {
				toast.info("vpn access is needed for 'Buyer Under writing' module Functioning")
			}
		}

		let mediaOnly = window.matchMedia("(max-width: 400px)");
		if (mediaOnly.matches) {
			const { location: { hash = "" } } = window;
			const skipUrls = ['#/Disclaimer', '#/EmandinewsList'];
			let isRenderMobileApp = true

			if (hash.includes("#/Emandinews/")) {
				isRenderMobileApp = false;
			} else if (skipUrls.includes(hash)) {
				isRenderMobileApp = false;
			}

			if (isRenderMobileApp) {
				renderMobileApp();
			}
			// renderMobileApp();
		}
		if (mediaOnly.matches) {
			const {
				location: { hash = "" },
			} = window;
			const skipUrls = ["#/Disclaimer", "#/EmandiTV"];
			let isRenderMobileApp = true;

			if (hash.includes("#/EmandinewsList/")) {
				isRenderMobileApp = false;
			} else if (skipUrls.includes(hash)) {
				isRenderMobileApp = false;
			}

			if (isRenderMobileApp) {
				renderMobileApp();
			}
			// renderMobileApp();
		}

		return () => {
			historyListenFn();
		}


	}, [selected]);
	const successDailogPopUp = () => {
		setStateValue({ ...stateValue, CallusPopUp: false, sucessDailog: true });
	}
	const hideConfirmPopUp = () => {
		setStateValue({ ...stateValue, CallusPopUp: false, sucessDailog: false });
	}
	const showConfirmPopUp = () => {
		setStateValue({ ...stateValue, CallusPopUp: false });
	}
	const hideCallusPopUp = () => {
		setStateValue({ ...stateValue, CallusPopUp: false });
	}

	const showCallusPopUp = () => {
		setStateValue({ ...stateValue, CallusPopUp: true });
	}

	const renderIconFn = (item) => {
		return <div className="nav-icon-image"><img alt="nav-alt" src={(selected === `${item.routeId}`) ? item.selecImg : item.image} /></div>;
	}

	const onNavClick = (item) => {
		setSelected(`${item.routeId}`);
		history.push(`${item.routeId}`);
	}

	const onTermsClick = () => {
		window.open("#/terms");
		// setSelected(`/terms`);
		// history.push(`/terms`);
	}

	const onLogout = () => {
		sessionStorage.removeItem("userInfo");
		sessionStorage.removeItem("isAdmin");
		sessionStorage.removeItem("isEmandiCash");
		sessionStorage.removeItem("isRiskAdmin");
		sessionStorage.removeItem("commodityHistoryFlag")
		sessionStorage.removeItem("kycUploaded", false);
		history.push(`/prereg`);
		window.location.reload();
	}

	const OnUserProfilClick = () => {
		history.push(`/editProfile/123456`)
	}

	const changeUrl = (value) => {
		history.push(`${value}`);
		setSelected(`/${value}`);
		console.log(value, "sad");
	}

	Lists.forEach((item, i) => {
		let { name, icon, title, image, routeId, children, selecImg, component, localeName, routeRepeat, titleLocaleName } = item;
		let RenderableComponent = lazy(() => import(`./containers/${component}`));
		let isAdmin = JSON.parse(sessionStorage.getItem("isAdmin"));
		// console.log(isAdmin)
		let isRiskAdmin = JSON.parse(sessionStorage.getItem("isRiskAdmin"));

		    // Test code: Check detected user roles
			// console.log("Detected user roles:");
			// console.log("isAdmin:", isAdmin);
			// console.log("isRiskAdmin:", isRiskAdmin);
		
		
		routeComps.push(
			<Route exact key={i} path={`${routeId}`}
				render={() => {
					return <div className="center-region-cls">
						<RenderableComponent />
					</div>
				}}
			/>
		);

		if (children && children.length) {
			children.forEach((element) => {
				let { name, routeId, component } = element;
				let RenderableComponent = lazy(() => import(`./containers/${component}`));
				routeComps.push(
					<Route exact key={name} path={`${routeId}/:id`}
						render={() => {
							return <div className="center-region-cls">
								<RenderableComponent />
							</div>
						}}
					/>
				);
			})
		}
		    // Test code: Inspect rendered components
			// console.log("Route Components:");
			// console.log("Route Components:", routeComps);
		if (isRiskAdmin) {
			if (item.type === "riskadmin/admin") {
				listComps.push(
					<div key={item.name} className={`nav-tree-list-cls ${(selected === `${routeId}`) ? "active-cls" : ""}`} onClick={() => onNavClick(item)}>
						<div className="renderMenu">
							{renderIconFn(item)}
							<p>{item.name}</p>
						</div>
					</div>
				)
			}
		}
		else if (isAdmin) {
			if (item.type === "admin" || item.type === "admin/trader" || item.type == "riskadmin/admin") {
				listComps.push(
					<div key={item.name} className={`nav-tree-list-cls ${(selected === `${routeId}`) ? "active-cls" : ""}`} onClick={() => onNavClick(item)}>
						<div className="renderMenu">
							{renderIconFn(item)}
							<p>{item.name}</p>
						</div>
					</div>
				)
			}
		} 
		else {
			if (item.type === "trader" || item.type === "admin/trader") {
				listComps.push(
					<div key={item.name} className={`nav-tree-list-cls ${(selected === `${routeId}`) ? "active-cls" : ""}`} onClick={() => onNavClick(item)}>
						<div className="renderMenu">
							{renderIconFn(item)}
							<p>{item.name}</p>
						</div>
					</div>
				)
			}
			// else {
			// 	if (item.type === "admin" || item.type === "riskadmin/admin") {
			// 		listComps.push(
			// 			<div key={item.name} className={`nav-tree-list-cls ${(selected === `${routeId}`) ? "active-cls" : ""}`} onClick={() => onNavClick(item)}>
			// 				<div className="renderMenu">
			// 					{renderIconFn(item)}
			// 					<p>{item.name}</p>
			// 				</div>
			// 			</div>
			// 		)
			// 	}
			// }
		}

		// listComps.push(
		// 	<div key={item.name} className={`nav-tree-list-cls ${(selected === `${routeId}`) ? "active-cls" : ""}`} onClick={() => onNavClick(item)}>
		// 		<div className="renderMenu">
		// 			{renderIconFn(item)}
		// 			<p>{item.name}</p>
		// 		</div>
		// 	</div>
		// )
		// if(routeId!=='/account')
		menuList.push(routeId)
	});

	if (menuList.includes(selected)) {
		showMenu = true;
	}

	if (history && history.location.pathname == "/") {
		history.push("/prereg")
	}

	const renderMobileApp = () => {
		// let url ="intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";
		// window.location.replace(url);

		// window.location.replace("intent://package=com.origo.auction");
		// setTimeout(function () {
		// 	window.location.replace("https://play.google.com/store/apps/details?id=com.origo.auction");
		// }, 2000);
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"

		// if (/windows phone/i.test(userAgent)) {
		// 	setTimeout(function () {
		// 		window.location.replace("https://play.google.com/store/apps/details?id=com.origo.auction");
		// 	}, 1000);
		// }

		// if (/android/i.test(userAgent)) {
		// 	setTimeout(function () {
		// 		window.location.replace("https://play.google.com/store/apps/details?id=com.origo.auction");
		// 	}, 1000);
		// }

		// // iOS detection from: http://stackoverflow.com/a/9039885/177710
		// if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		// 	setTimeout(function () {
		// 		window.location.replace("https://apps.apple.com/us/app/origo-auction/id1560836342");
		// 	}, 1000);
		// }
	}


	const _onAction = (e) => {
		if (logout === true) {
			// history.push("/signin");
			// sessionStorage.clear();
			// logout = false;
			// console.log("Action")
		}
	};

	const _onIdle = (e) => {
		if (history.location.pathname !== '/prereg') {
			console.log("Logout");
			if (stateValue.idleTimer === 9) {
				logout = true;
				setStateValue({ ...stateValue, visible: true, remainingTime: 60, isWarning: true });
			} else {
				onLogoutClick();
			}
		}
	};

	const _handleOnActive = (event) => {
		console.log('user is active', event);
		// console.log('time remaining', this.idleTimer.getRemainingTime())
	}

	const onContinueClick = () => {
		window.location.reload()
	}

	const onLogoutClick = () => {
		history.push("/prereg");
		sessionStorage.clear();
		logout = false;
		window.location.reload();
	}

	console.log(sessionStorage.getItem("userInfo"))
	if (sessionStorage.getItem("userInfo")) {
		if (isAdmin || isRiskAdmin) {
			headerMenuList = [{ lable: "Auctions", routeId: "/auction" }, { lable: "Orders", routeId: "/orders" }, { lable: "Invoices", routeId: "/invoice" }, { lable: "Settings", routeId: "/settings" }, { lable: "Transactions", routeId: "/transactions" }, { lable: "Traders", routeId: "/traders" }, { lable: "Commodity", routeId: "/commodity" }, { lable: "Reports", routeId: "/Reports" }, { lable: "Lender", routeId: "/commodity" }];
		} else {
			headerMenuList = [{ lable: "Auctions", routeId: "/auction" }, { lable: "Orders", routeId: "/orders" }, { lable: "Account", routeId: "/account" }, { lable: "Invoices", routeId: "/invoice" }, { lable: "Settings", routeId: "/settings" }, { lable: "Contact", routeId: "/contactUs" }]
		}
	}
	

	

	if (history.location.pathname.includes("/register") || history.location.pathname.includes("/liveAuction") || history.location.pathname.includes("/scheduleAuction") || history.location.pathname.includes("/CommodityResearch")) {
		showLandingPage = true;
	} else {
		showLandingPage = false;
	}


	const pathNamee = history.location.pathname;
	const getHeight = () => {
		if (sessionStorage.getItem("userInfo")) {
			return '100vh';
		} else {
			return 'unset';
		}
	};
	const { location: { hash } } = window;


	if (hash === "#/postreg" && !sessionStorage.getItem("userInfo")) {
		history.push('/prereg')
	}
	if (hash === "#/AboutUsPost" && !sessionStorage.getItem("userInfo")) {
		history.push('/prereg')
	} if (hash === "#/EmandiCash" && !sessionStorage.getItem("userInfo")) {
		history.push('/prereg')
	} if (hash === "#/ecashComingSoon" && !sessionStorage.getItem("userInfo")) {
		history.push('/ecashComingSoon')
	} if (hash === "#/eHedge" && !sessionStorage.getItem("userInfo")) {
		history.push('/prereg')
	} if (hash === "#/eSpot" && !sessionStorage.getItem("userInfo")) {
		history.push('/prereg')
	}
	if (hash === "#/eResearch" && !sessionStorage.getItem("userInfo")) {
		history.push('/prereg')
	}
	if (hash === '#/prereg') {
		sessionStorage.clear();
		logout = false;
	}

	return (

		<div className="App">
			{/* {(!isAdmin && stateValue.visible)?<SessionTimeOut visible={stateValue.visible} onAccept={onContinueClick} onCancel={onLogoutClick} remainingTime={60}/>:""} */}
			<SessionTimeOut visible={stateValue.visible} onAccept={onContinueClick} onCancel={onLogoutClick} remainingTime={stateValue.remainingTime} />
			{!isAdmin && (sessionStorage.getItem("userInfo")) ? <IdleTimer
				ref={ref => { idleTimer = ref }}
				element={document}
				onIdle={_onIdle}
				onAction={_onAction}
				onActive={_handleOnActive}
				timeout={1000 * 9 * 60}
			/> : ""}
			{stateValue.isWarning ? <IdleTimer
				ref={ref => { idleTimer = ref }}
				element={document}
				onIdle={onLogoutClick}
				onAction={_onAction}
				onActive={_handleOnActive}
				timeout={1000 * 1 * 60}
			/> : ""}

			{isAdmin || isRiskAdmin ||  selected === '/eCash' || selected === '/addECash' || selected === '/TwoCrore' || selected === '/gif' || selected === "/viewAll" || selected === "/eCashDetail/" || selected === '/EmandiCash' || selected === '/profin' || selected === '/buyerDetail' || selected === '/viewAllProfin' || selected === '/addBuyer'   ||  selected === '/account' || selected === '/auction' || selected === '/BuyerUnderWriting' || selected === '/BuyerUnderwriting' || selected === '/EmandiCashLimit' || selected === '/AddLenderPage' ? <div className={`app-header ${showLandingPage ? "landing-header-page-cls" : ""}`}>
				<div className="eauctionHeader">
					{/* <img src={e} style={{width:"20px",height:"30px",marginTop:"45px",marginLeft:"95px",position:"absolute"}}/> */}
					<img className="header-logo" onClick={() => { isAdmin || isRiskAdmin || selected === '/EmandiCash' || selected === '/addECash' || selected === '/TwoCrore' || selected === '/account' || selected === '/auction' || selected === '/profin' || selected === '/buyerDetail' || selected === '/viewAllProfin' || selected === '/addBuyer' || selected === '/BuyerUnderWriting' || selected === '/BuyerUnderwriting' || selected === '/EmandiCashLimit' ? history.push('/auction') : history.push('/postreg') }} src={LOGO} />
					{selected === "/homePage" ?
						<div className="menu-header">
							{(sessionStorage.getItem("userInfo")) ? headerMenuList.map((element) => {
								return (<p className="header-title" onClick={() => onNavClick(element)}>{element.lable}</p>)
							}) : ""}
						</div>
					: ""}
				</div>
				<div className="profileBlock-cls">
					{sessionStorage.getItem("userInfo") ?
						<div>
							<div className="landing-page-top-right-wrapper">
								<Profile onLogout={onLogout} OnUserProfilClick={OnUserProfilClick} />
							</div>
						</div>
						:
						<></>
					}
				</div>
			</div> : ''}

			{isAdmin || isRiskAdmin || selected === '/EmandiCash' || selected === '/addECash' || selected === '/TwoCrore' || selected === '/gif' || selected === "/eCashDetail/" || selected === "/viewAll" || selected === '/eCash' || selected === '/account' || selected === '/auction' || selected === '/profin' || selected === '/buyerDetail' || selected === '/viewAllProfin' || selected === '/addBuyer' || selected === '/BuyerUnderWriting' || selected === '/BuyerUnderwriting' || selected === '/EmandiCashLimit' || selected === '/AddLenderPage' ? <div className={`app-containers ${showLandingPage ? "landing-body-page-cls" : ""}`}>
				{(showMenu) ? <div className="app-menu-list ">
					<NaveList listComps={listComps} />
				</div> : ""}

				<div className={`container-view ${(showMenu) ? "" : "container-view-full-screen"}`} style={{ display: "flex", flexDirection: "column", height: "auto" }}>
					<BrowserRouter>
						<Router history={history}>
							<Suspense fallback={<div className="lazy-cnt-loader-cls"><Loader loading={true}></Loader></div>}>
								<Switch>
									{/* <Route path="/homePage" render={() => (<LandingPage setSelected={setSelected} onTermsClick={onTermsClick}/>)} /> */}
									<Route path="/register" render={() => (<Register />)} />
									<Route path="/homePage" render={() => (<Home />)} />
									<Route path="/terms" render={() => (<Terms />)} />
									<Route path="/Disclaimer" render={() => (<Disclaimer />)} />
									{/* <Route path="/ViewmoreAuctions" render={() => (<ViewmoreAuctions />)} /> */}
									<Route path="/liveAuction" render={() => (<UpcomingAuctions />)} />
									<Route path="/scheduleAuction" render={() => (<UpcomingAuctions />)} />
									<Route path="/CommodityResearch" render={() => (<CommodityResearch />)} />
									<Route path="/CashandCarryArbitage" render={() => (<CashandCarryArbitage />)} />
									<Route path="/EmandiCash" render={() => (<EmandiCash />)} />
									<Route path="/EmandiTV" render={() => (<EmandiTV />)} />
									{/* <Route path="/Emandinews" render={() => (<Emandinews />)} /> */}
									{/* new ui routes */}
									<Route path="/eAuction" render={() => (<Home/>)} />
									<Route path="/postreg" render={() => (<PostReg />)} />
									<Route path="/prereg" render={() => (<PreRegHome />)} />
									<Route path="/aboutuss" render={() => (<PreRegAboutUs />)} />
									<Route path="/eresearchh" render={() => (<PreRegEresearch />)} />
									<Route path="/eCashh" render={() => (<PreRegECash />)} />
									<Route path="/eHedgee" render={() => (<PreRegEHedge />)} />
									<Route path="/eSpott" render={() => (<PreRegESpot />)} />
									<Route path="/corporategovernancePolicy" render={() => (<CorporateGovernancePolicy />)} />
									<Route path="/corporategovernancePolicyy" render={() => (<CorporateGovernancePolicyy />)} />
									<Route path="/AboutUsPost" render={() => (<AboutUssPost />)} />
									<Route path='/profile' component={ProfileSectionPostReg}/>
									<Route path="/eMandiNews" render={() => (<NewEmandiNews />)} />
									<Route path="/eMandiFullNews" render={() => (<NewEmandiFullNews />)} />
									<Route path="/emandiTVNew" render={() => (<EmandiTVNew />)} />
									<Route path="/eResearch" render={() => (<EResearch />)} />
									<Route path="/ecashComingSoon" render={() => (<EcashPostReg />)} />
									<Route path="/eCashGst" render={() => (<ECashGst />)} />
									<Route path="/eCashDocuments" render={() => (<ECashDocuments />)} />
									<Route path="/eHedge" render={() => (<EHedge />)} />
									<Route path="/eSpot" render={() => (<ESpot />)} />
									<Route path="/EmandinewsList" render={() => (<EmandinewsList />)} />
									<Route path="/EmandiCashTerms" render={() => (<EmandiCashTerms />)} />
									<Route path="/Lender" render={() => (<Lender />)} />
									<Route path="/LenderPage" render={() => (<LenderPage />)} />

									<Route path="/AddLenders" render={() => (<Lenders />)} />
									<Route path="/AddLenderPage" render={() => (<AddLenderPage />)} />

									<Route path="/BuyerUnderwriting" render={() => (<BuyerUnderwriting />)} />
									<Route path='/newbuyer' component={NewBuyerPage} />
									<Route path='/newBuyerdata' component={NewBuyerData} />
									<Route path='/existingBuyer' component={ExistingBuyerPage} />
									{/* <Route path='/BuyerUnderwriting' component={BuyerUnderwriting} /> */}

									<Route path="/Lender" render={() => (<Lender />)} />
									<Route path="/LenderPage" render={() => (<LenderPage />)} />
									<Route path="/BuyerUnderwriting" render={() => (<BuyerUnderwriting />)} />
									<Route path='/newbuyer' component={NewBuyerPage} />
									<Route path='/newBuyerdata' component={NewBuyerData} />
									<Route path='/existingBuyer' component={ExistingBuyerPage} />
									<Route path='/eCash' component={ECashAdmin} />
									<Route path="/eCashDetail/" render={() => (<Details />)} />
									{/* <Route path='/BuyerUnderwriting' component={BuyerUnderwriting} /> */}
									<Route path='/ViewAll' component={ViewAll} />
									<Route path='/TwoCrore' component={TwoCrore} />
									<Route path='/gif' component={Gif} />
									<Route path='/gifTwoCrore' component={GifTwoCrore} />
									<Route path='/uploadAddDoc' component={uploadAdditionalDocuments} />
									<Route path='/uploadAddDocTwoCrore' component={uploadAdditionalDocsTwoCrore} />
									<Route path='/addECash' component={addECash} />
									<Route path='/twoCroreRequest' component={TwoCroreRequest} />
									{/* <Route path='/addLenders' component={AddLender}/> */}
									<Route path='/updateLender/:id' component={UpdateLender} />

									<Route path='/ViewAllProfin' component={ViewAllProfin} />
									<Route path='/profin' component={Profin} />
									<Route path='/AddBuyer' component={AddBuyer} />
									<Route path='/BuyerDetail' component={BuyerDetail} />

									{routeComps}
									<Route path="*" render={() => (<div>Page Not Found</div>)} />
								</Switch>
							</Suspense>
						</Router>
					</BrowserRouter>
				</div>
			</div>: <div className={`container-view ${(showMenu) ? "" : "container-view-full-screen"}`} style={{display :"flex" , flexDirection:"column", height: getHeight()}}>
					<BrowserRouter>
						<Router history={history}>
							<Suspense fallback={<div className="lazy-cnt-loader-cls"><Loader loading={true}></Loader></div>}>
								<Switch>
									<Route path="/register" render={() => (<Register />)} />
									<Route path="/homePage" render={() => (<Home />)} />
									<Route path="/terms" render={() => (<Terms />)} />
									<Route path="/Disclaimer" render={() => (<Disclaimer />)} />
									<Route path="/eAuction" render={() => (<Home/>)} />
									{/* <Route path="/ViewmoreAuctions" render={() => (<ViewmoreAuctions />)} /> */}
									<Route path="/liveAuction" render={() => (<UpcomingAuctions />)} />
									<Route path="/scheduleAuction" render={() => (<UpcomingAuctions />)} />
									<Route path="/CommodityResearch" render={() => (<CommodityResearch />)} />
									<Route path="/CashandCarryArbitage" render={() => (<CashandCarryArbitage />)} />
									<Route path="/EmandiCash" render={() => (<EmandiCash />)} />
									<Route path="/EmandiTV" render={() => (<EmandiTV />)} />
									{/* <Route path="/Emandinews" render={() => (<Emandinews />)} /> */}
									{/* new ui routes */}
									<Route path="/postreg" render={() => (<PostReg />)} />
									<Route path="/prereg" render={() => (<PreRegHome />)} />
									<Route path="/aboutuss" render={() => (<PreRegAboutUs />)} />
									<Route path="/eresearchh" render={() => (<PreRegEresearch />)} />
									<Route path="/eCashh" render={() => (<PreRegECash />)} />
									<Route path="/eHedgee" render={() => (<PreRegEHedge />)} />
									<Route path="/eSpott" render={() => (<PreRegESpot />)} />
									<Route path="/corporategovernancePolicy" render={() => (<CorporateGovernancePolicy />)} />
									<Route path="/corporategovernancePolicyy" render={() => (<CorporateGovernancePolicyy />)} />
									<Route path="/AboutUsPost" render={() => (<AboutUssPost />)} />
									<Route path="/eMandiNews" render={() => (<NewEmandiNews />)} />
									<Route path='/profile' component={ProfileSectionPostReg}/>
									<Route path="/eMandiFullNews" render={() => (<NewEmandiFullNews />)} />
									<Route path="/emandiTVNew" render={() => (<EmandiTVNew />)} />
									<Route path="/eResearch" render={() => (<EResearch />)} />
									<Route path="/ecashComingSoon" render={() => (<EcashPostReg />)} />
									<Route path="/eCashGst" render={() => (<ECashGst />)} />
									<Route path="/eCashDocuments" render={() => (<ECashDocuments />)} />
									<Route path="/eHedge" render={() => (<EHedge />)} />
									<Route path="/eSpot" render={() => (<ESpot />)} />
									<Route path="/EmandinewsList" render={() => (<EmandinewsList />)} />
									<Route path="/EmandiCashTerms" render={() => (<EmandiCashTerms />)} />
									<Route path="/Lender" render={() => (<Lender />)} />
									<Route path="/LenderPage" render={() => (<LenderPage />)} />
									<Route path="/BuyerUnderwriting" render={() => (<BuyerUnderwriting />)} />
									<Route path='/newbuyer' component={NewBuyerPage} />
									<Route path='/newBuyerdata' component={NewBuyerData} />
									<Route path='/existingBuyer' component={ExistingBuyerPage} />
									{/* <Route path='/BuyerUnderwriting' component={BuyerUnderwriting} /> */}
									<Route path="/Lender" render={() => (<Lender />)} />
									<Route path="/LenderPage" render={() => (<LenderPage />)} />
									<Route path="/BuyerUnderwriting" render={() => (<BuyerUnderwriting />)} />	
									<Route path='/newbuyer' component={NewBuyerPage} />
									<Route path='/newBuyerdata' component={NewBuyerData } />
									<Route path='/existingBuyer' component={ExistingBuyerPage} />
									<Route path='/eCash' component={ECashAdmin} />
									<Route path="/eCashDetail/" render={() => (<Details />)} />
									<Route path='/ViewAll' component={ViewAll} />
									<Route path='/TwoCrore' component={TwoCrore} />
									<Route path='/gif' component={Gif} />
									<Route path='/gifTwoCrore' component={GifTwoCrore} />
									<Route path='/uploadAddDoc' component={uploadAdditionalDocuments} />
									<Route path='/uploadAddDocTwoCrore' component={uploadAdditionalDocsTwoCrore} />
									<Route path='/addECash' component={addECash} />
									<Route path='/twoCroreRequest' component={TwoCroreRequest} />
									<Route path="/AddLenders" render={() => (<Lenders />)} />
									<Route path="/AddLenderPage" render={() => (<AddLenderPage />)} />
									<Route path='/updateLender/:id' component={UpdateLender}/>
									<Route path="/account" element={() => (<Account />)} />
									
									{/* <Route path='/addLenders' component={AddLender}/> */}
									{routeComps}
									<Route path="*" render={() => (<div>Page Not Found</div>)} />
								</Switch>
							</Suspense>
						</Router>
					</BrowserRouter>
				</div>
				}
			
		</div >
	);
}

export default App;