import React from 'react';
import PropTypes from "prop-types";

//@custom styles
import "./style.scss"


//@functional component
const CustomRadioButtons = ({ name, label, value, config = {}, required, className, placeholder, options, formData, status }) => {
    if (!config.onChange) {
        config.onChange = ((e) => { });
    }

    return (
        <div className={`radio-content-section ${className || ""}`}>
            <div className="radio-label">
                {required ? <div className="required-cls"><p>* </p></div> : ""}
                <div className="label-cls"><p> {label}</p></div>
            </div>

            <div className="radio-button-cls">
                {
                    options && options.map((option, index) =>
                        <div className="userStatus-radio-cls" key={index}>
                            <input type="radio" id={option.label} name={name} value={option.value} checked={option.value == value ? true : false} {...config} />
                            <label htmlFor={name}>{option.label}</label>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

//@for required/options props
CustomRadioButtons.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.string,
    config: PropTypes.object,
    className: PropTypes.string
};

export default CustomRadioButtons;