import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import CustomDropdown from '../../../components/CustomDropdown/index.js';
import eMandiNews from '../../../assets/images/eMandiNews.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

export class List extends Component {
  render() {
    let { handelInputChange, onReadnewClick,formData, onClickBack,hanglePageClick,handleNextPageBtn,handlePrevPageBtn, commodityList, completeCommodityMethod, onClickTabView, onCommoditySelect, onCommodityUnselect, state, onDownloadClick, onViewClick,ondownload } = this.props
    console.log(state, "state")
    const localpages = [];
        for (let i = 2; i < Math.ceil(state.count / state.limit); i++) {
            localpages.push(i);
        }
        const renderPageNumbers = localpages.map((number) => {
            if (
                number < state.maxPageNumberLimit + 1 &&
                number > state.minPageNumberLimit
            ) {
                return (
                    <li
                        key={number}
                        id={number}
                        onClick={hanglePageClick}
                        className={state.current_page === number ? "active-page" : null}
                    >
                        {number}
                    </li>
                );
            } else {
                return null;
            }
        });

        let pageIncrementBtn = null;
        if (localpages.length >=state.maxPageNumberLimit) {
            pageIncrementBtn = (
                <li
                    onClick={() => {
                        handleNextPageBtn(localpages.length + 2);
                    }}
                >
                    {" "}
                    &hellip;{" "}
                </li>
            );
        }

        let pageDeccrementBtn = null;
        if (state.minPageNumberLimit > 1) {
            pageDeccrementBtn = <li onClick={handlePrevPageBtn}> &hellip; </li>;
        }
    return (<div>
          <div className="header-icon-cls">
                    <i className="pi pi-angle-left" onClick={onClickBack}></i>
                    {/* <img src={Logo} /> */}
                    <p>Back</p>
                </div>
                <div className='menu-cls-Emandhi'>
                <div className='list-cls'>
                        <p className='list-EMandi'> <img src={eMandiNews}/><span >eMandi News</span></p>
                        {/* <p className={state.currentStage=="EMandiTV"?"active-cls":"norma-cls"} onClick={() => setCurrentState("EMandiTV")}><img src={eManditvlogo}/>E-Mandi TV</p> */}
                    </div >
                    
     
        
       <div className='Emandi-news-cls'>
       <div className='Emandi-news-heading'>eMandi News</div>
       <div className='filters-Time'>
        <CustomDropdown name="time" value={formData.time} options={state.options}onChange={handelInputChange} config={{placeholder:"All Time",showClear:true}} />
        </div> 
            {state.emandinewsdata && state.emandinewsdata.map((product) => {
                return (
                    <div>
                    <div className="product-item">
                        <div>
                            <img src={product.newsPicturePath} />
                            <div>
                            <p className="emandi-tittle">{product.topic}</p>
                                <span className="emandi-date">{product.location}{product.location?":":""}{product.uploadedOn ? <span> {product.uploadedOn.slice(8, 10)} {product.uploadedOn.slice(5, 7) == "01" ? "Jan" : product.uploadedOn.slice(5, 7) == "02" ? "Feb" : product.uploadedOn.slice(5, 7) == "03" ? "Mar" :
                                    product.uploadedOn.slice(5, 7) == "04" ? "Apr" : product.uploadedOn.slice(5, 7) == "05" ? "May" : product.uploadedOn.slice(5, 7) == "06" ? "Jun" : product.uploadedOn.slice(5, 7) == "07" ? "Jul" :
                                        product.uploadedOn.slice(5, 7) == "08" ? "Aug" : product.uploadedOn.slice(5, 7) == "09" ? "Sep" : product.uploadedOn.slice(5, 7) == "10" ? "Oct" : product.uploadedOn.slice(5, 7) == "11" ? "Nov" : "Dec"} {product.uploadedOn.slice(0, 4)}</span>
                                    : ""}
                                    
                                </span>
                                </div>
                                
                        </div>
                        {product.topic?<span className='readme' onClick={()=>onReadnewClick(product.id)}>Read News</span>:""}
                        
                    </div>
                   {product.topic?<></>:""}
                    </div>
                    
                    
                )
            })}
 </div>
 <div>
                    {state.count > 0 ?
                        <ul className="pageNumbers">
                            <li onClick={handlePrevPageBtn}>
                                {/* &lt;&lt; */}
                                <FontAwesomeIcon icon={faBackward} />
                            </li>
                            {state.count > 0 ?
                                <li
                                    key={1}
                                    id={1}
                                    onClick={hanglePageClick}
                                    className={state.current_page === 1 ? "active-page" : null}
                                >
                                    {1}
                                </li>
                                : ""}

                            {pageDeccrementBtn}
   
                            {renderPageNumbers}
 
                            {pageIncrementBtn}
                            {state.count > state.limit ? <li
                                key={localpages.length + 2}
                                id={localpages.length + 2}
                                onClick={hanglePageClick}
                                className={state.current_page === localpages.length + 2 ? "active-page" : null}
                            >
                                {localpages.length + 2}
                            </li>:""}
                            <li
                                onClick={() => {
                                    handleNextPageBtn(localpages.length + 2);
                                }}
                            >
                                {/* &gt;&gt; */}
                                <FontAwesomeIcon icon={faForward} />
                            </li>
                        </ul> : ""}
                </div>
</div>
</div>

    )
  }
}

export default List