import React, { Component } from 'react';
import CustomDialog from "./components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from './components/CustomInputText';
import phone from './assets/images/phone.png'
// import './styles.scss';
import './CallusPopUp.scss';

import callImage from "./assets/icons/ic_con_call.svg";
import mailImage from "./assets/icons/ic_con_mail.svg";
import addressImage from "./assets/icons/ic_con_address.svg";
import CustomInputArea from "./components/CustomInputArea";
import CustomButton from "./components/CustomButton";
import { fireAjaxRequest } from './utils/Utility';
import { CALL_US } from './utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from './components/CustomLoader';

import SuccessPopUp from "./SuccessPopUp"

class CallusPopUp extends Component {
    constructor(props) {
        super(props);

    }
    state = {
        formData: {},
        loading: false,
        userInfo: JSON.parse(sessionStorage.getItem("userInfo")),

    }
    handelInputChange = (e) => {
        let formData = this.state.formData;

        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });
    }

    onClickSend = () => {
        let { formData } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))

        if (userInfo) {
            const sendBody = {
                "name": userInfo.user.name || "Trader",
                "mobileNo": userInfo.user.phoneNo
            };
            (async () => {
                // this.setState({loading:true});
                try {
                    const resp = await fireAjaxRequest(CALL_US, {
                        method: 'POST',
                        body: JSON.stringify(sendBody),
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : ""
                        }
                    });
                    // this.setState({loading:false});
                    if (resp) {
                        this.setState({ requestId: resp && resp.data.requestId })
                        this.setState({ formData: {} });
                        this.props.successDailogPopUp();
                    }

                } catch (err) {
                    // this.setState({loading:false});
                    toast.error('Failed to sent your Query');
                }
            })();
        }
        else {
            if (!formData.fullname) {
                toast.error("Please enter name")
            }
            else if (!formData.number) {
                toast.error("Please enter mobile number")
            }
            else if (formData.number.length < 10) {
                toast.error("Phone number should contain 10 digits")
            }
            else {
                const sendBody = {
                    "name": formData.fullname,
                    "mobileNo": formData.number
                };
                (async () => {
                    // this.setState({loading:true});
                    try {
                        const resp = await fireAjaxRequest(CALL_US, {
                            method: 'POST',
                            body: JSON.stringify(sendBody),
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : ""
                            }
                        });
                        // this.setState({loading:false});
                        if (resp) {
                            this.setState({ requestId: resp && resp.data.requestId })
                            this.setState({ formData: {} });
                            this.props.successDailogPopUp();
                        }

                    } catch (err) {
                        // this.setState({loading:false});
                        toast.error('Failed to sent your Query');
                    }
                })();
            }

        }
    }
    onCilckcanel = () => {
        let { formData } = this.state;
        formData.fullname = ""
        formData.fullname = ""
        formData.number = ""
        this.setState({ formData: formData })
        this.props.hideConfirmPopUp()

    }
    render() {
        const { visible, onHide, onAccept, showConfirmPopUp, hideConfirmPopUp, successDailogPopUp, successDailogvisible } = this.props;
        let { formData, loading } = this.state;
        return (
            <div className="contact-us-dialog">
                <SuccessPopUp visible={successDailogvisible} onHide={hideConfirmPopUp} onCancel={hideConfirmPopUp} requestId={this.state.requestId} />
                <CustomDialog visible={visible} footer={() => { }} header={<div className="titlebar-cls">
                    <div className="first-div">
                        <img className="phone-icon" src={phone} />
                        Call Us - +91 83 3804 7777
                        </div>
                </div>} onHide={onHide} onCancel={onHide} width="37vw">
                    <CustomLoader loading={loading || false}>
                        <div className="callus-cls">
                            {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                            <div className="header-cls">
                                {/* <div className="first-div">
                                <img className="phone-icon" src={phone} />
                                        Call Us- 011-4123-6866</div> */}
                                <div className="second-div">or</div>
                                <div className="third-div">Request A Call Back?</div>

                            </div>
                            <div className="message-us">
                                {!sessionStorage.getItem("userInfo") ? <div className="names-div">
                                    <div className="inputfield-cls">
                                        <p>Enter Full Name</p>
                                        <div className="input-cls">
                                            <CustomInputText name="fullname" value={formData.fullname} config={{
                                            placeholder: "Full Name",
                                            onChange: this.handelInputChange.bind(this),
                                            }} />
                                        </div>

                                    </div>
                                    <div className="inputfield-cls">
                                        <p>Enter Mobile Number</p>
                                        <div class="input-container">
                                            <span class=" icon">+91</span>
                                            <CustomInputText name="number" value={formData.number} config={{
                                                onChange: this.handelInputChange.bind(this), keyfilter: "pnum", maxLength: "10",
                                            placeholder: "Mobile Number",
                                            }} />
                                           </div>
                                        {/* <div className="input-cls">
                                            <CustomInputText name="number" value={formData.number} config={{
                                                onChange: this.handelInputChange.bind(this), keyfilter: "pnum", maxLength: "10"
                                            }} />
                                        </div> */}
                                    </div>
                                </div> : ""}
                                <div className="btns-cls">
                                    <CustomButton label="Cancel" className="cancel" config={{ onClick: this.onCilckcanel }} />
                                    <CustomButton label="Submit" className="send" config={{ onClick: this.onClickSend.bind(this) }} />
                                </div>
                            </div>
                            {/* </Scrollbars> */}
                        </div>
                    </CustomLoader>
                </CustomDialog>

            </div>);
    }
}

export default CallusPopUp;