import React, { Component } from 'react';
import Login from '../Login';

import './styles.scss';


class Home extends Component {
    render() {
        return (
            <div className="homePage-cls">
                
                <div className="loginPage-cls">
                    <Login onTermsClick={this.props.onTermsClick}/>
                </div>
            </div>
        )
    }
}

export default Home;