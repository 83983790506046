//@constants
import {
    LOCALE_EVENT,
    PERMIT_ACCESS
} from "../constants";

const initialState = {
    locales: {},
    permitAccess: []
 };

function configReducer(state = initialState, action) {
    switch(action.type) {
        case LOCALE_EVENT:
            return { ...state, locales: (action.locales || {}) };

        case PERMIT_ACCESS:
            return { ...state, permitAccess: (action.access || []) };

        default:
            return state;
    }
}

export default configReducer;