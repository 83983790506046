import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Navbar.css';
import CallUsPopup from "../../../containers/HomeNew/callUs";
import LOGO from '../../../../src/assets/images/logo 1.png';
import PROFILE from '../../../../src/assets/images/profile_logo_navbar.svg';
import { GET_PERSONAL_INFO } from '../../../utils/Api';
import namePopupCross from '../../../../src/assets/images/namePopupCross.png';
import namePopupPerson from '../../../../src/assets/images/namePopupPerson.svg';
import Popup from 'reactjs-popup';
import { PUT_USER_DETAILS } from '../../../utils/Api';
import { fireAjaxRequest } from '../../../utils/Utility';
import { toast } from 'react-toastify';

let Pathlocation = '';
function Navbar() {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [fillAdharPopup, setFillAdharPopup] = useState(false);

    const [profile_logout_popup, setProfile_logout_popup] = useState(false);
    const [profile_name_popup, setProfile_name_popup] = useState(false);
    const [usernameInProfile, setUsernameInProfile] = useState('');


    useEffect(() => {
        Pathlocation = history.location.pathname;

    })
    const events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
    ];

    let timer;

    // this function sets the timer that logs out the user after 9 mins
    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            // clears any pending timer.
            resetTimer();
            // Listener clean up. Removes the existing event listener from the window
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            // logs out user
            logoutAction();
        }, 9 * 60 * 1000); // 10000ms = 10secs. You can change the time.
    };

    // this resets the timer if it exists.
    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    // when component mounts, it adds an event listeners to the window
    // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
    // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
        let checkbox = document.querySelector(".checkbox");
        let mobNav = document.querySelector('.mob-nav')
        checkbox.addEventListener('change', function () {
        if (this.checked) {
            mobNav.classList.add('show-side-nav')

        } else {
            mobNav.classList.remove('show-side-nav')
        }
        });
    }, []);

    // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
    const logoutAction = () => {
        localStorage.clear();
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("isAdmin");
        sessionStorage.removeItem("isEmandiCash");
        sessionStorage.removeItem("isRiskAdmin");
        sessionStorage.removeItem("commodityHistoryFlag")
        sessionStorage.removeItem("kycUploaded", false);
        history.push(`/homePage`);
        window.location.reload();
    };



    const getUserDetails = async()=>{
        setIsLoading(true)
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${GET_PERSONAL_INFO}/${userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },  
            });
            if (resp && resp.kyc.some((obj) => obj.type === "PAN")) {
                setIsLoading(false);
                history.push('/ecashComingSoon');
            } else {
                setIsLoading(false);
                setFillAdharPopup(true);
            }
        } catch (err) {
            setIsLoading(false);
            toast.error("Unable to get the account deatils")
        }
    }

    const eResearch = () => {
        history.push('/eResearch');
    }
    const eHedge = () => {
        history.push('/eHedge');
    }
    const eSpot = () => {
        history.push('/eSpot');
    }
    const eMandiCash = () => {
        getUserDetails();
    }
    const eAuction = () =>{
        history.push("/eAuction");
    }

    const handleProfileLogoutPopup = (e) => {
        if (e) 
        {
            setProfile_logout_popup(!profile_logout_popup);
        }
        return;
    };  
    
    const logout = () =>{
        history.push('/prereg');
    }

    const handleProfileNamePopup = (e) => {
        if (e) 
        {
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            setProfile_logout_popup(false);
            if(userInfo?.user?.name === null){
                setProfile_name_popup(true);
            }else{
                history.push("/profile")
            }
        }
        return;
    };
    const onSubmitProfileName = async () =>{
        if(usernameInProfile === '' || usernameInProfile === null){
            toast.error("Please enter your number first to continue.")
        }
        else{
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
            let sendBody = {
                "id" : userInfo?.user?.id,
                "name": usernameInProfile,
                "phoneNo": userInfo?.user?.phoneNo,
                "accountType": "INDIVIDUAL_TRADER", 
                "active": true,
                "verified": true
            }
            try{
                const resp = await fireAjaxRequest(PUT_USER_DETAILS, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${userInfo.token}`
                    },
                    body: JSON.stringify(sendBody),
                });
                if (resp) {
                    userInfo.user.name = usernameInProfile;
                    const updatedUserInfoString = JSON.stringify(userInfo);
                    sessionStorage.setItem('userInfo', updatedUserInfoString);
                    history.push("/profile")
                } else {
                toast.error("failed to update name");
                }
            }catch(err){
                toast.error("Something went wrong. Try again later")
            }
        }
    }

    return (
        <div className='headerr'>

            {isLoading && <div id="loader-container">
                <div id="spinner"></div>
            </div>}

            <Popup open={fillAdharPopup} className="noPAN-popup" closeOnDocumentClick={false}> 
                <div className='modal-header noPANPopup-header'>
                    KYC Required!!
                </div>
                <div className="modal-content">
                    <p className='noPANPopup-para'>Please complete Your PAN KYC first to continue with ecash.</p>
                    <div className='noPANPopup-okBtn-conatiner'>
                        <button 
                            onClick={()=>{
                                setFillAdharPopup(false)
                            }}
                            className='noPANPopup-cancelBtn'
                        >Cancel</button>
                        <button 
                            onClick={()=>{
                                // let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                                // if(userInfo?.user?.name === null){
                                //     setProfile_name_popup(true);
                                // }else{
                                //     setFillAdharPopup(false)
                                    history.push("/profile")
                                // }
                            }}
                            className='noPANPopup-okBtn'
                        >Ok</button>
                    </div>
                </div>
            </Popup>

            <div className='upperHeader'>
                <div className="upperinnerDiv">
                    <img className="header-logo" onClick={() => { history.push('/postreg') }} src={LOGO} alt="" />
                    <div class="mob-nav">
                        <a onClick={() => { history.push('/postreg') }} class={Pathlocation === '/postreg' ? 'active' : ''} className="scroll-to-top">Home</a>
                        <a onClick={() => { history.push('/AboutUsPost') }} class={Pathlocation === '/AboutUsPost' ? 'active' : ''} className="scroll-to-top">About us</a>
                        <a onClick={() => { eMandiCash() }} class={Pathlocation === '/ecashComingSoon' ? 'active' : ''}>eCash</a>
                        <a onClick={() => { eHedge() }} class={Pathlocation === '/eHedge' ? 'active' : ''} className="scroll-to-top">eHedge</a>
                        <a onClick={() => { eSpot() }} class={Pathlocation === '/eSpot' ? 'active' : ''} className="scroll-to-top">eSpot</a>
                        <a onClick={() => { eResearch() }} class={Pathlocation === '/eResearch' ? 'active' : ''} className="scroll-to-top">eResearch</a>
                        <a className='call'>Contact Us</a>
                        <a><img className="profileImg" src={PROFILE} alt="" onClick={handleProfileLogoutPopup}/></a>
                    </div>
                    <ul className='navbarUl'>
                        <li onClick={() => { history.push('/postreg') }}>
                            <span style={{ color: Pathlocation === '/postreg' ? '#04B23D' : '' }} className="scroll-to-top">Home</span>
                        </li>
                        <li onClick={() => { history.push('/AboutUsPost') }}>
                            <span style={{ color: Pathlocation === '/AboutUsPost' ? '#04B23D' : '' }} className="scroll-to-top">About Us</span>
                        </li>
                        {/* <li onClick={() => { eAuction() }}>
                            <span style={{ color: Pathlocation === '/eAuction' ? '#04B23D' : '' }}>eAuction</span>
                        </li> */}
                        <li onClick={() => { eMandiCash() }}>
                            <span style={{ color: Pathlocation === '/ecashComingSoon' ? '#04B23D' : '' }}>eCash</span>
                        </li>
                        <li onClick={() => { eHedge() }}>
                            <span style={{ color: Pathlocation === '/eHedge' ? '#04B23D' : '' }} className="scroll-to-top">eHedge</span>
                        </li>
                        <li onClick={() => { eSpot() }}>
                            <span style={{ color: Pathlocation === '/eSpot' ? '#04B23D' : '' }} className="scroll-to-top">eSpot</span>
                        </li>
                        <li onClick={() => { eResearch() }}>
                            <span style={{ color: Pathlocation === '/eResearch' ? '#04B23D' : '' }} className="scroll-to-top">eResearch</span>
                        </li>
                        <li>
                            <span className='call'>Contact Us</span>
                        </li>
                        <li>
                            <img className="profileImg" src={PROFILE} alt="" onClick={handleProfileLogoutPopup}/>
                        </li>
                    </ul>
                    <Popup open={profile_logout_popup} onClose={handleProfileLogoutPopup} className="profileClick-popup" closeOnDocumentClick={true}>
                        <div className='ProfileImgPopup'>
                            <div className='ProfileImgPopupCol' onClick={handleProfileNamePopup}>User Profile</div>
                            <hr></hr>
                            <div className='ProfileImgPopupCol' style={{color: "red"}} onClick={logout}>Logout</div>
                        </div>
                    </Popup>
                    <Popup open={profile_name_popup} onClose={handleProfileNamePopup} className="userProfileClick-popup" closeOnDocumentClick={false}>
                        <div className='modal-header namePopup-header'>
                            <img src={namePopupCross} className="namepopupCross" onClick={()=>{setProfile_name_popup(false)}}></img>
                        </div>
                        <div className="modal-content namePopup-content">
                            <img src={namePopupPerson} className='namePopupPeronImg'></img>
                            <p className='whatYourNameP'>What is your name?</p>
                            <div className='input-submit-container'>
                                <input className="namePopupInput" placeholder="Type your name here" 
                                        onChange={(e)=>{
                                            const inputValue = e.target.value;
                                            const pattern = /^[A-Za-z\s]*$/; 
                                            if (inputValue === "" || pattern.test(inputValue)) {
                                                setUsernameInProfile(e.target.value)
                                            }
                                        }}
                                        value={usernameInProfile}
                                ></input>
                                <div className='namePopupSubmit' onClick={onSubmitProfileName}>Submit</div>
                            </div>
                        </div>
                    </Popup>
                    <div class="hamContainer">
                        <input class="checkbox" type="checkbox" name="" id="" />
                        <div class="hamburger-lines">
                            <span class="line line1"></span>
                            <span class="line line2"></span>
                            <span class="line line3"></span>
                        </div>
                    </div>
                </div>
            </div>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
                integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"></link>
            <div
                className="modal fade"
                id="callUsModal"
                tabindex="-1"
                aria-labelledby=""
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div
                        className="modal-content"
                    >
                        <div className="modal-body">
                            <CallUsPopup />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar