import React, { Component } from 'react'
import List from "./view/list";
import './styles.scss';
import './mediaOnly.scss';
import { history, fireAjaxRequest } from "../../utils/Utility";
import { COMMODITY_RESEARCH, COMMODITY_LIST_API, COMMODITY_RESEARCH_COMMODITY, GET_EMANDI_NEWS, GET_EMANDI_TV_LIST, COMMODITY_RESEARCH_LOCATIONS,COMMODITY_RESEARCH_WEEKLY_REPORTS } from "../../utils/Api";
import axios from "axios";
import { toast } from 'react-toastify';
import moment from "moment";

export class CommodityResearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentStage: "dailyCommodityReports",
            activeItem: 0,
            offset :0,
            limit: 20,
            count:0,
            current_page:1,
            pageNumberLimit:3,
            maxPageNumberLimit:3,
            minPageNumberLimit:0,
            isAllSelected:false,
            formData: {
                reportType: "DAILY_COMMODITY_REPORTS",              
                harvestType: "PRE_HARVEST",
                // location:"All"
            },
            // items: [
            //     { label: 'Daily Commodity Reports', value: 1 },
            //     { label: 'Seasonal Commodity Reports', value: 2 },
            //     { label: 'Market Prices', value: 3 },
            //     { label: 'eMandi TV', value: 4 },
            //     { label: 'eMandi News', value: 5 },  
            // ],
            options: [{ "label": "All time", "value": "" },
            { "label": "Last 15 days", "value": "15days" },
            { "label": "Last 1 Month", "value": "1Month" },
            { "label": "Last 3 Month", "value": "3Month" },
            { "label": "Last 6 Month", "value": "6Month" },
            { "label": "Last 1 Year", "value": "1Year" }],

            languages: [{ "label": "All Languages", "value": "" },
            { "label": "ENGLISH", "value": "ENGLISH" },
            { "label": "HINDI", "value": "HINDI" },]
            
        }
    }

    componentDidMount() {
        let { formData } = this.state;
        this.getReportCommodity(formData);
        this.getreportTypeData(formData);
        this.getLocations()
    }
    hanglePageClick = (e) =>{
        this.setState({current_page : Number(e.target.id) , offset : Number(e.target.id)*this.state.limit - this.state.limit},()=>{
            if(this.state.formData.reportType === "WEEKLY_REPORTS")
            {
                this.getWeeklyreport();
            }
            else{
                this.getreportTypeData(this.state.formData);
            }
        });
    }
    handleNextPageBtn = (totalPages) =>{
        if(this.state.current_page + 1 > totalPages || this.state.count<this.state.limit )
        {
            return;
        }
        this.setState({current_page : this.state.current_page + 1 , offset : (this.state.current_page+1)*this.state.limit - this.state.limit},()=>{
            if(this.state.formData.reportType === "WEEKLY_REPORTS")
            {
                this.getWeeklyreport();
            }
            else{
                this.getreportTypeData(this.state.formData);
            }
        });
        if(this.state.current_page + 1 >this.state.maxPageNumberLimit && (this.state.current_page + 1) !== totalPages)
        {
            this.setState({maxPageNumberLimit : this.state.maxPageNumberLimit + this.state.pageNumberLimit, minPageNumberLimit : this.state.minPageNumberLimit + this.state.pageNumberLimit})
        }
        if(this.state.current_page === 1 && this.state.minPageNumberLimit >1 && this.state.maxPageNumberLimit >3)
        {
            this.setState({maxPageNumberLimit : 3, minPageNumberLimit : 0})
        }
    }
    handlePrevPageBtn= () =>{
        if((this.state.current_page - 1)===0 )
        {
            return;
        }
        this.setState({current_page : this.state.current_page - 1 , offset : (this.state.current_page-1)*this.state.limit - this.state.limit},()=>{
            if(this.state.formData.reportType === "WEEKLY_REPORTS")
            {
                this.getWeeklyreport();
            }
            else{
                this.getreportTypeData(this.state.formData);
            }
        });
         if((this.state.current_page - 1)%this.state.pageNumberLimit ===0)
        {
            this.setState({maxPageNumberLimit : this.state.current_page-1, minPageNumberLimit : this.state.minPageNumberLimit - this.state.pageNumberLimit})
        }
        if(this.state.current_page === Math.ceil(this.state.count/this.state.limit) && this.state.maxPageNumberLimit< this.state.current_page-1)
        {
            if(this.state.current_page%this.state.pageNumberLimit===0)
            {
                this.setState({maxPageNumberLimit : this.state.current_page-1, minPageNumberLimit : this.state.current_page - this.state.pageNumberLimit})
            }else{
                this.setState({maxPageNumberLimit : this.state.current_page-1, minPageNumberLimit : this.state.current_page - (this.state.current_page%this.state.pageNumberLimit)})
            } 
        }
        
    }
    getnestedreportTypeData = (formData) =>{
        
        console.log(formData);
        let { currentStage,offset, limit } = this.state;
        this.setState({ commodityCardData: "" }) 
        let reporttype = currentStage == "dailyCommodityReports" ? "DAILY_COMMODITY_REPORTS" :currentStage == "seasonalCommodityReports" ? "SEASONAL_COMMODITY_REPORTS" :
        currentStage == "marketPrices" ? "MARKET_PRICES":currentStage=="weeklyCommodityReports"?"WEEKLY_REPORTS":"WEEKLY_REPORTS"

        formData.reportType = reporttype;
        this.setState({ commodityCardData: "", formData: formData})
        let commodityUrl = `${COMMODITY_RESEARCH}?offset=${offset}&limit=${limit}&reportType=${reporttype}`;
        if (formData.reportType === "SEASONAL_COMMODITY_REPORTS") {
            if (formData.harvestType) {
                commodityUrl = `${commodityUrl}&harvestProcess=${formData.harvestType}`;
            }
        }

        if (formData.time) {
            commodityUrl = `${commodityUrl}&fromDate=${formData.time}`;
        }
        if (formData.location) {
            commodityUrl = `${commodityUrl}&location=${formData.location}`;
        }
        if(reporttype === "DAILY_COMMODITY_REPORTS" || reporttype === "WEEKLY_REPORTS"){
            if (formData.commodityType && formData.commodityType.length>0) {
                // if (reporttype === "DAILY_COMMODITY_REPORTS"||reporttype === "WEEKLY_REPORTS") {
                    commodityUrl = `${commodityUrl}&commodityId=${formData.commodityType}`;
                // } else {
                //     commodityUrl = `${commodityUrl}&commodity=${formData.commodityType}`;
                // }
            }
        }
        else{
            if(formData.commodityType){
                commodityUrl = `${commodityUrl}&commodityId=${formData.commodityType}`;
            }
        }

        axios.get(commodityUrl, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                this.setState({ commodityCardData: response.data.data, count: response.data.recordCount});
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

    getreportTypeData = (formData) => {
         console.log(formData);
        let { currentStage,offset, limit } = this.state;
        this.setState({ commodityCardData: "" }) 
        let reporttype = currentStage == "dailyCommodityReports" ? "DAILY_COMMODITY_REPORTS" :currentStage == "seasonalCommodityReports" ? "SEASONAL_COMMODITY_REPORTS" :
        currentStage == "marketPrices" ? "MARKET_PRICES":currentStage=="weeklyCommodityReports"?"WEEKLY_REPORTS":"WEEKLY_REPORTS"
         console.log(reporttype);
        formData.reportType = reporttype;
        this.setState({ commodityCardData: "", formData: formData})
        let commodityUrl = `${COMMODITY_RESEARCH}?offset=${offset}&limit=${limit}&reportType=${reporttype}`;
        if (formData.reportType === "SEASONAL_COMMODITY_REPORTS") {
            if (formData.harvestType) {
                commodityUrl = `${commodityUrl}&harvestProcess=${formData.harvestType}`;
            }
        }

        if (formData.time!=="") {
            commodityUrl = `${commodityUrl}&fromDate=${formData.time}`;
        }
        if (formData.location!=="") {
            commodityUrl = `${commodityUrl}&location=${formData.location}`;
        }
        if(reporttype === "DAILY_COMMODITY_REPORTS" || reporttype === "WEEKLY_REPORTS"){
            if (formData.commodityType && formData.commodityType.length>0) {
                // if (reporttype === "DAILY_COMMODITY_REPORTS"||reporttype === "WEEKLY_REPORTS") {
                    commodityUrl = `${commodityUrl}&commodityId=${formData.commodityType}`;
                // } else {
                //     commodityUrl = `${commodityUrl}&commodity=${formData.commodityType}`;
                // }
            }
        }
        else if(reporttype === "SEASONAL_COMMODITY_REPORTS"){
            if(formData.commodityType){
                commodityUrl = `${commodityUrl}&commodityId=${formData.commodityType}`;
            }
        }else{
            if(formData.commodityType){
                commodityUrl = `${commodityUrl}&commodity=${formData.commodityType}`;
            }
        }
        

        axios.get(commodityUrl, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                this.setState({ commodityCardData: response.data.data, count: response.data.recordCount});
            }
        }).catch((error) => {
            let flag=0;
            console.log(error.toString());
            if(offset>0)
            {
                flag=1;
                this.setState({offset:offset-limit, current_page: this.state.current_page-1},()=>{
                    this.getnestedreportTypeData(formData);
                });
                console.log(this.state.offset, this.state.current_page)
            }
            if(flag===0)
            {
                toast.error("No Records Found");
                this.setState({ commodityCardData: [], count: 0});
            }
        })

    }
    getLocations = () => {
        let locationUrl = `${COMMODITY_RESEARCH_LOCATIONS}?reportType=${""}`
        let locationList = [];
        axios.get(locationUrl, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                response.data.data.map((element) => {
                    locationList.push({
                        label: element.name,
                        value: element.name
                    })
                })
                locationList.unshift({ label: "All", value: "" })
                this.setState({ locationList: locationList });

            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

    getReportCommodity = (formDate) => {
        console.log(formDate.reportType,"formDate");
        let commodityUrl = `${COMMODITY_RESEARCH_COMMODITY}?reportType=${formDate.reportType}`
        let commodityList = [];
        let commodityListId=[];
        axios.get(commodityUrl, {
             headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                response.data.data.map((element) => {
                    commodityList.push({
                        label: element.name,
                        value: formDate.reportType==="MARKET_PRICES" ? element.name : element.id,
                        id:1
                    })
                    commodityListId.push(element.id);
                    console.log(commodityListId, commodityList)
                })
                // commodityList.unshift({ label: "All Commodity", value: -1,id:0 })
                this.setState({ commodityList: commodityList, commodityListId:commodityListId});
                // console.log("simran",this.state.commodityList, commodityListId)
            }
        }).catch((error) => {
            toast.error(error.response.message);
        })
    }
    getEmandiTVlist = () => {
        let { formData, offset, limit } = this.state;
        this.setState({ formData: formData, count: "" });
        (async () => {
            // this.setLoading(true);
            try {

                let apiUrl = `${GET_EMANDI_TV_LIST}`;
                let { reportResponce } = this.state;
                const response = await fireAjaxRequest(`${apiUrl}/get?offset=${0}&limit=${100}&reportType=${""}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                // this.setLoading(false);
                if (response) {
                    this.setState({ commodityCardData: response.data });

                } else {

                }

            } catch (err) {
                // this.setLoading(false);
                //console.log("Failed to load trader Details !");
                toast.error(err.message || "Failed to load !");

            }
        })();
    }
    getnestedWeeklyReport= () =>{
        let { formData, offset, limit} = this.state;
        console.log(this.state);
        let weeklyListUrl = `${COMMODITY_RESEARCH_WEEKLY_REPORTS}?offset=${offset}&limit=${limit}`
        if (formData.time) {
            weeklyListUrl = `${weeklyListUrl}&fromDate=${formData.time}`
        }
        if (formData.commodityType && formData.commodityType.length>0) {
             if (this.state.formData.reportType === "WEEKLY_REPORTS") {
                // if (this.state.reportType === "WEEKLY_REPORTS") {
                weeklyListUrl = `${weeklyListUrl}&commodityId=${formData.commodityType}`;
            } else {
                weeklyListUrl = `${weeklyListUrl}&commodity=${formData.commodityType}`;
            }
                // weeklyListUrl = `${weeklyListUrl}&commodity=${formData.commodityType}`;
            console.log(weeklyListUrl,"weekly")
        }
        if (formData.language) {
            if (this.state.formData.reportType === "WEEKLY_REPORTS") {
               weeklyListUrl = `${weeklyListUrl}&reportTypeLanguage=${formData.language}`;
           }
       }
        (async () => {
            
            try {

                let apiUrl = `${weeklyListUrl}`;
                let { reportResponce } = this.state;
                const response = await fireAjaxRequest(`${apiUrl}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        //"Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                // this.setLoading(false);
                if (response) {

                    reportResponce = response;
                    this.setState({ commodityCardData: response.data , count: response.recordCount});

                } else {

                }
            } catch (err) {
                    toast.error(err.message || "Failed to load !");
            }
        })();
    }
   

    getWeeklyreport = () => {
        let { formData, offset, limit } = this.state;
        let weeklyListUrl = `${COMMODITY_RESEARCH_WEEKLY_REPORTS}?offset=${offset}&limit=${limit}`
        if (formData.time) {
            weeklyListUrl = `${weeklyListUrl}&fromDate=${formData.time}`
        }
        if (formData.commodityType && formData.commodityType.length>0) {
             if (this.state.formData.reportType === "WEEKLY_REPORTS") {
                // if (this.state.reportType === "WEEKLY_REPORTS") {
                weeklyListUrl = `${weeklyListUrl}&commodityId=${formData.commodityType}`;
            } else {
                weeklyListUrl = `${weeklyListUrl}&commodity=${formData.commodityType}`;
            }
                // weeklyListUrl = `${weeklyListUrl}&commodity=${formData.commodityType}`;
            console.log(weeklyListUrl,"weekly")
        }
        if (formData.language) {
             if (this.state.formData.reportType === "WEEKLY_REPORTS") {
                weeklyListUrl = `${weeklyListUrl}&reportTypeLanguage=${formData.language}`;
            }
        }

        (async () => {
            
            try {

                let apiUrl = `${weeklyListUrl}`;
                let { reportResponce } = this.state;
                const response = await fireAjaxRequest(`${apiUrl}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        //"Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                // this.setLoading(false);
                if (response) {

                    reportResponce = response;
                    this.setState({ commodityCardData: response.data , count: response.recordCount});

                } else {

                }
            } catch (err) {
                let flag=0;
                if(err.code==="404" && offset>0)
                {
                    flag=1;
                    this.setState({offset:offset-limit, current_page: this.state.current_page-1});
                    this.getnestedWeeklyReport();
                }
                if(flag===0)
                {
                    toast.error(err.message || "Failed to load !");
                    this.setState({ commodityCardData:[] , count: 0});
                }
            }
        })();
    }







    getEmandiNewslist = () => {
        let { formData, offset, limit } = this.state;
        let newsListUrl = `${GET_EMANDI_NEWS}/get?offset=${0}&limit=${100}`
        if (formData.time) {
            newsListUrl = `${newsListUrl}&fromDate=${formData.time}`

        }
        (async () => {
            // this.setLoading(true);
            try {

                let apiUrl = `${newsListUrl}`;
                let { reportResponce } = this.state;
                const response = await fireAjaxRequest(`${apiUrl}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                // this.setLoading(false);
                if (response) {
                    reportResponce = response;
                    this.setState({ commodityCardData: response.data });

                } else {

                }
            } catch (err) {
                // this.setLoading(false);
                //console.log("Failed to load trader Details !");
                toast.error(err.message || "Failed to load !");

            }
        })();
    }
    setCurrentState = (value) => {
        // this.setState({offset: 0, current_page:1,pageNumberLimit:5,maxPageNumberLimit:5,minPageNumberLimit:0,})
        
        let { formData, offset, limit} = this.state;
        formData.commodityType="";
        formData.time="";
        formData.location="";
        formData.language="";
        offset=0;
        this.setState({commodityCardData:[],formData,commodityList:[],offset: offset, current_page:1, maxPageNumberLimit:3,minPageNumberLimit:0},()=>{
            if (value == "eMandiTV") {
                this.setState({ currentStage: value, formData,commodityCardData:[] })
                this.getEmandiTVlist();
            }
            else if (value == "eMandiNews") {
                this.setState({ currentStage: value, formData,commodityCardData:[] })
                this.getEmandiNewslist();
            }
            else if (value == "weeklyCommodityReports") {
                formData.reportType="WEEKLY_REPORTS";
                this.setState({ currentStage: value, formData,commodityCardData:[] })
                this.getWeeklyreport();
                this.getReportCommodity(formData);
                        }
            else {
                let reporttype = value == "dailyCommodityReports" ? "DAILY_COMMODITY_REPORTS" :
                 value == "seasonalCommodityReports" ? "SEASONAL_COMMODITY_REPORTS" : 
                 value == "marketPrices" ? "MARKET_PRICES" : value == "weeklyCommodityReports" ? "WEEKLY_REPORTS":"WEEKLY_REPORTS"
            //fetch market price location
            let Url = `${COMMODITY_RESEARCH_COMMODITY}?reportType=${reporttype}`
            let commodityList = [];
            let commodityCardData=[];
            axios.get(Url, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    response.data.data.map((element) => {
                        commodityList.push({
                            label: element.name,
                            value: reporttype==="MARKET_PRICES" ? element.name : element.id
                        })
                    })
                    // commodityList.unshift({ label: "All Commodity", value: "" })
                    console.log(commodityList)
                    this.setState({ commodityList: commodityList});
    
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    
                //fetch all data
                formData.reportType = reporttype;
                this.setState({ currentStage: value, formData })
                this.setState({ commodityCardData: "" })
                let commodityUrl = `${COMMODITY_RESEARCH}?offset=${offset}&limit=${this.state.limit}&reportType=${reporttype}`;
                if (formData.reportType === "SEASONAL_COMMODITY_REPORTS") {
                    if (formData.harvestType) {
                        commodityUrl = `${commodityUrl}&harvestProcess=${formData.harvestType}`;
                    }
                }
    
                if (formData.date) {
                    if (formData.date[0]) {
                        commodityUrl = `${commodityUrl}&fromDate=${moment(formData.date[0]).format('YYYY-MM-DD')}`;
                    }
                    if (formData.date[1]) {
                        commodityUrl = `${commodityUrl}&toDate=${moment(formData.date[1]).format('YYYY-MM-DD')}`;
                    }
    
                }
    
                if (formData.commodityType && formData.commodityType.length>0) {
                    if (formData.reportType === "DAILY_COMMODITY_REPORTS" || formData.reportType === "WEEKLY_REPORTS") {
                        commodityUrl = `${commodityUrl}&commodityId=${formData.commodityType}`;
                    } else {
                        commodityUrl = `${commodityUrl}&commodity=${formData.commodityType}`;
                    }
                }
    
                axios.get(commodityUrl, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        this.setState({ commodityCardData: response.data.data , count: response.data.recordCount });
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
            }
        })
        
        

    }

    handelInputChange = (e) => {
        console.log(e)
        let { formData, currentStage, commodityListId,commodityList} = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData, offset:0, current_page:1, maxPageNumberLimit:3, minPageNumberLimit:0},()=>{
            if (currentStage == "eMandiNews") {
                this.getEmandiNewslist();
            }
            else if (currentStage=="weeklyCommodityReports"){
                this.getWeeklyreport();
            }
            else {
                this.getreportTypeData(formData);
            }
        });        
    }

    completeCommodityMethod = (e) => {
        setTimeout(() => {
            (async () => {
                try {
                    const resp = await fireAjaxRequest(`${COMMODITY_LIST_API}?offset=0&limit=50&searchString=${e.query}`, {
                        method: 'GET',
                        // headers: {
                        //     "Content-Type": "application/json",
                        //     "Authorization": `Bearer ${this.state.userInfo.token}`
                        // },
                    });
                    if (resp && resp.items) {
                        let commodityList = []
                        resp.items && resp.items.length && resp.items.map((element) => {
                            commodityList.push({
                                "name": element.name,
                                "code": element.name
                            })
                        })
                        this.setState({ commodityList: commodityList, });
                    }

                } catch (err) {
                    this.setState({ locationList: [] });
                }
            })();
        }, 500);
    }

    onDownloadClick = (product) => {
        // let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // if (product.viewDownload === false){
        //     if ((product.reportType === "DAILY_COMMODITY_REPORTS" || product.reportType === "SEASONAL_COMMODITY_REPORTS") && userInfo){
        //         if (userInfo.user.kycStatus === "Verified" || JSON.parse(sessionStorage.getItem("kycUploaded")) === true){
        //             window.open(product.fileLocation);
        //         }else{
        //             toast.error("Please complete your KYC to view the report");
        //         }
        //     }else{
        //         sessionStorage.setItem("commodityHistoryFlag",true)
        //         history.push("/register");
        //     }
        // }else{
        //     window.open(product.fileLocation);
        window.open(product.fileLocation);

    }

    onCommoditySelect = (e) => {
        let { formData } = this.state;
        // let temp = [];
        // formData && formData.filterCommoditytemp && formData.filterCommoditytemp.length && formData.filterCommoditytemp.map((element)=>{
        //     temp.push(element.code)
        // })
        formData.filterCommodity = e.value.code;
        this.getreportTypeData(formData)
        this.setState({ formData: formData });
    }

    onCommodityUnselect = (e) => {
        let { formData } = this.state;
        let temp = [];
        formData && formData.filterCommoditytemp && formData.filterCommoditytemp.length && formData.filterCommoditytemp.map((element) => {
            temp.push(element.code)
        })
        formData.filterCommodity = temp;
        this.setState({ formData: formData });
    }

    onViewClick = (product) => {
        // let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // if (product.viewDownload === false){
        //     if ((product.reportType === "DAILY_COMMODITY_REPORTS" || product.reportType === "SEASONAL_COMMODITY_REPORTS") && userInfo){
        //         if (userInfo.user.kycStatus === "Verified" || JSON.parse(sessionStorage.getItem("kycUploaded")) === true){
        //             window.open(`http://docs.google.com/gview?url=${product.fileLocation}&embedded=true`,"_blank");

        //         }else{
        //             toast.error("Please complete your KYC to view the report");
        //         }
        //     }else{
        //         sessionStorage.setItem("commodityHistoryFlag",true)
        //         history.push("/register");
        //     }
        // }else{
        //     window.open(`http://docs.google.com/gview?url=${product.fileLocation}&embedded=true`,"_blank");
        // }
        // window.open(`http://docs.google.com/gview?url=${product.fileLocation}&embedded=true`,"_blank");
        window.open(`http://docs.google.com/gview?url=${product.fileLocation}&embedded=true`, "_blank");

    }

    onClickBack = () => {
        history.push('/homePage');

    }
    onClickTabView = (e) => {
        this.setState({ activeItem: e.value })
        if (e.value.label === "Daily Commodity Reports") {
            this.setState({
                currentStage: "dailyCommodityReports",

            })
        }
        else if (e.value.label === "Seasonal Commodity Reports") {
            this.setState({
                currentStage: "seasonalCommodityReports",
            })
        }
        else if (e.value.label === "Market Prices") {
            this.setState({
                currentStage: "marketPrices",
            })
        }
        else if (e.value.label === "eMandi TV") {
            this.setState({
                currentStage: "eMandiTV",
            })
        }
        else if (e.value.label === "weeklyCommodityReports") {
            this.setState({
                currentStage: "WeeklyCommodityReports",
            })
        }
        else if (e.value.label === "eMandi News") {
            this.setState({
                currentStage: "eMandiNews",
            })
        }
    }
    //  typedArrayToURL=(typedArray, mimeType)=>{
    //     return URL.createObjectURL(new Blob([typedArray.buffer], {type: mimeType}))
    //   }
    ondownload=(location)=>{
        window.open(location, '_blank', 'fullscreen=yes'); return false;
         const url = this.typedArrayToURL(location, "application/pdf");
        window.open(url)
    }
onReadnewClick=(location)=>{
    history.push(`/Emandinews/${location}`)
}

    render() {
        return (
            <List
                handelInputChange={this.handelInputChange}
                formData={this.state.formData}
                setCurrentState={this.setCurrentState}
                onClickBack={this.onClickBack}
                state={this.state}
                completeCommodityMethod={this.completeCommodityMethod}
                onCommoditySelect={this.onCommoditySelect}
                onCommodityUnselect={this.onCommodityUnselect}
                onDownloadClick={this.onDownloadClick}
                onViewClick={this.onViewClick}
                onClickTabView={this.onClickTabView}
                ondownload={this.ondownload}
                onReadnewClick={this.onReadnewClick}
                hanglePageClick={this.hanglePageClick}
                handleNextPageBtn={this.handleNextPageBtn}
                handlePrevPageBtn={this.handlePrevPageBtn}
            />
        )
    }
}

export default CommodityResearch