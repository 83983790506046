import React, { Component } from "react";
import Ticker from "../../LandingPage/view/ticker.js";
import Scrollbars from "react-custom-scrollbars";
import youtubelogo from "../../../assets/images/youtube.svg";
import CustomDropdown from "../../../components/CustomDropdown";
import eManditvlogo from "../../../assets/images/eManditvlogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import ShowSingleVideos from "./ShowSingleVideos";

import TvPopUp from "./TvPopUp";
class List extends Component {
  render() {
    let {
      onClickBack,
      state,
      onHidePopup,
      onClickPlay,
      formData,
      handelInputChange,
      hanglePageClick,
      handleNextPageBtn,
      handlePrevPageBtn,
      onClickToWatch

    } = this.props;
    console.log(state);
    const localpages = [];
    for (let i = 2; i < Math.ceil(state.count / state.limit); i++) {
      localpages.push(i);
    }
    const renderPageNumbers = localpages.map((number) => {
      if (
        number < state.maxPageNumberLimit + 1 &&
        number > state.minPageNumberLimit
      ) {
        return (
          <li
            key={number}
            id={number}
            onClick={hanglePageClick}
            className={state.current_page === number ? "active-page" : null}
          >
            {number}
          </li>
        );
      } else {
        return null;
      }
    });

    let pageIncrementBtn = null;
    if (localpages.length >= state.maxPageNumberLimit) {
      pageIncrementBtn = (
        <li
          onClick={() => {
            handleNextPageBtn(localpages.length + 2);
          }}
        >
          {" "}
          &hellip;{" "}
        </li>
      );
    }

    let pageDeccrementBtn = null;
    if (state.minPageNumberLimit > 1) {
      pageDeccrementBtn = <li onClick={handlePrevPageBtn}> &hellip; </li>;
    }
    return (
        <div>
        {
        // state.showTvpopUp ? (
          false ? (
          <ShowSingleVideos path={state.path} />
        ) : (
      <div className="emandi-tv-cls">
        <Ticker />
        <TvPopUp
          visible={state.showTvpopUp}
          onHidePopup={onHidePopup}
          path={state.path}
        />
        <div className="header-cls">
          <i className="pi pi-angle-left" onClick={onClickBack}></i>
          {/* <img src={Logo} /> */}
          <p>Back</p>
        </div>
        <div className="menu-cls">
          <div className="list-cls">
            <p className="list-EMandi">
              {" "}
              <img src={eManditvlogo} />
              <span>E-Mandi TV</span>
            </p>
            {/* <p className={state.currentStage=="EMandiTV"?"active-cls":"norma-cls"} onClick={() => setCurrentState("EMandiTV")}><img src={eManditvlogo}/>E-Mandi TV</p> */}
          </div>
          <Scrollbars
            className="contact-us-scrollbar"
            autoHide={false}
            style={{ width: "80%", height: "60vh" }}
          >
            <h2>E-Mandi TV</h2>
            <div className="content-cls">
              <div className="Emandi-TV-cls">
                {/* Daily Commodity Reports */}
                <CustomDropdown
                  name="time"
                  value={formData.time}
                  options={state.options}
                  onChange={handelInputChange}
                  config={{ placeholder: "All time" }}
                />

                {state.emandiData &&
                  state.emandiData.map((product) => {
                    return (
                      <div
                        className="product-item"
                        // onClick={() => onClickPlay(product.content)}
                      >
                        <div className="top-cls">
                          <div className="youtube-symbol-cls"
                          onClick={() => onClickToWatch(product.id)}>
                            <img src={youtubelogo} />
                          </div>
                          {/* <iframe width="200" height="75" src={product.content}></iframe> */}

                          <div className="emandi-info" onClick={() => onClickToWatch(product.id)}>
                            <p className="emandi-tittle">{product.tittle}</p>
                            <span className="emandi-date">
                              Uploaded on:
                              {product.uploadedOn ? (
                                <span>
                                  {" "}
                                  {product.uploadedOn.slice(8, 10)}{" "}
                                  {product.uploadedOn.slice(5, 7) == "01"
                                    ? "Jan"
                                    : product.uploadedOn.slice(5, 7) == "02"
                                    ? "Feb"
                                    : product.uploadedOn.slice(5, 7) == "03"
                                    ? "Mar"
                                    : product.uploadedOn.slice(5, 7) == "04"
                                    ? "Apr"
                                    : product.uploadedOn.slice(5, 7) == "05"
                                    ? "May"
                                    : product.uploadedOn.slice(5, 7) == "06"
                                    ? "Jun"
                                    : product.uploadedOn.slice(5, 7) == "07"
                                    ? "Jul"
                                    : product.uploadedOn.slice(5, 7) == "08"
                                    ? "Aug"
                                    : product.uploadedOn.slice(5, 7) == "09"
                                    ? "Sep"
                                    : product.uploadedOn.slice(5, 7) == "10"
                                    ? "Oct"
                                    : product.uploadedOn.slice(5, 7) == "11"
                                    ? "Nov"
                                    : "Dec"}{" "}
                                  {product.uploadedOn.slice(0, 4)}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Scrollbars>
          <div>
            {state.count > 0 ? (
              <ul className="pageNumbers">
                <li onClick={handlePrevPageBtn}>
                  {/* &lt;&lt; */}
                  <FontAwesomeIcon icon={faBackward} />
                </li>
                {state.count > 0 ? (
                  <li
                    key={1}
                    id={1}
                    onClick={hanglePageClick}
                    className={state.current_page === 1 ? "active-page" : null}
                  >
                    {1}
                  </li>
                ) : (
                  ""
                )}

                {pageDeccrementBtn}

                {renderPageNumbers}

                {pageIncrementBtn}
                {state.count > state.limit ? (
                  <li
                    key={localpages.length + 2}
                    id={localpages.length + 2}
                    onClick={hanglePageClick}
                    className={
                      state.current_page === localpages.length + 2
                        ? "active-page"
                        : null
                    }
                  >
                    {localpages.length + 2}
                  </li>
                ) : (
                  ""
                )}
                <li
                  onClick={() => {
                    handleNextPageBtn(localpages.length + 2);
                  }}
                >
                  {/* &gt;&gt; */}
                  <FontAwesomeIcon icon={faForward} />
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      )}
      </div>
    );
  }
}

export default List;
