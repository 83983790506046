import React, { useEffect, useRef, useState } from "react";
import backArrowEResearch from "../../../assets/images/backArrowEResearch.svg";
import adminPic from "../Images/adminPic.png";
import "../style/addbuyer.css";
import successicon from "../../../assets/images/successicon1.png";
import { history } from "../../../utils/Utility";
import {
  BUYER_REQUEST_STATUS,
  PROFIN_BUYER_STATE,
  PROFIN_ORIGO_ENTITY,
  PROFIN_ADD_BUYER,
} from "../../../utils/Api";
import { toast } from "react-toastify";
import img from '../Images/arrow_down_icon.png'

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

function AddBuyer() {
  const forceUpdate = useForceUpdate();

  const backHome = () => {
    history.push("/profin");
  };

  const state = useRef([]);
  const entity = useRef([]);
  const entitySlug = useRef([]);
  const contractSigned = useRef([]);
  const insuranceLimit = useRef([]);
  const buyerName = useRef([]);
  const [emptyState, setEmptyState] = useState(false);

  const buyerRequestApproved = (EcashStatus) => {
    if (window.confirm("Do you want to approve this request?")) {
      var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      var raw = JSON.stringify({
        comments: [
          {
            commentHeader: "",
            commentForStatus: "",
            commentBody: "",
          },
        ],
      });
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        BUYER_REQUEST_STATUS +
          "id=" +
          sessionStorage.getItem("buyerId") +
          "&status=" +
          "APPROVED",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {})
        .catch((error) => console.log("error", error));
    } else {
      document.getElementById("btnApprovedModalClose").click();
    }
  };

  useEffect(() => {
    getAddBuyerState();
    getAddBuyerEntity();
  }, []);

  const getAddBuyerState = () => {
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(PROFIN_BUYER_STATE + "states" + "/list", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        state.current = result;
        setEmptyState("");
        forceUpdate();
      })
      .catch((error) => console.log("error", error));
  };

  const getAddBuyerEntity = () => {
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(PROFIN_ORIGO_ENTITY + "origoentity" + "/list", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        entity.current = result;
        setEmptyState("");
        forceUpdate();
      })
      .catch((error) => console.log("error", error));
  };

  buyerName.current = sessionStorage.getItem("buyerName");

  const addBuyerToOrgzit = (e) => {
    if (document.getElementById("text-buyer").length == 0) {
      toast.error("Please select buyer name");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("drp-entityslug").value == "0") {
      toast.error("Please select entity");
      return false;
      setEmptyState(true);
    }

    if (
      document.getElementById("text-creditinsurancelimit").value.length == 0
    ) {
      toast.error("Please provide insurance limit");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-creditstart").value == 0) {
      toast.error("Please provide insurance start date");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-creditexpiry").value == 0) {
      toast.error("Please provide insurance expiry date");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-creditintimation").value == 0) {
      toast.error("Please provide insurance intimation date");
      return false;
      setEmptyState(true);
    }

    if (document.getElementById("text-creditacceptance").value == 0) {
      toast.error("Please provide insrance acceptance date");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-creditinternal").value.length == 0) {
      toast.error("Please provide internal credit limit");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-creditreview").value == 0) {
      toast.error("Please provide credit review date");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-credittolerance").value.length == 0) {
      toast.error("Please provide credit tolerance");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-erp").value.length == 0) {
      toast.error("Please provide erp id");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("drp-stateslug").value == "0") {
      toast.error("Please select state");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-gst").value.length == 0) {
      toast.error("Please provide gst number");
      return false;
      setEmptyState(true);
    }
    if (document.getElementById("text-address").value.length == 0) {
      toast.error("Please provide address ");
      return false;
      setEmptyState(true);
    }

    var entityName = entity.current?.data?.filter(
      (x) => x.slug == document.getElementById("drp-entityslug").value
    );
    var stateName = state.current?.data?.filter(
      (x) => x.slug == document.getElementById("drp-stateslug").value
    );

    var contractSigned = false;
    if (document.getElementById("drp-creditcontract").value == "true") {
      contractSigned = true;
    }

    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);
    var tempArray = [];

    tempArray = document.getElementById("text-creditstart").value.split("-");
    var insuranceStartDate =
      tempArray[2].toString().padStart(0, 2) +
      "/" +
      tempArray[1].toString().padStart(0, 2) +
      "/" +
      tempArray[0];

    tempArray = document.getElementById("text-creditexpiry").value.split("-");
    var insuranceExpiryDate =
      tempArray[2].toString().padStart(0, 2) +
      "/" +
      tempArray[1].toString().padStart(0, 2) +
      "/" +
      tempArray[0];

    tempArray = document
      .getElementById("text-creditintimation")
      .value.split("-");
    var insuranceIntimationDate =
      tempArray[2].toString().padStart(0, 2) +
      "/" +
      tempArray[1].toString().padStart(0, 2) +
      "/" +
      tempArray[0];

    tempArray = document
      .getElementById("text-creditacceptance")
      .value.split("-");
    var insuranceAcceptanceDate =
      tempArray[2].toString().padStart(0, 2) +
      "/" +
      tempArray[1].toString().padStart(0, 2) +
      "/" +
      tempArray[0];

    tempArray = document.getElementById("text-creditreview").value.split("-");
    var insuranceReviewDate =
      tempArray[2].toString().padStart(0, 2) +
      "/" +
      tempArray[1].toString().padStart(0, 2) +
      "/" +
      tempArray[0];

    const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}[A-Z]{2}$/;
    const isValidGST = gstPattern.test(document.getElementById("text-gst").value);
    if(!isValidGST){
      toast.error("Please enter valid gst number");
      document.getElementById("text-gst").value="";
      return;
    }
    var raw = JSON.stringify({
      buyer: {
        pfBuyerRequestId: sessionStorage.getItem("buyerId"),
        erpRefrenceId: document.getElementById("text-erp").value,
      },
      buyerOrigoEntitiyList: [
        {
          origoEntity: entityName[0].entity,
          origoEntitySlug: document.getElementById("drp-entityslug").value,
          contractSigned: contractSigned,
          insuranceLimit: document.getElementById("text-creditinsurancelimit")
            .value,
          creditLimitTolerance: document.getElementById("text-credittolerance")
            .value,
          internalCreditLimit: document.getElementById("text-creditinternal")
            .value,
          insuranceStartDate: insuranceStartDate,
          insuranceExpiryDate: insuranceExpiryDate,
          insuranceIntimationDate: insuranceIntimationDate,
          insuranceAcceptanceDate: insuranceAcceptanceDate,
          creditReviewDate: insuranceReviewDate,
        },
      ],
      buyerGstEntityList: [
        {
          stateName: stateName[0].state,
          stateSlug: document.getElementById("drp-stateslug").value,
          gstNo: document.getElementById("text-gst").value,
          address: document.getElementById("text-address").value,
        },
      ],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(PROFIN_ADD_BUYER + "add/" + "buyer/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        result = JSON.parse(result);
        // document.getElementById("btnModalClick").click()

        if (result.statusCode == 201) {
          var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer " + token);

          var raw = JSON.stringify({
            comments: [
              {
                commentHeader: "",
                commentForStatus: "",
                commentBody: "",
              },
            ],
          });
          var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          fetch(
            BUYER_REQUEST_STATUS +
              "id=" +
              sessionStorage.getItem("buyerId") +
              "&status=" +
              "APPROVED",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              document.getElementById("btnModalClick").click();
            })
            .catch((error) => console.log("error", error));
        } else if (result.code == 500) {
          // toast.error(result.message);
          toast.error("Unable to add buyer");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="profin-view-dashboard">
      <div className="row topheader">
        <div className="col">
          <input
            type="text"
            className="searchTextBox-2"
            placeholder="Search"
            onChange={(e) => this.onSearchChange(e)}
          />
          <img src={adminPic} style={{}} className="pic" />
        </div>
      </div>
      <div className="row" style={{ marginLeft: "3px" }}>
        <div className="col-md-12 viewAllBg">
          <div className="col-md-6 left-div">
            <div className="profin-breadcrumbs">
              <img
                src={backArrowEResearch}
                style={{ cursor: "pointer" }}
                onClick={() => backHome()}
              />
              <div className="breads">
                BuyerList &gt; &nbsp;
                <span style={{ color: "#04B23D" }}>Add Buyer</span>
              </div>
            </div>
            <h1
              style={{
                fontSize: "20px",
                color: "#2F2F2F",
                marginBottom: "10px",
                marginLeft: "75px",
                marginTop: "20px",
                fontWeight: "600",
              }}
            >
              <button className="add-buyer-button">+</button>
              &nbsp;Add Buyer
            </h1>
          </div>
          <div className="col-md-6 right-div"></div>
        </div>

        <div className="addbuyer-table">
          <table className="table">
            <tr>
              <th class="addbuyer-th">Buyer Name</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder="Enter Name"
                    id="text-buyer"
                    value={buyerName.current}
                    disabled
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            {/* <div className="sectionBtn">
            <div class="mb-3 mt-4 ">
              <select
                class="form-select select-css"
                onChange={this.handleDropdownChange}
                style={{
                  height: '38px', color: 'grey', width: '20%', marginLeft: '10px'
                }}
              >
                <option value="ALL" >All</option>
                <option value="APPROVED" style={{ color: 'grey' }}>Approved</option>
                <option value="REJECTED" style={{ color: 'grey' }}>Rejected</option>
                <option value="INCOMPLETE_PROPOSAL">Incomplete Proposal</option>
                <option value="RED_FLAGGED_WITH_RISK"> Red Flagged With Risk</option>
                <option value="SYSTEM_APPROVED_WITH_RISK"> System Approved With Risk</option>
                <option value="DISCREPANT"> Discrepant </option>
                <option value="RECOMMENDED_TO_LENDER">Recommended To Lender</option>
                <option value="UNDER_PROCESS_WITH_RISK">Under Process (with risk)</option>
              </select>
              <p
                style={{ color: 'red' }}
              >
              </p>
            </div>
          </div> */}
            <tr>
              <th class="addbuyer-th">Origo Entity</th>
              <td>
                <div className="d-flex justify-content-end ">
                  <select
                    id="drp-entityslug"
                    className="form-control form-control-sm dropdown-icon"
                    style={{
                      height: "30px",
                      color: "grey",
                    }}
                  >
                    <option selected value="0">
                      Select
                    </option>
                    {entity.current?.data?.map((item, key) => (
                      <option value={item.slug}>{item.entity}</option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Contract Signed</th>
              <td>
                <div className="d-flex justify-content-end">
                  <select
                    id="drp-creditcontract"
                    className="form-control form-control-sm dropdown-icon "
                    style={{
                      height: "30px",
                      color: "grey",
                      marginLeft: "10px",
                    }}
                  >
                    <option selected value="0">
                      Select
                    </option>
                    <option value={true}> Yes </option>
                    <option value={false}> No </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Insurance Limit</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder="₹ 0"
                    id="text-creditinsurancelimit"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Insurance Start Date</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    id="text-creditstart"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Insurance Expiry Date</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    id="text-creditexpiry"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Insurance Intimation Date</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    id="text-creditintimation"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Insurance Acceptance Date</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    id="text-creditacceptance"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Internal Credit Limit</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    id="text-creditinternal"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Credit Review Date</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    id="text-creditreview"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Credit Limit Tolerence</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder="₹ 0"
                    id="text-credittolerance"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Erp Reference Id</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder=""
                    id="text-erp"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">State Name</th>
              <td>
                <>
                  <div
                    className="d-flex justify-content-end"
                    // style={{ marginLeft: '94px' }}
                  >
                    <select
                      id="drp-stateslug"
                      className="form-control form-control-sm dropdown-icon"
                      style={{
                       height: "30px",
                        color: "grey",
                        marginLeft: "10px",
                      }}
                    >
                      <option selected value="0">
                        Select
                      </option>
                      {state.current?.data?.map((item, key) => (
                        <>
                          <option value={item.slug}>{item.state}</option>
                        </>
                      ))}
                    </select>
                  </div>
                </>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">GST NO</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder=""
                    id="text-gst"
                    className="form-control form-control-sm"
                    minLength={15}
                    maxLength={15}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th class="addbuyer-th">Address</th>
              <td>
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder=""
                    id="text-address"
                    className="form-control form-control-sm"
                  />
                </div>
              </td>
            </tr>
          </table>

          <div className="d-flex justify-content-between">
            <button
              class="button-discard btn btn-lg"
              style={{ width: "120px", height: "35px" }}
            >
              Discard
            </button>
            <button
              class="btn submit-button"
              style={{ width: "120px", height: "35px" }}
              onClick={addBuyerToOrgzit}
            >
              Submit
            </button>
            <button
              id="btnModalClick"
              data-bs-toggle="modal"
              data-bs-target="#buyerModal"
              class="btn submit-button"
              style={{ width: "120px", height: "35px", display: "none" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}

      <div class="modal" id="buyerModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <img src={successicon} className="successImage" />
              <br />
              <h5 className="msg">
                Buyer Details Submitted <br />
                succesfully!
              </h5>
              <hr
                style={{
                  width: "70%",
                  color: "#ECECEC",
                  marginLeft: "15%",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />
              <p className="reqPara">
                <img src="" /> Request ID <b>#{"564645"}</b>
              </p>
            </div>
            <div class="modal-footer" style={{ borderTop: "none" }}>
              <button
                type="button"
                class="btn btn-success submit-button"
                id="btnApprovedModalClose"
                data-bs-dismiss="modal"
              >
                Ok,Great
              </button>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBuyer;
