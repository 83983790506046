import React, { Component } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import PropTypes from "prop-types";

class CustomInputArea extends Component {

    render() {
        let { type,name, label, value, config = {}, required, className, placeholder, rows = 5, cols = 30 } = this.props
        let _value = value !== undefined ? value : "";

        return (
            type === "formCls" ?
                <div className={`inputarea-content-form-cls`}>
                    <div className="inputarea-label">
                        <label className="input-area-label">{label}</label>
                    </div>
                    <InputTextarea name={name || ""} value={_value} placeholder={(placeholder && placeholder.name) || ""} rows={rows} cols={cols} {...config} ></InputTextarea>
                </div>
                :
                <div className={`${className || ""} inputarea-content-section`}>
                    {(label) ? <div className="inputarea-label">
                        {required ? <span className="required-field">* </span> : ""}
                        <label className="input-area-label">{label}</label>
                    </div> : ""}
                    {(placeholder && placeholder.icon) ? <span className="p-inputgroup-addon">
                        <i className={placeholder.icon} />
                    </span> : ""}
                    <InputTextarea name={name || ""} value={_value} placeholder={(placeholder && placeholder.name) || ""} rows={rows} cols={cols} {...config} ></InputTextarea>
                </div>
        );
    }
}
//@for required/options props
CustomInputArea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    config: PropTypes.object,
    required: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number
};
export default CustomInputArea;