import vectorRight from '../../../assets/icons/vector_right.png';
import 'react-bootstrap-accordion/dist/index.css';
import React, { useEffect, useState, useRef } from 'react';
import '../style/Header.css';
import vectorHalfRectangle from '../../../assets/icons/vector_half_rectangle.png';
import vectorArrow from '../../../assets/icons/vector_arrow.png';
import 'react-bootstrap-accordion/dist/index.css';
import fileIcon from '../../../assets/icons/file_icon.PNG'
import { toast } from 'react-toastify';
import { fireAjaxRequest } from "../../../utils/Utility"
import { SAVE_ADD_DOCS, LIMIT_INCREASE } from "../../../utils/Api"
import pdfIcon from '../../../assets/icons/pdf_icon.png'
import closeIcon from '../../../assets/icons/close_icon.png'
import { UPLOAD_FILE } from "../../../utils/Api"
import { history } from '../../../utils/Utility';
import { LENDER_SELECTION, ADDITIONAL_DOCS } from "../../../utils/Api"
import AdditionalDocumentsTwoCrore from './uploadAdditionalDocsTwoCrore'
import Congratulations from './congratulations_two_cr';


function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
}

function TwoCrore() {
    let newreqId = 0;
    const forceUpdate = useForceUpdate();
    const [scrollTop, setScrollTop] = useState("no")
    const [fileName, setFileName] = useState([])
    const [fileType, setFileType] = useState([])
    const [itrLink, setITRLink] = useState([])
    const [ITRFinancialYear, setITRFinancialYear] = useState('')
    const [gstLink, setGstLink] = useState([])
    const [bankLink, setBankLink] = useState([])
    const [eCashRequestId, setECashRequestId] = useState('')
    const [docNo, setDocNo] = useState('');
    const [upload, setUpload] = useState(false);
    const [twoCroreDocs, setTwoCroreDocs] = useState([])
    const [limitIncreaseFetched, setLimitIncreaseFetched] = useState(false)
    const [uploadDocPage, setUploadDocPage] = useState(true)
    const [progressValue, setProgressValue] = useState("0%")
    const [additionalDocsState, setAdditionalDocsState] = useState(false)
    const [dataContent, setDataContent] = useState([])

    const newRid = useRef(0);

    const backHome = () => {
        history.push('/addECash');
    };


    var traderId = sessionStorage.getItem("traderID");
    var requestId = sessionStorage.getItem("requestID");

    useEffect(() => {

    })

    const changeText = (e, prefix, docType) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            let files = Array.from(input.files);
            document.getElementById(e).innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Add More';
            onClickFile(files[0], prefix, docType)
        };
        input.click();
    }

    const onClickFile = (e, prefix, docType) => {

        var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
        setFileName(e.name);
        setFileType(e.type);
        if (e.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.type == "video/mp4") {
            toast.error("Please Upload the correct file format")
        } else if (e.size > 10485760) {
            toast.error("Uploaded file is more than 10MB")
        }
        else {
            let formData = new FormData();

            formData.append("file", e);
            formData.append("prefix", prefix);
            (async () => {

                try {
                    const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        body: formData
                    });
                    if (resp && resp.filePath) {

                        if (prefix === "ITR") {
                            if (itrLink.filter(x => x[2] == ITRFinancialYear).length < 6) {
                                let arr = itrLink;
                                let obj = []
                                obj.push(resp.filePath);
                                obj.push(docType);
                                obj.push(ITRFinancialYear)
                                arr.push(obj);
                                setITRLink(arr);
                                forceUpdate();

                                if (itrLink.length == 1) {
                                    let v = progressValue.substring(0, progressValue.length - 1);
                                    v = parseInt(v) + 35;
                                    if (v > 100) {
                                        v = 100;
                                    }
                                    setProgressValue(v + "%")
                                    forceUpdate();
                                }

                                // if (progressValue != "100%" && progressValue != "70%") {
                                //     setProgressValue("35%")
                                // }


                            }
                            else {
                                toast.error("Only six document allowed for financial year")
                            }
                        }
                        else if (prefix === "gst") {
                            let arr = gstLink;
                            let obj = []
                            obj.push(resp.filePath);
                            obj.push(docType);
                            arr.push(obj)
                            setGstLink(arr)
                            forceUpdate();

                            if (gstLink.length == 1) {
                                let v = progressValue.substring(0, progressValue.length - 1);
                                v = parseInt(v) + 35;
                              
                                if (v > 100) {
                                    v = 100;
                                }
                                setProgressValue(v + "%")
                                forceUpdate();
                            }



                        }
                        else if (prefix === "bank") {
                            let arr = bankLink;
                            let obj = []
                            obj.push(resp.filePath);
                            obj.push(docType);
                            arr.push(obj)
                            setBankLink(arr)
                            forceUpdate();
                            if (bankLink.length == 1) {
                                let v = progressValue.substring(0, progressValue.length - 1);
                                v = parseInt(v) + 30;
                                if (v > 100) {
                                    v = 100;
                                }
                                setProgressValue(v + "%")
                                forceUpdate()
                            }


                            // setProgressValue("100%")

                        }
                    }
                    else {
                        toast.error("Failed to upload file !");
                        if (prefix === "itr") {
                            setITRLink([])
                            forceUpdate();
                        } else if (prefix === "gst") {
                            setGstLink([])
                            forceUpdate();
                        } else if (prefix === "bank") {
                            setBankLink([])
                            forceUpdate();
                        }
                    }
                }
                catch (err) {
                    toast.error(err.message);
                    if (prefix === "itr") {
                        setITRLink([])
                        forceUpdate();
                    } else if (prefix === "gst") {
                        setGstLink([])
                        forceUpdate();
                    } else if (prefix === "bank") {
                        setBankLink([])
                        forceUpdate();
                    }
                }
            })();
        }
    }

    const handleTwoCroreGif = () => {

        if (itrLink.length == 0) {
            toast.error("Please Upload ITR Document")
            return false;
        }
        if (gstLink.length == 0) {
            toast.error("Please Upload GST Document")
            return false;
        }
        if (bankLink.length == 0) {
            toast.error("Please Upload Bank Document")
            return false;
        }

        var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
        var twoCroreDocuments = twoCroreDocs

        itrLink.map((v) => {
            let docs = {}
            docs["docType"] = "ITR_AND_AUDITED_FINANCIAL_STATEMENTS"
            docs["docLink"] = [v[0]]
            twoCroreDocuments.push(docs)
            setTwoCroreDocs(twoCroreDocuments)
            forceUpdate()
        })

        gstLink.map((v) => {
            let docs = {}
            docs["docType"] = "GST_3B_PAST_12MONTHS"
            docs["docLink"] = [v[0]]
            twoCroreDocuments.push(docs)
            setTwoCroreDocs(twoCroreDocuments)
            forceUpdate()
        })

        bankLink.map((v) => {
            let docs = {}
            docs["docType"] = "BANK_STATEMENTS_MAIN_OPERATIVE_ACCOUNT_PAST_12MONTHS"
            docs["docLink"] = [v[0]]
            twoCroreDocuments.push(docs)
            setTwoCroreDocs(twoCroreDocuments)
            forceUpdate()
        })

        //limit Increased 
        if (limitIncreaseFetched === false) {
            var myHeaders = new Headers();
            var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

            myHeaders.append("Authorization", "Bearer " + token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(LIMIT_INCREASE + "ecashRequestId=" + requestId + "&traderId=" + traderId, requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLimitIncreaseFetched(true)
                    result = JSON.parse(result);
                    console.log(result);
                    if (result.ecashRequestId == null) {
                        toast.error(result.message)
                        setUploadDocPage(false)
                    }
                    else {

                        sessionStorage.setItem("eCashRequestId", result.eCashRequestId)
                        newRid.current = result.ecashRequestId

                        // setNewReqId(result.eCashRequestId);
                        // forceUpdate();
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("Authorization", "Bearer " + token);
                        newreqId = result.ecashRequestId

                        var raw = JSON.stringify({
                            "traderId": traderId,
                            "ecashRequestId": result.ecashRequestId,
                            "documents": twoCroreDocs
                        });
                        var requestOptions = {
                            method: 'PUT',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(SAVE_ADD_DOCS, requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                result = JSON.parse(result);
                                if (result.statusCode == "200") {
                                    toast.success("Successfully updated");
                                    var requestOptions1 = {
                                        method: 'GET',
                                        headers: myHeaders,
                                        redirect: 'follow'
                                    };
                                    if (additionalDocsState == false) {
                                        fetch(LENDER_SELECTION + "ecashRequestId=" + newreqId + "&traderId=" + sessionStorage.getItem("traderID"), requestOptions1)
                                            .then(response => response.text())
                                            .then(result => {
                                                result = JSON.parse(result)
                                                console.log("DIV ", result);
                                                if (result.isEligible != null) {
                                                    if (result.assignedLenderId != null) {
                                                        additionalDocs(result?.assignedLenderId)
                                                    }
                                                }
                                            }
                                            )
                                    }
                                }
                                else {
                                    // history.push('/GifTwoCrore')
                                    toast.error("document not uploaded yet")
                                }
                            }
                            )
                            .catch(error => console.log('error', error));
                        setECashRequestId(result.requestID)
                        forceUpdate()

                        // handleTwoCroreGif()
                    }
                }
                )
                .catch(error => console.log('error', error));
        }
    }

    const additionalDocs = (lenderId) => {
        var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(ADDITIONAL_DOCS + "lenderId=" + lenderId + "&ecashLimit=2Crore&organizationType=" + sessionStorage.getItem("orgType").toUpperCase(), requestOptions)
            .then(response => response.text())
            .then(result => {
                result = JSON.parse(result)
                console.log("State ", additionalDocsState)
              
                if (result.code != "404") {
                    setAdditionalDocsState(true)
                    setDataContent(result?.additionalDocs)
                    //console.log("Data Content : " + dataContent.length)
                }
                else
                {
                
                }
            }
            )
            .catch(error => console.log('error', error));
    }

    const removeFile = (e) => {
        let arr = itrLink;
        arr.splice(e, 1)
        setITRLink(arr);

        forceUpdate();
        if (itrLink.length == 0) {
            let v = progressValue.substring(0, progressValue.length - 1);
            v = v - 35;
            setProgressValue(v + "%")
            document.getElementById("btnItr").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
        }
    }

    const removeGstFile = (e) => {
        let arr = gstLink;
        arr.splice(e, 1)
        setGstLink(arr);
        forceUpdate();
        if (gstLink.length == 0) {
            let v = progressValue.substring(0, progressValue.length - 1);
            v = v - 35;
            setProgressValue(v + "%")
            document.getElementById("btnGst").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
        }
    }

    const removeBankFile = (e) => {
        let arr = bankLink;
        arr.splice(e, 1)
        setBankLink(arr);
        forceUpdate();
        if (bankLink.length == 0) {
            let v = progressValue.substring(0, progressValue.length - 1);
            v = v - 30;
            setProgressValue(v + "%")
            document.getElementById("btnBank").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
        }
    }

    const handleFinanciaChange = (e) => {

        if (e.target.value == "Select Financial Year") {
            document.getElementById("btnItr").style.display = "none"
        }
        else {
            document.getElementById("btnItr").style.display = "block"
            setITRFinancialYear(e.target.value)
            forceUpdate();
        }
    }

    return (
        <>
            {
                additionalDocsState == false ?
                    <>

                        {
                            uploadDocPage === false ?
                                <div id="main" className="main-section" style={{ backgroundColor: '#F2F2F2', height: "auto", minHeight: '1080px' }}>
                                    <nav aria-label="" style={{ paddingLeft: '10px', fontSize: '10pt' }}>
                                        <div className="container container-css ">
                                            <div className="container" style={{ padding: "20px", float: "left", width: "40%" }}>
                                                <p className="backbtn" onClick={() => backHome()}>
                                                    <i className="fa fa-arrow-left"></i>
                                                    <span style={{ color: '#757575' }} className='breadcrumbsLabel'>&nbsp; Back &nbsp;</span>
                                                </p>
                                            </div>
                                            <ol class="" style={{ float: "right", width: '400px', fontSize: "13px", marginTop: '20px' }}>
                                                <a className='breadcrumbsLabel'
                                                    href="#/eCash"
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: '#757575',
                                                        fontSize: "13px"
                                                    }}
                                                >
                                                    eCash &nbsp;{' '}
                                                </a>
                                                <img src={vectorRight}></img>
                                                <span style={{ color: '#04B23D' }} className='breadcrumbsLabel'> &nbsp; Upload Documents</span>
                                            </ol>
                                        </div>
                                    </nav>
                                    <div className="body-css" style={{
                                        height: 'auto', minHeight: "600px", marginTop: '15px',
                                        background: 'white'
                                    }}>

                                    </div>
                                </div>
                                :
                                <>
                                    <div id="main" className="main-section" style={{ backgroundColor: '#F2F2F2', height: "auto", minHeight: '1080px' }}>
                                        <nav aria-label="" style={{ paddingLeft: '10px', fontSize: '10pt' }}>
                                            <div className="container container-css ">
                                                <div className="container" style={{ padding: "20px", float: "left", width: "40%" }}>
                                                    <p className="backbtn" onClick={() => backHome()}>
                                                        <i className="fa fa-arrow-left"></i>
                                                        <span style={{ color: '#757575' }} className='breadcrumbsLabel'>&nbsp; Back &nbsp;</span>
                                                    </p>
                                                </div>
                                                <ol class="" style={{ float: "right", width: '400px', fontSize: "13px", marginTop: '20px', marginRight: "20px" }}>
                                                    <a className='breadcrumbsLabel'
                                                        href="#/eCash"
                                                        style={{
                                                            textDecoration: 'none',
                                                            color: '#757575',
                                                            fontSize: "13px"
                                                        }}
                                                    >
                                                        eCash &nbsp;{' '}
                                                    </a>
                                                    <img src={vectorRight}></img>
                                                    <span style={{ color: '#04B23D' }} className='breadcrumbsLabel'> &nbsp; Upload Documents</span>
                                                </ol>
                                            </div>
                                        </nav>
                                        <div className="body-css" style={{
                                            height: 'auto', minHeight: "600px", marginTop: '15px',
                                            background: 'white'
                                        }}>
                                            <div class="top_section">
                                                <br />

                                                <span class="increase_limit">
                                                    Increase limit to <span class="two_crore_green">  2 Crores </span> in a few easy steps
                                                </span>
                                                <br /> <br />
                                                <span>
                                                    <img class="file_css" src={fileIcon} />
                                                </span>
                                                <span class="upload_btn_1" style={{ fontSize: '13px' }}>
                                                    Upload the documents below
                                                </span>
                                                <br /> <br />
                                                <div class="progress" style={{ width: "40%" }}>
                                                    <div class="progress-bar" role="progressbar" style={{ width: progressValue, backgroundColor: "#04B23D" }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <h3 style={{ position: "absolute", left: "37%", marginTop: "-15px" }}>{progressValue}</h3>
                                            </div>
                                            <div class="accordion" id="accordionExample">

                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingThree">
                                                        <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            ITR and Audited Financial Statements *<i title="Upload ITR and Audited Financial Statements" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                                                            <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                                                                <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                                                                <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                            </button>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <select name="limit" id="partnerDrp" className="limit-css-1 limit-1" onChange={(e) => handleFinanciaChange(e)}>
                                                                <option className="limit-css-1" value="Select Financial Year">Select Financial Year</option>
                                                                <option className="limit-css-1" >2021-22</option>
                                                                <option className="limit-css-1" >2022-23</option>
                                                            </select>
                                                            <br />
                                                            <span style={{ fontSize: '12px' }}>
                                                                <ol>
                                                                    <li>
                                                                        ITR with or without Computation </li>
                                                                    <li>
                                                                        Profile & Loss
                                                                    </li>
                                                                    <li>

                                                                        Balance Sheet
                                                                    </li>
                                                                    <li>

                                                                        Auditors Report: 3 CB & 3CD
                                                                    </li>
                                                                    <li>

                                                                        Auditors Report:
                                                                    </li>
                                                                    <li>

                                                                        Schedules & Notes to Accounts
                                                                    </li>
                                                                    <br />
                                                                    <hr /><br />
                                                                    {/* {
                                                                console.log(itrLink)
                                                            } */}
                                                                    {
                                                                        itrLink.map((item, key) => (
                                                                            <>{item[2]} - <a href={item[0]} target='_blank' className='panel_links'><i>
                                                                                <img style={{ width: '5%' }} src={pdfIcon} />
                                                                                {item[1]}
                                                                            </i></a>
                                                                                <i>
                                                                                    <img style={{ width: '5%' }} src={closeIcon}
                                                                                        onClick={(e) => { removeFile(key) }}
                                                                                    />
                                                                                </i><br /></>
                                                                        ))
                                                                    }

                                                                    {
                                                                        itrLink.filter(x => x[2] == "2021-22").length == 6 && itrLink.filter(x => x[2] == "2022-23").length == 6 ? <>
                                                                        </> : <>
                                                                            {/* <input class="form-control  input-css-class"
                              placeholder='Enter Udyog Aadhar No.' maxLength={12} id="txtUdyog" onChange={(e) => { handleInputChangeField(e) }} /> */}
                                                                            <div className='clearfix' style={{ height: "10px" }}></div>
                                                                            {

                                                                                <button style={{ marginTop: '10px', display: "none" }} class="btn btn-sm btn-success btn-upload" id="btnItr"
                                                                                    onClick={(e) => { changeText("btnItr", "ITR", "ITR_AND_AUDITED_FINANCIAL_STATEMENTS") }}
                                                                                >
                                                                                    <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                                                                    <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                                                    <span style={{ marginLeft: '5px' }} >
                                                                                        Upload
                                                                                    </span>
                                                                                </button>

                                                                            }
                                                                        </>
                                                                    }
                                                                </ol>
                                                            </span>


                                                            <div className='clearfix' style={{ height: "30px" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingFour">
                                                        <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            GST 3B for the past 12 months *  <i title="Upload GST 3B for the past 12 months" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true" ></i>
                                                            <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                                                                <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                                                                <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                            </button>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            {
                                                                gstLink.map((item, key) => (
                                                                    <>
                                                                        <span className='panel_links'>
                                                                            {item[2]}
                                                                        </span>
                                                                        <a href={item[0]} target='_blank' className='panel_links'> <i>
                                                                            <img style={{ width: '5%' }} src={pdfIcon} />
                                                                            {item[1]}
                                                                        </i></a>
                                                                        <i>
                                                                            <img style={{ width: '5%' }} src={closeIcon}
                                                                                onClick={(e) => { removeGstFile(key) }}
                                                                            />
                                                                        </i><br /></>
                                                                ))
                                                            }
                                                            {
                                                                gstLink.length == 5 ? <>
                                                                </> : <>
                                                                    <div className='clearfix' style={{ height: "10px" }}></div>
                                                                    {

                                                                        <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnGst"
                                                                            onClick={(e) => { changeText("btnGst", "gst", "GST_3B_PAST_12MONTHS") }}
                                                                        >
                                                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                                            <span style={{ marginLeft: '5px' }} >
                                                                                Upload
                                                                            </span>
                                                                        </button>

                                                                    }
                                                                </>
                                                            }
                                                            {/* <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnGst"
                                                    onClick={(e) => { changeText("btnGst", "gst", "GST_CERTIFICATE") }}
                                                >
                                                    <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                                    <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                    <span style={{ marginLeft: '5px' }} >
                                                        Upload
                                                    </span>
                                                </button> */}
                                                            <div className='clearfix' style={{ height: "30px" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingFive">
                                                        <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            Bank statements of main Operative account
                                                            <br /> for the past 12 months * <i title="Upload Bank statements of main Operative account" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                                                            <button class="btn btn-sm btn-success btn-upload btn-acc" style={{ width: "30px", float: "right", marginLeft: "360px", position: "absolute" }}>
                                                                <img style={{ marginTop: '5px' }} src={vectorHalfRectangle} />
                                                                <img style={{ marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                            </button>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            {
                                                                bankLink.map((item, key) => (
                                                                    <><a href={item[0]} target='_blank' className='panel_links'><i>
                                                                        <img style={{ width: '5%' }} src={pdfIcon} />
                                                                        {item[1].substring(0, 15)}...
                                                                    </i></a>
                                                                        <i>
                                                                            <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeBankFile(key) }} />
                                                                        </i><br /></>
                                                                ))
                                                            }
                                                            {
                                                                bankLink.length == 5 ? <>
                                                                </> : <>
                                                                    <div className='clearfix' style={{ height: "10px" }}></div>
                                                                    {

                                                                        <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnBank"
                                                                            onClick={(e) => { changeText("btnBank", "bank", "BANK_STATEMENTS_MAIN_OPERATIVE_ACCOUNT_PAST_12MONTHS") }}
                                                                        >
                                                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                                            <span style={{ marginLeft: '5px' }} >
                                                                                Upload
                                                                            </span>
                                                                        </button>

                                                                    }
                                                                </>
                                                            }
                                                            {/* <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnBank"
                                                    onClick={(e) => { changeText("btnBank", "bank", "BANK_FILE") }}
                                                >
                                                    <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                                    <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                                    <span style={{ marginLeft: '5px' }} >
                                                        Upload
                                                    </span>
                                                </button> */}
                                                            <div className='clearfix' style={{ height: "30px" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="continue-topheader" style={{ marginLeft: "0px", width: "100%" }}>
                                                <button type="submit" class="btn btn-primary mt-3 continue-button-1"
                                                    onClick={handleTwoCroreGif}
                                                >
                                                    Continue
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </>


                        }





                    </> : dataContent.length > 0 ? <AdditionalDocumentsTwoCrore dataContent={dataContent} rId={newRid.current} /> : <Congratulations />
            }
        </>
    );
}

export default TwoCrore;
