import React, { useState, useEffect, useRef } from 'react';
import '../style/profin.css'
import adminPic from '../Images/adminPic.png'
import dashboardBg from '../Images/dashboardBg.png'
import CalendarIcon from '../../../assets/icons/calendar_icon.png';
import { Calendar } from 'primereact/calendar';
import documentPic from '../Images/document-pic.png';
import votePic from '../Images/vote-pic.png';
import memoCircle from '../Images/memo-circle.png';
import plusIcon from '../Images/plus_icon.PNG';
import leftVector from '../../../assets/icons/vector_left.png'
import forwardArrow from '../Images/left_arrow.png';
import backArrowEResearch from '../Images/right_arrow.png';
import { history } from '../../../utils/Utility';
import { BUYER_REQUEST_INDEX } from '../../../utils/Api';
import $ from 'jquery';
import { Alert } from 'bootstrap';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
}
function Index() {
    let array = [];
    const forceUpdate = useForceUpdate();
    const viewAll = () => {
        history.push("/viewAllProfin");
    };
    const buyerDetails = (e) => {
        sessionStorage.setItem('buyerId', e.id)
        history.push("/buyerDetail");
    };
    const offset = useRef(0);
    const limit = useRef(20);
    const totalRecords = useRef(0);
    const [buyerRequests, setBuyerRequests] = useState([]);
    const [fromDate, setFromDate] = useState('')
    const [filteredRejectedCount, setFilteredRejectedCount] = useState('0');
    const [filteredApprovedCount, setFilteredApprovedCount] = useState('0');
    const [filteredDiscrepentCount, setFilteredDiscrepentCount] = useState('0');

    useEffect(() => {
        getBuyerRequests("", offset.current, limit.current)
    }, []);

    const getBuyerRequests = (e, off, lim) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            );

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(BUYER_REQUEST_INDEX + "offset=" + off + "&limit=" + lim, requestOptions)
                .then(response => response.text())
                .then(result => {
                    result = JSON.parse(result);
                    setBuyerRequests(result.items);
                    totalRecords.current = (result.count)
                    forceUpdate();
                    setFilteredRejectedCount(result.items?.filter((item) => item.status === 'REJECTED').length);
                    setFilteredApprovedCount(result.items?.filter((item) => item.status === 'APPROVED').length);
                    setFilteredDiscrepentCount(result.items?.filter((item) => item.status === 'DISCREPANT').length);
                    forceUpdate();
                }
                ).catch(error => console.log('error', error));

        } catch (error) {
            console.error('Error fetching data:', error);

        }
    }

    const handleInputFromDate = (e) => {
        setFromDate(e.target.value);
        forceUpdate();
    }

    const handleInputToDate = (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(BUYER_REQUEST_INDEX + "offset=" + offset.current + "&limit=" + limit.current + "&fromDate=" + fromDate + "&toDate=" + e.target.value, requestOptions)
            .then(response => response.text())
            .then(result => {
                result = JSON.parse(result)
                setBuyerRequests(result.items)
            })
            .catch(error => console.log('error', error));
    }

    const onSearchChange = (e) => {
        console.log('search---', e.target.value)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(BUYER_REQUEST_INDEX + "offset=" + offset.current + "&limit=" + limit.current + "&searchString=" + e.target.value, requestOptions)
            .then(response => response.text())
            .then(result => {
                result = JSON.parse(result)
                setBuyerRequests(result.items);
                forceUpdate();
                setFilteredRejectedCount(result.items?.filter((item) => item?.status === 'REJECTED').length);
                setFilteredApprovedCount(result.items?.filter((item) => item?.status === 'APPROVED').length);
                setFilteredDiscrepentCount(result.items?.filter((item) => item?.status === 'DISCREPANT').length);
                forceUpdate();
            })
            .catch(error => console.log('error', error));
    }

    const handleFirstButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");
        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 0;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();
        getBuyerRequests("", offset.current, limit.current);
    }

    const handleSecondButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");

        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 20;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleThirdButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");
        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 40;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleFourthButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");
        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 60;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleFifthButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");

        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 80;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleLeftArrowButton = (e) => {
        if (offset.current >= 0) {
            offset.current = (offset.current - limit.current);
            limit.current = (limit.current);
            forceUpdate();
            getBuyerRequests("", offset.current, limit.current);
            forceUpdate();
            $(".pagination-content-active").removeClass("pagination-content-active").addClass("pagination-content").prev("div").addClass("pagination-content-active").removeClass("pagination-content");
        }
    }


    const handleRightArrowButton = (e) => {
        if (totalRecords.current > offset.current + limit.current) {
            offset.current = (offset.current + limit.current);
            limit.current = (limit.current);
            forceUpdate();
            getBuyerRequests("", offset.current, limit.current);
            forceUpdate();
            $(".pagination-content-active").removeClass("pagination-content-active").addClass("pagination-content").next("div").addClass("pagination-content-active").removeClass("pagination-content");
        }
    }

    return (
        <div className='profin-dashboard'>
            <div className='row topheader'>
                <div className="col">
                    <input
                        type="text"
                        className="searchTextBox-2"
                        placeholder="Search"
                        onChange={(e) => onSearchChange(e)}
                    />
                    <img src={adminPic} style={{}} className="pic" />
                </div>
            </div>
            <div className='row' style={{ marginLeft: '3px' }}>
                <div className="col-md-12 dashboardBg">
                    <h1 style={{ fontSize: '20px', color: '#2F2F2F', marginBottom: '10px', marginLeft: '35px', marginTop: '20px', fontWeight: '600' }}>
                        My Dashboard
                    </h1>
                    <span style={{ color: '#757575', marginLeft: '35px', fontSize: '14px', fontWeight: '500', marginBottom: '20px' }}>
                        See updates related to your accounts
                    </span>
                    <div className='left_box' style={{ marginTop: '20px' }}>
                        <div className="firstCard">
                            <h1 className="">
                                <img className="" src={memoCircle} />
                            </h1>
                            <div style={{ fontWeight: '600' }}>
                                Approved
                            </div>
                            <div style={{ fontWeight: '600' }}>
                                {filteredApprovedCount?.toString().padStart(2, '0')}
                            </div>
                        </div>
                        <div className="firstCard">
                            <h1 className="">
                                <img className="" src={votePic} />
                            </h1>
                            <div style={{ fontWeight: '600' }}>
                                Rejected
                            </div>
                            <div style={{ fontWeight: '600' }}>
                                {filteredRejectedCount?.toString().padStart(2, '0')}
                            </div>
                        </div>
                        <div className="firstCard" >
                            <h1 className="">
                                <img className="" src={documentPic} />
                            </h1>
                            <div style={{ fontWeight: '600' }}>
                                Discrepant
                            </div>
                            <div style={{ fontWeight: '600' }}>
                                {filteredDiscrepentCount?.toString().padStart(2, '0')}
                            </div>
                        </div>
                        <div
                            className="firstCard"
                            style={{ backgroundColor: "#04B23D" }}
                        >
                            <div className="">
                                <div className='dashed-css'>
                                    <img src={plusIcon} style={{ width: '21%', marginTop: '10px', marginLeft: '10px' }} />
                                    <div style={{ color: 'white', fontSize: '20px', marginTop: '10px', marginLeft: '10px', marginBottom: '30px' }}>Add new buyer</div>
                                    <h1>
                                        {' '}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }} >
                <div className="col-md-12 col-sm-12 viewAll-1"
                >
                    <div className='buyer-list col-md-6'>
                        Buyer List
                    </div>
                    <div className='col-md-5' style={{ textAlign: "right", paddingRight: "30px" }}>
                        <span onClick={() => viewAll()} className="viewAllLink-2">
                            View All
                            <span class="view-all-arrow">
                                <i class="fa fa-arrow-right view-all-arrow" ></i>
                            </span>
                        </span>
                        <div className='' style={{
                            border: "1px solid #DEDEDE", float: "left", borderRadius: '5px',
                            marginLeft: '15px',
                            height: '100%',
                            float: "right"
                        }}>
                            <input class="border-input" type="date" style={{ border: "none", outline: 'none', height: '30px' }} onChange={(e) => handleInputFromDate(e)} />
                            <input class="border-input" type="date" style={{ border: "none", outline: 'none', height: '63%' }} onChange={(e) => handleInputToDate(e)} />
                        </div>
                        <span className='sort-by-1' style={{ float: "right", marginTop: '8px' }}>
                            Sort By
                        </span>
                    </div>
                </div>
            </div>
            <div className="clearfix" style={{ clear: 'both' }}></div>
            <div className="row">
                <div className='col md-10'>
                    <div className="tableCard " style={{ margintop: "160px" }}>
                        <table className="table table-bordered table-striped table-width" style={{ marginTop: '-40px' }}>
                            <thead>
                                <tr class='text-header'>
                                    <th class="text-table" style={{ borderRadius: "16px 0px 0px 0px", alignItems: "center", justifyContent: "center" }}>
                                        Id
                                    </th>
                                    <th class="text-table">
                                        Name
                                    </th>
                                    <th class="text-table">
                                        Request Date
                                    </th>
                                    <th class="text-table">
                                        Approved Limit
                                    </th>
                                    <th class="text-table" style={{ borderRadius: "0px 16px 0px 0px", width: '220px' }}>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    buyerRequests == null ? <tr><td colSpan={5}>No Records Found</td></tr> : ""
                                }
                                {buyerRequests?.map((item, key) => (
                                    <tr onClick={(e) => buyerDetails(item)}>
                                        <td>
                                            {item.requestId ? item.requestId : "NA"}
                                        </td>
                                        <td>
                                            {item.name ? item.name : "NA"}
                                        </td>
                                        <td>
                                            {item.requestDate ? item.requestDate : "NA"}
                                        </td>
                                        <td>
                                            {item.approvedLimit ? item.approvedLimit : "NA"}
                                        </td>
                                        {item.status == 'INCOMPLETE' ? (
                                            <td>
                                                <button className={'statusIncomplete'}>
                                                    {item.status}
                                                </button>
                                            </td>
                                        )
                                            : item.status == 'UNDER_PROCESS' ? (
                                                <td>
                                                    <button className={'statusBtnunderProcess'}>
                                                        {item.status}
                                                    </button>
                                                </td>
                                            ) :
                                                item?.status == 'DISCREPANT' ? (
                                                    <td>
                                                        <button className={'statusBtnunderProcess'}>
                                                            {item.status}
                                                        </button>
                                                    </td>
                                                ) : item?.status == 'APPROVED' ? (
                                                    <td>
                                                        <button className={'statusBtn'}>
                                                            {item.status}
                                                        </button>
                                                    </td>
                                                ) : item?.status == 'REJECTED' ? (
                                                    <td>
                                                        <button className={'statusRejected'}>
                                                            {item.status}
                                                        </button>
                                                    </td>
                                                ) : <td>--</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="pagination-section">
                <span class="pagination-right">
                    <div class="pagination" >
                        
                        {offset.current == 0 ?
                            <img src={backArrowEResearch}
                                alt="" className="left-vector" disabled />
                            : <img src={backArrowEResearch}
                                alt="" className="left-vector" onClick={(e) => handleLeftArrowButton(e)} />
                        }
                        {
                            totalRecords.current >= 0?<div class="pagination-content-active"
                                onClick={(e) => handleFirstButton(e)}>
                            1
                          </div>:""
                        }
                        {
                            totalRecords.current >= 20?<div class="pagination-content" onClick={(e) => handleSecondButton(e)}
                            >2</div>:""
                        }
                         {
                            totalRecords.current >= 40?<div class="pagination-content" onClick={(e) => handleThirdButton(e)}
                            >3</div>:""
                        }
                          {
                            totalRecords.current >= 60?<div class="pagination-content" onClick={(e) => handleFourthButton(e)}
                            >4</div>:""
                        }
                          {
                            totalRecords.current >= 80?<div class="pagination-content" onClick={(e) => handleFifthButton(e)}
                            >5</div>:""
                        }
                        {
                            (offset.current == totalRecords.current - limit.current) ?
                                <img src={forwardArrow} alt="" className="left-vector" disabled />
                                : <img src={forwardArrow} alt="" className="left-vector" onClick={(e) => handleRightArrowButton(e)} />
                        }
                    </div>
                </span>
            </div>
        </div>
    );
};
export default Index;
