import React, { Component } from 'react';
import List from "./view/list";
import "./style.scss";
import { toast } from 'react-toastify';
import { history, fireAjaxRequest, createDropDown } from "../../utils/Utility"
import { CREATE_AUCTION, BUYNOW_PAYLATER, CASHANDCARRY_ARBITAGE, GET_AUCTION_LIST, GET_CASHANDCARRY_ARBITAGE_RECORDS, SET_ALERT, COMMODITY_LOCATION_API,GET_PERSONAL_INFO,EMANDI_CASH_VERIFY,GET_EMANDI_CASH_VIDEO } from '../../utils/Api'
import axios from "axios";
import "./mediaOnlyStyle.scss";
import CustomLoader from '../../components/CustomLoader';
import { element } from 'prop-types';
import AnalyticsEventTracker from '../AnalyticsEventTracker';
class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                slider: [0, 0],
                sliderValue: [0, 0]
            },
            activeAuctionType: "buy",
            buynowPopUp: false,
            cashandCarryPopUp: false,
            cashandCarryConfimPopUp: false,
            setalertPopUp: false,
            showemandicashPopUp:false,
            kycPopup:false,
            userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
            option: [
                {
                    label: "MT",
                    value: "mt"
                }, {
                    label: "BALES",
                    value: "bales"
                }, {
                    label: "QTL",
                    value: "qtl"
                }
            ],
            financeneeded: "Yes",
            cashandcarryList: [],
            loading: false,
            kyccheck:[],
            emandicashstatusPopup:false,
            status:"",
            emandicashListPopup:false
        }

    }

    componentDidMount() {
        this.getEmandiCashList()
        this.CheckemandiCashStatus();
        this.getuserDetails();
        let isAdmin = JSON.parse(sessionStorage.getItem("isAdmin"))
        // console.log("Checking Admin status:"+isAdmin);
        //Get Auction List
        let param = {
            "offerType": "All",
            "pagination": false,
            "auctionStatus": "Active"
        }

        if (!isAdmin){
            axios.post(GET_AUCTION_LIST, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let activeAuctionList = [], scheduledAuctionList = [];
                    // response.data.items.map((element) => {
                    //     if (element.status === "Active") {
                    //         activeAuctionList.push(element);
                    //     }else if(element.status === "Scheduled"){
                    //         scheduledAuctionList.push(element);
                    //     } 
                    //     else {
                          
                    //     }
                    // })
                    this.setState({ activeAuctionList: response.data.items });
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                // toast.error(error.response.data.message);
            })

            //Get scheduled auctions
            // Assuming GET_AUCTION_LIST is defined as the correct URL
            // const GET_AUCTION_LIST = 'https://servicedev.origoemandi.com:8088/origo/getAuction';

            // Define the request parameters
            const upcomingparam = {
                "offerType": "All",
                 "pagination": false,
                "auctionStatus": "Scheduled"
            };

            // Make the Axios POST request
            axios.post(GET_AUCTION_LIST, upcomingparam, {
            headers: {
                "Content-Type": "application/json"
                }
            })
            .then(response => {
            // Check if the response status is 200 (OK)
            if (response.status === 200) {
            // Assuming response.data.items contains the list of scheduled auctions
            // Update the state with the list of scheduled auctions
            this.setState({ scheduledAuctionList: response.data.items });
                 }
            })
            .catch(error => {
             // Handle error here
            console.error('Error:', error);
            });
    
            //Commodity Location 
            axios.get(`${COMMODITY_LOCATION_API}?auctionStatus=ACTIVE&auctionStatus=SCHEDULED`, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                // console.log(response);
                let commodityDropDownList = [], locationDropDownList = [], auctionTypeDropDown = [];
                if (response.status === 200) {
                    let { formData } = this.state;
                    // console.log(response.data)
                    commodityDropDownList = createDropDown(response.data.data.commodities, "name", "id");
                    response.data.data.locations && response.data.data.locations.map((element) => {
                        locationDropDownList.push({ "label": element, "value": element })
                    })
                    auctionTypeDropDown = [
                        { "label": "All", "value": "All" },
                        { "label": "Bid to Buy", "value": "Offer_To_Sell" },
                        { "label": "Bid to Sell", "value": "Offer_To_Buy" }
                    ]
                    formData.slider = [0, response.data.data.maxTotalQty]
    
                    this.setState({ commodityDropDownList: commodityDropDownList, locationDropDownList: locationDropDownList, auctionTypeDropDown: auctionTypeDropDown, formData: formData });
    
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                // toast.error(error.response.data.message);
            })
    
            axios.get(GET_CASHANDCARRY_ARBITAGE_RECORDS, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    this.setState({ cashandcarryList: response.data.data.records })
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                // toast.error(error.response.data.message);
            })
        }

        

        // (async () => {
        //     try {
        //         const resp = await fireAjaxRequest(GET_CASHANDCARRY_ARBITAGE_RECORDS, {
        //             method: 'GET',
        //             headers: {
        //                 "Content-Type": "application/json"
        //             }
        //         });
        //         if (resp && resp.data) {
        //             this.setState({ cashandcarryList: resp && resp.data })
        //         }
        //     } catch (err) {
        //         toast.error(err.message || "Your transaction failed, please check the account details provided and update them with correct values.");
        //     }
        // })();


        if (isAdmin) {
            history.push("/auction");
            // console.log(isAdmin, "admin")
        }

    }
    
    handelInputChange = (e) => {
        let { formData } = this.state;
        let alphabetOnly = ["commidityname", "location", "fullname", "buynowfullname"];
        if (e.target.type == "checkbox") {
            formData.financeneeded = e.target.value
            this.setState({ formData: formData })
        }
        alphabetOnly.map((element) => {
            if (e.target.name === element) {
                e.target.value = e.target.value.replace(/[^a-z A-Z]+/g, "");
            }
        })

        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });

        if (e.target.name === "auctionType" || e.target.name === "commodityName" || e.target.name === "locationName") {
            this.setState({activeAuctionList:[],scheduledAuctionList:[]})
            this.onAuctionFilterChange(formData);
        }


    }
getuserDetails=()=>{
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        (async () => {
            try {
                const resp = await fireAjaxRequest(`${GET_PERSONAL_INFO}/${userInfo.user.id}`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                        "Content-Type": "application/json"
                    }
                });
                if (resp) {
                    this.setState({kyccheck:resp.kyc})
                }
            } catch (err) {
            }
        })();
}
CheckemandiCashStatus=()=>{
    (async () => {
        try {
            const resp = await fireAjaxRequest(`${EMANDI_CASH_VERIFY}/${this.state.userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    "Content-Type": "application/json"
                }
            });
        } catch (err) {
            // toast.error("Failed to load User Account !");
            // if (err.message)
            this.setState({status:err.message})
            // if (err.message !== "data not found for this user") {
            //     toast.error(err.message || "Failed to load User Account !");
            // }
        }
    })();
}

    onAuctionFilterChange = (formData) => {
        //Get Auction List
        let param = {
            "offerType": formData.auctionType,
            "pagination": false,
            "location": formData.locationName ? [formData.locationName] : [],
            "commodityId": formData.commodityName ? [formData.commodityName] : [],
            "min": formData.sliderValue[0],
            "max": formData.sliderValue[1],
            "auctionStatus": "Active"
        }

        axios.post(GET_AUCTION_LIST, param, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                let activeAuctionList = [], scheduledAuctionList = [];
                // response.data.items.map((element) => {
                //     if (element.status === "Active") {
                //         activeAuctionList.push(element);
                //     } else {
                //         scheduledAuctionList.push(element);
                //     }
                // })
                this.setState({ activeAuctionList: response.data.items});
            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
            toast.error(`${error.response.data.message} in live auctions`);
        })

        // Get upcoming auctions filters
        let upcomingparam = {
            "offerType": formData.auctionType,
            "pagination": false,
            "location": formData.locationName ? [formData.locationName] : [],
            "commodityId": formData.commodityName ? [formData.commodityName] : [],
            "min": formData.sliderValue[0],
            "max": formData.sliderValue[1],
            "auctionStatus": "Scheduled"
        }

        axios.post(GET_AUCTION_LIST, upcomingparam, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                let activeAuctionList = [], scheduledAuctionList = [];
                // response.data.items.map((element) => {
                //     if (element.status === "Active") {
                //         activeAuctionList.push(element);
                //     } else {
                //         scheduledAuctionList.push(element);
                //     }
                // })
                this.setState({scheduledAuctionList: response.data.items });
            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
            toast.error(`${error.response.data.message} in upcoming auction`  );
        })
    }

    onSubmitBuynow = () => {
        let { formData } = this.state;
        if (!formData.buynowfullname) {
            toast.error("Please enter Name")
        }
        else if (!formData.buynownumber) {
            toast.error("Please enter Mobile Number")
        }
        else if (formData.buynownumber.length < 10) {
            toast.error("Phone Number should contain 10 digits")
        }
        else {
            const sendBody = {
                "name": formData.buynowfullname,
                "mobileNo": formData.buynownumber
            };

            (async () => {
                // this.setState({ loading: true });
                try {
                    const resp = await fireAjaxRequest(BUYNOW_PAYLATER, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : ""
                        },
                        body: JSON.stringify(sendBody)
                    });
                    // this.setState({ loading: false });
                    if (resp && resp.data) {
                        // toast.success("Auction created Successfully");
                        // formData.address = `${resp && resp.name} ${resp && resp.address}`;
                        // // formData.username = resp && resp.name;
                        // this.setState({ formData: formData, showaddressfield: true })
                        formData.buynowfullname = "";
                        formData.buynownumber = "";
                        formData.dailogContend = [`Your request has been sent successfully and your request id is ${resp && resp.data.requestId}.You will receive a call shortly!`]
                        this.setState({ requestId: resp && resp.data.requestId, cashandCarryConfimPopUp: true, buynowPopUp: false, formData: formData })
                    }
                } catch (err) {
                    // this.setState({ loading: true });
                    toast.error(err.message);
                    this.setState({ showaddressfield: false })
                }
            })();
        }
    }

    onSubmitAuction = () => {
        let { formData } = this.state
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo) {
            formData.name = userInfo.user.name || "Trader";
            formData.number = userInfo.user.phoneNo
        }
        if(!formData.commidityname && !formData.location && !formData.quantity && !formData.unit && !formData.name && !formData.number){
            toast.error("Please enter the required details")
        }
       else if (!formData.commidityname) {
            toast.error("Please enter commodity name");
        }
        else if (!formData.location) {
            toast.error("Please enter location");
        }
        else if (!formData.quantity) {
            toast.error("Please enter quantity");
        }
        else if (!formData.unit) {
            toast.error("Please enter unit");
        }
        else if (!formData.name) {
            toast.error(this.state.activeAuctionType=="buy"?"Please enter buyer's name":"Please enter seller's name");
        }
        else if (!formData.number) {
            toast.error(this.state.activeAuctionType=="buy"?"Please enter buyer's mobile number":"Please enter seller's mobile number");
        }
        else if (formData.number.length < 10) {
            toast.error("Mobile number should be 10 digits");
        } else {
            const sendBody = {
                "auctionType": this.state.activeAuctionType == "buy" ? "Buy" : "Sell",
                "commodityName": formData.commidityname,
                "location": formData.location,
                "quantity": formData.quantity,
                "uom": formData.unit,
                "name": formData.name,
                "mobileNo": formData.number
            };

            (async () => {
                this.setState({ loading: true });
                try {
                    const resp = await fireAjaxRequest(CREATE_AUCTION, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : ""
                        },
                        body: JSON.stringify(sendBody)
                    });
                    this.setState({ loading: false });
                    if (resp && resp.data) {
                        toast.success("Auction created Successfully");
                        // formData.address = `${resp && resp.name} ${resp && resp.address}`;
                        // // formData.username = resp && resp.name;
                        // this.setState({ formData: formData, showaddressfield: true })
                        formData.commidityname = "";
                        formData.location = "";
                        formData.quantity = "";
                        formData.name = "";
                        formData.number = "";
                        formData.unit = "";
                        formData.dailogContend = [`Your request has been sent successfully and your request id is ${resp && resp.data.requestId}.You will receive a call shortly!`]
                        this.setState({ requestId: resp && resp.data.requestId, cashandCarryConfimPopUp: true, formData: formData })
                    }
                } catch (err) {
                    // this.setState({ loading: false });
                    toast.error(err.message);
                    this.setState({ showaddressfield: false })
                }
            })();
        }
    }

    onClickSetalert = (e,event=null) => {
        if(event){
            event.stopPropagation();
             event.preventDefault();
        }
        
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let param = {
            "name": "string",
            "mobileNo": "string",
            "auctionId": e.auctionId
        }
        if (userInfo) {
            param.name = userInfo.user.name || "Trader";
            param.mobileNo = userInfo.user.phoneNo;
            axios.post(SET_ALERT, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let { formData } = this.state;
                    formData.dailogContend = ["Your preferred commodity auction alert has been set successfully. You will be notified via SMS before the auction goes live"]
                    this.setState({ cashandCarryConfimPopUp: true, setalertPopUp: false, formData: formData })
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                // toast.error(error.response.data.message);
                toast.error("An Alert  has been already created for this commodity");
            })

        } else {
            this.setState({ setalertPopUp: true })
        }
        this.setState({ setAlertAuctionId: e.auctionId });
    }

    onBidNowClick = (e) => {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo) {
            history.push(`/auction/auctionDetails/${e.id}`)
        } else {
            toast.error("Login to place bids")
            history.push('/register');
        }
    }
    

    onhideSetalert = () => {
        let  {formData}=this.state;
        formData.setalertfullname="";
        formData.setalertnumber=""
        this.setState({ setalertPopUp: false,formData:formData })
    }

    onSubmitcashandcarryPopUp = () => {
        let { formData } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo) {
            formData.cashandcarryfullname = userInfo.user.name || "Trader";
            formData.cashandcarrynumber = userInfo.user.phoneNo
        }
        if (!formData.cashandcarryfullname) {
            toast.error("Please enter Name")
        }
        else if (!formData.cashandcarrynumber) {
            toast.error("Please enter Mobile Number")
        }
        else if (formData.cashandcarrynumber.length < 10) {
            toast.error("Phone Number should contain 10 digits")
        }
        else if (!formData.financeneeded) {
            toast.error("Please select Finance needed")
        }
        else {
            const sendBody = {
                "commodityName": formData.arbitageCommodityName,
                "rateOfReturn": formData.arbitagerateOfreturn,
                "lockInPeriod": formData.arbitagelockInperiod,
                "name": formData.cashandcarryfullname,
                "mobileNo": formData.cashandcarrynumber,
                "financeNeeded": formData.financeneeded == "Yes" ? true : false

            };
            (async () => {
                // this.setState({ loading: true });
                try {
                    const resp = await fireAjaxRequest(CASHANDCARRY_ARBITAGE, {
                        method: 'POST',
                        body: JSON.stringify(sendBody),
                        headers: {
                            "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : "",
                            "Content-Type": "application/json"
                        }
                    });
                    // this.setState({ loading: false });
                    if (resp && resp.data) {
                        formData.dailogContend = [`Your request has been sent successfully and your request id is ${resp && resp.data.requestId}.You will receive a call shortly!`]
                        this.setState({ cashandCarryConfimPopUp: true, cashandCarryPopUp: false, requestId: resp && resp.data.requestId })

                    }
                } catch (err) {
                    // this.setState({ loading: false });
                }
            })();


        }
    }

    onSubmitsetalertPopUp = () => {
        let { formData, setAlertAuctionId } = this.state;
        if (!formData.setalertfullname) {
            toast.error("Please enter Name")
        }
        else if (!formData.setalertnumber) {
            toast.error("Please enter Mobile Number")
        }
        else if (formData.setalertnumber.length < 10) {
            toast.error("Phone Number should contain 10 digits")
        }
        else {
            let param = {
                "name": formData.setalertfullname,
                "mobileNo": formData.setalertnumber,
                "auctionId": setAlertAuctionId
            }

            axios.post(SET_ALERT, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let { formData } = this.state;
                    formData.dailogContend = ["Your preferred commodity auction alert has been set successfully. You will be notified via SMS before the auction goes live"]
                    this.setState({ cashandCarryConfimPopUp: true, setalertPopUp: false, formData: formData })

                }
            }).catch((error) => {
                this.setState({ setalertPopUp: false });
                // toast.error(error.response.data.message);
                if (error.response.data.message === "You have already set an alert...") {
                    toast.error("An alert have been already created for this commodity");

                } else {
                    toast.error(error.response.data.message);
                }

            })

        }
    }

    hidecashandCarryConfimPopUp = () => {
        let {formData}=this.state;
        formData.cashandcarryfullname="";
        formData.cashandcarrynumber="";
        formData.financeneeded="";
        this.setState({ cashandCarryConfimPopUp: false,formData:formData })
    }

    onclickAvailnow = (e) => {
        let { formData, setAlertAuctionId } = this.state;
        formData.arbitageCommodityName = e.commodityName;
        formData.arbitagelockInperiod = e.lockInPeriod;
        formData.arbitagerateOfreturn = e.rateOfReturn;
        this.setState({ cashandCarryPopUp: true, formData: formData })
    }

    hideCashandCarryPopUp = () => {
        let {formData}=this.state;
        formData.cashandcarryfullname="";
        formData.cashandcarrynumber="";
        formData.financeneeded="";
        this.setState({ cashandCarryPopUp: false })

    }

    onAuctionTypeChange = (value) => {
        this.setState({ activeAuctionType: value });
    }

    onSliderChange = (e) => {
        let { formData } = this.state;
        formData.sliderValue = e;
        this.setState({ formData: formData });

        this.onAuctionFilterChange(formData);

        // console.log(e, 'slider')
    }

    hidebuynowPopUp = () => {
        let {formData}=this.state;
        formData.buynowfullname="";
        formData.buynownumber=""
        this.setState({ buynowPopUp: false,formData:formData })
    }

    onClickbuynowpaylater = () => {
        let { formData, userInfo } = this.state;
        // console.log(userInfo, "lfjksdf")
        if (userInfo) {
            const sendBody = {
                "name": userInfo.user.name || "Trader",
                "mobileNo": userInfo.user.phoneNo,
            };

            (async () => {
                // this.setState({ loading: true });
                try {
                    const resp = await fireAjaxRequest(BUYNOW_PAYLATER, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.state.userInfo ? `Bearer ${this.state.userInfo.token}` : ""
                        },
                        body: JSON.stringify(sendBody)
                    });
                    // this.setState({ loading: false });
                    if (resp && resp.data) {
                        // toast.success("Auction created Successfully");
                        // formData.address = `${resp && resp.name} ${resp && resp.address}`;
                        // // formData.username = resp && resp.name;
                        // this.setState({ formData: formData, showaddressfield: true })
                        formData.buynowfullname = "";
                        formData.buynownumber = "";
                        formData.dailogContend = [`Your request has been sent successfully and your request id is ${resp && resp.data.requestId}.You will receive a call shortly!`]
                        this.setState({ requestId: resp && resp.data.requestId, cashandCarryConfimPopUp: true, buynowPopUp: false, formData: formData })
                    }
                } catch (err) {
                    // this.setState({ loading: true });
                    toast.error(err.message);
                    this.setState({ showaddressfield: false })
                }
            })();
        } else {
            this.setState({ buynowPopUp: true })
        }
    }

    onClickviewmore = (url) => {
        history.push(`/${url}`);
    }

    onClickCommodityresearch = () => {
        history.push('/CommodityResearch');
    }

    onClickBack = () => {
        history.push('/eAuction');
    }

    onClickRegister = () => {
        sessionStorage.removeItem("commodityHistoryFlag");
        history.push('/register');
    }
    onClickContactUS=()=>{
        history.push('/ContactUS');

    }
    onClickCashandCarryArbitage = () => {
        history.push('/CashandCarryArbitage');
    }
    onClickEmandiTV=()=>{
        history.push('/EmandiTV')
    }
    onClickEmandiNews=()=>{
        history.push('/EmandinewsList')
    }
    onDisclaimerClick = () => {
        window.open("#/Disclaimer");
      }
    onClickExplorenow=()=>{
        // history.push('/EmandiCash');
        sessionStorage.setItem("isEmandiCash", true);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        (async () => {
            try {
                const resp = await fireAjaxRequest(`${GET_PERSONAL_INFO}/${userInfo.user.id}`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                        "Content-Type": "application/json"
                    }
                });
                if (resp) {
                    this.setState({kyccheck:resp.kyc})
                }
            } catch (err) {
            }
        })();
        this.setState({showemandicashPopUp:true,kycPopup:false})
    }
    onClickEmandicashContinue=()=>{
        let {kyccheck}=this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (!userInfo) {
          toast.error("Please Login")
          history.push('/register');
        } 
        // else if(!kyccheck.length) {
        //     this.setState({kycPopup:true})
        //     // history.push('/editProfile/123456')
        // }
        else if(this.state.status){
            this.setState({emandicashstatusPopup:true,showemandicashPopUp:false})
        }
        else{
            history.push('/EmandiCash');
        }
    }
    onAcceptKycPopup=()=>{
        // window.location.reload();
            history.push('/editProfile/123456')

    }
    onHideKycPopup=()=>{
        window.location.reload();

    }
    hideEmandicashPopup=()=>{
        this.setState({showemandicashPopUp:false})
    }
    onAcceptEmandiCashStatus=()=>{
        history.push('./account')
    }
    onCancelEmandiCashStatus=()=>{
        this.setState({emandicashstatusPopup:false})
    }
    getEmandiCashList=()=>{
        axios.get(GET_EMANDI_CASH_VIDEO, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                this.setState({emandiCashList:response.data.data})
            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
            // toast.error(error.response.data.message);
        })
    }
    onClickEmandiCashicon=()=>{
        if(!this.state.emandiCashList){
            toast.error("currently video not available")
        }
        else{
            this.setState({emandicashListPopup:true})
        }
    }
    onClickCloseEmandiCashiconPopup=()=>{
        this.setState({emandicashListPopup:false})
    }
    render() {
        return (<CustomLoader loading={this.state.loading || false}>
            < List
                handelInputChange={this.handelInputChange}
                state={this.state}
                formData={this.state.formData}
                onSliderChange={this.onSliderChange}
                onAuctionTypeChange={this.onAuctionTypeChange}
                // buynowPopUp={this.state.buynowPopUp}
                hidebuynowPopUp={this.hidebuynowPopUp}
                onClickbuynowpaylater={this.onClickbuynowpaylater}
                onSubmitBuynow={this.onSubmitBuynow}
                cashandCarryPopUp={this.state.cashandCarryPopUp}
                hideCashandCarryPopUp={this.hideCashandCarryPopUp}
                onclickAvailnow={this.onclickAvailnow}
                cashandCarryConfimPopUp={this.state.cashandCarryConfimPopUp}
                hidecashandCarryConfimPopUp={this.hidecashandCarryConfimPopUp}
                onSubmitcashandcarryPopUp={this.onSubmitcashandcarryPopUp}
                setalertPopUp={this.state.setalertPopUp}
                onClickSetalert={this.onClickSetalert}
                onhideSetalert={this.onhideSetalert}
                onSubmitsetalertPopUp={this.onSubmitsetalertPopUp}
                onClickviewmore={this.onClickviewmore}
                onClickCommodityresearch={this.onClickCommodityresearch}
                onSubmitAuction={this.onSubmitAuction}
                onClickRegister={this.onClickRegister}
                onBidNowClick={this.onBidNowClick}
                timeremaining={this.state.timeremaining}
                // calculatetime={this.calculatetime}
                onClickContactUS={this.onClickContactUS}
                onClickCashandCarryArbitage={this.onClickCashandCarryArbitage}
                onClickExplorenow={this.onClickExplorenow}
                onClickEmandicashContinue={this.onClickEmandicashContinue}
                onAcceptKycPopup={this.onAcceptKycPopup}
                onHideKycPopup={this.onHideKycPopup}
                onClickEmandiTV={this.onClickEmandiTV}
                onClickEmandiNews={this.onClickEmandiNews}
                hideEmandicashPopup={this.hideEmandicashPopup}
                onAcceptEmandiCashStatus={this.onAcceptEmandiCashStatus}
                onCancelEmandiCashStatus={this.onCancelEmandiCashStatus}
                onClickEmandiCashicon={this.onClickEmandiCashicon}
                onClickCloseEmandiCashiconPopup={this.onClickCloseEmandiCashiconPopup}
                onDisclaimerClick={this.onDisclaimerClick}
            /></CustomLoader>
        );
    }
}

export default LandingPage;