import React, { Component } from 'react';
import '../style/style.css';
import rightArrow from '../../../assets/icons/right_arrow.png';
import leftVector from '../../../assets/icons/vector_left.png';
import RightVector from '../../../assets/icons/vector_right.png';
import { GET_ECASH_REQUESTS } from '../../../utils/Api';
import excel from '../../../assets/images/excel.png';
import CalendarIcon from '../../../assets/icons/calendar_icon.png';
import { Calendar } from 'primereact/calendar';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { history } from "../../../utils/Utility";
import Details from './Details';
import $ from 'jquery';


class index extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.tableRef = React.createRef(null);
    this.state = {
      eCashRequest: {},
      selectValue: '',
      fromDate: '',
      toDate: '',
      searchString: '',
      EcashStatus: '',
      offset:0,
      limit:20,
      entries: 20
    };
    this.data = '';
    this.className = '';
    this.getECashRequest(0, this.state.limit, '', '');
  } 

  getECashRequest = (offset, limit) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS + '?offset=' + offset + '&limit=' + limit,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ eCashRequest: result });
        this.setState({totalCount:result.count})
      })
      .catch((error) => console.log('error', error));
  };

  getECashRequestDateWise = (offset, limit, fromDate, toDate) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS +
      '?offset=' +
      offset +
      '&limit=' +
      limit +
      '&fromDate=' +
      fromDate +
      '&toDate=' +
      toDate,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ eCashRequest: result });
      })
      .catch((error) => console.log('error', error));
  };

  getECashRequestSearch = (offset, limit, searchString) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS +
      '?offset=' +
      offset +
      '&limit=' +
      limit +
      '&searchString=' +
      searchString,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ eCashRequest: result });
      })
      .catch((error) => console.log('error', error));
  };

  handleProceed(id) {
    history.push("/eCashDetail/" + id)
  };


  getECashRequestStatus = (offset, limit, EcashStatus) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS +
      '?offset=' +
      offset +
      '&limit=' +
      limit +
      '&EcashStatus=' +
      EcashStatus,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ eCashRequest: result });
      })
      .catch((error) => console.log('error', error));
  };

  onLeftDateChange = (e) => {
    this.state.fromDate = new Date(e.target.value).toISOString().slice(0, 10);
  
  };

  onRightDateChange = (e) => {
    var dt=new Date(e.target.value);
    var month=dt.getMonth()+1;
    if(month < 10)
    {
      month="0"+month;
    }
    var day=dt.getDate();
    if(day < 10)
    {
      day="0"+day;
    }

    this.state.toDate =dt.getFullYear()+"-"+month+"-"+day
    this.getECashRequestDateWise(
      0,
      500,
      this.state.fromDate,
      this.state.toDate
    );
  };

  handleEntries = (e) => {
    this.setState({entries: e.target.value,limit:e.target.value})
    this.getECashRequest(0, e.target.value);
  }

  onSearchChange = (e) => {
    this.state.search = e.target.value;
    this.getECashRequestSearch(0, 500, this.state.search);
  };

  onStatusClickAll = () => {
    this.getECashRequestStatus(0, 500, this.state.EcashStatus);
    document.getElementById('recommended_to_lender').style.background = '';
    document.getElementById('recommended_to_lender').style.color = '';
    document.getElementById('under_process').style.background = '';
    document.getElementById('under_process').style.color = '';
    document.getElementById('incomplete_proposal').style.background = '';
    document.getElementById('incomplete_proposal').style.color = '';
    document.getElementById('all').style.background = '#04B23D';
    document.getElementById('all').style.color = 'white';
  };

  onStatusClickRecommended = () => {
    this.getECashRequestStatus(0, 500, "RECOMMENDED_TO_LENDER");
    document.getElementById('all').style.background = '';
    document.getElementById('all').style.color = '';
    document.getElementById('under_process').style.background = '';
    document.getElementById('under_process').style.color = '';
    document.getElementById('incomplete_proposal').style.background = '';
    document.getElementById('incomplete_proposal').style.color = '';
    document.getElementById('recommended_to_lender').style.background = '#04B23D';
    document.getElementById('recommended_to_lender').style.color = 'white';
  };

  onStatusClickUnderProcess = () => {
    this.getECashRequestStatus(0, 500, "UNDER_PROCESS_WITH_RISK");
    document.getElementById('recommended_to_lender').style.background = '';
    document.getElementById('recommended_to_lender').style.color = '';
    document.getElementById('all').style.background = '';
    document.getElementById('all').style.color = '';
    document.getElementById('incomplete_proposal').style.background = '';
    document.getElementById('incomplete_proposal').style.color = '';
    document.getElementById('under_process').style.background = '#04B23D';
    document.getElementById('under_process').style.color = 'white';
  };

  onStatusClickIncomplete = () => {
    this.getECashRequestStatus(0, 500, "INCOMPLETE_PROPOSAL");
    document.getElementById('recommended_to_lender').style.background = '';
    document.getElementById('recommended_to_lender').style.color = '';
    document.getElementById('under_process').style.background = '';
    document.getElementById('under_process').style.color = '';
    document.getElementById('all').style.background = '';
    document.getElementById('all').style.color = '';
    document.getElementById('incomplete_proposal').style.background = '#04B23D';
    document.getElementById('incomplete_proposal').style.color = 'white';
  };

  handleECashClick = (e) => {
    this.props.history.push('/addECash');
  };
  componentDidMount() {
    this.getECashRequest(0, this.state.limit);
  }

  handleLeftVector = (e) => {
    $(".active").removeClass("active").prev("button").addClass("active");
    let offset = parseInt(this.state.offset) - parseInt(this.state.limit);
    let limit = this.state.limit;
    this.setState({ offset: offset, limit: limit})
    this.getECashRequest(offset, limit);
  }

  handleRightVector = (e) => {
   $(".active").removeClass("active").next("button").addClass("active");
    let offset = parseInt(this.state.offset) + parseInt(this.state.limit);
    let limit = this.state.entries;
    this.setState({ offset: offset, limit: limit })
    this.getECashRequest(offset, limit);
  }

  handleFirstButton = (e) => {
    $(e.target).addClass("active");
    $(e.target).siblings("button").removeClass("active")
    let offset = 0;
    let limit = this.state.limit;
    this.setState({ offset: offset, limit: limit,entries:parseInt(this.state.entries)-parseInt(limit) })
    this.getECashRequest(offset, limit);
  }

  handleSecondButton = (e) => {
    $(e.target).addClass("active");
    $(e.target).siblings("button").removeClass("active")
    let offset = this.state.limit;
    let limit = this.state.limit;
    this.setState({ offset: offset, limit: limit,entries:parseInt(this.state.entries)+parseInt(limit) })
    this.getECashRequest(offset, limit);
  }

  handleThirdButton = (e) => {
    $(e.target).addClass("active");
    $(e.target).siblings("button").removeClass("active")
    let offset = parseInt(this.state.limit)+parseInt(this.state.offset);
    let limit = this.state.limit;
    this.setState({ offset: offset, limit: limit,entries:parseInt(this.state.entries)+parseInt(limit) })
    this.getECashRequest(offset, limit);
  }


  handleDropdownChange = (event) => {
    this.getECashRequestStatus(0, 500, (event.target.value));
    if (event.target.value == 'ALL') {
      this.getECashRequest(0, 500);
    }
  };

  render() {
    let {
      name,
      value,
      dateFormat,
    } = this.props;

    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substring(0, 10);
    return (
      <div>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
          integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
          crossorigin="anonymous"
        ></link>
        <div className="container-fluid">
          {/* <Admin /> */}
          <span className="row row-topheader">
            <div className="col-md-4 col-sm-2">
              <input
                type="text"
                className="searchTextBox-1 input-search"
                placeholder="Search...."
                onChange={(e) => this.onSearchChange(e)}
              />
            </div>
            <div
              className="col-md-5"
              style={{ display: 'flex', marginLeft: '35px' }}
            >
              <label
                for="cars"
                style={{
                  paddingTop: '6px',
                  paddingRight: '6px',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                Entries
              </label>
              <select
                class="form-select"
                placeholder="Time :"
                aria-label="Default select example"
                style={{
                  height: '57%',
                  color: '#000',
                  width: '21%',
                  marginLeft: '5px',
                }}
                onChange={(e) => this.handleEntries(e)}
              >
                <option >10</option>
                <option selected>20</option>
                <option>30</option>
              </select>

              <div
                className="custom-calender col"
                style={{
                  width: '90%',
                  border: '1px solid #DEDEDE',
                  borderRadius: '5px',
                  marginLeft: '15px',
                  height: '57%',
                  display: 'flex',
                }}
              >
                <img
                  className="img-date"
                  src={CalendarIcon}
                  alt=""
                  style={{ width: '20px', margin: '13px' }}
                />
                <Calendar
                  className="calender"
                  style={{ width: '44%' }}
                  placeholder="DD/MM/YY"
                  defaultValue={'222'}
                  onChange={(e) => this.onLeftDateChange(e)}
                  dateFormat={dateFormat || 'dd/mm/yy'}
                  showOtherMonths={true}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange="1940:2050"
                  showOnFocus={true}
                  name={name}
                  value={value}
                />
                <span style={{ right: "410px", position: "absolute" }}>-</span>
                <Calendar
                  style={{ width: '50%' }}
                  defaultValue={date}
                  placeholder="DD/MM/YY"
                  onChange={(e) => this.onRightDateChange(e)}
                  dateFormat={dateFormat || 'dd/mm/yy'}
                  showOtherMonths={true}
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange="1940:2050"
                  showOnFocus={true}
                  name={name}
                  value={value}
                />
              </div>
            </div>
            <div className="col">
              <div className="col-md-12 col-sm-12">
                <DownloadTableExcel
                  filename="e-cash data"
                  sheet="users"
                  currentTableRef={this.tableRef.current}
                >
                  <span className="viewAllLink">
                    <img src={excel} style={{ width: '30px', cursor: 'pointer' }} />
                  </span>
                </DownloadTableExcel>
                {/* <button disabled className="btnAdd" style={{fontSize:"12px"}}>
                {' '}
                <b style={{ fontSize: '25px',marginRight:"10px"}}>+</b>
                &nbsp;Add eCash
              </button> */}
                <button className="btnAdd" onClick={this.handleECashClick}>
                  {' '}
                  <b>+</b>&nbsp;Add eCash
                </button>
              </div>
            </div>
          </span>
          <div className="sectionBtn">
            <div class="mb-3 mt-4 ">
              <select
                class="form-select select-css"
                onChange={this.handleDropdownChange}
                style={{
                  height: '38px', color: 'grey', width: '20%', marginLeft: '10px'
                }}
              >
                <option value="ALL" >All</option>
                <option value="APPROVED" style={{ color: 'grey' }}>Approved</option>
                <option value="REJECTED" style={{ color: 'grey' }}>Rejected</option>
                <option value="INCOMPLETE_PROPOSAL">Incomplete Proposal</option>
                <option value="RED_FLAGGED_WITH_RISK"> Red Flagged With Risk</option>
                <option value="SYSTEM_APPROVED_WITH_RISK"> System Approved With Risk</option>
                <option value="DISCREPANT"> Discrepant </option>
                <option value="RECOMMENDED_TO_LENDER">Recommended To Lender</option>
                <option value="UNDER_PROCESS_WITH_RISK">Under Process (with risk)</option>
              </select>
              <p
                style={{ color: 'red' }}
              >
              </p>
            </div>
          </div>
          <div className="clearfix" style={{ clear: 'both' }}></div>
          <div className="tableCard">
            <table className="customTable" ref={this.tableRef}>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Name</td>
                  <td>Request Date</td>
                  <td>Request Lim</td>
                  <td>Approved Lim</td>
                  <td>Available Lim</td>
                  <td>Status</td>
                  <td>Last Mod.</td>
                </tr>
              </thead>
              <tbody>
                {this.state.eCashRequest.items?.map((item) => (
                  <tr>
                    <td> {item.responseId}</td>
                    <td title={item.name}> {item.name.substring(0, 10)}...</td>
                    <td> {item.requestDate.substring(0, item.requestDate.indexOf("a"))}</td>
                    <td> {item.limitApplied}</td>
                    <td>
                      {' '}
                      {item?.assignedEmandiCashLimit
                        ? item?.assignedEmandiCashLimit
                        : 0}
                    </td>
                    <td>
                      {' '}
                      {item?.availableEmandiCashLimit
                        ? item?.availableEmandiCashLimit
                        : 0}
                    </td>
                    {item.statusOfRequest == 'Recommended_To_Lender' ? (
                      <td>
                        <button className={'statusBtn'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) : item.statusOfRequest == 'System_Approved_With_Risk' ? (
                      <td>
                        <button className={'statusBtn'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) : item.statusOfRequest == 'Under_Process_With_Risk' ? (
                      <td>
                        <button className={'statusBtnunderProcess'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) : item.statusOfRequest == 'Red_Flagged_With_Risk' ? (
                      <td>
                        <button className={'statusBtnunderProcess'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) :
                      item?.statusOfRequest == 'Discrepant' ? (
                        <td>
                          <button className={'statusBtnunderProcess'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : item?.statusOfRequest == 'Incomplete_Proposal' ? (
                        <td>
                          <button className={'statusIncomplete'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : item?.statusOfRequest?.toLowerCase() == 'approved' ? (
                        <td>
                          <button className={'statusBtn'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : item?.statusOfRequest?.toLowerCase() == 'rejected' ? (
                        <td>
                          <button className={'statusIncomplete'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : <td>--</td>}
                    <td>{item.lastModifiedBy}</td>
                    <td>
                      <img src={rightArrow} onClick={(e) => { this.handleProceed(item.id) }} alt="" className="arrowBtn" />
                      {this.state.id > 0 ? <Details /> : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div class="pagination-section">
            <span class="pagination-left">Showing {parseInt(this.state.offset) + 1} to {parseInt(this.state.entries)+parseInt(this.state.offset)} Entries</span>
            <span class="pagination-right">
              <div class="pagination">

                {    
                  this.state.offset==0?<button class="page-link page-num disabled" disabled style={{ backgroundColor: '#E8E7E7' }} >
                  <img src={leftVector} alt="" className="left-vector" />
                </button>:   <button class="page-link page-num" onClick={this.handleLeftVector} style={{ backgroundColor: '#E8E7E7' }} >
                  <img src={leftVector} alt="" className="left-vector" />
                </button>
                }
                <button class="active page-link page-num" onClick={this.handleFirstButton} id="firstPage" >
                  1
                </button>
                {
                  this.state.totalCount > this.state.limit ? <button class="page-link page-num" onClick={this.handleSecondButton}>2</button>:""
                }
               {
                this.state.totalCount > 40? <button class="page-link page-num" onClick={this.handleThirdButton}>3</button>:""
               }
                {
                   this.state.totalCount > this.state.limit ?<button class="page-link page-num" onClick={this.handleRightVector} style={{ backgroundColor: '#E8E7E7' }}>
                   <img src={RightVector} alt="" className="left-vector" />
                 </button>: <button class="page-link page-num" disabled style={{ backgroundColor: '#E8E7E7' }}>
                  <img src={RightVector} alt="" className="left-vector" />
                </button>
                }
               
              </div>
            </span>
          </div>
        </div>
     
      </div>
    );
  }
}

export default index;
