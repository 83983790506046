import React, { Component } from "react";
import List from "./view/List";
import "./styles.scss";
import { history, fireAjaxRequest } from "../../utils/Utility";
import { GET_EMANDI_TV_LIST } from "../../utils/Api";
import { toast } from "react-toastify";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      showTvpopUp: false,
      options: [
        { label: "All time", value: "" },
        { label: "Last 15 days", value: "15days" },
        { label: "Last 1 Month", value: "1Month" },
        { label: "Last 3 Month", value: "3Month" },
        { label: "Last 6 Month", value: "6Month" },
        { label: "Last 1 Year", value: "1Year" },
      ],
    };
  }
  componentDidMount() {
    this.getEmandiTVlist();
  }
  getEmandiTVlist = () => {
    let { formData } = this.state;
    let emandiURL = `${GET_EMANDI_TV_LIST}/get?offset=${0}&limit=${100}`;

    if (formData.time) {
      emandiURL = `${emandiURL}&fromDate=${formData.time}`;
    }

    (async () => {
      // this.setLoading(true);
      try {
        let apiUrl = `${emandiURL}`;
        const response = await fireAjaxRequest(`${apiUrl}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
          },
        });
        // this.setLoading(false);
        if (response) {
          this.setState({ emandiData: response.data });
        } else {
        }
      } catch (err) {
        // this.setLoading(false);
        //console.log("Failed to load trader Details !");
        toast.error(err.message || "Failed to load !");
      }
    })();
  };
  onClickPlay = (path) => {
    this.setState({ showTvpopUp: true, path: path });
  };
  onClickBack = () => {
    history.push("/eAuction");

    
  };

  onClickToWatch = (location) => {
    history.push(`/EmanditvList/${location}`);
  };

  onHidePopup = () => {
    this.setState({ showTvpopUp: false });
  };
  handelInputChange = (e) => {
    let { formData } = this.state;
    formData[e.target.name] = e && e.target && e.target.value;
    this.setState({ formData: formData });
    this.getEmandiTVlist();
  };
  render() {
    return (
      <div>
        <List
          onClickBack={this.onClickBack}
          state={this.state}
          onClickToWatch={this.onClickToWatch}
          onClickPlay={this.onClickPlay}
          onHidePopup={this.onHidePopup}
          formData={this.state.formData}
          handelInputChange={this.handelInputChange}
        />
      </div>
    );
  }
}

export default index;