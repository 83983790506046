import React, { useRef } from "react";
import { toast } from "react-toastify";
import './callUs.css';

// Images & Resources
import otpModalBg from "./Images/otpModalBg.png";
import OrigoFinalLogo from "./Images/FINAL1.svg";
import e from "./Images/e.png";
import WhatsappImage from "./Images/WhatsappImage.png";
import Close from "./Images/Close.png";
import Copy from "./Images/Copy.svg";
import GroupPeople from "./Images/GroupPeople.svg";
import $ from 'jquery';

const CallUsPopup = (props) => {
 
  const _onClickCopyIcon = () => {
    navigator.clipboard.writeText("8338047777").then(() => {
      toast.success("Number successfully copied");
    });

  }

  return (
    <div className="div-header">
      <div className="modal-card">
        <div className="grid-container">
          <div className="grid-item" style={{backgroundImage: `url(${otpModalBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", borderBottomLeftRadius: "8px", padding: "6%"}}>

            <p className="imagePara" style={{marginTop:"50px",marginLeft:"30px"}}><img className="eImage" src={e} style={{width:"15px",marginTop:"10px",marginRight:"5px"}}/><img className="mandiImage" src={OrigoFinalLogo} /></p>
            <div className="join-text" style={{marginLeft:"30px"}}>
              <p style={{fontFamily: "MetropolisRegular", fontWeight: "600",fontSize: "17px",marginTop:"30px", lineHeight: "134%"}}><span>Join the <span style={{color: "#04B23D"}}>revolution.</span></span><br />
                <span> Join the <span style={{color: "#04B23D"}}>Origo</span> Community.</span></p>
            </div>
            <div style={{marginLeft: "-1em", marginBottom: "5em",marginLeft:"20px"}}>
              <img className="grpPeopleImage" src={GroupPeople} />
            </div>
            <p></p>
          </div>
          <div className="grid-item" style={{backgroundColor: "#FFFFFF",  borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}>
            <div className="crossDiv">
              <p className="crossP" style={{textAlign: "right", cursor: "pointer"}}>
                <img classname="crossImg" data-bs-dismiss="modal" src={Close} height="14px" />
              </p>
              <div className="rightInnerDiv" style={{padding: "14%"}}>
                <p className="callUsHere" style={{fontSize:'14px',fontWeight:'600'}}>Call Us here:</p>
                <p className="numberImagePara">
                  <span className="number" style={{color:'#2F2F2F',fontSize:'18px',fontWeight:'500'}}>+91 8338047777</span>&emsp;&emsp;
                  <span style={{cursor: "pointer"}} onClick={_onClickCopyIcon}><img className="copyImg" src={Copy} /></span>

                </p>
                <hr style={{marginRight: "6em",border: '1px solid #ECECEC'}} />
                <div className="orscanFlex" style={{display: "flex", marginTop: "2em"}} >
                  <div><span className="orScan" >Or scan:</span></div>&emsp;
                  <div><span><img className="scannerCallUs" src={WhatsappImage} height="110px" style={{borderTop:'1px solid #979797',borderLeft:'1px solid #979797'}} /></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallUsPopup;