import React, { Component } from 'react';
import List from './view/list'
import { toast } from "react-toastify";
import { REGISTER_USER, VERIFY_USER, SIGNIN_API, OTP_API, FORGET_PASSWORD, FORGET_PASSWORD_SEND_OTP, FORGET_PASSWORD_VEIFY_OTP,EMANDI_CASH_VERIFY } from "../../utils/Api";
import { history,fireAjaxRequest } from '../../utils/Utility';
import axios from "axios";
import './style.scss';
import "./mediaOnlyStyle.scss";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            currentpage: "homepage",
            OTPvalue: ["", "", "", ""],
            disableOTP: false,
            resendOTPTime: 90,
            showAuthenticatewith: false,
            showloginbutton: false,
            showsendotpbutton: false,
            showcontinuebutton: true,
            showverifybutton: false,
            showotpfields: false,
            isotpoptions: false,
            showForgetPasswordVerifyBtn: false,
            otp: "",
            showEmandiCashPopup:false,
            // Otpnumbers:true,
            isButtonDisabled: false
        }
    }


    
    CheckemandiCashStatus=(data)=>{
        (async () => {
            try {
                const resp = await fireAjaxRequest(`${EMANDI_CASH_VERIFY}/${data.data.user.id}`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${data.data.token}`,
                        "Content-Type": "application/json"
                    }
                });
            } catch (err) {
                // toast.error("Failed to load User Account !");
                // if (err.message)
                this.setState({status:err.message})
                // if (err.message !== "data not found for this user") {
                //     toast.error(err.message || "Failed to load User Account !");
                // }
            }
        })();
    }
    onSendOTP = () => {
        console.log("kranthi")
    
        let { formData } = this.state;
        let loginType;
        loginType = "MOBILE";
        // if (formData.sentotpto === 'phonenumber') {
        //     loginType = "MOBILE";
        // } else if (formData.sentotpto === 'emailid') {
        //     loginType = "EMAIL";
        // }

        //Validation
        // if (!formData.sentotpto) {
        //     toast.error("Please enter where do you want the OTP to be sent")
        // } 
        // else {
            let param = {
                "phoneNo": formData.number,
                "loginType": loginType
            }

            axios.post(SIGNIN_API, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    console.log(response.data)
                    this.resendTimeLimit(true)
                    // this.setState({ OTPResponce: response.data,showAuthenticatewith: true,showloginbutton: false, showcontinuebutton: false, showsendotpbutton: true, isotpoptions: true  })
                    this.setState({OTPResponce: response.data,                      
                       
                    })
                  
                    // this.setState({ 
                    //      showotpfields: true,
                    //      isotpoptions: false,
                    //      showverifybutton: true, 
                    //     showsendotpbutton: false, showloginbutton:false,})
                    // this.setState({ OTPResponce: response.data, showotpfields: true, isotpoptions: true, showverifybutton: true, showsendotpbutton: false, showloginbutton:false})
                }
            }).catch((error) => {
                console.log(error.response.data.message, 'error');
                toast.error(error.response.data.message);
            })
           
        }
        
    // }
	onKeyDown = (e) => {
		if (e.key === "Tab") {
		  e.preventDefault();
		  console.log("Tab");
		}
	  };
    onClickVerifyContinue = () => {
        let { formData, OTPResponce, OTPvalue } = this.state;
        let param = {
            "loginKey": OTPResponce.loginKey,
            "otp": OTPvalue.toString().replace(/,/g, ""),
            "authenticationType": "OTP",
            "phoneNo": formData.number,
        }
        axios.post(OTP_API, param, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                console.log(response);
                let resp = response.data;
                if (resp.user.accountType === "ADMIN" || resp.user.accountType === "SUPER_ADMIN") {//IF THE USER IS ADMIN
                    sessionStorage.setItem("isAdmin", true);
                    sessionStorage.setItem("userInfo", JSON.stringify(resp));
                } else if (resp.user.accountType === "RISK_ADMIN") { //if user is risk admin
                    sessionStorage.setItem("isRiskAdmin", true);
                    setTimeout(() => {
                        history.push('./EmandiCashLimit')
                    }, 100)
                    sessionStorage.setItem("userInfo", JSON.stringify(resp));
                } else {//IF THE USER IS TRADER
                    sessionStorage.setItem("isAdmin", false);
                    sessionStorage.setItem("isRiskAdmin", false);
                    sessionStorage.setItem("userInfo", JSON.stringify(resp));
                }
                if (sessionStorage.getItem("commodityHistoryFlag")) {
                    // sessionStorage.removeItem("commodityHistoryFlag")
                    history.push(`/CommodityResearch`);
                } else {
                    let isEmandiCashpage=sessionStorage.getItem("isEmandiCash")
                    if(isEmandiCashpage){
                        (async () => {
                            try {
                                const resp = await fireAjaxRequest(`${EMANDI_CASH_VERIFY}/${response.data.user.id}`, {
                                    method: 'GET',
                                    headers: {
                                        "Authorization": `Bearer ${response.data.token}`,
                                        "Content-Type": "application/json"
                                    }
                                });
                                if(resp){
                                    history.push('./EmandiCash')
                                }
                            } catch (err) {
                                let message=err.message.split(",")
                                this.setState({status:err.message,showEmandiCashPopup:true})
                                 history.push(`/homePage`);
                                toast.error(message[0])
                            }
                        })();
                        // if(this.state.status){
                        //     this.setState({showEmandiCashPopup:true})
                        // }
                        // else{
                        //     history.push('./EmandiCash')
                        // }
                
                    }
                    else{
                        history.push(`/homePage`);

                    }
                }
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

//   Enter
    handleKeypress = (e) => {
        console.log(e.key,"handleKeypress")
        if(e.key === 'Enter'){
        this.onClickLoginContinue();
        
      }
    };
    // handleonForgetPasswordClick = (e) => {
    //     console.log(e.key,"handle")
    //     if(e.key === 'Enter'){
    //     this.onForgetPasswordClick();
    //   }
    // };
    // Continue
    onClickLoginContinue = () => {
        let { formData } = this.state;
        let errorMsg;

        if (formData.number) {
            if (formData.number.length !== 10) {
                errorMsg = "Please check the mobile number entered";
            }
        } else {
            errorMsg = "Please enter the mobile number";
        }

        let param = {
            "phoneNo": formData.number
        }

        if (errorMsg) {
            toast.error(errorMsg);
        } else {
            axios.post(VERIFY_USER, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    this.setState({ showAuthenticatewith: true, showloginbutton: true, showcontinuebutton: false })
                }
            }).catch((error) => {
                toast.error("You are not registered with us... Kindly register first!");
            })
        }
    }
    // Loginpage
    onclickLoginpage = () => {
        let { formData } = this.state;
        clearInterval(this.timer);
        this.setState({
            formData: {},
            currentpage: "homepage",
            OTPvalue: ["", "", "", ""],
            disableOTP: false,
            resendOTPTime: 90,
            showAuthenticatewith: false,
            showloginbutton: false,
            showsendotpbutton: false,
            showcontinuebutton: true,
            showverifybutton: false,
            showotpfields: false,
            isotpoptions: false,
            showForgetPasswordVerifyBtn: false
        });

    }

    onClickRegister = () => {
        clearInterval(this.timer);
        this.setState({
            currentpage: "registerpage",
            OTPvalue: ["", "", "", ""],
            disableOTP: false,
            resendOTPTime: 90,
            showAuthenticatewith: false,
            showloginbutton: false,
            showsendotpbutton: false,
            showcontinuebutton: true,
            showverifybutton: false,
            showotpfields: false,
            isotpoptions: false,
            showForgetPasswordVerifyBtn: false,
            formData: {},
        });
    }

handelInputChange =  (e) => {
        let formData = this.state.formData;
        let alphabetOnly = ["fullname"];
        if (e.target.name === "authwith") {
            formData[e.target.name] = e.target.value;
            if (e.target.value == "otp") {
                   if(!this.state.isButtonDisabled)
                   {
                    //    alert(this.state.isButtonDisabled);
                     this.setState({showAuthenticatewith: true,showloginbutton: false, 
                                showcontinuebutton: false, showsendotpbutton: false, 
                                isotpoptions:true ,showotpfields:true,showverifybutton:true,isButtonDisabled:true})

                            let { formData } = this.state;
                            let loginType;
                            loginType = "MOBILE";

                              //Validation
                                let param = {
                                    "phoneNo": formData.number,
                                    "loginType": loginType,
                                    
                                }
               
                                axios.post(SIGNIN_API, param, {
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                }).then((response) => {
                                    console.log(response);
                                    if (response.status === 200) {
                                        console.log(response.data)
                                        this.resendTimeLimit(true)
                                        this.setState({OTPResponce: response.data,showAuthenticatewith: true,showloginbutton: false, 
                                            showcontinuebutton: false, showsendotpbutton: false, 
                                            isotpoptions:true ,showotpfields:true,showverifybutton:true,isButtonDisabled:true})
            
                                        // this.setState({ OTPResponce: response.data, showotpfields: true, isotpoptions: true, showverifybutton: true, showsendotpbutton: false, 
                                        //     isButtonDisabled:true  })
                                    }
                                }).catch((error) => {
                                    console.log(error.response.data.message, 'error');
                                    toast.error(error.response.data.message);
                                })
                               
                        }
                        // setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);
                    }
            if (e.target.value == "password") {
                this.setState({ showloginbutton: true ,showverifybutton:false})
            }
        }
    
        if (e.target.name == 'referralnumber' || e.target.name == 'number') {
            formData[e.target.name] = e.target.value;
            formData[e.target.name] = formData[e.target.name].replace(".", "");
            this.setState({ formData: formData });

        } else if (e.target.name == 'password' || e.target.name == 'confirmpassword' || e.target.name == 'newPassword' || e.target.name == 'reNewPassword') {
            formData[e.target.name] = e.target.value;
            formData[e.target.name] = formData[e.target.name].replace(">", "");
            formData[e.target.name] = formData[e.target.name].replace("<", "");

            this.setState({ formData: formData });

        }
        else {
            alphabetOnly.map((element) => {
                if (e.target.name === element) {
                    e.target.value = e.target.value.replace(/[^a-z A-Z]+/g, "");
                }
            })
            formData[e.target.name] = e.target.value;
            this.setState({ formData: formData });
        }

    }

    onKeyUp = (e, index,formId=null) => {
        if (e.keyCode === 8 && index > 0) {
            let selected = document.getElementsByClassName("OTP-class");
            selected[index - 1].focus();
        }
        if (e.keyCode === 13) {
            console.log("in enter key");

            let { state: { otp } } = this;
            otp = "" + otp
            if (+otp.length === 4) {
                console.log("now pls submit the form when user press the enter button");
                // this.onForgetPasswordVerifyClick();
                if (formId && formId === "login") {
                    this.onClickVerifyContinue();
                } else {
            this.onForgetPasswordVerifyClick();
                    
                } 
            }
        }
    }
    onEnterPressBtnSubmitForm = (e, fieldName="") => {
        if (fieldName && e.keyCode === 13) {
            switch (fieldName) {
                case 'password':
                    this.onClickPasswordLogin();
                    break;

                case 'register':
                    this.onClickContinue();
                    break;

                    case 'forgotpassword':
                        this.onSaveNewPasswordClick();

                default:
                    break;
            }
        }
    }

    onChangeOTP = (e, index) => {
        let { OTPvalue } = this.state;

        OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        let selected = document.getElementsByClassName("OTP-class");
        if (index <= 2 && e.target.value) {
            selected[index + 1].focus();
        } else {
            OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        }

        this.handleOTPChange(OTPvalue, e, index)
    }

    handleOTPChange = (value, e, index) => {
        let { OTPvalue } = this.state;

        OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        let selected = document.getElementsByClassName("OTP-class");
        if (index <= 2 && e.target.value) {
            selected[index + 1].focus();
        } else {
            OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        }
        this.setState({ OTPvalue: value });

        let otp = value
        otp = otp.toString();
        otp = otp.replace(/,/g, "");
        this.setState({ otp: otp });
    }

    resendTimeLimit = (startTimer = false) => {
        if (startTimer) {
            this.timer = setInterval(() => {
                if (this.state.resendOTPTime === 1) {
                    this.setState({ OTPvalue: ["", "", "", ""], disableOTP: true });
                    let selected = document.getElementsByClassName("OTP-class");
                    selected && selected[0] && selected[0].focus();
                    clearInterval(this.timer);
                }

                this.setState(prevState => ({
                    resendOTPTime: prevState.resendOTPTime - 1
                }))
            }, 1000);
        }
    }

    onClickverify = () => {
        let { formData, OTPResponce, OTPvalue } = this.state;
        let param = {
            "loginKey": OTPResponce.loginKey,
            "otp": OTPvalue.toString().replace(/,/g, ""),
            "authenticationType": "OTP",
            "phoneNo": formData.number,
        }
        axios.post(OTP_API, param, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                this.CheckemandiCashStatus(response)
                console.log(response);
                let resp = response.data;
                if (resp.user.accountType === "ADMIN" || resp.user.accountType === "SUPER_ADMIN") {//IF THE USER IS ADMIN
                    sessionStorage.setItem("isAdmin", true);
                    history.push(`/auction`);
                    sessionStorage.setItem("userInfo", JSON.stringify(resp));
                } else if (resp.user.accountType === "RISK_ADMIN") { //if user is risk admin
                    sessionStorage.setItem("isRiskAdmin", true);
                    setTimeout(() => {
                        history.push('./EmandiCashLimit')
                    }, 100)
                    sessionStorage.setItem("userInfo", JSON.stringify(resp));
                } else {//IF THE USER IS TRADER
                    sessionStorage.setItem("isAdmin", false);
                    sessionStorage.setItem("isRiskAdmin", false);
                    sessionStorage.setItem("userInfo", JSON.stringify(resp));

                }
                this.setState({ currentpage: "accountverifiedpage" })
                setTimeout(() => {
                    history.push(`/editProfile/123456`);
                }, 3000)
            }
        }).catch((error) => {
            console.log(error.response.data.message, 'error');
            toast.error(error.response.data.message);
        })
    }

    onClickPasswordLogin = () => {
        console.log("apple");

        let { OTPResponce, formData } = this.state;
        if (!formData.authwith) {
            toast.error("Please select Authenticate option")
        }
        else if (!formData.password) {
            toast.error("Please Enter Password")
        }
        else {
            let param = {
                "loginKey": "",
                "otp": "",
                "password": formData.password,
                "authenticationType": "PASSWORD",
                "phoneNo": formData.number,
            }

            axios.post(OTP_API, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    let resp = response.data;
                    if (resp.user.accountType === "ADMIN" || resp.user.accountType === "SUPER_ADMIN") {//IF THE USER IS ADMIN
                        sessionStorage.setItem("isAdmin", true);
                        sessionStorage.setItem("userInfo", JSON.stringify(resp));
                    } else if (resp.user.accountType === "RISK_ADMIN") { //if user is risk admin
                        sessionStorage.setItem("isRiskAdmin", true);
                        setTimeout(() => {
                            history.push('./EmandiCashLimit')
                        }, 100)
                        sessionStorage.setItem("userInfo", JSON.stringify(resp));
                    } else {//IF THE USER IS TRADER
                        sessionStorage.setItem("isAdmin", false);
                        sessionStorage.setItem("isRiskAdmin", false);
                        sessionStorage.setItem("userInfo", JSON.stringify(resp));

                    }
                    if (sessionStorage.getItem("commodityHistoryFlag")) {
                        // sessionStorage.removeItem("commodityHistoryFlag")
                        history.push(`/CommodityResearch`);
                    } else {
                        let isEmandiCashpage=sessionStorage.getItem("isEmandiCash")
                        if(isEmandiCashpage){
                            (async () => {
                                try {
                                    const resp = await fireAjaxRequest(`${EMANDI_CASH_VERIFY}/${response.data.user.id}`, {
                                        method: 'GET',
                                        headers: {
                                            "Authorization": `Bearer ${response.data.token}`,
                                            "Content-Type": "application/json"
                                        }
                                    });
                                    if(resp){
                                        history.push('./EmandiCash')
                                    }
                                } catch (err) {
                                    let message=err.message.split(",")
                                    this.setState({status:err.message,showEmandiCashPopup:true})
                                     history.push(`/homePage`);
                                    toast.error(message[0])
                                }
                            })();
                    
                        }
                        else{
                            history.push(`/homePage`);

                        }
                    }
                }
            }).catch((error) => {
                console.log(error.response.data.message, 'error');
                toast.error(error.response.data.message);
            })
        }

    }

    resendOTP = () => {
     
        let { formData } = this.state;
        let loginType;
        // if (formData.sentotpto === 'phonenumber') {
        //     loginType = "MOBILE";
        // } else if (formData.sentotpto === 'emailid') {
        //     loginType = "EMAIL";
        // }
        //  else {
        // }
        loginType = "MOBILE";

        let param = {
            "phoneNo": formData.number,
            "loginType": loginType
        }
console.log(this.setState,"kranthi")
        axios.post(SIGNIN_API, param, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            console.log(response);
            if (response.status === 200) {
                console.log(response.data)
                this.resendTimeLimit(true)
                this.setState({ OTPResponce: response.data, showotpfields: true, isotpoptions: true, 
                    showverifybutton: true, showsendotpbutton: false,isButtonDisabled:true})
            }
        }).catch((error) => {
            console.log(error.response.data.message, 'error');
            toast.error(error.response.data.message);
        })
        this.setState({ resendOTPTime: 90, OTPvalue: ["", "", "", ""], disableOTP: false })
    }

    onForgetPasswordClick = () => {
 
        let { formData } = this.state;
        formData.authwith = "";
        let loginType = "MOBILE";

        let param = {
            "phoneNo": formData.number,
            "loginType": loginType
        }

        axios.get(`${FORGET_PASSWORD_SEND_OTP}?phoneNo=${formData.number}`, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            console.log(response);
            if (response.status === 200) {
                console.log(response.data)
                this.resendTimeLimit(true)
                this.setState({ OTPResponce: response.data, formData: formData, showForgetPasswordVerifyBtn: true, showloginbutton: false, showAuthenticatewith: false, showotpfields: true, disableOTP: false, resendOTPTime: 90, OTPvalue: ["", "", "", ""] });
            }
        }).catch((error) => {
            console.log(error.response.data.message, 'error');
            toast.error(error.response.data.message);
        })
   
    }

    onForgetPasswordVerifyClick = () => {
        let { formData, OTPResponce, OTPvalue } = this.state;
        let param = {
            "userId": OTPResponce.userId,
            "otp": OTPvalue.toString().replace(/,/g, ""),
        }

        axios.post(FORGET_PASSWORD_VEIFY_OTP, param, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            console.log(response, 'response')
            if (response.status === 200) {
                if (response.data.statusCode === 200) {
                    this.setState({ currentpage: "forgetPassword" });
                } else {
                    toast.error(response.data.message);
                }
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

    onSaveNewPasswordClick = () => {
        let { formData, OTPvalue } = this.state;
        let errorMsg;
        let paswdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~])/;

        if (!formData.newPassword) {
            toast.error("Please Enter new password")
        }
        else if (!formData.reNewPassword) {
            toast.error("Please Re-enter the new password ")
        } else if (formData.newPassword.length < 8) {
            toast.error("Password must be atleast 8 characters");
        }
        else if (!formData.newPassword.match(paswdRegex)) {
            toast.error("Password must contain uppercase, lowercase characters, at least one number and one special character");
        } else {
            if (formData.newPassword !== formData.reNewPassword) {
                errorMsg = "Password does not match";
            }

            if (errorMsg) {
                toast.error(errorMsg);
            } else {
                let param = {
                    "phoneNo": formData.number,
                    "otp": OTPvalue.toString().replace(/,/g, ""),
                    "newPassword": formData.newPassword
                }

                axios.post(FORGET_PASSWORD, param, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then((response) => {
                    if (response.status === 200) {
                        this.onclickLoginpage();
                        toast.success("Password has been updated successfully")
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
            }

        }


    }


    resendForgetPasswordOTP = () => {
        
        this.onForgetPasswordClick();
    }

    onClickContinue = () => {
        let { formData } = this.state
        var fullname1=formData.fullname.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          );
        console.log(fullname1,"fullname1");
        var validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        // let paswdRegex=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        let paswdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~])/;

        let mandatoryfields = [{ name: "confirmpassword", label: "confirm password" }, { name: "password", label: "password" }, { name: "number", label: "10 digits mobile number" }, { name: "fullname", label: "Full Name" }]
        let isError, errorMsg;
        if (!formData.fullname && !formData.number && !formData.referralnumber && !formData.password && !formData.confirmpassword) {
            errorMsg = " Please enter details in all mandatory details";
            isError = true;
        } else {
            mandatoryfields.map((data) => {
                if (!formData[data.name]) {
                    errorMsg = `Please enter ${data.label}`;
                    isError = true;

                }
            })
        }
        if (formData.fullname && formData.number && formData.password && formData.confirmpassword) {
            if (formData.password.length < 8) {
                errorMsg = "Password must be atleast 8 characters";
                isError = true;
            } else if (!formData.password.match(paswdRegex)) {
                errorMsg = "Password must contain uppercase, lowercase characters, at least one number and one special character";
                isError = true;
            } else if (formData.password !== formData.confirmpassword) {
                errorMsg = "Password does not match";
                isError = true;
            } else if (formData.fullname.length > 50) {
                errorMsg = "Full Name cannot be more than 50 characters";
                isError = true;
            } else if (formData.number.length < 10) {
                errorMsg = "Please enter 10 digits mobile number";
                isError = true;
            } else if (formData.referralnumber && formData.referralnumber.length < 10) {
                errorMsg = "Please enter 10 digits referral mobile number";
                isError = true;
            } else if (formData.email) {
                if (!formData.email.match(validRegex)) {
                    errorMsg = "Please enter valid email address";
                    isError = true;
                }
            }
            else {
                isError = false;
                // this.setState({currentpage:"otppage"});
                // this.resendTimeLimit(true);
            }
        }

        if (isError) {
            toast.error(errorMsg);
        } else {
            let param = {
                "name": formData.fullname,
                "mobileNumber": formData.number,
                "referralMobileNumber": formData.referralnumber,
                "email": formData.email,
                "password": formData.password
            };

            axios.post(REGISTER_USER, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                //send OTP
                if (response.status === 200) {
                    let param = {
                        "phoneNo": formData.number,
                        "loginType": "MOBILE"
                    }

                    axios.post(SIGNIN_API, param, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            console.log(response.data)
                            this.resendTimeLimit(true);
                            this.setState({ OTPResponce: response.data, showotpfields: true, isotpoptions: false, showverifybutton: true, showsendotpbutton: false, currentpage: "otppage" })
                        }
                    }).catch((error) => {
                        console.log(error.response.data.message, 'error');
                        toast.error(error.response.data.message);
                    })
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
        }
    }

    onTermsClick = () => {
        window.open("#/terms");
    }
    onClickBack = () => {
        let { showloginbutton,currentpage,showcontinuebutton, showForgetPasswordVerifyBtn} = this.state;
        if(showcontinuebutton){
        history.push("/eAuction")
        }
        else if (showloginbutton) {
            this.setState({ showAuthenticatewith: false, showloginbutton: false, showcontinuebutton: true,showverifybutton:false,showForgetPasswordVerifyBtn:false })
        }
        else if(showForgetPasswordVerifyBtn){
            this.setState({ showAuthenticatewith: true,showForgetPasswordVerifyBtn:false, showsendotpbutton:false,isotpoptions:false, showloginbutton: true, showcontinuebutton: false,showverifybutton:false,showotpfields:false, })
        }
        else{
            this.setState({ showAuthenticatewith: false,showForgetPasswordVerifyBtn:false, showsendotpbutton:false,isotpoptions:false, showloginbutton: false, showcontinuebutton: true,showverifybutton:false,showotpfields:false, })
        }
    }

    render() {
        
        return (
            <List
                handelInputChange={this.handelInputChange}
                formData={this.state.formData}
                onClickContinue={this.onClickContinue}
                currentpage={this.state.currentpage}
                OTPvalue={this.state.OTPvalue}
                disableOTP={this.state.disableOTP}
                onKeyUp={this.onKeyUp}
                onChangeOTP={this.onChangeOTP}
                handleOTPChange={this.handleOTPChange}
                resendTimeLimit={this.resendTimeLimit}
                resendOTPTime={this.state.resendOTPTime}
                onClickverify={this.onClickverify}
                onClickRegister={this.onClickRegister}
                onclickLoginpage={this.onclickLoginpage}
                onClickLoginContinue={this.onClickLoginContinue}
                showAuthenticatewith={this.state.showAuthenticatewith}
                showloginbutton={this.state.showloginbutton}
                showsendotpbutton={this.state.showsendotpbutton}
                showcontinuebutton={this.state.showcontinuebutton}
                showverifybutton={this.state.showverifybutton}
                showotpfields={this.state.showotpfields}
                onSendOTP={this.onSendOTP}
                isotpoptions={this.state.isotpoptions}
                onClickVerifyContinue={this.onClickVerifyContinue}
                onClickPasswordLogin={this.onClickPasswordLogin}
                resendOTP={this.resendOTP}
                onForgetPasswordClick={this.onForgetPasswordClick}
                state={this.state}
                onForgetPasswordVerifyClick={this.onForgetPasswordVerifyClick}
                onSaveNewPasswordClick={this.onSaveNewPasswordClick}
                resendForgetPasswordOTP={this.resendForgetPasswordOTP}
                onTermsClick={this.onTermsClick}
                onClickBack={this.onClickBack}
                handleKeypress={this.handleKeypress}
                onKeyDown={this.onKeyDown}
                handleonForgetPasswordClick={this.handleonForgetPasswordClick}
                onEnterPressBtnSubmitForm={this.onEnterPressBtnSubmitForm}
                
                
            />
        );
    }
}

export default Register;