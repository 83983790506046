import { useEffect, useState } from 'react';
import './updateLender.css'
import { fireAjaxRequest, history } from '../../../utils/Utility';
import { GET_LENDER_BY_ID, UPDATE_LENDER } from '../../../utils/Api';
import { toast } from 'react-toastify';

const UpdateLender = () => {

    const [currId, setId] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1, window.location.href.length))
    const [updateData, setUpdateData] = useState({})
    const [update, setUpdate] = useState(false)
    const [mouDate, setMouDate] = useState('')
    const [mouRenewal, setMouRenewal] = useState('')

    useEffect(() => {
        setId(window.location.href.substring(window.location.href.lastIndexOf('/') + 1, window.location.href.length))
    }, [])

    useEffect(async () => {

        try {
            const userInfo = sessionStorage.getItem('userInfo');
            const userInfoObj = JSON.parse(userInfo);
            const resp = await fireAjaxRequest(`${GET_LENDER_BY_ID}/${currId}`
                ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (resp) {
                console.log("result33-------", resp)
                let tempObj = resp.lenderData
                let mouDt = formatDateToYearFirst(tempObj.mouDate)
                let mouDtRenewal = formatDateToYearFirst(tempObj.mouDateOnRenewal ||'')
                setMouRenewal(mouDtRenewal)
                setMouDate(mouDt)
                let obj = {
                    id: tempObj.id,
                    name: tempObj.name,
                    email: tempObj.email,
                    // mouDate: "23/09/2022",
                    mouDate: tempObj.mouDate,
                    totalFunds: parseFloat(tempObj.totalFunds.replace(/,/g, "")),
                    availableFunds: parseFloat(tempObj.availableFunds.replace(/,/g, "")),
                    interestRate: tempObj.interestRate,
                    mouDateOnRenewal: tempObj.mouDateOnRenewal,
                    processingFees: tempObj.processingFees,
                    invoiceFinances: tempObj.invoiceFinances,
                }
                setUpdateData((obj))
                return
            } else {
                // toast.error("Failed to load Commodity List !");
            }
        } catch (err) {
            setUpdateData({});
        }
    }, [])


    const handleMouDate = (e) => {
        var dt = formatDate(e.target.value)
        setUpdateData(prevData => ({
            ...prevData,
            mouDate: dt
        }));
    }
  

    const handleMouDateUpdate = (e) => {
        var dt = formatDate(e.target.value)
        setMouDate(dt)

    }

    const handleMouRenewal = (e) => {   
        var dt = formatDate(e.target.value)

        setUpdateData(prevData => ({
            ...prevData,
            mouDateOnRenewal: dt
        }));
    }


    function formatDate(date) {

        var day, month, year;
        date = date.split("-")
        year = date[0]
        month = date[1]
        day = date[2]
        return day + '/' + month + '/' + year;
    }
    function formatDateToYearFirst(date) {
    
        var day, month, year;
        date = date.split("/")
        year = date[2]
        month = date[1]
        day = date[0]
        return year + '-' + month + '-' + day;
    }

    const handleInputChange = (e) => {
        var { name, value } = e.target;

        if(name==='name' ||  name==='email'){
            setUpdateData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
       else {
        var v = parseFloat(value);
        if (isNaN(v)) {
            value = '';
        } else {
            if (value.includes(".") && value.indexOf(".") < value.length - 4) {
                value = v.toFixed(3);
            }
        }

        if (name === 'totalFunds' || name === 'availableFunds') {
            if (Number(value) < 0) {
                alert('Negative Values not allowed')
                return;
            }

            if (name === 'availableFunds') {
                const totalFunds = parseFloat(updateData.totalFunds);
                const availableFunds = parseFloat(value);

                // if(availableFunds>0 && availableFunds<1){
                //     alert('Value should be greater than or equal to 1')
                //     return
                // }
                if (availableFunds > totalFunds) {
                    alert('Available value should be less than than total funds')
                    return;
                }
            }
               if (name === 'totalFunds') {
                const availableFunds = parseFloat(updateData.availableFunds);
                const totalFunds = parseFloat(value);

                // if(totalFunds>0 && totalFunds<1){
                //     alert('Value should be greater than or equal to 1')
                //     return
                // }

                if (availableFunds > totalFunds) {
                    alert('Available value should be less than than total funds')
                    return;
                }
            }
        }

        if (name === 'interestRate' || name === 'invoiceFinances' || name === 'processingFees') {
            if (Number(value) < 0) {
                alert('Negative value not allowed')
                return;
            }

            if(name==='interestRate' && Number(value)>100){
                alert('Interest Rate should be less than or equal to 100')
                return
            }

            if(name==='invoiceFinances' && Number(value)>100){
                alert('Invoice Finances should be less than or equal to 100')
                return
            }

            if(name==='processingFees' && Number(value)>100){
                alert('Processing Fees should be less than or equal to 100')
                return
            }
        }

        setUpdateData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }
    };

    const handleCalculate = () => {
        return parseFloat(parseFloat(updateData.interestRate) + parseFloat(updateData.processingFees)) / parseFloat(updateData.invoiceFinances);
    }

    const handleSubmit = async () => {

        if(updateData?.totalFunds.length===0){
            alert('Please fill all the fields')
            return
        }

        if(updateData?.availableFunds.length===0){
            alert('Please fill all the fields')
            return
        }

        if(updateData?.invoiceFinances.length===0){
            alert('Please fill all the fields')
            return
        }

        if(updateData?.processingFees.length===0){
            alert('Please fill all the fields')
            return
        }

        if(updateData?.interestRate.length===0){
            alert('Please fill all the fields')
            return
        }

        if(!updateData.name || !updateData.email || !mouDate ||  !mouRenewal){
            alert('Please fill all the fields')
            return
        }

        if(!/\S+@\S+\.\S+/.test(updateData?.email)){
            alert('Please enter valid email')
            return
        }

        try {
            const result = await fireAjaxRequest(UPDATE_LENDER, {
                method: 'PUT',
                body: JSON.stringify(updateData),
                headers: {
                    "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    "Content-Type": "application/json"
                },
            });
            if (result) {
                toast.success('Lender Update Successfully ');
                return
            }

        } catch (err) {
            console.log('err92', err)
        }

        console.log("userData47", updateData)
        return
    }


    const handleBack = () => {
        history.push('/AddLenderPage')
        return
    }

    return (
        <>
            <div className='back-ctn'>
                <button
                    style={{ marginTop: '20px' }}
                    onClick={() => handleBack()}
                    className='submitLender'>
                    Back
                </button>
            </div>
            <div className='update-main'>
                <div className='update-ctn'>
                    <div className='update-multi-ctn'>
                        <label className='label-edit'>
                            Name
                            <input
                                className='input-container'
                                type="text"
                                name="name"
                                value={updateData.name || ''}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label className='label-edit'>
                            Email
                            <input
                                className='input-container'
                                type="email"
                                name="email"
                                value={updateData.email || ''}
                                onChange={handleInputChange}
                                required={true}
                            />
                        </label>
                    </div>
                    <div className='update-multi-ctn'>
                        <label className='label-edit'>
                            MoU Date
                            <input
                                className='input-container'
                                type="date"
                                name="mouDate"
                                defaultValue={(mouDate) || ''}
                                disabled
                                onChange={handleMouDate}
                            />
                        </label>
                        <label className='label-edit'>
                            Total Fund
                            <input
                                className='input-container'
                                type="number"
                                name="totalFunds"
                                value={(updateData.totalFunds)}
                                onChange={handleInputChange}
                            />
                        </label>
                    </div>
                    <div className='update-multi-ctn'>
                        <label className='label-edit'>
                            Available Fund
                            <input
                                className='input-container'
                                type="number"
                                name="availableFunds"
                                value={(updateData.availableFunds)}
                                onChange={handleInputChange}
                            />
                        </label>

                        <label className='label-edit'>
                            Interest Rate
                            <input
                                className='input-container'
                                type="number"
                                name="interestRate"
                                value={updateData.interestRate}
                                onChange={handleInputChange}
                            />
                        </label>
                    </div>
                    <div className='update-multi-ctn'>
                        <label className='label-edit'>
                            Processing Fee
                            <input
                                className='input-container'
                                type="number"
                                name="processingFees"
                                value={(updateData.processingFees)}
                                onChange={handleInputChange}
                            />
                        </label>

                        <label className='label-edit'>
                            Invoice Finance
                            <input
                                className='input-container'
                                type="number"
                                name="invoiceFinances"
                                value={(updateData.invoiceFinances)}
                                onChange={handleInputChange}
                            />
                        </label>
                    </div>
                    <div className='update-multi-ctn'>
                        <label for="invoiceFinance" className="label-edit">
                            IRR
                            <input
                                type='number'
                                className='input-container'
                                value={handleCalculate().toFixed(2) || 0}
                                disabled
                            />
                        </label>
                        <label className='label-edit'>
                            MoU Date(On Renewal)
                            <input type="date" defaultValue={(mouRenewal) || ''} className="input-container" placeholder='MoU Date(On Renewal)' id="renewalDate" name='renewalDate'  onKeyPress={(e) => e.preventDefault()}  onKeyDown={(e) => e.preventDefault()} onChange={handleMouRenewal} required />

                        </label>

                        <button
                            style={{ marginTop: '24px' }}
                            onClick={() => handleSubmit()}
                            className='submitLender'>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateLender;
