import React from 'react';
import './cards.css';
// import commodity from "../../../assets/images/productsImage1.svg";
import productsImage1 from "../../../assets/images/productsImage1.svg";
import productsImage2 from "../../../assets/images/productsImage2.svg";
import productsImage3 from "../../../assets/images/productsImage3.svg";
import productsImage4 from "../../../assets/images/productsImage4.svg";
import productsImage5 from "../../../assets/images/productsImage5.svg";
import productsImage6 from "../../../assets/images/productsImage6.svg";
import productsImage7 from "../../../assets/images/productsImage7.svg";
import productsImage8 from "../../../assets/images/productsImage8.svg";
import { useHistory } from 'react-router-dom';

function Cards() {
    const history = useHistory();
    const emandiCashPage = () => {
        // history.push("/BuyerUnderwriting/newuser");
        // history.push("/EmandiCash");
        history.push("/ecashComingSoon");
    }
    const eResearchPage = () => {
        // history.push("/BuyerUnderwriting/newuser");
        history.push("/eResearch");
    }
    const eHedgePage = () => {
        // history.push("/BuyerUnderwriting/newuser");
        history.push("/eHedge");
    }
    const eSpotPage = () => {
        // history.push("/BuyerUnderwriting/newuser");
        history.push("/eSpot");
    }

    return (
        <div>
            <div class="row1-container container-css">
                <div class="box " onClick={() => emandiCashPage()}>
                    <div className='smallBox'>

                        <div class="small-box  yellow" style={{borderRadius:"0px 5px 5px 0px",marginLeft:"-20px"}}>
                        </div>
                        <p className='mx-3 mt-1 boxText instantCash'>Instant Cash</p>
                    </div>
                    <h4 style={{fontWeight:"600"}} className ="h4-css"><span  className="eIcon" style={{color:"#04B23D",fontSize:"22pt",marginRight:"3px",display:"inline-block"}}>e</span> Cash</h4>
                    <p className='text' style={{fontSize: '11px'}}>Get Instant for your Commodity Supplies</p>
                    <img src={productsImage1} alt="" className='image' />
                    <img src={productsImage2} alt="" className='image' />
                </div>
                <div class="box" onClick={() =>eResearchPage()}>
                    <div className='smallBox'>

                        <div class="small-box  yellow" style={{borderRadius:"0px 5px 5px 0px",marginLeft:"-20px"}}>
                        </div>
                        <p className='mx-3 mt-1 boxText instantCash' >Commodity Trends</p>
                    </div>
                    <h2 style={{fontWeight:"600"}} className ="h4-css" ><span  className="eIcon" style={{color:"#04B23D",fontSize:"22pt",marginRight:"3px",display:"inline-block"}}>e</span> Research</h2>
                    <p className='text' style={{fontSize: '11px'}}>Get insights to Market Trends and Research Reports</p>
                    <img src={productsImage3} alt="" className='image' />
                    <img src={productsImage4} alt="" className='image' />
                </div>

                <div class="box" onClick={() => eHedgePage()}>
                    <div className='smallBox'>

                        <div class="small-box  yellow" style={{borderRadius:"0px 5px 5px 0px",marginLeft:"-20px"}}>
                        </div>
                        <p className='mx-3 mt-1 boxText instantCash' >Hedging</p>
                    </div>
                    <h2 style={{fontWeight:"600"}} className ="h4-css" ><span  className="eIcon" style={{color:"#04B23D",fontSize:"22pt",marginRight:"3px",display:"inline-block"}}>e</span> Hedge
                        </h2>
                    <p className='text' style={{fontSize: '11px'}}>Get Agri Raw Materials at
                        Fixed Price</p>
                    <img src={productsImage5} alt="" className='image' />
                    <img src={productsImage6} alt="" className='image' />
                </div>
                <div class="box" onClick={() => eSpotPage()}>
                    <div className='smallBox'>
                        <div class="small-box  yellow" style={{borderRadius:"0px 5px 5px 0px",marginLeft:"-20px"}}>
                        </div>
                        <p className='mx-3 mt-1 boxText instantCash' >Buy Sell</p>
                    </div>
                    <h2 style={{fontWeight:"600"}} className ="h4-css" ><span  className="eIcon" style={{color:"#04B23D",fontSize:"22pt",marginRight:"3px",display:"inline-block"}}>e</span> Spot
                        </h2>
                    <p className='text' style={{fontSize: '11px'}}>Getting the best OFFER for your commodities</p>
                    <img src={productsImage7} alt="" className='image' />
                    <img src={productsImage8} alt="" className='image' />
                </div>
            </div>
        </div>
    )
}

export default Cards