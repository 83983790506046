import React, { useEffect, useState } from "react";
import backArrowEResearch from "../../../assets/images/backArrowEResearch.svg";
import adminPic from "../Images/adminPic.png";
import "../style/buyerdetail.css";
import pdfIcon from "../Images/pdf 1.svg";
import editIcon from "../Images/edit.svg";
import downloadIcon from "../Images/download.svg";
import eyeIcon from "../Images/eye.svg";
import { FileUploader } from "react-drag-drop-files";
import vectorDocuments from "../../../assets/icons/vector_documents.png";
import { history } from "../../../utils/Utility";
import { fireAjaxRequest } from "../../../utils/Utility";

import {
  BUYER_REQUEST_DETAILS,
  BUYER_REQUEST_STATUS,
  GET_EMANDI_CASH_USER_DETAILS,
  PROFIN_BUYER_DOCS,
  PROFIN_BUYER_SIGNATORY,
  PROFIN_BUYER_PARTNER,
  UPLOAD_FILE,
} from "../../../utils/Api";
import { toast } from "react-toastify";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

function BuyerDetail() {
  const forceUpdate = useForceUpdate();
  const [name, setName] = useState("");
  const [pan, setPan] = useState("");
  const [status, setStatus] = useState("");
  const [buyerRequest, setBuyerRequest] = useState([]);
  const [rId, setRequestId] = useState("");
  const [docNo, setdocNo] = useState("");
  const [editDocType, setEditUpload] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [traderId, setTraderId] = useState("");
  const [commentArrayState, setCommentArrayState] = React.useState([]);
  const [commentState, setCommentState] = React.useState();
  const [commentArrayStateForRejection, setCommentArrayStateForRejection] =
    React.useState([]);
  const [commentStateForRejection, setCommentStateForRejection] =
    React.useState();
  const [editDocLink, setEditDocLink] = useState([]);
  const [file, setFile] = useState(null);
  const [docName, setDocName] = useState("");
  const [checkDocuments, setCheckDocuments] = useState("");

  const backHome = () => {
    history.push("/profin");
  };

  const addBuyer = () => {
    history.push("/addBuyer");
  };

  const handleInputChange = (e) => {
    setCommentState(e.target.value);
  };

  const handleInputChangeForRejection = (e) => {
    setCommentStateForRejection(e.target.value);
  };

  useEffect(() => {
    getBuyerDetails("");
  }, []);

  const handleChange = (file) => {
    for (var i = 0; i < file.length; i++) {
      setFile(file[i]);
      onClickFile(file[i], editDocType, editDocType);
    }
  };

  const onClickFile = (e, prefix, docType) => {
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    if (
      e.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.type == "video/mp4"
    ) {
      toast.error("Please Upload the correct file format");
    } else if (e.size > 10485760) {
      toast.error("Uploaded file is more than 10MB");
    } else {
      let formData = new FormData();

      formData.append("file", e);
      formData.append("prefix", prefix);
      (async () => {
        try {
          const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });
          if (resp) {
            let arr = editDocLink;
            let obj = [];
            obj.push(resp.filePath);
            obj.push(docType);
            arr.push(obj);
            setEditDocLink(arr);
            forceUpdate();
          }
        } catch (err) {
          toast.error(err.message);
        }
      })();
    }
  };

  const editSaveDocs = (e) => {
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders1 = new Headers();
    myHeaders1.append("Content-Type", "application/json");
    myHeaders1.append("Authorization", "Bearer " + token);
    var links = [];

    console.log("eee-----", editDocLink);
    console.log("eeeeeee", buyerRequest.profinBuyerSignatoryList);
    console.log("eeeeeee", buyerRequest.profinBuyerPartners);

    editDocLink.map((item) => {
      links.push(item[0]);
    });
    console.log("33333333333333333", editDocLink);
    console.log("33333333333333333", docName);
    console.log("33333333333333333", docNo);
    console.log("33333333333333333", dLinks);

    var dLinks = JSON.stringify(links);
    dLinks = JSON.parse(dLinks);

    console.log("11111111111", buyerRequest.profinBuyerCompanyDocList.length);

    console.log("11111111111", dLinks);
    alert();

    if (checkDocuments == "partners") {
      var raw = JSON.stringify({
        profinBuyerRequestId:  buyerRequest?.requestId,
        partnerDocuments: [
          {
            partnerName: "",
            documents: [
              {
                docType: editDocType,
                docName: docName,
                docNo: docNo,
                docLink: dLinks,
              },
            ],
          },
        ],
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders1,
        body: raw,
        redirect: "follow",
      };

      fetch(PROFIN_BUYER_PARTNER, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          if (result.statusCode == "200") {
            toast.success("Successfully updated");
            getBuyerDetails();
            window.location.reload();
          } else {
            toast.error("document not uploaded yet");
          }
        })
        .catch((error) => console.log("error", error));
    }
    else if (checkDocuments == "signatory") {
      alert("dslkfjh ");
      var raw = JSON.stringify({
        profinBuyerRequestId: buyerRequest?.requestId,
        signatoryDocuments: [
          {
            signatoryName: "",
            documents: [
              {
                docType: editDocType,
                docName: docName,
                docNo: docNo,
                docLink: dLinks,
              },
            ],
          },
        ],
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders1,
        body: raw,
        redirect: "follow",
      };

      fetch(PROFIN_BUYER_SIGNATORY, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          if (result.statusCode == "200") {
            toast.success("Successfully updated");
            getBuyerDetails();
            window.location.reload();
          } else {
            toast.error("document not uploaded yet");
          }
        })
        .catch((error) => console.log("error", error));
    }
    else {
      var raw = JSON.stringify({
        profinBuyerRequestId: buyerRequest?.requestId,
        documents: [
          {
            docType: editDocType,
            docName: docName,
            docNo: docNo,
            docLink: dLinks,
          },
        ],
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders1,
        body: raw,
        redirect: "follow",
      };

      fetch(PROFIN_BUYER_DOCS, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          if (result.statusCode == "200") {
            toast.success("Successfully updated");
            getBuyerDetails();
            window.location.reload();
          } else {
            toast.error("document not uploaded yet");
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const getUsername = (phoneNo) => {
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      phoneNo: phoneNo,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GET_EMANDI_CASH_USER_DETAILS, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        setTraderId(result.data.id);
        setContactNo(result.data.contactNo);
        forceUpdate();
      })
      .catch((error) => console.log("error", error));
  };

  function getBuyerDetails(e) {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        BUYER_REQUEST_DETAILS + "id=" + sessionStorage.getItem("buyerId"),
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          setName(result.name);
          sessionStorage.setItem("buyerName", result.name);
          setPan(result.pan);
          setStatus(result.status);
          setBuyerRequest(result);
          forceUpdate();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  var commentArray = commentArrayState;
  var commentArrayForRejection = commentArrayStateForRejection;
  const addMoreClick = (e) => {
    commentArray.push(commentState);
    setCommentArrayState(commentArray);
    forceUpdate();
  };

  const addMoreClickForRejection = (e) => {
    commentArrayForRejection.push(commentStateForRejection);
    setCommentArrayStateForRejection(commentArrayForRejection);
    forceUpdate();
  };

  const buyerRequestApproved = () => {
    if (window.confirm("Do you want to approve this request?")) {
      var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      var raw = JSON.stringify({
        comments: [
          {
            commentHeader: "",
            commentForStatus: "",
            commentBody: "",
          },
        ],
      });
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        BUYER_REQUEST_STATUS +
          "id=" +
          sessionStorage.getItem("buyerId") +
          "&status=" +
          "APPROVED",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {})
        .catch((error) => console.log("error", error));
    } else {
      document.getElementById("btnApprovedModalClose").click();
    }
  };

  const buyerRequestRecommendedLender = () => {
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    if (window.confirm("Do you want to Recommend to lender this request?")) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        BUYER_REQUEST_STATUS +
          "id=" +
          sessionStorage.getItem("buyerId") +
          "&status=" +
          "RECOMMENDED_TO_LENDER",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          toast.success("Recommended to lender Successfully");
        })
        .catch((error) => console.log("error", error));
    } else {
      document.getElementById("btnModalClose").click();
    }
  };

  const buyerRequestDiscrepent = () => {
    var comments = "";
    var remarks = "";
    commentArrayState.map((item) => (remarks += item + ","));

    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      comments: [
        {
          commentHeader: "",
          commentForStatus: "",
          commentBody: remarks,
        },
      ],
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      BUYER_REQUEST_STATUS +
        "id=" +
        sessionStorage.getItem("buyerId") +
        "&status=" +
        "DISCREPANT",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        toast.success("Discrepent Successfully");
      })
      .catch((error) => console.log("error", error));
  };

  const buyerRequestRejected = () => {
    var comments = "";
    var remarks = "";
    commentArrayStateForRejection.map((item) => (remarks += item + ","));
    var token = JSON.parse(sessionStorage.getItem("userInfo")).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      comments: [
        {
          commentHeader: "",
          commentForStatus: "",
          commentBody: remarks,
        },
      ],
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      BUYER_REQUEST_STATUS +
        "id=" +
        sessionStorage.getItem("buyerId") +
        "&status=" +
        "REJECTED",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        toast.success("Rejected Successfully");
        getBuyerDetails();
      })
      .catch((error) => console.log("error", error));
  };

  const handleOnButtonClick = (t, name, no, c) => {
    setCheckDocuments(c);
    setEditUpload(t);
    setDocName(name);
    setdocNo(no);
    forceUpdate();
  };

  function downloadFile(fileURL, filename) {
    fetch(fileURL).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  }

  return (
    <div className="profin-view-dashboard">
      <div className="row topheader">
        <div className="col">
          <input
            type="text"
            className="searchTextBox-2"
            placeholder="Search"
            onChange={(e) => this.onSearchChange(e)}
          />
          <img src={adminPic} style={{}} className="pic" />
        </div>
      </div>
      <div className="row" style={{ marginLeft: "3px" }}>
        <div className="col-md-12 viewAllBg">
          <div className="col-md-6 left-div">
            <div className="profin-breadcrumbs">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => backHome()}
                src={backArrowEResearch}
              />
              <div className="breads">
                Home &gt; &nbsp;
                <span style={{ color: "#04B23D" }}>Buyer Details</span>
              </div>
            </div>
            <h1
              style={{
                fontSize: "20px",
                color: "#2F2F2F",
                marginBottom: "10px",
                marginLeft: "75px",
                marginTop: "20px",
                fontWeight: "600",
              }}
            >
              Buyer Detail
            </h1>
            <span
              style={{
                color: "#757575",
                marginLeft: "75px",
                fontSize: "14px",
                marginTop: "15px",
                fontWeight: "500",
                marginBottom: "20px",
              }}
            >
              All buyer details will be visible here
            </span>
          </div>
          <div className="col-md-6 right-div"></div>
        </div>
      </div>
      <div>
        <div className="row" style={{ marginTop: "30px", marginLeft: "65px" }}>
          <div className="col">
            <span className="tbody-head">Buyer Name</span>
          </div>
          <div className="col">
            <span className="tbody-desc">{name}</span>
          </div>
          <div className="col">
            <span className="tbody-head">PAN</span>
          </div>
          <div className="col">
            <span className="tbody-desc">{pan}</span>
          </div>
        </div>
        <div className="row" style={{ marginTop: "15px", marginLeft: "65px" }}>
          <div className="col">
            <span className="tbody-head">Status</span>
          </div>
          <div className="col">
            {status == "INCOMPLETE" ? (
              <button className={"statusIncomplete"}>{status}</button>
            ) : status == "UNDER_PROCESS" ? (
              <button className={"statusBtnunderProcess"}>{status}</button>
            ) : status == "DISCREPANT" ? (
              <button className={"statusBtnunderProcess"}>{status}</button>
            ) : status == "APPROVED" ? (
              <button className={"statusBtn"}>{status}</button>
            ) : status == "REJECTED" ? (
              <button className={"statusRejected"}>{status}</button>
            ) : (
              "--"
            )}{" "}
          </div>
          <div className="col"></div>
          <div className="col"></div>
        </div>
        <br />
        <>
          <table
            className="ecash-document-table table"
            style={{ fontSize: "11pt", marginLeft: "65px" }}
          >
            <thead>
              <tr className="">
                <th className="Column1">Document Type</th>
                <th className="Column2">Documents</th>
                <th className="Column3">Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {(buyerRequest?.profinBuyerCompanyDocList == null ||
                  buyerRequest?.profinBuyerCompanyDocList.length == 0) &&
                (buyerRequest?.profinBuyerProprietorshipDocList == null ||
                  buyerRequest?.profinBuyerProprietorshipDocList.length == 0) &&
                (buyerRequest?.profinBuyerPartnershipBuisnessDocList == null ||
                  buyerRequest?.profinBuyerPartnershipBuisnessDocList?.length ==
                    0) &&
                (buyerRequest?.profinBuyerLLPDocList == null ||
                  buyerRequest?.profinBuyerLLPDocList.length == 0) &&
                (buyerRequest?.profinBuyerSignatoryList == null ||
                  buyerRequest?.profinBuyerSignatoryList.length == 0) &&
                (buyerRequest?.profinBuyerPartners == null ||
                  buyerRequest?.profinBuyerPartners?.length == 0) ? (
                  <>
                    <tr>
                      <th colSpan="3"> No Documents Found </th>
                    </tr>
                  </>
                ) : (
                  <>
                    {buyerRequest?.profinBuyerLLPDocList?.map((item) => (
                      <>
                        <tr className="document-table-row">
                          <th title={item?.docType}>
                            {" "}
                            {item?.docType != null
                              ? item?.docType
                                  .replaceAll("_", " ")
                                  .substring(0, 20)
                              : "Document"}
                            ...{" "}
                          </th>{" "}
                          <td className="document-name">
                            <img className="pdfIcon" src={pdfIcon}></img>{" "}
                            {item?.docType}
                          </td>
                          <td>
                            <div className="documents-actions-div">
                              <div className="edit-download-div">
                                {item?.docLink.includes(",") ? (
                                  <>
                                    {item.docLink.split(",").map((p) => (
                                      <>
                                        <img
                                          src={editIcon}
                                          onClick={(t, n, docNo, c) =>
                                            handleOnButtonClick(
                                              item.docType,
                                              item.docName,
                                              item.docNo,
                                              ""
                                            )
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#myEdit"
                                        />
                                        <img
                                          src={downloadIcon}
                                          data-bs-toggle="modal"
                                          data-bs-target="#myDownload"
                                          onClick={(e, x) =>
                                            downloadFile(p, item?.docType)
                                          }
                                        />
                                        <a
                                          style={{ marginLeft: "10px" }}
                                          href={p}
                                          target="blank"
                                        >
                                          <img src={eyeIcon}></img>
                                        </a>
                                        <span> | </span>
                                      </>
                                    ))}
                                    {}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={editIcon}
                                      onClick={(t, n, docNo, c) =>
                                        handleOnButtonClick(
                                          item.docType,
                                          item.docName,
                                          item.docNo,
                                          ""
                                        )
                                      }
                                      data-bs-toggle="modal"
                                      data-bs-target="#myEdit"
                                    />
                                    <img
                                      src={downloadIcon}
                                      data-bs-toggle="modal"
                                      data-bs-target="#myDownload"
                                      onClick={(e, x) =>
                                        downloadFile(
                                          item?.docLink,
                                          item?.docType
                                        )
                                      }
                                    />
                                    <a href={item?.docLink} target="blank">
                                      <img src={eyeIcon}></img>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                    {buyerRequest?.profinBuyerCompanyDocList?.map(
                      (item, key) => (
                        <>
                          <tr className="document-table-row">
                            <th title={item?.docType}>
                              {" "}
                              {item?.docType != null
                                ? item?.docType
                                    .replaceAll("_", " ")
                                    .substring(0, 20)
                                : "Document"}
                              ...{" "}
                            </th>{" "}
                            <td className="document-name">
                              <img className="pdfIcon" src={pdfIcon}></img>{" "}
                              {item?.docType}
                            </td>
                            <td>
                              <div className="documents-actions-div">
                                <div className="edit-download-div">
                                  {item.docLink.map((p) => (
                                    <>
                                      <img
                                        src={editIcon}
                                        onClick={(t, n, docNo, c) =>
                                          handleOnButtonClick(
                                            item.docType,
                                            item.docName,
                                            item.docNo,
                                            "company"
                                          )
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#myEdit"
                                      />
                                      <img
                                        src={downloadIcon}
                                        onClick={(e, x) =>
                                          downloadFile(p, item?.docType)
                                        }
                                      />
                                      <a href={p} target="blank">
                                        <img src={eyeIcon}></img>
                                      </a>
                                      <span> | </span>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    )}
                    {buyerRequest?.profinBuyerProprietorshipDocList?.map(
                      (item, key) => (
                        <>
                          <tr className="document-table-row">
                            <th title={item?.docType}>
                              {" "}
                              {item?.docType != null
                                ? item?.docType
                                    .replaceAll("_", " ")
                                    .substring(0, 20)
                                : "Document"}
                              ...{" "}
                            </th>{" "}
                            <td className="document-name">
                              <img className="pdfIcon" src={pdfIcon}></img>{" "}
                              {item?.docType}
                            </td>
                            <td>
                              <div className="documents-actions-div">
                                <div className="edit-download-div">
                                  {item.docLink.map((p) => (
                                    <>
                                      <img
                                        src={editIcon}
                                        onClick={(t, n, docNo, c) =>
                                          handleOnButtonClick(
                                            item.docType,
                                            item.docName,
                                            item.docNo,
                                            ""
                                          )
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#myEdit"
                                      />
                                      <img
                                        src={downloadIcon}
                                        onClick={(e, x) =>
                                          downloadFile(p, item?.docType)
                                        }
                                      />
                                      <a href={p} target="blank">
                                        <img src={eyeIcon}></img>
                                      </a>
                                      <span> | </span>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    )}
                    {buyerRequest?.profinBuyerPartnershipBuisnessDocList?.map(
                      (e, key) =>
                      
                          <>
                            <tr className="document-table-row">
                              <th title={e?.docType}>
                                {" "}
                                {e?.docType != null
                                  ? e?.docType.substring(0, 20)
                                  : "Document"}
                                ...{" "}
                              </th>
                              <td className="document-name">
                                <img className="pdfIcon" src={pdfIcon}></img>{" "}
                                {e.docType}
                              </td>
                              <td>
                                <div className="documents-actions-div">
                                  <div className="edit-download-div">
                                    {e.docLink.map((p) => (
                                      <>
                                        <img
                                          src={editIcon}
                                          onClick={(t, n, docNo, c) =>
                                            handleOnButtonClick(
                                              e.docType,
                                              e.docName,
                                              e.docNo,
                                              "partnershipbusiness"
                                            )
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#myEdit"
                                        />
                                        <img
                                          src={downloadIcon}
                                          onClick={(e, x) =>
                                            downloadFile(p, e?.docType)
                                          }
                                        />
                                        <a href={p} target="blank">
                                          <img src={eyeIcon}></img>
                                        </a>
                                        <span> | </span>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                      
                    )}
                    {buyerRequest?.profinBuyerSignatoryList?.map((item, key) =>
                      item?.documents?.map((e) => (
                        <>
                          <tr className="document-table-row">
                            <th title={e?.docType}>
                              {" "}
                              {e?.docType != null
                                ? e?.docType.substring(0, 20)
                                : "Document"}
                              ...{" "}
                            </th>
                            <td className="document-name">
                              <img className="pdfIcon" src={pdfIcon}></img>{" "}
                              {e.docType}
                            </td>
                            <td>
                              <div className="documents-actions-div">
                                <div className="edit-download-div">
                                  {e.docLink.map((p) => (
                                    <>
                                      <img
                                        src={editIcon}
                                        onClick={(t, n, docNo, c) =>
                                          handleOnButtonClick(
                                            e.docType,
                                            e.docName,
                                            e.docNo,
                                            "signatory"
                                          )
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#myEdit"
                                      />
                                      <img
                                        src={downloadIcon}
                                        onClick={(e, x) =>
                                          downloadFile(p, e?.docType)
                                        }
                                      />
                                      <a href={p} target="blank">
                                        <img src={eyeIcon}></img>
                                      </a>
                                      <span> | </span>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                    {buyerRequest?.profinBuyerPartners?.map((item, key) =>
                      item?.documents?.map((e) => (
                        <>
                          <tr className="document-table-row">
                            <th title={e?.docType}>
                              {" "}
                              {e?.docType != null
                                ? e?.docType.substring(0, 20)
                                : "Document"}
                              ...{" "}
                            </th>
                            <td className="document-name">
                              <img className="pdfIcon" src={pdfIcon}></img>{" "}
                              {e?.docType}
                            </td>
                            <td>
                              <div className="documents-actions-div">
                                <div className="edit-download-div">
                                  {e.docLink.map((p) => (
                                    <>
                                      <img
                                        src={editIcon}
                                        onClick={(t, n, docNo, c) =>
                                          handleOnButtonClick(
                                            e.docType,
                                            e.docName,
                                            e.docNo,
                                            "partners"
                                          )
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#myEdit"
                                      />
                                      <img
                                        src={downloadIcon}
                                        onClick={(e, x) =>
                                          downloadFile(p, e?.docType)
                                        }
                                      />
                                      <a href={p} target="blank">
                                        <img src={eyeIcon}></img>
                                      </a>
                                      <span> | </span>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </>
                )}
              </>
            </tbody>
          </table>
        </>
        {buyerRequest?.status?.toLowerCase() == "approved" ? (
          <div className="footer-btns-container">
            <button className="discrepent-btn">Discrepent</button>
            <div className="">
              <button
                className="btn btn-secondary"
                style={{ marginRight: "10px" }}
                disabled
                id="button-id"
              >
                Approve
              </button>
              <button
                className="btn btn-secondary"
                style={{ marginRight: "10px" }}
                disabled
              >
                Reject
              </button>
            </div>
          </div>
        ) : buyerRequest?.status?.toLowerCase() == "rejected" ? (
          <div className="footer-btns-container">
            <button className="discrepent-btn">Discrepent</button>
            <div className="">
              <button
                className="btn btn-secondary"
                style={{ marginRight: "10px" }}
                disabled
                id="button-id"
              >
                Approve
              </button>
              <button
                className="btn btn-secondary"
                style={{ marginRight: "10px" }}
                disabled
              >
                Reject
              </button>
            </div>
          </div>
        ) : (
          <div className="footer-btns-container">
            <button
              className="discrepent-btn"
              data-bs-toggle="modal"
              data-bs-target="#myDiscrepent"
            >
              Discrepent
            </button>
            <div className="sent-reject-div">
              <button
                className="sent-to-lender-btn"
                onClick={addBuyer}
                data-bs-toggle="modal"
                data-bs-target="#myApproved"
                id="button-id"
              >
                Approve
              </button>
              <button
                className="reject-btn"
                data-bs-toggle="modal"
                data-bs-target="#myRejectedModal"
              >
                Reject
              </button>
            </div>
          </div>
        )}
      </div>
      <div class="modal" id="myEdit">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body editModal">
              <button
                type="button"
                class="btn-close modal-close"
                style={{ outline: "none", border: "none", marginTop: "5px" }}
                data-bs-dismiss="modal"
              ></button>
              <h3 style={{ marginBottom: "30px" }}> UPLOAD FILES </h3>
              <div class="mid-edit-section">
                <FileUploader
                  multiple="true"
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
                <br />
                <img class="documents-img" src={vectorDocuments} />
                <h3 class="drag-drop"> Drag & Drop </h3>
                <span class="your-files">
                  {" "}
                  Your files here Or Browse to upload{" "}
                </span>
                <span class="only-jpeg">
                  {" "}
                  Only JPEG, PNG, GIF and PDF files with max size of 15MB{" "}
                </span>
              </div>
              <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h5 style={{ textAlign: "center" }}>
                  {editDocLink.length} files attached
                </h5>
              </div>
              <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h5 style={{ textAlign: "center" }}>files attached</h5>
                <br />
              </div>
              {editDocLink.length > 0 ? (
                <button
                  id="btnUpdate btnSave"
                  onClick={editSaveDocs}
                  style={{ width: "140px" }}
                  class="btn btn-sm btn-success btnSave"
                >
                  Save File
                </button>
              ) : (
                <button
                  id="btnUpdate btnSave"
                  disabled
                  style={{ width: "140px" }}
                  class="btn btn-sm btn-success btnSave disabled"
                >
                  Save File
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="myDiscrepent">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ flexDirection: "row-reverse" }}>
              <h4 class="modal-title h4-css">Add Reason for Discrepent</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              {commentArrayState.map((item, key) => (
                <b className="reasonText">
                  {key + 1}.{item}
                  <br />
                </b>
              ))}
              <textarea
                id="txtRemarks"
                name="txtRemarks"
                placeholder="Add Comments"
                onChange={(e) => handleInputChange(e)}
              ></textarea>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success submit-button"
                data-bs-dismiss="modal"
                onClick={buyerRequestDiscrepent}
              >
                Submit
              </button>
              <div className="clearfix"></div>
              <span className="btnAddMore" onClick={(e) => addMoreClick(e)}>
                <b>+</b> Add More
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="myRejectedModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ flexDirection: "row-reverse" }}>
              <h4 class="modal-title h4-css">Add Reason for Rejection</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              {commentArrayStateForRejection.map((item, key) => (
                <b className="reasonText">
                  {key + 1}.{item}
                  <br />
                </b>
              ))}
              <textarea
                id="txtRemarks"
                name="txtRemarks"
                placeholder="Add Comments"
                onChange={(e) => handleInputChangeForRejection(e)}
              ></textarea>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success submit-button"
                data-bs-dismiss="modal"
                onClick={buyerRequestRejected}
              >
                Submit
              </button>
              <div className="clearfix"></div>
              <span
                className="btnAddMore"
                onClick={(e) => addMoreClickForRejection(e)}
              >
                <b>+</b> Add More
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyerDetail;
