import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import share from '../../../assets/images/share.jpg'
import{WhatsappShareButton, LinkedinShareButton,LinkedinIcon,WhatsappIcon} from "react-share";
export class List extends Component {
  constructor(props) {
    super(props);
    this.baseUrl = window.location.href;
    this.state = {
        showIcon:false,
    }
    this.wrapperRef = React.createRef();
}

handleShowIcon=async(e)=>{
  this.setState({showIcon:true})
  if(this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)){
     this.setState({showIcon:false})
  }
  document.addEventListener("mousedown",this.handleShowIcon);
  return()=>{
    document.removeEventListener("mousedown",this.handleShowIcon)
  }
  
}
  render() {
    let { onClickBack, state } = this.props;
    console.log(state, "state")
    return (
      <div className='Emandi-news-wrapper-cls'>
        <div className="header-cls">
          <i className="pi pi-angle-left" onClick={onClickBack}></i>
          {/* <img src={Logo} /> */}
          <p>Emandi News</p>
        </div>
        <div ref={this.wrapperRef} style={{float:"right",marginRight:"30px", marginTop:"-35px"}}>
                            <div onClick={this.handleShowIcon} style={{cursor:"pointer",display:"flex"}}>

                            <p><img src={share} width="27" height="14" /></p>
                        <p style={{marginLeft:"10px", fontSize:"15px"}}>Share</p>
                            </div>
                        
                            { this.state.showIcon && (
 <div style={{display:"flex"}}>
 <p>
    
 <WhatsappShareButton url={this.baseUrl} windowHeight="650px" title="Please check the Emandi News by clicking on">
 <WhatsappIcon size={32} round={true} />
 </WhatsappShareButton>
    </p>
    <p style={{marginLeft:"4px"}}>

    <LinkedinShareButton url={this.baseUrl} title="Please check the Emandi News by clicking on">
 <LinkedinIcon size={32} round={true} />
 </LinkedinShareButton>
    </p>

 </div>
        )
                            }
                        </div>
        <div className='content-view-cls'>
          <img height="200px" src={state && state.listData.newsPicturePath} />
          <div>
            <p className='heading'>{state && state.listData.topic}</p>
            <p className='heading'>{state && state.listData.location}{state && state.listData.uploadedOn ? <span> {state && state.listData.uploadedOn.slice(8, 10)} {state && state.listData.uploadedOn.slice(5, 7) == "01" ? "Jan" : state && state.listData.uploadedOn.slice(5, 7) == "02" ? "Feb" : state && state.listData.uploadedOn.slice(5, 7) == "03" ? "Mar" :
              state && state.listData.uploadedOn.slice(5, 7) == "04" ? "Apr" : state && state.listData.uploadedOn.slice(5, 7) == "05" ? "May" : state && state.listData.uploadedOn.slice(5, 7) == "06" ? "Jun" : state && state.listData.uploadedOn.slice(5, 7) == "07" ? "Jul" :
                state && state.listData.uploadedOn.slice(5, 7) == "08" ? "Aug" : state && state.listData.uploadedOn.slice(5, 7) == "09" ? "Sep" : state && state.listData.uploadedOn.slice(5, 7) == "10" ? "Oct" : state && state.listData.uploadedOn.slice(5, 7) == "11" ? "Nov" : "Dec"} {state && state.listData.uploadedOn.slice(0, 4)}</span>
              : state && state.listData.newsContent}</p>
           <Scrollbars> <p>{state && state.listData.newsContent}</p></Scrollbars>
          </div>
        </div>
      </div>
    )
  }
}

export default List