import React, { Component } from 'react';
import './style/style.css';
import arrow from './Images/arrow.png';
import rightArrow from '../../assets/icons/right_arrow.png';
import leftVector from '../../assets/icons/vector_left.png';
import RightVector from '../../assets/icons/vector_right.png';
import { GET_ECASH_REQUESTS } from '../../utils/Api';
import { history } from '../../utils/Utility';
import Details from './Components/Details';
import DoughnutChart from './Doughnut Chart';
import './style/Header.css';
import profilePic from './Images/profilePic.PNG';
import $ from 'jquery';
import CanvasJSReact from '../../assets/@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eCashRequest: {},
      filteredRecommended_To_Lender: 0,
      filteredUnderProcess: 0,
      filteredIncompleteProposal: 0,
      limit: 20,
      offset: 0,
      totalCount: 0,
    };
    this.data = '';
    this.className = '';
    this.filteredRecommended_To_LenderCount = 0;
    this.filteredUnderProcessCount = 0;
    this.filteredIncompleteProposalCount = 0;
    this.pathName = '';
  }

  componentDidMount() {
    this.getECashRequest(0, 20);
  }

  handleLeftVector = (e) => {
    $(".active").removeClass("active").prev("button").addClass("active");
    let offset = this.state.offset - 20;
    let limit = 20;
    this.setState({ offset: offset, limit: limit })
    this.getECashRequest(offset, limit);
  }

  handleRightVector = (e) => {
    $(".active").removeClass("active").next("button").addClass("active");

    let offset = this.state.offset + 20;
    let limit = 20;
    this.setState({ offset: offset, limit: limit })
    this.getECashRequest(offset, limit);
  }

  handleFirstButton = (e) => {
    $(e.target).addClass("active");
    $(e.target).siblings("button").removeClass("active")
    let offset = 0;
    let limit = 20;
    this.setState({ offset: offset, limit: limit })
    this.getECashRequest(offset, limit);
  }

  handleSecondButton = (e) => {
    $(e.target).addClass("active");
    $(e.target).siblings("button").removeClass("active")
    let offset = 20;
    let limit = 20;
    this.setState({ offset: offset, limit: limit })
    this.getECashRequest(offset, limit);
  }

  handleThirdButton = (e) => {
    $(e.target).addClass("active");
    $(e.target).siblings("button").removeClass("active")
    let offset = 40;
    let limit = 20;
    this.setState({ offset: offset, limit: limit })
    this.getECashRequest(offset, limit);
  }

  handleECashClick = (e) => {
    this.props.history.push('/addECash');
  };

  handleProceed(id) {
    history.push('/eCashDetail/' + id);
  }

  getECashRequest = (o, l) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    console.log('Token', token);
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      GET_ECASH_REQUESTS + '?offset=' + o + '&limit=' + l,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result",result)

        this.setState({ eCashRequest: result });
        this.setState({ totalCount: result.count })
        this.filteredRecommended_To_LenderCount=result.items?.filter((item) =>item.statusOfRequest === 'Recommended_To_Lender').length;
        this.filteredUnderProcessCount=result.items?.filter((item) =>item.statusOfRequest === 'Under_Process_With_Risk').length;
        this.filteredIncompleteProposalCount=result.items?.filter((item) =>item.statusOfRequest === 'Incomplete_Proposal').length;

        // result.items?.filter((item) =>
        //   item.statusOfRequest === 'Recommended_To_Lender'
        //     ? this.filteredRecommended_To_LenderCount++
        //     : this.filteredRecommended_To_LenderCount ||
        //       item.statusOfRequest === 'Under_Process_With_Risk'
        //       ? this.filteredUnderProcessCount++
        //       : this.filteredUnderProcessCount ||
        //         item.statusOfRequest === 'Incomplete_Proposal'
        //         ? this.filteredIncompleteProposalCount++
        //         : this.filteredIncompleteProposalCount
        // );
        this.setState({
          filteredRecommended_To_Lender:
            this.filteredRecommended_To_LenderCount,
        });
        this.setState({
          filteredUnderProcess: this.filteredUnderProcessCount,
        });
        this.setState({
          filteredIncompleteProposal: this.filteredIncompleteProposalCount,
        });
        console.log('result', result);
      })
      .catch((error) => console.log('error', error));
  };

  getECashRequestSearch = (offset, limit, searchString) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS +
      '?offset=' +
      this.state.offset +
      '&limit=' +
      this.state.limit +
      '&searchString=' +
      searchString,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ eCashRequest: result });
      })
      .catch((error) => console.log('error', error));
  };

  onSearchChange = (e) => {
    this.state.search = e.target.value;
    this.getECashRequestSearch(0, 500, this.state.search);
  };

  handleClick = (e) => {
    this.props.history.push('/viewAll');
  };

  render() {
    const options = {
			animationEnabled: true,
			title: {
				text: ""
			},
			subtitles: [{
				text: "",
				verticalAlign: "center",
				fontSize: 24,
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
				showInLegend: false,
				indexLabel: "{}",
				innerRadius:"80%",
				yValueFormatString: "#,###'%'",
				dataPoints: [
					{ name: "Incomplete Proposals ",  y:   
					this.state.filteredIncompleteProposal,color:"#F27B0E" },
					{ name: "Under Process", y: 
						this.state.filteredUnderProcess,color:"#F5D405" },
					{ name: "Recommended to lender", y: 
						this.state.filteredRecommended_To_Lender,color:"#04B23D" },
				]
			}]
		}

    return (
      <div className='eCash-home'>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
          integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
          crossorigin="anonymous"
        ></link>
        <div className="container-fluid">

          <div className="row topheader">
            {/* <span className="row row-topheader">
            <div className="col-md-4 col-sm-2">
              <input
                type="text"
                className="searchTextBox input-search"
                placeholder="Search...."
                onChange={(e) => this.onSearchChange(e)}
              />
            </div> */}
            <div className="col-md-6 col-sm-6 col-md-6 column-css" >
              <input
                type="text"
                className="searchTextBox input-search-1"
                placeholder="Search"
                onChange={(e) => this.onSearchChange(e)}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-md-6">
              <img src={profilePic} className="pic" />
              <span className="headerLabel">Admin</span>
            </div>
          </div>
          <div className='row' style={{marginTop:"20px"}}>
            <div className="col-md-8">
              <div className='left_box'>
                <div className="firstCard">
                  <span>
                    Recommended
                    <br /> to lender
                  </span>
                  <h1 className="cardValue">
                    {this.state.filteredRecommended_To_Lender
                      .toString()
                      .padStart(2, '0')}
                    <img className="cardArrow" src={arrow} />
                  </h1>
                </div>
                <div className="secondCard">
                <span >
                  Under Process
                  <br /> (with risk)
                </span>
                <h1 className="cardValue">
                  {this.state.filteredUnderProcess.toString().padStart(2, '0')}
                  <img className="cardArrow" src={arrow} />
                </h1>
              </div>
              <div className="firstCard">
                <span>
                  Incomplete
                  <br /> Proposals
                </span>
                <h1 className="cardValue">
                  {this.state.filteredIncompleteProposal
                    .toString()
                    .padStart(2, '0')}
                  <img className="cardArrow" src={arrow} />
                </h1>
              </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='right_box'>
          
              <div
                className="thirdCard"
                style={{ width: '450px', float: 'right', marginRight: '30px' }}
              >
                <div className="totalHeading">
                  <span>Total</span>
                  <h1>
                    {' '}
                    {(
                      this.state.filteredRecommended_To_Lender +
                      this.state.filteredUnderProcess +
                      this.state.filteredIncompleteProposal
                    )
                      .toString()
                      .padStart(2, '0')}{' '}
                  </h1>
                </div>
                {
                  console.log("IP State",this.filteredIncompleteProposalCount)
                }
               <CanvasJSChart options = {options} 
			/>

                    <div className="legend">
                <span>Recommended to lender</span>
                <br />
                <h1>
                  <i className="legendcolor recommendedLegend"></i>{' '}
                  {this.state.filteredRecommended_To_Lender
                    .toString()
                    .padStart(2, '0')}
                </h1>
                <span>Under Process (with-risk)</span>
                <br />
                <h1>
                  <i className="legendcolor underProcessLegend"></i>{' '}
                  {this.state.filteredUnderProcess
                    .toString()
                    .padStart(2, '0')}
                </h1>
                <span>Incomplete Proposals</span>
                <br />
                <h1>
                  <i className="legendcolor incompleteLegend"></i>{' '}
                  {this.state.filteredIncompleteProposal
                    .toString()
                    .padStart(2, '0')}
                </h1>
              </div>
                {/* <AccumulationDoughnut 
              IP = {this.state.filteredIncompleteProposal} UP = {this.state.filteredUnderProcess} RL = {this.state.filteredRecommended_To_Lender} 
              /> */}
              </div>
              </div>
            </div>
          </div>
         
          <div className="row" style={{ marginTop: "20px" }} >
            <div className="col-md-12 col-sm-12 viewAll-1" style={{ position: "relative", zIndex: "999999999" }}>
              <span className="viewAllLink-1" onClick={this.handleClick}>
                View All
                <span class="view-all-arrow">
                  <i class="fa fa-arrow-right view-all-arrow" ></i>
                </span>
              </span>
              <button onClick={this.handleECashClick} className="btnAdd">
                {' '}
                <b style={{ fontSize: '25px', marginRight: '8px' }}>+</b>
                &nbsp;Add eCash
              </button>
              {/* <button disabled className="btnAdd" style={{fontSize:"12px"}}>
                {' '}
                <b style={{ fontSize: '25px',marginRight:"10px"}}>+</b>
                &nbsp;Add eCash
              </button> */}
            </div>
          </div>
          <div className="clearfix" style={{ clear: 'both' }}></div>
          <div className="tableCard" style={{ margintop: "160px" }}>
            <table className="customTable">
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Name</td>
                  <td>Request Date</td>
                  <td>Request Lim</td>
                  <td>Approved Lim</td>
                  <td>Available Lim</td>
                  <td>Status</td>
                  <td>Last Mod.</td>
                </tr>
              </thead>
              <tbody>

                {this.state.eCashRequest.items?.map((item) => (
                  <tr>
                    <td> {item.responseId}</td>
                    <td title={item.name}> {item.name.substring(0, 10)}...</td>
                    <td> {item.requestDate.substring(0, item.requestDate.indexOf("a"))}</td>
                    <td> {item.limitApplied}</td>
                    <td>
                      {' '}
                      {item?.assignedEmandiCashLimit
                        ? item?.assignedEmandiCashLimit
                        : 0}
                    </td>
                    <td>
                      {' '}
                      {item?.availableEmandiCashLimit
                        ? item?.availableEmandiCashLimit
                        : 0}
                    </td>
                    {item.statusOfRequest == 'Recommended_To_Lender' ? (
                      <td>
                        <button className={'statusBtn'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) : item.statusOfRequest == 'System_Approved_With_Risk' ? (
                      <td>
                        <button className={'statusBtn'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) : item.statusOfRequest == 'Under_Process_With_Risk' ? (
                      <td>
                        <button className={'statusBtnunderProcess'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) : item.statusOfRequest == 'Red_Flagged_With_Risk' ? (
                      <td>
                        <button className={'statusBtnunderProcess'}>
                          {item.statusOfRequest.split('_').join(' ')}
                        </button>
                      </td>
                    ) :
                      item?.statusOfRequest == 'Discrepant' ? (
                        <td>
                          <button className={'statusBtnunderProcess'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : item?.statusOfRequest == 'Incomplete_Proposal' ? (
                        <td>
                          <button className={'statusIncomplete'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : item?.statusOfRequest?.toLowerCase() == 'approved' ? (
                        <td>
                          <button className={'statusBtn'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : item?.statusOfRequest?.toLowerCase() == 'rejected' ? (
                        <td>
                          <button className={'statusIncomplete'}>
                            {item.statusOfRequest.split('_').join(' ')}
                          </button>
                        </td>
                      ) : <td>--</td>}
                    <td>{item?.lastModifiedBy}</td>
                    <td>
                      <img
                        src={rightArrow}
                        onClick={(e) => {
                          this.handleProceed(item.id);
                        }}
                        alt=""
                        className="arrowBtn"
                      />
                      {this.state.id > 0 ? <Details /> : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div class="pagination-section">
          <span class="pagination-left">Showing {this.state.offset + 1} to {this.state.offset+20} Entries</span>
            <span class="pagination-right">
              <div class="pagination">

                {
                  this.state.offset == 0 ? <button class="page-link page-num disabled" disabled style={{ backgroundColor: '#E8E7E7' }} >
                    <img src={leftVector} alt="" className="left-vector" />
                  </button> : <button class="page-link page-num" onClick={this.handleLeftVector} style={{ backgroundColor: '#E8E7E7' }} >
                    <img src={leftVector} alt="" className="left-vector" />
                  </button>
                }
                <button class="active page-link page-num" onClick={this.handleFirstButton} id="firstPage" >
                  1
                </button>
                {
                  this.state.totalCount > 20 ? <button class="page-link page-num" onClick={this.handleSecondButton}>2</button> : ""
                }
                {
                  this.state.totalCount > 40 ? <button class="page-link page-num" onClick={this.handleThirdButton}>3</button> : ""
                }
                {
                  this.state.totalCount > 20 ? <button class="page-link page-num" onClick={this.handleRightVector} style={{ backgroundColor: '#E8E7E7' }}>
                    <img src={RightVector} alt="" className="left-vector" />
                  </button> : <button class="page-link page-num" disabled style={{ backgroundColor: '#E8E7E7' }}>
                    <img src={RightVector} alt="" className="left-vector" />
                  </button>
                }

              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
