import React, { Component } from 'react';
import { Link, useHistory } from "react-router-dom";
import { history } from '../../../utils/Utility';
import './emandiNews.scss';
import image1 from "../../../assets/images/newsImage1.png";
import image2 from "../../../assets/images/newsImage2.png";
import { toast } from 'react-toastify';
import {fireAjaxRequest} from '../../../utils/Utility';
import {GET_NEWS} from '../../../utils/Api'

class EmandiNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
          listData:[]
        }
      }
    componentDidMount(){
        this.shownewsList();
      }
      shownewsList=()=>{
        let Pathlocation = history.location.pathname;
         // let Path = Pathlocation.replace("/Emandinews/", "");
          //alert(Path);
          (async () => {
              // this.setLoading(true);
              try {
  
                  const response = await fireAjaxRequest(`${GET_NEWS}`+"?offset=0&limit=4", {
                      method: 'GET',
                      headers: {
                          "Content-Type": "application/json",
                          // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                      },
                  });
                  if(response){
                    console.log(response);
                    this.setState({listData:response.data})
                  }
              } catch (err) {
                  toast.error(err.message || "Failed to load !");
  
              }
          })();
      }
    hisFunc()
    {
        this.Pathlocation = history.location.pathname;
    }
    emandiNewsPage = () => {
      // history.push("/BuyerUnderwriting/newuser");
      history.push("/eMandiNews");
    }
    readFullNews = (e) => {
        history.push(`/eMandiFullNews/${e}`);
    }
  
  render(){
    return (
        <div className='emandiNewsSectionn' id="emandiNEWSId">

            <div className='smallBox mt-xl-5 emandi-news-css emandiTVV'>
              <div className='headerEmandiNews'>
                <div class="small-box  yellow" style={{borderRadius:"0px 5px 5px 0px"}}>
                  </div>
                  <p className='mx-3 big mt-1' style={{ fontWeight: 'bold' }}>eNews</p>
                  <p className='mx-1 small p-css'  style={{ fontSize: '16px', color: 'black !important' }}>| &nbsp; Commodity market ki latest khabar</p>
              </div>
                <p className="viewAll" style={{color: '#04B23D',cursor:'pointer' }} onClick={()=>this.emandiNewsPage()}> View All</p>
            </div>
            <div class="row1-container mt-4 news-section-css">
                
                    {
                        this.state.listData.map((item)=> 
                               
                                <div class="box" style={{marginRight:"20px",width:"250px",padding:"30px 20px"}}>
                                    <img src={item.newsPicturePath} style={{width:"100%",height:"100px",marginBottom:"20px"}} alt="..." />
                                    <div class="card-body">
                                        <p class="card-text" style={{fontWeight:"500" ,fontSize: "14px"}}>{item.topic}</p>
                                        <p className='fullNews' style={{color:"rgb(4, 178, 61)",fontWeight:"500",cursor:"pointer",fontSize:"12px"}} onClick={() => this.readFullNews(item.id)}>Read full news</p>
                                    </div>
                                </div>
                        )
                        
                    }
                
            </div>
        </div>
    )
  }
}

export default EmandiNews