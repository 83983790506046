import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import commodity from "../../../assets/images/commodity.png";
import Soyabean from "../../../assets/images/soyabean.jpg";
import Maize from "../../../assets/images/maize.jpg";
import { history } from '../../../utils/Utility';
import './commodityTabs.scss'
import './emandiCommodity.css';
import axios from 'axios';
import { COMMODITY_RESEARCH,GET_WEEKLY_REPORT} from "../../../utils/Api";
import { toast } from 'react-toastify';
import $ from 'jquery';
import CommodityResearch from '../../CommodityResearch';

class EmandiCommodity extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            currentStage: "dailyCommodityReports",
            activeItem: 0,
            offset :0,
            commodityLimit: 10,
            count:0,
            current_page:1,
            pageNumberLimit:3,
            maxPageNumberLimit:3,
            minPageNumberLimit:0,
            isAllSelected:false,
            commodityCardData:[],
            seasonalCommodityCardData:[],
            weeklyCommodityCardData:[],
            formData: {
                reportType: "DAILY_COMMODITY_REPORTS",              
                harvestType: "PRE_HARVEST",
                // location:"All"
            },
            // items: [
            //     { label: 'Daily Commodity Reports', value: 1 },
            //     { label: 'Seasonal Commodity Reports', value: 2 },
            //     { label: 'Market Prices', value: 3 },
            //     { label: 'eMandi TV', value: 4 },
            //     { label: 'eMandi News', value: 5 },  
            // ],
            options: [{ "label": "All time", "value": "" },
            { "label": "Last 15 days", "value": "15days" },
            { "label": "Last 1 Month", "value": "1Month" },
            { "label": "Last 3 Month", "value": "3Month" },
            { "label": "Last 6 Month", "value": "6Month" },
            { "label": "Last 1 Year", "value": "1Year" }],

            languages: [{ "label": "All Languages", "value": "" },
            { "label": "ENGLISH", "value": "ENGLISH" },
            { "label": "HINDI", "value": "HINDI" },],

            currentCommodityTab : "daily",
            
        }
        this.selectRef = React.createRef();
        this.applyBorder = this.applyBorder.bind(this);
        this.applySecondBorder = this.applySecondBorder.bind(this);
        this.applyThirdBorder = this.applyThirdBorder.bind(this);
        this.commodityFilters = this.commodityFilters.bind(this);
    }
    applyBorder=(id)=>
    {
       document.getElementById(id).style.borderBottom="2px solid black"
       document.getElementById('profile-tab0').style.borderBottom="none"
       document.getElementById('contact-tab0').style.borderBottom="none"
       this.setState({currentCommodityTab: "daily"})
       this.selectRef.current.selectedIndex = 0;
    }
    applySecondBorder=(id)=>
    {
       document.getElementById(id).style.borderBottom="2px solid black"
       document.getElementById('home-tab0').style.borderBottom="none"
       document.getElementById('contact-tab0').style.borderBottom="none"   
       this.setState({currentCommodityTab: "seasonal"})
       this.selectRef.current.selectedIndex = 0;
     }
    applyThirdBorder=(id)=>
    {
       document.getElementById(id).style.borderBottom="2px solid black"
       document.getElementById('profile-tab0').style.borderBottom="none"
       document.getElementById('home-tab0').style.borderBottom="none"
       this.setState({currentCommodityTab: "weekly"})
       this.selectRef.current.selectedIndex = 0;
    }   
   
  
    componentDidMount() {
        let { formData } = this.state;
        console.log(formData);
        this.getDailyReportCommodity(10);
        this.getSeasonalReportCommodity(10);
        this.getWeeklyReportCommodity(10);
    }
    eResearch=()=>{
        history.push('/eResearch');
    }
    viewAllDailyCommodity=()=>{
       //this.getDailyReportCommodity(500);

    }
    changeSlider=(e)=>{
        document.getElementsByClassName("indicator")[0].style.marginLeft=e+"px";
        
    }
    getDailyReportCommodity = (commodityLimit) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(COMMODITY_RESEARCH+"?offset=0&limit="+commodityLimit+"&reportType=DAILY_COMMODITY_REPORTS&fromDate=1Year", requestOptions)
            .then(response => response.json())
            .then(result => this.setState({commodityCardData:result.data}) )
            .catch(error => console.log('error', error));
    }
 
    getSeasonalReportCommodity = (commodityLimit) => {
       
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(COMMODITY_RESEARCH+"?offset=0&limit="+commodityLimit+"&reportType=SEASONAL_COMMODITY_REPORTS&fromDate=1Year&harvestProcess=POST_HARVEST", requestOptions)           
           .then(response => response.json())
            .then(result => this.setState({seasonalCommodityCardData:result.data}) )
            .catch(error => console.log('error', error));
    }
    getWeeklyReportCommodity = (commodityLimit) => {
       
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(GET_WEEKLY_REPORT+"/get?offset=0&limit="+commodityLimit+"&fromDate=1Year", requestOptions)
            .then(response => response.json())
            .then(result => this.setState({weeklyCommodityCardData:result.data}, ()=>{
                console.log(this.state.weeklyCommodityCardData)
            }) )
            // .then(result => this.setState({weeklyCommodityCardData:result.data}) )
            .catch(error => console.log('error', error));
    }

    commodityFilters=(e)=>{
        let time=e.target.value;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
        let url="";
        // let type= "";
        // type= {this.state.currentCommodityTab ==="daily"? 'DAILY_COMMODITY_REPORTS' : (this.state.currentCommodityTab ==="seasonal" ? "SEASONAL_COMMODITY_REPORTS" : "WEEKLY_REPORTS")};
        let type;
        if (this.state.currentCommodityTab === "daily") {
            type = "DAILY_COMMODITY_REPORTS";
            fetch(COMMODITY_RESEARCH+"?offset=0&limit=10&reportType="+type+"&fromDate="+time+"", requestOptions)
                .then(response => response.json())
                .then(result => {
                        this.setState({commodityCardData:result.data})              
                })
                .catch(error => console.log('error', error));
        } 
        else if (this.state.currentCommodityTab === "seasonal") {
            type = "SEASONAL_COMMODITY_REPORTS";
            fetch(COMMODITY_RESEARCH+"?offset=0&limit=10&reportType="+type+"&fromDate="+time+"&harvestProcess=POST_HARVEST", requestOptions)
                .then(response => response.json())
                .then(result => {
                        this.setState({seasonalCommodityCardData:result.data})
                })
                .catch(error => console.log('error', error));
        } else {
            type = "WEEKLY_REPORTS";
            fetch(GET_WEEKLY_REPORT+"/get?offset=0&limit=10&fromDate="+time+"", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (this.state.currentCommodityTab === "daily") {
                    this.setState({commodityCardData:result.data})
                } 
                else {
                    this.setState({weeklyCommodityCardData:result.data})
                }                 
            })
            .catch(error => console.log('error', error));
        }  


       
       
    }
    render()
    {
    return (
        <div className='commodityReaserachh'>
            <div className='smallBox mt-xl-5'>
                <div class="small-box  yellow mt-2" style={{borderRadius:"0px 5px 5px 0px"}}>
                </div>
                <p className='mx-3 big mt-1 ' style={{ fontWeight: '600',fontSize:"17pt",fontFamily:'MetropolisRegular'}}>Commodity Research</p>
                <select onChange={(e)=>{this.commodityFilters(e)}} 
                    id="timeSelct" 
                    class="form-select mx-2" 
                    placeholder='Time :' 
                    aria-label="Default select example" 
                    style={{ height: '10%', color: '#000', width: '17%', paddingTop: '10px', marginLeft: '5px' }}
                    ref={this.selectRef}
                >
                    <option selected="selected" value="1year">Time : Last 1 Year</option>
                    <option value="1Month">Time : Last Month</option>
                    <option value="15days">Time : Last 15 Days</option>
                    <option value="3Month">Time : Last 3 Months</option>
                    <option value="6Month">Time : Last 6 Months</option>
                </select>
            </div>
            <div className='container commodityBody mt-4'>
                <div>
                    <div class="tabs">
                        <nav class="navbar navbar-css">

                        <ul class="nav nav-tabs navbar-nav  " id="myTab0" role="tablist" style = {{  marginLeft: '16px'}} >
                            <div class = "borderless" > 
              <li class="nav-item active" role="presentation" 
              >
                <button
                onClick={()=>this.applyBorder('home-tab0')}
                class="nav-link daily-seasonal-tab active"
                id="home-tab0"
                data-bs-toggle="tab"
                data-bs-target="#home0"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                style={{
                    // borderColor: "grey",
                    paddingLeft: "0px",
                    textTransform: "capitalize",
                    fontSize: "17.5px",
                    borderWidth : '0px',
                    borderBottom: '2px solid black',
                    marginRight: '31px',
                    paddingBottom: '11px'
                }}
                >
                  <span>Daily Report</span>
                </button>
              </li>
                    </div>
              <li class="nav-item" role="presentation">
                <button
                  onClick={()=>this.applySecondBorder('profile-tab0')}
                  class="nav-link seasonal-report-css daily-seasonal-tab"
                  id="profile-tab0"
                  data-bs-toggle="tab"
                  data-bs-target="#profile0"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="true"
                  style={{
                    // borderColor: "grey",
                    paddingLeft: "0px",
                    textTransform: "capitalize",
                    fontSize: "17.5px",
                    borderWidth : '0px',
                    marginRight: '31px',
                    paddingBottom: '11px'
                  }}
                >
                  <span > Seasonal Report  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={()=>this.applyThirdBorder('contact-tab0')}
                  class="nav-link"
                  id="contact-tab0"
                  data-bs-toggle="tab"
                  data-bs-target="#contact0"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="true"
                  style={{
                    // borderColor: "grey",
                    paddingLeft: "0px",
                    textTransform: "capitalize",
                    fontSize: "17.5px",
                    borderWidth : '0px',
                    paddingBottom: '11px'
                  }}
                >
                <span> Weekly Report  </span>
                </button>
              </li>
            </ul>
            </nav>
                        {/* <div className='clearfix'></div>
                        <div class="slider" style={{marginLeft:"15px"}}>
                            <div class="indicator" style={{width:"100px"}}></div>
                        </div> */}
                        <div class="content">
                        <div class="tab-content " id="myTabContent0">

                            <div  class="tab-pane fade show active"
                                id="home0"
                                role="tabpanel"
                                aria-labelledby="home-tab0">
                                <p className='text mx-4 text-css-1'>Reports available for commodities:</p>
                                <div className='container-fluid'>
                                   
                                    <div className='row dailyCards' style={{width:"110%", marginLeft: '2px'}}>
                                        {
                                            this.state.commodityCardData && this.state.commodityCardData.map((item)=>(
                                                
                                                <div class="box col-md-2 dailyBoxx" onClick={() => console.log('clicked123')}>
                                                    <div className='ImageTextDiv'>
                                                        <img src={item.imagePath} alt="" className='commodityImage' />
                                                        <a href={item.fileLocation} target="_blank" style={{textDecoration:"none",color:"#000",fontWeight:"500"}}><span className='mt-3 ellipsis' title={item.commodityName} style={{display:"block",fontWeight:"500",fontSize: "16px"}}>{item.commodityName}</span></a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                            
                                    <div className='commoditybtn mt-3'>
                                        <button class="btn btn-primary" onClick={()=>this.viewAllDailyCommodity()}>View More</button>
                                    </div>
                                </div>
                            </div>
                            <div  class="tab-pane fade "
      role="tabpanel"
      aria-labelledby="home-tab0" id = "profile0">
                                        {/* <p className='text mx-4'>Reports available for commodities:</p> */}

                                <div className='container'>
                                    <div className='row dailyCards' style={{width:"110%"}}>
                                            {
                                                this.state.seasonalCommodityCardData && this.state.seasonalCommodityCardData.map((item)=>(
                                                   <div class="box col-md-2" onClick={() => console.log('clicked123')}>
                                                        <div className='ImageTextDiv'>
                                                            <img src={item.imagePath} alt="" className='commodityImage' />
                                                            <a href={item.fileLocation} target="_blank" style={{textDecoration:"none",color:"#000",fontWeight:"500"}}><span className='mt-3 ellipsis' title={item.commodityName} style={{display:"block",fontWeight:"500",fontSize: "16px"}}>{item.commodityName}</span></a>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                    </div>
                                    <div className='commoditybtn mt-3'>
                                        <button class="btn btn-primary" onClick={()=>this.viewAllDailyCommodity()}>View More</button>
                                    </div>
                                </div>
                            </div>
                            <div  class="tab-pane fade "
      role="tabpanel"
      aria-labelledby="home-tab0" id = "contact0">
                                        {/* <p className='text mx-4'>Reports available for commodities:</p> */}

                               <div className='container'>
                                {
                                    console.log("Weekly",this.state.weeklyCommodityCardData)
                                } 
                                    <div className='row dailyCards' style={{width:"110%"}}>
                                    {
                                            this.state.weeklyCommodityCardData && this.state.weeklyCommodityCardData.map((item)=>(
                                                <div class="box col-md-2" onClick={() => console.log('clicked123')}>
                                                    <div className='ImageTextDiv'>
                                                        <img src={item.commodityImagePath} alt="" className='commodityImage' />
                                                        <a href={item.fileLocation} target="_blank" style={{textDecoration:"none",color:"#000",fontWeight:"500"}}><span className='mt-3 ellipsis' title={item.commodityName} style={{display:"block",fontWeight:"500",fontSize: "16px"}}>{item.commodityName}</span></a>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                    </div>
                                    <div className='commoditybtn mt-3'>
                                        <button class="btn btn-primary" onClick={()=>this.viewAllDailyCommodity()}>View More</button>
                                    </div>
                                </div>
                            </div>
                            <section>
                            
                            </section>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>

    )
    }
}

export default EmandiCommodity  