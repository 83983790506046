import React from 'react'
import '../style/Header.css' 
import profilePic from '../Images/profilePic.PNG';
import dropdownIcon from '../../../assets/icons/dropdown_icon.png';

function Admin() {
  return (
    <div className='row topheader'>
        <div className='col-md-6 col-sm-6 col-md-6 admin' style = {{ marginLeft: '900px', 
    width: '22%'
      }}>
            <img src={profilePic} className="pic" />
            <span className='headerLabel'>Admin</span>
        </div>
    </div>
  )
}

export default Admin