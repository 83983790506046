import React, { Component } from 'react';
import './style.scss';
import CustomStepper from '../../components/CustomStepsMenu';
import CustomButton from '../../components/CustomButton';


class LoginStatus extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 1
    };
  }

  handleClick(clickType) {
    const { currentStep } = this.state;
    let newStep = currentStep;
    clickType === "next" ? newStep++ : newStep--;

    if (newStep > 0 && newStep <= 5) {
      this.setState({
        currentStep: this.props.currentStep
      });
    }
  }

  render() {
    const { currentStep } = this.state;

    return (
      <>


        <div className="stepper-container-horizontal">
          <CustomStepper
            direction="horizontal"
            currentStepNumber={this.props.currentStep - 1}
            steps={stepsArray}
            stepColor=" #7DB851"
          />
        </div>

        <div className="buttons-container">
          {/* <CustomButton onClick={() => this.handleClick("next")} label="CONTINUE" className="continue-btn" /> */}
        </div>
      </>
    );
  }
}

const stepsArray = [
  "Mobile verification",
  "Personal Info",
  "KYC Information"
];



export default LoginStatus;