import React, { Component } from 'react'
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomButton from '../../../components/CustomButton';
class PopUp extends Component {
    render() {
        const { visible, onHide, onAccept, onHidePopup, popupData,filelocation } = this.props;
        return (
            <div className="emandiTV-dailog">
                <div className="buy-now-dialog">
                    <CustomDialog visible={visible} footer={() => { }} header={<p>{popupData}</p>} onHide={onHidePopup} onCancel={onHidePopup} width="60vw">
                       
                            <div className="buynow-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                {/* <div className="header-cls">
                                <div className="first-div">Buy Now Pay Later</div>
                            </div> */}
                            {filelocation?<iframe src={`https://docs.google.com/gview?url=${filelocation}&embedded=true`} style={{"width":"70em","height":"500px"}} frameborder="0"></iframe>:"Loading"}
                            {/* <iframe src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${filelocation}#toolbar=0&scrollbar=0`} frameBorder="0" scrolling="auto" height="500px" width="600px"/> */}
                                <div className="message-us">
                                    <div className="btns-cls">
                                        <CustomButton label="Cancel" className="cancel" config={{ onClick: onHidePopup }} />
                                        <CustomButton label="OK" className="send" config={{ onClick: onAccept }} />
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default PopUp