import React, { useEffect, useRef, useState } from 'react';
import './EResearch';
import dropdownArrow from '../../../assets/images/dropdownArrow.svg';
import compareIcon from '../../../assets/images/compareIcon.svg';
import circleCross from '../../../assets/images/circle-cross.svg';
import searchIcon from '../../../assets/images/searchIconMarketPrice.svg';
import crossinput from '../../../assets/images/crossInput.svg';
import squareInfoIcon from '../../../assets/images/square-i-icon.svg';
import greenLineChartIcon from '../../../assets/images/green-lineChart-icon.svg';
import normalLineChartIcon from '../../../assets/images/normal-lineChart-icon.svg';
import greenCandleChartIcon from '../../../assets/images/green-candleChart-icon.svg';
import normalCandleChartIcon from '../../../assets/images/normal-candleChart-icon.svg';
import infoCross from '../../../assets/images/infoCross.svg';
import yelloLine from '../../../assets/images/yellow-line.svg';
import ruppeeBold from '../../../assets/images/ruppee-bold-hindi.png';
import upChangeArrow from '../../../assets/images/UpChangeArrow.svg';
import downChangeArrow from '../../../assets/images/downChangeArrow.png';
import popupInnerCompareHeaderCross from '../../../assets/images/popup-header-cross.svg';
import addIcon from '../../../assets/images/add-icon.svg';

import dot from '../../../assets/images/dot.svg'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { fireAjaxRequest } from '../../../utils/Utility';
import { COMMODITY_LIST_API, COMMODITY_LIST_ERESEARCH, MANDI_LIST_FOR_COMMODITY_ERESEARCH, MARKETPRICE_CANDLE_CHART_DATA, MARKETPRICE_LINE_CHART_DATA, TODAYS_PRICE, UP_DOWN_CHANGE } from '../../../utils/Api';
import { toast } from 'react-toastify';
import LineChart from './LineChartMarketPrice'
import CandleChart from './CandleChartMarketPrice'
import CandlestickChart from './CandleChartMarketPrice';
import CompareLineChart from './CompareLineChart';
 

function MarketPrice() {

    const [commodityList, setCommodityList] = useState([]);
    const [selected_commodity, setSelected_commodity] = useState('');
    const [selected_commodity_id, setSelected_commodity_id] = useState('');
    const [commodityModal, setCommodityModal] = useState(false);
    const [compareModal, setCompareModal] = useState(false);
    const [innerCompareModal, setInnerCompareModal] = useState(false);

    const [mandiList, setMandiList] = useState([]);
    const [selected_mandi, setSelected_mandi] = useState('');
    const [selected_mandi_id, setSelected_mandi_id] = useState('');

    const [lineChartData, setLineChartData] = useState([]);
    const [selected_lineChart_timePeriod, setSelected_lineChart_timePeriod] = useState('month');

    const [candleChartData, setCandleChartData] = useState([]);
    const [selected_candleChart_timePeriod, setSelected_candleChart_timePeriod] = useState('year');

    const [lineChartCompareData, setlLineChartCompareData] = useState([]);
    const [selected_lineChart_compare_timePeriod, setSelected_lineChart_compare_timePeriod] = useState('month');

    const [typeOfChart, setTypeOfChart] = useState('line');
    const [todaysPriceList, setTodaysPriceList] = useState([]);
    const [MarketPriceUnit, setMarketPriceUnit] = useState('');

    const [compareMandisList, setCompareMandisList] = useState([]);
    const [todaysPriceCompareList, setTodaysPriceCompareList] = useState([]);
    const [updownChangeForYearAndFiveData,setUpdownChangeForYearAndFiveData]= useState([]);
    const [colorData, setColorData] = useState([]);


    const [infoPopup, setInfoPopup] = useState(false);
    const [infoPopupText, setInfoPopupText] = useState('');
    
    const userInfo = sessionStorage.getItem('userInfo');
    const userInfoObj = JSON.parse(userInfo);

    const handleCommodityModal = (e) => {
        if (e) 
        {
            setCommodityModal(!commodityModal);
            setCompareMandisList([]);
        }
        return;
    };  
    const handleCompareModalOpen = (e) => {
        if (e) 
        {
            setCompareModal(true);
            setCompareMandisList([]);
        }
        return;
    }
    const handleCompareModalClose = (e) => {
        if (e) 
        {
            setCompareModal(false);
            setCompareMandisList([]);
            // window.location.reload();
        }
        return;
    }
    const handleAddMoreCompareMandi = (e) => {
        if (e && compareMandisList.length < mandiList.length) 
        {
            setCompareModal(!compareModal);
            // setCompareMandisList([]);
            if(compareMandisList.length === 0){
                setTodaysPriceCompareList([]);
                setlLineChartCompareData([]);
            }
        }
        return;
    }
    const handleCompareCommodityChange = (e) => {
        setCompareModal(true);
        if(compareMandisList.length === 0){
            setTodaysPriceCompareList([]);
            setlLineChartCompareData([]);
        }
    }
    const handleInnerCompareModal = (e) => {
        if (e && compareMandisList.length>1) {
          setInnerCompareModal(true);
          setCompareModal(false);
          getLineChartCompareData();
        }
        return;
    };
    const handleInnerCompareModalClose = (e) => {
        if (e) 
        {
            setInnerCompareModal(false);
            // window.location.reload();
            // setSelected_commodity(commodityList[0].name);
            // setSelected_commodity_id(commodityList[0].id);
        }
        return;
    }
    const handleFocus = (e) =>{
            document.getElementById('search1').style.display="none"
            document.getElementById('afterInput').style.display="flex"
    }
    const handleCommodityInputCross = (e) =>{
        document.getElementById('search1').style.display="flex"
        document.getElementById('afterInput').style.display="none"
        document.getElementById('commodity-search-input').value= ""
        commodity_searching_clear()
    }
    const getAllCommodityList = async ()=>{
        try {
            const resp = await fireAjaxRequest(`${COMMODITY_LIST_ERESEARCH}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userInfoObj.token}`,
                    "Content-Type": "application/json",
                },
            }
            );
            if (resp) {
                setCommodityList(resp.listOfCommodities);
                setSelected_commodity(resp.listOfCommodities[0].name);
                setSelected_commodity_id(resp.listOfCommodities[0].id);
            } else {
            toast.error("Failed to load Commodity List !");
            }
        } catch (err) {
            setCommodityList([]);
        }
    }
    const getMandiListForCommodity = async () =>{
        
        try {
            const resp = await fireAjaxRequest(`${MANDI_LIST_FOR_COMMODITY_ERESEARCH}?commodityId=${selected_commodity_id}`
,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userInfoObj.token}`,
                    "Content-Type": "application/json",
                },
            }
            );
            if (resp) {
                setMandiList(resp.listOfMandis);
                setSelected_mandi(resp.listOfMandis[0].name);
                setSelected_mandi_id(resp.listOfMandis[0].id);
            } else {
            toast.error("Failed to load Commodity List !");
            }
        } catch (err) {
            setCommodityList([]);
        }
    }
    const handleCommoditySelect = (name, id) =>{
        setSelected_commodity_id(id);
        setSelected_commodity(name); 
        setCommodityModal(false);
    }
    const commodity_searching = (e) =>{
        const value= e.target.value.toLowerCase().trim();
        const commodityDropdownItems = document.querySelectorAll('.marketPrice-dropdown-item');
        commodityDropdownItems.forEach(function (item) {
            const label = item.querySelector('.marketprice-commodityName');
            const itemName = item.textContent.toLowerCase();
            const itemMatches = itemName.includes(value);
    
            item.style.display = itemMatches ? 'flex' : 'none';
        });
    }
    const commodity_searching_clear = () =>{
        const commodityDropdownItems = document.querySelectorAll('.marketPrice-dropdown-item');
        commodityDropdownItems.forEach(function (item) {
            item.style.display = 'flex' ;
        });
    }
    const handleMandiChange = (name, id) =>{
        setSelected_mandi_id(id);
        setSelected_mandi(name); 
    }
    const handleLineChartTimePeriodChange = (time) =>{
        setSelected_lineChart_timePeriod(time);
    }
    const handleCandleChartTimePeriodChange = (time) =>{
        setSelected_candleChart_timePeriod(time);
    }
    const getLineChartData = async () =>{
        
        if(selected_commodity_id && selected_commodity_id!='' && selected_mandi_id && selected_mandi_id !=='' && selected_lineChart_timePeriod && selected_lineChart_timePeriod!=='')
        {
            try {
                const resp = await fireAjaxRequest(`${MARKETPRICE_LINE_CHART_DATA}?commodityId=${selected_commodity_id}&mandiId=${selected_mandi_id}&periodLength=${selected_lineChart_timePeriod}`
    ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    setLineChartData(resp.listOfPrices);
                } else {
                toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setLineChartData([]);
            }
        }
    }
    const getCandleChartData = async () =>{
        
        if(selected_commodity_id && selected_commodity_id!='' && selected_mandi_id && selected_mandi_id !=='' && selected_candleChart_timePeriod && selected_candleChart_timePeriod!=='')
        {
            try {
                const resp = await fireAjaxRequest(`${MARKETPRICE_CANDLE_CHART_DATA}?commodityId=${selected_commodity_id}&mandiId=${selected_mandi_id}&periodLength=${selected_candleChart_timePeriod}`
    ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    setCandleChartData(resp.listOfPrices);
                } else {
                toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setCandleChartData([]);
            }
        }
    }
    const getTodaysPrice = async () => {
        
        if(selected_commodity_id && selected_commodity_id!='' && selected_mandi_id && selected_mandi_id !=='')
        {
            try {
                const resp = await fireAjaxRequest(`${TODAYS_PRICE}?commodityId=${selected_commodity_id}&mandiId=${selected_mandi_id}`
    ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    setTodaysPriceList(resp.listOfPrices);
                    if(resp.listOfPrices[0].unit === "quintal")
                    {
                        setMarketPriceUnit('QTL')
                    }
                    else if(resp.listOfPrices[0].unit === "bale"){
                        setMarketPriceUnit('bale')
                    }
                    else if(resp.listOfPrices[0].unit === "10kg"){
                        setMarketPriceUnit('10kg')
                    }
                    else if(resp.listOfPrices[0].unit === "tonne"){
                        setMarketPriceUnit('T')
                    }
                } else {
                toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setTodaysPriceList([]);
            }
        }
    }
    const handleCompareMandisSelect = (mandiName, mandiId) =>{
        
        if (compareMandisList.find(obj => obj.id === mandiId)) {
            const updatedArray = compareMandisList.filter(obj => obj.id !== mandiId);
            setCompareMandisList(updatedArray);
          } else {
            const newObj = { id: mandiId, name: mandiName };
            const updatedArray = [...compareMandisList, newObj];
            setCompareMandisList(updatedArray);
          }
    }
    const getLineChartCompareData = async () =>{
        if(selected_commodity_id && selected_commodity_id!='' && compareMandisList && compareMandisList.length !==0 && selected_lineChart_compare_timePeriod && selected_lineChart_compare_timePeriod!=='')
        {
            try {
                const arrayOfIds = compareMandisList.map(obj => obj.id);
                const resp = await fireAjaxRequest(`${MARKETPRICE_LINE_CHART_DATA}?commodityId=${selected_commodity_id}&mandiId=${arrayOfIds}&periodLength=${selected_lineChart_compare_timePeriod}`
    ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    setlLineChartCompareData(resp.listOfPrices);
                    getTodaysComparePrice();
                } else {
                toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setlLineChartCompareData([]);
            }
        }
    }
    const getTodaysComparePrice = async () => {
        if(selected_commodity_id && selected_commodity_id!='' && compareMandisList &&  compareMandisList.length !==0)
        {
            try {
                console.log(lineChartCompareData, lineChartCompareData[0].values[0].date)
                const arrayOfIds = compareMandisList.map(obj => obj.id);
                const resp = await fireAjaxRequest(`${TODAYS_PRICE}?commodityId=${selected_commodity_id}&mandiId=${arrayOfIds}&currentDate=${lineChartCompareData[0].values[0].date}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    setTodaysPriceCompareList(resp.listOfPrices);
                } else {
                    toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setTodaysPriceCompareList([]);
            }
        }
    }
    const getTodaysComparePriceForYearAndFive = async () => {
        if(selected_commodity_id && selected_commodity_id!='' && compareMandisList &&  compareMandisList.length !==0 && selected_lineChart_compare_timePeriod)
        {
            try {
                const arrayOfIds = compareMandisList.map(obj => obj.id);
                const resp = await fireAjaxRequest(`${UP_DOWN_CHANGE}?commodityId=${selected_commodity_id}&mandiId=${arrayOfIds}&periodLength=${selected_lineChart_compare_timePeriod}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    setUpdownChangeForYearAndFiveData(resp.listOfPrices);
                } else {
                    toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setUpdownChangeForYearAndFiveData([]);
            }
        }
    }
    const handleLineChartCompareTimePeriodChange = (time) =>{
        setSelected_lineChart_compare_timePeriod(time);
    }
    const handleInfoButtonClick = () =>{
        if(typeOfChart==="line"){
            if(selected_lineChart_timePeriod === 'week') {
                setInfoPopupText('Showing Closing Prices for this week')
            }
            if(selected_lineChart_timePeriod === 'month') {
                setInfoPopupText('Showing Closing Prices for this month')
            }
            if(selected_lineChart_timePeriod === 'year') {
                setInfoPopupText('Showing Closing Prices for last trading days of past 12 months')
            }
            if(selected_lineChart_timePeriod === 'fiveyears') {
                setInfoPopupText('Showing Closing Prices for last trading days of half-year for the past 5 years')
            }
        }
        else if(typeOfChart==="candle"){
            if(selected_candleChart_timePeriod === 'month') {
                setInfoPopupText('Showing Opening, Maximum, Minimum and Closing Prices of all the weeks within a month')
            }
            if(selected_candleChart_timePeriod === 'year') {
                setInfoPopupText('Showing Opening, Maximum, Minimum and Closing Prices of all 12 months')
            }
            if(selected_candleChart_timePeriod === 'fiveyears') {
                setInfoPopupText('Showing Opening, Maximum, Minimum and Closing Prices on harlf yearly basis. First half of the year: 01 Jan to 30th June, Second half of the year: 01st July to 31st Dec')
            }
        }
        setInfoPopup(true);

    }
    const handleInfoPopupCrossClick = () =>{
        setInfoPopup(false);
    }
    const getColorData = () =>{
        const colorData = [];
        const colorList= ["#FF9431", "#5263FF", "#B845FF", "#5ED700", "#FF5794"];
        var colorsCopy = colorList.slice();

        if(compareMandisList  && compareMandisList.length > 0){
            compareMandisList.map((mandiData, index) => {
                const { name } = mandiData;
                var randomIndex = Math.floor(Math.random() * colorsCopy.length);
                var selectedColor = colorsCopy.splice(randomIndex, 1)[0];
                colorData.push({col : selectedColor ,  mandi: name});
            })
            console.log(colorData)
            setColorData(colorData);
        }
    }
    const handleDataPointClick = async (currentDate) => {
        if(selected_commodity_id && selected_commodity_id!='' && compareMandisList &&  compareMandisList.length !==0)
        {
            try {
                const arrayOfIds = compareMandisList.map(obj => obj.id);
                const resp = await fireAjaxRequest(`${TODAYS_PRICE}?commodityId=${selected_commodity_id}&mandiId=${arrayOfIds}&currentDate=${currentDate}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${userInfoObj.token}`,
                        "Content-Type": "application/json",
                    },
                }
                );
                if (resp) {
                    if (selected_lineChart_compare_timePeriod === "year" || selected_lineChart_compare_timePeriod === "fiveyears") {
                        let date = currentDate;
                        const updatedList = resp.listOfPrices.map((todayPriceObj) => {
                          const mandiname = todayPriceObj.mandiName;
                          const obj = updownChangeForYearAndFiveData.find(
                            (obj) => obj.mandiName === mandiname
                          );
                          if (obj) {
                            const value = obj.values.find((value) => value.date === date);
                            if (value) {
                              return { ...todayPriceObj, upDownChange: value.upDownChange };
                            } else {
                              return { ...todayPriceObj, upDownChange: "NA", todayPrice: "NA" };
                            }
                          }
                          return todayPriceObj;
                        });
                        setTodaysPriceCompareList(updatedList);
                    }
                    else{
                        setTodaysPriceCompareList(resp.listOfPrices);
                    }
                } else {
                    toast.error("Failed to load Commodity List !");
                }
            } catch (err) {
                setTodaysPriceCompareList([]);
            }
        }
    }

    useEffect(()=>{
        getAllCommodityList();
    },[])

    useEffect(()=>{
        if(commodityList.length !==0)
            getMandiListForCommodity();
    },[selected_commodity_id])

    useEffect(()=>{
        getTodaysPrice();
    },[selected_commodity_id, selected_mandi_id])

    useEffect(()=>{
        getLineChartData();
    },[selected_commodity_id, selected_mandi_id, selected_lineChart_timePeriod])

    useEffect(()=>{
        getCandleChartData();
    },[selected_commodity_id, selected_mandi_id, selected_candleChart_timePeriod])

    useEffect(()=>{
        getLineChartCompareData();
    },[selected_commodity_id, compareMandisList, selected_lineChart_compare_timePeriod])

    useEffect(()=>{
        setSelected_lineChart_compare_timePeriod("month")
    },[selected_commodity_id, compareMandisList])

    useEffect(()=>{
        setInfoPopup(false);
    },[selected_commodity_id , selected_mandi_id,selected_lineChart_timePeriod, selected_candleChart_timePeriod, commodityModal, compareModal, typeOfChart])

    useEffect(()=>{
        if(compareMandisList.length !==0)
             getColorData();
    },[compareMandisList, lineChartCompareData])

    useEffect(()=>{
        if(selected_lineChart_compare_timePeriod === "year" || selected_lineChart_compare_timePeriod=== "fiveyears"){
            getTodaysComparePriceForYearAndFive();
        }  
    },[selected_lineChart_compare_timePeriod,lineChartCompareData])

    useEffect(()=>{
        let date = lineChartCompareData[0]?.values[0]?.date;
        const updatedList = todaysPriceCompareList.map(todayPriceObj => {
        const mandiname = todayPriceObj.mandiName;
        const obj = updownChangeForYearAndFiveData.find(obj => obj.mandiName === mandiname);
        if (obj) {
            const value = obj.values.find(value => value.date === date);
            if (value) {
            return { ...todayPriceObj, upDownChange: value.upDownChange };
            } else {
            return { ...todayPriceObj, upDownChange: "NA", todayPrice: "NA" };
            }
        }
        return todayPriceObj;
        });
        setTodaysPriceCompareList(updatedList);
    },[updownChangeForYearAndFiveData])
    
  return (
    <div className='marketPrice-container'>
        <div className='eResearch-heading marketPrice-heading'>
            <div class="small-box  yellow mt-2" style={{borderRadius:"0px 5px 5px 0px"}}></div>
            <p className='mx-3 big mt-1 ' style={{ fontWeight: '600',fontSize:"17pt",fontFamily:'MetropolisRegular'}}>Market Prices</p>
        </div>
        <div className="marketPrice-btn-container">
            <button className='marketPrice-commodityBtn' onClick={handleCommodityModal}>
                <span>Commodity:</span> 
                <span style={{color: "#04B23D"}}>{selected_commodity}</span> 
                <img src={dropdownArrow}></img>
            </button>
            <button className='marketPrice-compareBtn' onClick={handleCompareModalOpen}>
                <span>Compare</span> 
                <img src={compareIcon}></img>
            </button>
            <Popup open={commodityModal} onClose={handleCommodityModal} closeOnDocumentClick={false}>
                <div className="modal-header marketPrice-commodity-modal-header">
                   <img src={circleCross} className='circle-cross' onClick={handleCommodityModal}></img>
                </div>
                <div className="modal-content marketPrice-commodity-modal-content">
                    <div className='search-div-marketPrice'>
                        <img src={searchIcon} alt="Search Icon" id="search1" className="searchIconFront"/>
                        <input type="text" placeholder='search commodity' id="commodity-search-input" className="marketPrice-commodity-search-input" onFocus={handleFocus} onChange={(e)=>{commodity_searching(e)}}/>
                        <div className='afterInputDiv' id="afterInput">
                            <img src={searchIcon} alt="Search Icon" className="searchIconBack" />
                            <img src={crossinput} alt="Cross Icon" className="crossIconBack" onClick={handleCommodityInputCross}/>
                        </div>
                    </div>
                    <span className='small-heading-commodities'>COMMODITIES</span>
                    <div className='scrollable-commodities-list-div'>
                        {
                            commodityList.map(function (commodity) {
                                return <div class="dropdown-itemm marketPrice-dropdown-item" onClick={()=>{handleCommoditySelect(commodity.name, commodity.id)}}>
                                        <label for="${commodity.id}" className={`marketprice-commodityName ${commodity.id===selected_commodity_id? 'marketPrice-active-commodity' : ''}`}>{commodity.name}</label>
                                        <div className={commodity.id===selected_commodity_id? 'show-dot-commodity' : 'hide-dot-commodity'}>
                                            <img src={dot}></img>
                                            <span className='text-selected'>selected</span>
                                        </div>
                                    </div>
                            })
                        }
                    </div>
                </div>
            </Popup>
            <Popup open={compareModal} onClose={innerCompareModal ? handleInnerCompareModal :handleCompareModalClose} closeOnDocumentClick={false}>
                <div className="modal-header marketPrice-compare-modal-header">
                   <img src={circleCross} className='circle-cross' onClick={innerCompareModal ? handleInnerCompareModal :handleCompareModalClose}></img>
                </div>
                <div className="modal-content marketPrice-compare-modal-content">
                    <p className='mandis-heading'>Select the Mandis you wish to compare:</p>
                    <div className='mandis-for-commodity-selected'>
                    {
                        mandiList.map((mandi)=>{
                            return <div className={`mandi-name-to-compare ${compareMandisList.find(obj => obj.id === mandi.id) ? "green-bg-compare-mandi" : ""}`} onClick={()=>{handleCompareMandisSelect(mandi.name, mandi.id)}}>{mandi.name}</div>
                        })
                    }
                </div>
                <p className='commodity-heading'>Commodity Selected:</p>
                <div className='selected-commodity-change'>
                    <span className='commodity-selected'>{selected_commodity}</span>
                    <span className='change-btn' onClick={handleCommodityModal}>Change</span>
                </div>
                <button className={`compare-inner-btn ${compareMandisList.length>1 ? "green-compare-inner-btn" : ""}`} onClick={handleInnerCompareModal}>Compare</button>
                </div>
            </Popup>
            <Popup open={innerCompareModal} onClose={handleInnerCompareModal} closeOnDocumentClick={false} className="wider-popup">
                <div className="modal-header marketPrice-compare-modal-header">
                   <img src={popupInnerCompareHeaderCross} className='inner-compare-popup-header-cross' onClick={handleInnerCompareModalClose}></img>
                </div>
                <div className='comparison-for'>
                    <div className='yellow-vertical-line'></div>
                    <span className='comaperison-heading' onClick={handleCompareCommodityChange}><span>Comparison for</span><span className='commodity-name-comparison-for'>{selected_commodity}</span></span>
                    <img src={dropdownArrow} style={{cursor : "pointer"}} onClick={handleCompareCommodityChange}></img>
                </div>
                <p className='selcted-mandis-label'>Selected Mandis:</p>
                <div className='selected-mandis-for-innerComparison'>
                    {
                        compareMandisList.map((mandi)=>{
                            return <div className="green-bg-selected-compare-mandi">{mandi.name}</div>
                        })
                    }
                    <div className="addMore-compare-mandi">
                        <span className='addMore-text' onClick={handleAddMoreCompareMandi}>Add more</span>
                        <img src={addIcon}></img>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table mandi-table">
                    <tbody>
                        { 
                            todaysPriceCompareList.map((obj, idx) => {
                                const colorObject = colorData.find((colorObj) => colorObj.mandi === obj.mandiName);
                                const backgroundColor = colorObject ? colorObject.col : 'black';
                                return (
                                  <tr>
                                    <td className='mandi-name-in-table'>
                                      <div className='dot-mandi-div'>
                                        <div className='color-circle' style={{ background: `${backgroundColor}` }}></div>
                                        <span>{obj.mandiName}</span>
                                      </div>
                                    </td>
                                    <td className='price-in-table'>{`${obj.todayPrice!== "NA" ? "₹" : ""}${obj.todayPrice}${obj.todayPrice!== "NA" ? `/${MarketPriceUnit}` : ""}`}</td>
                                    <td className={`updownChange-in-table ${obj.upDownChange.charAt(0) === '-' ? 'redUpdownChangeText' : 'greenUpdownChangeText'}`}>
                                      {`${obj.upDownChange !=="NA" ? (obj.upDownChange.charAt(0) === '-' ? '' : '+') : ""}${obj.upDownChange}  `}
                                      {obj.upDownChange !=="NA" ? (obj.upDownChange.charAt(0) === '-' ? <img className="updownChangeArrow" src={downChangeArrow} /> : <img className="updownChangeArrow" src={upChangeArrow} />) : ""}
                                    </td>
                                  </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className='marketprice-lineChart'>
                    <CompareLineChart listOfPrices={lineChartCompareData} 
                    colorData={colorData}
                    key1={selected_lineChart_compare_timePeriod}
                    key2= {selected_commodity_id}
                    key3={compareMandisList}
                    onDataPointClick={handleDataPointClick}
                    />
                    <div className='marketprice-lineChart-compare-timePeriods'>
                        <p className={`linechart-compare-timePeriod ${selected_lineChart_compare_timePeriod === "week" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartCompareTimePeriodChange('week')}}>1W</p>
                        <p className={`linechart-compare-timePeriod ${selected_lineChart_compare_timePeriod === "month" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartCompareTimePeriodChange('month')}}>1M</p>
                        <p className={`linechart-compare-timePeriod ${selected_lineChart_compare_timePeriod === "year" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartCompareTimePeriodChange('year')}}>1Y</p>
                        <p className={`linechart-compare-timePeriod ${selected_lineChart_compare_timePeriod === "fiveyears" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartCompareTimePeriodChange('fiveyears')}}>5Y</p>
                    </div>
                </div>
            </Popup>
        </div>
        <div className='marketPrice-tabs-container'>
            <div className="marketPrice-manidis-tabs">
                {
                    mandiList.map((mandi)=>{
                        return <div className={`marketPrice-tab ${mandi.id===selected_mandi_id? 'active-marketPrice-mandi-tab' : ''}`} onClick={()=>{handleMandiChange(mandi.name, mandi.id)}}>{mandi.name}</div>
                    })
                }
            </div>
            <div className='chart-types-div'>
                <img className="chart-type" src={squareInfoIcon} onClick={handleInfoButtonClick}></img>
                { typeOfChart==="line"? 
                    <img className="chart-type" src={greenLineChartIcon}></img>
                    :
                    <img className="chart-type" src={normalLineChartIcon} onClick={()=>{setTypeOfChart("line")}}></img>
                }
                {
                    typeOfChart==="candle"? 
                    <img className="chart-type" src={greenCandleChartIcon}></img>
                    :
                    <img className="chart-type" src={normalCandleChartIcon} onClick={()=>{setTypeOfChart("candle")}}></img>
                }
            </div>
        </div>
        <div className='marketPrice-lineChart-container'>
            <div className='todaysPrice-container'>
                <img className="yellowLine-todaysPrice" src={yelloLine}></img>
                <div className='text-todaysPrice'>
                    <span className="uppertext-todaysPrice"><img className="rupee-bold-img" src={ruppeeBold}></img>{`${todaysPriceList && todaysPriceList[0] && todaysPriceList[0].todayPrice}/${MarketPriceUnit ? MarketPriceUnit : ""}`}</span>
                    <span className={`lowertext-todaysPrice ${todaysPriceList && todaysPriceList[0] && todaysPriceList[0].upDownChange.charAt(0)=== '-' ? "redUpdownChangeText" : "greenUpdownChangeText"}`}>{`${todaysPriceList && todaysPriceList[0] && todaysPriceList[0].upDownChange.charAt(0)=== '-'?'' : '+'}${todaysPriceList && todaysPriceList[0] && todaysPriceList[0].upDownChange}  `}{todaysPriceList && todaysPriceList[0] && todaysPriceList[0].upDownChange.charAt(0)=== '-' ? <img className="updownChangeArrow" src={downChangeArrow}></img> :<img className="updownChangeArrow" src={upChangeArrow}></img>}<span className='text-today'>{`   |   Today`}</span></span>
                </div>
            </div>
            <div className='marketprice-lineChart'>
            {
                (lineChartData.length > 0 && typeOfChart === "line") ? 
                (
                    <LineChart listOfPrices={lineChartData} units={MarketPriceUnit} key1={selected_lineChart_timePeriod} key2={selected_mandi_id}/>
                )
                :
                (
                    (candleChartData.length > 0 && typeOfChart === "candle") ?
                    (
                        <CandlestickChart listOfPrices={candleChartData} units={MarketPriceUnit} key1={selected_candleChart_timePeriod} key2={selected_mandi_id}/>
                    ) 
                    :
                    (
                        <p>Loading Chart</p>
                    )
                )
            }
            {infoPopup ?  
                <div className="info-popup"><span>{infoPopupText}</span> <span onClick={handleInfoPopupCrossClick}><img className="infoCross" src={infoCross}></img></span></div> 
                : ""
            }
            </div>
            {
                typeOfChart === "line" ? 
                <div className='marketprice-lineChart-timePeriods'>
                    <p className={`linechart-timePeriod ${selected_lineChart_timePeriod === "week" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartTimePeriodChange('week')}}>1W</p>
                    <p className={`linechart-timePeriod ${selected_lineChart_timePeriod === "month" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartTimePeriodChange('month')}}>1M</p>
                    <p className={`linechart-timePeriod ${selected_lineChart_timePeriod === "year" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartTimePeriodChange('year')}}>1Y</p>
                    <p className={`linechart-timePeriod ${selected_lineChart_timePeriod === "fiveyears" ? 'active-lineChart-timePeriod' : ''}`} onClick={()=>{handleLineChartTimePeriodChange('fiveyears')}}>5Y</p>
                </div>
                :
                <div className='marketprice-candleChart-timePeriods'>
                    <p className={`candlechart-timePeriod ${selected_candleChart_timePeriod === "month" ? 'active-candleChart-timePeriod' : ''}`} onClick={()=>{handleCandleChartTimePeriodChange('month')}}>1M</p>
                    <p className={`candlechart-timePeriod ${selected_candleChart_timePeriod === "year" ? 'active-candleChart-timePeriod' : ''}`} onClick={()=>{handleCandleChartTimePeriodChange('year')}}>1Y</p>
                    <p className={`candlechart-timePeriod ${selected_candleChart_timePeriod === "fiveyears" ? 'active-candleChart-timePeriod' : ''}`} onClick={()=>{handleCandleChartTimePeriodChange('fiveyears')}}>5Y</p>
                </div>
            }
           
        </div>
    </div>
  )
}

export default MarketPrice