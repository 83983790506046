
const initialState = {
    // pinCodeDetails : null,
};

function configReducer(state = initialState, action) {
    switch (action.type) {
        // case "FETCH_PINCODE_DETAILS":
        //     return {
        //         ...state, pinCodeDetails: action.data
        //     }
        default:
            return state;
    }
}

export default configReducer;