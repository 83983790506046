import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import successImage from "../../../assets/images/success.png";
import errorImage from "../../../assets/images/error.png";


class TransactionStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        let { status, onHide, visible=false, onCancel, onAccept} = this.props;
        
        if (status){
            return (<div className="transaction-status">
                <CustomDialog className="transaction-status-successful" visible={false} onHide={onHide} btn1={"CLOSE"} btn2={"RETRY"} onAccept={onAccept} onCancel={onCancel}>
                    <img src={successImage} alt=""/>
                    <p className="status-msg">Congratulation ! transaction sucessfully completed.</p>
                    <p className="sub-text">Lorem ipsum dolor sit </p>
                    <p className="contend">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto culpa nam, corrupti harum aspernatur illo sunt sit perferendis odit deleniti dolorem quas. Quis amet praesentium odio, blanditiis deleniti molestiae suscipit.
                    Deleniti eius hic mollitia nobis adipisci numquam impedit ad? Sit voluptas ab alias! Laboriosam vel quia rem illo nam veniam, repellendus necessitatibus, obcaecati iste hic facere architecto, porro enim eligendi.</p>
                </CustomDialog>
            </div>);
        }else{
            return (<div className="transaction-status">
                <CustomDialog className="transaction-status-failed" visible={visible} onHide={onHide} btn1={"CLOSE"} btn2={"RETRY"} onAccept={""} onCancel={onCancel}>
                    <img src={errorImage} alt="" />
                    <p className="status-msg">We are sorry, transaction couldn't be completed.</p>
                    <p className="sub-text">Lorem ipsum dolor sit </p>
                    <p className="contend">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto culpa nam, corrupti harum aspernatur illo sunt sit perferendis odit deleniti dolorem quas. Quis amet praesentium odio, blanditiis deleniti molestiae suscipit.
                    Deleniti eius hic mollitia nobis adipisci numquam impedit ad? Sit voluptas ab alias! Laboriosam vel quia rem illo nam veniam, repellendus necessitatibus, obcaecati iste hic facere architecto, porro enim eligendi.</p>
                </CustomDialog>
            </div>);
        }
        
    }
}
 
export default TransactionStatusDialog;