import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import CustomInputText from "../../../components/CustomInputText";
import CustomButton from '../../../components/CustomButton';
class transferRequestOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.props.resendOTPTime !== 0) {
                // clearInterval(this.timer);
                this.props.OTPCounter();
            }
            // this.props.OTPCounter();
        }, 1000);
    }


    render() {
        let { visible, onHide, onAccept, onCancel, formData, handelInputChange, sendOTP,showwithdrawsubmitbutton } = this.props;
        console.log(showwithdrawsubmitbutton,"showwithdrawsubmitbutton")
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let phoneNo = userInfo && userInfo.user && userInfo.user.phoneNo;
        phoneNo = phoneNo && phoneNo.slice(6, 10);

        return (<div className="transferOTPDialog">
            <CustomDialog visible={visible} onHide={onHide} onAccept={onAccept} onCancel={onCancel} header={"Verification with OTP"}>
                <div className="bank-details-wrapper">
                    <p class="account-type">A Four digit OTP has been sent to ******{phoneNo}</p>
                    <CustomInputText label="OTP" name={"otp"} value={formData.otp} config={{
                        onChange: handelInputChange,
                        keyfilter: "num",
                        maxlength: 4
                    }} />
                    <p class="account-type">Resend OTP in {this.props.resendOTPTime} secs {this.props.resendOTPTime === 0 ? <span onClick={sendOTP} > Resend OTP</span> : ""}</p>
                    <p class="account-type">Attempt remaining: {this.props.OTPAttempltremaining}</p>
                </div>
                <div className="btns-cls">
                    <CustomButton label="Cancel" className="cancel" config={{ onClick: onHide }} />
                    {showwithdrawsubmitbutton? <CustomButton label="Submit" className="accept" config={{ onClick: onAccept }} />:""}
                   
                </div>

            </CustomDialog>
        </div>);
    }
}

export default transferRequestOTP;