  import React, { Component } from 'react'
  import List from './view/List'
  import { history, fireAjaxRequest } from "../../utils/Utility"
  import { UPLOAD_FILE, EMANDI_CASH,REUPLOAD_FILE } from '../../utils/Api'
  import { toast } from 'react-toastify';
  import CustomLoader from '../../components/CustomLoader';
  import './styles.scss'
  class index extends Component {
    constructor(props) {
      super(props)

      this.state = {
        currentPage: "busineesdetailspage",
        formData: {},
        enableContinuebutton: false,
        pancardFile: {},
        aadharCard: {},
        bankstatement: {},
        gstcertificate: {},
        gstreturn: {},
        financialdoc: {},
        businessPan: {},
        userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
        loading:false,
        showPopUp:false,
        viewdocumentpopup:false,
        checked: false,
      }
    }
    handelInputChange = (e) => {
      let { formData } = this.state
      formData[e.target.name] = e.target.value;
      this.setState({ formData: formData });
    }
    onTermsChecked=()=>{ 
      console.log(this.state.checked,"kldsfdlsfj")
      
    if(this.state.checked){
      this.setState({ checked: false });
      }
    else{
    this.setState({ checked: true });
    }
}
    
    onReupload=(prefix)=>{
      // 
    }
    onClickContinue = () => {
      let {pancardFile,bankstatement,aadharCard,gstcertificate}=this.state;
      this.setState({pancardFile:{},bankstatement:{},aadharCard:{},gstcertificate:{},pancard:"",bank:"",aadhar:"",gst:""})
      this.setState({ currentPage: "kycpage" })
    }
    onClickFile = (e, prefix) => {
    if(e.target.files[0].type=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.target.files[0].type =="video/mp4"){
      toast.error("Please Upload the correct file format")
    }else if(e.target.files[0].size>10485760){
      toast.error("Uploaded file is more than 10MB")
    }
    else{
      this.setState({loading:true});
      let formData = new FormData();

      formData.append("file", e.target.files[0]);
      formData.append("prefix", prefix);
      (async () => {
        try {
          const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
            method: 'POST',
            headers: {
              "Authorization": `Bearer ${this.state.userInfo.token}`
            },
            body: formData
          });
          if (resp && resp.filePath) {
            this.setState({loading:false});
            this.setState({ [e.target.name]: resp.filePath,filelocation:resp.filePath });
            if (prefix === "PAN") {
              this.setState({ pancardFile: e.target.files[0] })
            } else if (prefix === "AADHAR") {
              this.setState({ aadharCard: e.target.files[0] })
            } else if (prefix === "bank") {
              this.setState({ bankstatement: e.target.files[0] })
            } else if (prefix === "Gst") {
              this.setState({ gstcertificate: e.target.files[0] })
            } else if (prefix === "gstreturn") {
              this.setState({ gstreturn: e.target.files[0] })
            } else if (prefix === "financial") {
              this.setState({ financialdoc: e.target.files[0] })
            } else if (prefix === "businesspan") {
              this.setState({ businessPan: e.target.files[0] })
            }
          } else {
            this.setState({loading:false,[e.target.name]:''});
            toast.error("Failed to upload file !");
            if (prefix === "PAN") {
              this.setState({ pancardFile: "" })
            } else if (prefix === "AADHAR") {
              this.setState({ aadharCard: e.target.files[0] })
            }else if (prefix === "bank") {
              this.setState({ bankstatement: "" })
            } else if (prefix === "Gst") {
              this.setState({ gstcertificate: "" })
            } else if (prefix === "gstreturn") {
              this.setState({ gstreturn:"" })
            } else if (prefix === "financial") {
              this.setState({ financialdoc: "" })
            } else if (prefix === "businesspan") {
              this.setState({ businessPan: "" })
            }
          }

        } catch (err) {
        this.setState({loading:false,[e.target.name]:""});
          toast.error("Failed to upload file !");
          if (prefix === "PAN") {
            this.setState({ pancardFile: "" })
          }else if (prefix === "AADHAR") {
            this.setState({ aadharCard: e.target.files[0] })
          } else if (prefix === "bank") {
            this.setState({ bankstatement: "" })
          } else if (prefix === "Gst") {
            this.setState({ gstcertificate: "" })
          } else if (prefix === "gstreturn") {
            this.setState({ gstreturn:"" })
          } else if (prefix === "financial") {
            this.setState({ financialdoc: "" })
          } else if (prefix === "businesspan") {
            this.setState({ businessPan: "" })
          }
        }
      })();
   
    }
    }
    onClickKycContinue = () => {
      let { formData ,pancardFile,bankstatement,aadharCard,gstcertificate} = this.state;
      if(formData.type === 'Partner'){
        if(!pancardFile.name){
          toast.error('Please Upload PAN Card!')
        }
        else if(!aadharCard.name){
          toast.error('Please Upload Aadhar Card!')
        }
        else{
          this.setState({ currentPage: "cashlimitpage" })
          }
      }
      else{
        if(!pancardFile.name){
          toast.error('Please Upload PAN Card!')
        }
        else if(!gstcertificate.name){
          toast.error('Please Upload GST Statement!')
        }
        else{
          this.setState({ currentPage: "cashlimitpage" })
  
        }
      }
      
    }
    onClickcashlimitContinue = () => {
      this.setState({gstcertificate:{},gstreturn:{},financialdoc:{},businessPan:{},gst:"",gstretur:"",financial:"",businesPan:"",})
      let { formData } = this.state;
      if (formData.limit == "Cr") {
        // this.setState({ currentPage: "businessKycpage" })
        let { formData } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let params = {
    
          "traderId": userInfo.user.id,
          "name": userInfo.user.name,
          "contactNo": userInfo.user.phoneNo,
          "emailId": userInfo.user.email,
          "nameOfOrganization": formData.orgname,
          "typeOfOrganization":formData.type==="P"?"Private":formData.type=="L"?"LLP":"Partnership or Poprietorship",
          "docs": [],
          "limitApplied": formData.limit === 'lac'?"75 lac":"2 Cr",
          "statusOfRequest": "Under process",
          "assignedEmandiCashLimit": "0.00",
          "availableEmandiCashLimit": "0.00",
          "cibilConsent": "yes",
        };
        if(this.state.pancard){
          params.docs.push({
            "docType": "pan",
            "docPath": this.state.pancard
          })
        }
        if(this.state.aadhar){
          params.docs.push({
            "docType": "aadhar card",
            "docPath": this.state.aadhar
          })
        }
        if(this.state.bank){
          params.docs.push({
            "docType": "bank statement",
            "docPath": this.state.bank
          })
        }
        if(this.state.gst){
          params.docs.push({
            "docType": "gst certificate",
            "docPath": this.state.gst
          })
        }
        if(this.state.gstretur){
          params.docs.push({
            "docType": "gst returns",
            "docPath": this.state.gstretur
          })
        }
        if(this.state.financial){
          params.docs.push({
            "docType": "finalcial docs",
            "docPath": this.state.financial
          })
        }
        if(this.state.businesPan){
          params.docs.push({
            "docType": "business pan",
            "docPath": this.state.businesPan
          })
        }
        (async () => {
          this.setState({loading:true});
          try {
            const resp = await fireAjaxRequest(EMANDI_CASH, {
              method: 'POST',
              body: JSON.stringify(params),
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.userInfo.token}`
              }
            });
            this.setState({loading:false});
            if (resp) {
              console.log(resp.responseId,"repo")
              this.setState({currentPage:"sucessPage",responseId:resp.responseId,pancardFile:{},aadharCard:{},bankstatement:{},gstcertificate:{},gstreturn:{},financialdoc:{},businessPan:{}})
            }
    
          } catch (err) {
            this.setState({loading:false});
            if(err.code==500){
              // toast.error(err.message);
              this.setState({popupData:err.message,showPopUp:true})
            }
            else{
              toast.error('Failed to send details');

            }
          }
        })();
      
      }
      else {
        let { formData } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let params = {
    
          "traderId": userInfo.user.id,
          "name": userInfo.user.name,
          "contactNo": userInfo.user.phoneNo,
          "emailId": userInfo.user.email,
          "nameOfOrganization": formData.orgname,
          "typeOfOrganization":formData.type==="P"?"Private":formData.type=="L"?"LLP":"Partnership or Poprietorship",
          "docs": [],
          "limitApplied": formData.limit === 'lac'?"75 lac":"2 Cr",
          "statusOfRequest": "Under process",
          "assignedEmandiCashLimit": "0.00",
          "availableEmandiCashLimit": "0.00",
          "cibilConsent": "yes",
    
        };
        if(this.state.pancard){
          params.docs.push({
            "docType": "pan",
            "docPath": this.state.pancard
          })
        }
        if(this.state.aadhar){
          params.docs.push({
            "docType": "aadharcard",
            "docPath": this.state.aadhar
          })
        }
        if(this.state.bank){
          params.docs.push({
            "docType": "bank statement",
            "docPath": this.state.bank
          })
        }
        if(this.state.gst){
          params.docs.push({
            "docType": "gst certificate",
            "docPath": this.state.gst
          })
        }
        if(this.state.gstretur){
          params.docs.push({
            "docType": "gst returns",
            "docPath": this.state.gstretur
          })
        }
        if(this.state.financial){
          params.docs.push({
            "docType": "finalcial docs",
            "docPath": this.state.financial
          })
        }
        if(this.state.businesPan){
          params.docs.push({
            "docType": "business pan",
            "docPath": this.state.businesPan
          })
        }
        (async () => {
          this.setState({loading:true});
          try {
            const resp = await fireAjaxRequest(EMANDI_CASH, {
              method: 'POST',
              body: JSON.stringify(params),
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.userInfo.token}`
              }
            });
            this.setState({loading:false});
            if (resp) {
              console.log(resp.responseId,"repo")
              this.setState({currentPage:"sucessPage",responseId:resp.responseId,pancardFile:{},aadharCard:{},bankstatement:{},gstcertificate:{},gstreturn:{},financialdoc:{},businessPan:{}})
            }
    
          } catch (err) {
            this.setState({loading:false});
            if(err.code==500){
              // toast.error(err.message);
              this.setState({popupData:err.message,showPopUp:true})
            }
            else{
              toast.error('Failed to send details');

            }
          }
        })();
      }
    }
    onClickbusinessKycContinue = () => {
      let { formData,pancardFile,bankstatement, gstcertificate,gstreturn,financialdoc,businessPan } = this.state;
      if(!gstcertificate.name){
        toast.error('Please Upload GST Certificates !')
      }
      else if(!gstreturn.name){
        toast.error('Please Upload GST Returns !')
      }
      else if(!financialdoc.name){
        toast.error('Please Upload Financial Document!')
      }
      else if(!businessPan.name){
        toast.error('Please Upload Business PAN Card !')
      }
  else{
    
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let params = {

      "traderId": userInfo.user.id,
      "name": userInfo.user.name,
      "contactNo": userInfo.user.phoneNo,
      "emailId": userInfo.user.email,
      "nameOfOrganization": formData.orgname,
      "typeOfOrganization": "private",
      "docs": [],
      "limitApplied": formData.limit === 'lac'?"75 lac":"2 Cr",
      "statusOfRequest": "Under process",
      "assignedEmandiCashLimit": "0.00",
      "availableEmandiCashLimit": "0.00"

    };
    if(this.state.pancard){
      params.docs.push({
        "docType": "pan",
        "docPath": this.state.pancard
      })
    }
    if(this.state.bank){
      params.docs.push({
        "docType": "bank statement",
        "docPath": this.state.bank
      })
    }
    if(this.state.aadhar){
      params.docs.push({
        "docType": "aadharcard",
        "docPath": this.state.aadhar
      })
    }
    if(this.state.gst){
      params.docs.push({
        "docType": "gst certificate",
        "docPath": this.state.gst
      })
    }
    if(this.state.gstretur){
      params.docs.push({
        "docType": "gst returns",
        "docPath": this.state.gstretur
      })
    }
    if(this.state.financial){
      params.docs.push({
        "docType": "finalcial docs",
        "docPath": this.state.financial
      })
    }
    if(this.state.businesPan){
      params.docs.push({
        "docType": "business pan",
        "docPath": this.state.businesPan
      })
    }
    (async () => {
      this.setState({loading:true});
      try {
        const resp = await fireAjaxRequest(EMANDI_CASH, {
          method: 'POST',
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.state.userInfo.token}`
          }
        });
        this.setState({loading:false});
        if (resp) {
          this.setState({currentPage:"sucessPage",responseId:resp.responseId})
        }

      } catch (err) {
        this.setState({loading:false});
        if(err.code==500){
          // toast.error(err.message)
          this.setState({popupData:err.message,showPopUp:true})

        }
        else{
          toast.error('Failed to send details');

        }
      }
    })();
  }
      // this.setState({currentPage:"sucessPage"})
    }
    onClickBack = () => {
      let {currentPage}=this.state;
      if(currentPage=="busineesdetailspage"){
        history.push('/postreg');
      }
      else if(currentPage=="busineesdetailspage"){
        this.setState({currentPage:"busineesdetailspage",gstretur:"",financial:"",businesPan:""})
      }
      else if(currentPage=="kycpage"){
        this.setState({currentPage:"busineesdetailspage",gstretur:"",financial:"",businesPan:""})
      }
      else if(currentPage=="cashlimitpage"){
        this.setState({currentPage:"kycpage",gstretur:"",financial:"",businesPan:""})
      }
      else if(currentPage=="busineesdetailspage"){
        this.setState({currentPage:"cashlimitpage",gstretur:"",financial:"",businesPan:""})
      }
      else if(currentPage=="sucessPage"){
        history.push('/postreg');
      }else{
      }

    }
    onHidePopup = () => {
      this.setState({ showPopUp: false,viewdocumentpopup:false })
  }
  onAccept=()=>{
    history.push('/account')
  }
  onclickview=()=>{
    this.setState({viewdocumentpopup:true})
  }
  onClicksucessContinue=()=>{
    history.push('/homePage')

  }
  onviewFile=(location)=>{
    window.open(location, '_blank', 'fullscreen=yes'); return false;
}
  onTermsClick = () => {
    window.open("#/EmandiCashTerms");
  }

    render() {
      return (
        <div>
          <CustomLoader loading={this.state.loading || false}>
          <List currentPage={this.state.currentPage} formData={this.state.formData} handelInputChange={this.handelInputChange} state={this.state} onClickContinue={this.onClickContinue}
            onClickFile={this.onClickFile} onClickKycContinue={this.onClickKycContinue} onClickcashlimitContinue={this.onClickcashlimitContinue} onClickBack={this.onClickBack}
            onClickbusinessKycContinue={this.onClickbusinessKycContinue} 
            onHidePopup={this.onHidePopup} onAccept={this.onAccept}
            onclickview={this.onclickview}  onReupload={this.onReupload}onClicksucessContinue={this.onClicksucessContinue} onviewFile={this.onviewFile} onTermsChecked={this.onTermsChecked} onTermsClick={this.onTermsClick}/>
          </CustomLoader>
        </div>
      )
    }
  }

  export default index