//indicative price in view more listing page of live and upcoming auctions on landing page in trader side
import React, { Component } from 'react'
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton/index.js';
import Ticker from "../../LandingPage/view/ticker.js";
import SetalertPopUp from "../../LandingPage/view/SetalertPopUp";
import CashandCarryConfimPopUp from "../../LandingPage/view/CashandCarryConfimPopUp"
import {numberWithCommas} from '../../../utils/Utility'
import CustomDropdown from '../../../components/CustomDropdown'
import { history } from "../../../utils/Utility";
export class list extends Component {
    render() {
        let { onClickBack, Pathlocation, state, onBidNowClick, onSetAlertClick, onhideSetalert, handelInputChange, formData, onSubmitsetalertPopUp, onClickRegister, onHideConfimPopUp }=this.props;
        const onRowDoubleClick = (e) => {
            console.log(e);
            if (e.data.status === "Draft") {
                history.push(`/auction/newAuction/${e.data.id}`);
            } else {
                history.push(`/auction/auctionDetails/${e.data.id}`)
            }
        }
        let column = [
            {
                "header": "Auction ID",
                "field": "auctionId",
                renderer: ((rowData) => {
                    return ((rowData && rowData.auctionId) ? <div className="auction-id-byoutType"><div>{rowData && rowData.auctionId}</div></div> : "");
                })
            },

            {
                "header": "Commodity",
                "field": "commodityName",
                renderer: ((rowData) => {
                    return ((rowData && rowData.commodityName) ? <div className="auction-id-byoutType"><div>{rowData && rowData.commodityName}</div></div> : "");
                })
            },

            {
                "header": "Indicative Price(Rs/UoM)",
                "field": "indicativePrice",
                renderer: ((rowData) => {
                    return (rowData && rowData.indicativePrice?<div> ₹ {numberWithCommas(rowData.indicativePrice.toFixed(2))}/{rowData.uom.toUpperCase()}</div> : `₹ 0.00/${rowData.uom.toUpperCase()} `);
                    
                })
            },
            {
                "header": "Total Qty",
                "field": "totalQty",
                renderer: ((rowData) => {
                    return ((rowData && rowData.totalQty) ? <div className="auction-id-byoutType"><div>{rowData && rowData.totalQty}</div></div> : "");
                })
            },
            {
                "header": "Location",
                "field": "deliveryLocation",
                renderer: ((rowData) => {
                    return ((rowData && rowData.deliveryLocation) ? <div className="auction-id-byoutType"><div>{rowData && rowData.deliveryLocation}</div></div> : "");
                })
            },
            {
                "header": "Auction Start Time",
                "field": "bidLots",
                renderer: ((rowData) => {
                    return (((rowData && rowData.currentRound === "ROUND1" && (rowData.start!==null) ) ) ? 
                    <div><span>{rowData.startDate.slice(8, 10)}/{rowData.startDate.slice(5, 7)}/{rowData.startDate.slice(0, 4)}</span> {this.props.converttime(rowData.startDate.slice(11, 16))}</div> 
                    : 
                     (rowData.roundTwoStartTime!=null)?
                      <div><span>{rowData.roundTwoStartTime.slice(8, 10)}/{rowData.roundTwoStartTime.slice(5, 7)}/{rowData.roundTwoStartTime.slice(0, 4)}</span> {this.props.converttime(rowData.roundTwoStartTime.slice(11, 16))} IST</div>
                      :
                      <div></div>
                    )
                })
            },
            {
                "header": "Auction End Time",
                "field": "bidLots",
                renderer: ((rowData) => {
                    return (((rowData && rowData.currentRound === "ROUND1") ) ? <div><span>{rowData.endDate.slice(8, 10)}/{rowData.endDate.slice(5, 7)}/{rowData.endDate.slice(0, 4)}</span> {this.props.converttime(rowData.endDate.slice(11, 16))}</div> 
                    : 
                    <div><span>{rowData.roundTwoEndTime.slice(8, 10)}/{rowData.roundTwoEndTime.slice(5, 7)}/{rowData.roundTwoEndTime.slice(0, 4)}</span> {this.props.converttime(rowData.roundTwoEndTime.slice(11, 16))}</div>);
                })
            },
            {
                "header": "Status",
                "field": "status",
                renderer: ((rowData) => {
                    return ((rowData && rowData.status) ? <div className="auction-id-byoutType"><div>{rowData && rowData.status}</div></div> : "");
                })
            },
            {
                "header": "Bid now",
                "field": "bidLots",
                renderer: ((rowData) => {
                    if (Pathlocation.includes("liveAuction")){
                        return ((rowData && rowData.auctionId) ? <div className="bid-now-cls"><div><CustomButton label={rowData && rowData.auctionId.slice(0,2)=="FA"?"Buy Now":"Sell Now"} onClick={()=>onBidNowClick(rowData)}/></div></div> : "");
                    }else{
                        return ((rowData && rowData.auctionId) ? <div className="bid-now-cls"><div><CustomButton label="Set Alert"onClick={()=>onSetAlertClick(rowData)} /></div></div> : "");
                    }
                })
            }
        ];
        return (
            <div className="viewmoreauctions-cls">

                <CashandCarryConfimPopUp visible={state.confirmPopup} onHide={onHideConfimPopUp} onCancel={onHideConfimPopUp} formData={formData} bodyContend={formData.dailogContend}/>
                <SetalertPopUp visible={state.setalertPopUp} onHide={onhideSetalert} onCancel={onhideSetalert} handelInputChange={handelInputChange} formData={formData} onSubmitsetalertPopUp={onSubmitsetalertPopUp} onClickRegister={onClickRegister}/>

                <Ticker />
                <div className="header-cls">
                    <i className="pi pi-angle-left" onClick={onClickBack} ></i>
                        {/* <img src={Logo}/> */}
                    <p>{Pathlocation.includes("liveAuction")?"Live Auctions":"Upcoming Auctions"}</p>
                </div>
                {Pathlocation.includes("liveAuction")?<div className="filter-list">
                            <CustomDropdown name="auctionType" value={formData.auctionType} onChange={handelInputChange} options={state.auctionTypeDropDown} config={{ placeholder: "Auction Type",showClear:true }} />
                            <CustomDropdown name="commodityName" value={formData.commodityName} onChange={handelInputChange} options={state.commodityDropDownList} config={{ filter: true, placeholder: "Commodities",showClear:true  }} />
                            <CustomDropdown name="locationName" value={formData.locationName} onChange={handelInputChange} options={state.locationDropDownList} config={{ filter: true, placeholder: "Locations",showClear:true  }} />
                            <CustomDropdown name="endOn" value={formData.endOn} onChange={handelInputChange} options={state.dateType} config={{ filter: true, placeholder: " Auction Date",showClear:true  }} />
                        </div>:<div className="filter-list">
                           <CustomDropdown name="commodityName" value={formData.commodityName} onChange={handelInputChange} options={state.commodityDropDownList} config={{ filter: true, placeholder: "Commodities",showClear:true  }} />
                            <CustomDropdown name="locationName" value={formData.locationName} onChange={handelInputChange} options={state.locationDropDownList} config={{ filter: true, placeholder: "Locations",showClear:true  }} />
                            </div>}
                
                        
                <div className="customTable-cls" >
                    <CustomTable columns={column} data={Pathlocation.includes("liveAuction")?state.activeAuctionList:state.scheduledAuctionList} className="bids-activity-grid-cls" config={{ scrollable: true, scrollHeight: "55vh" ,onRowDoubleClick: onRowDoubleClick,}} />
                </div>
            </div>
        )
    }
}

export default list
