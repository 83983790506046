import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Scrollbars from 'react-custom-scrollbars';
import CustomTable from '../../components/CustomTable';



class Lender extends Component {
    render() {
        let {column,state,onRowDoubleClick,onviewFile,onReupload,onScrollFrame,formData,handelInputChange,onClickBack,deleteReport,onhide,onClickaddemandicash,onClickbusinessdetailpageContinue,onClickFile,onClickKycContinue,onClickcashlimitContinue,onClickbusinessKycContinue,onhideAddemandioiCash}=this.props;

    return (
        <>
             <div class="d-flex mt-3 mx-2">
                <div class="p-2 flex-grow-1">
                    {/* <input type="text" placeholder='search' /> */}
                    <div class="form-group has-feedback">
                        <input type="text" class="form-control  " placeholder='search' id="inputSuccess2" />
                        <span class="glyphicon glyphicon-search form-control-feedback"></span>
                    </div>
                </div>
                <div class="p-2">
                    <button className="btn btn-success me-md-2" type="button">New Lender</button>
                </div>
                <Scrollbars autoHide={false} style={{ width: "100%", height: '70vh' }} onScrollFrame={onScrollFrame} >
            <CustomTable columns={column}  config={{
                onRowDoubleClick: onRowDoubleClick
            }} />
            </Scrollbars>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"/>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"/>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
  <title>Origo e-Mandi</title>
  <script src=
  "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.0/sweetalert.min.js">
    </script>
    <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
  />
            </div>
        </>
    )
        }
}
export default Lender