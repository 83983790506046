import React, { Component } from 'react'
import CustomInputText from "../../../components/CustomInputText"
import CustomButton from '../../../components/CustomButton'
import OTP from '../../../components/CustomInputOTP'
import Logo from '../../../assets/images/tick.png'
import { Password } from 'primereact/password';
import { RadioButton } from 'primereact/radiobutton';
import { Scrollbars } from 'react-custom-scrollbars';
import Ticker from "../../LandingPage/view/ticker.js";
import flag from '../../../assets/images/flag.png'
import CustomCheckbox from "../../../components/CustomCheckbox"

export default class list extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checked: true,
        }
    }
    render() {
        let { handelInputChange, formData,onEnterPressBtnSubmitForm, onClickContinue,handleonForgetPasswordClick, currentpage, OTPvalue,isButtonDisabled,onKeyDown, disableOTP,handleKeypress, onChangeOTP, onClickBack,onKeyUp, handleOTPChange, resendTimeLimit, resendOTPTime, resendOTP, onClickverify, onClickRegister, onclickLoginpage, onClickLoginContinue, onClickVerifyContinue, showAuthenticatewith, showloginbutton, showsendotpbutton, showcontinuebutton, showverifybutton, showotpfields, onSendOTP, isotpoptions, onClickPasswordLogin, onForgetPasswordClick, state, onForgetPasswordVerifyClick, onSaveNewPasswordClick, resendForgetPasswordOTP, onTermsClick } = this.props;

        return (
            <div className="register-Landing-page">
                <Ticker />
                {currentpage == "homepage" ?
                    <div className="home-page-cls">
                        {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '40vh' }}> */}

                        <div className="container-cls">
                            <div className="header-cls">{showotpfields && state.showForgetPasswordVerifyBtn ? <div className="header-cls">
                                <div style={{ padding: "14px" }}></div>
                                <span className="pi pi-arrow-left Authentication_back" onClick={onClickBack}></span> <span className='auth-cls-label'>Authentication Required</span>
                            </div> : <div className="header-cls">
                                <div ><strong className='header-label'>Login</strong></div>
                                <span className='member-type-cls'>Not a member yet?</span><strong onClick={onClickRegister} style={{ cursor: "pointer", fontFamily: "Poppins", fontSize: "16px", color: "#ffff" }}> Register</strong>
                            </div>}

                            </div>
                            <div className="fields-cls">
                                <div className="mandatory-fields-cls">
                                    <div className="inputfield-cls">
                                        <p>Enter Mobile Number</p>
                                        <div class="input-container">
                                            <i class="fa fa-user icon">
                                                <img src={flag} />
                                            </i><span className='code'>+91</span>
                                            <div className="input-cls">
                                                <CustomInputText name="number" value={formData.number} config={{
                                                    onChange: handelInputChange, onKeyDown:onKeyDown, onKeyPress:handleKeypress, keyfilter: "pnum", maxLength: "10",
                                                    disabled: showcontinuebutton ? false : true,
                                                }} />
                                            </div>
                                            {/* <div className="input-cls">
                                            <CustomInputText name="number" value={formData.number} config={{
                                                onChange: handelInputChange, keyfilter: "pnum", maxLength: "10",
                                                disabled: showcontinuebutton ? false : true,
                                            }} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showAuthenticatewith ? <div className="showauth-cls">
                                <div className="first-div"> Authenticate with</div>
                                <div className="radioButtons-cls">
                                    <div className="radio-btn-class">
                                        <div className="p-field-radiobutton">
                                            <RadioButton name="authwith" value="password" onChange={handelInputChange} checked={formData.authwith === 'password'} />
                                            <label>Password</label>

                                        </div>
                                        <div className="p-field-radiobutton">
                                            <RadioButton name="authwith" value="otp" onChange={handelInputChange} checked={formData.authwith === 'otp'} />
                                            <label>OTP</label>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                            {showloginbutton&&formData.authwith === 'password' ?

                                <div className="passwordfield-cls">
                                    <p>Enter Password</p>
                                    <div className="input-cls">
                                        <CustomInputText name="password" value={formData.password} customOnKeyPress={onEnterPressBtnSubmitForm}config={{
                                            
                                            onKeyDown:handleonForgetPasswordClick,
                                            // onKeyPress:handleonForgetPasswordClick,
                                            onChange: handelInputChange,
                                            type: "password",
                                           
                                            }} />

                                    </div>
                                    {/* <i className="pi pi-arrow-left" onClick={onClickBack}></i><span className='first-Authenticate' style={{paddingLeft:"40px"}}></span> */}
                                    <p className="forgot-label" onKeyDown={onKeyDown} onKeyPress={handleonForgetPasswordClick} onClick={onForgetPasswordClick}>Forgot password</p>
                                </div>
                                : isotpoptions && formData.authwith == "otp" ?
                                <div className="verifyOTP-cls">
                                <div className="otp-cls">
                                    <p id="sendOTP"><b>Enter OTP</b></p>
                                    <OTP label={"OTP"} value={OTPvalue} disableOTP={disableOTP} onKeyUp={onKeyUp} onChangeOTP={onChangeOTP} handleOTPChange={handleOTPChange} formId="login"/>
                                    <p id="resendOTP">{resendOTPTime < 1 ? <p id="resend" onClick={resendOTP}>Resend OTP</p> : <p><span className='otp-time-cls'>Resend OTP </span><span>in {resendOTPTime} secs</span></p>}</p>
                                </div>
                            </div>:""}
                            

                            {/* {isotpoptions && formData.authwith == "otp" ?                          
                            <div className="showauth-cls">
                                <div className="first-div">Where do you want the OTP to be sent?</div> 
                                <div className="radioButtons-cls">
                                   <div className="radio-btn-class">
                                       <div className="p-field-radiobutton">
                                           <RadioButton name="sentotpto" value="phonenumber" onChange={handelInputChange} checked={formData.sentotpto === 'phonenumber'} />
                                           <label>Phone Number</label>
                                        </div>
                                        <div className="p-field-radiobutton">
                                           <RadioButton name="sentotpto" value="emailid" onChange={handelInputChange} checked={formData.sentotpto === 'emailid'} />
                                            <label>Email ID</label>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                            : ""} */}

                            {/* {showotpfields && formData.sentotpto == "phonenumber" ? */}
                                {/* // <div className="verifyOTP-cls">
                                //     <div className="otp-cls"> */}
                                        {/* <p id="sendOTP">OTP Sent to {this.state.phoneNo} <span onClick={this.onChangeNumber.bind(this)}>Use different number</span></p> */}
                                        {/* <OTP label={"OTP"} value={OTPvalue} disableOTP={disableOTP} onKeyUp={onKeyUp} onChangeOTP={onChangeOTP} handleOTPChange={handleOTPChange} />
                                        <p id="resendOTP">{resendOTPTime < 1 ? <p id="resend" onClick={resendOTP}>Resend OTP</p> : <p><span className='otp-time-cls'>Resend OTP </span><span>in {resendOTPTime} secs</span></p>}</p>
                                    </div> */}
                                {/* </div>
                                : ""} */}
                            {/* {showotpfields && formData.sentotpto == "emailid" ? <div className=""> */}
                                {/* <div className="verifyOTP-cls">
                                    <div className="otp-cls">
                                        <p id="sendOTP">The OTP has been sent to the following email Id <b style={{ "fontSize": "16px" }}>{state.OTPResponce && state.OTPResponce.email}</b></p>
                                        <OTP label={"OTP"} value={OTPvalue} disableOTP={disableOTP} onKeyUp={onKeyUp} onChangeOTP={onChangeOTP} handleOTPChange={handleOTPChange} />
                                        <p id="resendOTP">{resendOTPTime < 1 ? <p id="resend" onClick={resendOTP}>Resend OTP</p> : <p><span className='otp-time-cls'>Resend OTP </span><span>in {resendOTPTime} secs</span></p>}</p>
                                    </div>
                                </div> */}
                            {/* </div>
                                : ""} */}
                            {showotpfields && state.showForgetPasswordVerifyBtn ?
                                 <div className="verifyOTP-cls">
                                  
                                    <p className="verify-text">Enter OTP</p> 
                                    <div className="otp-cls">
                                        <OTP label={"OTP"} value={OTPvalue} disableOTP={disableOTP} onKeyUp={onKeyUp} onChangeOTP={onChangeOTP} handleOTPChange={handleOTPChange} />
                                        <p id="resendOTP">{resendOTPTime < 1 ? <p id="resend" onClick={resendForgetPasswordOTP}>Resend OTP</p> : <p><span className='otp-time-cls'>Resend OTP </span><span>in {resendOTPTime} secs</span></p>}</p>
                                    </div>
                                </div>
                                : ""}
                            {/* {showsendotpbutton ? <CustomButton label="SEND OTP" config={{ onClick: onSendOTP }} /> : ""} */}
                            {showloginbutton ? <CustomButton label=" LOGIN" config={{ onClick: onClickPasswordLogin }} /> : ""}
                            {showcontinuebutton ? <CustomButton label="CONTINUE" config={{ onClick: onClickLoginContinue }} /> : ""}
                            {showverifybutton ? <CustomButton label="VERIFY" config={{ style: { opacity: state.otp !== "" ? 1 : 0.5 }, onClick: state.otp !== "" ? onClickVerifyContinue : "" }} /> : ""}
                            {state.showForgetPasswordVerifyBtn ? <CustomButton label="VERIFY" config={{ style: { opacity: state.otp !== "" ? 1 : 0.5 }, onClick: state.otp !== "" ? onForgetPasswordVerifyClick : "" }} /> : ""}
                        </div>
                        {/* </Scrollbars> */}
                    </div>
                    :
                    currentpage == "registerpage" ?
                        <div className="register-container-cls">
                            {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '70vh' }}> */}

                            <div className="container-cls">
                                <div className="header-cls">
                                    <div ><strong className='header-label'>Register</strong></div>
                                    <span className='member-type-cls'>Already a member?</span><strong onClick={onclickLoginpage} style={{ cursor: "pointer", color: "#ffff" }}> Login</strong>
                                </div>
                                <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '41vh' }}>

                                    <div className="fields-cls">
                                        <div className="mandatory-fields-cls">
                                            <div className="inputfield-cls">
                                                <p>Full Name <span>*</span></p>
                                                <div className="input-cls">
                                                    {/* <CustomInputText name="fullname" value={formData.fullname} config={{
                                                        onChange: handelInputChange,
                                                        
                                                    }} /> */}
                                                    
                                                    <CustomInputText name="fullname" value={formData.fullname} config={{
                                                        onChange: handelInputChange,
                                                    }} customOnKeyPress={onEnterPressBtnSubmitForm} formName="register" />

                                                </div>

                                            </div>
                                            {/* <div className="inputfield-cls">
                                            <p>Last Name</p>
                                            <div className="input-cls">
                                                <CustomInputText name="lastname" value={formData.lastname} config={{
                                                    onChange: handelInputChange,
                                                }} />
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className="mandatory-fields-cls">
                                            <div className="inputfield-cls">
                                                <p>Mobile Number <span>*</span></p>
                                                <div class="input-container">
                                                    <i class="fa fa-user icon">
                                                        <img src={flag} />
                                                    </i><span className='code'>+91</span>
                                                    <div className="input-cls">
                                                        <div className="input-cls">
                                                            <CustomInputText name="number" value={formData.number} config={{
                                                                onChange: handelInputChange, keyfilter: "pnum", maxLength: "10"
                                                            }} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="input-cls">
                                        <CustomInputText name="number" value={formData.number} config={{
                                            onChange: handelInputChange, keyfilter: "pnum", maxLength: "10"
                                        }} />
                                    </div> */}
                                                </div>

                                            </div>

                                            <div className="inputfield-cls">
                                                <p> Email id</p>
                                                <div className="input-cls">
                                                    <CustomInputText name="email" value={formData.email} config={{
                                                        onChange: handelInputChange,
                                                    }}customOnKeyPress={onEnterPressBtnSubmitForm} formName="register" />
                                                </div>
                                            </div>
                                            <div className="inputfield-cls">
                                                <p>Password <span>*</span></p>
                                                <div className="input-cls">
                                                    {/* <CustomInputText name="password" value={formData.password} config={{
                                                onChange: handelInputChange
                                            }} /> */}
                                                    <Password name="password" value={formData.password}
                                                        onChange={handelInputChange} feedback={false}customOnKeyPress={onEnterPressBtnSubmitForm} formName="register" />
                                                </div>
                                            </div>
                                            <div className="inputfield-cls">
                                                <p>Confirm Password <span>*</span></p>
                                                <div className="input-cls">
                                                    {/* <CustomInputText name="confirmpassword" value={formData.confirmpassword} config={{
                                                onChange: handelInputChange,
                                            }} /> */}
                                                    <Password name="confirmpassword" value={formData.confirmpassword}
                                                        onChange={handelInputChange} feedback={false}customOnKeyPress={onEnterPressBtnSubmitForm} formName="register" />
                                                </div>
                                            </div>
                                            <div className="inputfield-cls">
                                                <p>Referral Mobile Number</p>
                                                <div class="input-container">
                                                    <i class="fa fa-user icon">
                                                        <img src={flag} />
                                                    </i><span className='code'>+91</span>
                                                    <div className="input-cls">
                                                        <div className="input-cls">
                                                            <CustomInputText name="referralnumber" value={formData.referralnumber} config={{
                                                                onChange: handelInputChange, keyfilter: "pnum", maxLength: "10"
                                                            }} customOnKeyPress={onEnterPressBtnSubmitForm} formName="register"/>
                                                        </div>
                                                    </div>
                                                    {/* <div className="input-cls">
                                        <CustomInputText name="referralnumber" value={formData.referralnumber} config={{
                                            onChange: handelInputChange, keyfilter: "pnum", maxLength: "10"
                                        }} />
                                    </div> */}
                                                </div>

                                            </div>

                                        </div>
                                        <div className="terms-conditions-cls" >
                                            <CustomCheckbox checked={this.state.checked} onChange={e => this.setState({ checked: e.checked })} />
                                            <label htmlFor="label" className='label'>By Signing up you agree to <span >Terms and Conditions</span></label>
                                        </div>

                                    </div>
                                </Scrollbars>
                                <CustomButton label="CONTINUE" config={{ onClick: onClickContinue }} />
                            </div>
                            {/* </Scrollbars> */}
                        </div> :
                        currentpage == "otppage" ?
                            <div className="otp-page-cls">
                                <div className="container-cls">
                                    <div className="header-cls">
                                        <div className="header-cls">
                                            <div ><strong className='header-label'>Register</strong></div>
                                            <span className='member-type-cls'>Already a member?</span><strong onClick={onclickLoginpage} style={{ cursor: "pointer", color: "#ffff" }}> Login</strong>
                                        </div>
                                    </div>
                                    <div className="fields-cls">
                                        <div className="verifyOTP-cls">
                                            <div className="otp-cls">
                                                <OTP label={"OTP"} value={OTPvalue} disableOTP={disableOTP} onKeyUp={onKeyUp} onChangeOTP={onChangeOTP} handleOTPChange={handleOTPChange} />
                                                <p id="resendOTP">{resendOTPTime < 1 ? <p id="resend" onClick={resendOTP}>Resend OTP</p> : <p><span className='otp-time-cls'>Resend OTP </span><span>in {resendOTPTime} secs</span></p>}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <CustomButton label="VERIFY" config={{ style: { opacity: state.otp !== "" ? 1 : 0.5 }, onClick: state.otp !== "" ? onClickverify : "" }} />
                                </div>
                            </div>
                            :
                            currentpage == "forgetPassword" ?
                                <div className="home-page-cls">
                                    <div className="container-cls">
                                        <div className="header-cls">
                                            <div className="header-cls">
                                                <div style={{ padding: "14px" }}></div>
                                                <span className='auth-cls-label'>Password Creation</span>
                                            </div>
                                        </div>
                                        <div className="fields-cls">
                                            <div className="mandatory-fields-cls">
                                                <div className="inputfield-cls">
                                                    <p>Enter new password</p>
                                                    <div className="input-cls">
                                                        <CustomInputText name="newPassword" value={formData.newPassword} config={{
                                                            onChange: handelInputChange,
                                                            type: "password"
                                                        }} customOnKeyPress={onEnterPressBtnSubmitForm} formName="forgotpassword" />
                                                    </div>
                                                </div>
                                                <div className="inputfield-cls">
                                                    <p>Re-enter new Password</p>
                                                    <div className="input-cls">
                                                        <CustomInputText name="reNewPassword" value={formData.reNewPassword} config={{
                                                            onChange: handelInputChange,
                                                            type: "password"
                                                        }} customOnKeyPress={onEnterPressBtnSubmitForm} formName="forgotpassword" />
                                                    </div>
                                                </div>
                                            </div>
                                            <CustomButton label=" Save"   config={{ onClick: onSaveNewPasswordClick }}  />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="verified-page-cls">
                                    <div className="container-cls">
                                        <div className="fields-cls">
                                            <div className="verified-page">
                                                <p><img src={Logo} alt=""></img></p>
                                                <p>Your account has been successfully registered!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                }
                <div className="terms-and-condition" onClick={onTermsClick}>Terms and Conditions</div>
            </div >

        )

    }
}
