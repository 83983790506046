import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import CustomInputText from "../../../components/CustomInputText";

class logoutDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            resendOTPTime:5
        }
    }


    render() { 
        let {visible,onHide,onAccept,onCancel,formData,handelInputChange,onhide} = this.props;

        return (  <div className="transferOTPDialog">
        <CustomDialog visible={visible} onHide={onHide} arrayOfButton={[{label:"OK",onClick:onAccept,className:"logout-btn"}]} btn1={"Cancel"} btn2={"Ok"} onAccept={onAccept} onCancel={onAccept} header={"Alert"}>
            <div className="bank-details-wrapper">
                <p class="account-type">You have been logout as you have exceeded the number of attempt.</p>
            </div>
        </CustomDialog>
    </div> );
    }
}
 
export default logoutDialog;