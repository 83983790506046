import React from 'react';
import { Link, useHistory } from "react-router-dom";

import Scrollbars from 'react-custom-scrollbars';
import Navbar from '../../../components/HomeNewPageComponents/Navbar/Navbar';
import image1 from "../../../assets/images/newsImage1.png";
import image2 from "../../../assets/images/newsImage2.png";

import './emandiNews.scss';
import Footer from '../../../components/HomeNewPageComponents/Footer/Footer';
import { fireAjaxRequest } from '../../../utils/Utility';
import { GET_NEWS } from '../../../utils/Api';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useEffect } from 'react';
import back_arrow from '../../../assets/images/back_arrow_breadcrumbs.svg'

function NewEmandiFullNews() {

    const history= useHistory();
    let Pathlocation = history.location.pathname;
    let Path = Pathlocation.replace("/eMandiFullNews/", "");
    const [listData,setListData]=useState([]);
    const shownewsList=()=>{
        let Pathlocation = history.location.pathname;
          (async () => {
              // this.setLoading(true);
              try {
                  const response = await fireAjaxRequest(`${GET_NEWS}/`+Path, {
                      method: 'GET',
                      headers: {
                          "Content-Type": "application/json",
                          // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                      },
                  });
                  if(response){
                    setListData(response.data);
                    console.log(listData);
                    //this.setState({listData:response.data})
                  }
              } catch (err) {
                  toast.error(err.message || "Failed to load !");
  
              }
          })();
      } 
      useEffect(() => {
        shownewsList();
      });

    const backNews = () => {
        history.push('/eMandiNews')
    }

    const backHome = () => {
        history.push('/postreg')
    }
 
    return (
        <div>
            <Navbar />
            <Scrollbars autoHide={false} style={{ width: "100%", height: "93vh" }}>
                <div className='newEmandiNews'>
                    <div className='container mt-5 homeback'>
                        <p className='backbtn' ><span> 
                        <img src={back_arrow} onClick={() => backHome()}></img>
                        </span>
                        <span onClick={() => backHome()}>&nbsp; &nbsp;&nbsp;&nbsp; Home&nbsp; &gt;
                            &nbsp; &nbsp; </span> <span onClick={() => backNews()}> eNews&nbsp;</span> &gt;  <span style={{ color: '#04B23D' }}>&nbsp; &nbsp;{listData.topic}</span></p>
                    </div>
                    <div className='container mb-5 titleReadFullNews'>

                        <div className='mt-4'>
                            <h4>{listData.topic}</h4>
                            <img src={listData.newsPicturePath} class="mt-5" alt="..." style={{ width: '60%' }} />
                            <p className='mt-4' style={{width:"660px"}}>
                               {listData.newsContent}
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </Scrollbars>
        </div>
    )
}

export default NewEmandiFullNews