import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
// import ShowSingleVideos from "./ShowSingleVideos";
export class List extends Component {
  render() {
    let { onClickBack, state } = this.props;
    console.log(state, "state")
    return (
      <>
      {/* {false ? (
        <ShowSingleVideos path={state.path} />
      ) : ( */}
      <div className='Emandi-news-wrapper-cls'>
        <div className="header-cls">
          <i className="pi pi-angle-left" onClick={onClickBack}></i>
          {/* <img src={Logo} /> */}
          <p>Emandi TV</p>
        </div>
        <div className='content-view-cls'>
          <img height="200px" src={state && state.listData.newsPicturePath} />
          <div>
            <p className='heading'>{state && state.listData.tittle}</p>
            <p className='heading'>{state && state.listData.location}{state && state.listData.uploadedOn ? <span> {state && state.listData.uploadedOn.slice(8, 10)} {state && state.listData.uploadedOn.slice(5, 7) == "01" ? "Jan" : state && state.listData.uploadedOn.slice(5, 7) == "02" ? "Feb" : state && state.listData.uploadedOn.slice(5, 7) == "03" ? "Mar" :
              state && state.listData.uploadedOn.slice(5, 7) == "04" ? "Apr" : state && state.listData.uploadedOn.slice(5, 7) == "05" ? "May" : state && state.listData.uploadedOn.slice(5, 7) == "06" ? "Jun" : state && state.listData.uploadedOn.slice(5, 7) == "07" ? "Jul" :
                state && state.listData.uploadedOn.slice(5, 7) == "08" ? "Aug" : state && state.listData.uploadedOn.slice(5, 7) == "09" ? "Sep" : state && state.listData.uploadedOn.slice(5, 7) == "10" ? "Oct" : state && state.listData.uploadedOn.slice(5, 7) == "11" ? "Nov" : "Dec"} {state && state.listData.uploadedOn.slice(0, 4)}</span>
              : state && state.listData.videoContent}</p>
           <Scrollbars> <p>{state && state.listData.videoContent}</p></Scrollbars>
          </div>
        </div>
      </div>
      {/* )} */}
      </>
    )
  }
}


export default List