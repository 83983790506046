import React, { useEffect, useState } from 'react';
import '../style/eCashPostReg.css';
import vectorHalfRectangle from '../../../../assets/icons/vector_half_rectangle.png';
import vectorArrow from '../../../../assets/icons/vector_arrow.png';
import 'react-bootstrap-accordion/dist/index.css';
import { toast } from 'react-toastify';
import { fireAjaxRequest, history } from "../../../../utils/Utility"
import { UPLOAD_FILE, SAVE_ADD_DOCS, PARTNER_ADD_DOCS, SIGNATORY_ADD_DOCS, LENDER_SELECTION, AADHAAR_CONSENT_API, GET_AADHAAR_API, KYC_VERIFY_UAN, PUT_MSME_DETAILS } from "../../../../utils/Api"
import pdfIcon from '../../../../assets/icons/pdf_icon.png';
import Navbar from '../../Navbar/Navbar'

import closeIcon from '../../../../assets/icons/close_icon.png';
// import Gif from './gif'; 
import ECashHome from './eCashHome';
//create your forceUpdate hook
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
}
function ECashDocuments(props) {
  let num = 5;
  const [numVal, setNumVal] = useState(num);
  // call your hook here
  const forceUpdate = useForceUpdate();
  const [udyamAadharLink, setUdhyamAadharLink] = useState([]);
  const [aadharInput, setAadharInput] = useState('');
  const [aadharName, setAadharName] = useState('');
  const [aadharLink, setAadharLink] = useState([]);
  const [panLink, setPanLink] = useState([])
  const [passportLink, setPassportLink] = useState([])
  const [gstLink, setGstLink] = useState([])
  const [voterIdLink, setVoterIDLink] = useState([])
  const [cibilConsentLink, setCibilConsentLink] = useState([])
  const [regPartnerShipLink, setRegPartnerShipLink] = useState([])
  const [partnerStrucLink, setPartnerStrucLink] = useState([])
  const [regCertificateLink, setRegCertificateLink] = useState([])
  const [memorandumLink, setMemorandumLink] = useState([])
  const [shareholdingLink, setShareholdingLink] = useState([])
  const [fileTypeLink, setFileTypeLink] = useState([])
  const [fileName, setFileName] = useState([])
  const [fileType, setFileType] = useState([])
  const [docNo, setDocNo] = useState('');
  const [aadharOtpSent, setAadharOTPSent] = useState(false);
  const [aadharOtpVerify, setAadharOtpVerify] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [caseId, setCaseId] = useState('');
  const [shareCode, setShareCode] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [aadharDropdown, setAadharDropdown] = useState(false)
  const [voterIdDropdown, setVoterIdDropdown] = useState(false)
  const [passportDropdown, setPassportDropdown] = useState(false)
  const [partner, setPartner] = useState("")
  const [documentUploded, setDocumentUploaded] = useState(false)
  const [partnerDocs, setPartnerDocs] = useState([])
  const [signatoryDocs, setSignatoryDocs] = useState([])
  const [reqId, setRequestId] = useState('');
  const [orgType, setOrgType] = useState('');


  useEffect(() => {
    // document.getElementById("btnback").style.display = "block";

    setRequestId(props.requestId);
    setOrgType(props.oType);
  })
  const [upload, setUpload] = useState(false);


  sessionStorage.setItem("requestID", reqId)
  sessionStorage.setItem("orgType", props.oType);


  const msme = props.msme;
  const changeText = (e, prefix, docType) => {
    orgType == "Proprietorship" ? setNumVal(1) : setNumVal(5)

    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      document.getElementById(e).innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Add More';
      try {
        // document.getElementById("partnerDrp").value = "Select Partner";
      }
      catch {
      }
      document.getElementById("docDrp").value = "Select Documents";
      // document
      onClickFile(files[0], prefix, docType)
    };
    input.click();
  }
  const [documents, setDocuments] = React.useState(true);

  const onClickFile = (e, prefix, docType) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    setFileName(e.name);
    setFileType(e.type);
    if (e.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.type == "video/mp4") {
      toast.error("Please Upload the correct file format")
    } else if (e.size > 10485760) {
      toast.error("Uploaded file is more than 10MB")
    }
    else {
      let formData = new FormData();

      formData.append("file", e);
      formData.append("prefix", prefix);
      (async () => {
        try {
          const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
            method: 'POST',
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: formData
          });
          if (resp && resp.filePath) {
            if (prefix === "udhyamaadhar") {
              let arr = udyamAadharLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(docNo);
              arr.push(obj);
              setUdhyamAadharLink(arr);
              forceUpdate();
              setUpload(true);
              document.getElementById("txtUdyog").style.display = 'none';
              document.getElementById("btnUdyog").style.visibility = 'hidden';

              try {
                document.getElementById("partnerDrp").selectedIndex = 0
              }
              catch
              {

              }

            }
            else if (prefix === "pan") {
              let arr = panLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setPanLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            } else if (prefix === "gst") {
              let arr = gstLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setGstLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            } else if (prefix === "passport") {
              try {
                var selIndex = document.getElementById("partnerDrp").selectedIndex;
                if (selIndex == 0) {
                  toast.error("Please " + document.getElementById("partnerDrp").value)
                  return false;
                }
              }
              catch
              {

              }

              let arr = passportLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(partner);
              arr.push(obj)
              setPassportLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0;
              }
              catch {

              }
            } else if (prefix === "VoterID") {
              try {
                var selIndex = document.getElementById("partnerDrp").selectedIndex;
                if (selIndex == 0) {
                  toast.error("Please " + document.getElementById("partnerDrp").value)
                  return false;
                }
              }
              catch
              {

              }
              let arr = voterIdLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(partner);
              arr.push(obj)

              setVoterIDLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0;
              }
              catch {

              }
            }
            else if (prefix === "regPartnerShip") {
              let arr = regPartnerShipLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setRegPartnerShipLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "partnerStruc") {
              let arr = partnerStrucLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setPartnerStrucLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "regCertificate") {
              let arr = regCertificateLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setRegCertificateLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "memorandum") {
              let arr = memorandumLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setMemorandumLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "shareholding") {
              let arr = shareholdingLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setShareholdingLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }
            else if (prefix === "cibilConsent") {
              let arr = cibilConsentLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              arr.push(obj)
              setCibilConsentLink(arr)
              forceUpdate();
              try {
                document.getElementById("partnerDrp").selectedIndex = 0

              }
              catch
              {

              }

            }

            else if (prefix === "aadhar") {
              try {
                var selIndex = document.getElementById("partnerDrp").selectedIndex;
                if (selIndex == 0) {
                  toast.error("Please " + document.getElementById("partnerDrp").value)
                  return false;
                }
              }
              catch
              {

              }
              let arr = aadharLink;
              let obj = []
              obj.push(resp.filePath);
              obj.push(docType);
              obj.push(partner);
              arr.push(obj)
              setAadharOtpVerify(false);
              setAadharLink(arr);
              forceUpdate();
              verifyAadharConsent(aadharInput, "")
              document.getElementById("aadharName").value = ""
              document.getElementById("aadharNo").value = ""
              try {
                document.getElementById("partnerDrp").selectedIndex = 0;
              }
              catch {

              }
            }
          } else {
            toast.error("Failed to upload file !");
            if (prefix === "udhyamaadhar") {
              setUdhyamAadharLink([])
              forceUpdate();
            }
            else if (prefix === "pan") {
              setPanLink([])
              forceUpdate();
            } else if (prefix === "gst") {
              setGstLink([])
              forceUpdate();
            } else if (prefix === "Passport") {
              setPassportLink([])
              forceUpdate();
            } else if (prefix === "VoterId") {
              setVoterIDLink([])
              forceUpdate();
            } else if (prefix === "cibilConsent") {
              setCibilConsentLink([])
              forceUpdate();
            }
            else if (prefix === "regPartnerShip") {
              setRegPartnerShipLink([])
              forceUpdate();
            }
            else if (prefix === "partnerStruc") {
              setPartnerStrucLink([])
              forceUpdate();
            }
            else if (prefix === "regCertificate") {
              setRegCertificateLink([])
              forceUpdate();
            }
            else if (prefix === "memorandum") {
              setMemorandumLink([])
              forceUpdate();
            }
            else if (prefix === "shareholding") {
              setShareholdingLink([])
              forceUpdate();
            }
            else if (prefix === "aadhar") {
              setAadharLink([])
              forceUpdate();
            }
          }
        }
        catch (err) {
          toast.error(err.message);
          if (prefix === "udhyamaadhar") {
            setUdhyamAadharLink([])
            forceUpdate();
          }
          else if (prefix === "pan") {
            setPanLink([])
            forceUpdate();
          } else if (prefix === "gst") {
            setGstLink([])
            forceUpdate();
          } else if (prefix === "Passport") {
            setPassportLink([])
            forceUpdate();
          } else if (prefix === "VoterId") {
            setVoterIDLink([])
            forceUpdate();
          } else if (prefix === "cibilConsent") {
            setCibilConsentLink([])
            forceUpdate();
          }
          else if (prefix === "regPartnerShip") {
            setRegPartnerShipLink([])
            forceUpdate();
          }
          else if (prefix === "partnerStruc") {
            setPartnerStrucLink([])
            forceUpdate();
          }
          else if (prefix === "regCertificate") {
            setRegCertificateLink([])
            forceUpdate();
          }
          else if (prefix === "memorandum") {
            setMemorandumLink([])
            forceUpdate();
          }
          else if (prefix === "shareholding") {
            setShareholdingLink([])
            forceUpdate();
          }
          else if (prefix === "aadhar") {
            setAadharLink([])
            forceUpdate();
          }
        }
      })();
    }
  }


  const aadharNameChanged = (e) => {
    setAadharName(e.target.value)
    forceUpdate()
  }

  const handleDropdown = (e) => {
    setAadharDropdown(false);
    setPassportDropdown(false);
    setVoterIdDropdown(false);
    if (e.target.value === 'Aadhaar Card') {
      setAadharDropdown(true);
      forceUpdate()
    }
    else if (e.target.value === 'Voter_Id') {
      setVoterIdDropdown(true);
      forceUpdate()
    }
    else if (e.target.value === 'Passport') {
      setPassportDropdown(true);
      forceUpdate()
    }
    forceUpdate()
  }

  const handlePartner = (e) => {
    if (e.target.value == "Select Partner" || e.target.value == "Select Signatory") {
      toast.error("Please " + e.target.value)
    }
    else {
      setPartner(e.target.value);
      forceUpdate()
    }
  }

  const aadharNoChanged = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setAadharInput(e.target.value)
      forceUpdate()
      if (e.target.value.length == 12) {
        verifyAadharConsent(e.target.value, aadharName)
      }
    }
    else {
      toast.error("Only numeric allowed")
      e.target.value = e.target.value.substring(0, e.target.value.length - 1)
      return false;
    }

  }

  const handleOtpInput = (e, index) => {
    let selected = document.getElementsByClassName("input-css-3");
    if (index <= 5 && e.target.value) {
      selected[index].focus();
    } else {
      // OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
    }
    setOtpInput(otpInput + e.target.value);
    forceUpdate();
  }

  const handleKeyDown = (event) => {
    try {
      if (event.key === 'Backspace') {
        let selected = document.getElementsByClassName("input-css-3");
        selected[event.target.id - 2].focus();
        selected[event.target.id - 1].value = '';

        // document.getElementById(event.target.id - 2)?.focus();
      }
      var k = event.which;

      if (event.key == "@" || event.key == "!" || event.key == "#" || event.key == "$" || event.key == "%" || event.key == "^" || event.key == "&" || event.key == "*" || event.key == ")" || event.key == "(") {
        event.preventDefault();
        return false;
      }
      /* numeric inputs can come from the keypad or the numeric row at the top */
      if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
        event.preventDefault();
        return false;
      }
    }
    catch {

    }
  };

  const verifyOTP = () => {

    const verifyOTPDetails = {
      "consent": "Y",
      "otp": otpInput,
      "shareCode": shareCode,
      "aadhaarNo": aadharInput,
      "accessKey": accessKey,
      "clientData": {
        "caseId": caseId
      }
    };

    (async () => {
      try {
        const resp = await fireAjaxRequest(`${GET_AADHAAR_API}`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(verifyOTPDetails)
        });
        if (resp.status === true) {

          setAadharOtpVerify(true)
          setAadharOTPSent(false)

          toast.success("Aadhar Successfully Verified!")
        }
        else {
          setAadharOtpVerify(false)
          //setAadharOTPSent(false)

          toast.success("Invalid OTP")
        }

        if (resp) {
          setOtpInput('');
          if (resp.message.includes("400")) {
            toast.error("Mandatory fields are missing / invalid")
          }
          else if (resp.message.includes("401")) {
            toast.error("API Key is missing or invalid.")
          }
          else if (resp.message.includes("402")) {
            toast.error("Credits to access the APIs expired.")
          }
          else if (resp.message.includes("104")) {
            toast.error("Internal processing error of Karza.")
          }
          else if (resp.message.includes("102")) {
            toast.error("Internal processing error of Karza.")
          }
          else if (resp.message.includes("500")) {
            toast.error("Internal processing error of Karza.")
          }
          else if (resp.message.includes("503")) {
            toast.error("The source for authentication is down for maintenance or inaccessible.")
          }
          else if (resp.message.includes("504")) {
            toast.error("The response latency from the source for authentication is >30sec.")
          }
        }
      } catch (err) {
        toast.error(err.message)
      }
    })();
  }

  const handleInputChangeField = (e) => {
    setDocNo(e.target.value)
    forceUpdate();

    if (e.target.value.length == 12) {
      var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(KYC_VERIFY_UAN + "consent=" + "Y" + "&uanNo=" + e.target.value, requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result)
          if (result.statusCode == "101") {
            // document.getElementById("lblVerified").style.display="block";

            setUpload(true)
          }
          else {
            setUpload(false)
          }
        })
        .catch(error => console.log('error', error));
    }

  }

  const removeFile = (e) => {
    let arr = udyamAadharLink;
    arr.splice(e, 1)
    setUpload(false);
    setUdhyamAadharLink(arr);
    forceUpdate();
    if (udyamAadharLink.length == 0) {
      document.getElementById("txtUdyog").style.display = 'block';
      document.getElementById("txtUdyog").value = '';

      // document.getElementById("txtUdyog").style.display="block";
      // document.getElementById("lblVerified").style.display="none";
      // document.getElementById("btnUdyog").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
    }
  }

  const removePanFile = (e) => {
    let arr = panLink;
    arr.splice(e, 1)
    setPanLink(arr);
    forceUpdate();
    if (panLink.length == 0) {
      try {
        document.getElementById("btnPan").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeVoterIdFile = (e) => {
    let arr = voterIdLink;
    arr.splice(e, 1)
    setVoterIDLink(arr);
    forceUpdate();
    if (voterIdLink.length == 0) {
      try {
        document.getElementById("btnVoterId").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }

  }

  const removePassportFile = (e) => {
    let arr = passportLink;
    arr.splice(e, 1)
    setPassportLink(arr);
    forceUpdate();
    if (passportLink.length == 0) {
      try {
        document.getElementById("btnPassport").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';

      }
      catch
      {

      }
    }
  }

  const removeAadharFile = (e) => {
    let arr = aadharLink;
    arr.splice(e, 1)
    setAadharLink(arr);
    forceUpdate();
    if (aadharLink.length == 0) {
      try {
        document.getElementById("btnAadhar").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeGstFile = (e) => {
    let arr = gstLink;
    arr.splice(e, 1)
    setGstLink(arr);
    forceUpdate();
    if (gstLink.length == 0) {
      try {

        document.getElementById("btnGst").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }

  const removeCibilFile = (e) => {
    let arr = cibilConsentLink;
    arr.splice(e, 1)
    setCibilConsentLink(arr);
    forceUpdate();
    if (cibilConsentLink.length == 0) {
      try {
        document.getElementById("btnCibil").innerHTML = '<i style={{ width: "10%", marginRight: "3px" }} class="fa fa-plus-circle" aria-hidden="true"></i> Upload';
      }
      catch
      {

      }
    }
  }


  const verifyAadharConsent = (aadharNo, name) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      "value": aadharNo,
      "userAgent": "Chrome",
      "consent": "Y",
      "name": name,
      "consentText": "I hereby grant consent to..",
      "consentTime": "1610369914",
      "lat": "17.300166",
      "longitude": "78.526495",
      "clientData": {
        "caseId": "a341fafb"
      },
      "deviceId": "5FA01EE3-B9AB-42A3-ACB1-B150D0C2CA07"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(AADHAAR_CONSENT_API, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result);
        toast.success(result.message);
        setAccessKey(result.data.result.accessKey);
        setCaseId(result.data.clientData.caseId);
        setShareCode(result.shareCode);
        setAadharOTPSent(true);
        forceUpdate();
      })
      .catch(error => console.log('error', error));
  }

  const handleClickContinue = () => {

    if (udyamAadharLink.length == 0 && msme.toLowerCase() == "yes") {
      document.getElementById("continue-btn").disabled = false;
      toast.error("Please Upload Udyam Aadhar Document")
      return false;
    }
    if (panLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload PAN Document")
      return false;
    }
    if (aadharLink.length == 0 && voterIdLink.length == 0 && passportLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload Aadhar/Voter Id / Passport Document")
      return false;
    }
    if (gstLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;

      toast.error("Please Upload GST Certificate")
      return false;
    }
    if (cibilConsentLink.length == 0) {
      document.getElementById("continue-btn").disabled = false;
      toast.error("Please Upload CIBIL Consent")
      return false;
    }

    document.getElementById("uploadDocsLabel").style.color = "#04B23D"
    document.getElementById("continue-btn").disabled = true;

    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var links = []
    udyamAadharLink.map((item) => {
      links.push(item[0])
    })
    var dLinks = JSON.stringify(links);
    dLinks = JSON.parse(dLinks);

    var plinks = []
    panLink.map((item) => {
      plinks.push(item[0])
    })
    var pan_links = JSON.stringify(plinks);
    pan_links = JSON.parse(pan_links);

    var vlinks = []
    voterIdLink.map((item) => {
      vlinks.push(item[0])
    })
    var voter_links = JSON.stringify(vlinks);
    voter_links = JSON.parse(voter_links);

    var alinks = []
    aadharLink.map((item) => {
      alinks.push(item[0])
    })
    var aadhar_links = JSON.stringify(alinks);
    aadhar_links = JSON.parse(aadhar_links);

    var palinks = []
    passportLink.map((item) => {
      palinks.push(item[0])
    })
    var pass_links = JSON.stringify(palinks);
    pass_links = JSON.parse(pass_links)


    var partnerDocuments = partnerDocs
    setPartnerDocs(partnerDocuments)
    forceUpdate()
    voterIdLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["partnerName"] = v[2];
      item["documents"] = [docs];
      partnerDocuments.push(item)
      setPartnerDocs(partnerDocuments)
      forceUpdate()
    })

    aadharLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["partnerName"] = v[2];
      item["documents"] = [docs];
      partnerDocuments.push(item)
      setPartnerDocs(partnerDocuments)
      forceUpdate()
      document.getElementById("aadharName").value = ""
      document.getElementById("aadharNo").value = ""
    })

    passportLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["partnerName"] = v[2];
      item["documents"] = [docs];
      partnerDocuments.push(item)
      setPartnerDocs(partnerDocuments)
      forceUpdate()
    })

    var signatoryDocuments = signatoryDocs
    setSignatoryDocs(signatoryDocuments)

    forceUpdate()
    voterIdLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["signatoryName"] = v[2];
      item["documents"] = [docs];
      signatoryDocuments.push(item)
      setSignatoryDocs(signatoryDocuments)
      forceUpdate()
    })

    aadharLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["signatoryName"] = v[2];
      item["documents"] = [docs];
      signatoryDocuments.push(item)
      setSignatoryDocs(signatoryDocuments)
      forceUpdate()
      document.getElementById("aadharName").value = ""
      document.getElementById("aadharNo").value = ""
    })

    passportLink.map((v) => {
      let docs = {}
      docs["docType"] = v[1]
      docs["docLink"] = [v[0]]
      let item = {}
      item["signatoryName"] = v[2];
      item["documents"] = [docs];
      signatoryDocuments.push(item)
      setSignatoryDocs(signatoryDocuments)
      forceUpdate()
    })


    var glinks = []
    gstLink.map((item) => {
      glinks.push(item[0])
    })
    var gst_links = JSON.stringify(glinks);
    gst_links = JSON.parse(gst_links);

    var clinks = []
    cibilConsentLink.map((item) => {
      clinks.push(item[0])
    })
    var cibil_links = JSON.stringify(clinks);
    cibil_links = JSON.parse(cibil_links);

    var rlinks = []
    regPartnerShipLink.map((item) => {
      rlinks.push(item[0])
    })
    var r_links = JSON.stringify(rlinks);
    r_links = JSON.parse(r_links);

    var plinks = []
    partnerStrucLink.map((item) => {
      rlinks.push(item[0])
    })
    var p_links = JSON.stringify(plinks);
    p_links = JSON.parse(p_links);

    var rclinks = []
    regCertificateLink.map((item) => {
      rclinks.push(item[0])
    })
    var rc_links = JSON.stringify(rclinks);
    rc_links = JSON.parse(rc_links);

    var mlinks = []
    memorandumLink.map((item) => {
      rlinks.push(item[0])
    })
    var m_links = JSON.stringify(mlinks);
    m_links = JSON.parse(m_links);

    var slinks = []
    shareholdingLink.map((item) => {
      slinks.push(item[0])
    })
    var s_links = JSON.stringify(slinks);
    s_links = JSON.parse(s_links);

    if (msme == "yes") {
      var raw = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "ecashRequestId": reqId,
        "isAdditionalDocRequest": false,
        "documents": [
          // {
          //   "id": 0,
          //   "docType": "MSME/UDYAM_CERTIFICATE",
          //   "docName": fileName,
          //   "docNo": docNo,
          //   "docLink": dLinks
          // },
          {
            "id": 0,
            "docType": "PAN",
            "docName": fileName,
            "docNo": '',
            "docLink": pan_links
          },
          {
            "id": 0,
            "docType": "GST_CERTIFICATE",
            "docName": fileName,
            "docNo": '',
            "docLink": gst_links
          },
          {
            "id": 0,
            "docType": "CIBIL_REPORT",
            "docName": fileName,
            "docNo": '',
            "docLink": cibil_links
          },
        ]
      });

      var raw1 = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "requestId": reqId,
        "msmeNo": docNo,
        "msmeDocLink": dLinks
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw1,
        redirect: 'follow'
      };

      fetch(PUT_MSME_DETAILS, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    }
    else {
      var raw = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "ecashRequestId": reqId,
        "isAdditionalDocRequest": false,
        "documents": [
          {
            "id": 0,
            "docType": "PAN",
            "docName": fileName,
            "docNo": '',
            "docLink": pan_links
          },
          {
            "id": 0,
            "docType": "GST_CERTIFICATE",
            "docName": fileName,
            "docNo": '',
            "docLink": gst_links
          },
          {
            "id": 0,
            "docType": "CIBIL_REPORT",
            "docName": fileName,
            "docNo": '',
            "docLink": cibil_links
          },
        ]
      });
    }
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(SAVE_ADD_DOCS, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        if (result.statusCode == "200") {
          toast.success("Successfully updated")
          setDocumentUploaded(true)
          history.push('/gif')
        }
        else {
          document.getElementById("continue-btn").disabled = false;
          toast.error("document not uploaded yet")
          toast.error(result.message)
        }
      }
      )
      .catch(error => console.log('error', error));



    if (orgType === "Partnership") {
      var raw1 = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "ecashRequestId": reqId,
        "partnerDocuments": partnerDocs
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw1,
        redirect: 'follow'
      };

      fetch(PARTNER_ADD_DOCS, requestOptions)
        .then(response => response.text())
        .then(result => console.log('result', result))
        .catch(error => console.log('error', error));
    }
    if (orgType === "Company" || orgType === "LLP") {

      var raw2 = JSON.stringify({
        "traderId": sessionStorage.getItem("traderID"),
        "ecashRequestId": reqId,
        "signatoryDocuments": signatoryDocs
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw2,
        redirect: 'follow'
      };

      fetch(SIGNATORY_ADD_DOCS, requestOptions)
        .then(response => response.text())
        .then(result => console.log('result', result))
        .catch(error => console.log('error', error));
    }
  }


  const handleLenderSelection = () => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(LENDER_SELECTION, requestOptions)
      .then(response => response.text())
      .then(result => console.log('result', result))
      .catch(error => console.log('error', error));
  }

  return (
    <>
      <Navbar />
      <div className="container eCashPostReg" >
        <div className='row' >
          <div className='col-md-6'>
            <ECashHome />
          </div>
          <div className='col-md-6'>

            <div className="bottom-section">
              <div className="entity-css">
                <label for="name" className='breadcrumbsLabel' style={{ marginLeft: "20px" }}>
                  Upload Documents
                  <span
                    aria-hidden="true"
                    class="required"
                    style={{ color: '#04B23D', marginLeft: '5px' }}
                  >
                    *
                  </span>
                </label>
                <div className='gap'></div>
                <div class="accordion eCashAccordian" id="accordionExample">

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        MSME/Udyam Certificate
                        <i title="Upload MSME/Udyam Certificate" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                     
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body" style={{ marginLeft: '-22px' }}>
                        {
                          udyamAadharLink.map((item, key) => (
                            <div className="panel_copy" style={{
                              marginLeft: '20px',
                              marginBottom: '10px'
                            }}>
                              <h3>{item[2]}</h3>
                              <a className='panel_links' href={item[0]} target='_blank'>
                                <img style={{ width: '5%' }} src={pdfIcon} />
                                {item[1]}
                              </a>
                              <i>
                                <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeFile(key) }} />
                              </i><br /></div>
                          ))
                        }
                        {
                          udyamAadharLink.length == 5 ? <>
                          </> : <>
                            <input class="form-control  input-css-class"
                              placeholder='Enter Udyog Aadhar No.' maxLength={12} id="txtUdyog" onChange={(e) => { handleInputChangeField(e) }} />
                            <div className='clearfix' style={{ height: "10px" }}></div>
                            {
                              upload == true ?
                                <>
                                  <span id="lblVerified" style={{ fontSize: "12px", marginLeft: "20px", color: '#04B23D' }}>
                                    Verified
                                  </span>
                                  <button class="btn btn-sm btn-success btn-upload " id="btnUdyog" type="file" name={"Aadhar"} onClick={(e) => {
                                    changeText("btnUdyog", "udhyamaadhar", "MSME/UDYAM_CERTIFICATE")
                                  }}>
                                    <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                    <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                    <span style={{ marginLeft: '5px' }}
                                    >
                                      Upload
                                    </span>
                                  </button>
                                </>
                                :
                                ""
                            }
                          </>
                        }
                        <div className='clearfix' style={{ height: "30px" }}></div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        {
                          orgType == "Proprietorship" ? "PAN Card" : orgType == "Partnership" ? "PAN Card of all Partners" : "PAN Card of all authorized signatory"
                        }

                        <i title="Upload PAN Card(s)" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                  
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {
                          panLink.map((item, key) => (
                            <div className="panel_copy">
                              <h3>{item[2]}</h3>
                              <a className='panel_links' href={item[0]} target='_blank'>
                                <img style={{ width: '5%' }} src={pdfIcon} />
                                {item[1]}
                              </a>
                              <i>
                                <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removePanFile(key) }} />
                              </i><br /></div>
                          ))
                        }
                        {
                          panLink.length == numVal ?
                            <></>
                            :
                            <button class="btn btn-sm btn-success btn-upload" id="btnPan" onClick={(e) => { changeText("btnPan", "pan", "PAN") }}>
                              <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                              <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                              <span style={{ marginLeft: '5px' }}
                              >
                                Upload
                              </span>
                            </button>
                        }
                        <div className='clearfix' style={{ height: "30px" }}></div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Aadhaar/ Passport/Voter ID <i title="Upload Aadhaar/ Passport/Voter ID" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true"></i>
                       
                      </button>
                    </h2>

                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      {
                        orgType == "Proprietorship" ? <>
                          <div class="accordion-body">


                            <select name="limit" id="docDrp" onChange={(e) => { handleDropdown(e) }} className="limit-css-1 limit-1">
                              <option className="limit-css-1" value="Select Documents">Select Documents</option>
                              <option className="limit-css-1" value="Aadhaar Card">Aadhaar Card</option>
                              <option className="limit-css-1" value="Voter_Id">Voter Id</option>
                              <option className="limit-css-1" value="Passport">Passport</option>
                            </select>
                            <div>
                              {
                                aadharLink.map((item, key) => (
                                  <div className="panel_copy">

                                    <a className='panel_links' href={item[0]} target='_blank'>
                                      <img style={{ width: '5%' }} src={pdfIcon} />
                                      {item[1]}
                                    </a>
                                    <i>
                                      <img className='removeBtn' src={closeIcon}
                                        onClick={(e) => { removeAadharFile(key) }}
                                      />
                                    </i><br /></div>
                                ))
                              }
                              {
                                aadharDropdown === true && aadharLink.length == 0 ? <div>

                                  <input id="aadharName" onChange={(e) => { aadharNameChanged(e) }} class="form-control input-css-2"
                                    placeholder='Enter Aadhar Name' />
                                  <input id="aadharNo" onChange={(e) => { aadharNoChanged(e) }} class="form-control  input-css-2" maxLength={12}
                                    placeholder='Enter Aadhar No.' />
                                  {
                                    aadharOtpSent === true ?
                                      <div class="otp-section">
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 1) }} maxLength={1} id="1" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 2) }} maxLength={1} id="2" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 3) }} maxLength={1} id="3" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 4) }} maxLength={1} id="4" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 5) }} maxLength={1} id="5" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 6) }} maxLength={1} id="6" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <button onClick={(e) => { verifyOTP(e) }} class="btn btn-sm btn-success btn-upload">
                                          <span style={{ marginLeft: '5px  ' }}

                                          >
                                            Verify
                                          </span>
                                        </button>
                                      </div>

                                      : ""
                                  }
                                  {
                                    aadharOtpVerify === true ?
                                      aadharLink.length == 1 ?
                                        <> </>
                                        :
                                        <>
                                          <button class="btn btn-sm btn-success btn-upload" id="btnAadhar" onClick={(e) => { changeText("btnAadhar", "aadhar", "AADHAAR") }}>
                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                            <span style={{ marginLeft: '5px' }}
                                            >
                                              Upload
                                            </span>
                                          </button>
                                        </>
                                      : ""
                                  }
                                </div>
                                  :
                                  ""
                              }
                              {
                                <div>
                                  {
                                    voterIdLink.map((item, key) => (
                                      <div className="panel_copy">

                                        <a className='panel_links' href={item[0]} target='_blank'>
                                          <img style={{ width: '5%' }} src={pdfIcon} />
                                          {item[1]}
                                        </a>
                                        <i>
                                          <img className='removeBtn' src={closeIcon}
                                            onClick={(e) => { removeVoterIdFile(key) }}
                                          />
                                        </i><br /></div>
                                    ))
                                  }
                                  {
                                    voterIdLink.length == 1 ?
                                      <></>
                                      : voterIdDropdown === true ?
                                        <>
                                          <br />
                                          <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnVoterId" onClick={(e) => { changeText("btnVoterId", "VoterID", "VOTER_ID") }}>
                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                            <span style={{ marginLeft: '5px' }}
                                            >
                                              Upload
                                            </span>
                                          </button>
                                        </> : ""
                                  }
                                </div>

                              }
                              {
                                <div>
                                  {
                                    passportLink.map((item, key) => (
                                      <div className="panel_copy">

                                        <a className='panel_links' href={item[0]} target='_blank'>
                                          <img style={{ width: '5%' }} src={pdfIcon} />
                                          {item[1]}
                                        </a>
                                        <i>
                                          <img className='removeBtn' src={closeIcon}
                                            onClick={(e) => { removePassportFile(key) }}
                                          />
                                        </i><br /></div>
                                    ))
                                  }
                                  {
                                    passportLink.length == 1 ?
                                      <></>
                                      : passportDropdown === true ?
                                        <>
                                          <br />
                                          <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnPassport" onClick={(e) => { changeText("btnPassport", "passport", "PASSPORT") }}>
                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                            <span style={{ marginLeft: '5px' }}
                                            >
                                              Upload
                                            </span>
                                          </button>
                                        </> : ""
                                  }
                                </div>

                              }
                            </div>

                            <div className='clearfix' style={{ height: "30px" }}></div>

                            <div className='clearfix' style={{ height: "30px" }}></div>
                          </div>
                        </> : <>
                          {/* For Company and Partnership */}
                          <div class="accordion-body">

                            {

                              orgType == "Partnership" ?
                                <select name="limit" id="partnerDrp" className="limit-css-1 limit-1" onChange={(e) => { handlePartner(e) }}>
                                  <option className="limit-css-1" value="Select Partner" selected>Select Partner</option>
                                  <option className="limit-css-1" value="Partner 1">Partner 1</option>
                                  <option className="limit-css-1" value="Partner 2">Partner 2</option>
                                  <option className="limit-css-1" value="Partner 3">Partner 3</option>
                                  <option className="limit-css-1" value="Partner 4">Partner 4</option>
                                  <option className="limit-css-1" value="Partner 5">Partner 5</option>
                                </select>
                                :
                                <select name="limit" id="partnerDrp" className="limit-css-1 limit-1" onChange={(e) => { handlePartner(e) }}>
                                  <option className="limit-css-1" value="Select Signatory" selected>Select Signatory</option>
                                  <option className="limit-css-1" value="Signatory 1">Signatory 1</option>
                                  <option className="limit-css-1" value="Signatory 2">Signatory 2</option>
                                  <option className="limit-css-1" value="Signatory 3">Signatory 3</option>
                                  <option className="limit-css-1" value="Signatory 4">Signatory 4</option>
                                  <option className="limit-css-1" value="Signatory 5">Signatory 5</option>
                                </select>
                            }

                            <br />
                            <select name="limit" id="docDrp" onChange={(e) => { handleDropdown(e) }} className="limit-css-1 limit-1">
                              <option className="limit-css-1" value="Select Documents">Select Documents</option>
                              <option className="limit-css-1" value="Aadhaar Card">Aadhaar Card</option>
                              <option className="limit-css-1" value="Voter_Id">Voter Id</option>
                              <option className="limit-css-1" value="Passport">Passport</option>
                            </select>
                            <div>
                              {
                                aadharLink.map((item, key) => (
                                  <div className="panel_copy">
                                    <h3>{item[2]}</h3>
                                    <a className='panel_links' href={item[0]} target='_blank'>
                                      <img style={{ width: '5%' }} src={pdfIcon} />
                                      {item[1]}
                                    </a>
                                    <i>
                                      <img className='removeBtn' src={closeIcon}
                                        onClick={(e) => { removeAadharFile(key) }}
                                      />
                                    </i><br /></div>
                                ))
                              }
                              {
                                aadharDropdown === true ? <div>
                                  <input id="aadharName" onChange={(e) => { aadharNameChanged(e) }} class="form-control input-css-2"
                                    placeholder='Enter Aadhar Name' />
                                  <input id="aadharNo" onChange={(e) => { aadharNoChanged(e) }} class="form-control  input-css-2" maxLength={12}
                                    placeholder='Enter Aadhar No.' />
                                  {
                                    aadharOtpSent === true ?
                                      <div class="otp-section">
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 1) }} maxLength={1} id="1" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 2) }} maxLength={1} id="2" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 3) }} maxLength={1} id="3" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 4) }} maxLength={1} id="4" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 5) }} maxLength={1} id="5" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <input autoFocus={true} onKeyDown={handleKeyDown} onChange={(e, id) => { handleOtpInput(e, 6) }} maxLength={1} id="6" class="form-control  input-css-3"
                                          placeholder='X' />
                                        <button onClick={(e) => { verifyOTP(e) }} class="btn btn-sm btn-success btn-upload">
                                          <span style={{ marginLeft: '5px  ' }}
                                          >
                                            Verify
                                          </span>
                                        </button>
                                      </div>

                                      : ""
                                  }
                                  {
                                    aadharOtpVerify === true ?
                                      aadharLink.length == 5 ?
                                        <> </>
                                        :
                                        <>
                                          <button class="btn btn-sm btn-success btn-upload" id="btnAadhar" onClick={(e) => { changeText("btnAadhar", "aadhar", "AADHAAR") }}>
                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                            <span style={{ marginLeft: '5px' }}
                                            >
                                              Upload
                                            </span>
                                          </button>
                                        </>
                                      : ""
                                  }
                                </div>
                                  :
                                  ""
                              }
                              {
                                <div>
                                  {
                                    voterIdLink.map((item, key) => (
                                      <div className="panel_copy">
                                        <h3>{item[2]}</h3>
                                        <a className='panel_links' href={item[0]} target='_blank'>
                                          <img style={{ width: '5%' }} src={pdfIcon} />
                                          {item[1]}
                                        </a>
                                        <i>
                                          <img className='removeBtn' src={closeIcon}
                                            onClick={(e) => { removeVoterIdFile(key) }}
                                          />
                                        </i><br /></div>
                                    ))
                                  }
                                  {
                                    voterIdLink.length == 5 ?
                                      <></>
                                      : voterIdDropdown === true ?
                                        <>
                                          <br />
                                          <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnVoterId" onClick={(e) => { changeText("btnVoterId", "VoterID", "VOTER_ID") }}>
                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                            <span style={{ marginLeft: '5px' }}
                                            >
                                              Upload
                                            </span>
                                          </button>
                                        </> : ""
                                  }
                                </div>

                              }
                              {
                                <div>
                                  {
                                    passportLink.map((item, key) => (
                                      <div className="panel_copy">
                                        <h3>{item[2]}</h3>
                                        <a className='panel_links' href={item[0]} target='_blank'>
                                          <img style={{ width: '5%' }} src={pdfIcon} />
                                          {item[1]}
                                        </a>
                                        <i>
                                          <img className='removeBtn' src={closeIcon}
                                            onClick={(e) => { removePassportFile(key) }}
                                          />
                                        </i><br /></div>
                                    ))
                                  }
                                  {
                                    passportLink.length == 5 ?
                                      <></>
                                      : passportDropdown === true ?
                                        <>
                                          <br />
                                          <button class="btn btn-sm btn-success btn-upload btn-acc" id="btnPassport" onClick={(e) => { changeText("btnPassport", "passport", "PASSPORT") }}>
                                            <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                                            <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                                            <span style={{ marginLeft: '5px' }}
                                            >
                                              Upload
                                            </span>
                                          </button>
                                        </> : ""
                                  }
                                </div>

                              }
                            </div>

                            <div className='clearfix' style={{ height: "30px" }}></div>

                            <div className='clearfix' style={{ height: "30px" }}></div>
                          </div></>
                      }



                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed acc-btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        GST Certificate <i title="Upload GST Certificate" class="fa fa-info-circle info-class" style={{ marginLeft: "20px" }} aria-hidden="true" ></i>
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {
                          gstLink.map((item, key) => (
                            <div className="panel_copy">
                              <h3>{item[2]}</h3>
                              <a className='panel_links' href={item[0]} target='_blank'>
                                <img style={{ width: '5%' }} src={pdfIcon} />
                                {item[1]}
                              </a>
                              <i>
                                <img style={{ width: '5%' }} src={closeIcon} onClick={(e) => { removeGstFile(key) }} />
                              </i><br /></div>
                          ))
                        }
                        {
                          gstLink.length == 5 ?
                            <></>
                            :
                            <button style={{ marginTop: '10px' }} class="btn btn-sm btn-success btn-upload" id="btnGst" onClick={(e) => { changeText("btnGst", "gst", "GST_CERTIFICATE") }}  >
                              <img style={{ width: '10%', marginTop: '5px' }} src={vectorHalfRectangle} />
                              <img style={{ width: '10%', marginTop: '-9px', marginLeft: '-9px' }} src={vectorArrow} />
                              <span style={{ marginLeft: '5px' }} >
                                Upload
                              </span>
                            </button>
                        }
                        <div className='clearfix' style={{ height: "30px" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="continue-topheader">
                    <button type="submit" class="btn btn-primary mt-3 continue-button-1" id="continue-btn" onClick={handleClickContinue}
                    >
                      Continue
                    </button>
                  </div> */}
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ECashDocuments;
