import React, { Component } from "react";
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from "react-custom-scrollbars";
import CustomInputText from "../../../components/CustomInputText";

// import './PopUp.scss';

import CustomButton from "../../../components/CustomButton";
import CustomLoader from "../../../components/CustomLoader";

class buynowPopUp extends Component {

    render() {
        const { visible, onHide, onAccept,onHidePopup,path } = this.props;
        return (
            <div className="emandiTV-dailog">
                <div className="buy-now-dialog">
                    <CustomDialog visible={visible} footer={() => { }} header={<p>Emandi TV</p>} onHide={onHidePopup} onCancel={onHidePopup} width="45vw">
                        <CustomLoader >
                            <div className="buynow-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                {/* <div className="header-cls">
                                    <div className="first-div">Buy Now Pay Later</div>
                                </div> */}
                                <div className="message-us">

                                    <div className="label-cls"></div>
                                    <iframe width="500" height="315"
                                        src={path}
                                        allowfullscreen="allowfullscreen"
                                         mozallowfullscreen="mozallowfullscreen" 
                                         msallowfullscreen="msallowfullscreen" 
                                         oallowfullscreen="oallowfullscreen" 
                                         webkitallowfullscreen="webkitallowfullscreen"
                                        >
                                    </iframe>
                                    <div className="btns-cls">
                                        <CustomButton label="Close" className="cancel" config={{ onClick: onHidePopup }} />
                                        {/* <CustomButton label="Submit" className="send" config={{ onClick: onHidePopup }} /> */}
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                        </CustomLoader>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default buynowPopUp;