import React, { Component } from 'react';
import userIcon from '../../assets/images/graphic_no_bid_placed.svg'
import {GET_PERSONAL_INFO} from "../../utils/Api";
import {fireAjaxRequest } from "../../utils/Utility";
import "./styles.scss";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
        }
    }
    
    componentDidMount() {
        (async () => {
            try {
                const resp = await fireAjaxRequest(`${GET_PERSONAL_INFO}/${this.state.userInfo.user.id}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`
                    },
                });
                if (resp && resp.phoneNo) {
                    this.setState({ personalInfo:resp });
                    console.log(this.state.personalInfo);
                } else {
                }
            } catch (err) {
               
            }
        })();
    }

    onProfileClick = () =>{
        let isAdmin = JSON.parse(sessionStorage.getItem("isAdmin"));
        if (!isAdmin){
            this.props.OnUserProfilClick();
        }
    }

    render() {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let { onLogout } = this.props;
        let {personalInfo} = this.state;

        return (
            <div className="userProfileBlock-cls">
                <div className="image-cls">
                    {/* <img src={userIcon} alt="userImage" onClick={this.onProfileClick}/> */}
                </div>
                <div className="userDetails-cls ">
                    <div className="label-cls" onClick={this.onProfileClick}><p>{personalInfo && personalInfo.name ||userInfo && userInfo.user && userInfo.user.name || `NA`}</p></div>
                    <div className="logout-cls">
                        <p onClick={onLogout}>Logout</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;