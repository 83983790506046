import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import './buyer.css';
import { BUYER_UNDER_WRITING, FILTER_TABLE_RECORDS_API } from '../../utils/Api';
import NewBuyerData from './NewBuyerData';
import { toast } from 'react-toastify';
import Loader from "../../components/CustomLoader";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactHtmlParser from 'html-react-parser';


var names = [];
var i = 1;
export default function BuyerUnderwriting() {

    const [isLoading, setLoader] = useState(false);
    const [Item, setItem] = useState([]);
    const [search, setSearch] = useState('');
    const [searchItem, setSearchItem] = useState([]);
    const [page, setPage] = useState(true);

    const [exportTable, setExport] = useState(false);
    const history = useHistory();
    const newbuyerPage = () => {
        // history.push("/BuyerUnderwriting/newuser");
        history.push("/newbuyer");

    }
    const existingBuyerPage = () => {
        history.push("/existingBuyer")
    }

    const BuyersData = (e) => {
        console.log(e.id);
        history.push(`/newBuyerdata/${e.id}`);
    }

   
    useEffect(() => {
        setLoader(true);
        try {
            fetch(BUYER_UNDER_WRITING, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    "Content-Type": "application/json",
                    'Accept': 'application/json',
                },
            }).then(resp =>
                resp.json()).then(res => {
                    // console.log(res.data);
                    // JSON.parse(JSON.stringify(result.items))
                    let x = JSON.parse(JSON.stringify(res.data));
                    // console.log("data",x);
                    setItem(x);
                    setLoader(false);
                }).catch(err => { toast.error("Connection Interupted - check your Internet Connection") })
        } catch (err) {
            if (err == "404 Not Found") {
                setPage(false);
            }
        }
    }, []);


    const tableExport = (e) => {
        e.preventDefault();
        document.getElementById("reactTable").click();
        // setExport(true);
    }

    return (
        <>
            <Loader loading={isLoading}>

                {/* {
                toast.info("vpn access is needed for 'Buyer Under writing' module Functioning")
            } */}
                <div class="d-flex mt-5 mx-5 container-view" style={{ overflow: "auto", marginBottom: "1rem" }}>
                    <div class="p-2 flex-grow-1  me-md-2">

                        {/* <input type="text" className="form-control" placeholder='search' name='search' value={search} onChange={handleSearch} style={{ width: "60%" }} ></input>
                    <button className='btn btn-primary' onClick={handleSearchFilter}>search</button> */}
                        {/* <form class="example"> */}
                        <input className="search" type="text" placeholder="Search.." name="search" value={search} onChange={event => setSearch(event.target.value)} />
                        {/* <button className='searchButton' type="search" onClick={handleSearchFilter}><i class="fa fa-search"></i></button> */}
                        {/* </form> */}
                    </div>

                    <div class="p-2">
                        <button className="btn btn-success me-md-2" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Download Buyers Data" onClick={tableExport}><i className='fa fa-download'></i>
                            <ReactHTMLTableToExcel
                                className="buyerTable"
                                id="reactTable"
                                table="buyerTable"
                                filename="Buyers Table"
                                display="none"
                                sheet="Sheet"
                                style={{ display: "none" }}
                            />
                        </button>
                        {/* {
                            exportTable == true ?
                                document.getElementById("reactTable").click()
                                : ""
                        } */}
                    </div>
                    <div class="p-2">
                        <button className="btn btn-success me-md-2" type="button" onClick={newbuyerPage}>New Buyer</button>
                    </div>
                    <div class="p-2">

                        <button className="btn btn-success me-md-2" type="button" onClick={existingBuyerPage}>Existing Buyer</button>

                    </div>
                </div>
                <div className="container-fluid ">

                    <table className="table table-striped scroll" >

                        <thead className="table-success scroll">

                            <tr style={{ color: "#198754" }}>

                                <th>S.No</th>
                                <th>Buyer Name</th>
                                <th>Credit Limit / Buyer Limit Request</th>
                                <th>Contact No</th>
                                <th>Credit Period / Payment Terms (Days)</th>
                                <th>ATP Segment</th>
                                <th>Score Decile</th>
                                <th>Email Id</th>
                                <th>Submission Date</th>
                                <th>Finanancial Year</th>
                                <th>Action By</th>
                                <th>Action Date</th>
                                <th>Status</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        {
                            page != false ?

                                <tbody>
                                    {/* {   searchItem!=0?
                            search.length != 0 ? */}
                                    {Item.filter(item => {
                                        // console.log("Buyer Name : "+item.buyerName);
                                        try {
                                            if (search == '') {
                                                // console.log(x);
                                                // names=x.buyerName
                                                return item;
                                            }
                                            else if (item.buyerName != null|| item.atpSegment!=null||item.scoreDecils!=null||item.emailId!=null ) {
                                                if (item.buyerName.toString().toLowerCase().includes(search.toLowerCase())
                                                || item.emailId.toString().toLowerCase().includes(search.toLowerCase())
                                                || item.scoreDecils.toString().toLowerCase().includes(search.toLowerCase())
                                                || item.atpSegment.toString().toLowerCase().includes(search.toLowerCase())
                                                || item.contactNumber.toString().toLowerCase().includes(search.toLowerCase())
                                                || item.status.toString().toLowerCase().includes(search.toLowerCase())
                                                ){
                                                    return item;
                                                }
                                            }
                                        }
                                        catch {

                                        }
                                    }).map((item, key) => {
                                        return (
                                            (<tr key={key + 1}>
                                                <td>{key += 1}</td>
                                                <td>{item.buyerName}</td>
                                                <td>{item.creditLimit}</td>
                                                <td>{item.contactNumber}</td>
                                                <td>{item.creditPeriod}</td>
                                                <td>{item.atpSegment}</td>
                                                <td>{item.scoreDecils}</td>
                                                <td>{item.emailId}</td>
                                                <td>{item.submissionDate}</td>
                                                <td>{ReactHtmlParser(item.financialYear!=null?item.financialYear.replaceAll(",",'<br/>'):"NA")}</td>
                                                <td>{item.userName!=null?item.userName:"NA"}</td>
                                                <td>{item.dateTime!=null?item.dateTime:"NA"}</td>
                                                <td>{item.status}</td>
                                                {/* <Link to={"/newBuyerdata?id="+item.id}> */}
                                                <td><Link><button label="" title="" type="button" className="btn btn-sm btn-primary" onClick={() => BuyersData(item)} ><i className="fa fa-eye"> View</i></button>
                                                </Link></td>

                                            </tr>
                                            )
                                        )
                                    })

                                    }
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan='9'>No Records Available</td>
                                    </tr>
                                </tbody>

                        }
                    </table>
                    <table id="buyerTable" style={{ display: "none" }}>
                        <thead className="table-success scroll">

                            <tr style={{ color: "#198754" }}>

                                <th>S.No</th>
                                <th>Buyer Name</th>
                                <th>CIN/LLP</th>
                                <th>PAN No.</th>
                                <th>Buyer Address</th>
                                <th>Buyer Legal Status</th>
                                <th>Email Id</th>
                                <th>GST No.</th>
                                <th>Existing Limit</th>
                                <th>Projected sales</th>
                                <th>Credit Limit / Buyer Limit Request</th>
                                <th>Credit Period / Payment Terms (Days)</th>
                                <th>Contact No</th>
                                <th>ATP Segment</th>
                                <th>Score Decile</th>
                                <th>Finanancial Year</th>
                                <th>Action By</th>
                                <th>Action Date</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {

                                Item.map((item, key) => {
                                    return (
                                        (
                                            <tr key={key + 1}>
                                                <td>{key += 1}</td>
                                                <td>{item.buyerName}</td>
                                                <td>{item.cinLLP}</td>
                                                <td>{item.pan}</td>
                                                <td>{item.buyersAddress}</td>
                                                <td>{item.legalStatusOfBuyer}</td>
                                                <td>{item.emailId}</td>
                                                <td>{item.gstNo}</td>
                                                <td>{item.existingLimit}</td>
                                                <td>{item.projectedSales}</td>
                                                <td>{item.creditLimit}</td>
                                                <td>{item.creditPeriod}</td>
                                                <td>{item.contactNumber}</td>
                                                <td>{item.atpSegment}</td>
                                                <td>{item.scoreDecils}</td>
                                                <td>{item.financialYear!=null?item.financialYear:"NA"}</td>
                                                <td>{item.userName!=null?item.userName:"NA"}</td>
                                                <td>{item.dateTime!=null?item.dateTime:"NA"}</td>
                                                <td>{item.status}</td>
                                            </tr>
                                        ))
                                })
                            }
                        </tbody>

                    </table>
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
                        integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
                    <title>Origo e-Mandi</title>
                    <script src=
                        "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.0/sweetalert.min.js">
                    </script>
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
                    />
                </div>
                {/* </div>
                </div>
            </div> */}
            </Loader>

        </>
    )
}