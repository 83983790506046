import React, { Component } from 'react';
import CanvasJSReact from '../../../assets/@canvasjs/react-charts';
import forwardCircleArrow from '../../../assets/images/forward-circle-arrow.svg'
import backwardCircleArrow from '../../../assets/images/backward-circle-arrow.svg'
import dropdownArrow from '../../../assets/images/dropdownArrow.svg';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class LineChart extends Component {

    state = {
        // dividerIndex: this.props.listOfPrices && this.props.listOfPrices[0].values && this.props.listOfPrices[0].values.length - 1,
        dividerIndex : 0,
        openDividerTop : false
    };
    componentDidUpdate(prevProps) {
        if (prevProps.key1 !== this.props.key1 || prevProps.key2 !== this.props.key2) {
          this.setState({ dividerIndex: 0, openDividerTop : false });
        }
      }
    handleMoveDivider = (direction) => {
        const { dividerIndex } = this.state;
        const { listOfPrices} = this.props;
    
        if (direction === 'forward') {
          if (dividerIndex < listOfPrices[0].values.length - 1) {
            this.setState({ dividerIndex: dividerIndex + 1 });
          }
        } 
        else if (direction === 'backward') {
          if (dividerIndex > 0) {
            this.setState({ dividerIndex: dividerIndex - 1 });
          }
        }
    };
    handleDataPointClick = (e) => {
        const { dataPointIndex } = e;
        this.setState({ dividerIndex: dataPointIndex });
      };
    handleDividerTopClick = () => {
        this.setState({openDividerTop : !this.state.openDividerTop})
    }

	render() {
        const { dividerIndex } = this.state;
        const { listOfPrices } = this.props;

		const dataPoints = listOfPrices[0].values.map((price, index) => ({
			x: new Date(price.date),    
			y: price.close,
		}));

        const minPrice = Math.min(...dataPoints.map(point => point.y));
        const maxPrice = Math.max(...dataPoints.map(point => point.y));
        const yOffset = (maxPrice - minPrice) * 0.3;

        const dividerDataPoints = [
        { x: dataPoints[dividerIndex].x, y: minPrice - yOffset },
        { x: dataPoints[dividerIndex].x, y: maxPrice + yOffset }
        ];

		const options = {
			animationEnabled: true,
			exportEnabled: false,
			theme: "light2", // "light1", "dark1", "dark2"
			title:{
				text: ""
			},
      axisY: {
        // title: "",
        // includeZero: false,
        // suffix: "",
          lineThickness: 0,
          tickLength: 0,
          gridThickness: 0,
          labelFormatter: () => ""
      },
      axisY2: {
        title: "",
        includeZero: false,
        suffix: "",
        // lineThickness: 0,
        tickLength: 0,
        gridThickness: 0,
        // labelFormatter: () => "",
        stripLines: [],
      },
      axisX: {
        // title: "",
        // prefix: "",
        // interval: 2,
          lineThickness: 0,
          tickLength: 0,
          labelFormatter: () => "" 
      },
      marginRight: 40,
			data: [
            {
                type: "line",
                toolTipContent: `Date {x} : {y}/${this.props.units}`,
                dataPoints: dataPoints,
                color: "#04B23D",
                lineThickness: 1,
                shared: false,
                click: this.handleDataPointClick,
                axisYType: "secondary",
                // markerType: 'none',
                // markerSize: 0,
            },
            {
                type: 'line',
                toolTipContent: "Date {x} : {y}/{this.props.units}",
                dataPoints: dividerDataPoints,
                color: '#D3D3D3',
                markerSize: 0,
                markerType: 'none',
                lineThickness: 1
            }
      ],
            toolTip: {
                enabled: false,
              },
            interactivityEnabled: true,
		}

        // const noteStyle = {
        //     // left: `${((dataPoints.length-dividerIndex) / dataPoints.length) * 100}%`, 
        //     left: `${dividerIndex === 0 ? (((dataPoints.length-dividerIndex) / dataPoints.length) * 92) :dividerIndex === dataPoints.length-1 ? (((dataPoints.length-dividerIndex) / dataPoints.length) * 190):(((dataPoints.length-dividerIndex) / dataPoints.length) * 100)}%`,
        // };

        const getNoteLeft = () => {
            const windowWidth = window.innerWidth;
            const dividerIndex = this.state.dividerIndex;
            const dataPointsLength = dataPoints.length;
      
            if (windowWidth < 450) {
              if (dividerIndex === 0) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 79;
              } else if (dividerIndex === dataPointsLength - 1) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 500;
              }
              else{
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 100;
              }
            } else {
              if (dividerIndex === 0) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 89;
              } else if (dividerIndex === dataPointsLength - 1) {
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 190;
              }
              else{
                return ((dataPointsLength - dividerIndex) / dataPointsLength) * 100;
              }
            }
          };
      
          const noteStyle = {
            left: `${getNoteLeft()}%`,
          };

        function formatDate(date) {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString().slice(-2);
            
            return `${day}/${month}/${year}`;
        }
          


		return (
		<div className='line-chart-div'>
			<CanvasJSChart options = {options}/>
            <div className="button-container">
                <img className="lineChart_divider_move_btn" src={backwardCircleArrow} onClick={() => this.handleMoveDivider('forward')}></img>
                <img className="lineChart_divider_move_btn" src={forwardCircleArrow} onClick={() => this.handleMoveDivider('backward')}></img>
            </div>
            <div className='divider-top' style={noteStyle} onClick={this.handleDividerTopClick}>
                <div className={`divider-top-first ${this.state.openDividerTop ? 'opensize-divider-top-first' : ''}`}>
                    {`₹ ${dataPoints[dividerIndex].y}/${this.props.units} | ${formatDate(new Date(dataPoints[dividerIndex].x))}  `}
                    <img src={dropdownArrow} style={{height: "6px" , marginLeft: "7px"}}></img>
                </div>
                { this.state.openDividerTop ? 
                    <div className='divider-top-second'>
                        <p>Open: {`₹${listOfPrices[0].values[dividerIndex].open}/${this.props.units}`}</p>
                        <p>Close: {`₹${listOfPrices[0].values[dividerIndex].close}/${this.props.units}`}</p>
                    </div>
                    :
                    ""
                }
            </div>
		</div>
		);
	}
}

export default LineChart;                           