import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import CustomButton from '../../components/CustomButton';
import {history} from  "../../utils/Utility"
import "./index.scss";
class EmandiCashTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {}   
    }
    
    render() {
        // const Back =(e)=>{
        //     history.push("/EmandiCash")
        // }
        return (<div className="terms-cls">
            <Scrollbars autoHide={false} style={{ width: "100%", height: '80vh' }} >
           {/* <button onClick={Back} ><span className='Cash_termback'>Back</span></button> */}
           <p className="heading">Terms and Conditions</p>
               <p className='Declaration-cls'>Declaration</p> 
               <p className="Content">
                    <div className="content-cls">
                        <p className="sub-content">
                            1.
                        </p>
                        <p className="sub-content">We declare that I/we are citizen of India and all particulars and information given in this
application form are true correct and complete and no material information has been withheld
or suppressed. </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            2.
                        </p>
                        <p className="sub-content">I/We shall advise Origo Finance in writing of any change in my/our residential or employment
/Business address.

                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            3.
                        </p>
                        <p className="sub-content">
                        I/We confirm that the funds shall be used for the stated purpose and will not be used for the
speculative or anti-social purpose </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            4.
                        </p>
                        <p className="sub-content">
                        I/we authorize Origo Finance to conduct such credit checks as it consider necessary in its sole
discretion to make any enquiries with other finance companies/register credit bureau/other
institution regarding my application and to release such or any other information its records for
the purpose of credit appraisal/sharing for any other purpose. Origo Finance reserves the rights
to retain the photograph and documents submitted with the application and will not return the
same to the applicant.
                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            5.
                        </p>
                        <p className="sub-content">
                         I/We understand that the sanction of this facility is at the sole discretion of Origo Finance and
upon my/our executing necessary securities and other formalities as required by Origo Finance.
I/We further agree that my/our facilities shall be governed by the rules of Origo Finance which
may force from time to time.
                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            6.
                        </p>
                        <p className="sub-content">
                        Payment: No cash/bearer cheque has been collected from me/us upfront towards processing
of the loan application. All payments made to Origo Finance shall be in the name of Origo
Finance Pvt Ltd only.

                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            7.
                        </p>
                        <p className="sub-content">
                        I/We will not be entitled to avail of any benefits/discounts/Free gifts or any other benefits, which
is not documented in the loan agreement.

                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            8.
                        </p>
                        <p className="sub-content">
                        I/We confirm that I/We have no insolvency proceedings against me/us nor have I /We ever
been adjudicated insolvent.


                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            9.
                        </p>
                        <p className="sub-content">
                        I/We confirm that I/we am/are not related to any of the Directors/Senior Officers of Origo
Finance.
                        </p>
                    </div>
                    <div className="content-cls">
                        <p className="sub-content">
                            10.
                        </p>
                        <p className="sub-content">
                        Please note that all the documents submitted along with the application, including photo graph
etc will be retained by Origo Finance for record purposes and will not be returned.
                        </p>
                    </div>
                   
                    <p className="Content">
                    I/We consent to receive information/services etc for Marketing purpose through
telephone/mobile/sms/email by Origo/its employees/agents.
                    </p>
                    <p className="Content">
                    I/We confirm that I/we have read and understood the above declaration and that the details
provided on form are correct.
                    </p>
                </p>
                
            </Scrollbars>
        </div>);
    }
}

export default EmandiCashTerms;