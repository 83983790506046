import React, { Component } from 'react';
import CustomDialogTwo from "../../../components/CustomDialogTwo";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from '../../../components/CustomInputText';

// import './styles.scss';
import './PopUp.scss';


import CustomButton from "../../../components/CustomButton";
import { fireAjaxRequest } from '../../../utils/Utility';
import { SET_CONTACTUS_API } from '../../../utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from '../../../components/CustomLoader';


class CashandCarryConfimPopUp extends Component {
    constructor(props) {
        super(props);

    }
    state = {
        formData: {},
        loading: false
    }
  
    render() {
        let { visible, onHide, onAccept, requestId, formData, bodyContend } = this.props;
        // let bodyContend = ["apple","ball","cat"];
        return (
            <div className="popup-cls">
                <div className="cashandcarryconfirm-dialog">

                    <CustomDialogTwo visible={visible} footer={() => { }} header={  "Done" } onHide={onHide} onCancel={onHide} width="45vw">
                            <div className="cashandcarryconfirm-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                <div className="message-us">
                                    <div className="names-div">
                                        {/* <p>Your request has been sent successfully and your request id is <span className='Id-cls'>{requestId}</span></p>
                                        <p>You will receive a call shortly!</p> */}
                                        {bodyContend && bodyContend.map((element)=>{
                                            return(<p>{element}</p>)
                                        })}
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                    </CustomDialogTwo>
                </div>
            </div>);
    }
}

export default CashandCarryConfimPopUp;