import React, {useEffect,useState} from 'react'
import '../style/Header.css'
import profilePic from '../Images/profilePic.PNG';
import dropdownIcon from '../../../assets/icons/dropdown_icon.png';
import image_congratulations from '../../../assets/images/image_congratulations.png';
import SearchGif from '../../../assets/images/search_gif_image.png';
import Admin from './Admin';
import { LENDER_SELECTION, ADDITIONAL_DOCS } from "../../../utils/Api"
import { history } from "../../../utils/Utility"
import AdditionalDocumentsTwoCrore from './uploadAdditionalDocsTwoCrore'
import Congratulations from './congratulations_two_cr';


function Gif() {
  const [additionalDocsState, setAdditionalDocsState] = useState(false)
  const [dataContent, setDataContent] = useState([])
  
  const handleLenderSelection = () => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
  
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
  
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(LENDER_SELECTION + "ecashRequestId=" + sessionStorage.getItem("eCashRequestId") + "&traderId=" + sessionStorage.getItem("traderID"), requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        additionalDocs(result?.assignedLenderId)
      }
      )
      .catch(error => console.log('error', error));
      // setAdditionalDocsState(true)
  }
  useEffect(() => {
    handleLenderSelection()
  })

  
  const additionalDocs = (lenderId) => {
    setAdditionalDocsState(true)
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
  
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
  
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    fetch(ADDITIONAL_DOCS + "lenderId=" + lenderId + "&ecashLimit=2Crore&organizationType=" + sessionStorage.getItem("orgType").toUpperCase(), requestOptions)
      .then(response => response.text())
      .then(result =>
        {
          result = JSON.parse(result)
         setDataContent(result?.additionalDocs)
        }
          )
      .catch(error => console.log('error', error));
  }

 
 
  return (
      additionalDocsState == false ?   
      <>
       <div className='row '>
       <div className='col-md-12' style={{ textAlign: "center" }}>
       <Admin />
       <nav aria-label="" style={{ paddingLeft: '10px',  float: 'left' }}>
       <div className="container " >
       <p className="backbtn"
       >
       <i className="fa fa-arrow-left"></i>
       <span style={{ color: '#757575' }} className='breadcrumbsLabel'>&nbsp; Back &nbsp;</span>
       </p>
       </div>
       </nav>
       <div class ="image-style">
       <img src={SearchGif} />
       </div>
       <div className='review-document'>
       We're reviewing your documents to assign  your limit.
       </div>
       </div>
       </div>
       </>
      : dataContent != undefined ? <AdditionalDocumentsTwoCrore dataContent={dataContent} /> : <Congratulations />
  )
}

export default Gif