import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";

// import './styles.scss';


import CustomButton from "../../../components/CustomButton";
import { toast } from 'react-toastify';


class ConfirmDeleteDailog extends Component {

    render() {
        const { visible, onHide, onCancel,onAcceptKycPopup,onHideKycPopup } = this.props;
    
        return (
            <div className="popup-cls">
                <div className="buy-now-dialog">
                    <CustomDialog visible={visible} footer={() => { }} header={<p style={{"fontSize":"14px"}}>Please complete your KYC...!</p>} onHide={onHideKycPopup} onCancel={onHideKycPopup} width="35vw">
                            <div className="buynow-cls">
                                <div className="message-us">
                                    <div className="btns-cls">
                                        {/* <CustomButton label="Cancel" className="cancel" config={{ onClick: onHideKycPopup }} /> */}
                                        <CustomButton label="Ok" className="send" config={{ onClick: onAcceptKycPopup }} />
                                    </div>
                                </div>
                            </div>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default ConfirmDeleteDailog;