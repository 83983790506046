import React, { useState } from 'react'
import './buyer.css';
import { useHistory, Link } from "react-router-dom";
import ModelForm from './ModelForm';
import { toast } from 'react-toastify';
import { waitFor } from '@testing-library/react';
import { element } from 'prop-types';
import { procLink_api, tempProc_link } from '../../utils/Api';

var panType = '';

const re = /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/;
var tF = false;
export default function NewBuyerPage() {
  const [data, setData] = useState([])
  const [llpdata, setllpData] = useState([])
  const [cin, setCin] = useState()
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [Pan, setPan] = useState('');
  const [creditLimit, setFormData] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [filterdata, setFilterdata] = useState([])
  var companyArray = [];
  var llpArray = [];


  const submitHandler = (e) => {
    e.preventDefault();
    console.log(data, 'Buyer data');
  }

  const history = useHistory();
  const existingBuyerPage = () => {
    history.push("/existingBuyer")
  }
  const newbuyerPage = () => {
    // history.push("/BuyerUnderwriting/newuser");
    history.push("/newbuyer");

  }
  const panCardChange = (e) => {
    if (e.target.value.length > 4) {
      if (re.test(e.target.value)) {
        tF = true;

        var pan = e.target.value;
        setPan(pan);
        // console.log(Pan);
        var fourthChar = pan[3].toUpperCase();
        var orgType = {
          "C": "Company",
          "P": "Person",
          "H": "Hindu Undivided Family",
          "F": "Firm",
          "A": "Association of Persons",
          "T": "Trust",
          "B": "Body of Individual",
          "L": "Local Authority",
          "J": "Artificial Juridical Person",
          "G": "Government"
        };
        panType = document.getElementById("organization").value = orgType[fourthChar];
        console.log(panType);
      } else {
        tF = false;
        // setLoader(false);
        var pan = e.target.value;
        setPan(pan);
        // toast.error("Please enter a valid PAN Number");
      }
    }
    else {
      document.getElementById("organization").value = "";
    }
  }
  const getCreditLimit = (e) => {
    if (Pan == '' && name == '') {
      toast.error("Please enter Buyer Name or PAN to get details.")
    }
    else if(Pan!=0&&!re.test(Pan)){
      toast.error("Please enter a valid PAN Number")
    }
    else {

      

      var x = Pan;
      var regNo = document.getElementById("cpin").value;
      var PanNo = document.getElementById("panNunmber").value;
      var url = "";
      var isPan = false;

      
      if (Pan.length != 0) {

        if (re.test(Pan)) {


          if (panType == "Company" || panType == "Firm") {
            if (panType == "Firm") {
              url = `${tempProc_link}?entityType=LLP&searchByNumberType=PAN&registredNumber=` + Pan
            } else {

              url = `${tempProc_link}?entityType=Company&searchByNumberType=PAN&registredNumber=` + Pan
            }
          }
          else {
            toast.info("Entity must be of Type Company or LLP")
          }
        } else {
          toast.error("Valid Pan");
        }
        // }
      } else {


        if (regNo.length > 0) {
          if (type == "Company") {         
            url = `${tempProc_link}?entityType=Company&searchByNumberType=CIN&registredNumber=` + regNo;
          }
          else {
            url = `${tempProc_link}?entityType=LLP&searchByNumberType=LLPIN&registredNumber=` + regNo;
          }
        }

        else {
         
        }
      }
      // console.log(url + regNo);
      setLoader(true);
      document.getElementById("myModal").style.display = "none";
      try {
        fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },

        }).then(resp =>
          resp.json()).then(res => {
            console.log(res.message)
            try {

              if (res.message != undefined) {
                alert(res.message);
                window.location.reload();
                setLoader(false);



                // setTimeout(() => {
                // console.log('Hello, World!')
                // }, 0);
              }
              else if (res.message == "Invalid PAN/ Invalid CIN/ Invalid LLPIN") {
                setLoader(false);
                setTimeout(() => {
                  // console.log('Hello, World!')
                  window.location.reload();
                }, 0);
              }
              else {
                console.log(res);
                // document.getElementById("id").value = res.creditLimit.buyer_name
                document.getElementById("buyername").value = res.creditLimit.buyer_name
                document.getElementById("cin").value = document.getElementById("cpin").value
                document.getElementById("amount").value = res.creditLimit.credit_limit
                document.getElementById("panNumber").value = res.creditLimit.buyer_pan
                document.getElementById("contactNumber").value = res.creditLimit.contact_details.phone
                document.getElementById("buyerAddress").value = res.creditLimit.buyer_address
                document.getElementById("buyerStatus").value = res.creditLimit.legal_status
                document.getElementById("email").value = res.creditLimit.contact_details.email
                document.getElementById("contact_person").value = res.creditLimit.contact_details.name
                document.getElementById("gstNo").value = res.creditLimit.gst_number
                document.getElementById("atpSegment").value = res.creditLimit.atp_segment
                document.getElementById("score").value = res.creditLimit.score_deciles
                document.getElementById("financialYear").value = res.creditLimit.financial_year
                // document.getElementById("limit").value=res.creditLimit.credit_limit
                document.getElementById("sales").value = res.creditLimit.credit_limit * 12
                setLoader(false);
                document.getElementById("myModal").style.display = "block";
                sessionStorage.setItem("credit_limit_obj", res.creditLimit);
              }
            } catch (error) {
              toast.error("Network connection problem - please check your connection");
            }
          }).catch(err => {
            console.log(err);
            if (err == 'TypeError: Failed to fetch') {
              toast.error("Network connection problem - please check your connection");
            } else {
              setName('')
              setLoader(false);
              // if (err == "Unexpected token < in JSON at position 0") {
              toast.error(`No data available for Buyer Name - ${name}`)
              setTimeout(() => {
                // console.log('Hello, World!')
                window.location.reload();
              }, 5000);
            }
            // }
          })
      } catch (error) {
        toast.error("Network connection problem - please check your connection");
      }
    }
  }


  // const buyerNameBlur=(e)=>{
  //   var buyerName=e.target.value;
  //   var obj= data.find(x=>x.legal_name===buyerName);
  //   if(obj!=undefined)
  //   {
  //     setType("Company");
  //     setCin(obj.cin);
  //   }
  //   else
  //   {
  //       var obj= llpdata.find(x=>x.legal_name===buyerName);
  //       if(obj!=undefined)
  //       {
  //          setCin(obj.llpin);
  //          setType("LLP")
  //       }
  //       else
  //       {
  //           setCin("");
  //       }
  //   }
  // }



  const buyerNameChange = (e) => {

    e.preventDefault();
    // console.log(e.target.value);
    // let wait=false;
    setName(e.target.value);

    var n = document.getElementById("name").value;
    // console.log(n);
    // var token="Ovc0NQth3YaF04YDwJp9c5ks7b5MLD3v1ZuHKHpW";

    var searchItem = `%7B%22nameStartsWith%22%3A%22${n}%22%7D`;
    var sandbox_url = "https://api.probe42.in/probe_pro_sandbox/entities?limit=25&filters=" + n;
    var prod_url = "https://api.probe42.in/probe_pro/entities?limit=25&filters=" + searchItem;
    var sandbox_key = "Ovc0NQth3YaF04YDwJp9c5ks7b5MLD3v1ZuHKHpW";
    var prod_key = "qjTYIaIuhF6LR0FJpR0iHTSQB9j5gyu8HaWBEUie";
    try {
      if (name.length > 2) {

        // if(wait==true){
        fetch(sandbox_url, {
          method: 'GET',
          headers: {
            'x-api-key': sandbox_key,
            "x-api-version": "1.3",
            'Accept': 'application/json',
          },



        }).then(resp =>
          resp.json()).then(res => {
            try {
              res.data.entities.companies.map((item) => {
                var obj = { cin: item.cin, legal_name: item.legal_name }
                companyArray.push(obj);
              })
              setData(companyArray);
            }
            catch
            {

            }

            try {
              res.data.entities.llps.map((item) => {
                var obj = { llpin: item.llpin, legal_name: item.legal_name }
                llpArray.push(obj);
              })
              setllpData(llpArray);
            }
            catch
            {

            }
          })

        var buyerName = e.target.value;
        var obj = data.find(x => x.legal_name === buyerName);
        if (obj != undefined) {
          setType("Company");
          setCin(obj.cin);
        }
        else {
          var obj = llpdata.find(x => x.legal_name === buyerName);
          if (obj != undefined) {
            setCin(obj.llpin);
            setType("LLP")
          }
          else {
            setCin("");
          }
        }
        // }
      }
    }
    catch
    {

    }
  }

  function panCheck() {
    // toast.error("Please enter a valid PAN Number");
    setTimeout(() => {
      // console.log('Hello, World!')
      window.location.reload();
    }, 3000);
  }

  return (
    <>

      <div className="d-flex mt-4 mx-3  flex-md-row">
        <div className="p-2 flex-grow-1 ">
          &nbsp;&nbsp;&nbsp;&nbsp;<span class="glyphicon glyphicon-arrow-left" style={{ color: "#5cb85c" }}></span>&nbsp;
          {/* <button className='border-none' onClick={redirectPage}><strong>Back</strong></button> */}
          <Link to="/BuyerUnderwriting" className="link-dark">Back</Link>
          {/* href="/BuyerUnderwriting" className="link-dark"><strong>Back</strong></a> */}
        </div>
        <div className="p-2">
          <button className="btn btn-success btn-md" type="button" onClick={newbuyerPage}><strong>New Buyer</strong></button>
        </div>
        <div className="p-2">
          <button className="btn btn-success btn-md " type="button" onClick={existingBuyerPage}><strong>Existing Buyer</strong></button>
        </div>
        <hr />
      </div>
      <div className='border-bottom my-4 mx-2'>    </div>
      <div className='mx-5 '>
        <h3><strong>New Buyer</strong></h3>
        <br></br>
        {

          Pan.length == 0 && name.length == 0 ?
            <form className="row g-3 mt-5 needs-validation">
              <div>
                <div className="col-md-4">
                  <label for="buyername" className="form-label">Buyer Name</label>
                  <input placeholder='Enter min. 4 letters' autocomplete="off" value={name} list="entities" onChange={buyerNameChange} className="form-control" name="entities" id='name' />
                  <datalist id="entities">
                    {
                      data.map((item) => (
                        <option value={item.legal_name}>{item.cin}</option>
                      ))

                    }

                    {
                      llpdata.map((item) => (
                        <option value={item.legal_name}>{item.llpin}</option>
                      ))
                    }
                  </datalist>



                </div>
                <div className="col-md-4">
                  <label for="cpin" className="form-label">CPIN/LLPIN</label>
                  <input type="text" value={cin} disabled className="form-control" placeholder='Populated on selection of Buyer Name' id="cpin" name='cpin' />
                </div>
              </div>
              <div className='col-md-12 mt-4 mx-2'>
                <strong className="pull-left">Buyer Entity Type</strong>
                {
                  type === "Company" ?
                    <div className="pull-left" style={{ marginLeft: "20px" }}>
                      <input className="form-check-input" type="checkbox" checked disabled id="corporate" name="entityType" value='corporate' />
                      <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                        &nbsp; Corporate
                      </label>
                      <input className="form-check-input" style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                      <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                    </div>
                    : type === "LLP" ?
                      <div className="pull-left" style={{ marginLeft: "20px" }}>
                        <input className="form-check-input" type="checkbox" disabled id="corporate" name="entityType" value='corporate' />
                        <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                          &nbsp; Corporate
                        </label>
                        <input className="form-check-input" checked style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                        <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                      </div>
                      :
                      <div className="pull-left" style={{ marginLeft: "20px" }}>
                        <input className="form-check-input" type="checkbox" disabled id="corporate" name="entityType" value='corporate' />
                        <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                          &nbsp; Corporate
                        </label>
                        <input className="form-check-input" style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                        <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                      </div>



                }

              </div>
              <div className="col-md-8">
                <h6></h6>
              </div>
              <div>
                <div className="col-md-4">
                  <label for="panNunmber" className="form-label">Enter PAN Number</label>
                  <input type="text" onBlur={panCardChange} className="form-control" placeholder='PETPBXXXXX' id="panNunmber"
                    name='pancard' />
                </div>
                <div className="col-md-4">
                  <label for="organization" className="form-label">Organization Type</label>
                  <input type="text" className="form-control" disabled placeholder='Proprietorship' id="organization" name='organization' />
                </div>
              </div>
              <div className="col-auto mx-4 mt-5 ">
                <button type="button" data-bs-toggle="modal" data-bs-target="#myModal" onClick={getCreditLimit} className="btn btn-success px-5">Submit</button>
              </div>
            </form>
            : Pan.length != 0 ?
              <form className="row g-3 mt-5 needs-validation">
                <div>
                  <div className="col-md-4">
                    <label for="buyername" className="form-label">Buyer Name</label>
                    <input placeholder='Enter min. 4 letters' autocomplete="off" disabled value={name} list="entities" onChange={buyerNameChange} className="form-control" name="entities" id='name' />
                    <datalist id="entities">
                      {
                        data.map((item) => (
                          <option value={item.legal_name}>{item.cin}</option>
                        ))

                      }

                      {
                        llpdata.map((item) => (
                          <option value={item.legal_name}>{item.llpin}</option>
                        ))
                      }
                    </datalist>



                  </div>
                  <div className="col-md-4">
                    <label for="cpin" className="form-label">CPIN/LLPIN</label>
                    <input type="text" value={cin} disabled className="form-control" placeholder='Populated on selection of Buyer Name' id="cpin" name='cpin' />
                  </div>
                </div>
                <div className='col-md-12 mt-4 mx-2'>
                  <strong className="pull-left">Buyer Entity Type</strong>
                  {
                    type === "Company" ?
                      <div className="pull-left" style={{ marginLeft: "20px" }}>
                        <input className="form-check-input" type="checkbox" checked disabled id="corporate" name="entityType" value='corporate' />
                        <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                          &nbsp; Corporate
                        </label>
                        <input className="form-check-input" style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                        <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                      </div>
                      : type === "LLP" ?
                        <div className="pull-left" style={{ marginLeft: "20px" }}>
                          <input className="form-check-input" type="checkbox" disabled id="corporate" name="entityType" value='corporate' />
                          <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                            &nbsp; Corporate
                          </label>
                          <input className="form-check-input" checked style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                          <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                        </div>
                        :
                        <div className="pull-left" style={{ marginLeft: "20px" }}>
                          <input className="form-check-input" type="checkbox" disabled id="corporate" name="entityType" value='corporate' />
                          <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                            &nbsp; Corporate
                          </label>
                          <input className="form-check-input" style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                          <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                        </div>



                  }



                </div>
                <div className="col-md-8">
                  <h6></h6>
                </div>
                <div>
                  <div className="col-md-4">
                    <label for="panNunmber" className="form-label">Enter PAN Number</label>
                    <input type="text" onBlur={panCardChange} className="form-control" placeholder='PETPBXXXXX' id="panNunmber"
                      name='pancard' />
                  </div>
                  <div className="col-md-4">
                    <label for="organization" className="form-label">Organization Type</label>
                    <input type="text" className="form-control" disabled placeholder='Proprietorship' id="organization" name='organization' />
                  </div>
                </div>
                <div className="col-auto mx-4 mt-5 ">
                  <button type="button" data-bs-toggle="modal" data-bs-target="#myModal" onClick={getCreditLimit} className="btn btn-success px-5">Submit</button>
                </div>
              </form> :
              <form className="row g-3 mt-5 needs-validation">
                <div>
                  <div className="col-md-4">
                    <label for="buyername" className="form-label">Buyer Name</label>
                    <input placeholder='Enter min. 4 letters' autocomplete="off" value={name} list="entities" onChange={buyerNameChange} className="form-control" name="entities" id='name' />
                    <datalist id="entities">
                      {
                        data.map((item) => (
                          <option value={item.legal_name}>{item.cin}</option>
                        ))

                      }

                      {
                        llpdata.map((item) => (
                          <option value={item.legal_name}>{item.llpin}</option>
                        ))
                      }
                    </datalist>



                  </div>
                  <div className="col-md-4">
                    <label for="cpin" className="form-label">CPIN/LLPIN</label>
                    <input type="text" value={cin} disabled className="form-control" placeholder='Populated on selection of Buyer Name' id="cpin" name='cpin' />
                  </div>
                </div>
                <div className='col-md-12 mt-4 mx-2'>
                  <strong className="pull-left">Buyer Entity Type</strong>
                  {
                    type === "Company" ?
                      <div className="pull-left" style={{ marginLeft: "20px" }}>
                        <input className="form-check-input" type="checkbox" checked disabled id="corporate" name="entityType" value='corporate' />
                        <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                          &nbsp; Corporate
                        </label>
                        <input className="form-check-input" style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                        <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                      </div>
                      : type === "LLP" ?
                        <div className="pull-left" style={{ marginLeft: "20px" }}>
                          <input className="form-check-input" type="checkbox" disabled id="corporate" name="entityType" value='corporate' />
                          <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                            &nbsp; Corporate
                          </label>
                          <input className="form-check-input" checked style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                          <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                        </div>
                        :
                        <div className="pull-left" style={{ marginLeft: "20px" }}>
                          <input className="form-check-input" type="checkbox" disabled id="corporate" name="entityType" value='corporate' />
                          <label className="form-check-label radio-font" style={{ color: "#000", opacity: "1" }}>
                            &nbsp; Corporate
                          </label>
                          <input className="form-check-input" style={{ marginLeft: "20px" }} type="checkbox" disabled id="llpRadio" name="entityType" value='llp' />
                          <label className="form-check-label radio-font text-dark" style={{ color: "#000", opacity: "1" }}>&nbsp; LLP</label>
                        </div>



                  }



                </div>
                <div className="col-md-8">
                  <h6></h6>
                </div>
                <div>
                  <div className="col-md-4">
                    <label for="panNunmber" className="form-label">Enter PAN Number</label>
                    <input type="text" disabled onBlur={panCardChange} className="form-control" placeholder='PETPBXXXXX' id="panNunmber"
                      name='pancard' />
                  </div>
                  <div className="col-md-4">
                    <label for="organization" className="form-label">Organization Type</label>
                    <input type="text" className="form-control" disabled placeholder='Proprietorship' id="organization" name='organization' />
                  </div>
                </div>
                <div className="col-auto mx-4 mt-5 " style={{ marginBottom: "5rem" }}>
                  <button type="button" data-bs-toggle="modal" data-bs-target="#myModal" onClick={getCreditLimit} className="btn btn-success px-5">Submit</button>
                </div>
              </form>
        }

      </div>

      {/* <!-- The Modal --> */}

      {
        name == '' && Pan == '' ?
          '' :
          Pan != '' ? tF == true ?
            <div className="modal modal-dialog-scrollable " id="myModal" style={{ overflow: "auto" }}>
              <div className="modal-dialog modal-fullscreen-sm-down" role='document' style={{ maxWidth: "1250px" }} >
                <div className="modal-content">

                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                    <button type="button" className="btn-close btn-close-success" data-bs-dismiss="modal" ></button>
                  </div>

                  {/* <!-- Modal body --> */}
                  <div className="modal-body" style={{ overflow: "auto" }}>
                    <div >
                      <ModelForm isLoading={isLoading} />
                    </div>
                  </div>

                </div>
              </div>
            </div> :
            // toast.error("Please enter a valid PAN Number")
            panCheck()
            :
            <div className="modal modal-dialog-scrollable " id="myModal" style={{ overflow: "auto" }}>
              <div className="modal-dialog modal-fullscreen-sm-down" role='document' style={{ maxWidth: "1250px" }} >
                <div className="modal-content">

                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                    <button type="button" className="btn-close btn-close-success" data-bs-dismiss="modal" ></button>
                  </div>

                  {/* <!-- Modal body --> */}
                  <div className="modal-body" style={{ overflow: "auto" }}>
                    <div >
                      <ModelForm isLoading={isLoading} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
      }
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous" />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
      <title>Origo e-Mandi</title>
      <script src=
        "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.0/sweetalert.min.js">
      </script>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
      />


    </>
  )
}



