import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import CustomDropDown from "../../../components/CustomDropdown";
import CustomInputArea from "../../../components/CustomInputArea";

class BankDeatilsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        let {visible,onHide,onAccept,onCancel,formData,handelInputChange} = this.props;

        return ( <div className="BankDeatilsDialog">
        <CustomDialog visible={visible} onHide={onHide} btn1={"Cancel"} btn2={"Continue"} onAccept={onAccept} onCancel={onCancel} header={"Bank Details"}>
            <div className="bank-details-wrapper">
                <div>
                    <p class="account-type">Bank Name</p>
                    <p class="account-type-details">{formData && formData.bank}</p>
                    <p class="account-type">Account Number</p>
                    <p class="account-type-details">{formData && formData.acNumber}</p>
                    <p class="account-type">IFSC</p>
                    <p class="account-type-details">{formData && formData.ifsccode}</p>
                </div>
                <div>
                    <p class="account-type">Withdraw Amount</p>
                    <p class="account-type-details">₹{formData && formData.Withdraw}</p>
                    <CustomDropDown name="transType" value={formData.transType} label="Transaction Type" onChange={handelInputChange} options={[{"label":"IMPS","value":"IMPS"},{"label":"NEFT","value":"NEFT"},{"label":"RTGS","value":"RTGS"}]} />
                </div>
            </div>
            <CustomInputArea label="Remark" name="transRemark" value={formData.transRemark} config={{
                onChange:(e) => handelInputChange(e)
            }} />
            
        </CustomDialog>
    </div> );
    }
}
 
export default BankDeatilsDialog;