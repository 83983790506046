import { useEffect, useState } from 'react';
import './lenders.css'
import { SET_LENDER_DATA_API } from '../../../utils/Api';
import { fireAjaxRequest } from '../../../utils/Utility';
import { toast } from 'react-toastify';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
  }
const AddLender = ({ setOpenModal ,hideAddLender}) => {
    const forceUpdate = useForceUpdate();
    const [mouDate, setMouDate] = useState('01/06/2023')
    const [mouRenewal, setMouRenewal] = useState('')

    const [lenderData,setLenderData]=useState({})
   
    function formatDate(date) {
        var day, month, year;
        date=date.split("-")
        year = date[0]
        month = date[1]
        day = date[2]
        
        return day + '/' + month + '/' + year;
      }

    const handleInputChange=(e)=>{
        var{name,value}=e.target

        if(name==='name' ||  name==='email'){
                setLenderData((prevData) => ({
                    ...prevData,
                    [name]: value
                }));
            }

        else {
        var v = parseFloat(value);
        if (isNaN(v)) {
            value = '';
        } else {
            if (value.includes(".") && value.indexOf(".") < value.length - 4) {
                value = v.toFixed(3);
            }
        }

        if (name === 'totalFunds' || name === 'availableFunds') {

            if (Number(value) < 0) {
                alert('Negative Values not allowed')
                return;
            }

            if (name === 'availableFunds') {
                const totalFunds = parseFloat(lenderData.totalFunds);
                const availableFunds = parseFloat(value);

                if (availableFunds > totalFunds) {
                    alert('Available value should be less than than total funds')
                    return;
                }
            }

            if (name === 'totalFunds') {
                const availableFunds = parseFloat(lenderData.availableFunds);
                const totalFunds = parseFloat(value);

                if (availableFunds > totalFunds) {
                    alert('Available value should be less than than total funds')
                    return;
                }
            }

            setLenderData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
        else{
            if (name === 'interestRate' || name === 'invoiceFinances' || name === 'processingFees') {
            
                if (Number(value) < 0) {
                    alert('Negative value not allowed')
                    return;
                }

                
            if(name==='interestRate' && Number(value)>100){
                alert('Interest Rate should be less than or equal to 100')
                return
            }

            if(name==='invoiceFinances' && Number(value)>100){
                alert('Invoice Finances should be less than or equal to 100')
                return
            }

            if(name==='processingFees' && Number(value)>100){
                alert('Processing Fees should be less than or equal to 100')
                return
            }

                setLenderData((prevData) => ({
                    ...prevData,
                    [name]: value
                }));
            }
        }
    }
 }

    const handleSubmit = async () => {

        if(!lenderData.name || !lenderData.email || !sessionStorage.getItem("mouDate") || !lenderData.totalFunds || !lenderData.availableFunds || !lenderData.invoiceFinances || !lenderData.processingFees || !sessionStorage.getItem("mouDateRenewal")){
            alert('Please fill all the fields')
            return
        }

        if(!/\S+@\S+\.\S+/.test(lenderData?.email)){
            alert('Please enter valid email')
            return
        }

        let lenderDatas = {
            name: lenderData.name,
            email: lenderData.email,
            mouDate: sessionStorage.getItem("mouDate"),
            totalFunds: lenderData.totalFunds,
            availableFunds: lenderData.availableFunds,
            interestRate: lenderData.interestRate,
            processingFees: lenderData.processingFees,
            mouDateOnRenewal: sessionStorage.getItem("mouDateRenewal"),
            invoiceFinances: lenderData.invoiceFinances
        }

        if (window.confirm(`Are sure Available Fund ${lenderData.availableFunds}`) == true) {
            try {
                const resp = await fireAjaxRequest(SET_LENDER_DATA_API, {
                    method: 'POST',
                    body: JSON.stringify(lenderDatas),
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                        "Content-Type": "application/json"
                    }
                });

                if (resp) {
                    toast.success('Lender Created Successfully ');
                    window.location.reload();
                }

            } catch (err) {
                console.log('err92', err)
            }
        } else {
            return
        }
        setOpenModal(false)
        hideAddLender(true)
    }

    const handleMouDate = (e) => {
       var dt= formatDate(e.target.value)
        setMouDate(dt)
        sessionStorage.setItem("mouDate",dt)
    }

    const handleMouRenewal = (e) => {
        var dt= formatDate(e.target.value)
        setMouRenewal(dt)
        sessionStorage.setItem("mouDateRenewal",dt)
    }

    const handleCalculate = () => {
        return parseFloat(((parseFloat(lenderData.interestRate) + parseFloat(lenderData.processingFees)))) / parseFloat(lenderData.invoiceFinances)
    }
    
    useEffect(() => {
        const handleWheel = (e) => {
            e.preventDefault();
        };

        const inputs = document.querySelectorAll('input[type="number"]');
        inputs.forEach((input) => {
            input.addEventListener('wheel', handleWheel);
        });

        return () => {
            inputs.forEach((input) => {
                input.removeEventListener('wheel', handleWheel);
            });
        };
    }, []);

    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                            hideAddLender(true)
                        }}
                    >
                        X
                    </button>
                </div>

                <div className='main-container'>
                    <div className="form-container">
                        <div className='mutli-container'>
                            <div className='singleContainer'>
                                <label for="lenderName" className="form-label-lender">Lender Name</label>
                                <input type="text" className="input-container" placeholder='Enter Lender Name' id="lenderName" value={lenderData.name} name='name' onChange={handleInputChange}  />
                            </div>
                            <div className='singleContainer'>
                                <label for="email" className="form-label-lender">Lender Email Id</label>
                                <input type="email" className="input-container" placeholder='Enter Lender Email id' id="lenderEmail" value={lenderData.email} name="email" onChange={handleInputChange} required={true}  />
                            </div>
                        </div>
                        <div className='mutli-container'>
                            <div className='singleContainer'>
                                <label for="mouDate" className="form-label-lender">Select MoU Date</label>
                                <input type="date" className="input-container" placeholder='MoU Date' id="MouDate" name='MouDate' onChange={(e) => handleMouDate(e)} required />
                            </div>
                            <div className='singleContainer'>
                                <label for="renewalDate" className="form-label-lender">MoU Date(On Renewal)</label>
                                <input type="date" className="input-container" placeholder='MoU Date(On Renewal)' id="renewalDate" name='renewalDate' onChange={handleMouRenewal} required />
                            </div>
                        </div>

                        <div className='mutli-container'>
                            <div className='singleContainer'>
                                <label for="totalFund" className="form-label-lender">Total Funds</label>
                                <input type="number" className="input-container" placeholder='Enter Total Funds' id="totalFunds" value={lenderData.totalFunds} name='totalFunds' onChange={handleInputChange} required />
                            </div>
                            <div className='singleContainer'>
                                <label for="availfund" className="form-label-lender">Available Funds</label>
                                <input type="number" className="input-container" placeholder='Enter Available Funds' value={lenderData.availableFunds} id="availablefund" name='availableFunds' onChange={handleInputChange} required />
                            </div>
                        </div>
                        <div className='mutli-container'>
                            <div className='singleContainer'>
                                <label for="interestRate" className="form-label-lender">Interest Rate (In %)</label>
                                <input type="number" className="input-container" placeholder='Enter Interest Rate' id="interestRate" name='interestRate' value={lenderData.interestRate} onChange={handleInputChange} required />
                            </div>
                            <div className='singleContainer'>
                                <label for="processingFee" className="form-label-lender">Processing Fees (In %)</label>
                                <input type="number" className="input-container" placeholder='Enter Processing Fees' value={lenderData.processingFees} id="processingFee" name='processingFees' onChange={handleInputChange} required />
                            </div>
                        </div>

                        <div style={{ marginLeft: '6px' }} className='mutli-container'>
                            <div className='singleContainer'>
                                <label for="invoiceFinance" className="form-label-lender">Invoice Financed (In %)</label>
                                <input type="number" className="input-container" placeholder='Enter Invoice Financed' id="invoiceFinance" value={lenderData.invoiceFinances} name='invoiceFinances' onChange={handleInputChange} required />
                            </div>
                            <div className='singleContainer'>
                                <label for="invoiceFinance" className="form-label-lender">IRR </label>
                                <input type='number' className='input-container' value={handleCalculate().toFixed(2) || 0} disabled={true} />
                            </div>
                            <button style={{ marginLeft: '8px' }} onClick={() => handleSubmit()} className='submitLender'>Submit </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddLender;