import React, { Component } from 'react'
import './styles.scss';
import List from './view/List'
import { history, fireAjaxRequest } from "../../utils/Utility";
import {GET_EMANDI_TV_ID, } from '../../utils/Api'
import { toast } from 'react-toastify';
import path from 'path';

export class EmanditvList extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        formData: {},
        showTvpopUp: false,
        listData:[]
      }
      // this.state = {
      //   formData: {},
      //   showTvpopUp: false,}
    }
    componentDidMount(){
      this.getEmandiTVlist();
    }

    getEmandiTVlist = () => {
      let { formData } = this.state;
      let Pathlocation = history.location.pathname;
      let Path = Pathlocation.replace("/EmanditvList/", "");
      let emandiURL = `${GET_EMANDI_TV_ID}/getById?tvId=${13}`;
      // get?offset=${0}&limit=${100}
      if (formData.time) {
        emandiURL = `${emandiURL}&fromDate=${formData.time}`;
      }
  
      (async () => {
        // this.setLoading(true);
        try {
          let apiUrl = `${emandiURL}`;
          const response = await fireAjaxRequest(`${apiUrl}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            },
          });
          // this.setLoading(false);
          if (response) {
            this.setState({ emandiData: response.data });
          } else {
          }
        } catch (err) {
          // this.setLoading(false);
          //console.log("Failed to load trader Details !");
          toast.error(err.message || "Failed to load !");
        }
      })();
    };
    // -------------------------------------------------------------------
    // EmanditvList=()=>{
    //   let Pathlocation = history.location.pathname;
    //     let Path = Pathlocation.replace("/EmanditvList/", "");
    //     (async () => {
    //         // this.setLoading(true);
    //         try {

    //             const response = await fireAjaxRequest(`${GET_EMANDI_TV_ID}/${Path}`, {
    //                 method: 'GET',
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
    //                 },
    //             });
    //             if(response){
    //               this.setState({listData:response.data})
    //             }
    //         } catch (err) {
    //             // this.setLoading(false);
    //             //console.log("Failed to load trader Details !");
    //             toast.error(err.message || "Failed to load !");

    //         }
    //     })();
    // }
    onClickBack = () => {
        // sessionStorage.setItem("isemandinewspage", true);
        history.push('/EmandiTV');

    }
  render() {
    return (
      <List onClickBack={this.onClickBack} state={this.state}/>
    )
  }
}

export default EmanditvList;