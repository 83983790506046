import React, { Component } from 'react';
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomInputText from '../../../components/CustomInputText';
import eMandiCash from '../../../assets/images/eMandi Cash.svg'

// import './styles.scss';
import './PopUp.scss';


import CustomButton from "../../../components/CustomButton";
import { fireAjaxRequest } from '../../../utils/Utility';
import { SET_CONTACTUS_API } from '../../../utils/Api';
import { toast } from 'react-toastify';
import CustomLoader from '../../../components/CustomLoader';


class buynowPopUp extends Component {
constructor(props) {
  super(props)

  this.state = {
    userInfo : JSON.parse(sessionStorage.getItem("userInfo"))
  }
}
    render() {
        const { visible, onHide, onAccept, handelInputChange, formData, onSubmitBuynow, state,onClickEmandicashContinue } = this.props;
        return (
            <div className="popup-cls">
                <div className="E-mandicash-dialog">
                    <CustomDialog visible={visible} footer={() => { }} header={()=>{}} onHide={onHide} onCancel={onHide} width="50vw">
                                <div className="heading-cls">
                                    <div className='header'>
                                    <div>
                                            <span className='user-name'>Hey {this.state.userInfo?this.state.userInfo.user.name:"User"},</span>
                                            <p>Welcome to <span>e-Mandi Cash</span></p>
                                        </div>
                                        <div>
                                            <img src={eMandiCash} />
                                        </div>
                                    </div>
                                        
                                    <div className="list-cls">
                                        <div className='menu-cls'>
                                            <div><i className="pi pi-check-circle"></i> <p>Collateral free</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Upto <i class="fa fa-inr"></i> 2 crores cash for your supplies</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Payment confirmation in just one day</p></div>
                                        </div>
                                        <div  className='menu-cls'>
                                       <div> <i className="pi pi-check-circle"></i>  <p>Available in 3 days</p></div>
                                       <div> <i className="pi pi-check-circle"></i> <p>Quick & easy way to enroll buyer</p></div>
                                        </div>
                                    </div>
                                    <div className="continue-cls" onClick={onClickEmandicashContinue}>
                                        <CustomButton label="Continue" className="cancel" /><i className=" pi pi-arrow-right" style={{'fontSize': '1em'}}></i>
                                        {/* <CustomButton label="Submit" className="send" config={{ onClick: onSubmitBuynow }} /> */}
                                    </div>
                                </div>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default buynowPopUp;