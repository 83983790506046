import { React, useEffect, useState, useRef } from 'react'
import Navbar from '../../Navbar/Navbar'
import FixedAppDirectingFooter from '../../FixedAppDirectingFooter/FixedAppDirectingFooter'
import { toast } from 'react-toastify';
import { AADHAAR_CONSENT_API, ADDITIONAL_DOCS, BUYER_PAN, GET_AADHAAR_API, GET_DETAILS_BY_PIN, GET_PERSONAL_INFO, IS_ELIGIBLE, KYC_VERIFY_GST, KYC_VERIFY_UAN, LENDER_SELECTION, LIMIT_INCREASE, PARTNER_ADD_DOCS, POST_ENQUIRY, POST_GST_DATA, PROGRESS_REPORT, PUT_MSME_DETAILS, SAVE_ADD_DOCS, SIGNATORY_ADD_DOCS, UPLOAD_FILE } from '../../../../utils/Api';
import Footer from '../../Footer/Footer';
import scanner from '../../../../pre-reg/img/WAQR3x.png'
import people from '../../../../pre-reg/img/peopleCropped.png'
import Scrollbars from 'react-custom-scrollbars';
import ECashHome from './eCashHome';
import ProfilePic from '../../../../assets/images/ecashProfilePic.svg';
import '../style/eCashPostReg.css';
import { KYC_VERIFY_PAN, POST_PAN_DATA} from "../../../../utils/Api";
import backArrowEResearch from '../../../../assets/images/backArrowEResearch.svg'
import rightAngleGreen from '../../../../assets/images/rightAngleGreen.svg'
import HomeVector from '../Images/home_vector.png';
import { fireAjaxRequest, history } from '../../../../utils/Utility';
import greenRolling from '../../../../assets/images/Rolling-1.1s-200px.svg'
import greenTick from '../../../../assets/images/greenTick.svg'
import Popup from 'reactjs-popup';
import crossInput from '../../../../assets/images/crossInput.svg'
import infoIcon from '../../../../assets/images/info-small.svg'
import greenUpload from '../../../../assets/images/greenUpload.svg'
import uploadArrowTransparentBG from '../../../../assets/images/uploadArrowTransparentBG.svg'
import documentIcon from '../../../../assets/images/document.svg'
import cross from '../../../../assets/images/popup-header-cross.svg'
import bigDocImg from '../../../../assets/images/BigDocument.svg'
import adharLogo from '../../../../assets/images/AdharLogo.svg'
import { detect } from 'detect-browser';
import CircularPercentageLoader from './CircularPercentageLoader';
import pdf from '../../../../assets/images/pdf 1.svg';
import alertTriangle from '../../../../assets/images/alert-triangle.svg';
import reviewDocsGif from '../../../../assets/images/reviwing docs gif.gif';
import reviewDocsFarmerSupplierImg from '../../../../assets/images/reviewingDocs.svg';
import searchDocIcon from '../../../../assets/images/searchDocIcon.svg';
import yellowDocImage from '../../../../assets/images/yellowDocImage.svg';
import congratulationsImage from '../../../../assets/images/CongratulationsImage.svg';
import rejected from '../../../../assets/images/rejected.svg';
import rocket from '../../../../assets/images/rocket-lunch 1.svg';


function EcashPostReg() {
    const [userPersonalDetails, setUserPersonalDetails] = useState(null);
    const [ecashPANValue, setEcashPANValue] = useState('');
    const [ecashName, setEcashName] = useState('');
    const [ecashAddress, setEcashAddress] = useState('')
    const [showFirmNameRolling, setShowFirmNameRolling] = useState(false);
    const [showFirmNameTick, setShowFirmNameTick] = useState(false);
    const [isCheckedEcashTC, setIsCheckedEcashTC] = useState(false);
    const [showEcashTCPopup, setShowEcashTCPopup] = useState(false);
    const [showEcashPanNoMatchPopup, setShowEcashPanNoMatchPopup] = useState(false);
    const [organization_type, setOrganization_type] = useState('');
    const [details_state, setDetails_state] = useState(3);
    const [ecashGSTValue, setEcashGSTValue] = useState('');
    const [isValidGst, setIsValidGst] = useState(false);
    const [isLoading, setIsLoading]= useState(false);
    const [showEcashGstVintageLess, setShowEcashGstVintageLess] =useState(false);
    const [showEcashGstContinueConfirmation, setShowEcashGstContinueConfirmation] = useState(false);
    const [regDate, setRegDate]= useState('');
    const [tradeName, setTradeName]= useState('');
    const [addr, setAddr]= useState('');
    const [state, setState]= useState('');
    const [pinCode, setPinCode]= useState('');
    const [city, setCity]= useState('');
    const [vintageDate, setVintageDate]= useState('');
    const [isRegisteredMSME, setIsRegisteredMSME]= useState(null);
    const [ecashProgressReport, setEcashProgressReport]= useState({});
    const [progressReportDocData, setProgressReportDocData]= useState([]);
    const [ecashRequestId, setEcashRequestId]= useState('');
    const [ecashBusinessAddress,setEcashBusinessAddress]= useState('');
    const [ecashBusinessPincode,setEcashBusinessPincode]= useState('');
    const [ecashLimitApplied ,setEcashLimitApplied]= useState('');
    const [ecashRequestStatus ,setEcashRequestStatus]= useState('');
    const [ecashMsmeUdyog ,setEcashMsmeUdyog]= useState('');
    const [showMsmeUpload ,setShowMsmeUpload]= useState(false);
    const [msmeDocuments ,setMsmeDocuments]= useState([]);
    const [gstDocuments ,setGstDocuments]= useState([]);
    const [uploadDocumentPopup ,setUploadDocumentPopup]= useState(false);
    const [partners, setPartners]= useState([
        { id: 1, pan: '', verified: false, documents: [] },
      ]);
    const [currentFile, setCurrentFile] = useState('');
    const [currentFileSaveFor, setCurrentFileSaveFor] = useState('');
    const [partnersIdType, setPartnersIdType] = useState([
        { id: 1, type:"" ,name:"",aadharNo:"", verified: false, documents: [] },
    ]);
    const [aadharName, setAadharName] = useState('');
    const [aadharValue, setAadharValue] = useState('');

    const [aadharOTP, setAadharOTP] = useState([]);
    const [aadharAccessKey, setAadharAccessKey] = useState('');
    const [aadharShareCode, setAadharShareCode] = useState('');
    const [counter, setCounter] = useState(0);
    const [itemToAddKycOtpPopup, setItemToAddKycOtpPopup] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [partnersCompletedCount, setPartnersCompletedCount] = useState(0);
    const [partnersIdTypeCompletedCount, setPartnersIdTypeCompletedCount] = useState(0);
    const [showContinueBtn, setShowContinueBtn] = useState(false);
    const [showSaveBeforeAlert, setShowSaveBeforeAlert] = useState(false);
    const [showReviewDocs, setShowReviewDocs] = useState(false);
    const [additionalDocs, setAdditionalDocs] = useState([]);
    const [additionalDocsNames, setAdditionalDocsNames] = useState([]);
    const [ecashStatusFor75Lac,setEcashStatusFor75Lac] = useState("");
    const [itrYearSelectValue,setITRYearSelectValue] = useState("Select Year");
    const [yearData, setYearData] = useState([]);
    const [Completedyears, setCompletedyears] = useState([]);
    const fileInputRef = useRef(null);
    const [yearOptions, setYearOptions] = useState(["Select Year", "2022-23", "2021-22"]);
    const [gst3BDocuments, setGst3BDocuments] = useState([]);
    const [bankStatementDocuments, setBankStatementDocuments] = useState([]);
    const [ecashRequestIDsArray , setEcashRequestIdsArray] = useState([]);
    const [assignedLender , setAssignedLender] = useState('');
    const [show75ApprovedPopup , setShow75ApprovedPopup] = useState(false);
    const [rejectionComments , setRejectionComments] = useState([]);
    const [anyOneShopsAndEstablishment , setAnyOneShopsAndEstablishment] = useState([]);
    const [anyOneUtilityBill , setAnyOneUtilityBill] = useState([]);
    const [anyOneMandiLicense , setAnyMandiLicense] = useState([]);
    const [showContinueWithMobilePopup , setShowContinueWithMobilePopup] = useState(false);
    const [showContinueWithMobileMsg, setShowContinueWithMobileMsg]=  useState("");

    useEffect(async() => {
        // call us popup
        let chatOverlay = document.querySelector('.chatOverlay')
        let callBtn = document.querySelectorAll('.call')
        let chatCross = document.querySelector('.chatRight > div > svg:last-of-type')

        callBtn.forEach((ele) => {
            ele.addEventListener('click', () => {
                chatOverlay.classList.add('chatDisplay')
                document.body.style.overflow = 'hidden'
                let elm = document.querySelector('.toaster')
                elm.style.display = "none";
            })
        })

        chatCross.addEventListener('click', () => {
            document.body.style.overflow = 'auto'
            chatOverlay.classList.remove('chatDisplay')
        })
        let callUsNumber = document.querySelector('.chatContent>div:first-of-type')

        callUsNumber.addEventListener('click', () => {
            let elm = document.querySelector('.toaster')
            elm.style.display = "flex";
            var newone = elm.cloneNode(true);
            elm.parentNode.replaceChild(newone, elm);
        })
        await getUserDetails();
        await traderIsEligible();
    }, [])

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
      }, [counter]);

    useEffect(() => {
        if(percentage>=100){
            const documentContainers = document.querySelectorAll('.document-container');
            closeOtherContainers(documentContainers, -1);
        }
        else{
            setShowContinueBtn(false)
        }
    }, [percentage]);

    const StatusCheck = async(ecashHistory)=>{  
        
        if(ecashHistory.length===1){
            if(ecashHistory[0].status==="Rejected"){
                await progressReportForRejectionComments(ecashHistory[0].ecashRequestId , ecashHistory);
                setDetails_state(10);
            }
            else if(ecashHistory[0].status==="Approved"){
                setDetails_state(9);
            }
            else {
                await progressReport(ecashHistory[0].ecashRequestId , ecashHistory);
            }
        }
        else{
            if(ecashHistory[0].status==="Incomplete_Proposal"){                                            //in process
                if(ecashHistory[1].status==="Rejected"){
                    await progressReportForRejectionComments(ecashHistory[1].ecashRequestId , ecashHistory);
                    setDetails_state(10);
                }
                else if(ecashHistory[1].status==="Approved"){
                    await progressReport(ecashHistory[0].ecashRequestId , ecashHistory);
                    setShow75ApprovedPopup(true);
                }
                else {
                    await progressReport(ecashHistory[0].ecashRequestId , ecashHistory);
                }
            }
            else{                                                                                    //on waiting, reject, approved 
                if(ecashHistory[1].status==="Rejected"){
                    await progressReportForRejectionComments(ecashHistory[1].ecashRequestId , ecashHistory);
                    setDetails_state(10);
                }
                else if(ecashHistory[1].status==="Approved"){
                    if(ecashHistory[0].status==="Rejected"){
                        await progressReportForRejectionComments(ecashHistory[0].ecashRequestId , ecashHistory);
                        setDetails_state(11);
                    }
                    else if(ecashHistory[0].status==="Approved"){
                        setDetails_state(9);
                    }
                    else{
                        setDetails_state(8);
                        setShow75ApprovedPopup(true);
                    } 
                }
                else {
                    await progressReport(ecashHistory[0].ecashRequestId , ecashHistory);
                }
            }
        }
    }
    const getUserDetails = async()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${GET_PERSONAL_INFO}/${userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },  
            });
            if (resp) {
                setUserPersonalDetails(resp);
                if(details_state<3){
                    const panObject = resp.kyc.find(obj => obj.type === "PAN");
                    if (panObject) {
                        setEcashPANValue(panObject.value);
                        await getIndividualPanVerify(panObject.value.toUpperCase());
                    }
                }
            } else {
                toast.error("Unable to fetch user details")
            }
        } catch (err) {
            toast.error("Unable to get the account deatils")
        }
    }
    const userPanChecking = async(number, name, type, address)=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let sendBody ={
            "userId": userInfo.user.id,
            "pan": number,
            "organizationName": name,
            "organizationType": type,
            "organizationAddress": address
          };
        try {
            const resp = await fireAjaxRequest(`${BUYER_PAN}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){
                setShowContinueWithMobileMsg("Please use mobile app to continue");
                setShowContinueWithMobilePopup(true);
            }
            else{
                setIsLoading(false);
                toast.error("Something went wrong")
            }
        } catch (err) {
            setIsLoading(false);
            if(err.code === 200){
                setShowContinueWithMobileMsg("Please use mobile app to continue");
                setShowContinueWithMobilePopup(true);
            }
            else if(err.code === 409){
                setShowContinueWithMobileMsg("Please use mobile app to continue");
                setShowContinueWithMobilePopup(true);
            }
            else if(err.code === 400){
                console.log("please continue");
            }
        }
    }
    const traderIsEligible = async()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${IS_ELIGIBLE}userId=${userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },  
            });
            if (resp) {
                setDetails_state(1);
                getUserDetails();
            } else {
                toast.error("Unable to fetch user eligibility details")
            }
        } catch (err) {
            if(err.statusCode === 400){
                //request in process
                setEcashRequestId(err.userHistory[0].ecashRequestId);
                setEcashRequestIdsArray(err.userHistory);
                await StatusCheck(err.userHistory);
                // await progressReport(err.userHistory[0].ecashRequestId , err.userHistory);
            }
            else if(err.statusCode === 409){
                setShowContinueWithMobileMsg("Please use mobile app to continue")
                setShowContinueWithMobilePopup(true);
            }
            else if(err.statusCode === 422){
                setShowContinueWithMobileMsg("Please use mobile app to continue")
                setShowContinueWithMobilePopup(true);
            }
            else if(err.statusCode === 429){
                setShowContinueWithMobileMsg("Please use mobile app to continue")
                setShowContinueWithMobilePopup(true);
            }
        }
    }
    const progressReport = async(ecashRequestId , userHistoryIDsArray) =>{
        
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${PROGRESS_REPORT}ecashRequestId=${ecashRequestId}&traderId=${userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}` 
                },  
            });
            if (resp) {
                setEcashProgressReport(resp.ecashProgressReport);
                setOrganization_type(resp.ecashProgressReport.typeOfOrganization);
                setEcashPANValue(resp.ecashProgressReport.pan);
                setEcashGSTValue(resp.ecashProgressReport.gstin);
                setEcashName(resp.ecashProgressReport.nameOfOrganization);
                setIsRegisteredMSME(resp.ecashProgressReport.isMsmeRegistered);
                setEcashRequestStatus(resp.ecashProgressReport.statusOfRequest);
                setEcashBusinessAddress(resp.ecashProgressReport.buisnessAddress);
                setEcashBusinessPincode(resp.ecashProgressReport.buisnessAddress.slice(-6));
               await handleMsmePinCodeChange(resp.ecashProgressReport.buisnessAddress.slice(-6));
                setEcashLimitApplied(resp.ecashProgressReport.limitApplied);
                setEcashStatusFor75Lac(resp.ecashProgressReport.statusOfRequest);
                setRejectionComments(resp.ecashProgressReport.ecashComments? resp.ecashProgressReport.ecashComments : []);
                let arrayToMap;
                if (resp.ecashProgressReport.typeOfOrganization === "Partnership") {
                    arrayToMap = resp.ecashProgressReport.ecashPartnershipBuisnessDocList;
                }else if (resp.ecashProgressReport.typeOfOrganization === "LLP") {
                    arrayToMap = resp.ecashProgressReport.ecashLLPDocList;
                } else if (resp.ecashProgressReport.typeOfOrganization === "Company") {
                    arrayToMap = resp.ecashProgressReport.ecashCompanyDocList;
                }else{
                    arrayToMap = resp.ecashProgressReport.ecashProprietorshipDocList;
                }
                // if(resp.ecashProgressReport.typeOfOrganization === 'Partnership'){
                    if(userHistoryIDsArray.length===2){
                            setAssignedLender(resp.ecashProgressReport.assignedLender);
                            setDetails_state(6);
                            let CrDocsFromProgress = arrayToMap.filter(item=>(item.docType==="ITR_AND_AUDITED_FINANCIAL_STATEMENTS" || item.docType==="GST_3B_PAST_12MONTHS" || item.docType==="BANK_STATEMENTS_MAIN_OPERATIVE_ACCOUNT_PAST_12MONTHS"));
                            if(CrDocsFromProgress.length===3){
                                const apiResponse = await getPartnershipAdditionalDocs(resp.ecashProgressReport.assignedLender, resp.ecashProgressReport.typeOfOrganization, "2Crore");
                                const additionalDocsFromProgress = arrayToMap.filter((item) => {
                                    return apiResponse.some((doc) => doc.docName === item.docType);
                                });
                                if(additionalDocsFromProgress.length===apiResponse?.length){
                                    setDetails_state(8);
                                    setPercentage(100); 
                                }
                                else{
                                    additionalDocsFromProgress.forEach((item) => {
                                        const linksArray = item.docLink.split(',');
                                        const correspondingApiResponseObj = apiResponse.find(doc => doc.docName === item.docType);
                                        if (correspondingApiResponseObj) {
                                          correspondingApiResponseObj.documents = linksArray;
                                        }
                                    });
                                    setAdditionalDocs(apiResponse);
                                }
                            }
                            else if(CrDocsFromProgress.length>0){
                                let percentageTotal=0;
                                const ITRObjectFromProgress = CrDocsFromProgress.find(obj => obj.docType === "ITR_AND_AUDITED_FINANCIAL_STATEMENTS");
                                if(ITRObjectFromProgress){
                                    setCompletedyears(["2022-23","2021-22"]);
                                    let documents =ITRObjectFromProgress.docLink.split(",");
                                    const arrayOfObjects = documents.map(url => ({ blobLink: url }));
                                    const array1 = [];
                                    const array2 = [];
    
                                    for (let i = 0; i < arrayOfObjects.length; i++) {
                                        if (i < 6) {
                                            array1.push(arrayOfObjects[i]);
                                        } else {
                                            array2.push(arrayOfObjects[i]);
                                        }
                                    }
                                    let obj1={
                                        year:"2022-23",
                                        documents: array1
                                    }
                                    let obj2={
                                        year:"2021-22",
                                        documents: array2
                                    }
                                    setYearData([obj1, obj2]);
                                    percentageTotal+=100/3;
                                }
                                
                                const gst3BObjectFromProgress = CrDocsFromProgress.find(obj => obj.docType === "GST_3B_PAST_12MONTHS");
                                if(gst3BObjectFromProgress){
                                    let documents= gst3BObjectFromProgress.docLink.split(",");
                                    const arrayOfObjects = documents.map(url => ({ blobLink: url }));
                                    setGst3BDocuments(arrayOfObjects);
                                    percentageTotal+=100/3;
                                }
                                const bankStatementObjectFromProgress = CrDocsFromProgress.find(obj => obj.docType === "BANK_STATEMENTS_MAIN_OPERATIVE_ACCOUNT_PAST_12MONTHS");
                                if(bankStatementObjectFromProgress){
                                    let documents= bankStatementObjectFromProgress.docLink.split(",");
                                    const arrayOfObjects = documents.map(url => ({ blobLink: url }));
                                    setBankStatementDocuments(arrayOfObjects);
                                    percentageTotal+=100/3;
                                }
                                setPercentage(percentageTotal);
                            }
                        // }
                    }
                    else{
                        setDetails_state(3);
                        let progressReportDocs={};
                        //setting msme and gst values 
                        let countPercentage=0;
                        let percentageInc= 0;
                        if(resp.ecashProgressReport.gstin!=="" && resp.ecashProgressReport.gstin!==null){
                            if(resp.ecashProgressReport.isMsmeRegistered){
                                percentageInc=25;
                            }
                            else{
                                percentageInc=33.33;
                            }
                        }
                        else{
                            percentageInc=33.33;
                        }
                        //setting business docs
                            arrayToMap.map(async (BusinessDoc)=>{
                                
                                let docLinksArray = BusinessDoc.docLink.split(',');
                                let documentsArray = [];
                                for (let link of docLinksArray) {
                                    const documentObject = {
                                    blobLink: link,
                                    name: BusinessDoc.docType,
                                    };
                                    documentsArray.push(documentObject);
                                }
                                if(BusinessDoc.docType==="MSME/UDYAM_CERTIFICATE"){
                                    setEcashMsmeUdyog(BusinessDoc.docNo);
                                    setMsmeDocuments(documentsArray);
                                    setShowMsmeUpload(true);
                                    countPercentage= countPercentage+percentageInc;
                                    progressReportDocs.msmeNo=BusinessDoc.docNo;
                                    progressReportDocs.msmeDocs=documentsArray;
                                    if(resp.ecashProgressReport.gstin==='' || resp.ecashProgressReport.gstin===null){
                                        await handleMsmeChange(BusinessDoc.docNo);
                                    }
                                    
                                }
                                else if(BusinessDoc.docType==="GST_CERTIFICATE"){
                                    setGstDocuments(documentsArray);
                                    countPercentage= countPercentage+percentageInc;
                                    progressReportDocs.gstDocs=documentsArray; 
                                }
                                else  if(resp.ecashProgressReport.typeOfOrganization === "Proprietorship" && (BusinessDoc.docType==="AADHAAR" || BusinessDoc.docType==="PASSPORT" || BusinessDoc.docType==="VOTER_ID")){
                                    let newPartnersIdTypeArray=[];
                                    let idObj={
                                        id: 1,
                                        type: BusinessDoc.docType,
                                        name: BusinessDoc.docType === "AADHAR" ? BusinessDoc.docName : "",
                                        aadharNo: BusinessDoc.docNo,
                                        documents: documentsArray,
                                    }
                                    countPercentage= countPercentage+percentageInc;
                                    newPartnersIdTypeArray.push(idObj);
                                    setPartnersIdType(newPartnersIdTypeArray);
                                    setPartnersIdTypeCompletedCount(1);
                                    
                                }
                                else if(resp.ecashProgressReport.typeOfOrganization === "Proprietorship" && (BusinessDoc.docType==="SHOPS_AND_ESTABLISHMENT_CERTIFICATE")){
                                    setAnyOneShopsAndEstablishment(documentsArray);
                                    countPercentage= countPercentage+percentageInc;
                                    progressReportDocs.shopsDocs=documentsArray; 
                                }
                                else if(resp.ecashProgressReport.typeOfOrganization === "Proprietorship" && (BusinessDoc.docType==="UTILITY_BILL")){
                                    setAnyOneUtilityBill(documentsArray);
                                    countPercentage= countPercentage+percentageInc;
                                    progressReportDocs.utilityDocs=documentsArray; 
                                }
                                else if(resp.ecashProgressReport.typeOfOrganization === "Proprietorship" && (BusinessDoc.docType==="MANDI_LICENSE")){
                                    setAnyMandiLicense(documentsArray);
                                    countPercentage= countPercentage+percentageInc;
                                    progressReportDocs.mandiDocs=documentsArray; 
                                }
                        })
                        if(resp.ecashProgressReport.typeOfOrganization !== "Proprietorship"){
                            let newPartnersArray=[];
                            let newPartnersIdTypeArray=[];
                            if (resp.ecashProgressReport.typeOfOrganization === "Partnership"){
                                resp.ecashProgressReport.ecashPartners.map((partnerObj , idx)=>{
                                    partnerObj.ecashPartnersDocs.map((partnerDocs)=>{
                                        let docLinksArray = partnerDocs.docLink.split(',');
                                        let documentsArray = [];
                                        for (let link of docLinksArray) {
                                            const documentObject = {
                                            blobLink: link,
                                            name: partnerDocs.docType,
                                            };
                                            documentsArray.push(documentObject);
                                        }
                                        if(partnerDocs.docType==="PAN"){
                                            let panObj={
                                                id:idx+1,
                                                pan:partnerDocs.docNo,
                                                verified: true,
                                                documents:documentsArray,
                                            }
                                            newPartnersArray.push(panObj);
                                        }
                                        else{
                                            let idObj={
                                                id: idx+1,
                                                type: partnerDocs.docType,
                                                name: partnerDocs.docType === "AADHAR" ? partnerDocs.docName : "",
                                                aadharNo: partnerDocs.docNo,
                                                documents: documentsArray,
                                            }
                                            newPartnersIdTypeArray.push(idObj);
                                        }
                                    })
                                })
                            }
                            else if(resp.ecashProgressReport.typeOfOrganization === "LLP" || resp.ecashProgressReport.typeOfOrganization === "Company"){
                                resp.ecashProgressReport.ecashSignatoryList.map((partnerObj , idx)=>{
                                    partnerObj.ecashSignatoryDocList.map((partnerDocs)=>{
                                        let docLinksArray = partnerDocs.docLink.split(',');
                                        let documentsArray = [];
                                        for (let link of docLinksArray) {
                                            const documentObject = {
                                            blobLink: link,
                                            name: partnerDocs.docType,
                                            };
                                            documentsArray.push(documentObject);
                                        }
                                        if(partnerDocs.docType==="PAN"){
                                            let panObj={
                                                id:idx+1,
                                                pan:partnerDocs.docNo,
                                                verified: true,
                                                documents:documentsArray,
                                            }
                                            newPartnersArray.push(panObj);
                                        }
                                        else{
                                            let idObj={
                                                id: idx+1,
                                                type: partnerDocs.docType,
                                                name: partnerDocs.docType === "AADHAR" ? partnerDocs.docName : "",
                                                aadharNo: partnerDocs.docNo,
                                                documents: documentsArray,
                                            }
                                            newPartnersIdTypeArray.push(idObj);
                                        }
                                    })
                                })
                            }
                            
                            setPartners(newPartnersArray);
                            progressReportDocs.partnersData=newPartnersArray; 
    
                            setPartnersIdType(newPartnersIdTypeArray);
                            progressReportDocs.partnersIdTypeData=newPartnersIdTypeArray; 
    
                            setProgressReportDocData(progressReportDocs);
    
                            //setting percentage
                            if(newPartnersArray.length>0){
                                countPercentage= countPercentage+percentageInc;
                                setPartnersCompletedCount(newPartnersArray.length);
                            }
                            if(newPartnersIdTypeArray.length>0){
                                countPercentage= countPercentage+percentageInc;
                                setPartnersIdTypeCompletedCount(newPartnersIdTypeArray.length);
                            }
                            
                        }
                        //setting partners values
                        
                        setPercentage(countPercentage===99.99 ? 100 : countPercentage);
                        if(countPercentage>=99.99 && resp.ecashProgressReport.assignedLender!=null){
                            setDetails_state(4);
                            setPercentage(0);
                            const apiResponse = await getPartnershipAdditionalDocs(resp.ecashProgressReport.assignedLender, resp.ecashProgressReport.typeOfOrganization, resp.ecashProgressReport.limitApplied);
                                const additionalDocsFromProgress = arrayToMap.filter((item) => {
                                    return apiResponse.some((doc) => doc.docName === item.docType);
                                });
                                if(additionalDocsFromProgress.length===apiResponse?.length){
                                    setDetails_state(5);
                                    setPercentage(0); 
                                }
                                else{
                                    setPercentage(additionalDocsFromProgress.length * (100/apiResponse?.length))
                                    console.log(additionalDocsFromProgress);
                                    additionalDocsFromProgress.forEach((item) => {
                                        const linksArray = item.docLink.split(',');
                                        let documentsArray = [];
                                        for (let link of linksArray) {
                                            const documentObject = {
                                            blobLink: link,
                                            name: item.docType,
                                            };
                                            documentsArray.push(documentObject);
                                        }
                                        const indexToUpdate = apiResponse.findIndex(doc => doc.docName === item.docType);
                                        if (indexToUpdate !== -1) {
                                            apiResponse[indexToUpdate].documents = documentsArray;
                                        }
                                        // const correspondingApiResponseObj = apiResponse.find(doc => doc.docName === item.docType);
                                        // if (correspondingApiResponseObj) {
                                        //   correspondingApiResponseObj.documents = documentsArray;
                                        // }
                                    });
                                    console.log(apiResponse);
                                    setAdditionalDocs(apiResponse);
                                }
                            return;
                        }else {
                            return;
                        }
                    }
                // }
            } else {
                toast.error("Unable to fetch user progress details");
            }
        } catch (err) {
            toast.error("something went wrong");
        }
    }
    const progressReportForRejectionComments = async(ecashRequestId , userHistoryIDsArray) =>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${PROGRESS_REPORT}ecashRequestId=${ecashRequestId}&traderId=${userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}` 
                },  
            });
            if (resp) {
                setEcashProgressReport(resp.ecashProgressReport);
                setOrganization_type(resp.ecashProgressReport.typeOfOrganization);
                setEcashPANValue(resp.ecashProgressReport.pan);
                setEcashGSTValue(resp.ecashProgressReport.gstin);
                setEcashName(resp.ecashProgressReport.nameOfOrganization);
                setIsRegisteredMSME(resp.ecashProgressReport.isMsmeRegistered);
                setEcashRequestStatus(resp.ecashProgressReport.statusOfRequest);
                setEcashBusinessAddress(resp.ecashProgressReport.buisnessAddress);
                setEcashLimitApplied(resp.ecashProgressReport.limitApplied);
                setEcashStatusFor75Lac(resp.ecashProgressReport.statusOfRequest);
                setRejectionComments(resp.ecashProgressReport.ecashComments);
            } else {
                toast.error("Unable to fetch user progress details");
            }
        } catch (err) {
            toast.error("something went wrong");
        }
    }
    const getIndividualPanVerify = async(panNumbervalue)=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            setShowFirmNameRolling(true);
            setShowFirmNameTick(false);
            const resp = await fireAjaxRequest(`${KYC_VERIFY_PAN}pan=${panNumbervalue}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },  
            });
            if (resp && resp.data) {
                setShowFirmNameRolling(false);
                setShowFirmNameTick(true);
                setEcashName(resp.data.name);
                let organisationType;
                if(panNumbervalue.charAt(3)=== 'P'){
                    setOrganization_type('Proprietorship');
                    organisationType="Proprietorship"
                }else if(panNumbervalue.charAt(3)=== 'C'){
                    setOrganization_type("Company");
                    organisationType="Company"
                }
                else if(panNumbervalue.charAt(3)=== 'F'){
                    if (resp.data.name.toLowerCase().includes("llp")) {
                        setOrganization_type("LLP");    
                        organisationType="LLP"
                    }
                    else {
                        setOrganization_type("Partnership");
                        organisationType="Partnership"
                    }
                }
                await userPanChecking(panNumbervalue,resp.data.name, organisationType, resp.data.address);
            } else {
                setShowFirmNameRolling(false);
                setShowFirmNameTick(false);
                toast.error("Unable to fetch name linked to this PAN , Please try again")
            }
        } catch (err) {
            setTimeout(() => {
                if(err.code === "422"){
                    setShowFirmNameRolling(false);
                    toast.error("Invalid Details")
                }
                else{
                    setShowFirmNameRolling(false);
                    toast.error("something went wrong")
                }
            }, 5000);
        }
    }
    const handlePanNumber = async(e) =>{
        const inputValue = e.target.value.toUpperCase();    
        setEcashPANValue(inputValue);
        if(inputValue.length===10){
            const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
            const isValidPan = panPattern.test(inputValue);
            if(!isValidPan && (inputValue.charAt(3)==='F' || inputValue.charAt(3)==='P' || inputValue.charAt(3)==='C')){
                document.getElementsByClassName('eCashPanNumberError')[0].style.display="flex"
            }else{
                document.getElementsByClassName('eCashPanNumberError')[0].style.display="none"
                await getIndividualPanVerify(e.target.value.toUpperCase());
            }   
        }
        else if(inputValue.length===4){
            if(inputValue.charAt(3).toUpperCase()==='P' && !userPersonalDetails?.kyc.find(obj => obj.type === "PAN").value.includes(inputValue))
            {
                setShowEcashPanNoMatchPopup(true);
                setEcashPANValue('');
            }
        }
    }
    const VERIFY_GST = (inputGSTValue) =>{
        setIsLoading(true);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        (async () => {
            try {
                const resp = await fireAjaxRequest(KYC_VERIFY_GST + "consent=" + "Y" + "&additionalData=" + false + "&gstin=" + inputGSTValue, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${userInfo.token}`,
                        "Content-Type": "application/json"
                    },
                });
                if (resp) {
                    setIsLoading(false);
                    let result = resp.result;
                    let stateVar1 = result?.stj.substring(result?.stj.indexOf('-') + 2);
                    let stateVar2 = stateVar1?.split(",")
                    let pinVar = result?.pradr?.adr?.substring(result?.pradr?.adr?.length - 6)
                    let cityArray = result.pradr.adr.split(",")
                    let city = cityArray[cityArray.length - 3]
                    var date1 = new Date();
                    var dd = String(date1.getDate()).padStart(2, '0');
                    var mm = String(date1.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = date1.getFullYear();
                    date1 = mm + '/' + dd + '/' + yyyy;
                    var date2 = result?.rgdt;
                    var Difference_In_Time = new Date(date1).getTime() - new Date(date2).getTime();
                    var numberOfDays = Difference_In_Time / (1000 * 3600 * 24);
                    var years = Math.floor(numberOfDays / 365);
                    var months = Math.floor(numberOfDays % 365 / 30);
                    var days = Math.floor(numberOfDays % 365 % 30);
                    let vintageD = years + " year, " + months + " month, " + days + " day";

                    if(years<2){
                        setShowEcashGstVintageLess(true);
                    }
                    else{
                        setIsValidGst(true);
                        toast.success("Successfully verified Your Gst Number");
                        setTradeName(resp.result.tradeNam);
                        setAddr(resp.result.pradr.adr);
                        setState(stateVar2[0]);
                        setPinCode(pinVar);
                        setCity(city);
                        setRegDate(resp.result.rgdt);
                        setVintageDate(vintageD);
                    }
                }
                else{
                    setIsLoading(false);
                    toast.error("Something Went Wrong..Please try again later.")
                }
            } catch (err) {
                setIsLoading(false);
                if(err.code === "422"){
                    toast.error("Invalid Details")
                }
                else{
                    toast.error("something went wrong")
                }
            }
        })();
    }
    const handleGSTNumber = async(e) =>{
        const inputValue = e.target.value.toUpperCase();    
        setEcashGSTValue(inputValue);
        if(inputValue.length===15){
            const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
            const isValidGST = gstPattern.test(inputValue);
            if(!isValidGST){
                setIsValidGst(false);
                document.getElementsByClassName('eCashGSTNumberError')[0].style.display="flex"
            }
            else if(!(inputValue.substring(2,12)===ecashPANValue)){
                setIsValidGst(false);
                toast.error("Please enter valid GST Number linked with the given PAN")
            }
            else{
                document.getElementsByClassName('eCashGSTNumberError')[0].style.display="none"
                VERIFY_GST(inputValue);
            }   
        }
    }
    const handleEcashCheckboxChange = (e) => {
        setIsCheckedEcashTC(!isCheckedEcashTC);
    }
    const handleShowEcashTC = (e) =>{
        setShowEcashTCPopup(true);
    }
    const handleEcashTCPopupCross = () =>{
        setShowEcashTCPopup(false);
     }
     const handleEcashPanNoMatchPopupCross = () =>{
        setShowEcashPanNoMatchPopup(false)
     }
     const handleEcashPanContinue = () =>{
        setDetails_state(2);
        setEcashGSTValue('');
        setIsRegisteredMSME(null);
     }
     const handleEcashPostGst = async(panReqId) =>{
        setIsLoading(true);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "requestId": panReqId,
            "GST": ecashGSTValue,
            "isMSMEregistered": true,
            "vintage": vintageDate,
            "registrationDate": regDate,
            "organizationName": tradeName,
            "address": addr,
            "city": city,
            "state": state,
            "pincode": pinCode
          };
        try {
            const resp = await fireAjaxRequest(`${POST_GST_DATA}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){
                setIsLoading(false);
                toast.success("Successfully submited !!");
                setDetails_state(3);
            }
            else{
                setIsLoading(false);
                toast.error("Something went wrong")
            }
        } catch (err) {
            setIsLoading(false);
            if(err.code === "422"){
                toast.error("Invalid GST Details")
            }
            else{
                toast.error("something went wrong")
            }
        }
     }
     const handleEcashGSTContinue = async() =>{       //post pan
        setIsLoading(true);
        setShowEcashGstContinueConfirmation(false);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "pan": ecashPANValue,
            "name": ecashName,
            // "name": "Simran",
            "contactNo": userPersonalDetails.phoneNo,
            "limitApplied": "75Lakh",
            "isLimitApproved": false,
            "organizationType": organization_type
            // "organizationType": "Company"
          };
        try {
            const resp = await fireAjaxRequest(`${POST_PAN_DATA}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){
                setIsLoading(false);
                handleEcashPostGst(resp.requestId);
            }
            else{
                setIsLoading(false);
                toast.error("Something went wrong")
            }
        } catch (err) {
            setIsLoading(false);
            if(err.code === "422"){
                toast.error("Invalid PAN Details")
            }
            else if(err.statusCode === 400 && err.message.includes("for the diffrent User")){
                toast.error("this pan has been already used by another user , please try again with differnt pan");
                setDetails_state(1);
                setIsCheckedEcashTC(false);
                setEcashPANValue(ecashPANValue)
                setEcashGSTValue('');
                setIsRegisteredMSME(null);
                setIsValidGst(false);
                setShowEcashGstVintageLess(false);
                setShowEcashGstContinueConfirmation(false);
                setRegDate('')
                setTradeName('')
                setAddr('')
                setState('')
                setPinCode('')
                setCity('')
                setVintageDate('')
            }
            else if(err.statusCode === 400){
                toast.error("Your request is already in process with incomplete status with this PAN, please wait or use another pan    ");
            }
            else{
                toast.error("something went wrong")
            }
        }
     }
     const handleEcashNonGSTClick = async() =>{       //post pan
        setIsLoading(true);
        setShowEcashGstContinueConfirmation(false);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "pan": ecashPANValue,
            "name": ecashName,
            "contactNo": userPersonalDetails.phoneNo,
            "limitApplied": "75Lakh",
            "isLimitApproved": false,
            "organizationType": organization_type
          };
        try {
            const resp = await fireAjaxRequest(`${POST_PAN_DATA}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){
                setIsLoading(false);
                setDetails_state(3);
                // handleEcashPostGst(resp.requestId);
            }
            else{
                setIsLoading(false);
                toast.error("Something went wrong")
            }
        } catch (err) {
            setIsLoading(false);
            if(err.code === "422"){
                toast.error("Invalid PAN Details")
            }
            else if(err.statusCode === 400 && err.message.includes("for the diffrent User")){
                toast.error("this pan has been already used by another user , please try again with differnt pan");
                setDetails_state(1);
                setIsCheckedEcashTC(false);
                setEcashPANValue(ecashPANValue)
                setEcashGSTValue('');
                setIsRegisteredMSME(null);
                setIsValidGst(false);
                setShowEcashGstVintageLess(false);
                setShowEcashGstContinueConfirmation(false);
                setRegDate('')
                setTradeName('')
                setAddr('')
                setState('')
                setPinCode('')
                setCity('')
                setVintageDate('')
            }
            else if(err.statusCode === 400){
                toast.error("Your request is already in process with incomplete status with this PAN, please wait or use another pan    ");
            }
            else{
                toast.error("something went wrong")
            }
        }
     }
     const toggleDocument = (event, idx) => {
        const documentDiv = event.currentTarget;
        const uploadDiv = documentDiv.nextElementSibling;
        const uploadDisplay = getComputedStyle(uploadDiv).display;
        if (uploadDisplay === 'none') {
            uploadDiv.style.display = 'flex';
            documentDiv.getElementsByClassName('greenUpload-docNumber-div')[0].style.display= 'none';
        } else {
            uploadDiv.style.display = 'none';
            documentDiv.getElementsByClassName('greenUpload-docNumber-div')[0].style.display= 'flex';
        }
    }
    const handleMsmeChange = async(value)=>{
        const inputValue = value;
        var udyogInputpattern = /^[A-Za-z0-9]+$/;
        if (inputValue === "" || udyogInputpattern.test(inputValue)) {
            setEcashMsmeUdyog(value.toUpperCase());
        }
        if(inputValue.length===12){
            var udyogpattern = /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{7}$/;
            if(!udyogpattern.test(inputValue)){
                toast.error("Please enter valid Udyog/MSME Number")
            }
            else{
                setIsLoading(true);
                let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                try {
                    const resp = await fireAjaxRequest(`${KYC_VERIFY_UAN}consent=Y&uanNo=${inputValue}`, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${userInfo.token}`
                        },  
                    });
                    if(resp) {
                        setIsLoading(false)
                        //non gst 
                        var date2 = resp?.result.dateOFCommencement;

                        var date1 = new Date();
                        var parts = date2.split("/");
                        var day = parseInt(parts[0], 10);
                        var month = parseInt(parts[1], 10);
                        var year = parseInt(parts[2], 10);
                        var dateOfCommencement = new Date(year, month - 1, day); // Month is 0-based

                        var differenceMs = date1 - dateOfCommencement;

                        var msPerDay = 24 * 60 * 60 * 1000;
                        var msPerMonth = msPerDay * 30.44; 
                        var msPerYear = msPerDay * 365.25; 

                        var years = Math.floor(differenceMs / msPerYear);
                        var months = Math.floor((differenceMs % msPerYear) / msPerMonth);
                        var days = Math.floor(((differenceMs % msPerYear) % msPerMonth) / msPerDay);

                        var vintageD = years + " year, " + months + " month, " + days + " day";

                        if(years<2 && (!isRegisteredMSME || (ecashGSTValue==="" || ecashGSTValue===null))){
                            setShowEcashGstVintageLess(true);
                        }else {
                            setTradeName(resp?.result.nameofEnterPrise);
                            setRegDate(resp?.result.dateOFCommencement);
                            setVintageDate(vintageD);
                            setShowMsmeUpload(true);
                        }
                    } else {
                        setIsLoading(false);
                        setShowMsmeUpload(false);
                        toast.error("unable to verify UAN");
                    }
                } catch (err) {
                    setIsLoading(false)
                    toast.error(err.message)
                }
            }
        }
    }
    const handleMsmePinCodeChange = async(value)=>{
        
        setEcashBusinessPincode(value);
        if(value.length===6){
            var pincodePattern = /^[0-9]{6}$/;
            if(!pincodePattern.test(value)){
                toast.error("Please enter valid Pin code")
            }
            else{
                setIsLoading(true);
                let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                try {
                    const resp = await fireAjaxRequest(`${GET_DETAILS_BY_PIN}${value}`, {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${userInfo.token}`
                        },  
                    });
                    if(resp) {
                        setIsLoading(false)
                        setState(resp[0].PostOffice[0].State);
                        setCity(resp[0].PostOffice[0].Block);
                        toast.success("successfully verified pincode");
                    } else {
                        setIsLoading(false);
                        toast.error("unable to verify UAN");
                    }
                } catch (err) {
                    setIsLoading(false)
                    toast.error(err.message)
                }
            }
        }
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        if(files.length > 0){
            setCurrentFile(files[0]);
        }
    };  

    const openFileInput = () => {
        fileInputRef.current.click();
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        setCurrentFile(file);
    };
    const handleSaveFile = async(e) =>{
        
        if (currentFile) {
            try{
                let countPercentage=0;
                let percentageInc= 0;
                if(ecashGSTValue!=="" && ecashGSTValue!==null){  //with gst
                    if(isRegisteredMSME){
                        if(organization_type==="Proprietorship"){
                            percentageInc=33.33;
                        }else{
                            percentageInc=25;
                        }
                    }
                    else{   
                        if(organization_type==="Proprietorship"){
                            percentageInc=50;
                        }
                        else{
                            percentageInc=33.33;
                        }                                     
                    }
                }
                else{                                            // non gst
                        percentageInc=33.33;
                }
                const blobLink = await getBloblink(currentFile); 
                const fileWithBlobLink = { blobLink, name: currentFile.name, type: currentFile.type, size: currentFile.size};
                if(currentFileSaveFor==='msme'){
                    if(msmeDocuments.length===0){
                        setPercentage(percentage+percentageInc)
                    }
                    setMsmeDocuments([...msmeDocuments, fileWithBlobLink]);
                }
                else if(currentFileSaveFor.includes('partner')){
                    let id=currentFileSaveFor.substring(7);
                    const updatedPartners = partners.map((partner) =>
                        partner.id.toString() === id ? { ...partner, documents: [...partner.documents, fileWithBlobLink] } : partner
                    );
                    setPartners(updatedPartners);
                    var isCompleted=false;
                    var partnersCompetedCount = 0;
                    updatedPartners.map((partner,idx)=>{
                        if(partner.pan!=='' && partner.documents.length>0){
                            isCompleted=true;
                            partnersCompetedCount = partnersCompetedCount+1;
                        }
                    })
                    setPartnersCompletedCount(partnersCompetedCount);
                    if(partnersCompetedCount===1 && updatedPartners[id-1].documents.length===1){
                        setPercentage(percentage+percentageInc);
                    }
                }
                else if(currentFileSaveFor.includes('idType')){
                    let id=currentFileSaveFor.substring(6);
                    const updatedPartnersIdTypes = partnersIdType.map((partnerIdType) =>
                        partnerIdType.id.toString() === id ? { ...partnerIdType, documents: [...partnerIdType.documents, fileWithBlobLink]} : partnerIdType
                    );
                    setPartnersIdType(updatedPartnersIdTypes);
    
                    var isCompleted=false;
                    var partnersIdTypeompetedCount = 0;
                    updatedPartnersIdTypes.map((partnerIdType,idx)=>{
                        if(partnerIdType.type!==''){
                            if(partnerIdType.type==="AADHAR" && (partnerIdType.name!=='' && partnerIdType.aadharNo!=='' && partnerIdType.documents.length>0)){
                                isCompleted=true;
                                partnersIdTypeompetedCount = partnersIdTypeompetedCount+1;
                            }else if(partnerIdType.type!=="AADHAR" && partnerIdType.documents.length>0){
                                isCompleted=true;
                                partnersIdTypeompetedCount = partnersIdTypeompetedCount+1;
                            }
                        }
                    })
                    setPartnersIdTypeCompletedCount(partnersIdTypeompetedCount)
                    if(partnersIdTypeompetedCount===1 && partnersIdType[id-1].documents.length===0){
                        setPercentage(percentage+percentageInc);
                    }
                }
                else if(currentFileSaveFor==='gstDoc'){
                    if(gstDocuments.length===0){
                        setPercentage(percentage+percentageInc);
                    }
                    setGstDocuments([...gstDocuments, fileWithBlobLink]);
                }
                else if(currentFileSaveFor==='anyOneShops'){
                    if(anyOneShopsAndEstablishment.length===0 && anyOneMandiLicense.length===0 && anyOneUtilityBill.length===0){
                        setPercentage(percentage+percentageInc);
                    }
                    setAnyOneShopsAndEstablishment([...anyOneShopsAndEstablishment, fileWithBlobLink]);
                }
                else if(currentFileSaveFor==='anyOneUtility'){
                    if(anyOneShopsAndEstablishment.length===0 && anyOneMandiLicense.length===0 && anyOneUtilityBill.length===0){
                        setPercentage(percentage+percentageInc);
                    }
                    setAnyOneUtilityBill([...anyOneUtilityBill, fileWithBlobLink]);
                }
                else if(currentFileSaveFor==='anyOneMandi'){
                    if(anyOneShopsAndEstablishment.length===0 && anyOneMandiLicense.length===0 && anyOneUtilityBill.length===0){
                        setPercentage(percentage+percentageInc);
                    }
                    setAnyMandiLicense([...anyOneMandiLicense, fileWithBlobLink]);
                }
                else if(currentFileSaveFor.includes('addiDocs')){
                    let id=currentFileSaveFor.substring(8);
                    if(additionalDocs[id].documents.length===0){
                        setPercentage(percentage+100/additionalDocsNames.length);
                    }
                    const updatedAdditionalDocs = additionalDocs.map((additionalDoc,indx) =>
                        indx.toString() === id  ? { ...additionalDoc, documents: [...additionalDoc.documents, fileWithBlobLink]} : additionalDoc
                    );
                    setAdditionalDocs(updatedAdditionalDocs);
                }
                else if(currentFileSaveFor.includes('yearDocs')){
                    const updatedYearData = yearData.map((yearDataObj,indx) =>
                        yearDataObj.year === itrYearSelectValue ? { ...yearDataObj, documents: [...yearDataObj.documents, fileWithBlobLink]} : yearDataObj
                    );
                    setYearData(updatedYearData);
                    const allYearsHaveSixDocuments = updatedYearData.every((yearObj) => updatedYearData.length>=2 && yearObj.documents.length === 6);
                    if (allYearsHaveSixDocuments) {
                        setPercentage(percentage+100/3);
                    }
                }
                else if(currentFileSaveFor.includes('gst3BDocuments')){
                    const updated3BDocuments = [...gst3BDocuments, fileWithBlobLink];
                    setGst3BDocuments(updated3BDocuments);
                    if(updated3BDocuments.length===1){
                        setPercentage(percentage+100/3);
                    }
                }
                else if(currentFileSaveFor.includes('bankStatementDocuments')){
                    const updatedBankDocuments = [...bankStatementDocuments, fileWithBlobLink];
                    setBankStatementDocuments(updatedBankDocuments);
                    if(updatedBankDocuments.length===1){
                        setPercentage(percentage+100/3);
                    }
                }
                else{
                    console.log("currentFileSaveFor" ,currentFileSaveFor);
                }
                setCurrentFile(null); 
                setUploadDocumentPopup(false);
            }
            catch (error){
                console.error(error);
                toast.error("Error while uploading file to blob");
            }
            
        }
    }
    const handleRemoveDocument = (docFor,index) => {
        if(docFor === 'msme'){
            const updatedFiles = [...msmeDocuments];
            updatedFiles.splice(index, 1); 
            if(updatedFiles.length===0){
                setPercentage(percentage-25);
            }
            setMsmeDocuments(updatedFiles); 
        }
        else if(docFor.includes('partner')){
            let id=docFor.substring(7);
            const updatedPartners = partners.map((partner) =>
                partner.id.toString() === id ? { ...partner, documents: partner.documents.filter((doc, idx) => idx !== index) } : partner
            );
            setPartners(updatedPartners);
            if(partnersCompletedCount===1 && (updatedPartners[0].pan==='' || updatedPartners[0].documents.length===0)){
                setPercentage(percentage-25);
                setPartnersCompletedCount(0);
            }
        }
        else if(docFor.includes('idType')){
            let id=docFor.substring(6);
            const updatedPartnersIdTypes = partnersIdType.map((partnerIdType) =>
                partnerIdType.id.toString() === id ? { ...partnerIdType, documents: partnerIdType.documents.filter((doc, idx) => idx !== index)} : partnerIdType
            );
            setPartnersIdType(updatedPartnersIdTypes);

            if(partnersIdTypeCompletedCount===1){
                if(updatedPartnersIdTypes[0].type==="AADHAR" && (updatedPartnersIdTypes[0].name==='' || updatedPartnersIdTypes[0].aadharNo==='' || updatedPartnersIdTypes[0].documents.length===0)){
                    setPercentage(percentage-25);
                }
                else if(updatedPartnersIdTypes[0].type!=="AADHAR" && updatedPartnersIdTypes[0].documents.length===0){
                    setPercentage(percentage-25);
                    setPartnersIdTypeCompletedCount(0)
                }
                
            }
        }
        if(docFor === 'gstDoc'){
            const updatedFiles = [...gstDocuments];
            updatedFiles.splice(index, 1); 
            if(updatedFiles.length===0){
                setPercentage(percentage-25);
            }
            setGstDocuments(updatedFiles); 
        }
        else if(docFor.includes('addiDocs')){
            let id=docFor.substring(8);
            const updatedAdditionalDocs = additionalDocs.map((additionalDoc,indx) =>
                indx.toString() === id ? { ...additionalDoc, documents: additionalDoc.documents.filter((doc, idx) => idx !== index)} : additionalDoc
            );
            if(updatedAdditionalDocs[id].documents.length===0){
                setPercentage(percentage-100/additionalDocsNames.length <=0 ? 0 : percentage-100/additionalDocsNames.length);
            }
            setAdditionalDocs(updatedAdditionalDocs);
        }
        else if(docFor.includes('yearDocs')){
            const updatedYearData = yearData.map((yearDataObj,indx) =>
                yearDataObj.year === itrYearSelectValue ? { ...yearDataObj, documents: yearDataObj.documents.filter((doc, idx) => idx !== index)} : yearDataObj
            );
           
            setYearData(updatedYearData);
        }
        else if(docFor.includes('gst3BDocuments')){
            const updatedFiles = [...gst3BDocuments];
            updatedFiles.splice(index, 1); 
            if(updatedFiles.length===0){
                setPercentage(percentage-100/3);
            }
            setGst3BDocuments(updatedFiles);
        }
        else if(docFor.includes('bankStatementDocuments')){
            const updatedFiles = [...bankStatementDocuments];
            updatedFiles.splice(index, 1); 
            if(updatedFiles.length===0){
                setPercentage(percentage-100/3);
            }
            setBankStatementDocuments(updatedFiles);
        }
        
    };
    const handlePanChange = async(partnerId, panValue) => {
        const updatedPartners = partners.map((partner) =>
          partner.id === partnerId ? { ...partner, pan: panValue} : partner
        );
        setPartners(updatedPartners);
        if(panValue.length===10){
            // if(panValue.charAt(3).toUpperCase()!=='P')
            // {
            //     toast.error("Please upload PAN of individual partner");
            //     const updatedPartners = partners.map((partner) =>
            //     partner.id === partnerId ? { ...partner, pan: '' } : partner
            //     );
            //     setPartners(updatedPartners);
            // }
            if(partners.some((partner) => partner.pan === panValue))
            {
                toast.error("This pan is already used for other partner");
                const updatedPartners = partners.map((partner) =>
                partner.id === partnerId ? { ...partner, pan: '' } : partner
                );
                setPartners(updatedPartners);   
            }
            else{
                const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
                const isValidPan = panPattern.test(panValue);
                if(!isValidPan){
                    toast.error("Please enter valid pan number")
                }else{
                    setIsLoading(true);
                    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                    try {
                        const resp = await fireAjaxRequest(`${KYC_VERIFY_PAN}pan=${panValue}`, {
                            method: 'GET',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${userInfo.token}`
                            },  
                        });
                        if (resp && resp.data) {
                            setIsLoading(false);
                            const updatedPartners = partners.map((partner) =>
                            partner.id === partnerId ? { ...partner, verified:true, pan: panValue} : partner
                            );
                            setPartners(updatedPartners);
                            toast.success("successfully verified");
                        } else {
                            setIsLoading(false);
                            const updatedPartners = partners.map((partner) =>
                            partner.id === partnerId ? { ...partner, pan: '' } : partner
                            );
                            setPartners(updatedPartners);
                            toast.error("Unable to fetch name linked to this PAN , Please try again")
                        }
                    } catch (err) {
                        if(err.code === "422"){
                            setIsLoading(false);
                            const updatedPartners = partners.map((partner) =>   
                            partner.id === partnerId ? { ...partner, pan: '' } : partner
                            );
                            setPartners(updatedPartners);
                            toast.error("Invalid Details"); 
                        }
                        else{
                            setIsLoading(false);
                            const updatedPartners = partners.map((partner) =>
                            partner.id === partnerId ? { ...partner, pan: '' } : partner
                            );
                            setPartners(updatedPartners);
                            toast.error("something went wrong");
                        }
                    }
                }
            }
        }
        else if(panValue.length===4){
            if(panValue.charAt(3).toUpperCase()!=='P')
            {
                toast.error("Please upload PAN of individual partner");
                const updatedPartners = partners.map((partner) =>
                partner.id === partnerId ? { ...partner, pan: '' } : partner
                );
                setPartners(updatedPartners);
            }
        }
      };
    const addPartner =()=>{
        
        const newPartnerId = partners.length+1;
        const newPartner = {
            id: newPartnerId,
            pan: '',
            verified: false,
            documents: [],
        };
        setPartners([...partners, newPartner]);
    }
    const addPartnerIdType =()=>{
        const newPartnerIdTypeId = partnersIdType.length+1;
        const newPartnerIdType = {
            id: newPartnerIdTypeId,
            type:"" ,
            name:"",
            aadharNo:"", 
            verified: false,
            documents: []
        };
        setPartnersIdType([...partnersIdType, newPartnerIdType]);
    }
    const removePartner=(partnerIdx)=>{
        if (partners.length === 1) {
            setPartners([{ id: 1, pan: '', verified: false, documents: [] }]);
            setPartnersCompletedCount(0);
        } else {
            const updatedPartners = partners
                .filter((_, i) => i !== partnerIdx)
                .map((partner, i) => ({ ...partner, id: i + 1 }));
            setPartners(updatedPartners);
            setPartnersCompletedCount(partnersCompletedCount-1);
        }
    }
    const removePartnerIdType=(partnerIdTypeIdx)=>{
        if (partnersIdType.length === 1) {
            setPartnersIdType([{ id: 1, type:"" ,name:"",aadharNo:"", verified: false, documents: [] }]);
            setPartnersIdTypeCompletedCount(0);
            setPercentage(percentage-25 >0 ? percentage-25 : 0)
        } else {
            const updatedPartnersIdType = partnersIdType
                .filter((_, i) => i !== partnerIdTypeIdx)
                .map((partnerIdType, i) => ({ ...partnerIdType, id: i + 1 }));
            setPartnersIdType(updatedPartnersIdType);
            setPartnersIdTypeCompletedCount(partnersIdTypeCompletedCount-1);
        }
    }
    const handlePartnerIdTypeSelected = (partnerIdTypeId, idType)=>{
        const updatedPartnersIdTypes = partnersIdType.map((partnerIdType) =>
        partnerIdType.id === partnerIdTypeId ? { ...partnerIdType, type: idType, name:"" , aadharNo:"" , verified: false, documents:[]} : partnerIdType
        );
        setPartnersIdType(updatedPartnersIdTypes);
        setAadharName('');
        setAadharValue('');
        setAadharOTP('');
        setAadharAccessKey('');
        setAadharShareCode('');
    }
    const handleAdharNameChange = (e, partnerIdTypeId, val) =>{
        const updatedPartnersIdTypes = partnersIdType.map((partnerIdType) =>
            partnerIdType.id.toString() === partnerIdTypeId.toString() ? { ...partnerIdType, name:val,
            } : partnerIdType
        );
        setPartnersIdType(updatedPartnersIdTypes);
    }
    const handleAdharNumberChange = (e, partnerIdTypeId, val) => {
        const updatedPartnersIdTypes = partnersIdType.map((partnerIdType) =>
            partnerIdType.id === parseInt(partnerIdTypeId) 
              ? { ...partnerIdType, aadharNo: val }
              : partnerIdType
          );
          setPartnersIdType(updatedPartnersIdTypes);
        if (e.target.value.length === 12 && aadharName !== '') {
          getAdharOtp(e.target.value , updatedPartnersIdTypes , partnerIdTypeId);
        } else if (e.target.value.length === 12 && aadharName === '') {
          toast.error("Name should not be empty, Please enter your name first and then aadhar number");
          setAadharName('');
          setAadharValue('');
        }
      };
    const getAdharOtp = (value , updatedPartnersIdTypes , partnerIdTypeId) =>{
        setIsLoading(true)
        const browser = detect();
        const sendDetails = {
            "value": value,
            "userAgent": browser.name,
            "consent": "Y",
            "name": aadharName,
            "consentText": "I hereby grant consent to..",
            "consentTime": "1610369914",
            "ipAddress": "192.168.43.96",
            "lat": "17.300166",
            "longitude": "78.526495",
            "clientData": {
                "caseId": "a341fafb"
            },
            "deviceId": "5FA01EE3-B9AB-42A3-ACB1-B150D0C2CA07"
        };
        (async () => {
            try {
                const resp = await fireAjaxRequest(AADHAAR_CONSENT_API, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(sendDetails)
                });
                if (resp && resp.data) {
                    setIsLoading(false);
                    setItemToAddKycOtpPopup(true);
                    setAadharOTP([])
                    setCounter(60);
                    setAadharAccessKey(resp.data.result.accessKey)
                    setAadharShareCode(resp.shareCode)
                    setPartnersIdTypeCompletedCount(partnersIdTypeCompletedCount+1);
                    if(partnerIdTypeId===1){
                        setPercentage(percentage+25);
                    }
                    if (resp.message.includes("not")) {
                        toast.error(resp.message);
                    }
                }
                else if (resp) {
                    setIsLoading(false);
                    toast.error("Something Went Wrong, Please Try Again Later or Make sure your entered deatils are correct.")
                }
            } catch (err) {
                setIsLoading(false);
                if(err.code === "422"){
                    toast.error("Invalid Details")
                    }
                    else{
                        toast.error("something went wrong")
                    }
            }
        })();
    }
    const handleAdharOtpPopupCross = () =>{
        setItemToAddKycOtpPopup(false);
        setAadharOTP('');
    }
    const clickEvent = (first, last) => {
        first.value = first.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        if (first.value.length) {
            if (last != 'seventh') {
            document.getElementById(last).focus();
            }
        }
        if (first.value.length < 1) {
            if (first.id == 'sixth') {
                document.getElementById('fifth').focus();
            }
            if (first.id == 'fifth') {
                document.getElementById('fourth').focus();
            }
            if (first.id == 'fourth') {
            document.getElementById('third').focus();
            }
            else if (first.id == 'third') {
            document.getElementById('sec').focus();
            }
            if (first.id == 'sec') {
            document.getElementById('ist').focus();
            }
        }
        // return true;
        }
    const _onChangeOTP = (e, position) => {
        const previousOTP = [...aadharOTP];
        if (e && e.target && e.target.value) {
            previousOTP[position] = e.target.value || "";
        } else {
            previousOTP[position] = "";
        }
        setAadharOTP([...previousOTP]);
    }
    const _onKeyDownOTP = (e, position) => {
        if (e.key === "Enter") {
            handleOtpverifyBtn();
        }
    }
    const handleOtpverifyBtn = (id) =>{
        if(aadharOTP.length===6){
            handleVarifyOtp(id);
            setItemToAddKycOtpPopup(false);
        }
    }
    const handleVarifyOtp = (id) =>{
        setIsLoading(true);
        const verifyOTPDetails = {
            "otp":  aadharOTP.join(""),
            "shareCode": aadharShareCode,
            "accessKey": aadharAccessKey,
            "aadhaarNo": aadharValue,
            "clientData": {
                "caseId": "a341fafb"
            }
        };
        (async () => {
            try {
                const resp = await fireAjaxRequest(GET_AADHAAR_API, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(verifyOTPDetails)
                });
                if (resp && resp.data && resp.data.dataFromAadhaar) {
                    setIsLoading(false);
                    setItemToAddKycOtpPopup(false);
                    console.log(id);

                    const updatedPartnersIdType = partnersIdType.map((partnerIdType) =>
                        partnerIdType.id === id ? { ...partnerIdType, verified :true} : partnerIdType
                    );
                    console.log(updatedPartnersIdType)
                    setPartnersIdType(updatedPartnersIdType);
                    toast.success("Aadhar is successfully verified");
                    setAadharName('');
                    setAadharValue('');
                    setAadharOTP([]);
                }
                else{
                    toast.error("Something Went Wrong..Please try again later.")
                }
            } catch (err) {
                setIsLoading(false);
                if(err.code === "422"){
                toast.error("Invalid OTP")
                }
                else{
                    toast.error("something went wrong")
                }
            }
        })();
    }
    const handleResendOtp = () =>{
        getAdharOtp(aadharValue);
    }
    const partnershipDocsProceed = () => {
        
        const documentContainers = document.querySelectorAll('.document-container');
        let incompleteFound = false; 

        documentContainers.forEach((container, index) => {
            
            if (incompleteFound) {
                return; 
            }
            const documentDiv = container.querySelector('.document-name-upload');
            const documentText=documentDiv.querySelector('.document-Name-div').querySelector('.document-name').innerText
            const uploadDiv = container.querySelector('.openDocument-Div');
            const uploadDisplay = getComputedStyle(uploadDiv).display;
            let isCompleted = false;
            if(documentText==="MSME/Udyam Certificate")//msme
            {
                if(ecashMsmeUdyog.length===12 && msmeDocuments.length>0){
                    isCompleted=true;
                }
            }
            else if(documentText === "PAN Card of all Partners *" || documentText === "PAN Card of all Signatories *"){//pans
                partners.map((partner,idx)=>{
                    if(partner.pan!=='' && partner.documents.length>0){
                        isCompleted=true;
                    }
                })
            }
            else if(documentText === "Aadhaar/ Passport/Voter ID *"){//ids
                partnersIdType.map((partnerIdType,idx)=>{
                    if(partnerIdType.type!==''){
                        if(partnerIdType.type==="AADHAR" && (partnerIdType.name!=='' && partnerIdType.aadharNo!=='' && partnerIdType.documents.length>0)){
                            isCompleted=true;
                        }else if(partnerIdType.type!=="AADHAR" && partnerIdType.documents.length>0){
                            isCompleted=true;
                        }
                    }
                })
            }
            else if(documentText === "GST Certificate *"){//gst
                if(gstDocuments.length>0){
                    isCompleted=true;
                }
            }
            else if(documentText === "Shops and Establishment" || documentText === "Utility Bill" || documentText === "Mandi License"){//gst
                if(anyOneShopsAndEstablishment.length>0 || anyOneUtilityBill.length>0 || anyOneMandiLicense.length>0){
                    isCompleted=true;
                }
            }
            if (!isCompleted) {
                if (uploadDisplay === 'none') {
                    uploadDiv.style.display = 'flex';
                    documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'none';
                    closeOtherContainers(documentContainers, index);
                }
                else{
                    closeOtherContainers(documentContainers, index);
                }
                incompleteFound = true; 
            } else {
                if (uploadDisplay === 'flex') {
                    uploadDiv.style.display = 'none';
                    documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'flex';
                } else {
                    
                }
            }
        });

        if(!incompleteFound){
            if(organization_type==="Proprietorship"){
                setShowContinueBtn(true);
            }else{
                let updatedPartners=partners;
                setPartners(prevPartners => {
                     updatedPartners = prevPartners.filter(partner => {
                      if (partner.pan==='' || partner.documents.length===0) {
                        return false; 
                      }
                      return true;
                    });
                  
                    return updatedPartners;
                  });
                  let updatedPartnersIdTypes=partnersIdType;
                setPartnersIdType(prevPartnersIdType => {
                     updatedPartnersIdTypes = prevPartnersIdType.filter(partnerIdType => {
                      if (partnerIdType.type !== '') {
                        if (partnerIdType.type === "AADHAR" && (partnerIdType.name === '' || partnerIdType.aadharNo === '' || partnerIdType.documents.length === 0)) {
                          return false; 
                        } else if (partnerIdType.type !== "AADHAR" && partnerIdType.documents.length === 0) {
                          return false; 
                        }
                      } else {
                        return false; 
                      }
                      return true; 
                    });
                    return updatedPartnersIdTypes;
                });
                if((updatedPartners.length === updatedPartnersIdTypes.length) && percentage===100){
                    setShowContinueBtn(true);
                }
                else{
                    toast.error("Number of PAN's and Ids for partners should be same");
                }
            }
            
        }
    };
    const partnershipLimitDocsProceed =() =>{
        const documentContainers = document.querySelectorAll('.document-container75Lac');
        let incompleteFoundd = false; 
        documentContainers.forEach((container, index) => {
            if (incompleteFoundd) {
                return; 
            }
            const documentDiv = container.querySelector('.document-name-upload');
            const uploadDiv = container.querySelector('.openDocument-Div');
            const uploadDisplay = getComputedStyle(uploadDiv).display;
            let isCompleted = false;

            if(additionalDocs[index].documents.length>0){
                isCompleted=true;
            }

            if (!isCompleted) {
                if (uploadDisplay === 'none') {
                    uploadDiv.style.display = 'flex';
                    documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'none';
                    closeOtherContainers(documentContainers, index);
                }
                else{
                    closeOtherContainers(documentContainers, index);
                }
                incompleteFoundd = true; 
            } else {
                if (uploadDisplay === 'flex') {
                    uploadDiv.style.display = 'none';
                    documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'flex';
                } 
            }
        });
        if(!incompleteFoundd){
            setShowContinueBtn(true);
        }

    }
    const closeOtherContainers = (containers, currentIndex) => {
        containers.forEach((container, index) => {
            if (index !== currentIndex) {
                const uploadDiv = container.querySelector('.openDocument-Div');
                const documentDiv = container.querySelector('.document-name-upload');
                uploadDiv.style.display = 'none';
                documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'flex';
            }
        });
    };
    const getBloblink = async(file) => {
        var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
        // if (e.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.type == "video/mp4") {
        //   toast.error("Please Upload the correct file format")
        // } else if (e.size > 10485760) {
        //   toast.error("Uploaded file is more than 10MB")
        // }
        let formData = new FormData();
        formData.append("file", file);
        formData.append("prefix", "ecashWebDocs");
        try {
            const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: formData
            });
            if (resp && resp.filePath) {
                return resp.filePath;
            }
        }
        catch (err) {
            toast.error("error while uploading file to blob");
        }
    }
    const saveMsme = async() =>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "requestId": ecashRequestId,
            "msmeNo": ecashMsmeUdyog,
            "msmeDocLink": msmeDocuments[0].blobLink,
            "address":ecashBusinessAddress,
            "city": city,
            "state": state,
            "pincode": ecashBusinessPincode,
            "vintage":vintageDate,
            "registrationDate": regDate,
            "organizationName": tradeName,
          };

        try {
            const resp = await fireAjaxRequest(`${PUT_MSME_DETAILS}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){

            }
            else{
                toast.error("Something went wrong while saving msme")
            }
        } catch (err) {
            if(err.code === "400"){
                toast.error("Invalid GST Details in msme")
            }
            else{
                toast.error("something went wrong while saving msme")
            }
        }
    }
    const savePartnersPansAndIds = async()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let partnerDocs = [];
        partners.forEach(partner => {
            let mergeDataOfThisPartner={};
            const id = partner.id;
            const partnerIdType = partnersIdType.find(item => item.id === id);
            const partnersBlobLinksArray = partner.documents.map(document => document.blobLink);
            const partnersIdTypeBlobLinksArray = partnerIdType.documents.map(document => document.blobLink);

            const partnerName = `Partner-${id}`;
            const panDocument = {
              docNo: partner.pan,
              docType: 'PAN',
              docLink:partnersBlobLinksArray,
            };
            const idTypeDocument = {
              docNo: partnerIdType.aadharNo !== '' ? partnerIdType.aadharNo : "", 
              docType: partnerIdType.type,
              docLink: partnersIdTypeBlobLinksArray,
            };
            mergeDataOfThisPartner = {
                "partnerName": partnerName,
                "documents": [
                    panDocument,
                    idTypeDocument
                ]
            }
            partnerDocs.push(mergeDataOfThisPartner);
          });

        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashRequestId,
            "partnerDocuments" : partnerDocs,
          };

        try {
            const resp = await fireAjaxRequest(`${PARTNER_ADD_DOCS}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){

            }
            else{
                toast.error("Something went wrong while saving patnersDocs")
            }
        } catch (err) {
            if(err.code === "400"){
                toast.error("Invalid GST Details in msme")
            }
            else{
                toast.error("something went wrong while saving patnersDocs")
            }
        }
    }
    const saveAndExitPartnersPansAndIds = async(isPartnersPansCompleted, isPartnersIdsCompleted)=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let partnerDocs = [];
        if(isPartnersPansCompleted){
            partners.forEach(partner => {
                const id = partner.id;
                const partnersBlobLinksArray = partner.documents.map(document => document.blobLink);
    
                const partnerName = organization_type ==="Partnership" ?`Partner-${id}` :(organization_type==="LLP" || organization_type==="Company" ? `Authorized Signatory-${id}` : "");
                const panDocument = {
                  docNo: partner.pan,
                  docType: 'PAN',
                  docLink: partnersBlobLinksArray,
                };
                const partnerType =organization_type ==="Partnership" ? "partnerName" :(organization_type==="LLP" || organization_type==="Company" ? "signatoryName" : "")
                let DataOfThisPartner = {
                    [partnerType]: partnerName,
                    "documents": [
                        panDocument,
                    ]
                }
                partnerDocs.push(DataOfThisPartner);
            });
        }
        if(isPartnersIdsCompleted){
            partnersIdType.forEach(partnerIdType => {
                const id = partnerIdType.id;

                const partnersIdTypeBlobLinksArray = partnerIdType.documents.map(document => document.blobLink);

                const partnerName = organization_type ==="Partnership" ?`Partner-${id}` :(organization_type==="LLP" || organization_type==="Company" ? `Authorized Signatory-${id}` : "");

                const idTypeDocument = {
                    docNo: partnerIdType.aadharNo !== '' ? partnerIdType.aadharNo : "", 
                    docType: partnerIdType.type,
                    docLink: partnersIdTypeBlobLinksArray,
                  };

                const partner = partners.find(item => item.id === id);

                if(partner && partner.pan!=='' && partner.documents.length>0){
                    let partnerDocsWithSameId = partnerDocs.find(item => item.partnerName === partnerName);
                    partnerDocsWithSameId.documents.push(idTypeDocument);
                }
                else{
                    const partnerType =organization_type ==="Partnership" ? "partnerName" :(organization_type==="LLP" || organization_type==="Company" ? "signatoryName" : "")
                    let DataOfThisPartner = {
                        [partnerType]: partnerName,
                        "documents": [
                            idTypeDocument,
                        ]
                    }
                    partnerDocs.push(DataOfThisPartner);
                }
            });
        }
        const TypeDocuments =organization_type ==="Partnership" ? "partnerDocuments" :(organization_type==="LLP" || organization_type==="Company" ? "signatoryDocuments" : "")
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashRequestId,
            [TypeDocuments] : partnerDocs,
          };

          const ApiUrl =organization_type ==="Partnership" ? PARTNER_ADD_DOCS :(organization_type==="LLP" || organization_type==="Company" ? SIGNATORY_ADD_DOCS : "")
        try {
            const resp = await fireAjaxRequest(ApiUrl, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){

            }
            else{
                toast.error("Something went wrong while saving Docs")
            }
        } catch (err) {
            if(err.code === "400"){
                toast.error("Invalid GST Details in msme")
            }
            else{
                toast.error("something went wrong while saving Docs")
            }
        }
    }
    const saveGst = async()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashRequestId,
            "documents": [
                {
                  "docType": "GST_CERTIFICATE",
                  "docNo":"",
                  "docLink": gstDocuments.map(document => document.blobLink),
                }
              ]
          };

        try {
            const resp = await fireAjaxRequest(`${SAVE_ADD_DOCS}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){

            }
            else{
                toast.error("Something went wrong while saving gst")
            }
        } catch (err) {
            if(err.code === "400"){
                toast.error("Invalid GST Details in gst")
            }
            else{
                toast.error("something went wrong while saving gst")
            }
        }
    }
    const saveAnyOneDoc = async()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let anyOneDocType;
        let anyOneDocs;
        if(anyOneShopsAndEstablishment.length>0){
            anyOneDocType="SHOPS_AND_ESTABLISHMENT_CERTIFICATE";
            anyOneDocs=anyOneShopsAndEstablishment;
        }
        else if(anyOneUtilityBill.length>0){
            anyOneDocType="UTILITY_BILL";
            anyOneDocs=anyOneUtilityBill;
        }
        else  if(anyOneMandiLicense.length>0){
            anyOneDocType="MANDI_LICENSE";
            anyOneDocs=anyOneMandiLicense;
        }
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashRequestId,
            "documents": [
                {
                  "docType": anyOneDocType,
                  "docNo":"",
                  "docLink": anyOneDocs.map(document => document.blobLink),
                }
              ]
          };

        try {
            const resp = await fireAjaxRequest(`${SAVE_ADD_DOCS}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){

            }
            else{
                toast.error("Something went wrong while saving docs")
            }
        } catch (err) {
            if(err.code === "400"){
                toast.error("Invalid Details")
            }
            else{
                toast.error("something went wrong while saving docs")
            }
        }
    }
    const saveProprietorId = async()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        const partnersIdTypeBlobLinksArray = partnersIdType[0].documents.map(document => document.blobLink);
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashRequestId,
            "documents": [
                {
                  "docType": partnersIdType[0].type,
                  "docNo":partnersIdType[0].aadharNo !== '' ? partnersIdType[0].aadharNo : "", 
                  "docLink": partnersIdTypeBlobLinksArray,
                }
              ]
          };

        try {
            const resp = await fireAjaxRequest(`${SAVE_ADD_DOCS}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){
                
            }
            else{
                toast.error("Something went wrong while saving gst")
            }
        } catch (err) {
            if(err.code === "400"){
                toast.error("Invalid GST Details in gst")
            }
            else{
                toast.error("something went wrong while saving gst")
            }
        }
    }
    const saveAddiDocs = async()=>{
        setIsLoading(true);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        const filteredAndMapped = additionalDocs
        .filter(item => item.documents.length !== 0)
        .map((item, idx) => ({
            "docType": additionalDocsNames[idx].docName,
            "docNo": "",
            "docLink": item.documents.map(document => document.blobLink)
        }));
        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashRequestId,
            "isAdditionalDocRequest": true,
            "documents": filteredAndMapped
        };
        try {
            const resp = await fireAjaxRequest(`${SAVE_ADD_DOCS}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },
                body: JSON.stringify(sendBody),
            });
            if(resp){
                setIsLoading(false);
            }
            else{
                setIsLoading(false);
                toast.error("Something went wrong while saving gst")
            }
        } catch (err) {
            if(err.code === "400"){
                setIsLoading(false);
                toast.error("Invalid GST Details in gst")
            }
            else{
                setIsLoading(false);
                toast.error("something went wrong while saving gst")
            }
        }
    }
    const saveCrDocs = async(ecashId)=>{
        
        setIsLoading(true);
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let combinedCrDocuments= [];
        let ItrBloblinks={
            "docType" : "ITR_AND_AUDITED_FINANCIAL_STATEMENTS",
            "docName" : Completedyears.join(","),
            "docNo":"",
            "docLink": yearData.map(obj => obj.documents.map(doc => doc.blobLink)).flat(),
        }
        let gst3BBloblinks ={
            "docType" : "GST_3B_PAST_12MONTHS",
            "docNo":"",
            "docLink": gst3BDocuments.map(doc => doc.blobLink),
        }
        let bankStatementBloblinks ={
            "docType" : "BANK_STATEMENTS_MAIN_OPERATIVE_ACCOUNT_PAST_12MONTHS",
            "docNo":"",
            "docLink": bankStatementDocuments.map(doc => doc.blobLink),
        }
        if(Completedyears.length===2)
        combinedCrDocuments.push(ItrBloblinks)
        if(gst3BBloblinks.docLink.length>0)
        combinedCrDocuments.push(gst3BBloblinks)
        if(bankStatementBloblinks.docLink.length>0)
        combinedCrDocuments.push(bankStatementBloblinks)

        let sendBody ={
            "traderId": userInfo?.user?.id,
            "ecashRequestId": ecashId,
            "documents": combinedCrDocuments
        };
        if(combinedCrDocuments.length>0){
            try {
                const resp = await fireAjaxRequest(`${SAVE_ADD_DOCS}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${userInfo.token}`
                    },
                    body: JSON.stringify(sendBody),
                });
                if(resp){
                    setIsLoading(false);
                    setEcashLimitApplied("2Crore");
                }
                else{
                    setIsLoading(false);
                    toast.error("Something went wrong while saving gst")
                }
            } catch (err) {
                if(err.code === "400"){
                    setIsLoading(false);
                    toast.error("Invalid GST Details in gst")
                }
                else{
                    setIsLoading(false);
                    toast.error("something went wrong while saving gst")
                }
            }
        }
        
    }
    const ContinuePartnershipDocs1 = async()=>{
        debugger
        if(userPersonalDetails.accountType==="INDIVIDUAL_TRADER" && organization_type !=="Proprietorship"){
            let isOk=false;
            partners.map((partner)=>{
                if(partner.pan === userPersonalDetails.kyc[0].value){
                    isOk=true;
                }
            })
            if(isOk===false){
                toast.error("atleast one pan number should be match with the pan number saved in profile section")
                return;
            }
        }
        // setIsLoading(true);
        setShowReviewDocs(true);

        try {
            //-------msme
            if(isRegisteredMSME || (ecashGSTValue==="" || ecashGSTValue===null)){
                console.log(ecashMsmeUdyog.length, msmeDocuments)
                if(ecashMsmeUdyog.length===12 && msmeDocuments.length>0 && ((ecashGSTValue==="" || ecashGSTValue===null)? (city!=="" && city!==null && state!=="" && state!==null) : true)){
                    await saveMsme();
                }
                else{
                    toast.error("complete msme first");
                    setShowReviewDocs(true);
                    return;
                }
            }
            
            //---------proprietor id
            if(organization_type==="Proprietorship"){
                if(partnersIdType[0].type!==''){
                    if(partnersIdType[0].type==="AADHAR" && (partnersIdType[0].name!=='' && partnersIdType[0].aadharNo!=='' && partnersIdType[0].documents.length>0)){
                        saveProprietorId();
                    }else if(partnersIdType[0].type!=="AADHAR" && partnersIdType[0].documents.length>0){
                        saveProprietorId();
                    }else{
                        toast.error("Plaese upload any id proof");
                    }
                }
                else{
                    toast.error("Plaese upload any id proof");
                    setShowReviewDocs(true);
                    return;
                }
            }
            else{
                    //---------partners pan and ids
                var isPartnersPansCompleted=false;
                var isPartnersIdsCompleted=false;
                partners.map((partner,idx)=>{
                    if(partner.pan!=='' && partner.documents.length>0){
                        isPartnersPansCompleted=true;
                    }
                })
                partnersIdType.map((partnerIdType,idx)=>{
                    if(partnerIdType.type!==''){
                        if(partnerIdType.type==="AADHAR" && (partnerIdType.name!=='' && partnerIdType.aadharNo!=='' && partnerIdType.documents.length>0)){
                            isPartnersIdsCompleted=true;
                        }else if(partnerIdType.type!=="AADHAR" && partnerIdType.documents.length>0){
                            isPartnersIdsCompleted=true;
                        }
                    }
                })
                if(isPartnersPansCompleted && isPartnersIdsCompleted){
                    await savePartnersPansAndIds();
                }
                else{
                    if(!isPartnersPansCompleted){
                        toast.error("complete Pan cards of All")
                        setShowReviewDocs(true);
                        return;
                    }
                    else if(!isPartnersIdsCompleted){
                        toast.error("complete Ids of All ")
                        setShowReviewDocs(true);
                        return;
                    }
                }
            }
            

            //---------gst
            if(ecashGSTValue!=="" && ecashGSTValue!==null){
                if(gstDocuments.length>0){
                    await saveGst();
                }
                else{
                    toast.error("complete gst first")
                    setShowReviewDocs(true);
                    return;
                }
            }

            //---------any one doc
            if(organization_type==="Proprietorship" && (ecashGSTValue==="" || ecashGSTValue==null) ){
                if(anyOneShopsAndEstablishment.length>0 || anyOneUtilityBill.length>0 || anyOneMandiLicense.length>0){
                    await saveAnyOneDoc();
                }
                else{
                    toast.error("complete any one of the last three docs first");
                    setShowReviewDocs(true);
                    return;
                }
            }
            

            //----------lenderSelection and additional docs
            await lenderSelection();
            

        } catch (error) {
            console.error('An error occurred while saving documents');
        }
        
    }
    const ContinuePartnershipLimitDocs =  async()=>{
        try{
            await saveAddiDocs();
            if(ecashLimitApplied==="75Lakh"){
                setDetails_state(5);
            }else{
                setDetails_state(8);
            }
            
            setShowReviewDocs(false);
            setPercentage(0);
            setShowContinueBtn(false);
            setShowSaveBeforeAlert(false);
        }catch(err){
            toast.error(err);
        }
       
        
    }
    const ContinueCrDocuments =  async()=>{
        if(ecashLimitApplied==="75Lakh"){
            LimitIncreaseAPI();
        }else {
            try{
                setShowReviewDocs(true);
                let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
                let combinedCrDocuments= [];
                let ItrBloblinks={
                    "docType" : "ITR_AND_AUDITED_FINANCIAL_STATEMENTS",
                    "docName" : Completedyears.join(","),
                    "docNo":"",
                    "docLink": yearData.map(obj => obj.documents.map(doc => doc.blobLink)).flat(),
                }
                let gst3BBloblinks ={
                    "docType" : "GST_3B_PAST_12MONTHS",
                    "docNo":"",
                    "docLink": gst3BDocuments.map(doc => doc.blobLink),
                }
                let bankStatementBloblinks ={
                    "docType" : "BANK_STATEMENTS_MAIN_OPERATIVE_ACCOUNT_PAST_12MONTHS",
                    "docNo":"",
                    "docLink": bankStatementDocuments.map(doc => doc.blobLink),
                }
                if(Completedyears.length===2)
                combinedCrDocuments.push(ItrBloblinks)
                if(gst3BBloblinks.docLink.length>0)
                combinedCrDocuments.push(gst3BBloblinks)
                if(bankStatementBloblinks.docLink.length>0)
                combinedCrDocuments.push(bankStatementBloblinks)
        
                let sendBody ={
                    "traderId": userInfo?.user?.id,
                    "ecashRequestId": ecashRequestId,
                    "documents": combinedCrDocuments
                };
                if(combinedCrDocuments.length===3){
                    try {
                        const resp = await fireAjaxRequest(`${SAVE_ADD_DOCS}`, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${userInfo.token}`
                            },
                            body: JSON.stringify(sendBody),
                        });
                        if(resp){
                            setPercentage(0);
                            setShowContinueBtn(false);
                            setShowSaveBeforeAlert(false);
                            await getPartnershipAdditionalDocs(resp.assignedLenderId, organization_type.toLocaleUpperCase(), "2Crore");
                            setShowReviewDocs(false);
                        }
                        else{
                            setIsLoading(false);
                            toast.error("Something went wrong while saving gst")
                        }
                    } catch (err) {
                        if(err.code === "400"){
                            setIsLoading(false);
                            toast.error("Invalid GST Details in gst")
                        }
                        else{
                            setIsLoading(false);
                            toast.error("something went wrong while saving gst")
                        }
                    }
                }else{
                    toast.error("Please upload all the required documents");
                }
                
                    

            }catch(err){
                toast.error(err);
            }
        }
    }
    function compareDocumentsOfProgressReportToCurrent(progressDocs, currentDocs) {
        for (let i = 0; i < progressDocs.length; i++) {
            if(progressDocs.length !== currentDocs.length){
                return false;
            }
            if (progressDocs[i].blobLink !== currentDocs[i].blobLink) {
                return false;
            }
        }
        return true;
    }
    const handleBackClick = () =>{
        if(details_state===2){
            setDetails_state(details_state-1); 
            setEcashGSTValue(''); 
            setIsRegisteredMSME(null); 
            // setIsCheckedEcashTC(false); 
            // setEcashPANValue(ecashPANValue)
        }
        if(details_state===3){
            if(showSaveBeforeAlert){
                history.push('/postreg');
                return;
            }
            else if(ecashMsmeUdyog!== progressReportDocData.msmeNo || (!compareDocumentsOfProgressReportToCurrent(progressReportDocData.msmeDocs, msmeDocuments))){
                setShowSaveBeforeAlert(true);  
                return;
            }
            else if(!compareDocumentsOfProgressReportToCurrent(progressReportDocData.gstDocs, gstDocuments)){
                setShowSaveBeforeAlert(true);
                return;
            }
            else if(partners.length!==progressReportDocData.partnersData.length){
                setShowSaveBeforeAlert(true);
                return;
            }
            else if(partnersIdType.length !==progressReportDocData.partnersIdTypeData.length){
                setShowSaveBeforeAlert(true);
                return;
            }
            else{
                if(partners.length===progressReportDocData.partnersData.length){
                    for (let i = 0; i < partners.length; i++) {
                        const obj1 = partners[i];
                        const obj2 = progressReportDocData.partnersData[i];
                        if (!(obj1.pan === obj2.pan && obj1.verified === obj2.verified && compareDocumentsOfProgressReportToCurrent(obj1.documents, obj2.documents))) {
                            setShowSaveBeforeAlert(true);
                            return;
                        }
                    }
                }
                if(partnersIdType.length ===progressReportDocData.partnersIdTypeData.length){
                    for (let i = 0; i < partnersIdType.length; i++) {
                        const obj1 = partnersIdType[i];
                        const obj2 = progressReportDocData.partnersIdTypeData[i];
                        if (!(obj1.type === obj2.type && obj1.name === obj2.name &&  obj1.aadharNo === obj2.aadharNo && compareDocumentsOfProgressReportToCurrent(obj1.documents, obj2.documents))) {
                            setShowSaveBeforeAlert(true);
                            return;
                        }
                    }
                }
            }
            if(showSaveBeforeAlert===false){
                history.push('/postreg');
            }

        }
        if(details_state===4){
            history.push('/postreg');
            return;
        }
        if(details_state===6){
            setDetails_state(details_state-1); 
            setPercentage(0);

        }
    }
    const SaveAndExitPartnershipDocs1 = async() =>{
        if(ecashMsmeUdyog.length===12 && msmeDocuments.length>0){
            await saveMsme();
        }
        if(gstDocuments.length>0){
            await saveGst();
        }
        if(organization_type==="Proprietorship" && (ecashGSTValue==="" ||  ecashGSTValue===null)){
            if(anyOneMandiLicense.length>0 || anyOneShopsAndEstablishment.length>0 || anyOneUtilityBill.length>0){
                await saveAnyOneDoc();
            }
        }
        if(organization_type==="Proprietorship" && partnersIdType[0].documents.length>0){
            saveProprietorId();
        }
        if(organization_type!=="Proprietorship"){
            var isPartnersPansCompleted=false;
            var isPartnersIdsCompleted=false;
            partners.map((partner,idx)=>{
                if(partner.pan!=='' && partner.documents.length>0){
                    isPartnersPansCompleted=true;
                }
            })
            partnersIdType.map((partnerIdType,idx)=>{
                if(partnerIdType.type!==''){
                    if(partnerIdType.type==="AADHAR" && (partnerIdType.name!=='' && partnerIdType.aadharNo!=='' && partnerIdType.documents.length>0)){
                        isPartnersIdsCompleted=true;
                    }else if(partnerIdType.type!=="AADHAR" && partnerIdType.documents.length>0){
                        isPartnersIdsCompleted=true;
                    }
                }
            })
            if(isPartnersPansCompleted || isPartnersIdsCompleted){
                await saveAndExitPartnersPansAndIds(isPartnersPansCompleted, isPartnersIdsCompleted);
            }
            history.push("/postreg")
        }
    }
    const SaveAndExitPartnershipLimitDocs =async() =>{
        await saveAddiDocs();
        history.push("./postreg")
    }
    const SaveAndExitCRDocuments =async() =>{
        
        if(ecashLimitApplied==="75Lakh"){
            await LimitIncreaseAPI();
        }else{
            await saveCrDocs(ecashRequestId);
        }
        history.push('/postreg');
    }
    const lenderSelection = async()=>{
        debugger
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${LENDER_SELECTION}ecashRequestId=${ecashRequestId}&traderId=${userInfo.user.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },  
            });
            if (resp) {
                setShowReviewDocs(false);
                setDetails_state(4);
                setPercentage(0);
                setShowContinueBtn(false);
                setShowSaveBeforeAlert(false);
                getPartnershipAdditionalDocs(resp?.assignedLenderId, organization_type.toUpperCase(), ecashLimitApplied);
            } else {
                toast.error("Unable to fetch lender details")
            }
        } catch (err) {
            toast.error("error while getting lender details")
        }
    }
    const getPartnershipAdditionalDocs = async(lenderID, organisationalType, limit="75Lakh")=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const resp = await fireAjaxRequest(`${ADDITIONAL_DOCS}lenderId=${lenderID}&ecashLimit=${limit!=="75Lakh" ?"2Crore":limit}&organizationType=${organisationalType?organisationalType.toUpperCase():organization_type.toUpperCase()}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userInfo.token}`
                },  
            });
            if (resp) {
                setAdditionalDocsNames(resp?.additionalDocs);
                const Additionaldocuments = resp?.additionalDocs.map(item => ({
                    "docName": item.docName,
                    "docFor": item.docFor,
                    "docDescrption":item.docDescrption,
                    "docForLimit": item.docForLimit,
                    "documents": []
                }));
                setAdditionalDocs(Additionaldocuments);
                setDetails_state(4);
                return Additionaldocuments;
            } else {
                toast.error("Unable to fetch lender details")
            }
        } catch (err) {
            toast.error("error while getting lender details")
        }
    }
    const handleYearChange = (selectedYear)=>{
        setITRYearSelectValue(selectedYear);
        if (selectedYear !== "Select Year") {

            var updatedYearData = yearData.filter((year) => {
                return year.documents.length === 6 || year.year === selectedYear;
              });

            const yearExists = updatedYearData.some((data) => data.year === selectedYear);
      
            if (!yearExists) {
              setYearData((prevData) => [
                ...updatedYearData,
                { year: selectedYear, documents: [] },
              ]);
            }
          }
    }
    const financialDocsProceed = ()=>{
        const documentContainers = document.querySelectorAll('.document-container2Cr');
        let incompleteFoundFinancialDoc = false; 
        documentContainers.forEach((container, index) => {
            
            if (incompleteFoundFinancialDoc) {
                return; 
            }
            const documentDiv = container.querySelector('.document-name-upload');
            const uploadDiv = container.querySelector('.openDocument-Div');
            const uploadDisplay = getComputedStyle(uploadDiv).display;
            let isCompleted = false;

            if(index===0){
                if(Completedyears.length==2){
                    isCompleted=true;
                }
            }
            else if(index===1){
                if(gst3BDocuments.length>0){
                    isCompleted=true;
                }
            }
            else if(index===2){
                if(bankStatementDocuments.length>0){
                    isCompleted=true;
                }
            }

            if (!isCompleted) {
                if (uploadDisplay === 'none') {
                    uploadDiv.style.display = 'flex';
                    documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'none';
                    closeOtherContainers(documentContainers, index);
                    if(index===0){
                        let arr=[];
                        yearData.map((data)=>{
                            if(data.documents.length===6){
                                arr.push(data.year);
                            }
                            else if(data.documentslength>0 && data.documents.length<6){
                                toast.error("Please complete the ITR documents.")
                            }
                        });
                        setCompletedyears(arr);
                        if(!yearData.some(obj=>obj.documents.length<6 && obj.documents.length>=0)){
                            const updatedYearOptions = yearOptions.filter(item => item !== itrYearSelectValue);
                            setYearOptions(updatedYearOptions);
                            if (updatedYearOptions.length > 0) {
                                const lastElement = updatedYearOptions[updatedYearOptions.length - 1];
                                handleYearChange(lastElement);
                            }
                        }
                    }
                }
                else{
                    closeOtherContainers(documentContainers, index);
                    if(index===0){
                        let arr=[];
                        yearData.map((data)=>{
                            if(data.documents.length===6){
                                arr.push(data.year);
                            }
                        })
                        setCompletedyears(arr);
                        if(!yearData.some(obj=>obj.documents.length<6 && obj.documents.length>=0)){
                            const updatedYearOptions = yearOptions.filter(item => item !== itrYearSelectValue);
                            setYearOptions(updatedYearOptions);
                            if (updatedYearOptions.length > 0) {
                                const lastElement = updatedYearOptions[updatedYearOptions.length - 1];
                                handleYearChange(lastElement);
                            }
                        }
                    }
                }
                incompleteFoundFinancialDoc = true; 
            } else {
                if (uploadDisplay === 'flex') {
                    uploadDiv.style.display = 'none';
                    documentDiv.querySelector('.greenUpload-docNumber-div').style.display = 'flex';
                } 
            }
        });
        if(!incompleteFoundFinancialDoc){
            setShowContinueBtn(true);
        }
        
    }
    const LimitIncreaseAPI = async() =>{
        
        setIsLoading(true);
        if(ecashRequestIDsArray.length<2){
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            try {
                const resp = await fireAjaxRequest(`${LIMIT_INCREASE}ecashRequestId=${ecashRequestId}&traderId=${userInfo.user.id}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${userInfo.token}`
                    },  
                });
                if (resp) {
                    saveCrDocs(resp.ecashRequestId);
                    // traderIsEligible();
                } else {
                    setIsLoading(false);
                    toast.error("Unable to fetch user eligibility details");
                }
            } catch (err) {
                setIsLoading(false);
                toast.error("Error while increasing limit");
            }
        }
        else{
            toast.error("Request to Increase Limit is already Generated");
        }
        
    }
    

    return (
        <div class="mainContainer">
            <div class="innerContainer">
                <div class="chatOverlay">
                    <div class="toaster">
                        Phone number copied successfully!
                    </div>
                    <div class="loginContainer">
                        <div class="loginLeft">
                            <svg width="85" height="33" viewBox="0 0 85 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M39.9681 5.00335C39.9681 5.73383 39.8752 6.3983 39.6895 6.99676C39.5038 7.58643 39.2252 8.09688 38.8538 8.52813C38.4913 8.95938 38.0358 9.28941 37.4876 9.51824C36.9481 9.74706 36.3203 9.86148 35.604 9.86148C34.8611 9.86148 34.2156 9.74706 33.6673 9.51824C33.119 9.28061 32.6636 8.95058 32.301 8.52813C31.9384 8.09688 31.6687 7.58203 31.4919 6.98356C31.315 6.3851 31.2266 5.72062 31.2266 4.99014C31.2266 4.02204 31.3857 3.17715 31.7041 2.45547C32.0225 1.73379 32.5044 1.17053 33.15 0.765684C33.8044 0.36084 34.6268 0.158418 35.6172 0.158418C36.5634 0.158418 37.3593 0.36084 38.0049 0.765684C38.6504 1.16173 39.1368 1.72499 39.464 2.45547C39.8001 3.17715 39.9681 4.02644 39.9681 5.00335ZM32.4867 5.00335C32.4867 5.79543 32.5973 6.47751 32.8183 7.04957C33.0394 7.62163 33.3799 8.06168 33.8397 8.36971C34.3084 8.67775 34.8965 8.83177 35.604 8.83177C36.3203 8.83177 36.9039 8.67775 37.3549 8.36971C37.8148 8.06168 38.1552 7.62163 38.3763 7.04957C38.5974 6.47751 38.7079 5.79543 38.7079 5.00335C38.7079 3.81522 38.4603 2.88672 37.9651 2.21784C37.4699 1.54017 36.6872 1.20133 35.6172 1.20133C34.9009 1.20133 34.3084 1.35535 33.8397 1.66338C33.3799 1.96261 33.0394 2.39826 32.8183 2.97032C32.5973 3.53359 32.4867 4.21126 32.4867 5.00335Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M47.4351 2.52148C47.5677 2.52148 47.7092 2.53028 47.8595 2.54788C48.0187 2.55668 48.1558 2.57428 48.2707 2.60068L48.1248 3.67C48.0099 3.6436 47.8816 3.6216 47.7402 3.60399C47.6075 3.58639 47.4793 3.57759 47.3555 3.57759C47.0813 3.57759 46.8205 3.6348 46.5728 3.74921C46.3252 3.86362 46.1042 4.02644 45.9096 4.23766C45.7151 4.44008 45.5603 4.68651 45.4453 4.97694C45.3392 5.26737 45.2862 5.59301 45.2862 5.95385V9.72946H44.1189V2.65349H45.0739L45.2066 3.94723H45.2596C45.41 3.6832 45.5913 3.44558 45.8035 3.23435C46.0157 3.01433 46.2589 2.84271 46.5331 2.7195C46.8072 2.58748 47.1079 2.52148 47.4351 2.52148Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M52.9765 2.65349V9.72946H51.8092V2.65349H52.9765ZM52.4061 0C52.5829 0 52.7377 0.0616067 52.8704 0.18482C53.0118 0.299233 53.0826 0.484053 53.0826 0.739281C53.0826 0.985707 53.0118 1.17053 52.8704 1.29374C52.7377 1.41695 52.5829 1.47856 52.4061 1.47856C52.2115 1.47856 52.0479 1.41695 51.9153 1.29374C51.7826 1.17053 51.7163 0.985707 51.7163 0.739281C51.7163 0.484053 51.7826 0.299233 51.9153 0.18482C52.0479 0.0616067 52.2115 0 52.4061 0Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M59.9601 2.52148C60.4288 2.52148 60.8489 2.60949 61.2203 2.7855C61.6005 2.96152 61.9233 3.22995 62.1886 3.59079H62.2549L62.4141 2.65349H63.3427V9.84828C63.3427 10.5171 63.2277 11.076 62.9978 11.5249C62.7678 11.9825 62.4185 12.3257 61.9498 12.5546C61.4812 12.7834 60.8842 12.8978 60.1591 12.8978C59.6462 12.8978 59.1731 12.8582 58.7398 12.779C58.3153 12.7086 57.935 12.5986 57.599 12.449V11.3796C57.8289 11.4941 58.0809 11.5909 58.3551 11.6701C58.6292 11.7581 58.9255 11.8241 59.2438 11.8681C59.5622 11.9121 59.8894 11.9341 60.2254 11.9341C60.8356 11.9341 61.3131 11.7537 61.658 11.3928C62.0117 11.0408 62.1886 10.5568 62.1886 9.94069V9.66346C62.1886 9.55784 62.193 9.40823 62.2019 9.21461C62.2107 9.01218 62.2196 8.87137 62.2284 8.79216H62.1753C61.9277 9.153 61.6182 9.42143 61.2468 9.59745C60.8842 9.77347 60.4598 9.86148 59.9734 9.86148C59.0537 9.86148 58.333 9.54024 57.8112 8.89777C57.2983 8.2553 57.0419 7.3576 57.0419 6.20468C57.0419 5.44779 57.1568 4.79652 57.3867 4.25086C57.6255 3.6964 57.9616 3.26956 58.3949 2.97032C58.8282 2.67109 59.3499 2.52148 59.9601 2.52148ZM60.1193 3.49838C59.7214 3.49838 59.3809 3.60399 59.0979 3.81522C58.8238 4.02644 58.6115 4.33447 58.4612 4.73932C58.3197 5.14416 58.249 5.63702 58.249 6.21788C58.249 7.08917 58.4081 7.75805 58.7265 8.2245C59.0537 8.68215 59.5268 8.91097 60.1458 8.91097C60.5084 8.91097 60.8179 8.86697 61.0744 8.77896C61.3308 8.68215 61.5431 8.53693 61.7111 8.34331C61.8791 8.14089 62.0029 7.88566 62.0825 7.57763C62.1621 7.26959 62.2019 6.90435 62.2019 6.48191V6.20468C62.2019 5.56221 62.1267 5.04295 61.9764 4.64691C61.8349 4.25086 61.6094 3.96043 61.2999 3.77561C60.9904 3.59079 60.5968 3.49838 60.1193 3.49838Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M73.993 6.17827C73.993 6.76794 73.9134 7.2916 73.7542 7.74925C73.6039 8.1981 73.3828 8.58094 73.091 8.89777C72.808 9.21461 72.4587 9.45663 72.0431 9.62385C71.6363 9.78227 71.1808 9.86148 70.6768 9.86148C70.2081 9.86148 69.7748 9.78227 69.3768 9.62385C68.9789 9.45663 68.634 9.21461 68.3422 8.89777C68.0503 8.58094 67.8204 8.1981 67.6524 7.74925C67.4932 7.2916 67.4136 6.76794 67.4136 6.17827C67.4136 5.39499 67.5463 4.73492 67.8116 4.19806C68.0769 3.6524 68.4571 3.23875 68.9524 2.95712C69.4476 2.66669 70.0356 2.52148 70.7166 2.52148C71.3621 2.52148 71.9281 2.66669 72.4145 2.95712C72.9097 3.23875 73.2944 3.6524 73.5685 4.19806C73.8515 4.73492 73.993 5.39499 73.993 6.17827ZM68.6207 6.17827C68.6207 6.73274 68.6915 7.21679 68.833 7.63043C68.9833 8.03528 69.2132 8.34771 69.5227 8.56774C69.8323 8.78776 70.2258 8.89777 70.7033 8.89777C71.1808 8.89777 71.5744 8.78776 71.8839 8.56774C72.1934 8.34771 72.4189 8.03528 72.5604 7.63043C72.7107 7.21679 72.7859 6.73274 72.7859 6.17827C72.7859 5.61501 72.7107 5.13536 72.5604 4.73932C72.4101 4.34327 72.1801 4.03964 71.8706 3.82842C71.5699 3.60839 71.1764 3.49838 70.69 3.49838C69.9649 3.49838 69.4387 3.73601 69.1115 4.21126C68.7843 4.68651 68.6207 5.34218 68.6207 6.17827Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M16.5181 30.4931V13.3503H21.7694L24.9226 25.0439L28.0402 13.3503H33.3035V30.4931H30.0436V16.9987L26.594 30.4931H23.2156L19.7779 16.9987V30.4931H16.5181Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M39.4749 21.8632L36.4521 21.3253C36.7919 20.1248 37.3767 19.2361 38.2065 18.6592C39.0363 18.0823 40.2691 17.7939 41.905 17.7939C43.3907 17.7939 44.4971 17.9693 45.2242 18.3201C45.9512 18.6631 46.4609 19.1036 46.7533 19.6415C47.0536 20.1716 47.2038 21.1499 47.2038 22.5765L47.1682 26.412C47.1682 27.5034 47.2196 28.3103 47.3223 28.8326C47.433 29.3471 47.6345 29.9006 47.9269 30.4931H44.6315C44.5445 30.2748 44.4378 29.9513 44.3114 29.5225C44.2561 29.3276 44.2166 29.199 44.1929 29.1366C43.6239 29.6823 43.0153 30.0916 42.3673 30.3644C41.7193 30.6373 41.0278 30.7737 40.2929 30.7737C38.9968 30.7737 37.9734 30.4268 37.2226 29.733C36.4798 29.0392 36.1084 28.1621 36.1084 27.1019C36.1084 26.4003 36.2783 25.7767 36.6181 25.231C36.9579 24.6775 37.4321 24.2565 38.0406 23.9681C38.657 23.6718 39.5421 23.4146 40.6959 23.1963C42.2527 22.9079 43.3315 22.6389 43.9321 22.3894V22.062C43.9321 21.4306 43.774 20.9823 43.4579 20.7173C43.1418 20.4444 42.5451 20.308 41.6679 20.308C41.0752 20.308 40.6129 20.4249 40.281 20.6588C39.9491 20.8849 39.6804 21.2863 39.4749 21.8632ZM43.9321 24.5294C43.5053 24.6697 42.8296 24.8373 41.905 25.0322C40.9804 25.2271 40.3758 25.4181 40.0913 25.6052C39.6567 25.9092 39.4394 26.2951 39.4394 26.7628C39.4394 27.2228 39.6132 27.6203 39.9609 27.9556C40.3087 28.2908 40.7512 28.4584 41.2886 28.4584C41.8892 28.4584 42.4622 28.2635 43.0074 27.8737C43.4105 27.5775 43.6752 27.215 43.8017 26.7862C43.8886 26.5056 43.9321 25.9716 43.9321 25.1842V24.5294Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M62.171 30.4931H58.84V24.1552C58.84 22.8143 58.7689 21.949 58.6266 21.5592C58.4844 21.1616 58.2512 20.8537 57.9272 20.6354C57.6111 20.4171 57.2278 20.308 56.7774 20.308C56.2005 20.308 55.6828 20.4639 55.2245 20.7757C54.7661 21.0876 54.45 21.5007 54.2762 22.0152C54.1102 22.5298 54.0272 23.4808 54.0272 24.8685V30.4931H50.6962V18.0745H53.7901V19.8987C54.8886 18.4955 56.2716 17.7939 57.9391 17.7939C58.674 17.7939 59.3458 17.9264 59.9543 18.1915C60.5628 18.4487 61.0211 18.78 61.3293 19.1854C61.6455 19.5908 61.8628 20.0507 61.9813 20.5652C62.1078 21.0798 62.171 21.8165 62.171 22.7753V30.4931Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M77.3278 30.4931H74.2339V28.6689C73.7203 29.3783 73.1117 29.9084 72.4084 30.2592C71.713 30.6022 71.0096 30.7737 70.2984 30.7737C68.8522 30.7737 67.6114 30.2007 66.5762 29.0548C65.5488 27.901 65.0352 26.2951 65.0352 24.237C65.0352 22.1322 65.537 20.5341 66.5406 19.4427C67.5443 18.3435 68.8127 17.7939 70.3458 17.7939C71.7525 17.7939 72.9695 18.3708 73.9968 19.5245V13.3503H77.3278V30.4931ZM68.4373 24.0148C68.4373 25.3401 68.623 26.299 68.9944 26.8914C69.5318 27.749 70.2826 28.1777 71.2467 28.1777C72.0133 28.1777 72.6652 27.8581 73.2026 27.2189C73.74 26.5718 74.0087 25.6091 74.0087 24.3306C74.0087 22.904 73.7479 21.8788 73.2263 21.2552C72.7048 20.6237 72.037 20.308 71.223 20.308C70.4327 20.308 69.7689 20.6198 69.2315 21.2435C68.702 21.8593 68.4373 22.7831 68.4373 24.0148Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M80.844 16.3907V13.3503H84.175V16.3907H80.844ZM80.844 30.4931V18.0745H84.175V30.4931H80.844Z"
                                    fill="#0B0B0B" />
                                <path
                                    d="M11.5401 21.8275C11.5401 22.1802 11.4327 22.5887 11.2178 23.0531C11.0029 23.5089 10.6075 23.9949 10.0315 24.5109C9.46421 25.0269 8.64759 25.4827 7.58168 25.8783C6.52437 26.274 5.19199 26.5449 3.58454 26.6911L3.27508 26.7169C3.20631 27.0609 3.17193 27.3791 3.17193 27.6715C3.17193 28.308 3.32236 28.7681 3.62322 29.0519C3.93268 29.3271 4.37967 29.4647 4.9642 29.4647C6.2536 29.4647 7.48713 28.7681 8.66478 27.3748H10.0315C9.49859 28.1919 8.69057 29.0003 7.60747 29.8001C6.52437 30.6 5.23927 30.9999 3.75216 30.9999C2.72064 30.9999 1.83525 30.7247 1.09599 30.1743C0.36533 29.6152 0 28.7982 0 27.7231C0 26.4073 0.36533 25.1301 1.09599 23.8916C1.82665 22.6446 2.82379 21.634 4.0874 20.86C5.35102 20.0774 6.72638 19.686 8.21349 19.686C9.40833 19.686 10.2593 19.8881 10.7665 20.2924C11.2823 20.6966 11.5401 21.2083 11.5401 21.8275ZM8.81951 21.7759C8.81951 21.4921 8.72065 21.2341 8.52295 21.0019C8.32524 20.7611 8.05446 20.6407 7.71062 20.6407C7.1089 20.6407 6.55446 20.8514 6.04729 21.2728C5.54873 21.6856 5.12752 22.1759 4.78368 22.7435C4.43984 23.3111 4.15617 23.8873 3.93268 24.4722C3.71777 25.057 3.58454 25.4182 3.53296 25.5558C5.27795 25.2634 6.59314 24.7689 7.47853 24.0723C8.37251 23.3756 8.81951 22.6102 8.81951 21.7759Z"
                                    fill="#04B23D" />
                            </svg>
                            <p>Join the <span>revolution</span></p>
                            <p>Join the <span>Origo</span> Community</p>
                            <img src={people} alt="" />
                        </div>

                        <div class="loginRight chatRight">
                            <div>
                                <svg width="14" height="13" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.499999 4.99993C0.503649 4.60535 0.662633 4.2281 0.942499 3.94993L4.16 0.724931C4.30052 0.585242 4.49061 0.506836 4.68875 0.506836C4.88689 0.506836 5.07698 0.585242 5.2175 0.724931C5.2878 0.794653 5.34359 0.877604 5.38167 0.968998C5.41974 1.06039 5.43935 1.15842 5.43935 1.25743C5.43935 1.35644 5.41974 1.45447 5.38167 1.54586C5.34359 1.63726 5.2878 1.72021 5.2175 1.78993L2.75 4.24993H10.25C10.4489 4.24993 10.6397 4.32895 10.7803 4.4696C10.921 4.61025 11 4.80102 11 4.99993C11 5.19884 10.921 5.38961 10.7803 5.53026C10.6397 5.67091 10.4489 5.74993 10.25 5.74993H2.75L5.2175 8.21743C5.35873 8.35767 5.43846 8.54826 5.43917 8.74728C5.43987 8.9463 5.36148 9.13745 5.22125 9.27868C5.08102 9.41991 4.89042 9.49965 4.6914 9.50035C4.49238 9.50105 4.30123 9.42266 4.16 9.28243L0.942499 6.05743C0.660809 5.77742 0.501674 5.39711 0.499999 4.99993Z"
                                        fill="#545050" />
                                </svg>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.8045 0.195191C15.6795 0.0702103 15.51 0 15.3332 0C15.1564 0 14.9869 0.0702103 14.8619 0.195191L7.99986 7.05719L1.13786 0.195191C1.01284 0.0702103 0.8433 0 0.666524 0C0.489748 0 0.320209 0.0702103 0.195191 0.195191C0.0702103 0.320209 0 0.489748 0 0.666524C0 0.8433 0.0702103 1.01284 0.195191 1.13786L7.05719 7.99986L0.195191 14.8619C0.0702103 14.9869 0 15.1564 0 15.3332C0 15.51 0.0702103 15.6795 0.195191 15.8045C0.320209 15.9295 0.489748 15.9997 0.666524 15.9997C0.8433 15.9997 1.01284 15.9295 1.13786 15.8045L7.99986 8.94252L14.8619 15.8045C14.9869 15.9295 15.1564 15.9997 15.3332 15.9997C15.51 15.9997 15.6795 15.9295 15.8045 15.8045C15.9295 15.6795 15.9997 15.51 15.9997 15.3332C15.9997 15.1564 15.9295 14.9869 15.8045 14.8619L8.94252 7.99986L15.8045 1.13786C15.9295 1.01284 15.9997 0.8433 15.9997 0.666524C15.9997 0.489748 15.9295 0.320209 15.8045 0.195191Z"
                                        fill="#757575" />
                                </svg>
                            </div>
                            <div class="chatContent">
                                <p>Call Us here:</p>
                                <div onClick={() => { navigator.clipboard.writeText('+91 8338047777') }} onPointerDown={() => { navigator.clipboard.writeText('+91 8338047777') }}>
                                    <span>+91 8338047777</span>
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.66665 13.3333C8.55038 13.3323 9.39761 12.9808 10.0225 12.3559C10.6474 11.731 10.9989 10.8837 11 10V4.16201C11.001 3.81158 10.9325 3.46444 10.7984 3.14069C10.6642 2.81694 10.4672 2.52304 10.2186 2.27601L8.72398 0.781345C8.47695 0.532794 8.18305 0.335747 7.8593 0.201625C7.53555 0.0675032 7.18841 -0.0010253 6.83798 1.15931e-05H3.66665C2.78292 0.00107016 1.93568 0.3526 1.31079 0.977491C0.685901 1.60238 0.334372 2.44961 0.333313 3.33334V10C0.334372 10.8837 0.685901 11.731 1.31079 12.3559C1.93568 12.9808 2.78292 13.3323 3.66665 13.3333H7.66665ZM1.66665 10V3.33334C1.66665 2.80291 1.87736 2.2942 2.25243 1.91913C2.62751 1.54406 3.13621 1.33334 3.66665 1.33334C3.66665 1.33334 6.94598 1.34268 6.99998 1.34934V2.66668C6.99998 3.0203 7.14046 3.35944 7.3905 3.60949C7.64055 3.85954 7.97969 4.00001 8.33331 4.00001H9.65065C9.65731 4.05401 9.66665 10 9.66665 10C9.66665 10.5304 9.45593 11.0392 9.08086 11.4142C8.70579 11.7893 8.19708 12 7.66665 12H3.66665C3.13621 12 2.62751 11.7893 2.25243 11.4142C1.87736 11.0392 1.66665 10.5304 1.66665 10ZM13.6666 5.33334V12.6667C13.6656 13.5504 13.3141 14.3976 12.6892 15.0225C12.0643 15.6474 11.217 15.999 10.3333 16H4.33331C4.1565 16 3.98693 15.9298 3.86191 15.8047C3.73688 15.6797 3.66665 15.5102 3.66665 15.3333C3.66665 15.1565 3.73688 14.987 3.86191 14.8619C3.98693 14.7369 4.1565 14.6667 4.33331 14.6667H10.3333C10.8637 14.6667 11.3725 14.456 11.7475 14.0809C12.1226 13.7058 12.3333 13.1971 12.3333 12.6667V5.33334C12.3333 5.15653 12.4035 4.98696 12.5286 4.86194C12.6536 4.73692 12.8232 4.66668 13 4.66668C13.1768 4.66668 13.3464 4.73692 13.4714 4.86194C13.5964 4.98696 13.6666 5.15653 13.6666 5.33334Z"
                                            fill="#757575" />
                                    </svg>
                                </div>
                                <div>
                                    <span>Or Scan:</span>
                                    <img src={scanner} style={{ cursor: "pointer" }} onClick="window.open('https://wa.me/message/MAJZQZV5VM7DD1?src=qr')" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navbar />
                {isLoading && <div id="loader-container">
                    <div id="spinner"></div>
                </div>}
                <Scrollbars autoHide={false} style={{ width: "100%", height: "93vh" }} className="scrollbars-wrapper">
                    <div className="container eCashPostReg">
                        <Popup open={show75ApprovedPopup} className="upload-drag-popup" closeOnDocumentClick={false}>
                            <div className='modal-header upload-drag-header'>
                                <div className='cross-header'>
                                    <img src={crossInput} onClick={()=>{setShow75ApprovedPopup(false)}} style={{cursor:"pointer"}}></img>
                                </div>
                                <div className='upload-drag-heading'><span>CONGRATULATIONS!</span></div>
                            </div>
                            <div className="modal-content upload-drag-content">
                                <span className='approvedText75'>Congratulations, You have been sanctioned a Loan Limit of 75 lac .</span>
                                <button className='saveFileBtn' onClick={()=>{setShow75ApprovedPopup(false)}}>Ok, Great</button>
                            </div>
                        </Popup>
                        <Popup open={showContinueWithMobilePopup} className="upload-drag-popup" closeOnDocumentClick={false}>
                            <div className='modal-header upload-drag-header'>
                                <div className='cross-header'>
                                    <img src={crossInput} onClick={()=>{setShow75ApprovedPopup(false)}} style={{cursor:"pointer"}}></img>
                                </div>
                                <div className='upload-drag-heading'><span>ALERT!</span></div>
                            </div>
                            <div className="modal-content upload-drag-content">
                                <span className='approvedText75'>{showContinueWithMobileMsg}</span>
                                <button className='saveFileBtn' onClick={()=>{setShowContinueWithMobilePopup(false); history.push("/postreg")}}>Okay</button>
                            </div>
                        </Popup>
                        <div className='row'>
                            <div className='col-md-6' style={{padding:"0px"}}>
                                <ECashHome />
                            </div>
                             <div className='col-md-6 ecash-right-container'>
                                {details_state >1 && details_state!==5  && details_state!==8 && details_state!==9 && details_state!==10 && details_state!==11? <div className="BackContainer" onClick={handleBackClick}>
                                    <img src={backArrowEResearch} className='backArrowBtn'></img>
                                    <span className='back-text'>Back</span>
                                </div> : ""}
                                <div className='container eCash-right-section'>
                                    <div className='rightInnerSection'>
                                        {details_state!=5 && details_state!=6 && details_state!=8 && details_state!==9 && details_state!==10 && details_state!==11?<div className='upprFixedDiv'>
                                                <div className={`profilePic-welcome-support ${details_state>=3 ? "profilePic-welcome-support-form3Page" : ""}`}>
                                                    <img src={ProfilePic} className='ecashProfilePic'/>
                                                    {details_state<3 ? <div className='welcome-text-div'>
                                                        <div className='welcome'> Welcome!</div>
                                                        <div className='support'>Support your business with <span class='ecash-text'> eCash </span></div>
                                                    </div>:""}
                                                    {details_state==3 ?<div className='welcome-text-div-from3Page'>
                                                        <div className='almostHere'>You are almost here</div>
                                                        <div className='support'>Support your business with<span class='ecash-text'> eCash </span></div>
                                                    </div>:""}
                                                    {details_state==4 || details_state==7 ?<div className='welcome-text-div-from3Page'>
                                                        <div className='almostHere'>You are a few steps away!</div>
                                                        <div className='support'>Upload these documents to unlock your limit</div>
                                                    </div>:""}
                                                </div>
                                            </div>
                                        :""}
                                        <div className='panUpload-div'>
                                            {details_state<=3 ? 
                                                <div className='upload-progress-bar'>
                                                     <div className='stage'>
                                                         <span className='stageNumber'>1</span>
                                                         <span className='stageText' style={details_state >=3 ? {color: "#04B23D"} :{ color: "#757575"}}>Enter details</span>
                                                     </div>
                                                     <img className='right-angle-green' src={rightAngleGreen}></img>
                                                     <div className='stage'>
                                                         <span className='stageNumber' style={details_state < 3 ? {background: "#757575"} :{ background: "#04B23D"}}>2</span>
                                                         <span className='stageText'>Upload Documents</span>
                                                     </div>
                                                 </div>
                                                :""
                                            }
                                            {details_state=== 1 ? 
                                                <div className='pan-deatils-checkbox-flex'>
                                                <div className='deatils-section'>
                                                    <span className='DetailsHeading'>Entity Details<span>*</span></span>
                                                    <input 
                                                        type='text' 
                                                        placeholder='Enter Business PAN' 
                                                        className={`ecashDetailsInput ${userPersonalDetails?.accountType==="ORGANIZATION_TRADER" ? "disabledBg" : ""}`}
                                                        value={ecashPANValue}
                                                        onChange={handlePanNumber}
                                                        maxLength={10}
                                                        disabled={userPersonalDetails?.accountType==="ORGANIZATION_TRADER"}
                                                    ></input>
                                                    <Popup open={showEcashPanNoMatchPopup} className="aadhar-consent-popup" closeOnDocumentClick={false}>
                                                        <div className='modal-header aadhar-consent-header'>
                                                            <div className='cross-header'>
                                                                <img src={crossInput} onClick={handleEcashPanNoMatchPopupCross} style={{cursor:"pointer"}}></img>
                                                            </div>
                                                            <div className='aadhar-consent-Heading'><span>PAN Mismatched</span></div>
                                                        </div>
                                                        <div className="modal-content ecashPanMismatched-consent-modal-content">
                                                            <span className='aadhar-consent-para2'>PAN details provided in profile do not match with the PAN provided here. Please provide the pan mentioned in profile section or enter your organisational PAN</span>
                                                        </div>
                                                    </Popup>
                                                    <span className="eCashPanNumberError" style={{color:"red"}}>Please Enter Valid Pan Number</span>
                                                    <span className='firmNameLoadingDiv'>
                                                        <div className='firmName-div'>
                                                            <img src = {HomeVector} />
                                                            <span className={`firmNameEcash ${(ecashName==='' || ecashName === null)? "":"greenFetchedFirmName"}`}>{(ecashName==='' || ecashName === null) ? "Firm Name" : ecashName}</span>
                                                        </div>
                                                        {showFirmNameRolling? <img src={greenRolling} className='rollingImage'></img> :""}
                                                        {showFirmNameTick ? <img src={greenTick}></img> : ""}
                                                    </span>
                                                </div>
                                                <div className='ecash-checkbox-text-container'>
                                                    <input
                                                        id="ecashT&Ccheckbox"
                                                        type="checkbox"
                                                        className='checkBox'
                                                        checked={isCheckedEcashTC}
                                                        onChange={(e)=>{handleEcashCheckboxChange(e)}}
                                                    />
                                                    <span className='agree-ecash-TC-text'>By continue, I agree to eCash <span className='TC_text' onClick={handleShowEcashTC}>T&C</span></span>
                                                </div>
                                                <Popup open={showEcashTCPopup} className="aadhar-consent-popup" closeOnDocumentClick={false}>
                                                    <div className='modal-header aadhar-consent-header'>
                                                        <div className='cross-header'>
                                                            <img src={crossInput} onClick={handleEcashTCPopupCross} style={{cursor:"pointer"}}></img>
                                                        </div>
                                                        <div className='aadhar-consent-Heading'><span>Ecash T&C</span></div>
                                                    </div>
                                                    <div className="modal-content aadhar-consent-modal-content">
                                                        <span className='aadhar-consent-para1'>Upon clicking on this link and sharing the Aadhaar number, You (the Aadhaar holder), hereby irrevocably:</span>
                                                        <span className='aadhar-consent-para2'>1. Place a request to Origo Commodities India Private Limited or any of its Group Companies,
                                                                Associates, Subsidiaries or Holding Companies to access Your Aadhaar Information from
                                                                UIDAI to fetch and verify information regarding Aadhaar Number, Aadhaar XML, Virtual ID, e-Aadhaar,
                                                                Masked Aadhaar, Aadhaar details, demographic information, identity information, Aadhaar registered 
                                                                mobile number, face authentication details and/or biometric information (collectively referred to as 
                                                                “Aadhaar Information”).
                                                                <br></br>
                                                                2. Explicitly authorise Origo Commodities India Private Limited or any of its Group Companies, 
                                                                Associates, Subsidiaries or Holding Companies to fetch Your Aadhaar Information from UIDAI 
                                                                to make it available to Origo Commodities India Private Limited or any of its Group Companies, 
                                                                Associates, Subsidiaries or Holding Companies or any third party, as may be required for the purpose 
                                                                of credit appraisal/sharing for any other purpose.
                                                                <br></br>
                                                                3. Agree to take all the necessary actions required for the purpose of authenticating and verifying Your Aadhaar Information.
                                                                <br></br>
                                                                4. Give a valid, binding, irrevocable and explicit authorisation and consent as may be required
                                                                under applicable laws, rules, regulations and guidelines for availing the Aadhaar API services including, but not limited to the transmission and storage of Your Aadhaar Information by Origo Commodities India Private Limited or any of its Group Companies,
                                                                Associates, Subsidiaries or Holding Companies.
                                                                <br></br>
                                                                5. Understand and agree that the Consent has been submitted by You voluntarily and without
                                                                any coercion, misrepresentation from Origo Commodities India Private Limited or any of
                                                                its Group Companies, Associates, Subsidiaries or Holding Companies or any other party.
                                                                <br></br>
                                                                6. Fully understand and accept sole and complete responsibility for any issues, legal suits, damages, 
                                                                losses, penalties, 
                                                                fines or liabilities (“Losses”) arising out of Your sharing of Aadhaar Information and 
                                                                authorising Origo Commodities India Private Limited or any of its Group Companies, Associates, 
                                                                Subsidiaries or Holding Companies for fetching your Aadhar Information and that You will fully hold 
                                                                harmless Origo Commodities India Private Limited or any of its Group Companies, Associates, Subsidiaries 
                                                                or Holding Companies, its representatives, employees and directors for any Losses arising out of such request
                                                                and actions.
                                                            </span>
                                                    </div>
                                                    <div className='whiteShadow'></div>
                                                </Popup>
                                                </div>  
                                            :""}
                                            {details_state=== 2 ?  
                                                <div className='deatils-section'>
                                                    <span className='DetailsHeading'>Entity Details<span>*</span></span>
                                                    <input
                                                        type='text' 
                                                        placeholder='Enter GST No.' 
                                                        className={`ecashDetailsInput`}
                                                        value={ecashGSTValue}
                                                        onChange={handleGSTNumber}
                                                        maxLength={15}
                                                    ></input>
                                                    <Popup open={showEcashGstVintageLess} className="AreUsure-gstContinue-popup" closeOnDocumentClick={false}>
                                                        <div className='modal-header aadhar-consent-header'>
                                                            <div className='cross-header'>
                                                                <img src={crossInput} onClick={()=>{setShowEcashGstVintageLess(false)}} style={{cursor:"pointer"}}></img>
                                                            </div>
                                                            <div className='aadhar-consent-Heading'><span>Vintage is Less</span></div>
                                                        </div>
                                                        <div className="modal-content AreUsure-gstContinue-modal-content">
                                                            <span className='aadhar-consent-para2'>Your organization vintage is less, please try anyother oragniztion.</span>
                                                            <div className='okBtnPopupDiv'><button onClick={()=>{setShowEcashGstVintageLess(false)}}>Ok</button></div>
                                                        </div>
                                                    </Popup>
                                                    <Popup open={showEcashGstContinueConfirmation} className="AreUsure-gstContinue-popup" closeOnDocumentClick={false}>
                                                        <div className='modal-header aadhar-consent-header'>
                                                            <div className='cross-header'>
                                                                <img src={crossInput} onClick={()=>{setShowEcashGstContinueConfirmation(false)}} style={{cursor:"pointer"}}></img>
                                                            </div>
                                                            <div className='aadhar-consent-Heading'><span>Are you sure?</span></div>
                                                        </div>
                                                        <div className="modal-content AreUsure-gstContinue-modal-content">
                                                            <span className='aadhar-consent-para2'>Are you sure , you want to continue with this pan and gst ?</span>
                                                            <div className='okBtnPopupDiv'>
                                                                <button onClick={()=>{setShowEcashGstContinueConfirmation(false)}} className='cancelBtn'>Cancel</button>
                                                                <button onClick={handleEcashGSTContinue}>Ok</button>
                                                            </div>
                                                        </div>
                                                    </Popup>
                                                    <span className="eCashGSTNumberError" style={{color:"red"}}>Please Enter Valid GST Number</span>
                                                    {ecashGSTValue ?
                                                        <div className='IsMSMERegistered_div'>
                                                        <span className='isRegisteredAsMSME_Span'>Are you registered as an MSME</span>
                                                        <div className={`radio-group isMSMEregistered`}>
                                                            <input 
                                                                type="radio" 
                                                                id="radio1"
                                                                name="MSMEradio" 
                                                                value="yes" 
                                                                checked={isRegisteredMSME === true} 
                                                                onClick={()=>{
                                                                    setIsRegisteredMSME(true)
                                                                }} 
                                                            />
                                                            <label for="radio1">Yes</label>
                                                            <input 
                                                                type="radio" 
                                                                id="radio2"
                                                                name="MSMEradio" 
                                                                value="no" 
                                                                checked={isRegisteredMSME === false} 
                                                                onClick={()=>{
                                                                    setIsRegisteredMSME(false)
                                                                }} 
                                                            />
                                                            <label for="radio2">No</label>
                                                        </div>
                                                        </div>
                                                    :
                                                    <span className='dontHaveGstText' onClick={handleEcashNonGSTClick}>I don’t have a GST number</span>
                                                    }
                                                </div>
                                            :""}
                                            {(details_state === 3)?
                                                <div className='documents-partnership-withgst'>
                                                    <div className='selfAttested-percent-div'>
                                                        <span className='self-attested-text'>*Documents must be self attested</span>
                                                        <div className='percent-bar-text-div' style={{display:"flex"}}>
                                                            <img></img>
                                                            <span className='percentage-text'>
                                                                <CircularPercentageLoader percentage={percentage} />
                                                                <span>{percentage <=100 ? percentage : 100}% Completed</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {showSaveBeforeAlert ? 
                                                        <div className='save-before-back-alert'>
                                                            <img src={alertTriangle}></img>
                                                            <span>Please save before proceeding to next.</span>
                                                        </div>
                                                        :""
                                                    }
                                                    
                                                    {isRegisteredMSME || (ecashGSTValue==="" || ecashGSTValue===null)? 
                                                        <div className='document-container'>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,0)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>MSME/Udyam Certificate</span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    {msmeDocuments.length>0 ? 
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{msmeDocuments.length}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                <div className='documentinput-div'>
                                                                    <input 
                                                                        className='document-input' 
                                                                        placeholder='Enter Udyog No.'
                                                                        value={ecashMsmeUdyog}
                                                                        onChange={(e)=>{
                                                                            handleMsmeChange(e.target.value);
                                                                        }}
                                                                        maxLength={12}
                                                                    ></input>
                                                                    {(ecashGSTValue==="" || ecashGSTValue===null )&&
                                                                        <input 
                                                                            className='document-input' 
                                                                            placeholder='Enter Business Address'
                                                                            value={ecashBusinessAddress}
                                                                            onChange={(e)=>{
                                                                                setEcashBusinessAddress(e.target.value);
                                                                            }}
                                                                        ></input>}
                                                                    {(ecashGSTValue==="" || ecashGSTValue===null )&&
                                                                        <input 
                                                                            className='document-input' 
                                                                            placeholder='Enter Pin Code'
                                                                            value={ecashBusinessPincode}
                                                                            onChange={(e)=>{
                                                                                handleMsmePinCodeChange(e.target.value);
                                                                            }}
                                                                            maxLength={6}
                                                                        ></input>}
                                                                </div>
                                                                <Popup open={uploadDocumentPopup} className="upload-drag-popup" closeOnDocumentClick={false}>
                                                                    <div className='modal-header upload-drag-header'>
                                                                        <div className='cross-header'>
                                                                            <img src={crossInput} onClick={()=>{setUploadDocumentPopup(false); setCurrentFile(null);}} style={{cursor:"pointer"}}></img>
                                                                        </div>
                                                                        <div className='upload-drag-heading'><span>Upload Files</span></div>
                                                                    </div>
                                                                    <div className="modal-content upload-drag-content">
                                                                        <div className='dotted-div' id="drag-drop-area" 
                                                                            onDrop={(e) => handleDrop(e)}
                                                                            onDragOver={(e) => handleDragOver(e)}
                                                                        >
                                                                            <img src={bigDocImg}></img>
                                                                            <span className="dragdrop-text">Drag & Drop</span>
                                                                            <span className="browse-text">Your files here Or <span style={{color:"#04B23D" , cursor:"pointer"}} onClick={openFileInput}>Browse</span> to upload</span>
                                                                            <span>{currentFile?.name}</span>
                                                                            <span className="size-text">Only JPEG, PNG, GIF and PDF files with max size of 15MB</span>
                                                                            <input
                                                                                type="file"
                                                                                multiple
                                                                                style={{ display: 'none' }}
                                                                                ref={fileInputRef}
                                                                                onChange={(e) => handleFileInput(e)}
                                                                            />
                                                                        </div>
                                                                        <button className='saveFileBtn' onClick={handleSaveFile}>Save File</button>
                                                                    </div>
                                                                </Popup>
                                                                {showMsmeUpload ? 
                                                                    <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                        {msmeDocuments.length>0 ? 
                                                                            <div className='upload-Names-div'>
                                                                                {msmeDocuments?.map((doc, index)=>{
                                                                                    return <div className='documentname-cross-div'><img src={documentIcon} style={{marginRight: "1rem"}}></img> {doc.name} <img src={cross} style={{width: "9px" , cursor: "pointer", marginLeft: "1rem" }} onClick={() => {handleRemoveDocument('msme' ,index)}}></img></div>
                                                                                }
                                                                                )}
                                                                            </div>:""
                                                                        }
                                                                        <div className="upload-addMore-Btn-div">
                                                                            <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor('msme')}} disabled={msmeDocuments.length>0}><img src={uploadArrowTransparentBG}></img>{msmeDocuments.length>0?"Add":"Upload"}</button>
                                                                        </div>
                                                                    </div>
                                                                    :""
                                                                }
                                                            </div>
                                                        </div>:""
                                                    }
                                                    {organization_type!="Proprietorship" ?
                                                        <div className='document-container'>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,1)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>PAN Card of all {organization_type==="Partnership" ? "Partners" :(organization_type==="LLP" || organization_type==="Company" ? "Signatories" : "")} <span style={{color: "#04B23D"}}>*</span></span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    {partnersCompletedCount>0 ? 
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{partnersCompletedCount}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                {partners.map((partner, partnerIdx) => (
                                                                    <div className='eacsh-partnerWisePanDocumentDiv' id={`partnerParentDiv${partner.id}`}>
                                                                        <span className='PartnerNo'><span>{organization_type==="Partnership" ? "Partner" :(organization_type==="LLP" || organization_type==="Company" ? "Signatory" : "")}-{partner.id}</span><img src={cross} style={{width:"11px" , cursor:"pointer"}} onClick={()=>{removePartner(partnerIdx)}}></img></span>
                                                                        <div className='documentinput-div'>
                                                                            <input 
                                                                            className='document-input' 
                                                                            placeholder='Enter PAN No.'
                                                                            value={partner.pan}
                                                                            onChange={(e) => handlePanChange(partner.id, e.target.value.toUpperCase())}
                                                                            ></input>
                                                                        </div>
                                                                        {partner.verified===true?
                                                                            <div className='upload-Names-btn-div'>
                                                                                <div className='upload-Names-div'>
                                                                                    {partner.documents?.map((doc,index)=>{
                                                                                    return <div className='documentname-cross-div'><img src={documentIcon} style={{marginRight: "1rem"}}></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer", marginLeft: "1rem" }} onClick={() => {handleRemoveDocument(`partner${partner.id}` ,index)}}></img></div>
                                                                                    })}
                                                                                </div>
                                                                                <div className="upload-addMore-Btn-div">
                                                                                    <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`partner${partner.id}`)}}><img src={uploadArrowTransparentBG}></img> {partner.documents.length>0?"Add":"Upload"}</button>
                                                                                </div>
                                                                            </div>
                                                                            :""
                                                                        }
                                                                    </div>
                                                                ))}
                                                                <div className="addMorePartners-div" onClick={addPartner}><span className='addMorePartners-text'>ADD MORE {organization_type==="Partnership" ? "Partners" :(organization_type==="LLP" || organization_type==="Company" ? "Signatories" : "")}</span></div>
                                                            </div>
                                                        </div>
                                                    :""}
                                                    <div className='document-container'>
                                                        <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,2)}}>
                                                            <div className='document-Name-div'>
                                                                <span className='document-name'>Aadhaar/ Passport/Voter ID <span style={{color: "#04B23D"}}>*</span></span>
                                                                <img src={infoIcon} style={{width: "17px"}}></img>
                                                            </div>
                                                            <div className='greenUpload-docNumber-div'>
                                                                {partnersIdTypeCompletedCount>0 ?
                                                                    <div className='documentsNumberDiv'>
                                                                        <img src={pdf}></img>
                                                                        <span>+{partnersIdTypeCompletedCount}</span>
                                                                    </div>: 
                                                                    <img src={greenUpload} className='greenUploadImg'></img>
                                                                }
                                                            </div>
                                                        </div>
                                                        {console.log(partnersIdType)}
                                                        <div className='openDocument-Div'>
                                                            {partnersIdType.map((partnerIdType, idx)=>{
                                                                return <div className='eacsh-partnerWisePanDocumentDiv' id={`partnerIdTypeParentDiv${partnerIdType.id}`}>
                                                                        {organization_type!="Proprietorship" ? 
                                                                            <span className='PartnerNo'>
                                                                                <span>{organization_type==="Partnership" ? "Partner" :(organization_type==="LLP" || organization_type==="Company" ? "Signatory" : "")}-{partnerIdType.id}</span>
                                                                                <img src={cross} style={{width:"11px" , cursor:"pointer"}} onClick={()=>{removePartnerIdType(idx)}}></img>
                                                                            </span>
                                                                        :""}
                                                                    <div className='documentinput-div'>
                                                                        <select className='document-select' type="select" placeholder='Select Document' 
                                                                        onChange={(e) => {
                                                                                handlePartnerIdTypeSelected(partnerIdType.id,e.target.value.toUpperCase());
                                                                        }}
                                                                        value={partnerIdType.type.toLowerCase()}
                                                                        >
                                                                            <option value="" disabled selected>Select Document</option>
                                                                            <option value="aadhar">Aadhar Card</option>
                                                                            <option value="voter_id">Voter ID</option>
                                                                            <option value="passport">Passport</option>
                                                                        </select>
                                                                    </div>
                                                                    {partnerIdType.type === "Aadhar".toUpperCase() ? 
                                                                        <div className='partner-aadhar-details'>
                                                                            <div className='documentinput-div'>
                                                                                <input 
                                                                                    className='document-input' 
                                                                                    placeholder='Name as per the Aadhar'
                                                                                    value={partnerIdType.name!=='' ? partnerIdType.name :aadharName}
                                                                                    onChange={(e)=>{
                                                                                        const pattern = /^[A-Za-z\s]*$/; 
                                                                                        if (pattern.test(e.target.value)) {
                                                                                            setAadharName(e.target.value)}
                                                                                            handleAdharNameChange(e, partnerIdType.id, e.target.value);
                                                                                        }
                                                                                    }
                                                                                ></input>
                                                                            </div>
                                                                            <div className='documentinput-div'>
                                                                                <input 
                                                                                    className='document-input'
                                                                                    placeholder='Enter Aadhar No.'
                                                                                    value={partnerIdType.aadharNo!=='' ? partnerIdType.aadharNo :aadharValue}
                                                                                    onChange={(e)=>{
                                                                                        const regex = /^[0-9]*$/; 
                                                                                        if (regex.test(e.target.value)) {
                                                                                            setAadharValue(e.target.value)
                                                                                            handleAdharNumberChange(e, partnerIdType.id, e.target.value);
                                                                                        }
                                                                                    }}
                                                                                 ></input>
                                                                            </div>
                                                                            {partnerIdType.verified===true? 
                                                                                <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                                    <div className='upload-Names-div'>
                                                                                        {partnerIdType.documents?.map((doc,index)=>{
                                                                                            return <div className='documentname-cross-div'><img src={documentIcon} style={{marginRight: "1rem"}}></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer", marginLeft: "1rem" }} onClick={() => {handleRemoveDocument(`idType${partnerIdType.id}` ,index)}}></img></div>
                                                                                        })}
                                                                                    </div>
                                                                                    <div className="upload-addMore-Btn-div">
                                                                                        <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`idType${partnerIdType.id}`)}}><img src={uploadArrowTransparentBG}></img> {partnerIdType.documents.length>0?"Add":"Upload"}</button>
                                                                                    </div>
                                                                                </div>
                                                                                :""
                                                                            }
                                                                            <Popup open={itemToAddKycOtpPopup} className="selectedKYCItemOtpPopup" closeOnDocumentClick={false}>
                                                                                <div className='modal-header selectedKYCItemOtpPopup-header'>
                                                                                    <div className='cross-header'>
                                                                                        <img src={crossInput} onClick={handleAdharOtpPopupCross} style={{cursor:"pointer"}}></img>
                                                                                    </div>
                                                                                    <div className='heading-header'>
                                                                                        <img src={adharLogo}></img>
                                                                                        <span>Aadhar Verification</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="modal-content selectedKYCItemFields-content">
                                                                                    <div className='selectedItemToAddInputsRows-container'>
                                                                                        <div className='selectedItemToAddInputs-row'>
                                                                                            <span className='form-labels'>Enter OTP</span>
                                                                                            <div className='otpBoxesContainer'>
                                                                                                <input 
                                                                                                placeholder="x" 
                                                                                                type="text" 
                                                                                                pattern="\d*" 
                                                                                                id='ist' 
                                                                                                maxlength="1"
                                                                                                className='adharOtpFields'
                                                                                                onKeyUp={(event)=>{clickEvent(event.target,'sec')}}
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const regex = /^[0-9]*$/; 
                                                                                                    if (regex.test(value)) {
                                                                                                        _onChangeOTP(e, 0);
                                                                                                    } else {
                                                                                                    e.target.value = '';
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => _onKeyDownOTP(e, 0)}
                                                                                                value={aadharOTP.length>0 && aadharOTP[0] || ""}
                                                                                                />
                                                                                                <input 
                                                                                                placeholder="x" 
                                                                                                type="text" 
                                                                                                pattern="\d*" 
                                                                                                id="sec" 
                                                                                                maxlength="1"
                                                                                                className='adharOtpFields'
                                                                                                onKeyUp={(event)=>{clickEvent(event.target,'third')}}
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const regex = /^[0-9]*$/; 
                                                                                                    if (regex.test(value)) {
                                                                                                        _onChangeOTP(e, 1);
                                                                                                    } else {
                                                                                                    e.target.value = '';
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => _onKeyDownOTP(e, 1)}
                                                                                                value={aadharOTP.length>0 && aadharOTP[1] || ""}
                                                                                                />
                                                                                                <input 
                                                                                                placeholder="x" 
                                                                                                type="text" 
                                                                                                pattern="\d*" 
                                                                                                id="third" 
                                                                                                maxlength="1"
                                                                                                className='adharOtpFields'
                                                                                                onKeyUp={(event)=>{clickEvent(event.target,'fourth')}}
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const regex = /^[0-9]*$/; 
                                                                                                    if (regex.test(value)) {
                                                                                                        _onChangeOTP(e, 2);
                                                                                                    } else {
                                                                                                    e.target.value = '';
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => _onKeyDownOTP(e, 2)}
                                                                                                value={aadharOTP.length>0 && aadharOTP[2] || ""}
                                                                                                />
                                                                                                <input 
                                                                                                placeholder="x" 
                                                                                                type="text" 
                                                                                                pattern="\d*" 
                                                                                                id="fourth"
                                                                                                onKeyUp={(event)=>{clickEvent(event.target,'fifth')}} 
                                                                                                maxlength="1"
                                                                                                className='adharOtpFields'
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const regex = /^[0-9]*$/; 
                                                                                                    if (regex.test(value)) {
                                                                                                        _onChangeOTP(e, 3);
                                                                                                    } else {
                                                                                                    e.target.value = '';
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => _onKeyDownOTP(e, 3)}
                                                                                                value={aadharOTP.length>0 && aadharOTP[3] || ""}
                                                                                                />
                                                                                                <input 
                                                                                                placeholder="x" 
                                                                                                type="text" 
                                                                                                pattern="\d*" 
                                                                                                id="fifth"
                                                                                                onKeyUp={(event)=>{clickEvent(event.target,'sixth')}} 
                                                                                                maxlength="1"
                                                                                                className='adharOtpFields'
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const regex = /^[0-9]*$/; 
                                                                                                    if (regex.test(value)) {
                                                                                                        _onChangeOTP(e, 4);
                                                                                                    } else {
                                                                                                    e.target.value = '';
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => _onKeyDownOTP(e, 4)}
                                                                                                value={aadharOTP.length>0 && aadharOTP[4] || ""}
                                                                                                />
                                                                                                <input 
                                                                                                placeholder="x" 
                                                                                                type="text" 
                                                                                                pattern="\d*" 
                                                                                                id="sixth"
                                                                                                onKeyUp={(event)=>{clickEvent(event.target,'seventh')}} 
                                                                                                maxlength="1"
                                                                                                className='adharOtpFields'
                                                                                                onChange={(e) => {
                                                                                                    const value = e.target.value;
                                                                                                    const regex = /^[0-9]*$/; 
                                                                                                    if (regex.test(value)) {
                                                                                                        _onChangeOTP(e, 5);
                                                                                                    } else {
                                                                                                    e.target.value = '';
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => _onKeyDownOTP(e, 5)}
                                                                                                value={aadharOTP.length>0 && aadharOTP[5] || ""}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='selectedItemToAddCounter-row'>
                                                                                            <div className='selectedItemToAddCounter-container'>
                                                                                                <span>OTP has been sent on your Aadhar registered mobile number</span>
                                                                                                <span style={{color:"#757575"}}>Didn’t receive an OTP? <span style={{color:"#04B23D"}}>00:{counter}</span>
                                                                                                {
                                                                                                    +counter === 0 ? (
                                                                                                        <span style={{cursor: "pointer" , color:"#04B23D"}} onClick={handleResendOtp}>Resend</span>
                                                                                                    ) : (
                                                                                                        <span style={{cursor: "text" , color:"#D3D3D3"}}>Resend</span>
                                                                                                    )
                                                                                                }</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='selectedItemToAddSubmit-row'>
                                                                                            <button className={` addItemSubmit addItemOtpVerify ${aadharOTP.length<6 ? 'disabledAddItemSubmit' : "addItemSubmit"}`} onClick={()=>{handleOtpverifyBtn(partnerIdType.id)}}>Verify</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Popup>
                                                                        </div>
                                                                    :""}
                                                                    {partnerIdType.type === "Voter_id".toUpperCase() || partnerIdType.type === "Passport".toUpperCase() ?
                                                                        <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                            <div className='upload-Names-div'>
                                                                                {partnerIdType.documents?.map((doc,index)=>{
                                                                                    return <div className='documentname-cross-div'><img src={documentIcon} style={{marginRight: "1rem"}}></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer", marginLeft: "1rem" }} onClick={() => {handleRemoveDocument(`idType${partnerIdType.id}` ,index)}}></img></div>
                                                                                })}
                                                                            </div>
                                                                            <div className="upload-addMore-Btn-div">
                                                                                <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`idType${partnerIdType.id}`)}}><img src={uploadArrowTransparentBG}></img> {partnerIdType.documents.length>0?"Add":"Upload"}</button>
                                                                            </div>
                                                                        </div>
                                                                    :""}
                                                                </div>
                                                            })}
                                                            
                                                            {!(organization_type==="Proprietorship" && partnersIdType.length>0)?
                                                                <div className="addMorePartners-div" onClick={addPartnerIdType}><span className='addMorePartners-text'>ADD MORE {organization_type==="Partnership" ? "Partners" :(organization_type==="LLP" || organization_type==="Company" ? "Signatories" : "")}</span></div>
                                                            :""}
                                                        </div>
                                                    </div>
                                                    {ecashGSTValue!=="" &&  ecashGSTValue!==null ?
                                                        <div className='document-container'>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>GST Certificate <span style={{color: "#04B23D"}}>*</span></span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    {gstDocuments.length>0 ? 
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{gstDocuments.length}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                    <div className='upload-Names-div'>
                                                                        {gstDocuments?.map((doc,index)=>{
                                                                            return <div className='documentname-cross-div' key={doc.name}><img></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`gstDoc` ,index)}}></img></div>
                                                                        })}
                                                                    </div>
                                                                    <div className="upload-addMore-Btn-div">
                                                                        <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`gstDoc`)}}><img src={uploadArrowTransparentBG}></img>{gstDocuments.length>0?"Add":"Upload"}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :""
                                                    }
                                                    {(ecashGSTValue==="" ||  ecashGSTValue===null) && organization_type === "Proprietorship" ?
                                                        <>
                                                            <span>Upload ANY ONE of the below</span>
                                                            <div className='document-container'>
                                                                <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                    <div className='document-Name-div'>
                                                                        <span className='document-name'>Shops and Establishment</span>
                                                                        <img src={infoIcon} style={{width: "17px"}}></img>
                                                                    </div>
                                                                    <div className='greenUpload-docNumber-div'>
                                                                        {anyOneShopsAndEstablishment.length>0 ? 
                                                                            <div className='documentsNumberDiv'>
                                                                                <img src={pdf}></img>
                                                                                <span>+{anyOneShopsAndEstablishment.length}</span>
                                                                            </div>: 
                                                                            <img src={greenUpload} className='greenUploadImg'></img>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='openDocument-Div'>
                                                                    <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                        <div className='upload-Names-div'>
                                                                            {anyOneShopsAndEstablishment?.map((doc,index)=>{
                                                                                return <div className='documentname-cross-div' key={doc.name}><img></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`anyOneShops` ,index)}}></img></div>
                                                                            })}
                                                                        </div>
                                                                        <div className="upload-addMore-Btn-div">
                                                                            <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`anyOneShops`)}}><img src={uploadArrowTransparentBG}></img>{anyOneShopsAndEstablishment.length>0?"Add":"Upload"}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='document-container'>
                                                                <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                    <div className='document-Name-div'>
                                                                        <span className='document-name'>Utility Bill</span>
                                                                        <img src={infoIcon} style={{width: "17px"}}></img>
                                                                    </div>
                                                                    <div className='greenUpload-docNumber-div'>
                                                                        {anyOneUtilityBill.length>0 ? 
                                                                            <div className='documentsNumberDiv'>
                                                                                <img src={pdf}></img>
                                                                                <span>+{anyOneUtilityBill.length}</span>
                                                                            </div>: 
                                                                            <img src={greenUpload} className='greenUploadImg'></img>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='openDocument-Div'>
                                                                    <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                        <div className='upload-Names-div'>
                                                                            {anyOneUtilityBill?.map((doc,index)=>{
                                                                                return <div className='documentname-cross-div' key={doc.name}><img></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`anyOneUtility` ,index)}}></img></div>
                                                                            })}
                                                                        </div>
                                                                        <div className="upload-addMore-Btn-div">
                                                                            <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`anyOneUtility`)}}><img src={uploadArrowTransparentBG}></img>{anyOneUtilityBill.length>0?"Add":"Upload"}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='document-container'>
                                                                <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                    <div className='document-Name-div'>
                                                                        <span className='document-name'>Mandi License</span>
                                                                        <img src={infoIcon} style={{width: "17px"}}></img>
                                                                    </div>
                                                                    <div className='greenUpload-docNumber-div'>
                                                                        {anyOneMandiLicense.length>0 ? 
                                                                            <div className='documentsNumberDiv'>
                                                                                <img src={pdf}></img>
                                                                                <span>+{anyOneMandiLicense.length}</span>
                                                                            </div>: 
                                                                            <img src={greenUpload} className='greenUploadImg'></img>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='openDocument-Div'>
                                                                    <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                        <div className='upload-Names-div'>
                                                                            {anyOneMandiLicense?.map((doc,index)=>{
                                                                                return <div className='documentname-cross-div' key={doc.name}><img></img>{doc.name}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`anyOneMandi` ,index)}}></img></div>
                                                                            })}
                                                                        </div>
                                                                        <div className="upload-addMore-Btn-div">
                                                                            <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`anyOneMandi`)}}><img src={uploadArrowTransparentBG}></img>{anyOneMandiLicense.length>0?"Add":"Upload"}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        
                                                    :""} 
                                                </div>
                                            :""}
                                            {(details_state === 4)?
                                                <div className='documents-partnership-withgst'>
                                                    <div className='selfAttested-percent-div'>
                                                        <span className='self-attested-text'>*Documents must be self attested</span>
                                                        <div className='percent-bar-text-div' style={{display:"flex"}}>
                                                            <img></img>
                                                            <span className='percentage-text'>
                                                                <CircularPercentageLoader percentage={percentage} />
                                                                <span>{percentage <=100 ? percentage : 100}% Completed</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {showSaveBeforeAlert ? 
                                                        <div className='save-before-back-alert'>
                                                            <img src={alertTriangle}></img>
                                                            <span>Please save before proceeding to next.</span>
                                                        </div>
                                                        :""
                                                    }
                                                    <Popup open={uploadDocumentPopup} className="upload-drag-popup" closeOnDocumentClick={false}>
                                                        <div className='modal-header upload-drag-header'>
                                                            <div className='cross-header'>
                                                                <img src={crossInput} onClick={()=>{setUploadDocumentPopup(false); setCurrentFile(null);}} style={{cursor:"pointer"}}></img>
                                                            </div>
                                                            <div className='upload-drag-heading'><span>Upload Files</span></div>
                                                        </div>
                                                        <div className="modal-content upload-drag-content">
                                                            <div className='dotted-div' id="drag-drop-area" 
                                                                onDrop={(e) => handleDrop(e)}
                                                                onDragOver={(e) => handleDragOver(e)}
                                                            >
                                                                <img src={bigDocImg}></img>
                                                                <span className="dragdrop-text">Drag & Drop</span>
                                                                <span className="browse-text">Your files here Or <span style={{color:"#04B23D" , cursor:"pointer"}} onClick={openFileInput}>Browse</span> to upload</span>
                                                                <span>{currentFile?.name}</span>
                                                                <span className="size-text">Only JPEG, PNG, GIF and PDF files with max size of 15MB</span>
                                                                <input
                                                                    type="file"
                                                                    multiple
                                                                    style={{ display: 'none' }}
                                                                    ref={fileInputRef}
                                                                    onChange={(e) => handleFileInput(e)}
                                                                />
                                                            </div>
                                                            <button className='saveFileBtn' onClick={handleSaveFile}>Save File</button>
                                                        </div>
                                                    </Popup>
                                                    {additionalDocs?.map((doc, idxxx)=>{
                                                         return <div className='document-container75Lac' key={doc.docName? doc.docName : `AdditonDoc${idxxx}`}>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>{doc.docName}<span style={{color: "#04B23D"}}>*</span></span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    {console.log(doc?.documents, doc?.documents.length)}
                                                                    {doc?.documents?.length>0 ? 
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{doc?.documents?.length}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                    <div className='upload-Names-div'>
                                                                        {doc.documents?.map((dc,index)=>{
                                                                            return <div className='documentname-cross-div' key={dc.name? dc.name:`Additional Document${index}`}><img></img>{dc.name? dc.name:"Additional Document"}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`addiDocs${idxxx}` ,index)}}></img></div>
                                                                        })}
                                                                    </div>
                                                                    <div className="upload-addMore-Btn-div">
                                                                        <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`addiDocs${idxxx}`)}}><img src={uploadArrowTransparentBG}></img>{doc.documents?.length>0?"Add":"Upload"}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    })}
                                                </div>
                                            :""}   
                                            {(details_state === 5)? 
                                                <div className='reviewing75LacDocsScreen'>
                                                    <span className='requestFor75LacInProcess'>Your request for <span className='green75Lac'>₹75L</span> is in progress!</span>
                                                    <img src={reviewDocsFarmerSupplierImg} className='roundImageDoneRequest'></img>
                                                    <div className='textDivFor75LacScreen'>
                                                        <div className='upperTextFor75LacScreen'>
                                                            <img src={searchDocIcon}></img>
                                                            <span className='reviewingDocsText'>We're reviewing your documents.</span>
                                                        </div>
                                                        <span className='lowerTextFor75LacScreen'>Our team will be in touch with you within 48 Hours</span>
                                                    </div>
                                                    <span className='requestIdText'>Request ID {ecashRequestId}</span>
                                                </div>
                                            :""} 
                                            {(details_state === 6)?
                                                <div className='documents-partnership-withgst'>
                                                    <div className='crDocsHeading'>Increase your limit to <span className='greenLimitcr'>2 Crores</span> in a few easy steps</div>
                                                    <div className='lowerUploadDocsSectionCr'> 
                                                        <div className='uploadHeadingCr'>
                                                            <img src={yellowDocImage}></img>
                                                            <span className='uploadDocsHeading2Cr'>Upload the documents below</span>
                                                        </div>
                                                        <div className='selfAttested-percent-div'>
                                                            <span className='self-attested-text'>*Documents must be self attested</span>
                                                            <div className='percent-bar-text-div' style={{display:"flex"}}>
                                                                <img></img>
                                                                <span className='percentage-text'>
                                                                    <CircularPercentageLoader percentage={percentage} />
                                                                    <span>{percentage <=100 ? percentage.toFixed(2) : 100}% Completed</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Popup open={uploadDocumentPopup} className="upload-drag-popup" closeOnDocumentClick={false}>
                                                        <div className='modal-header upload-drag-header'>
                                                            <div className='cross-header'>
                                                                <img src={crossInput} onClick={()=>{setUploadDocumentPopup(false); setCurrentFile(null);}} style={{cursor:"pointer"}}></img>
                                                            </div>
                                                            <div className='upload-drag-heading'><span>Upload Files</span></div>
                                                        </div>
                                                        <div className="modal-content upload-drag-content">
                                                            <div className='dotted-div' id="drag-drop-area" 
                                                                onDrop={(e) => handleDrop(e)}
                                                                onDragOver={(e) => handleDragOver(e)}
                                                            >
                                                                <img src={bigDocImg}></img>
                                                                <span className="dragdrop-text">Drag & Drop</span>
                                                                <span className="browse-text">Your files here Or <span style={{color:"#04B23D" , cursor:"pointer"}} onClick={openFileInput}>Browse</span> to upload</span>
                                                                <span>{currentFile?.name}</span>
                                                                <span className="size-text">Only JPEG, PNG, GIF and PDF files with max size of 15MB</span>
                                                                <input
                                                                    type="file"
                                                                    multiple
                                                                    style={{ display: 'none' }}
                                                                    ref={fileInputRef}
                                                                    onChange={(e) => handleFileInput(e)}
                                                                />
                                                            </div>
                                                            <button className='saveFileBtn' onClick={handleSaveFile}>Save File</button>
                                                        </div>
                                                    </Popup>
                                                    <div className='docsdiv2Cr'>
                                                        <div className='document-container2Cr'>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>ITR and Audited Financial Statements<span style={{color: "#04B23D"}}>*</span></span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    { Completedyears.length>0?
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{Completedyears.length}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                <div className='completedItrYears'>
                                                                    {Completedyears.map((completedYear) => {
                                                                            return <div className='completedItrYearDiv'>
                                                                                <div className='completedItrYearLeft'>
                                                                                    <img src={documentIcon}></img>
                                                                                    <span>{completedYear}</span>
                                                                                </div>
                                                                                <img src={greenTick}></img>
                                                                            </div>
                                                                    })}
                                                                </div>
                                                                {Completedyears.length<2 ? 
                                                                    <div className='documentinput-div'>
                                                                        <select className='document-select' 
                                                                        type="select" 
                                                                        placeholder='Select Year' 
                                                                        onChange={(e) => {
                                                                            handleYearChange(e.target.value);
                                                                        }}
                                                                        value={itrYearSelectValue}
                                                                        >
                                                                            {yearOptions.map((option) => (
                                                                                <option key={option} value={option} disabled={option === "Select Year"} selected={option === itrYearSelectValue}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                :""}
                                                                {itrYearSelectValue !=="Select Year" ? <>
                                                                    <div className='sixDocsNames'>
                                                                        <span>1. ITR with or without Computation</span>
                                                                        <span>2. Profile & Loss</span>
                                                                        <span>3. Balance Sheet </span>
                                                                        <span>4. Auditors Report: 3 CB & 3CD</span>
                                                                        <span>5. Auditors Report: </span>
                                                                        <span>6. Schedules & Notes to Accounts</span>
                                                                    </div>
                                                                    <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                        <div className='upload-Names-div'>
                                                                            {yearData.map((yearObj) => {
                                                                                if (yearObj.year === itrYearSelectValue) {
                                                                                    return yearObj.documents.map((doc, index) => (
                                                                                    <div className='documentname-cross-div' key={index}>
                                                                                        <img src={documentIcon} style={{ marginRight: "1rem" }} alt="Document" />
                                                                                        {doc.name}
                                                                                        <img
                                                                                        src={cross}
                                                                                        style={{ width: "9px", cursor: "pointer", marginLeft: "1rem" }}
                                                                                        alt="Remove"
                                                                                        onClick={() => handleRemoveDocument(`yearDocs`, index)}
                                                                                        />
                                                                                    </div>
                                                                                    ));
                                                                                }
                                                                                return null; 
                                                                            })}
                                                                        </div>
                                                                        {yearData.some((yearObj) => yearObj.year === itrYearSelectValue && yearObj.documents.length<6) ? 
                                                                            <div className="upload-addMore-Btn-div">
                                                                                <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`yearDocs`)}}><img src={uploadArrowTransparentBG}></img>{yearData.some((yearObj) => yearObj.year === itrYearSelectValue && yearObj.documents.length > 0)?"Add More":"Upload Documents"}</button>
                                                                            </div>
                                                                        :""}
                                                                    </div>
                                                                </>:""}
                                                            </div> 
                                                        </div> 
                                                        <div className='document-container2Cr'>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>GST 3B for the past 12 months <span style={{color: "#04B23D"}}>*</span></span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    {gst3BDocuments.length>0 ? 
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{gst3BDocuments.length}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                    <div className='upload-Names-div'>
                                                                        {gst3BDocuments?.map((doc,index)=>{
                                                                            return <div className='documentname-cross-div' key={doc.name? doc.name : "GST 3B"}><img></img>{doc.name? doc.name : "GST 3B"}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`gst3BDocuments` ,index)}}></img></div>
                                                                        })}
                                                                    </div>
                                                                    <div className="upload-addMore-Btn-div">
                                                                        <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`gst3BDocuments`)}}><img src={uploadArrowTransparentBG}></img>{additionalDocs?"Add":"Upload"}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        <div className='document-container2Cr'>
                                                            <div className='document-name-upload' onClick={(e)=>{toggleDocument(e,3)}}>
                                                                <div className='document-Name-div'>
                                                                    <span className='document-name'>Bank statements of main Operative <br></br>account for the past 12 months <span style={{color: "#04B23D"}}>*</span></span>
                                                                    <img src={infoIcon} style={{width: "17px"}}></img>
                                                                </div>
                                                                <div className='greenUpload-docNumber-div'>
                                                                    {bankStatementDocuments.length>0 ? 
                                                                        <div className='documentsNumberDiv'>
                                                                            <img src={pdf}></img>
                                                                            <span>+{bankStatementDocuments.length}</span>
                                                                        </div>: 
                                                                        <img src={greenUpload} className='greenUploadImg'></img>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='openDocument-Div'>
                                                                <div className='upload-Names-btn-div' style={{display:"flex"}}>
                                                                    <div className='upload-Names-div'>
                                                                        {bankStatementDocuments?.map((doc,index)=>{
                                                                            return <div className='documentname-cross-div' key={doc.name? doc.name : "Bank Statement"}><img></img>{doc.name? doc.name : "Bank Statement"}<img src={cross} style={{width: "9px" , cursor: "pointer" , marginLeft: "1rem"}} onClick={() => {handleRemoveDocument(`bankStatementDocuments` ,index)}}></img></div>
                                                                        })}
                                                                    </div>
                                                                    <div className="upload-addMore-Btn-div">
                                                                        <button className='document-upload-btn' onClick={()=>{setUploadDocumentPopup(true); setCurrentFileSaveFor(`bankStatementDocuments`)}}><img src={uploadArrowTransparentBG}></img>{additionalDocs?"Add":"Upload"}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                            :""}    
                                            {(details_state === 8)? 
                                                <div className='reviewing75LacDocsScreen'>
                                                    <span className='requestFor75LacInProcess'>Your request for <span className='green75Lac'>₹ 2Cr</span> is in progress!</span>
                                                    <img src={reviewDocsFarmerSupplierImg} className='roundImageDoneRequest'></img>
                                                    <div className='textDivFor75LacScreen'>
                                                        <div className='upperTextFor75LacScreen'>
                                                            <img src={searchDocIcon}></img>
                                                            <span className='reviewingDocsText'>We're reviewing your documents.</span>
                                                        </div>
                                                        <span className='lowerTextFor75LacScreen'>Our team will be in touch with you within 48 Hours</span>
                                                    </div>
                                                    <span className='requestIdText'>Request ID {ecashRequestId}</span>
                                                </div>
                                            :""} 
                                            {(details_state === 9)? 
                                                <div className='reviewing75LacDocsScreen'>
                                                    <span className='requestFor75LacApproved'>Congratulations!</span>
                                                    <img src={congratulationsImage} className='roundImageDoneRequest'></img>
                                                    <div className='textDivFor75LacScreen'>
                                                        <span className='sanctionedDocsText'>You have been sanctioned a</span>
                                                        <span className='limitText'>Loan Limit of ₹75 Lac</span>
                                                    </div>
                                                    <span className='requestIdText'>Request ID {ecashRequestId}</span>
                                                </div>
                                            :""} 
                                            {(details_state === 10)? 
                                                <div className='reviewing75LacDocsScreen'>
                                                    <span className='requestFor75LacInProcess'>Thank you for applying</span>
                                                    <img src={rejected} className='roundImageDoneRequest'></img>
                                                    <span className='sorryText'>Sorry, We can not process your Request.</span>
                                                    <div className='rejectionReason-div'>
                                                        <span className="rejectionHeading">Reason For Rejection:</span>
                                                        {rejectionComments.map((item , indx)=>{
                                                            <span className='rejectionReasons'>{indx+1}. {item.commentHeader}</span>
                                                        })}
                                                    </div>
                                                    <div className='reapplyDiv'>
                                                        <img src={rocket}></img>
                                                        <span className='reapplyText'>You can re-apply after <b>6 Months</b>: on <b>20/04/2024</b></span>
                                                    </div>
                                                </div>
                                            :""} 
                                             {(details_state === 11 )? 
                                                <div className='reviewing75LacDocsScreen'>
                                                    <span className='requestFor75LacApproved'>Congratulations!</span>
                                                    <img src={congratulationsImage} className='roundImageDoneRequest'></img>
                                                    <div className='textDivFor75LacScreen'>
                                                        <span className='sanctionedDocsText'>You have been sanctioned a</span>
                                                        <span className='limitText'>Loan Limit of ₹75 Lac</span>
                                                        <span>But cannot sanction higher limit you applied for</span>
                                                    </div>
                                                    <span className='requestIdText'>Request ID {ecashRequestId}</span>
                                                </div>
                                            :""} 
                                        </div>
                                    </div>
                                </div>

                                {/* buttons */}
                                {details_state=== 1 ? 
                                    <div className="bottom-continue-div">
                                        <button className={`ecashContinueBtn ${ecashPANValue!== ''  && ecashName !=='' && isCheckedEcashTC  ? "enabledContinueBtn" : ""}`} disabled={ecashPANValue=== '' || ecashName ==='' || isCheckedEcashTC === false} onClick={handleEcashPanContinue}>Continue</button>
                                    </div>
                                :""}
                                {details_state=== 2 ? 
                                    <div className="bottom-continue-div">
                                        <button className={`ecashContinueBtn ${ecashGSTValue!== '' && ecashGSTValue.length=== 15 && isValidGst && isRegisteredMSME!==null? "enabledContinueBtn" : ""}`} disabled={ecashGSTValue=== '' || ecashGSTValue.length< 15 || isValidGst ===false || isRegisteredMSME===null} onClick={()=>{ setShowEcashGstContinueConfirmation(true)}}>Continue</button>
                                    </div>
                                :""}
                                {details_state=== 3 && !showReviewDocs? 
                                    <div className="bottom-continue-div">
                                        {showContinueBtn ?
                                            <button className={`ecashContinueBtn enabledContinueBtn`} onClick={ContinuePartnershipDocs1}>Continue</button>
                                            :
                                            <div className='buttons-inner-div'>
                                                <button className='saveExitBtn' onClick={SaveAndExitPartnershipDocs1}>Save & Exit</button>
                                                <button className='ProceedBtn' onClick={partnershipDocsProceed}>Proceed</button>
                                            </div>
                                        }
                                    </div>
                                :""} 
                                {details_state=== 4? 
                                    <div className="bottom-continue-div">
                                        {showContinueBtn ?
                                            <button className={`ecashContinueBtn enabledContinueBtn`} onClick={ContinuePartnershipLimitDocs}>Continue</button>
                                            :
                                            <div className='buttons-inner-div'>
                                                <button className='saveExitBtn' onClick={SaveAndExitPartnershipLimitDocs}>Save & Exit</button>
                                                <button className='ProceedBtn' onClick={partnershipLimitDocsProceed}>Proceed</button>
                                            </div>
                                        }
                                    </div>
                                :""}
                                {details_state=== 5? 
                                    <div className="bottom-continue-div">
                                        <div className='wnatToIcrText'>Want to increase your limit to <span className='limitgreen'>2 Cr?</span></div>
                                            <div className='buttons-inner-div'>
                                                <button className='saveExitBtn' onClick={()=>{history.push('/postreg')}}>Explore More</button>
                                                <button className='ProceedBtn' onClick={()=>{setDetails_state(6)}}>Yes</button>
                                            </div>
                                    </div>
                                :""}
                                {details_state=== 6? 
                                    <div className="bottom-continue-div">
                                        {showContinueBtn ?
                                            <button className={`ecashContinueBtn enabledContinueBtn`} onClick={ContinueCrDocuments}>Continue</button>
                                            :
                                            <div className='buttons-inner-div'>
                                                <button className='saveExitBtn' onClick={SaveAndExitCRDocuments}>Save & Exit</button>
                                                <button className='ProceedBtn' onClick={financialDocsProceed}>Proceed</button>
                                            </div>
                                        }
                                    </div>
                                :""}
                                {details_state=== 8? 
                                    <div className="bottom-continue-div">
                                        <div className='buttons-inner-div'>
                                            <button className='saveExitBtn' onClick={()=>{history.push('/postreg')}}>Explore More</button>
                                        </div>
                                    </div>
                                :""}
                                {details_state=== 9? 
                                    <div className="bottom-continue-div">
                                        <div className='wnatToIcrText'>Want to increase your limit to <span className='limitgreen'>2 Cr?</span></div>
                                            <div className='buttons-inner-div'>
                                                <button className='saveExitBtn' onClick={()=>{history.push('/postreg')}}>Explore More</button>
                                                <button className='ProceedBtn' onClick={()=>{setDetails_state(6)}}>Yes</button>
                                            </div>
                                    </div>
                                :""}

                                {showReviewDocs ?   
                                    <div className='reviewDocsDiv'>
                                        <img src={reviewDocsGif} className='reviewGif'></img>
                                    </div>
                                    :""
                                }
                               
                            </div>
                        </div>
                    </div>
                    <Footer />
                </Scrollbars>
                <FixedAppDirectingFooter />
            </div>
        </div>
    )
}

export default EcashPostReg;
