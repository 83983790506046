//upcoming auction card changed reserve price value to indicative price value
//on upcoming card click details
import React, { Component } from 'react';
import { Carousel } from 'primereact/carousel';
import "./style.scss";
import { toast } from 'react-toastify';
import Timer from './Timer';
import {numberWithCommas} from '../../utils/Utility'
class UpcomingAuctionsCarousel extends Component {
    static timer = {}
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    onClickSetalert = () => {
        toast.error("wrong")
    }

    productTemplate = (product) => {
        // let a=    this.calculatetime(product.endDate);
        let date = product.status == "Scheduled" ? product.startDate : ""
        let footerText = "";
        // console.log(product.auctionImageUrl,'product')
        let commoditysplitedname=product.commodityName.split(" ")
        let splittedlocation=product.deliveryLocation;
        let splittedwarehouseName = product.warehouseName;
        let splittedState = product.state.replace(/[0-9-]/g,'');

        if(splittedlocation){
            var newsplittedlocation = splittedlocation.length > 20 ? splittedlocation.substring(0, 17) + "..." : splittedlocation;
        }
        if(splittedState){
            var stateName = splittedState.length > 20 ? splittedState.substring(0, 17) + "..." : splittedState;
        }
        if(splittedwarehouseName !== null){
            var newsplittedwarehouseName = splittedwarehouseName.length > 20 ? splittedwarehouseName.substring(0, 17) + "..." : splittedwarehouseName;
        }

        return (
            // <div className="upcoming-card-view">
            //     <div className="product-item">
            //         <div className="top-warpper">
            //             <div className="image-wrapper">
            //                 <div className="commodity-image">
            //                     {/* Image */}
            //                     <img src={product.auctionImageUrl} alt="preview" />
            //                 </div>
            //             </div>
            //             <div className="commodity-info">
            //             <p className="commodity-name" >{commoditysplitedname[0]}</p>
            //             <p className="commodity-name" >{commoditysplitedname[1]}</p>
            //             <p className="commodity-name" >{commoditysplitedname[2]}</p>
            //                 {product.floorPrie ? <p className="commodity-price"><i class="fa fa-inr"></i>{` ${numberWithCommas(product.floorPrie.toFixed(2))}/${product.lotUom.toUpperCase()}`}</p> : ""}
            //                 {/* {product.floorPrie ? <p className="commodity-price">{`${product.lotUom.toUpperCase()}`}</p> : ""} */}
            //                 {/* <p className="bid-now" onClick={() => this.props.onBidNowClick(product)}>{this.props.buttonText}</p> */}
            //             </div>
            //             <div className="commodity-qty">
            //                 <p>{`${numberWithCommas(product.totalQty)}${product.lotUom.toUpperCase()}`}</p>
            //                 <p className="bid-now" onClick={() => this.props.onBidNowClick(product)}>{this.props.buttonText}</p>
                        
            //             </div>
            //         </div>
            //         <div className="bottom-wrapper">
            //             <div className='location-cls'>
            //                  {/* <p>{product.deliveryLocation.slice(0,10)}</p>
            //                 <p>{product.deliveryLocation.slice(10,21)}</p> */}
            //                 <p>{splittedlocation[0]}</p>
            //                 <p>{splittedlocation[1]}</p>
            //             </div>
            //             <div className='time-cls'>
            //                 <p className="closes-in">{`${footerText} `}<p className='close-label'><p className='label' style={{"textAlign":"center"}}></p> {<Timer dateFuture={product.startDate} />}</p> </p>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            <div className="upcoming-card-view" onClick={() => this.props.onBidNowClick(product)}>
                <div className="product-item"  style={{ backgroundImage: product.auctionImageUrl?`url(${product.auctionImageUrl})`:""}}>
                    <div className='header-wrapper'>
                        <div className="commodity-info">
                            <div className="commodity-name" >{commoditysplitedname[0]}</div>
                            <div className="commodity-name" >{commoditysplitedname[1]}</div>
                            <div className="commodity-name" >{commoditysplitedname[2]}</div>
                        </div>
                        <div className='commodity-details'>
                          <div>{`${numberWithCommas(product.totalQty)}${product.lotUom.toUpperCase()}`}</div> 
                          {/* <div className='price'>{product.currentRound==="Round1"?(product.floorPrie ? <div className="commodity-price"><i class="fa fa-inr"></i>{` ${numberWithCommas(product.floorPrie.toFixed(2))}/${product.lotUom.toUpperCase()}`}</div>:""):(product.currentRound==="ROUND2"?(product.reservePriceRoundTwo ? <div className="commodity-price"><i class="fa fa-inr"></i>{` ${numberWithCommas(product.reservePriceRoundTwo.toFixed(2))}/${product.lotUom.toUpperCase()}`}</div>:""):"")}</div>  */}
                          <div className='price'>{product.indicativePrice ? <div className="commodity-price"><i class="fa fa-inr"></i>{` ${numberWithCommas(product.indicativePrice.toFixed(2))}/${product.lotUom.toUpperCase()}`}</div> : "No Price"}</div> 
                        
                        </div>
                    </div>
                    <div className="commodity-qty">
                       <div className="bid-now" onClick={(event) => this.props.onClickSetalert(product,event)}>{this.props.buttonText}</div>

                         {/* { auctiontype=="FA" ?<div className="bid-now" onClick={() => this.props.onBidNowClick(product)}>Buy Now</div>:<div className="bid-now" onClick={() => this.props.onBidNowClick(product)}>Sell Now</div>} */}
                     </div>
                    <div className="bottom-wrapper">
                        <div className='location-cls'>
                        <p>{newsplittedwarehouseName ? newsplittedwarehouseName : splittedwarehouseName == null ? <br/> : newsplittedwarehouseName }</p>
                            <p>{newsplittedlocation}</p>
                            <p>{stateName}</p>
                        </div>
                        <div className='time-cls'>
                            <p className="closes-in">{`${footerText} `}<p className='close-label'><p className='label' style={{ "textAlign": "center" }}></p> {<Timer dateFuture={product.startDate} />}</p> </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let { value = [{}, {}, {}, {}, {}, {}], numVisible = 2, numScroll = 2, responsiveOptions, header, className, config, onClickSetalert, onBidNowClick, timeremaining, calculatetime } = this.props;

        responsiveOptions = [];
        return (<div className={`custom-carousel ${className ? className : ""}`}>
            <Carousel value={value} numVisible={2} numScroll={2}
                responsiveOptions={responsiveOptions} itemTemplate={this.productTemplate}
                header={<h5>{header}</h5>} config={{ ...config }}
            />
        </div>);
    }
}

export default UpcomingAuctionsCarousel;