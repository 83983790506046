import React from 'react'
import './FIxedAppDirectingFooter.css'

function FixedAppDirectingFooter() {

    const playstoreRedirect = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            window.location.replace("https://play.google.com/store/apps/details?id=com.origo.auction");
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.replace("https://apps.apple.com/us/app/origo-auction/id1560836342");
        }
    }
    
  return (
    <div className='fixed-footer-container'>
        <p className='upgrade-text'>Upgrade to a better experience</p>
        <button className='download-app-btn' onClick={playstoreRedirect}>Download our app</button>
    </div>
  )
}

export default FixedAppDirectingFooter
