import { combineReducers } from "redux";

import AppReducer from "./App";
import EditProfile from "./editProfile";
import Commodity from "./commodity";


const rootReducer = combineReducers({
    AppReducer, EditProfile, Commodity
});

export default rootReducer;