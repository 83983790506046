import React, { Component } from 'react'
import CustomTable from '../../../components/CustomTable'
import Scrollbars from 'react-custom-scrollbars'
import CashandCarryPopUp from './CashandCarryPopUp'
import CustomButton from '../../../components/CustomButton'
import SuccessPopUp from './SuccessPopUp'
class list extends Component {
    render() {
        let {state,onClickBack,cashandCarryPopUp,hideCashandCarryPopUp,onSubmitcashandcarryPopUp,handelInputChange,onclickAvailnow,formData,hideConfirmPopUp}=this.props
        
        let column = [
            {
                "header": "Commodity",
                "field": "commodityName",
                renderer: ((rowData) => {
                    return ((rowData && rowData.commodityName) ? <div className="auction-id-byoutType"><div>{rowData && rowData.commodityName}</div></div> : "");
                })
            },

            {
                "header": "Rate Of Return",
                "field": "rateOfReturn",
                renderer: ((rowData) => {
                    return ((rowData && rowData.rateOfReturn) ? <div className="auction-id-byoutType"><div>{`${rowData && rowData.rateOfReturn}%`}</div></div> : "0");
                })
            },
            {
                "header": "Lock in period",
                "field": "lockInPeriod",
                renderer: ((rowData) => {
                    return ((rowData && rowData.lockInPeriod) ? <div className="auction-id-byoutType"><div>{`${rowData && rowData.lockInPeriod} ${rowData.lockInPeriod === 1 ? "day" : "days"}`}</div></div> : "0");
                })
            },
            {
                "header": "",
                "field": "",
                renderer: ((rowData) => {
                    return ((rowData && rowData) ? <div className="auction-id-byoutType"><div><CustomButton label="Avail now" onClick={() => onclickAvailnow(rowData)} /></div></div> : "");
                })
            }
        ];
        return (
            <div className='cashandcarryarbitage-cls'>
                <CashandCarryPopUp visible={cashandCarryPopUp} onHide={hideCashandCarryPopUp} onCancel={hideCashandCarryPopUp} onSubmitcashandcarryPopUp={onSubmitcashandcarryPopUp} handelInputChange={handelInputChange} formData={formData} state={state} />
                <SuccessPopUp visible={state.sucessDailog} onHide={hideConfirmPopUp} onCancel={hideConfirmPopUp} requestId={state.requestId} />

                <div className="header-cls">
                    <i className="pi pi-angle-left" onClick={onClickBack}></i>
                    {/* <img src={Logo} /> */}
                    <p>Cash and Carry Arbitage</p>
                </div>
                <div className="customTable-cls" >
                    <Scrollbars autoHide={false} style={{ width: "100%", height: "60vh" }}>

                        <CustomTable columns={column} data={state.cashandcarryList} className="bids-activity-grid-cls" config={{ scrollable: true, scrollHeight: "250px" }} />
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default list