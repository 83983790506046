import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Navbar from '../../../components/HomeNewPageComponents/Navbar/Navbar';
import image1 from "../../../assets/images/video1.svg";
import image2 from "../../../assets/images/video2.svg";
import image3 from "../../../assets/images/video3.svg";
import './emandiTv.scss';
import Footer from '../../../components/HomeNewPageComponents/Footer/Footer';
import Form from './Form';
import { Link, useHistory } from "react-router-dom";
import { history } from '../../../utils/Utility';
import { Component } from 'react';
import { GET_EMANDI_TV_LIST } from '../../../utils/Api';
import { fireAjaxRequest } from '../../../utils/Utility';
import { toast } from 'react-toastify';
import back_arrow from '../../../assets/images/back_arrow_breadcrumbs.svg'

class EmandiTVNew extends Component {

    constructor(props)
    {
        super(props);
        this.Pathlocation="";
        
        this.state = {
            formData: {
            },
            showTvpopUp: false,
            offset :0,
            limit: 500,
            count:0,
            emandiData:[],
            emandiAllData:[],
            current_page:1,
            pageNumberLimit:3,
            maxPageNumberLimit:3,
            minPageNumberLimit:0,
            options: [{ "label": "All time", "value": "" },
            { "label": "Last 15 days", "value": "15days" },
            { "label": "Last 1 Month", "value": "1Month" },
            { "label": "Last 3 Month", "value": "3Month" },
            { "label": "Last 6 Month", "value": "6Month" },
            { "label": "Last 1 Year", "value": "1Year" }]
        }
        this.getEmandiTVlist();
    };
    
    hisFunc()
    {
        this.Pathlocation = history.location.pathname;
    }
    getEmandiTVlist()  {
        let { formData, offset, limit } = this.state;
        let emandiURL = `${GET_EMANDI_TV_LIST}/get?offset=${offset}&limit=${limit}`;
       
            if (formData.time) {
                emandiURL = `${emandiURL}&fromDate=${formData.time}`
            }
    
        (async () => {
            // this.setLoading(true);
            try {
    
                let apiUrl = `${emandiURL}`;
                const response = await fireAjaxRequest(`${apiUrl}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                // this.setLoading(false);
                if (response) {
                    this.setState({ emandiData: response.data , count: response.count,emandiAllData:response.data});
    
                } else {
    
                }
    
            } catch (err) {
                // this.setLoading(false);
                //console.log("Failed to load trader Details !");
                toast.error(err.message || "Failed to load !");
    
            }
        })();
    }
    backHome = () => {
        // history.push("/postreg#emandiTVId");
        history.push({
            pathname: '/postreg',
            state: { source: 'emanditv-view-all-page' },
            hash: '#emandiTVId',
          });
    }
    filterTVNews=(e)=>
    {
        let serachItem=e.target.value;
        if(serachItem!="")
        {
            let listData=this.state.emandiData;
            this.setState({emandiData:listData.filter(x=>x.tittle.toLowerCase().includes(serachItem.toLowerCase()))});
        }
        else
        {
            this.setState({emandiData:this.state.emandiAllData});
        }
    }

render(){
    return (
        <div>
            <Navbar />
            <Scrollbars autoHide={false} style={{ width: "100%", height: "93vh" }}>
                <div className='newEmandiNews container-css ' style = {{marginBottom: '260px'}}>
                    <div className='container mt-5'>
                        <p className='backbtn p-css-1' onClick={() => this.backHome()}>
                            <img src={back_arrow} onClick={() => this.backHome()}></img>&nbsp; &nbsp;&nbsp;&nbsp; Home&nbsp; &gt;
                            <span style={{ color: '#04B23D' }}>&nbsp; &nbsp;eTV</span>
                        </p>
                    </div>
                    <div className='container'>
                        <div className='container-css-1 mt-xl-5 col-md'>
                            <div className = 'smallBox'>
                            <div class="small-box yellow" style={{ height: '40px', paddingTop: '5px'}}>
                            </div>
                            <p className='mx-3 big mt-1 emandiTVViewAll' style={{ fontSize: '22px', 
    fontWeight: '800' }}>eTV</p>
    </div>
                            {/* <div class="input-group">
    <span class="input-group-addon" style = {{    marginLeft: '700px' }}>
    <input type="text input-css-2 " class="form-control" placeholder='         Search Videos'/>
    <i class="fa fa-search i-css-1" style = {{  paddingLeft: '20px !important' }}></i>
    </span>
</div> */}
<div class="input-box">
  {/* <span class="prefix input-box-addon">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> */}
  <i class="fa fa-search i-css-1"></i>
  <input type="text input-css-2" placeholder="Search videos" onChange={(e)=>{this.filterTVNews(e)}}  style = {{ fontFamily: 'MetroPolisExtraLight', fontWeight: '400' }}/>
  {/* <i class="fa fa-search i-css-1" style = {{  paddingLeft: '20px !important', marginTop: '300px !important', marginLeft: '600px !important' }}></i> */}
</div>
                        {/* <div className='smallBox mt-xl-5'>
                            <div class="small-box  yellow">
                            </div>
                            <p className='mx-3 big mt-1 ' style={{ fontWeight: 'bold' }}>eMandi TV</p>
                            <input type="text" class="form-control mt-2" onChange={(e)=>{this.filterTVNews(e)}} id="exampleInputPassword1" placeholder='search news' style={{ height: '35px', marginLeft: '70%', width: '17%' }} />
                        </div> */}

                            {/* <input type="text" class="form-control mt-2 input-css-1 " onChange={(e)=>{this.filterTVNews(e)}} id="exampleInputPassword1" placeholder='Search Videos' style={{ height: '35px', marginLeft: '70%', width: '17%' }} 
                            />  */}
                            {/* <i class="fa fa-search"></i> */}

                        </div>

                    <div class="row mt-5 TvRow" style = {{justifyContent: 'space-between'}}>
                        {
                            this.state.emandiData.map((item)=>(
                            <div class="col-md-3" style={{width:"350px"}}>
                            <iframe class="card-img-top" src={item.content} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen" 
                            msallowfullscreen="msallowfullscreen" 
                            oallowfullscreen="oallowfullscreen" 
                            webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                                {/* <div class="card-body"> */}
                                <p class="card-text mt-4" style={{ fontSize: '16px' }}>{item.tittle}</p>
                                {/* </div> */}
                                <p style={{color: "#757575"}} className='views'>{item.uploadedOn}</p>
                            </div>
                            ))
                        }    
                    </div>
                </div>
                </div>
                <div className='mt-5 container-css'>
                    <div className='form mt-5 formSection'>
                        <div className='formBG emandiTvNewFormBg'>
                            <div class="container">
                                <div class="row mt-5 mt-3 mx-3">
                                    <div class="col-lg">
                                        <p className='formText mb-4 stay-ahead-css stayP'>
                                            Stay Ahead of everyone,<br />
                                            Join the waitlist for <span style={{ color: '#04B23D' }}>eHedge</span> and <span style={{ color: '#04B23D' }}>eSPOT</span>
                                        </p>
                                        <hr
                                            class="mb-4 mt-0 d-inline-block mx-auto"
                                            style={{ width: '70%', color: '#FDE96C', border: '2px solid', background: '#FDE96C', height: '2px' }}
                                        />
                                        <p className='headText mt-3'>For Agri Raw Materials at <strong style={{ color: 'black' }}>Fixed Price</strong></p>
                                        <p className='headText mt-3'>&</p>
                                        <p className='headText mt-3'>The <strong style={{ color: 'black' }}> best OFFER</strong> for your commodities</p>
                                    </div>
                                    <div class="col-sm formDivv">
                                        <Form />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Scrollbars>
        </div>
    )
}
}

export default EmandiTVNew