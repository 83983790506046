import React, { Component } from 'react';
import { Slider } from 'primereact/slider';
import "./style.scss";

class CoustomSilder extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        let {onChange, name, value, config, className } = this.props;

        return ( <div className={`custom-silder ${className?className:""}`}>
            <p>[{value[0]}, {value[1]}]</p>
            <Slider name={name} value={value} onChange={onChange} range config={{...config}}/>
        </div> );
    }
}
 
export default CoustomSilder;