import React, { useState } from 'react';
import PropTypes from "prop-types";
import "./style.scss";

//@primereact components
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { cssTransition } from 'react-toastify';


//@functional component
const CustomDropdown = ({ label, name, value, onChange, className, config, required, options, multiselect, isdisabled }) => {
    if (multiselect === true) {
        return (
            <div className={`dropdown-content-section ${className || ""}`}>
                <div className="Dropdown-label">
                    {required ? <span className="required-field">* </span> : ""}
                    <label >{label}</label>
                </div>
                <MultiSelect name={name} options={options} value={value} onChange={onChange} className="multiselect" filter={true} filterPlaceholder="Search" {...config} disabled={isdisabled}
                />
            </div>
        );
    } else {
        return (
            <div className={`dropdown-content-section ${className || ""}`}>
                <div className="Dropdown-label">
                    {required ? <span className="required-field">* </span> : ""}
                    <label >{label}</label>
                </div>
                <Dropdown name={name} options={options} value={value} onChange={onChange} tooltip={name === "type" ?value:""} tooltipOptions={{position: 'bottom'}} {...config} />

            </div>
        );
    }

};

//@for required/options props
CustomDropdown.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number
    ]),
    options: PropTypes.array,
    config: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default CustomDropdown