import React, { Component } from 'react'
import CustomDialog from "../../../components/CustomDialog";
import { Scrollbars } from 'react-custom-scrollbars';
import CustomButton from '../../../components/CustomButton';
import './PopUp.scss';

class EmandiCashStatusPopUp extends Component {
    render() {
        const { visible, onHide, onAcceptEmandiCashStatus, onCancelEmandiCashStatus, emandicashstatusPopup,status } = this.props;
        return (
            <div className="popup-cls">
                <div className="status-pop-up">
                    <CustomDialog visible={visible} footer={() => { }} header={<p>{status}</p>} onHide={onCancelEmandiCashStatus} onCancel={onCancelEmandiCashStatus} width="40vw">
                            <div className="buynow-cls">
                                {/* <Scrollbars className="contact-us-scrollbar" autoHide={false} style={{ width: "100%", height: '75vh' }}> */}
                                {/* <div className="header-cls">
                                <div className="first-div">Buy Now Pay Later</div>
                            </div> */}
                                <div className="message-us">
                                    <div className="btns-cls">
                                        <CustomButton label="Cancel" className="cancel" config={{ onClick: onCancelEmandiCashStatus }} />
                                        <CustomButton label="OK" className="send" config={{ onClick: onAcceptEmandiCashStatus }} />
                                    </div>
                                </div>
                                {/* </Scrollbars> */}
                            </div>
                    </CustomDialog>

                </div>
            </div>);
    }
}

export default EmandiCashStatusPopUp