import React, { Component } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';

import './styles.scss';

class Spinner extends Component {
    render() {
        const {strokeWidth=3,style={width:50}}=this.props;
        return (
            <div className="spinnerCls">
                <ProgressSpinner strokeWidth={strokeWidth} style={style}/>
            </div>
        )
    }
}

export default Spinner;