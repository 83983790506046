import React from 'react';
import 'react-bootstrap-accordion/dist/index.css';
import '../style/eCashHome.css';
import HomeVector from '../Images/home_vector.png';

import Coins from '../Images/coins.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ECashHome() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000, // Adjust the speed as per your requirement
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Change slide every 4 seconds
    prevArrow: null, // Remove the previous arrow
    nextArrow: null,
    appendDots: (dots) => (
      <div>
        <ul className="custom-dots">{dots}</ul>
      </div>
    ),
  };

  return (
    <>
      <div className="Container eCashHome">
        <div className='text-section'>
          <div className='instant-cash'>
           Get instant cash for your <br></br> commodity supplies with <br></br><span style = {{color: '#04B23D' }}> eCash </span>
          </div>
          <div className='explore'>
            Explore Special Benefits with us
          </div>
          {/* <hr style={{color:"white", width:"80%"}}></hr> */}
          <div className="carousel-container">
            <Slider {...settings}>
              <div className="slide"><img src = {HomeVector} /><span>Collateral Free</span></div>
              <div className="slide"><img src = {HomeVector} /><span>Staring from 0.7%</span></div>
              <div className="slide"><img src = {HomeVector} /><span>Minimum Documentation</span></div>
              <div className="slide"><img src = {HomeVector} /><span>Relationship Management</span></div>
            </Slider>
          </div>
          <img className='coins-section' src= {Coins} />
        </div>
      </div>
    </>
  );
}

export default ECashHome;
