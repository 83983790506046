import React, { Component } from 'react';
import CustomDialog from "./components/CustomDialog";
// import  CountdownCircleTimer  from "react-countdown-circle-timer";
import "./sessionTimeOutStyle.scss"

class SessionTimeOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remainingTime: 60
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            let {remainingTime } = this.state;

            if (this.props.remainingTime > 0){
                remainingTime --;
            }

            if (remainingTime === 0){
                clearInterval(this.timer);
                this.props.onCancel();
            }
            // console.log(remainingTime,'remainingTime')
            this.setState({ remainingTime: remainingTime});
        }, 1000);
    }

    renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            this.props.onCancel();
        }

        return (
            <div className="timer">
                <p className="value">{remainingTime}</p>
            </div>
        );
    };

    render() {
        let { visible, onHide, onAccept, onCancel } = this.props;

        return (<CustomDialog className={"session-time-out"} visible={visible} onHide={onHide} btn1={"Log Out"} btn2={"Continue Session"} onAccept={onAccept} onCancel={onCancel} header={"Session Time Out"}>
            <div className="time-out-alert">
                <i className="pi pi-exclamation-triangle"></i>
                <div className="wrapper">
                    <p>Your session is about to expire.</p>
                    <div className="warp-timer">
                        <p>You will be logged out in {this.state.remainingTime} seconds</p>
                            {/* <CountdownCircleTimer isPlaying  duration={60} colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]} onComplete={() => [false, 1000]}>
                                {this.renderTime}
                            </CountdownCircleTimer>  */}
                        {/* <p className="seconds">seconds</p> */}
                    </div>
                </div>
                
            </div>

        </CustomDialog>);
    }
}

export default SessionTimeOut;