import React, { Component } from 'react';
import CustomInputText from '../../components/CustomInputText';
import CustomButton from '../../components/CustomButton';
import CustomCheckbox from '../../components/CustomCheckbox';
import OTP from '../../components/CustomInputOTP';
import PersonalInfo from './view/PersonalInfo';
import KYCInfo from './view/KYCInfo';
import bgImage from "../../assets/images/graphic_web.png";
import tickGreen from "../../assets/images/tick_green.svg";
import tickGrey from "../../assets/images/tick_grey.svg";
import kycIcon from "../../assets/images/kycIcon.png";
import arrowGrey from "../../assets/images/arrowGrey.png";
import './styles.scss';
import { toast } from 'react-toastify';
import { fireAjaxRequest, history } from '../../utils/Utility';
import { SIGNIN_API, OTP_API, SET_PERSONALINFO_API, REFERRAL_API } from '../../utils/Api';
import ContactUsPopUp from './ContactUsPopUp';
import playStoreLogo from "../../assets/images/category_7f907eb0.png";
import appleStoreLogo from "../../assets/images/category_7f741d10.png";
import Scrollbars from 'react-custom-scrollbars';



class Login extends Component {
    state = {
        phoneNo: "",
        referralNo: "",
        otp: "",
        currentPage: "login",
        name: "",
        resendOTPTime: 90,
        checkTnC: true,
        personalInfo: {},
        showContanctUs: false,
        OTPvalue: ["", "", "", ""],
        disableOTP: false
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    onChangeMobileNo = (e) => {
        var myRegEx = /[^\d]/i;
        if (!myRegEx.test(e.target.value) && e.target.value.length < 11) {
            this.setState({ phoneNo: e.target.value })
        }
    }
    onchangeReferralMobileNo = (e) => {
        var myRegEx = /[^\d]/i;
        if (!myRegEx.test(e.target.value) && e.target.value.length < 11) {
            this.setState({ referralNo: e.target.value })
        }
    }

    onApplyreferralcode = (e) => {
        const { currentPage, referralNo, checkTnC, loginInfo } = this.state;
        if (currentPage === "signup") {
            if (referralNo.length !== 10) {
                toast.error("Please Enter 10 digits Referral Mobile Number");
            }
            else {
                const sendBody = {
                    "referralMobileNumber": this.state.referralNo
                };

                (async () => {
                    try {
                        const resp = await fireAjaxRequest(REFERRAL_API, {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(sendBody)
                        });
                        if (resp && resp.message) {
                            toast.success(resp.message || "Referral Code Applied Successfully");

                        }
                    } catch (err) {
                        toast.error(err.message || "Failed to Apply !");
                    }
                })();
                // this.state.referralNo=""
            }

        }

    }
    resendTimeLimit = (startTimer = false) => {
        if (startTimer) {
            this.timer = setInterval(() => {
                if (this.state.resendOTPTime === 1) {
                    this.setState({ OTPvalue: ["", "", "", ""], disableOTP: true });
                    let selected = document.getElementsByClassName("OTP-class");
                    selected && selected[0] && selected[0].focus();
                }

                this.setState(prevState => ({
                    resendOTPTime: prevState.resendOTPTime - 1
                }))
            }, 1000);
        }
    }

    onSkip = () => {
        history.push(`/auction`)
    }

    onclickSubmit = () => {
        const { currentPage, phoneNo, checkTnC, loginInfo } = this.state;
        if (currentPage === "login") {
            if (phoneNo.length !== 10) {
                toast.error("Please Enter 10 digits Mobile Number");
            }
            else {
                const sendBody = {
                    "phoneNo": this.state.phoneNo
                };

                (async () => {
                    try {
                        const resp = await fireAjaxRequest(SIGNIN_API, {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(sendBody)
                        });
                        if (resp.phoneNo || resp.phoneNo === 0) {
                            this.resendTimeLimit(true);
                            this.setState({ currentPage: (resp.name ? "verifyOTP" : "signup"), resendOTPTime: 90 })
                            this.setState({ loginInfo: resp });
                        }
                    } catch (err) {
                        toast.error(err.message || "Failed to Login !");
                    }
                })();
            }

        }

        if (currentPage === "verifyOTP" || currentPage === "signup") {
            if (!checkTnC && !this.state.otp.length < 4) {
                if (currentPage === "signup" && !this.state.name) {
                    toast.error("Enter the User Name !")
                } else if (this.state.otp.length < 4) {
                    toast.error("Enter the complete OTP !")
                } else if (!checkTnC) {
                    toast.error("Need to agree Terms and Conditions !")
                }
            } else if (!this.state.name && !loginInfo.name) {
                toast.error("Enter the User Name !");
            } else if (!loginInfo.name && (this.state.name.length <= 3 || this.state.name.length >= 49)) {
                toast.error("User name length should be between 3-50 letters");
            } else {
                const sendOTPBody = {
                    "loginKey": loginInfo.loginKey,
                    "otp": this.state.otp,
                    "phoneNo": loginInfo.phoneNo,
                    "name": (loginInfo.name) ? loginInfo.name : this.state.name,
                    "referralMobileNumber": this.state.referralNo ? this.state.referralNo : null
                };
                (async () => {
                    try {
                        const resp = await fireAjaxRequest(OTP_API, {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",

                            },
                            body: JSON.stringify(sendOTPBody)
                        });

                        if (resp) {
                            clearInterval(this.timer);
                            if (resp.user.accountType === "ADMIN" || resp.user.accountType === "SUPER_ADMIN") {//IF THE USER IS ADMIN
                                sessionStorage.setItem("isAdmin", true);
                                history.push(`/auction`);
                                sessionStorage.setItem("userInfo", JSON.stringify(resp));
                                this.setState({ resendOTPTime: 90, otp: "" });
                            } else {//IF THE USER IS TRADER
                                sessionStorage.setItem("isAdmin", false);
                                sessionStorage.setItem("userInfo", JSON.stringify(resp));
                                this.getKYCDetails();
                                //this.setState({ currentPage: 'personalInfo', resendOTPTime: 30, otp: "" })
                            }

                        } else {
                            toast.error("Failed to Log in !");
                        }
                    } catch (err) {
                        toast.error("Your account has been disabled. Please write an email to sales@origoindia.com@origoindia.com for further clarifications." || "Failed to Log in  !");
                    }
                })();
            }


        }
        if (currentPage === "personalInfo") {
            //console.log("personal submiy")
        }
    }

    getKYCDetails = () => {
        let userId = JSON.parse(sessionStorage.getItem('userInfo')).user.id;
        (async () => {
            try {
                const resp = await fireAjaxRequest(`${SET_PERSONALINFO_API}/${userId}`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                        "Content-Type": "application/json"
                    },
                });
                if (resp) {
                    if (resp.kyc && resp.kyc.length > 0) {
                        history.push(`/auction`);
                    }
                    else {
                        this.setState({ currentPage: 'personalInfo', resendOTPTime: 90, otp: "" })
                    }
                } else {
                    toast.error("Failed to Log in !");
                }
            } catch (err) {
                toast.error(err && err.code !== 400 ? err.message : "Failed to Log in  !");
            }
        })();
    }

    onChangeName = (e) => {
        this.setState({ name: e.target.value })
    }

    renderLoginPage = () => {
        return (
            <div>
                <div className="loginText-cls">
                    <p id="text">Login <span>or</span> Signup</p>
                    <p id="subText">Please submit your phone number to continue</p>
                </div>
                <div className="userMobile-cls">
                    <div className="mobileNo-cls">
                        <p>+91 </p><CustomInputText value={this.state.phoneNo} config={{ onChange: this.onChangeMobileNo.bind(this), placeholder: 'Your 10 digit mobile number' }} />
                    </div>


                </div>
            </div>
        )
    }

    resendOTP = () => {
        const sendBody = {
            "phoneNo": this.state.phoneNo
        };
        (async () => {
            try {
                const resp = await fireAjaxRequest(SIGNIN_API, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(sendBody)
                });
                if (resp.phoneNo || resp.phoneNo === 0) {
                    this.resendTimeLimit(true);
                    this.setState({ loginInfo: resp, OTPvalue: ["", "", "", ""] });
                    let selected = document.getElementsByClassName("OTP-class");
                    selected[0].focus();
                }
            } catch (err) {
                toast.error("Failed to Login !");
            }
        })();
        this.setState({ resendOTPTime: 90, OTPvalue: ["", "", "", ""], disableOTP: false })
    }

    onClickContactUs = () => {
        this.setState({ showContanctUs: true })
    }

    hideContactUs = () => {
        this.setState({ showContanctUs: false })
    }
    onChangeOTP = (e, index) => {
        let { OTPvalue } = this.state;

        OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        let selected = document.getElementsByClassName("OTP-class");
        if (index <= 2 && e.target.value) {
            selected[index + 1].focus();
        } else {
            OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        }
        // this.setState({ value: value  });

        // if (this.props.handleOTPChange){
        this.handleOTPChange(OTPvalue, e, index)
        // }
    }
    renderOTPPage = () => {
        return (<div>
            <div className="loginText-cls">
                <p id="text">Verify <span>with</span> OTP</p>
                <p id="subText">Please verify your phone number</p>
            </div>
            <div className="verifyOTP-cls">
                <div className="otp-cls">
                    <p id="sendOTP">OTP Sent to {this.state.phoneNo} <span onClick={this.onChangeNumber.bind(this)}>Use different number</span></p>
                    <OTP label={"OTP"} value={this.state.OTPvalue} disableOTP={this.state.disableOTP} onKeyUp={this.onKeyUp.bind(this)} onChangeOTP={this.onChangeOTP.bind(this)} handleOTPChange={this.handleOTPChange.bind(this)} />
                    <p id="resendOTP">{this.state.resendOTPTime < 1 ? <p id="resend" onClick={this.resendOTP.bind(this)}>Resend OTP</p> : `Resend OTP in ${this.state.resendOTPTime} secs`}</p>
                </div>
            </div>
            <div className="moreDetails-cls">
                <div className="contactTerms-cls">
                    <p id="contactus">Having trouble here? <span onClick={this.onClickContactUs.bind(this)}>Contact Us</span></p>
                    <p id="termnCond"> <CustomCheckbox formatType="vertical" name="checkTnC"
                        value={this.state.checkTnC}
                        items={[{ label: 'By logging you agree to ', key: 'checkTnC', checked: this.state.checkTnC }]}
                        config={{
                            onChange: this.onClickTnCCheckBox.bind(this)
                        }}
                    />
                        {/* <span onClick={() => window.open("https://eauction.blob.core.windows.net/eauction/TermsAndConditions_04_05_2021_03_46_03_1620123363282.pdf")} >Terms and Conditions</span> */}
                        <span onClick={this.props.onTermsClick} >Terms and Conditions</span>
                    </p>
                </div>
            </div>
        </div>
        )
    }

    onChangeNumber = () => {
        clearInterval(this.timer);
        this.resendTimeLimit(false);
        this.setState({ resendOTPTime: 90, currentPage: "login", OTPvalue: ["", "", "", ""], disableOTP: false, phoneNo: "" });
        let selected = document.getElementsByClassName("OTP-class");
        selected[0].focus();
        this.setState({ referralNo: "", name: "" })
    }

    onClickTnCCheckBox = (e) => {
        this.setState({
            checkTnC: e.target.checked
        })

    }

    rendersignupPage = () => {
        return (<div>
            <div className="loginText-cls">
                <p id="text">Thanks for signing up</p>
                <p id="subText">Please submit your name and verify your phone number</p>
            </div>
            <div className="name-cls">
                <div className="text-cls">
                    <p>Your Name</p>
                    <CustomInputText value={this.state.name} config={{ onChange: this.onChangeName.bind(this), placeholder: 'Enter Name' }} />
                </div>
                <div className="referalNo-cls">
                    <p>+91 </p><CustomInputText value={this.state.referralNo} config={{ onChange: this.onchangeReferralMobileNo.bind(this), placeholder: 'Referral mobile number' }} />
                </div>
                <CustomButton label="Apply Referral" className="referral-btn-cls" onClick={this.onApplyreferralcode.bind(this)} />
            </div>
            <div className="verifyOTP-cls">
                <div className="otp-cls">
                    <p id="sendOTP">OTP Sent to {this.state.phoneNo} <span onClick={this.onChangeNumber.bind(this)}>Use different number</span></p>
                    <OTP label={"OTP"} value={this.state.OTPvalue} disableOTP={this.state.disableOTP} onKeyUp={this.onKeyUp.bind(this)} onChangeOTP={this.onChangeOTP.bind(this)} handleOTPChange={this.handleOTPChange.bind(this)} />
                    <p id="resendOTP">{this.state.resendOTPTime < 1 ? <p id="resend" onClick={this.resendOTP.bind(this)}>Resend OTP</p> : `Resend OTP in ${this.state.resendOTPTime} secs`}</p>
                </div>
            </div>
            <div className="moreDetails-cls">
                <div className="contactTerms-cls">
                    <p id="contactus">Having trouble here? <span onClick={this.onClickContactUs.bind(this)}>Contact Us</span></p>
                    <p id="termnCond"> <CustomCheckbox formatType="vertical" name="checkTnC"
                        value={this.state.checkTnC}
                        items={[{ label: 'By logging you agree to ', key: 'checkTnC', checked: this.state.checkTnC }]}
                        config={{
                            onChange: this.onClickTnCCheckBox.bind(this)
                        }}
                    />
                        <span onClick={this.props.onTermsClick}>Terms and Conditions</span>
                        {/* <span onClick={()=>window.open("https://eauction.blob.core.windows.net/eauction/TermsAndConditions_04_05_2021_03_46_03_1620123363282.pdf")}>Terms and Conditions</span> */}
                    </p>
                </div>
            </div>
        </div>
        )
    }

    handleOTPChange = (value, e, index) => {
        let { OTPvalue } = this.state;

        OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        let selected = document.getElementsByClassName("OTP-class");
        if (index <= 2 && e.target.value) {
            selected[index + 1].focus();
        } else {
            OTPvalue[index] = e.target.value.charAt(e.target.value.length - 1);
        }
        this.setState({ OTPvalue: value });

        let otp = value
        otp = otp.toString();
        otp = otp.replace(/,/g, "");
        this.setState({ otp: otp });
    }

    onKeyUp = (e, index) => {
        if (e.keyCode === 8 && index > 0) {
            let selected = document.getElementsByClassName("OTP-class");
            selected[index - 1].focus();
        }
    }

    onClickPersonalInfoSubmit = (data, state) => {
        const personalInfo = data;
        const emailverified = state.verifiedemailicon;
        const phoneNo = JSON.parse(sessionStorage.getItem("userInfo")).user.phoneNo;
        const parms = {
            id: JSON.parse(sessionStorage.getItem("userInfo")).user.id,
            name: personalInfo.name,
            phoneNo: phoneNo,
            email: emailverified ? personalInfo.email : "",
            accountType: personalInfo.accountType,
            orgName: personalInfo.accountType == "INDIVIDUAL_TRADER" ? "" : personalInfo.orgName,
            pincode: personalInfo.pincode,
            state: personalInfo.state,
            district: personalInfo.district,
            town: personalInfo.town,
            region: personalInfo.region,
            address: personalInfo.address,
        };
        // if (personalInfo.email == null) {
        //     toast.error("Please Provide Email Address");
        // }else 
        if (personalInfo.state && !personalInfo.address) {
            toast.error("Please Provide Address !");
        } else if (personalInfo.name.length <= 3 || personalInfo.name.length >= 50) {
            toast.error("User name length should be between 3-50 letters");
        }
        else if (personalInfo.email && !emailverified) {
            toast.error("Please veirfy your email address")
        }
        else {
            (async () => {
                try {
                    const resp = await fireAjaxRequest(SET_PERSONALINFO_API, {
                        method: 'PUT',
                        body: JSON.stringify(parms),
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        }
                    });
                    if (resp) {
                        clearInterval(this.timer);
                        const setUser = {
                            token: JSON.parse(sessionStorage.getItem("userInfo")).token,
                            user: resp
                        }
                        sessionStorage.setItem("userInfo", JSON.stringify(setUser));
                        toast.success("User Details are Updated");
                        this.setState({ currentPage: 'kycInfo' })
                    } else {
                        toast.error("Failed to send Personal Info !");
                    }
                } catch (err) {
                    toast.error("Failed to send Personal Info !");
                }
            })();
        }
    }

    onClickKYCInfoSubmit = () => {
        clearInterval(this.timer);
        history.push(`/auction`)
    }

    progressBar = () => {
        const { currentPage } = this.state;

        return (
            <div className="progreeBar-cls">
                <div className="mobileVer-cls" onClick={() => this.setState({ currentPage: "login" })}>
                    <div className="img-cls"><img src={tickGreen} alt="greenTick" /></div>
                    <div className="labelText-cls"><p>Mobile Verification</p></div>
                </div>
                <div className="nextArrow-cls">
                    <img src={arrowGrey} alt="arrowGrey" />
                </div>
                <div className="personalInfo-cls" style={
                    currentPage === "personalInfo" ? {
                        boxShadow: "0px 2px 5px #00000029",
                        borderRadius: 10,
                        padding: "0px 10px",
                        color: "#00000099",
                        fontSize: 14,
                        fontWeight: 'bold',
                    } : {
                            color: "#7DB851",
                            fontSize: 14,
                        }} onClick={() => this.setState({ currentPage: "personalInfo" })}>
                    <div className="img-cls"><img src={currentPage === "personalInfo" ? tickGrey : tickGreen} alt="greenTick" /></div>
                    <div className="labelText-cls"><p>Personal Info</p></div>
                </div>
                <div className="nextArrow-cls">
                    <img src={arrowGrey} alt="arrowGrey" />
                </div>
                <div className="kycInfo-cls" style={
                    currentPage === "kycInfo" ? {
                        boxShadow: "0px 2px 5px #00000029",
                        borderRadius: 10,
                        padding: "0px 10px",
                        color: "#00000099",
                        fontSize: 14,
                        fontWeight: 'bold',
                    } : {
                            color: "#D8D8D8",
                            fontSize: 14,
                        }} onClick={() => this.setState({ currentPage: "kycInfo" })}>
                    <div className="img-cls"><img src={kycIcon} alt="greenTick" style={currentPage === "kycInfo" ? { width: 12, height: 14 } : { width: 15, height: 15, color: '#D8D8D8' }} /></div>
                    <div className="labelText-cls"><p>KYC Information</p></div>
                </div>
            </div>
        )

    }

    getPersonalInfoDetails = (personalInfo) => {
        this.setState({ personalInfo })
    }

    render() {
        const { currentPage, resendOTPTime, personalInfo } = this.state;
        if (resendOTPTime < 1) {
            clearInterval(this.timer);
        }
        return (
            currentPage === "personalInfo" ?
                <>
                    {this.progressBar()}
                    <PersonalInfo currentPage={currentPage} onClickPersonalInfoSubmit={this.onClickPersonalInfoSubmit.bind(this)} onSkip={this.onSkip} getPersonalInfoDetails={this.getPersonalInfoDetails.bind(this)} />
                </> :
                currentPage === "kycInfo" ?
                    <>
                        {this.progressBar()}
                        <KYCInfo onClickKYCInfoSubmit={this.onClickKYCInfoSubmit.bind(this)} onSkip={this.onSkip} personalInfo={this.state.personalInfo} />
                    </> :
                    <div className="loginScreen-cls">
                        <div className="container">
                            {(currentPage === "login" || currentPage === "verifyOTP" || currentPage === "signup") && <div className="bgText-cls">
                                <ContactUsPopUp visible={this.state.showContanctUs} onHide={this.hideContactUs} onCancel={this.hideContactUs} />

                                <div className="text-cls">
                                    <div className="textHeader">
                                        {/* <img src={LOGO} alt=""/> */}
                                    </div>
                                    <p className="subHeader">
                                        <span>✓&nbsp;</span>
                                        An online Auction platform for traders to procure and sell commodities.
                                    </p>
                                    <p className="subHeader">
                                        <span>✓&nbsp;</span>
                                        Buyer/Supplier Registration- Online KYC for traders
                                    </p>
                                    <p className="subHeader">
                                        <span>✓&nbsp;</span>
                                        Transparent e-bidding.
                                    </p>
                                    <p className="subHeader">
                                        <span>✓&nbsp;</span>
                                        Access to real time information on delivery/pickup, quality and price of commodities.
                                    </p>
                                    <p className="subHeader">
                                        <span>✓&nbsp;</span>
                                        Access to online banking and payments.
                                    </p>
                                    <p className="subHeader">
                                        <span>✓&nbsp;</span>
                                        Ease of doing business through Mobile-App.
                                    </p>

                                    <div className="store-link-apple-play">
                                        <img onClick={() => { window.location = "https://play.google.com/store/apps/details?id=com.origo.auction" }} src={playStoreLogo} alt="playStoreLogo" />
                                        <img onClick={() => { window.location = "https://apps.apple.com/us/app/origo-auction/id1560836342" }} src={appleStoreLogo} alt="appleStoreLogo" />
                                    </div>

                                </div>
                                {/* <div className="bgImage-cls">
                                    <img src={bgImage} alt="bgImage" />
                                </div> */}
                            </div>}
                            <div className="loginContainer-cls">
                                <Scrollbars autoHide={false} style={{ width: "100%", height: '85vh' }} >

                                    <div className="loginSubContainer-cls">
                                        {
                                            currentPage === "login" ?
                                                this.renderLoginPage()
                                                :
                                                currentPage === "verifyOTP" ?
                                                    this.renderOTPPage()
                                                    :
                                                    currentPage === "signup" ?
                                                        this.rendersignupPage()
                                                        : ""
                                        }
                                        <div className="submitButton-cls">
                                            <div className="button-cls" style={{ paddingBottom: 40, marginTop: currentPage === "login" ? 180 : currentPage === "verifyOTP" ? 50 : 0 }}>
                                                {currentPage === "login" && <CustomButton label="SUBMIT" config={{ width: 100, height: 100, style: { opacity: this.state.phoneNo !== "" ? 1 : 0.5 }, onClick: this.state.phoneNo !== "" ? this.onclickSubmit.bind(this) : null }} />}
                                                {currentPage === "verifyOTP" && <CustomButton label="SUBMIT" config={{ width: 100, height: 100, style: { opacity: this.state.otp !== "" ? 1 : 0.5 }, onClick: this.state.otp !== "" ? this.onclickSubmit.bind(this) : null }} />}
                                                {currentPage === "signup" && <CustomButton label="SUBMIT" config={{ width: 100, height: 100, style: { opacity: this.state.otp !== "" ? 1 : 0.5 }, onClick: this.state.otp !== "" ? this.onclickSubmit.bind(this) : null }} />}
                                            </div>
                                        </div>


                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
        )
    }
}

export default Login;