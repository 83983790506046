import React, { Component } from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class DoughnutChart extends Component {
	constructor(props)
    {
		
        super(props);
		console.log("Props",this.props);
		this.IP=this.props.IP;
		this.UP=this.props.UP;
		this.RL=this.props.RL;
        this.state = {
        incompleteProposal : "",
        underProcess :  "",           
        recommendedLender:""
        }
		
		//this.setState({incompleteProposal:this.props.IP,underProcess:this.props.UP,recommendedLender:this.props.RL})
    }
	
	render() {
		const options = {
			animationEnabled: true,
			title: {
				text: ""
			},
			subtitles: [{
				text: "",
				verticalAlign: "center",
				fontSize: 24,
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
				showInLegend: false,
				indexLabel: "{}",
				innerRadius:"80%",
				yValueFormatString: "#,###'%'",
				dataPoints: [
					{ name: "Incomplete Proposals ",  y:   
					this.IP,color:"#F27B0E" },
					{ name: "Under Process", y: 
						this.UP,color:"#F5D405" },
					{ name: "Recommended to lender", y: 
						this.RL,color:"#04B23D" },
				]
			}]
		}
		
		return (
			<CanvasJSChart options = {options} 
			/>
		);
	}
}

export default DoughnutChart;