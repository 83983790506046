import React, { Component } from 'react';
import List from './view/list';
import { history, createDropDown } from "../../utils/Utility";
import { GET_AUCTION_LIST, SET_ALERT, COMMODITY_LOCATION_API } from "../../utils/Api";
import axios from "axios";
import { toast } from 'react-toastify';
import "./styles.scss";


export class UpcomingAuctions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setalertPopUp: false,
            formData: {},
            confirmPopup: false,
            dateType: [{ label: "Auctions Ending today", value: "Today" }, { label: "Auctions Ending tomorrow", value: "Tomorrow" }, { label: "Auctions Ending later", value: "Later" }],

        }
    }

    componentDidMount() {
        let Pathlocation = history.location.pathname;
        if (Pathlocation.includes("liveAuction")) {
            //Get Auction List
            let param = {
                "offerType": "All",
                "pagination": false,
                "auctionStatus": "Active"
            }

            axios.post(GET_AUCTION_LIST, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    console.log(response.data);
                    let activeAuctionList = [], scheduledAuctionList = [];
                    // response.data.items.map((element)=>{
                    //     if (element.status === "Active"){
                    //         activeAuctionList.push(element);
                    //     }else if (element.status === "Scheduled"){
                    //         scheduledAuctionList.push(element);
                    //     }
                    // })
                    this.setState({ activeAuctionList: response.data.items });
                }
            }).catch((error) => {
                console.log(error.response.data.message, 'error');
                toast.error(error.response.data.message);
            })

        }
        else {
            //Get scheduled auctions
            let upcomingparam = {
                "offerType": "All",
                "pagination": false,
                "auctionStatus": "Scheduled"
            }
            axios.post(GET_AUCTION_LIST, upcomingparam, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let activeAuctionList = [], scheduledAuctionList = [];
                    // response.data.items.map((element) => {
                    //     if (element.status === "Active") {
                    //         activeAuctionList.push(element);
                    //     }else if(element.status === "Scheduled"){
                    //         scheduledAuctionList.push(element);
                    //     } 
                    //     else {

                    //     }
                    // })
                    this.setState({ scheduledAuctionList: response.data.items });
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                // toast.error(error.response.data.message);
            })

        }



        //Commodity Location 
        axios.get(`${COMMODITY_LOCATION_API}?auctionStatus=${Pathlocation.includes("liveAuction")?"ACTIVE":"SCHEDULED"}`, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            // console.log(response);
            let commodityDropDownList = [], locationDropDownList = [], auctionTypeDropDown = [];
            if (response.status === 200) {
                let { formData } = this.state;
                // console.log(response.data)
                commodityDropDownList = createDropDown(response.data.data.commodities, "name", "id");
                response.data.data.locations && response.data.data.locations.map((element) => {
                    locationDropDownList.push({ "label": element, "value": element })
                })
                auctionTypeDropDown = [
                    { "label": "All", "value": "All" },
                    { "label": "Bid to Buy", "value": "Offer_To_Sell" },
                    { "label": "Bid to Sell", "value": "Offer_To_Buy" }
                ]
                formData.slider = [0, response.data.data.maxTotalQty]

                this.setState({ commodityDropDownList: commodityDropDownList, locationDropDownList: locationDropDownList, auctionTypeDropDown: auctionTypeDropDown, formData: formData });

            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
            // toast.error(error.response.data.message);
        })
    }
    onAuctionFilterChange = (formData) => {
        let Pathlocation = history.location.pathname;
        if (Pathlocation.includes("liveAuction")) {
            //Get Auction List
            let param = {
                "offerType": formData.auctionType,
                "pagination": false,
                "location": formData.locationName ? [formData.locationName] : [],
                "commodityId": formData.commodityName ? [formData.commodityName] : [],
                // "min": formData.sliderValue[0],
                // "max": formData.sliderValue[1],
                "auctionEndOn": formData.endOn,
                "auctionStatus": "Active"
            }

            axios.post(GET_AUCTION_LIST, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let activeAuctionList = [], scheduledAuctionList = [];
                    // response.data.items.map((element) => {
                    //     if (element.status === "Active") {
                    //         activeAuctionList.push(element);
                    //     } else {
                    //         scheduledAuctionList.push(element);
                    //     }
                    // })
                    this.setState({ activeAuctionList: response.data.items });
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                toast.error(error.response.data.message);
            })
        }
        else {
            // Get upcoming auctions filters
            let upcomingparam = {
                "offerType": formData.auctionType,
                "pagination": false,
                "location": formData.locationName ? [formData.locationName] : [],
                "commodityId": formData.commodityName ? [formData.commodityName] : [],
                // "min": formData.sliderValue[0],
                // "max": formData.sliderValue[1],
                "auctionEndOn": formData.endOn,
                "auctionStatus": "Scheduled"
            }

            axios.post(GET_AUCTION_LIST, upcomingparam, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let activeAuctionList = [], scheduledAuctionList = [];
                    // response.data.items.map((element) => {
                    //     if (element.status === "Active") {
                    //         activeAuctionList.push(element);
                    //     } else {
                    //         scheduledAuctionList.push(element);
                    //     }
                    // })
                    this.setState({ scheduledAuctionList: response.data.items });
                }
            }).catch((error) => {
                // console.log(error.response.data.message, 'error');
                toast.error(error.response.data.message);
            })
        }



    }

    onClickBack = () => {
        // history.push('/eAuction')
        history.push('/eAuction');
    }

    onClickRegister = () => {
        history.push('/register');
    }

    handelInputChange = (e) => {
        let { formData } = this.state;

        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });
        if (e.target.name === "auctionType" || e.target.name === "commodityName" || e.target.name === "locationName" || e.target.name === "endOn") {
            this.setState({ activeAuctionList: [], scheduledAuctionList: [] })
            this.onAuctionFilterChange(formData);
        }
    }

    onBidNowClick = (e) => {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        console.log(e)
        if (userInfo) {
            console.log(e)
            history.push(`/auction/auctionDetails/${e.id}`)
        } else {
            toast.error("Login to place bids")
            history.push('/register');
        }
    }

    onSetAlertClick = (e) => {
        
        let { formData, setAlertAuctionId } = this.state;
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        console.log(e)
        if (userInfo) {
            let param = {
                "name": userInfo.user.name || "Trader",
                "mobileNo": userInfo.user.phoneNo,
                "auctionId": e.id
            }

            axios.post(SET_ALERT, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let { formData } = this.state;
                    formData.dailogContend = ["Your preferred commodity auction alert has been set successfully. You will be notified via SMS before the auction goes live"]
                    this.setState({ confirmPopup: true, setalertPopUp: false, formData: formData })
                }
            }).catch((error) => {
                this.setState({ setalertPopUp: false });
                toast.error(error.response.data.message);
            })

        } else {
            this.setState({ setalertPopUp: true });
        }

        this.setState({ setAlertAuctionId: e.auctionId });
    }

    converttime(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    onSubmitsetalertPopUp = () => {
        let { formData, setAlertAuctionId } = this.state;
        if (!formData.setalertfullname) {
            toast.error("Please enter Name")
        }
        else if (!formData.setalertnumber) {
            toast.error("Please enter Mobile Number")
        }
        else if (formData.setalertnumber.length < 10) {
            toast.error("Phone Number should contain 10 digits")
        }
        else {
            const param = {
                "name": formData.setalertfullname,
                "mobileNo": formData.setalertnumber,
                "auctionId": setAlertAuctionId
            }

            axios.post(SET_ALERT, param, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    let { formData } = this.state;
                    formData.dailogContend = ["Your preferred commodity auction alert has been set successfully. You will be notified via SMS before the auction goes live"]
                    this.setState({ confirmPopup: true, setalertPopUp: false, formData: formData })

                }
            }).catch((error) => {
                this.setState({ setalertPopUp: false });
                toast.error(error.response.data.message);
            })

        }
    }

    onHideConfimPopUp = () => {
        this.setState({ confirmPopup: false });
    }

    onhideSetalert = () => {
        this.setState({ setalertPopUp: false })
    }

    render() {
        let Pathlocation = history.location.pathname;

        return (
            <List
                onClickBack={this.onClickBack}
                Pathlocation={Pathlocation}
                state={this.state}
                onBidNowClick={this.onBidNowClick}
                onSetAlertClick={this.onSetAlertClick}
                formData={this.state.formData}
                handelInputChange={this.handelInputChange}
                onClickRegister={this.onClickRegister}
                onhideSetalert={this.onhideSetalert}
                onSubmitsetalertPopUp={this.onSubmitsetalertPopUp}
                onHideConfimPopUp={this.onHideConfimPopUp}
                converttime={this.converttime}
            />
        )
    }
}

export default UpcomingAuctions
