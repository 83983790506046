import React, { Component } from 'react';
import PropTypes from "prop-types";

//@custom styles
import './style.scss';

//@class component
class CustomCheckbox extends Component {
    render() {
        const { id, name, label, items, config = {},labelFirst, checked, required, disabled, formatType, value } = this.props;

        return (
            <div className={"checkboxComponentCls"}>
                {label &&
                    <div className="label-cls">
                        <div className="required-cls">{required ? <b>*</b> : ""}</div>
                        <div className="labelText-cls"><p>{label}</p></div>
                    </div>
                }

                <div className={((formatType === "vertical") ? "verticalCheckCls" : "horizontalCheckCls")}>
                    {items ? items.map((item, index) => {
                        return (
                            <div className={"checkbox-cls"} key={index}>
                                {labelFirst ?
                                    <div  className={"checkbox-cls"}>
                                        {(item.name) ? <div className="label"><p>{item.name}</p></div> : ""}
                                        <div className="checkInput">
                                            <input id={id} type="checkbox" {...config}
                                                name={name.includes("permission") ? name : item.name || name}
                                                disabled={disabled || false} value={item.key}
                                                checked={(item && item.checked) || checked}
                                            />
                                            <span className="checkmark"></span>
                                        </div>

                                    </div>
                                    :
                                    <div  className={"checkbox-cls"}>
                                        
                                        <div className="checkInput">
                                            <input id={id} type="checkbox" {...config}
                                                name={name.includes("permission") ? name : item.name || name}
                                                disabled={disabled || false} value={item.key}
                                                checked={(item && item.checked) || checked}
                                            />
                                        </div>
                                        {(item.label) ? <div className="label"><p>{item.label}</p></div> : ""}
                                    </div>
                                }
                            </div>
                        )
                    })
                        :
                        <input id={id} type="checkbox" {...config}
                            disabled={disabled || false} value={value}
                            checked={checked}
                        />
                    }
                </div>
            </div>
        )
    }
}

//@for required/options props
CustomCheckbox.propTypes = {
    items: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    config: PropTypes.object,
    required: PropTypes.bool,
    formatType: PropTypes.string
};

export default CustomCheckbox;