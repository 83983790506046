import React, { useState, useEffect, useRef } from 'react';
import '../style/viewallprofin.css'
import adminPic from '../Images/adminPic.png'
import forwardArrow from '../Images/left_arrow.png';
import backArrowEResearch from '../Images/right_arrow.png';
import backArrow from '../../../assets/images/backArrowEResearch.svg'
import { history } from '../../../utils/Utility';
import $ from 'jquery';
import { BUYER_REQUEST_INDEX } from '../../../utils/Api';


function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
}
function ViewAll() {
    let array = [];
    const forceUpdate = useForceUpdate();

    const buyerDetails = () => {
        history.push("/buyerDetail");
    };
    const backHome = () => {
        history.push("/profin");
    };
    const offset = useRef(0);
    const limit = useRef(20);
    const totalRecords = useRef(0);
    const [buyerRequests, setBuyerRequests] = useState([]);
    const filteredRejectedCount = useRef(0);
    const filteredApprovedCount = useRef(0);
    const filteredDiscrepantCount = useRef(0);
    const currentStatus = useRef("");

    useEffect(() => {
        getBuyerRequests("", offset.current, limit.current)
    }, []);

    const getBuyerRequests = (e, off, lim) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
            );

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(BUYER_REQUEST_INDEX + "offset=" + off + "&limit=" + lim + "&status=" + e, requestOptions)
                .then(response => response.text())
                .then(result => {
                    result = JSON.parse(result);
                    setBuyerRequests(result.items);
                    totalRecords.current = (result.count)
                    forceUpdate();
                    filteredRejectedCount.current = (result.items?.filter((item) => item.status === 'REJECTED').length);
                    filteredApprovedCount.current = (result.items?.filter((item) => item.status === 'APPROVED').length);
                    filteredDiscrepantCount.current = (result.items?.filter((item) => item.status === 'DISCREPANT').length);
                    forceUpdate();
                }
                ).catch(error => console.log('error', error));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const filterStatus = (e) => {
        document.getElementById(e).className = "btn-green btn";
        $("#" + e).siblings().addClass("btn-normal btn");
        $("#" + e).siblings().removeClass("btn-green");
        currentStatus.current = "";
        if (e != "all") {
            offset.current = 0;
            currentStatus.current = e.toUpperCase();
            getBuyerRequests(e.toUpperCase(), offset.current, limit.current)
        }

        else {
            offset.current = 0;
            getBuyerRequests("", offset.current, limit.current)
        }
    };

    const onSearchChange = (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(BUYER_REQUEST_INDEX + "offset=" + offset.current + "&limit=" + limit.current + "&searchString=" + e.target.value, requestOptions)
            .then(response => response.text())
            .then(result => {
                result = JSON.parse(result)
                setBuyerRequests(result.items);
                forceUpdate();
            })
            .catch(error => console.log('error', error));
    }


    const handleFirstButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");
        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 0;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();
        getBuyerRequests("", offset.current, limit.current);
    }

    const handleSecondButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");

        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 20;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleThirdButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");
        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 40;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleFourthButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");
        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 60;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleFifthButton = (e) => {
        $(e.target).addClass("pagination-content-active");
        $(e.target).removeClass("pagination-content");

        $(e.target).siblings("button").removeClass("pagination-content-active")
        $(e.target).siblings("div").addClass("pagination-content")
        let off = 80;
        let lim = 20;
        offset.current = (off);
        limit.current = (lim);
        forceUpdate();

        getBuyerRequests("", offset.current, limit.current);
    }

    const handleLeftArrowButton = (e) => {
        if (offset.current >= 0) {
            offset.current = (offset.current - limit.current);
            limit.current = (limit.current);
            forceUpdate();
            getBuyerRequests(currentStatus.current, offset.current, limit.current);
            forceUpdate();
            $(".pagination-content-active").removeClass("pagination-content-active").addClass("pagination-content").prev("div").addClass("pagination-content-active").removeClass("pagination-content");
        }
    }


    const handleRightArrowButton = (e) => {
        if (totalRecords.current > offset.current + limit.current) {
            offset.current = (offset.current + limit.current);
            limit.current = (limit.current);
            forceUpdate();
            getBuyerRequests(currentStatus.current, offset.current, limit.current);
            forceUpdate();
            $(".pagination-content-active").removeClass("pagination-content-active").addClass("pagination-content").next("div").addClass("pagination-content-active").removeClass("pagination-content");
        }
    }

    return (
        <div className='profin-view-dashboard'>
            <div className='row topheader'>
                <div className="col">
                    <input
                        type="text"
                        className="searchTextBox-2"
                        placeholder="Search"
                        onChange={(e) => onSearchChange(e)}
                    />
                    <img src={adminPic} style={{}} className="pic" />
                </div>
            </div>
            <div className='row' style={{ marginLeft: '3px' }}>
                <div className="col-md-12 viewAllBg">
                    <div className='profin-breadcrumbs'>
                        <img style={{ cursor: "pointer" }} onClick={() => backHome()} src={backArrow} />
                        <div className='breads'
                        >
                            Home &gt; &nbsp;
                            <span style={{ color: "#04B23D" }}
                            >BuyerList</span>
                        </div>
                    </div>
                    <h1 style={{ fontSize: '20px', color: '#2F2F2F', marginBottom: '10px', marginLeft: '75px', marginTop: '20px', fontWeight: '600' }}>
                        Buyer List
                    </h1>
                    <span style={{ color: '#757575', marginLeft: '75px', fontSize: '14px', marginTop: '15px', fontWeight: '500', marginBottom: '20px' }}>
                        All buyer details will be visible here
                    </span>
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }} >
                <div className="col-md-12 col-sm-12 viewAll-1"
                >
                    <span class="button-section ">
                        <button onClick={(e) => filterStatus("all")} id="all" class="btn btn-green btn-lg"> All </button>
                        <button onClick={(e) => filterStatus("approved")} id="approved" class="btn-normal btn btn-lg"> Approved </button>
                        <button onClick={(e) => filterStatus("rejected")} id="rejected" class="btn-normal btn btn-lg"> Rejected </button>
                        <button onClick={(e) => filterStatus("discrepant")} id="discrepant" class="btn-normal btn btn-lg"> Discrepant </button>
                    </span>
                </div>
            </div>
            <div className="clearfix" style={{ clear: 'both' }}></div>
            <div className="tableCard" style={{ margintop: "160px" }}>
                <table className="table table-bordered table-striped table-width-1">
                    <thead>
                        <tr class='text-header'>
                            <th class="text-table" style={{ borderRadius: "16px 0px 0px 0px", alignItems: "center", justifyContent: "center" }}>
                                Id
                            </th>
                            <th class="text-table">
                                Name
                            </th>
                            <th class="text-table">
                                Request Date
                            </th>
                            <th class="text-table">
                                Approved Lim
                            </th>
                            <th class="text-table" style={{ borderRadius: "0px 16px 0px 0px", width: '220px' }}>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            buyerRequests == null ? <tr><td colSpan={5}>No Records Found</td></tr> : ""
                        }
                        {buyerRequests?.map((item, key) => (

                            <tr onClick={() => buyerDetails()}>
                                <td>
                                    {item.requestId ? item.requestId : "NA"}
                                </td>
                                <td>
                                    {item.name ? item.name : "NA"}
                                </td>
                                <td>
                                    {item.requestDate ? item.requestDate : "NA"}
                                </td>
                                <td>
                                    {item.approvedLimit ? item.approvedLimit : "NA"}
                                </td>
                                {item.status == 'INCOMPLETE' ? (
                                    <td>
                                        <button className={'statusIncomplete'}>
                                            {item.status}
                                        </button>
                                    </td>
                                )
                                    : item.status == 'UNDER_PROCESS' ? (
                                        <td>
                                            <button className={'statusBtnunderProcess'}>
                                                {item.status}
                                            </button>
                                        </td>
                                    ) :
                                        item?.status == 'DISCREPANT' ? (
                                            <td>
                                                <button className={'statusBtnunderProcess'}>
                                                    {item.status}
                                                </button>
                                            </td>
                                        ) : item?.status == 'APPROVED' ? (
                                            <td>
                                                <button className={'statusBtn'}>
                                                    {item.status}
                                                </button>
                                            </td>
                                        ) : item?.status == 'REJECTED' ? (
                                            <td>
                                                <button className={'statusRejected'}>
                                                    {item.status}
                                                </button>
                                            </td>
                                        ) : <td>--</td>}
                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>
            <div class="pagination-section">
                <span class="pagination-right">
                    <div class="pagination" >

                        {offset.current == 0 ?
                            <img src={backArrowEResearch}
                                alt="" className="left-vector" disabled />
                            : <img src={backArrowEResearch}
                                alt="" className="left-vector" onClick={(e) => handleLeftArrowButton(e)} />
                        }
                        {
                            totalRecords.current >= 0 ? <div class="pagination-content-active"
                                onClick={(e) => handleFirstButton(e)}>
                                1
                            </div> : ""
                        }
                        {
                            totalRecords.current >= 20 ? <div class="pagination-content" onClick={(e) => handleSecondButton(e)}
                            >2</div> : ""
                        }
                        {
                            totalRecords.current >= 40 ? <div class="pagination-content" onClick={(e) => handleThirdButton(e)}
                            >3</div> : ""
                        }
                        {
                            totalRecords.current >= 60 ? <div class="pagination-content" onClick={(e) => handleFourthButton(e)}
                            >4</div> : ""
                        }
                        {
                            totalRecords.current >= 80 ? <div class="pagination-content" onClick={(e) => handleFifthButton(e)}
                            >5</div> : ""
                        }
                        {
                            (offset.current == totalRecords.current - limit.current) ?
                                <img src={forwardArrow} alt="" className="left-vector" disabled />
                                : <img src={forwardArrow} alt="" className="left-vector" onClick={(e) => handleRightArrowButton(e)} />
                        }
                    </div>
                </span>
            </div>

        </div>

    );
};

export default ViewAll;
