import React, { Component } from 'react';
import tickerData from "./tickerData.json";
import Marquee from "react-fast-marquee";
import "./ticker.scss";
import axios from "axios";
import { SPOT_TICKER, MCX, NCDEX } from "../../../utils/Api";
import { RadioButton } from 'primereact/radiobutton';
import { numberWithCommas } from '../../../utils/Utility';
import { Client } from '@stomp/stompjs';

class TickerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: true,
            direction: "left",
            spotInfo: [],
            type: "S",
            serverTime: [],
            mcx: [],
            ncdex: [],
        }
    }

    componentDidMount() {
        // let eventSource = new EventSource("http://eauctiondevservice.walkingtree.tech/origo/stream/tiker");
        //     eventSource.onmessage = (event) => {
        //         const usage = JSON.parse(event.data);
        //         console.log(usage)
        //     }
        // let headers = {
        //     "Content-Type": "application/json"
        // };
        // let eventSource = new EventSource("http://eauctiondevservice.walkingtree.tech/origo/stream/tiker", {headers});
        // eventSource.onmessage = (event) => {
        //     const usage = JSON.parse(event.data);
        //     console.log(usage)
        // }
        // eventSource.onerror = (err) => {
        //     console.error("EventSource failed:", err);
        //     eventSource.close();
        // }
        // var source = new EventSource("demo_sse.php");
        // source.onmessage = function(event) {
        //     // document.getElementById("result").innerHTML += event.data + "<br>";
        //     console.log(event,"data")
        // };

        this.getSpotInfo();
        // this.getDerivativesData();
        this.getAllData();
        this.onloadData();

    }
    onloadData = async () => {
        try {
            const resp = await axios.get(MCX);
            // console.log(resp.data);
            if (resp) {
                this.setState({ mcx: resp.data });
            }
            const response = await axios.get(NCDEX);
            // console.log(response.data);
            if (response) {
                this.setState({ ncdex: response.data });
            }
        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    getAllData = () => {
        // console.log('Component did mount');
        this.client = new Client();
        this.client.configure({
            // brokerURL: 'ws://115.247.13.130:8088/stomp',
            brokerURL: 'ws://service.origoemandi.com:8088/stomp',
            onConnect: () => {
                // console.log('onConnect');
                this.client.subscribe('/queue/mcx', message => {
                    //   console.log(message);
                    let body = JSON.parse(message.body || "[{}]");
                    this.setState({ mcx: body });
                });
                this.client.subscribe('/queue/ncdex', message => {
                    //   console.log(message);
                    let body = JSON.parse(message.body || "[{}]");
                    this.setState({ ncdex: body });
                });

            },
            // Helps during debugging, remove in production

        });

        this.client.activate();
    }
    // getDerivativesData=()=>{
    //     console.log('Component did mount');
    //     this.client = new Client();
    //     this.client.configure({
    //       brokerURL: 'ws://115.247.13.130:8088/stomp',
    //       onConnect: () => {
    //         console.log('onConnect');
    //         this.client.subscribe('/queue/now', message => {
    //           console.log(message.body);
    //           let body=JSON.parse(message.body || "[{}]");
    //           this.setState({serverTime: body});
    //         });


    //         this.client.subscribe('/topic/greetings', message => {
    //         //   alert(message.body);
    //         });
    //       },
    //       // Helps during debugging, remove in production
    //       debug: (str) => {
    //         // console.log(new Date(), str);
    //       }
    //     });

    //     this.client.activate();
    // }
    // clickHandler = () => {
    //     this.client.publish({destination: '/app/greetings', body: 'Hello world'});
    //   }

    handelInputChange = (e) => {
        this.setState({ type: e.value });
    }

    getSpotInfo = () => {
        axios.get(SPOT_TICKER, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.status === 200) {
                let spotInfo = response.data.data;
                this.setState({ spotInfo: spotInfo });
                // console.log(spotInfo)
                setTimeout(function () {
                    this.getSpotInfo();
                }, 3000 * 60 * 30); //sec min no._of_min
            }
        }).catch((error) => {
            // console.log(error.response.data.message, 'error');
        })
    }

    render() {

        let { play, direction, spotInfo, serverTime, mcx, ncdex } = this.state;

        return (<div className="landing-page-ticker">
            <div className="radio-btns">
                <div className='left-radio-btns'>
                    <div className='spot-label'>SPOT</div>
                    <div className="p-field-radiobutton">
                        <RadioButton inputId="S" name="filterRadio" value="S" onChange={this.handelInputChange} checked={this.state.type === "S"} />
                        <label htmlFor="CR">e-Mandi</label>
                    </div>
                </div>
                <div className='right-radio-btns'>
                    <div className='future-label'>FUTURES</div>
                    <div className='radios-btns-cls'>
                        <div className="p-field-radiobutton">
                            <RadioButton name="filterRadio" value="N" onChange={this.handelInputChange} checked={this.state.type === "N"} />
                            <label htmlFor="CR">NCDEX</label>
                        </div>
                        <div className="p-field-radiobutton">
                            <RadioButton inputId="D" name="filterRadio" value="D" onChange={this.handelInputChange} checked={this.state.type === "D"} />
                            <label htmlFor="A">MCX</label>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.type == "D" ? <Marquee gradient={false} direction={direction} play={play}>
                {mcx && mcx.length ? mcx.map((element) => {
                    return (
                        <div className="ticker-warpper">
                            <div className="ticker-header">
                                <p className="commodity-name">{element.symbol}</p>
                                <p className="location">{element.expiry}</p>
                            </div>
                            <div className="ticker-footer">
                                <p className="source">{numberWithCommas(element.ltp)}</p>
                                <p className={`upDown ${element.change > 0 ? "green" : "red"}`}>{element.change > 0 ? <i className="pi pi-caret-up"></i> : <i className="pi pi-caret-down"></i>}{element.change > 0 ? `+${numberWithCommas(element.change)}` : numberWithCommas(element.change)}</p>
                            </div>
                        </div>
                    )
                }) : <p className='loader'>Loading...</p>}
            </Marquee> : this.state.type == "N" ? <Marquee gradient={false} direction={direction} play={play}>
                {ncdex && ncdex.length ? ncdex.map((element) => {
                    return (
                        <div className="ticker-warpper">
                            <div className="ticker-header">
                                <p className="commodity-name">{element.symbol}</p>
                                <p className="location">{element.expiry}</p>
                            </div>
                            <div className="ticker-footer">
                                <p className="source"> {numberWithCommas(element.ltp)}</p>
                                <p className={`upDown ${element.change > 0 ? "green" : "red"}`}>{element.change > 0 ? <i className="pi pi-caret-up"></i> : <i className="pi pi-caret-down"></i>}{element.change > 0 ? `+${numberWithCommas(element.change)}` : numberWithCommas(element.change)}</p>
                            </div>
                        </div>
                    )
                }) : <p className='loader'>Loading...</p>}
            </Marquee> : <Marquee gradient={false} direction={direction} play={play}>
                {spotInfo && spotInfo.length ? spotInfo.map((element) => {
                    return (
                        <div className="ticker-warpper">
                            <div className="ticker-header">
                                <p className="commodity-name">{element.commodityName}</p>
                                <p className="location">{element.location}</p>
                            </div>
                            <div className="ticker-footer">
                                <p className="source">{numberWithCommas(element.spotPrice.toFixed(2))}</p>
                                <p className={`upDown ${element.upDown > 0 ? "green" : "red"}`}>{element.upDown > 0 ? <i className="pi pi-caret-up"></i> : <i className="pi pi-caret-down"></i>}{element.upDown > 0 ? `+${numberWithCommas(element.upDown)}` : numberWithCommas(element.upDown)}</p>
                            </div>
                        </div>
                    )
                }) : <p className='loader'>Loading...</p>}
            </Marquee>}

            <div className="ticker-control">
                <i className="pi pi-chevron-left" onClick={() => { this.setState({ direction: "left" }) }}></i>
                {this.state.play == true ? <i className="pi pi-pause" onClick={() => { this.setState({ play: !play }) }}></i> : <i className="pi pi-play" onClick={() => { this.setState({ play: !play }) }}></i>}
                <i className="pi pi-chevron-right" onClick={() => { this.setState({ direction: "right" }) }}></i>
            </div>

        </div>);
    }
}

export default TickerComponent;