import React, { useEffect, useState } from 'react'
import '../style/Header.css'
import SearchGif from '../../../assets/images/search_gif_image.png';
import Admin from './Admin';
import { LENDER_SELECTION, ADDITIONAL_DOCS } from "../../../utils/Api"
import AdditionalDocuments from './uploadAdditionalDocuments'
import Congratulations from './congratulations';
import { toast } from 'react-toastify';
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}
function Gif() {
  const forceUpdate = useForceUpdate();
  const [additionalDocsState, setAdditionalDocsState] = useState(false)
  const [dataContent, setDataContent] = useState([])
  const [lenderSelected, setLenderSelected] = useState(false)
  const [isLenderAssigned, setLenderAssigned] = useState(0)
  var traderId = sessionStorage.getItem("traderID");
  var ecashRequestId = sessionStorage.getItem("requestID");
  const orgType = sessionStorage.getItem("orgType").toUpperCase();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        setIsLoading(true);
        var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        const response = await fetch(LENDER_SELECTION + "ecashRequestId=" + ecashRequestId + "&traderId=" + traderId, requestOptions)
        const result = await response.json();
        additionalDocs(result?.assignedLenderId)
        if (isMounted) {
          setData(result);
          setIsLoading(false);
          alert(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    }
    if (!isLoading) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };

  }, [isLoading,additionalDocsState,dataContent]);

  const additionalDocs = (lenderId) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

     fetch(ADDITIONAL_DOCS + "lenderId=" + lenderId + "&ecashLimit=75Lakh&organizationType=" + orgType, requestOptions)
    //fetch(ADDITIONAL_DOCS + "lenderId=1&ecashLimit=75Lakh&organizationType=COMPANY", requestOptions)
      .then(response => response.text())
      .then(result => {
        setAdditionalDocsState(true)
        result = JSON.parse(result)
        setDataContent(result?.additionalDocs)

      }
      )
      .catch(error => console.log('error', error));
  }
  return (
    additionalDocsState == false ?
      <>
        <div className='row '>
          <div className='col-md-12' style={{ textAlign: "center" }}>
            {/* <Admin /> */}
            <nav aria-label="" style={{ paddingLeft: '10px', float: 'left' }}>
              {/* <div className="container " >
       <p className="backbtn"
       >
       <i className="fa fa-arrow-left"></i>dataContent
       <span style={{ color: '#757575' }} className='breadcrumbsLabel'>&nbsp; Back &nbsp;</span>
       </p>
       </div> */}
            </nav>
            <div class="image-style">
              <img src={SearchGif} />
            </div>
            <div className='review-document'>
              We're reviewing your documents to assign  your limit.
            </div>
          </div>
        </div>
      </>
      : dataContent != undefined ? <AdditionalDocuments dataContent={dataContent} orgType={orgType} /> : <Congratulations />
  )
}

export default Gif