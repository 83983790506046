import React, { Component } from 'react';
import CustomDialog from "../../../../components/CustomDialog";
import Spinner from "../../../../components/Spinner";

class UploadDailog extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { status, onHide, visible, onCancel, onAccept } = this.props;
        return (
            <div className="uploadImageDialog-cls">
                <CustomDialog className="uploadDailog-cls" width={"25vh"} visible={visible} onHide={onHide} btn1={"CLOSE"} btn2={"RETRY"} onAccept={() => { }} onCancel={() => { }}>
                    <Spinner />
                    <div className="header1-cls">
                        <p>Getting image data</p>
                    </div>
                    <div className="header2-cls">
                        <p>Please check the values, once done.</p>
                    </div>
                </CustomDialog>
            </div>
        );
    }
}

export default UploadDailog;