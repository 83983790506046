import React, { useState } from 'react'
import '../style/Header.css'
import profilePic from '../Images/profilePic.PNG';
import dropdownIcon from '../../../assets/icons/dropdown_icon.png';
import image_congratulations from '../../../assets/images/image_congratulations.png';
import TwoCrore from './two_crore';

function Congratulations() {
  const [twoCrore, setTwoCrore] = useState(false);

  const handleOnClick = () => {
    setTwoCrore(true)
  }
  return (
    <>
      {twoCrore == false ?
        //   <div className='body-css'>
        //   <div className="bottom-section">
        //     <div className="entity-css">
        //   <div className='row'>
        //     <div className='col-md-12' style={{textAlign:"center"}}>

        //       <img src={image_congratulations_two_crore} class="image-css" style={{width:"400px"}} />
        //       <div class="doc-css" style={{marginTop:"0px"}}>
        //        Congratulations 
        //       </div>
        //       <div class="our-team">
        //         You have been sanctioned a
        //       </div>
        //       <div class="increase-limit" style={{marginTop:"10px"}}>
        //       <span className='greenLabel'>Loan Limit of &#8377;2 Cr</span>
        //       </div>
        //   </div>

        //     </div>
        //   </div>
        //   </div>
        // </div>
        <div className='row ' style={{overflow:"auto"}}>
          <div className='col-md-12' style={{ textAlign: "center" }}>
            {/* <img src={profilePic} className="pic" />
        <span className='headerLabel'>Admin</span> */}
            <div class="your-request">
              Your request for <span className='greenLabel'>₹2Cr</span> is in progress! <br />
            </div>
            <img src={image_congratulations} class="image-css" />
            <div class="doc-css">
              We're reviewing your documents.
            </div>
            <div class="our-team">
              Oue team will be in touch with you within 48 Hours
            </div>
          
          </div>
        </div>
        : <TwoCrore />}
    </>
  )
}

export default Congratulations