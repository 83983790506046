import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import "./index.scss";
class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<div className="terms-cls">
            <Scrollbars autoHide={false} style={{ width: "100%", height: '80vh' }} >
                <p className="heading">Disclaimer</p>
                {/* <p className="label">1.<span className="sub-label-cls">Preamble</span></p>
                <p className="Content">On the home screen there will be a link text as "Disclaimer". On the click of same a new tab will open which displays the content of the disclaimer.  </p> */}

             
                    <p>This Report has been prepared by Origo Commodities India Private Limited. This report does not constitute an offer or solicitation for the purchase or sale of any commodity or as an official confirmation of any transaction. The information contained herein is from publicly available data or other sources believed to be reliable. This report is provided for assistance only and is not intended to be and must not alone be taken as the basis for an investment or business decision. The user assumes the entire risk of any use made of this information. Each recipient of this report should make such investigation as it deems necessary to arrive at an independent evaluation of an investment/business decision in the agro commodities and determine the merits and risks of such business decision.</p>

                    <p>This information is strictly confidential and is being furnished to you solely for your information. This information should not be reproduced or redistributed or passed on directly or indirectly in any form to any other person or published, copied, in whole or in part, for any purpose. This report is not directed or intended for distribution to, or use by, any person or entity who is a citizen or resident of or located in any locality, state, country or other jurisdiction, where such distribution, publication, availability or use would be contrary to law, regulation or which would subject OCIPL and associates / group companies to any registration or licensing requirements within such jurisdiction. The distribution of this report in certain jurisdictions may be restricted by law, and persons in whose possession this report comes, should observe, any such restrictions.</p>

                    <p> The information given in this report is as of the date of this report and there can be no assurance that future results or events will be consistent with this information. This information is subject to change without any prior notice. OCIPL reserves the right to make modifications and alterations to this statement as may be required from time to time. OCIPL or any of its associates / group companies shall not be in any way responsible for any loss or damage that may arise to any person from any inadvertent error in the information contained in this report.</p>

                    <p> OCIPL is committed to providing independent and transparent recommendation to its clients. Neither OCIPL nor any of its associates, group companies, directors, employees, agents or representatives shall be liable for any damages whether direct, indirect, special or consequential including loss of revenue or lost profits that may arise from or in connection with the use of the information.</p>

                    <p> Our proprietary trading businesses may make investment decisions that are inconsistent with the recommendations expressed herein. Past performance is not necessarily a guide to future performance. The disclosures of interest statements incorporated in this report are provided solely to enhance the transparency and should not be treated as endorsement of the views expressed in the report.</p>

                    <p>  OCIPL and its associates, officer, directors, and employees, research analyst (including relatives) worldwide may: (a) from time to time, buy or sell the commodities thereof mentioned herein or (b) be engaged in any other transaction involving such commodities and earn commissions/charges or other compensation or act as advisor or lender/borrower or have other potential/material conflict of interest with respect to any recommendation and related information and opinions at the time of publication of research report or at the time of public appearance.</p>

                    <p> This report is disseminated to all the clients simultaneously, not all clients may receive this report at the same time. We will not treat recipients as clients by virtue of their receiving this report.</p>

                    <p>   This report should not be construed as invitation or solicitation to do business with Origo Commodities India Private Limited. The views expressed in this report may not be suitable for all businessmen/traders/commodity dealers etc.</p>
                    <p>   Trading in commodities are subject to market risks, read all the related documents carefully before taking any business decision.</p>

            </Scrollbars>
        </div>);
    }
}

export default Disclaimer;