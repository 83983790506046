import React from "react";
import PropTypes from "prop-types";

//@primereact components
import { Button } from 'primereact/button';

//@custom styles
import "./styles.scss";

//@functional component
const CustomButton = ({ label, config, onClick, className,customOnKeyPress = null, formName = null, }) => {
    return (
        <div className="button-content-section">
            <Button label={label} className={`p-button-rounded custom-btn-cls ${className || ""}`} 
                onClick={onClick} {...config}
            />
        </div>
    );
};

//@for required/options props
CustomButton.propTypes = {
    label: PropTypes.string,
    config: PropTypes.object,
    onClick: PropTypes.func,
    className:PropTypes.string
};


export default CustomButton;