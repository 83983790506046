import React, { useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { FILTER_TABLE_RECORDS_API } from '../../utils/Api';
import './buyer.css';
import Loader from "../../components/CustomLoader";

var pan = '';
var id = '';
var X = [];

export default function ExistingBuyerPage() {


  const [isLoading, setLoader] = useState(false);
  const [data, setData] = useState([])
  const [llpdata, setllpData] = useState([])
  const [name, setName] = useState('')
  const [Item, setItem] = useState([]);
  const [cin, setCin] = useState()
  const [type, setType] = useState()
  const [page, setPage] = useState(true);

  var companyArray = [];
  var llpArray = [];


  const history = useHistory();
  const newbuyerPage = () => {
    // history.push("/BuyerUnderwriting/newuser");
    history.push("/newbuyer");

  }

  const handlePanChange = (e) => {
    // e.preventDefault();
    // setItem([]);
    setLoader(false);
    pan = document.getElementById("buyerpan").value;
    console.log(pan);
  }

  const buyerNameChange = (e) => {

    // e.preventDefault();
    //  setItem([]);
    setLoader(false);
    // console.log(e.target.value);
    // let wait=false;
    setName(e.target.value);

    var n = document.getElementById("buyername").value;
    // console.log(n);
    var token = "Ovc0NQth3YaF04YDwJp9c5ks7b5MLD3v1ZuHKHpW";

    var searchItem = "%7B%22nameStartsWith%22%3A%22" + n + "%22%7D";
    var sandbox_url = "https://api.probe42.in/probe_pro_sandbox/entities?limit=25&filters=" + n;
    var prod_url = "https://api.probe42.in/probe_pro/entities?limit=25&filters=" + searchItem;
    var sandbox_key = "Ovc0NQth3YaF04YDwJp9c5ks7b5MLD3v1ZuHKHpW";
    var prod_key = "qjTYIaIuhF6LR0FJpR0iHTSQB9j5gyu8HaWBEUie";

    if (name.length > 2) {
      // if(wait==true){
      fetch(sandbox_url, {
        method: 'GET',
        headers: {
          'x-api-key': sandbox_key,
          "x-api-version": "1.3",
          'Accept': 'application/json',
        },



      }).then(resp =>
        resp.json()).then(res => {
          res.data.entities.companies.map((item) => {
            var obj = { cin: item.cin, legal_name: item.legal_name }
            companyArray.push(obj);
          })
          setData(companyArray);

          res.data.entities.llps.map((item) => {
            var obj = { llpin: item.llpin, legal_name: item.legal_name }
            llpArray.push(obj);
          })
          setllpData(llpArray);
        })

      var buyerName = e.target.value;
      var obj = data.find(x => x.legal_name === buyerName);
     try{

     if (obj != undefined) {
        setType("Company");
        setCin(obj.cin);
      }
      else {
        var obj = llpdata.find(x => x.legal_name === buyerName);
        if (obj != undefined) {
          setCin(obj.llpin);
          setType("LLP")
        }
        else {
          setCin("");
        }
      }
    }catch(e){
      toast.error(`No data avaailable for Buyer Name - ${name}`)
    } 
    }
    // }
  }

  const getBuyerCreditLimit = (e) => {

    if (pan == '' || name == '') {
      toast.error("Buyer Name and PAN is required.")
    }
    else {

      setItem([]);
      setLoader(true);
      // document.getElementById("buyerDetails").style.display = "none";
      e.preventDefault();

      const buyerData_01 = {
        "dataform": "mi8ga50exi",
        "filters": [
          {
            "field": "pan",
            "op": "values",
            "values": [
              pan
            ]
          }
        ],
        "getfieldvalues": false,
        "use_field_slug": true
      }
      try {


        fetch(FILTER_TABLE_RECORDS_API, {
          method: 'PUT',
          body: JSON.stringify(buyerData_01),
          headers: {
            'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
            "Content-Type": "application/json"
          },
        }).then(resp =>
          resp.json()).then(res => {
            console.log(res);
            var x = JSON.parse(JSON.stringify(res.objects[0]));
            // console.log(x.fields.name);
            id = x.id
            // console.log(id);
            // setItem(x);

            {
              x.fields.name != name ?

                msg()

                :
                //   setItem(res.objects);
                // setLoader(false);
                buyerData_02(id);
            }


          }).catch(err => {
            console.log(err);
            // if(err=="ERR_CONNECTION_TIMED_OUT"||"Failed to fetch"){
            //   toast.error("Network connection problem - please check your connection");
            // } 
            // else if(err === "syntax error")
            setLoader(false);
            setItem([]);
            setPage(false);
    
            setTimeout(() => {
              // console.log('Hello, World!')
              window.location.reload();
            }, 5000);
            toast.error(`No Record Available For Pan Value - ${pan}`);
          })
      }
      catch (err) {
        if (err == "ERR_CONNECTION_TIMED_OUT" || "Failed to fetch") {
          toast.error("Network connection problem - please check your connection");
        }
        else if (err === "syntax error")
          toast.error(`No Record Available For PAN Value - ${pan}`);
        setLoader(false);
        setItem([]);
        setPage(false);

        setTimeout(() => {
          // console.log('Hello, World!')
          window.location.reload();
        }, 5000);
        // setItem([]);
        // setLoader(false);
      }

      function buyerData_02(id) {
        setItem([]);
        X = [];

        const dataBody =
        {
          "dataform": "hi5i5sqmc8",
          "dbvalues": {
            "buyer": id
          },
          "filters": [
            {
              "field": "buyer",
              "op": "values",
              "values": [
                id
              ]
            }
          ],
          "getfieldvalues": false,
          "use_field_slug": true
        }
        console.log(dataBody);
        fetch(FILTER_TABLE_RECORDS_API, {
          method: 'PUT',
          body: JSON.stringify(dataBody),
          headers: {
            'Authorization': "ApiKey pratibha.jain:e3e06e74ba441e14a778434f916357b9274d11b5",
            "Content-Type": "application/json"
          },
        }).then(resp =>
          resp.json()).then(res => {
            // console.log(res);
            X.push(res.objects[0])
            console.log(X[0]);

            setItem(res.objects);
            setLoader(false);

          }).catch(err => { toast.error("Connection Intrupted - Check your Internet connection") })


      }
    }


  }
  function msg() {
    setLoader(false);
    setItem([]);
    setPage(false);

    setTimeout(() => {
      // console.log('Hello, World!')
      window.location.reload();
    }, 5000);
    toast.error(`Invalid PAN - ${pan} for Buyer Name - ${name} `)
    toast.info("Please enter correct Buyer Name and PAN Number.")
  }

  return (


    <div>
      <div className="d-flex mt-4 mx-3  flex-md-row">
        <div className="p-2 flex-grow-1 ">
          &nbsp;&nbsp;&nbsp;&nbsp;<span className="glyphicon glyphicon-arrow-left" style={{ color: "#5cb85c" }}></span>&nbsp;
          {/* <button className='border-none' onClick={redirectPage}><strong>Back</strong></button> */}
          <Link to="/BuyerUnderwriting" className="link-dark"><strong>Back</strong></Link>
        </div>
        <div className="p-2">
          <button className="btn btn-success btn-md" type="button" onClick={newbuyerPage}><strong>New Buyer</strong></button>
        </div>
        <div className="p-2">
          <button className="btn btn-success btn-md " type="button"><strong>Existing Buyer</strong></button>
        </div>
        <hr />
      </div>
      <div className='border-bottom my-4 mx-5'>    </div>
      <div className='mx-5 '>
        <h3><strong>Existing Buyer</strong></h3>
        <form className="row g-3 mt-5 needs-validation" noValidate>
          <div>
            <div className="col-md-4">
              <label for="buyername" className="form-label">Enter Buyer Name</label>
              <input autocomplete="off" value={name} list="entities" className="form-control" placeholder='Enter Buyer Name' id="buyername" name='entities' onChange={buyerNameChange} required />
              <p className='mt-1'>To be populated from Orgzit</p>
              <datalist id="entities">
                {
                  data.map((item) => (
                    <option value={item.legal_name}>{item.cin}</option>
                  ))

                }

                {
                  llpdata.map((item) => (
                    <option value={item.legal_name}>{item.llpin}</option>
                  ))
                }
              </datalist>
            </div>
          </div>
          <div>
            <div className="col-md-4 mt-4">
              <label for="buyername" className="form-label">Enter PAN Number</label>
              <input type="text" className="form-control" placeholder='BETPBXXXXXX' id="buyerpan" name='buyername' onChange={handlePanChange} required />
              <div className=''></div>
            </div>
          </div>
          <div className='mx-4 mt-5'>
            <button type="button" className="btn btn-success btn-lg px-5" data-bs-toggle="modal" data-bs-target="#buyerDetails" onClick={getBuyerCreditLimit}>Get Buyer Limit Details</button>

          </div>
        </form>
      </div>


      {/* Modal for existing Buyer Limit */}
      {
        ((name == '' && pan == '') || name == '' || pan == '') || page == false ? " " :
          // Item == [] ? setLoader(false)
          //   :
            <div className="modal" id="buyerDetails" >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <p><h2><strong>Limit Details</strong></h2></p>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setItem([]); setLoader(false) }}></button>
                  </div>
                  <Loader loading={isLoading}>
                    <div className="modal-body">
                      <div className="card card-body mt-4 ml-2 ">
                        {
                          Item.map((item) => {
                            // console.log("insurance limit", item)
                            return (
                              <>
                                <div className='py-2'>
                                  <h4>{item.fields._title}</h4>
                                </div>
                                <div className='py-2'>
                                  <span>Insurance limit</span>
                                  <p><strong>{item.fields.insurance_limit}</strong></p>
                                </div>
                                <div className='py-2'>
                                  <span>Credit Limit Tolerance</span>
                                  <p><strong>{item.fields.credit_limit_tolerance}</strong></p>
                                </div>
                                <div className='py-2'>
                                  <span>Internal Credit Limit </span>
                                  <p><strong>{item.fields.approval_credit_limit}</strong></p>
                                </div>
                                {/* <div>
                            <hr className='hr-existingBuyer py-2' />
                            <p><span><strong>Total Credit Limit : </strong></span>
                              <span className='pull-right'><strong>{item.fields.approved_credit_limit_with_tolerance}</strong></span></p>
                            <hr />
                          </div> */}
                              </>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Loader>


                </div>
              </div>
            </div>
      }


      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous" />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
      <title>Origo e-Mandi</title>
      <script src=
        "https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.0/sweetalert.min.js">
      </script>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"
      />



    </div>

  )
}
