import React, { Component } from 'react'
import CustomInputText from '../../../components/CustomInputText'
import { RadioButton } from 'primereact/radiobutton';
import Scrollbars from 'react-custom-scrollbars';
import CustomButton from '../../../components/CustomButton';
import uploadIcon from '../../../assets/images/ic_upload.svg'
import eMandiCash from '../../../assets/images/eMandi Cash.svg'
import PopUp from './PopUp';
import ViewDocumentPopUp from './ViewDocumentPopUp'
import { Checkbox } from 'primereact/checkbox';

class List extends Component {
    render() {
        let { currentPage, formData, handelInputChange, state, onClickContinue, onClickFile, onClickKycContinue, onTermsChecked, onClickcashlimitContinue, onClickBack, onClickbusinessKycContinue, onHidePopup, onAccept, onclickview, onClicksucessContinue, onviewFile, onTermsClick } = this.props
        return (
            <div className='emandi-page-cls'>
                <PopUp visible={state.showPopUp} onHidePopup={onHidePopup} popupData={state.popupData} onAccept={onAccept} filelocation={state.filelocation} />
                <ViewDocumentPopUp visible={state.viewdocumentpopup} onHidePopup={onHidePopup} filelocation={state.filelocation} />
                <Scrollbars autoHide={false} style={{ width: "100%", height: "76vh" }}>
                    <div className="header-cls">
                        <i className="pi pi-angle-left" onClick={onClickBack}></i>
                        {/* <img src={Logo} /> */}
                        <p >Back</p>
                    </div>
                    <div className='content-cls'>
                        {
                            currentPage == "busineesdetailspage" ? <div className='busineesdetailspage'>
                                <p className='steps-cls'>STEP 1 OF 3</p>
                                <h3>Business Details of Supplier looking for Immediate Payment </h3>
                                <div className='businesscontent-cls'>
                                    <div className='busineesdetailspage-fields'>
                                        <div className="inputfield-cls">
                                            <p>What is your organization name</p>
                                            <div className="input-cls">
                                                <CustomInputText name="orgname" value={formData.orgname} config={{ onChange: (e) => handelInputChange(e), placeholder: "Enter name" }} />
                                            </div>
                                        </div>
                                        <div className="radiofield-cls">
                                            <p>Type of your organization</p>
                                            <div className="radio-btn-class">
                                                <div className={formData.type === 'P' ? "selected" : "p-field-radiobutton"}>
                                                    <label >Private/Public Limited</label>
                                                    <RadioButton name="type" value="P" onChange={handelInputChange} checked={formData.type === 'P'} />
                                                </div>
                                                <div className={formData.type === 'L' ? "selected" : "p-field-radiobutton"}>
                                                    <label >LLP/Partnership </label>
                                                    <RadioButton name="type" value="L" onChange={handelInputChange} checked={formData.type === 'L'} />
                                                </div>
                                                <div className={formData.type === 'Partner' ? "selected" : "p-field-radiobutton"}>
                                                    <label htmlFor="Yes">Proprietorship</label>
                                                    <RadioButton name="type" value="Partner" onChange={handelInputChange} checked={formData.type === 'Partner'} />
                                                </div>
                                            </div>
                                        </div>
                                        <CustomButton label="Continue" className={formData.type && formData.orgname ? 'continue-btn-enabled' : 'continue-btn-disabled'} onClick={formData.type && formData.orgname ? onClickContinue : ""} />

                                    </div>

                                    <div className="list-cls">
                                        <div className='header'>
                                            <div>
                                                <p>Welcome to <span>e-Mandi Cash</span></p>
                                            </div>
                                            <div>
                                                <img src={eMandiCash} />
                                            </div>
                                        </div>
                                        <div className='menu-cls'>
                                            <div><i className="pi pi-check-circle"></i> <p>Collateral free</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Upto <i class="fa fa-inr"></i> 2 crores cash for your supplies</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Payment confirmation in just one day</p></div>
                                            <div> <i className="pi pi-check-circle"></i>  <p>Available in 3 days</p></div>
                                            <div> <i className="pi pi-check-circle"></i> <p>Quick & easy way to enroll buyer</p></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className='terms-conditions' onClick={onTermsClick}>Terms and Conditions</p> */}
                            </div> : currentPage == "kycpage" ? <div className='busineesdetailspage'>
                                <p className='steps-cls'>STEP 2 OF 3</p>
                                <h3>Applicant KYC</h3>
                                <div className='businesscontent-cls'>
                                    <div className='busineesdetailspage-fields'>
                                        <p className='head-cls'>Upload PAN Card</p>
                                        <span className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</span>
                                        <div className="inputFile-cls">
                                            <label>
                                                <img src={uploadIcon} alt="upload" />
                                                <CustomInputText value={state.pancardFile && state.pancardFile.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                <input type="file" name={"pancard"} onChange={(e) => onClickFile(e, "PAN")} />
                                            </label>
                                        </div>
                                        <label className='filename-cls' onClick={() => onviewFile(state && state.pancard)}>{state.pancardFile && state.pancardFile.name}</label>
                                        {
                                            formData.type === 'Partner' ? <div>
                                                <p className='head-cls'>Upload Aadhar Card</p>
                                                <span className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</span>

                                                <div className="inputFile-cls">
                                                    <label>
                                                        <img src={uploadIcon} alt="upload" />
                                                        <CustomInputText value={state.aadharCard && state.aadharCard.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                        <input type="file" name={"aadhar"} onChange={(e) => onClickFile(e, "AADHAR")} />
                                                    </label>
                                                </div>
                                                <label className='filename-cls' onClick={() => onviewFile(state && state.aadhar)}>{state.aadharCard && state.aadharCard.name}</label>
                                            </div> : <div>
                                                <p className='head-cls'>Upload GST Certificate</p>
                                                <span className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</span>
                                                <div className="inputFile-cls">
                                                    <div className="inputFile-cls">
                                                        <label>
                                                            <img src={uploadIcon} alt="upload" />
                                                            <CustomInputText value={state.gstcertificate && state.gstcertificate.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                            <input type="file" name={"gst"} onChange={(e) => onClickFile(e, "Gst")} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <label className='filename-cls' onClick={() => onviewFile(state && state.gst)}>{state.gstcertificate && state.gstcertificate.name}</label>

                                            </div>
                                        }
                                        <CustomButton label="Continue" className={formData.type ? 'continue-btn-enabled' : 'continue-btn-disabled'} onClick={formData.type ? onClickKycContinue : ""} />

                                    </div>
                                    <div className="list-cls">
                                        <div className='header'>
                                            <div>
                                                <p>Welcome to <span>e-Mandi Cash</span></p>
                                            </div>
                                            <div>
                                                <img src={eMandiCash} />
                                            </div>
                                        </div>
                                        <div className='menu-cls'>
                                            <div><i className="pi pi-check-circle"></i> <p>Collateral free</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Upto <i class="fa fa-inr"></i> 2 crores cash for your supplies</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Payment confirmation in just one day</p></div>
                                            <div> <i className="pi pi-check-circle"></i>  <p>Available in 3 days</p></div>
                                            <div> <i className="pi pi-check-circle"></i> <p>Quick & easy way to enroll buyer</p></div>
                                        </div>
                                    </div>
                                </div>

                            </div> : currentPage == "cashlimitpage" ? <div className='cashlimitpage'>
                                <p className='steps-cls'>STEP 3 OF 3</p>
                                <h3>SetUp your cash limit</h3>
                                <div className='businesscontent-cls'>
                                    <div className='cashlimit-fields'>
                                        <div className="radiofield-cls">
                                            <div className="radio-btn-class">
                                                <div className={formData.limit === 'lac' ? "selected" : "p-field-radiobutton"}>
                                                    <label >Limit upto <i class="fa fa-inr"></i> 75 lac<br /><span>No more document required</span></label>
                                                    {/* <label ></label> */}
                                                    <RadioButton name="limit" value="lac" onChange={handelInputChange} checked={formData.limit === 'lac'} />
                                                </div>
                                                <div className={formData.limit === 'Cr' ? "selected" : "p-field-radiobutton"}>
                                                    <label >Limit upto <i class="fa fa-inr"></i> 2 Cr <br /><span className='label-cls'>No more document required</span></label>
                                                    <RadioButton name="limit" value="Cr" onChange={handelInputChange} checked={formData.limit === 'Cr'} />
                                                </div>
                                            </div>
                                            <div className="saveChangesButton-cls">
                                                <div className="commoditycheck-cls">
                                                    <div className="showCheckbox-cls">
                                                        <Checkbox checked={state.checked} onChange={onTermsChecked} ></Checkbox></div>
                                                    {/* <div className="label-cls" >By Clicking you are agreeing to the <span className='terms-agreeing'>Terms and Conditions</span></div> */}
                                                    <div className="label-cls" onClick={onTermsClick}>By Clicking you are agreeing to the <span className='etermConditions'>Terms and Conditions</span></div>

                                                </div>
                                            </div>

                                            <CustomButton label="Submit" className={formData.limit ? state.checked ? 'continue-btn-enabled' : 'continue-btn-disabled' : 'continue-btn-disabled'} onClick={formData.limit ? state.checked ? onClickcashlimitContinue : "" : ""} />
                                        </div>

                                    </div>

                                    <div className="list-cls">
                                        <div className='header'>
                                            <div>
                                                <p>Welcome to <span>e-Mandi Cash</span></p>
                                            </div>
                                            <div>
                                                <img src={eMandiCash} />
                                            </div>
                                        </div>
                                        <div className='menu-cls'>
                                            <div><i className="pi pi-check-circle"></i> <p>Collateral free</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Upto <i class="fa fa-inr"></i> 2 crores cash for your supplies</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Payment confirmation in just one day</p></div>
                                            <div> <i className="pi pi-check-circle"></i>  <p>Available in 3 days</p></div>
                                            <div> <i className="pi pi-check-circle"></i> <p>Quick & easy way to enroll buyer</p></div>
                                        </div>
                                    </div>
                                </div>
                                <p className='terms-conditions' onClick={onTermsClick}>Terms and Conditions</p>
                            </div> : currentPage == "businessKycpage" ? <div className='busineesdetailspage'>
                                <p className='steps-cls'>STEP 2 OF 3</p>
                                <h3>Business KYC</h3>
                                <div className='businesscontent-cls'>

                                    <div className='busineesdetailspage-fields'>
                                        <p className='head-cls'>Upload GST certificate</p>
                                        <p className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</p>
                                        <div className="inputFile-cls">
                                            <label>
                                                <img src={uploadIcon} alt="upload" />
                                                <CustomInputText value={state.gstcertificate && state.gstcertificate.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                <input type="file" name={"gst"} onChange={(e) => onClickFile(e, "Gst")} />
                                            </label>
                                        </div>
                                        <label className='filename-cls' onClick={() => onviewFile(state && state.gst)}>{state.gstcertificate && state.gstcertificate.name}</label>
                                        <hr></hr>
                                        <p className='head-cls'>12 Month GST returns</p>
                                        <p className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</p>
                                        <div className="inputFile-cls">
                                            <label>
                                                <img src={uploadIcon} alt="upload" />
                                                <CustomInputText value={state.gstreturn && state.gstreturn.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                <input type="file" name={"gstretur"} onChange={(e) => onClickFile(e, "gstreturn")} />
                                            </label>
                                        </div>
                                        <label className='filename-cls' onClick={() => onviewFile(state && state.gstretur)}>{state.gstreturn && state.gstreturn.name}</label>
                                        <hr></hr>
                                        <p className='head-cls'>3 Year Financials</p>
                                        <p className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</p>
                                        <div className="inputFile-cls">
                                            <label>
                                                <img src={uploadIcon} alt="upload" />
                                                <CustomInputText value={state.financialdoc && state.financialdoc.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                <input type="file" name={"financial"} onChange={(e) => onClickFile(e, "financial")} />
                                            </label>
                                        </div>
                                        <label className='filename-cls' onClick={() => onviewFile(state && state.financial)}>{state.financialdoc && state.financialdoc.name}</label>

                                        <hr></hr>

                                        <p className='head-cls'>Business PAN Card</p>
                                        <p className='label-cls'>Upload PNG, JPEG or PDF file upto 10MB</p>
                                        <div className="inputFile-cls">
                                            <label>
                                                <img src={uploadIcon} alt="upload" />
                                                <CustomInputText value={state.businessPan && state.businessPan.name} config={{ disabled: true }} placeholder={{ "name": "Add Picture" }} />
                                                <input type="file" name={"businesPan"} onChange={(e) => onClickFile(e, "businesspan")} />
                                            </label>
                                        </div>
                                        <label className='filename-cls' onClick={() => onviewFile(state && state.businesPan)}>{state.businessPan && state.businessPan.name}</label>
                                        <CustomButton label="Continue" className={formData.type ? 'continue-btn-enabled' : 'continue-btn-disabled'} onClick={onClickbusinessKycContinue} />

                                    </div>
                                    <div className="list-cls">
                                        <div className='header'>
                                            <div>
                                                <p>Welcome to <span>e-Mandi Cash</span></p>
                                            </div>
                                            <div>
                                                <img src={eMandiCash} />
                                            </div>
                                        </div>
                                        <div className='menu-cls'>
                                            <div><i className="pi pi-check-circle"></i> <p>Collateral free</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Upto <i class="fa fa-inr"></i> 2 crores cash for your supplies</p></div>
                                            <div><i className="pi pi-check-circle"></i> <p>Payment confirmation in just one day</p></div>
                                            <div> <i className="pi pi-check-circle"></i>  <p>Available in 3 days</p></div>
                                            <div> <i className="pi pi-check-circle"></i> <p>Quick & easy way to enroll buyer</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                : currentPage == "sucessPage" ? <div className='sucesspage'>
                                    <i className="pi pi-check-circle"></i>
                                    <h3 className='sucess-label'>Congratulations..!</h3>
                                    <h3 className='sucess-label'>Your request successfully submitted.</h3>
                                    <p className='confirm-msg-cls'>we have recieved your request, we have also send you a confirmation over the registered mail ID & Mobile Number</p>
                                    <div className='sucesspage-fields'>
                                        <div>
                                            <p>Limit request for</p>
                                            <p className='label-cls'>{formData.limit == "Cr" ? <div>Upto <i class="fa fa-inr"></i> 2 {formData.limit}</div> : <div>Upto {<i class="fa fa-inr"></i>} 75 lac</div>}</p>
                                        </div>
                                        <div>
                                            <p>Request ID</p>
                                            <p className='label-cls'>#{state.responseId}</p>
                                        </div>

                                    </div>
                                    <p>WHAT'S NEXT</p>
                                    <p className='confirm-msg1-cls'>Our smart engine is evaluating your response, based on that your limit will be alloted. Our eMandi cash expert will reach out to you in case required.</p>
                                    <div className="continue-cls" onClick={onClicksucessContinue}>
                                        <CustomButton label="Go to Homepage" className="cancel" />
                                        {/* <CustomButton label="Submit" className="send" config={{ onClick: onSubmitBuynow }} /> */}
                                    </div>
                                </div> : ""}

                    </div>
                </Scrollbars>
            </div>
        )
    }
}

export default List