import React from 'react'
import '../style/Header.css'
import profilePic from '../Images/profilePic.PNG';
import dropdownIcon from '../../../assets/icons/dropdown_icon.png';
import image_congratulations from '../../../assets/images/image_congratulations.png';
import { history } from "../../../utils/Utility"

function Congratulations() {
  const requestId = sessionStorage.getItem("requestID");


  const handleTwoCrore = () => {
    history.push('/TwoCrore')
  }

  return (
    <div className='row' style={{overflow:"auto"}}>
      <div className='col-md-12' style={{textAlign:"center"}}>
        {/* <img src={profilePic} className="pic" />
            <span className='headerLabel'>Admin</span> */}
        <div class="your-request" style={{marginTop:"50px"}}>
          Your request for <span className='greenLabel'>₹75L</span> is in progress! <br /> Request Id : #{requestId} 
        </div>
        <img src={image_congratulations} class="image-css"/>
        <div class="doc-css">
          We're reviewing your documents.
        </div>
        <div class="our-team">
          Oue team will be in touch with you within 48 Hours
        </div>
        <div class="increase-limit">
          Want to increase your limit to <span className='greenLabel'>2 Cr?</span>
        </div>
        <div class = "yes_button" onClick = {handleTwoCrore}>
          <button class="btn yes-btn" style = {{fontSize: '14px'}}>
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default Congratulations