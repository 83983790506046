import React, { Component } from 'react'
import './styles.scss';
import List from './view/List'
import { history, fireAjaxRequest } from "../../utils/Utility";
import {GET_NEWS} from '../../utils/Api'
import { toast } from 'react-toastify';

export class Emandinews extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        listData:[]
      }
    }
    componentDidMount(){
      this.shownewsList();
    }
    shownewsList=()=>{
      let Pathlocation = history.location.pathname;
        let Path = Pathlocation.replace("/Emandinews/", "");
        (async () => {
            // this.setLoading(true);
            try {

                const response = await fireAjaxRequest(`${GET_NEWS}/${Path}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                if(response){
                  this.setState({listData:response.data})
                }
            } catch (err) {
                // this.setLoading(false);
                //console.log("Failed to load trader Details !");
                toast.error(err.message || "Failed to load !");

            }
        })();
    }
    onClickBack = () => {
        // sessionStorage.setItem("isemandinewspage", true);
        history.push('/EmandinewsList');

    }
  render() {
    return (
      <List onClickBack={this.onClickBack} state={this.state}/>
    )
  }
}

export default Emandinews