import React, { Component } from 'react';
import CustomInputText from '../../../../components/CustomInputText';
import CustomRadioButtons from '../../../../components/CustomRadioButtons';
import CustomInputArea from '../../../../components/CustomInputArea';
import CustomButton from '../../../../components/CustomButton';
import LoginStatus from '../../../../containers/LoginStatus';
import './styles.scss';
import { fireAjaxRequest } from '../../../../utils/Utility';
import { GET_PINCODE_DETAILS, SET_PERSONALINFO_API ,SEND_EMAIL_OTP} from '../../../../utils/Api';
import { toast } from 'react-toastify';

class PersonalInfo extends Component {
    state = {
        personalData: {
            accountType: "INDIVIDUAL_TRADER",
            phoneNo: JSON.parse(sessionStorage.getItem("userInfo")).user.phoneNo,
            email:JSON.parse(sessionStorage.getItem("userInfo")).user.email
        },
        isEmail:"",
        showemailediticon:false,
        showotpfields:false,
        disableemailfield:JSON.parse(sessionStorage.getItem("userInfo")).user.email?true:false,
        verifiedemailicon:JSON.parse(sessionStorage.getItem("userInfo")).user.email?true:false,
        showsendotpbtn:JSON.parse(sessionStorage.getItem("userInfo")).user.email?false:true
    }
    componentDidMount(){
        if(this.state.personalData.email){
            this.setState({showsendotpbtn:false})
        }

    }
    onClicksendOtp=()=>{
        let {personalData}=this.state

       var validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (!personalData.email ||!personalData.email.match(validRegex)) {
            toast.error("Please enter valid email address")
        }
        else {
            const sendemailBody = {
                "email": this.state.personalData.email
            };
            (async () => {
                // this.setLoading(true);
                try {
                    const resp = await fireAjaxRequest(`${SEND_EMAIL_OTP}?email=${personalData.email}`, {
                        method: 'GET',
                        // body: JSON.stringify(sendemailBody),
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        }
                    });
                    // this.setLoading(false);
                    if (resp) {
                        this.setState({emailtotp:resp.otp,showemailediticon:true,showotpfields:true,disableemailfield:true,showsendotpbtn:false});
                        toast.success('OTP sent Successfully');
                    }

                } catch (err) {
                    // this.setLoading(false);
                    toast.error('Failed to Send OTP');
                }
            })();
        }

    }
    onVerifyOtp=()=>{
        let {personalData}=this.state
        if (!personalData.otp) {
            toast.error("Please enter OTP")
        }
        else if(personalData.otp.length<6){
            toast.error("OTP must contain 6 digits")
        }
        else {
            const sendemailBody = {
                "email": personalData.email,
                "verificationCode":personalData.otp,
            };
            (async () => {
                // this.setLoading(true);
                try {
                    const resp = await fireAjaxRequest(`${SEND_EMAIL_OTP}?email=${personalData.email}`, {
                        method: 'POST',
                        body: JSON.stringify(sendemailBody),
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                            "Content-Type": "application/json"
                        }
                    });
                    // this.setLoading(false);
                    if (resp) {
                        toast.success('Email address Verified'); 
                        personalData.otp=""
                        this.setState({showotpfields:false,showemailediticon:false,verifiedemailicon:true,personalData:personalData})
                    }

                } catch (err) {
                    // this.setLoading(false);
                    if(err.code==400){
                        toast.error("Invalid OTP")
                    }
                    else{
                        toast.error('Failed to Verify email address');

                    }
                }
            })();
        }

    }
    enableEmailfield=()=>{
        this.setState({disableemailfield:false,verifiedemailicon:false,showsendotpbtn:true,showotpfields:false,showemailediticon:false})
    }
    componentDidMount() {
        this.setState({ personalData: JSON.parse(sessionStorage.getItem("userInfo")).user });
        const email=JSON.parse(sessionStorage.getItem("userInfo")).user.email;
        if(email&&email.length>0){
            this.setState({isEmail:false})
        }
        else{
            this.setState({isEmail:true})
        }
    }

    onHandleInputChange = (e) => {
        const { personalData } = this.state;
        personalData[e.target.name] = e.target.value;
        if (personalData["accountType"] === "INDIVIDUAL_TRADER") {
            personalData.orgName = "";
        }
        this.setState({ personalData });
        this.props.getPersonalInfoDetails(personalData)
    }

    onChangePinCode = (e) => {
        const { personalData } = this.state;
        personalData[e.target.name] = e.target.value;
        this.setState({ personalData });
        if (e.target.value.length == 6) {
            (async () => {
                try {
                    const resp = await fireAjaxRequest(`${GET_PINCODE_DETAILS}/${e.target.value}`);
                    const pinDetails = resp && resp[0].PostOffice && resp[0].PostOffice[0]
                    personalData.state = pinDetails.State || "";
                    personalData.district = pinDetails.District || "";
                    personalData.town = pinDetails.Block || "";
                    personalData.region = pinDetails.Region || "";
                    this.setState({ personalData });
                } catch (err) {
                    toast.error("Failed to get pincode data");
                }
            })();
        }
    }

    onclickSubmit = () => {
        this.props.onClickPersonalInfoSubmit(this.state.personalData,this.state);
    

    }

    render() {
        const { personalData } = this.state;
        
        return (
            <div className="personalInfoBlock-cls">
                <div className="personalInfo-cls">
                    <div className="personalInfoDetails-cls">
                        <div className="block1-cls">
                            <div><CustomInputText label="Full Name" name="name" value={personalData.name} className="formCls"
                                config={{
                                    onChange: this.onHandleInputChange.bind(this)
                                }}
                            /></div>
                            <div><CustomInputText label="Phone Number" name="phoneNo" value={personalData.phoneNo} className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this), disabled: true, style: { backgroundColor: "#ACACAC" }
                            }} /></div>
                            <div><CustomInputText label="Email ID" name="email" value={personalData.email} className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this),disabled: this.state.disableemailfield?true:false
                            }} />
                            {this.state.showsendotpbtn?<p className="sendotp" onClick={this.onClicksendOtp} >Send OTP</p>:""}
                            {this.state.verifiedemailicon?<p style={{fontSize:"12px",width:"80%"}}>Verified Email Address <span style={{color:"#E43137"}}>{personalData.email} <i className="pi pi-pencil" onClick={this.enableEmailfield}></i></span></p>:""}
                        
                            </div>
                            {this.state.showemailediticon? <p style={{fontSize:"12px",width:"80%"}}>Enter the verification code sent to <span style={{color:"#E43137"}}>{personalData.email} <i className="pi pi-pencil" style={{marginLeft:"2px"}} onClick={this.enableEmailfield}></i></span></p>:""}
                        {this.state.showotpfields?<div><CustomInputText label="OTP" name="otp" value={personalData.otp} className="formCls" config={{
                            onChange: this.onHandleInputChange.bind(this),maxlength:"6",keyfilter:"pnum"
                            }} />
                            <p className="resendotp" onClick={this.onClicksendOtp}>Resend OTP</p>

                            <div className="buttons-wrapper">
                                <CustomButton className="verify-btn-cls" label="VERIFY" onClick={this.onVerifyOtp} />
                                {/* <CustomButton className="cancel" label="Resend OTP" onClick={onClicksendOtp} /> */}
                            </div>
                        </div>:""}
                            <div> <CustomRadioButtons name={"accountType"} value={personalData.accountType} label={"Account Type"}
                                options={
                                    [{ label: "Individual Trader", value: "INDIVIDUAL_TRADER" }, { label: "Organization", value: "ORGANIZATION_TRADER" }]
                                } config={{
                                    onChange: this.onHandleInputChange.bind(this),
                                    disabled:this.state.isEmail?false:true
                                }}
                            /></div>
                            <div><CustomInputText label="Organization Name" value={personalData.accountType === "INDIVIDUAL_TRADER" ? "" : personalData.orgName} name="orgName" className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this), disabled: personalData.accountType === "INDIVIDUAL_TRADER" ? true : false, style: { backgroundColor: personalData.accountType === "INDIVIDUAL_TRADER" ? "#ACACAC" : "#fff" }
                            }} /></div>
                        </div>
                        <div className="block2-cls">
                            <CustomInputText label="Pincode" name="pincode" value={personalData.pincode} className="formCls" config={{
                                onChange: this.onChangePinCode.bind(this), maxlength: "6",keyfilter:"num"
                            }} />
                            <CustomInputText label="State" name="state" value={personalData.state} className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this), disabled: true, style: { backgroundColor: "#ACACAC" }
                            }} />
                            <CustomInputText label="District" name="district" value={personalData.district} className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this), disabled: true, style: { backgroundColor: "#ACACAC" }
                            }} />
                            <CustomInputText label="City/Town/Village" name="town" value={personalData.town} className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this), disabled: true, style: { backgroundColor: "#ACACAC" }
                            }} />
                            <CustomInputText label="Region/Taluka" name="region" value={personalData.region} className="formCls" config={{
                                onChange: this.onHandleInputChange.bind(this), disabled: true, style: { backgroundColor: "#ACACAC" }
                            }} />
                        </div>
                        <div className="block3-cls">
                            <CustomInputArea type="formCls" label="Address" name="address" value={personalData.address} rows={10} config={{
                                onChange: this.onHandleInputChange.bind(this)
                            }} />
                        </div>
                    </div>
                    <div className="personalInfoButtons-cls">
                        <div className="skipButton">
                            <CustomButton label="SKIP TO VIEW AUCTIONS" onClick={this.props.onSkip} />
                            <p>This information will be required to place bids</p>
                        </div>
                        <div className="continueButton">
                            {this.props.currentPage == "personalInfo" && <CustomButton label="CONTINUE" onClick={this.onclickSubmit.bind(this)} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonalInfo;