//@constants

// let BASE_PROXY_URL ='http://eauctiondevservice.walkingtree.tech/origo';
// let BASE_PROXY_URL ='https://eauctionqaservice.walkingtree.tech/origo';
// let BASE_PROXY_URL ='http://eauctiondevservice.walkingtree.tech/origo';
//let BASE_PROXY_URL ='https://eauctionqaservice.walkingtree.tech/origo';
//let BASE_PROXY_URL ='https://eauctionqaservice.walkingtree.tech/origo';
//let BASE_PROXY_URL_MCX ='https://service.origoemandi.com:8443/origo';
//let BASE_PROXY_URL = 'https://auctionuatservice.origoindia.com:8089/origo';

//const BASE_PROXY_URL = 'https://virtserver.swaggerhub.com/WalkingTreeDev/Origo-eAuction/1.0.1.2';
// let BASE_PROXY_URL ='http://eauctionqaservice.walkingtree.tech/origo';
// //const BASE_PROXY_URL = 'https://virtserver.swaggerhub.com/WalkingTreeDev/Origo-eAuction/1.0.1.2';
// let BASE_PROXY_URL = 'https://service.origoemandi.com:8443/origo'; // production URL
// // let BASE_PROXY_URL = 'https://auctionuatservice.origoindia.com:8089/origo';


// let BASE_PROXY_URL = 'https://emandiuatservice.origoindia.com:8089/origo';
let BASE_PROXY_URL = 'https://service.origoemandi.com:8443/origo'; // production URL
let BASE_URL_ENQUIRY = 'https://servicedev.origoemandi.com:8088/origo';

const { location: { origin  } } = window;
// if(origin ==='http://eauctionqa.walkingtree.tech'){
//     BASE_PROXY_URL = 'https://eauctionqaservice.walkingtree.tech/origo'
// } else if(origin === 'https://emandiuat.origoindia.com:8443'){
//     BASE_PROXY_URL = 'https://emandiuatservice.origoindia.com:8089/origo'
// }else if(origin === 'http://eauctiondev.walkingtree.tech'){
//     BASE_PROXY_URL = 'http://eauctiondevservice.walkingtree.tech/origo'
// }else if(origin === 'http://localhost:3000'){
//     BASE_PROXY_URL = 'https://serviceqa.origoemandi.com:8077/origo'
// }
//public
 if(origin==='https://emandidev.origoemandi.com:3000'){
    BASE_PROXY_URL= 'https://servicedev.origoemandi.com:8088/origo'
    
}
else if(origin==='https://emandiqa.origoemandi.com:3001'){
    BASE_PROXY_URL= 'https://serviceqa.origoemandi.com:8077/origo'
}
else if(origin==='https://emandiuat.origoemandi.com:3002'){
    BASE_PROXY_URL= 'https://serviceuat.origoemandi.com:8090/origo'
}
//local
else if(origin === 'http://localhost:3000'){
//    BASE_PROXY_URL= 'https://serviceqa.origoemandi.com:8077/origo'
   BASE_PROXY_URL = 'https://servicedev.origoemandi.com:8088/origo'
    // BASE_PROXY_URL= 'https://serviceuat.origoemandi.com:8090/origo'
}
//public
// else if(origin==='https://emandidev.origoemandi.com:3000'){
//     BASE_PROXY_URL= 'https://servicedev.origoemandi.com:8088/origo'
// }
else if(origin==='https://emandidev.origoemandi.com:3000'){
    BASE_PROXY_URL= 'https://servicedev.origoemandi.com:8088/origo'
}
else if(origin==='https://emandiqa.origoemandi.com:3001'){
    BASE_PROXY_URL= 'https://serviceqa.origoemandi.com:8077/origo'
}
else if(origin==='https://emandiuat.origoemandi.com:3002'){
    BASE_PROXY_URL= 'https://serviceuat.origoemandi.com:8090/origo'
}

let BASE_PROXY_URL_MCX ='https://service.origoemandi.com:8443/origo';

//Auction
export const GET_AUCTION_LIST = `${BASE_PROXY_URL}/getAuction`;
export const POST_AUCTION = `${BASE_PROXY_URL}/auction`;
export const GET_AUCTION_BY_ID = `${BASE_PROXY_URL}/auction`;
export const POST_BID = `${BASE_PROXY_URL}/bid`;
export const GET_BID = `${BASE_PROXY_URL}/bid`;
export const DELETE_AUCTION = `${BASE_PROXY_URL}/auction/cancelAuction`;
export const DELETE_DRAFT = `${BASE_PROXY_URL}/auction`;
export const UPLOAD_FILE = `${BASE_PROXY_URL}/file`;
export const GET_ORDER = `${BASE_PROXY_URL}/order`;
export const DOWNLOAD_ORDER = `${BASE_PROXY_URL}/downloadOrder`;
export const EXPORT_BIDS = `${BASE_PROXY_URL}/bid/export`;
export const LOCATION_LIST = `${BASE_PROXY_URL}/list/locations`;
export const SEND_NOTIFICATION = `${BASE_PROXY_URL}/notifications`;
export const POST_PO_SO_PAYMENT = `${BASE_PROXY_URL}/transaction/orderPayment`;
export const POST_AUCTION_WON_LOT = `${BASE_PROXY_URL}/order`;
export const SEARCH_MOBILE_NUMBER = `${BASE_PROXY_URL}/user/validate/mobileno`;
export const SYSTEMTIME = `${BASE_PROXY_URL}/user/getCurrentDate`;
export const Download_Auction_List= `${BASE_PROXY_URL}/auction/download`;
export const Download_Order_List= `${BASE_PROXY_URL}/orderExport`;

//Accounts
export const GET_OREDR_ID = `${BASE_PROXY_URL}/payment/paymentMode`;
export const PAYMENT_VERIFICATION = `${BASE_PROXY_URL}/payment/verification`;
export const WITHDRAW_DEPOSIT = `${BASE_PROXY_URL}/payment/withdrawRequest`;
export const CHECK_IFSC = `${BASE_PROXY_URL}/validateIfsc`;
export const TRANSFER_STATUS_CHECK = `${BASE_PROXY_URL}/transaction/transferStatusCheck`;
export const TRANSFER_OTP_REQUEST = `${BASE_PROXY_URL}/otp`;
export const DOWNLOAD_TRANSACTIONS = `${BASE_PROXY_URL}/transactions/report`;
export const GET_EMANDI_CASH_ACCOUNT = `${BASE_PROXY_URL}/emandicash/get`;


//Personal Info
export const GET_PINCODE_DETAILS = `https://api.postalpincode.in/pincode`;
export const PUT_PERSONAL_INFORMATION_DETAILS = `https://virtserver.swaggerhub.com/WalkingTreeDev/Origo-eAuction/1.0.1.2/user`;
export const POST_KYC = `https://virtserver.swaggerhub.com/WalkingTreeDev/Origo-eAuction/1.0.1.2/kyc`;
export const GET_PERSONAL_INFO = `${BASE_PROXY_URL}/user`;

//profile section
export const PUT_USER_DETAILS = `${BASE_PROXY_URL}/profile/updatePersonalInfo`;
export const POST_KYC_PROFILE = `${BASE_PROXY_URL}/profile/kyc?`

//Commodity
export const GET_COMMODITY_GRID_DATA = `${BASE_PROXY_URL}/commodity`;
export const GET_COMMODITY_BY_ID = `${BASE_PROXY_URL}/commodity`;
export const POST_COMMODITY = `${BASE_PROXY_URL}/commodity`;
export const PUT_COMMODITY_DETIALS = `${BASE_PROXY_URL}/commodity`;
export const GET_TRADER_INFO_FROM_PHONENO = `${BASE_PROXY_URL}/user/getByPhoneNo`;
export const GET_GRADE_PARAM = `${BASE_PROXY_URL}/gradeParam`
export const DELETE_COMMODITY = `${BASE_PROXY_URL}/commodity`
export const DOWNLOAD_COMMODITY = `${BASE_PROXY_URL}/commodityExcel/download`




export const SIGNIN_API = `${BASE_PROXY_URL}/auth/signup-signin`;
export const REFERRAL_API = `${BASE_PROXY_URL}/user/validate/details`;
export const OTP_API = `${BASE_PROXY_URL}/auth/authenticate`;
export const SET_PERSONALINFO_API = `${BASE_PROXY_URL}/user`;

//  lender master 
export const SET_LENDER_DATA_API=`${BASE_PROXY_URL}/ecash/lender`
export const GET_ALL_LENDER_DATA=`${BASE_PROXY_URL}/ecash/lender`
export const UPDATE_LENDER=`${BASE_PROXY_URL}/ecash/lender`
export const GET_LENDER_BY_ID=`${BASE_PROXY_URL}/ecash/lender`
export const DEACTIVATE_USER_API=`${BASE_PROXY_URL}/user/deactivate`;
export const BIDDER_DETAILS=`${BASE_PROXY_URL}/user/specific`;
export const GET_TRADER_LIST = `${BASE_PROXY_URL}/list/traders`
export const SET_CONTACTUS_API = `${BASE_PROXY_URL}/contactUs`;
export const SET_KYC_API = `${BASE_PROXY_URL}/kyc`;
export const SET_KYC_FILE_UPLOAD_API = `${BASE_PROXY_URL}/kyc/ocr`;
export const SET_INVOICE_API = `${BASE_PROXY_URL}/invoice`
export const USER_ACCOUNT_API = `${BASE_PROXY_URL}/uesrAccount`;
export const AADHAR_CONSENT = `${BASE_PROXY_URL}/kyc/uploadAadharConsent`;

//admin Transactions
export const ADMIN_TRANSACTIONS_API = `${BASE_PROXY_URL}/transactions`;

// Trader Transactions
export const ACCOUNT_TRANSACTIONS_API = `${BASE_PROXY_URL}/transactions`;
export const DEPOSIT_PAYMENT_API = `${BASE_PROXY_URL}/payment/offlinePayment`;



export const USERACCOUNT_API = `${BASE_PROXY_URL}/userAccount`;
export const SUBSCRIBE_COMMODITY_API = `${BASE_PROXY_URL}/subscribeCommodity`;
export const COMMODITY_LIST_API = `${BASE_PROXY_URL}/list/commodities`;
export const AUCTION_LIST_API = `${BASE_PROXY_URL}/list/auctions`;
export const TRADER_BID_LIST_API = `${BASE_PROXY_URL}/bid`;
export const DEPOSITS_OR_PAYMENTS_API = `${BASE_PROXY_URL}/userAccount`;
//orders Transactions
export const ORDER_LIST_API = `${BASE_PROXY_URL}/order`;
export const LOCATION_LIST_API = `${BASE_PROXY_URL}/list/locations`;

export const GET_INVOICE_API = `${BASE_PROXY_URL}/invoice`;

//upload File
export const UPLOAD_FILE_API = `${BASE_PROXY_URL}/file`;
export const AADHAAR_CONSENT_API = `${BASE_PROXY_URL}/aadhaar/aadhaarConsent`;
export const GET_AADHAAR_API = `${BASE_PROXY_URL}/aadhaar/getAadhaarFile`;
export const AADHAAR_OFFLINE_API = `${BASE_PROXY_URL}/aadhaar/aadhaarOffline`;

//Admin trader
export const WITHDRAW_REQUEST = `${BASE_PROXY_URL}/payment/offlineWithdraw`;
export const DOWNLOAD_TRADER = `${BASE_PROXY_URL}/trader/export`;
export const SEND_EMAIL_OTP = `${BASE_PROXY_URL}/otp/email`;

//Landing Page 
export const CREATE_AUCTION = `${BASE_PROXY_URL}/leadsquared/auction`;
export const CALL_US = `${BASE_PROXY_URL}/leadsquared/callus`;
export const BUYNOW_PAYLATER = `${BASE_PROXY_URL}/leadsquared/bnpl`;
export const CASHANDCARRY_ARBITAGE = `${BASE_PROXY_URL}/leadsquared/arbitrage`;
export const GET_CASHANDCARRY_ARBITAGE_RECORDS = `${BASE_PROXY_URL}/arbitrage`;
export const SET_ALERT = `${BASE_PROXY_URL}/leadsquared/setAlert`; 
export const COMMODITY_LOCATION_API = `${BASE_PROXY_URL}/list/commodities_locations`;

// Landing Reports
export const POST_REPORT = `${BASE_PROXY_URL}/reports`;
export const GET_REPORT = `${BASE_PROXY_URL}/reports`;
export const DELETE_REPORT = `${BASE_PROXY_URL}/reports`;


//get weekly reports
export const GET_WEEKLY_REPORT = `${BASE_PROXY_URL}/weeklyReports`;


//post COMMODITY BY ADMIN
export const POST_COMMODITY_BYADMIN = `${BASE_PROXY_URL}/subscribeCommodityByAdmin`;


//Landing Login
export const REGISTER_USER = `${BASE_PROXY_URL}/auth/register`;
export const VERIFY_USER = `${BASE_PROXY_URL}/auth/verifyUser`;
export const FORGET_PASSWORD = `${BASE_PROXY_URL}/auth/forgotPassword`;
export const FORGET_PASSWORD_SEND_OTP = `${BASE_PROXY_URL}/otp/mobileNo`;
export const FORGET_PASSWORD_VEIFY_OTP = `${BASE_PROXY_URL}/otp`;
export const EMANDI_CASH_VERIFY = `${BASE_PROXY_URL}/emandicash/verify`;

//coming soon




//Commodity research
export const COMMODITY_RESEARCH = `${BASE_PROXY_URL}/commodityResearch`;
export const COMMODITY_RESEARCH_SEASONAL_REPORTS = `${BASE_PROXY_URL}/commodityResearch/seasonal`;
export const COMMODITY_RESEARCH_TOTAL_COUNTS = `${BASE_PROXY_URL}/commodityResearch/reports/count`;
export const COMMODITY_RESEARCH_WEEKLY_REPORTS = `${BASE_PROXY_URL}/weeklyReports/get`;
export const COMMODITY_RESEARCH_COMMODITY = `${BASE_PROXY_URL}/commodityResearch/commodities`;
export const COMMODITY_RESEARCH_LOCATIONS = `${BASE_PROXY_URL}/commodityResearch/locations`;

//Market Price
export const COMMODITY_LIST_ERESEARCH =`${BASE_PROXY_URL}/eresearch/commodities`;
export const MANDI_LIST_FOR_COMMODITY_ERESEARCH =`${BASE_PROXY_URL}/eresearch/mandis`;
export const MARKETPRICE_LINE_CHART_DATA =`${BASE_PROXY_URL}/eresearch/marketprice`;
export const TODAYS_PRICE =`${BASE_PROXY_URL}/eresearch/todayPrice`;
export const MARKETPRICE_CANDLE_CHART_DATA =`${BASE_PROXY_URL}/eresearch/marketprice/candles`;
export const UP_DOWN_CHANGE =`${BASE_PROXY_URL}/eresearch/upDownChange`;


//ticker 
export const SPOT_TICKER = `${BASE_PROXY_URL}/stream/spot`
export const  NCDEX = `${BASE_PROXY_URL_MCX}/stream/ncdexticker`
export const  MCX = `${BASE_PROXY_URL_MCX}/stream/mcxticker`

//emandiCash
export const EMANDI_CASH = `${BASE_PROXY_URL}/emandicash`;
export const GET_EMANDI_CASH = `${BASE_PROXY_URL}/emandicash`;
export const GET_EMANDI_CASH_USER_DETAILS = `${BASE_PROXY_URL}/auth/verifyUser`;
export const REUPLOAD_FILE = `${BASE_PROXY_URL}/emandicash/reuploadDocs`;

//emandiTV
export const UPLOAD_EMANDI_TV = `${BASE_PROXY_URL}/emanditv`;
export const GET_EMANDI_TV_LIST = `${BASE_PROXY_URL}/emanditv`;
export const DELETE_EMANDI_TV = `${BASE_PROXY_URL}/emanditv`;
export const GET_EMANDI_TV_ID = `${BASE_PROXY_URL}/emanditv/getById`;
//emandi news
export const GET_EMANDI_NEWS = `${BASE_PROXY_URL}/emandinews`;
export const UPLOAD_EMANDI_NEWS = `${BASE_PROXY_URL}/emandinews`;
export const DELETE_EMANDI_NEWS = `${BASE_PROXY_URL}/emandinews`;

//emandi news in commodity research
export const GET_NEWS = `${BASE_PROXY_URL}/emandinews/get`;
//Emandi cash Video
export const EMANDI_CASH_VIDEO = `${BASE_PROXY_URL}/emandicash/video`;
export const GET_EMANDI_CASH_VIDEO = `${BASE_PROXY_URL}/emandicash/video/get`;

export const VERIFY_PAN = "https://testapi.karza.in/v2/pan";

export const VERIFY_GST = "https://api.karza.in/gst/uat/v2/gstdetailed";



// Buyer under writing
export const BUYER_UNDER_WRITING = `${BASE_PROXY_URL}/buyerUnderWriting`
export const BUYER_UNDER_WRITING_MAIL = `${BASE_PROXY_URL}/buyer_underwriting_mail`

export const INSURANCE_LIMIT_API = "https://profin.orgzit.com/api/1/ozrecord/"
export const FILTER_TABLE_RECORDS_API = "https://profin.orgzit.com/api/1/ozrecord/filter/?limit=20"
export const BUYER_REQUEST_INDEX =  `${BASE_PROXY_URL}/profinbuyer/?`
export const BUYER_REQUEST_DETAILS = `${BASE_PROXY_URL}/profinbuyer/fetchById?`
export const BUYER_REQUEST_STATUS = `${BASE_PROXY_URL}/profinbuyer/comment/update/status?`
export const PROFIN_BUYER_DOCS = `${BASE_PROXY_URL}/profinbuyer/docs`
export const PROFIN_BUYER_PARTNER = `${BASE_PROXY_URL}/profinbuyer/docs/partner`
export const PROFIN_BUYER_SIGNATORY = `${BASE_PROXY_URL}/profinbuyer/docs/signatory`
export const PROFIN_BUYER_STATE = `${BASE_PROXY_URL}/profinbuyer/`
export const PROFIN_ORIGO_ENTITY = `${BASE_PROXY_URL}/profinbuyer/`
export const PROFIN_ADD_BUYER = `${BASE_PROXY_URL}/profinbuyer/`
export const sandbox_url= "https://api.probe42.in/probe_pro_sandbox/entities?limit=25&filters=";
export const prod_url= "https://api.probe42.in/probe_pro/entities?limit=25&filters=";
export const sandbox_key ="Ovc0NQth3YaF04YDwJp9c5ks7b5MLD3v1ZuHKHpW";
export const prod_key = "qjTYIaIuhF6LR0FJpR0iHTSQB9j5gyu8HaWBEUie";
export const procLink_api = `${BASE_PROXY_URL}/creditlimit/`;
export const bdPerson_api = `${BASE_PROXY_URL}/bdperons/getAll`;
export const tempProc_link =`${BASE_PROXY_URL}/creditlimit/`;
export const GST_VALIDATION=`https://api.karza.in/gst/uat/v2/gstdetailed?x-karza-key=x1un4W0Q7Gycpa2takb1`;
// eMadi new Home
export const POST_ENQUIRY= `${BASE_PROXY_URL}/enquiry`;
export const SIGNIN_SIGNUP=`${BASE_PROXY_URL}/auth/signup-signin/v2`;
export const OTP_AUTH_API=`${BASE_PROXY_URL}/auth/authenticate`;
export const ENQUIRY_FORM=`${BASE_PROXY_URL}/enquiry`;

//ECash Admin
export const KYC_VERIFY_GST = `${BASE_PROXY_URL}/karza?`
export const KYC_VERIFY_PAN = `${BASE_PROXY_URL}/kyc/verify/pan?`;
export const LENDER_SELECTION = `${BASE_PROXY_URL}/ecash/lenderselection?`;
export const ADDITIONAL_DOCS = `${BASE_PROXY_URL}/ecash/lender/additionaldocs?`;
export const SAVE_ADD_DOCS =`${BASE_PROXY_URL}/ecash/docs`;
export const PARTNER_ADD_DOCS =`${BASE_PROXY_URL}/ecash/partner/docs`;
export const SIGNATORY_ADD_DOCS =`${BASE_PROXY_URL}/ecash/signatory/docs`;
export const GET_ECASH_REQUESTS = `${BASE_PROXY_URL}/ecash`;
export const GET_ECASH_REQUESTS_APPROVED = `${BASE_PROXY_URL}/ecash/comment/update/status`;
export const POST_PAN_DATA =  `${BASE_PROXY_URL}/ecash/entitydetails/pan`;
export const POST_GST_DATA =  `${BASE_PROXY_URL}/ecash/entitydetails/gst`;
export const POST_DOCUMENTS_DATA =  `${BASE_PROXY_URL}/ecash/partner/docs`;
export const KYC_VERIFY_UAN = `${BASE_PROXY_URL}/kyc/verify/uan?`;
export const LIMIT_INCREASE = `${BASE_PROXY_URL}/ecash/limit/increase?`
export const PUT_MSME_DETAILS = `${BASE_PROXY_URL}/ecash/entitydetails/msme`;
export const GET_DETAILS_BY_PIN = `${BASE_PROXY_URL}/pincode/get/`;
export const IS_ELIGIBLE = `${BASE_PROXY_URL}/ecash/isEligible?`;
export const PROGRESS_REPORT = `${BASE_PROXY_URL}/ecash/request/progress?`;
export const BUYER_PAN= `${BASE_PROXY_URL}/ecashbuyer/entitydetails/pan`;

//eCash Web



