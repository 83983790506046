import React from 'react'
import '../style/Header.css' 
import profilePic from '../Images/profilePic.PNG';

function Header() {
  return (
    <div className='row topheader'>
        <div className='col-md-6 col-sm-6 col-md-6'>
        </div>

        <div className='col-md-6 col-sm-6 col-md-6'>
            <img src={profilePic} className="pic" />
            <span className='headerLabel'>Admin</span>
        </div>
    </div>
  )
}

export default Header