import React, { useEffect, useState } from 'react';
import AddLender from './Components/addLender';
import { GET_ALL_LENDER_DATA, GET_LENDER_BY_ID } from '../../utils/Api';
import { fireAjaxRequest } from '../../utils/Utility';
import UpdateLender from './Components/UpdateLender';
import { history } from '../../utils/Utility';  
import editIcon from '../../assets/icons/editIcon.png'

const AddLenders = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lenderArr, setLenderArr] = useState([])
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [lenderData, setLenderData] = useState({})
    const [isAddLenderClick,setIsAddLenderClick]=useState(true)

    const [currentPage,setCurrentPage]=useState(1)
    const lendersPerPage=5;
    const lastIndex=currentPage*lendersPerPage
    const firstIndex=lastIndex-lendersPerPage
    const lenders=lenderArr.slice(firstIndex,lastIndex)
    const nextPage=Math.ceil(lenderArr.length/lendersPerPage)
    const numbers=[...Array(nextPage+1).keys()].slice(1)

    const handleAdd = () => {
        setIsModalOpen(true);
        setIsAddLenderClick(false)
    };

    const closeModal=()=>{
        setIsEditOpen(false)
    }

    useEffect(async () => {
        try {
            const result = await fireAjaxRequest(GET_ALL_LENDER_DATA, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    "Content-Type": "application/json"
                }
            });

            if (result) {

                console.log('res324', result.items)
                setLenderArr((result.items))
                return
            }

        } catch (err) {    
            console.log('err92', err)
        }
    }, [])
    
    function formatMouDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDate = formattedDay + '/' + formattedMonth + '/' + year;
        return formattedDate;
    }

    const handlePrePage=()=>{
        if(currentPage!==1){
            setCurrentPage(currentPage-1)
        }
    }

    const handleNextPage  =()=>{
       if(currentPage!==nextPage){
        setCurrentPage(currentPage+1)
       }
    }

    const handleCurrentPage=(n)=>{
        setCurrentPage(n)
    }

    const handleProceed = (id) => {
        setIsEditOpen(true)
        // getLenderData(id)
        history.push('/updateLender/'+id)
    }

    return (
        <div className='add-lender-home'>
            {isAddLenderClick ? <div className='create-lender-btn' style={{ cursor: 'pointer' }}>
                 <button className="btnAdd" style={{ fontSize: "12px", marginTop: '10px' }} onClick={handleAdd}>
                    {' '}
                    <b style={{ fontSize: '25px', marginRight: "10px" }}>+</b>
                    Add Lender
                </button>
            </div>:<></>}
            {!isModalOpen &&
            <>
                <div className="tableCard" style={{ margintop: "160px" }}>
                <table className="customTable">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>MoU Date</td>
                            <td>Total Funds</td>
                            <td>Available Funds</td>
                            <td>Interest Rate</td>
                            <td>Processing Fees</td>
                            <td>Invoice Finances</td>
                            <td>Created Date</td>
                            <td>Last updated Date</td>
                            <td>Action</td>
                        </tr>
                    </thead>

                    <tbody>

                        {lenders?.map((item) => (
                            <>
                                <tr style={{ backgroundColor: '#fff' }}>
                                    <td title={item.name}> {item.name.substring(0, 10)}...</td>
                                    <td> {item.mouDate}</td>
                                    <td > {item.totalFunds}</td>
                                    <td > {item.availableFunds}</td>
                                    <td > {item.interestRate}</td>
                                    <td > {item.processingFees}</td>
                                    <td > {item.invoiceFinances}</td>
                                    <td>{formatMouDate(item.created)}</td>
                                    <td>{formatMouDate(item.lastUpdated)}</td>
                                    <td>
                                        <img
                                            style={{width:'18px', height:'18px'}}
                                            src={editIcon}
                                            onClick={() => {
                                                handleProceed(item.id);
                                            }}
                                            alt=""
                                            className="arrowBtn"
                                        />
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>

                </table>
            </div>

            <div className='pagination-ctn'>
                <div className='page-item'>
                    <p  style={{color:'#fff',backgroundColor:'#04b23d',paddingLeft:'4px', paddingRight:'4px', borderRadius:'4px'}} className='single-page' onClick={()=>handlePrePage()}>Pre</p>
                </div>
                {
                    numbers.map((n,i)=>(
                        <div className={`page-item ${currentPage===n?'active':''}`} key={i}>
                            <p className='single-page' onClick={()=>handleCurrentPage(n)}>{n}</p>
                        </div>
                    ))
                }
                <div className='page-item'>
                    <p style={{color:'#fff',backgroundColor:'#04b23d',paddingLeft:'4px', paddingRight:'4px', borderRadius:'4px'}} className='single-page' onClick={()=>handleNextPage()}>Next</p>
                </div>
            </div>
            </> }
            {isModalOpen && <AddLender setOpenModal={setIsModalOpen} hideAddLender={setIsAddLenderClick} />}
            {isEditOpen && <UpdateLender lenderData={lenderData}/>}

        </div>
    );
};

export default AddLenders;
