import React from 'react';
import '../style/Header.css';
import vectorRight from '../../../assets/icons/vector_right.png';
import { history } from '../../../utils/Utility';
import Admin from './Admin';
import 'react-bootstrap-accordion/dist/index.css';
import Pan from './panNumber';

function AddECash() {
  const backHome = () => {
    window.location.reload();
    // history.push('/addECash');
  };

  return (
    <>
      {/* <div className="topheader"  >
        <Admin />
      </div> */}
      <div className="main-section" style={{ backgroundColor: '#F2F2F2', height:"auto", minHeight: '820px' }}>
        <nav aria-label="" style={{ paddingLeft: '10px' }}>
          <div className="container container-css ">
            <div className="container" style={{padding:"20px",float:"left", width: "40%"}}>
            <p className="backbtn" id="btnback" onClick={() => backHome()}>
                <i className="fa fa-arrow-left"></i>
                <span style={{ color: '#757575' }} className='breadcrumbsLabel'>&nbsp; Back &nbsp;</span>
              </p>   
            </div>
            <ol class="" style={{float:"right", width: '400px', fontSize: "13px", marginTop: '20px' }}>
            <a className='breadcrumbsLabel'
              href="#/eCash"
              style={{
                textDecoration: 'none',
                color: '#757575',
                fontSize: "13px"
              }}
            >
              eCash &nbsp;{' '}
            </a>
            <img src={vectorRight}></img>
            <span  style={{ color: '#04B23D' }} className='breadcrumbsLabel'> &nbsp; Entity Details</span>
          </ol>
          </div>
         
        </nav>
        <div className="body-css" style={{
          height: 'auto',minHeight:"600px", marginTop: '15px',
          background: 'white'
        }}>
          <div className="top-section" style={{ paddingTop: '15px', fontSize: '13px'  }}>
            <ol style={{ display: 'flex', height: '30px' }}>
              <button 
                type="button"
                class="btn btn-Light"
                style={{
                  border: '1px',
                  borderRadius: '100px',
                  background: '#757575',
                  color: 'white',
                  width: '25px',
                  height: '25px',
                  paddingTop: '2px'
                }}
              >
                <span style = {{marginLeft: '-4px'}}>1</span>
              </button>
              <span className='breadcrumbsLabel' id='enterDetailsLabel'>
                Enter Details
              </span>
              <button
                type="button"
                class="btn btn-dark"
                style={{
                  border: '1px solid white',
                  borderRadius: '100px',
                  background: '#D3D3D3',
                  color: 'white',
                  marginLeft: '50px',
                  width: '30px',
                  height: '30px',
                  paddingTop: '2px'
                }}
              >
                <span  style = {{marginLeft: '-3px',display:'inline-block',marginTop:"2px"}}>2</span>
              </button>
              <span id='uploadDocsLabel' className='breadcrumbsLabel' 
                style={{
                  marginLeft: '10px',
                  marginTop: '2px',
                  color: '#979797',
                }}
              >
                Upload Documents
              </span>
            </ol>
          </div>
          <Pan />
        </div>
      </div>
      
    </>
  );
}

export default AddECash;
