import React, { Component } from 'react';
import { history, fireAjaxRequest } from '../../../utils/Utility';
import Scrollbars from 'react-custom-scrollbars';
import Navbar from '../../../components/HomeNewPageComponents/Navbar/Navbar';
import image1 from "../../../assets/images/newsImage1.png";
import image2 from "../../../assets/images/newsImage2.png";
import './emandiNews.scss';
import Footer from '../../../components/HomeNewPageComponents/Footer/Footer';
import { GET_NEWS } from '../../../utils/Api';
import { toast } from 'react-toastify';
import back_arrow from '../../../assets/images/back_arrow_breadcrumbs.svg'


export class NewEmandiNews extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listData:[],
            listAllData:[]
          }
    }
    componentDidMount(){
        this.shownewsList();
      }
    shownewsList=()=>{
        let Pathlocation = history.location.pathname;
         // let Path = Pathlocation.replace("/Emandinews/", "");
          //alert(Path);
          (async () => {
              // this.setLoading(true);
              try {

                  const response = await fireAjaxRequest(`${GET_NEWS}`, {
                      method: 'GET',
                      headers: {
                          "Content-Type": "application/json",
                          // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                      },
                  });
                  if(response){
                    console.log(response);
                    this.setState({listData:response.data,listAllData:response.data})
                  }
              } catch (err) {
                  toast.error(err.message || "Failed to load !");
  
              }
          })();
      }
    backHome = () => {
        // history.push('/postreg')
        history.push({
            pathname: '/postreg',
            state: { source: 'emandinews-view-all-page' },
            hash: '#emandiNEWSId',
          });
    }
    backEMandi = (e) => {
        history.push('/eMandiNews')
    }
    readFullNews = (e) => {
        history.push(`/eMandiFullNews/${e}`);
    }
    filterNews=(e)=>
    {
       
        let serachItem=e.target.value;
       
        if(serachItem!="")
        {
            let listData=this.state.listData;
            this.setState({listData:listData.filter(x=>x.topic.toLowerCase().includes(serachItem.toLowerCase()))});
        }
        else
        {
            this.setState({listData:this.state.listAllData});
        }

    }
    render() {
        return (
            <div>
                <Navbar />
                <Scrollbars autoHide={false} style={{ width: "100%", height: "93vh" }}>
                    <div className='newEmandiNewsViewAll emandi-news-css-1'>
                        <div className='container mt-5'>
                            <p className='backbtn'>
                                <span
                                 onClick={() => this.backHome()}
                                 > 
                                    <img src={back_arrow} onClick={() => this.backHome()}></img>
                                    &nbsp; &nbsp;&nbsp;&nbsp; Home&nbsp; &gt;
                                </span>
                                <span onClick={() => this.backEMandi()} style={{ color: '#04B23D' }}>&nbsp; &nbsp;eNews</span> </p>
                        </div>
                        <div className='container'>
                            {/* <div className='smallBox mt-xl-5'>
                        <div className='container-css-1'>
                                <div class="small-box  yellow">
                                </div>
                                <p class = 'p-css-2' style={{ fontSize:"14pt",marginLeft:"15px", }}>eMandi News</p>
                            </div>                   
<div class="input-box" style = {{fontFamily: 'MetroPolisExtraLight !important'}}>
  <span class="prefix input-box-addon">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;      &nbsp;</span>
  <input type="text input-css-3" placeholder="Search news" onChange={(e)=>{this.filterNews(e)}} style = {{fontFamily: 'MetroPolisExtraLight !important'}}/>
  <i class="fa fa-search i-css-2" ></i>
</div>
                        </div> */}
                        <div className='container-css-1 mt-xl-5 col-md'>
                            <div className = 'smallBox'>
                            <div class="small-box yellow" style={{ height: '40px', paddingTop: '5px'}}>
                            </div>
                            <p className='mx-3 big mt-1 emandiTVViewAll' style={{ fontSize: '22px', 
    fontWeight: '600' }}>eNews</p>
    </div>
                            {/* <div class="input-group">
    <span class="input-group-addon" style = {{    marginLeft: '700px' }}>
    <input type="text input-css-2 " class="form-control" placeholder='         Search Videos'/>
    <i class="fa fa-search i-css-1" style = {{  paddingLeft: '20px !important' }}></i>
    </span>
</div> */}
<div class="input-box">
  {/* <span class="prefix input-box-addon">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> */}
  <i class="fa fa-search i-css-1"></i>
  <input type="text input-css-2" placeholder="Search News" onChange={(e)=>{this.filterNews(e)}}  style = {{ fontFamily: 'MetroPolisExtraLight', fontWeight: '400' }}/>
  {/* <i class="fa fa-search i-css-1" style = {{  paddingLeft: '20px !important', marginTop: '300px !important', marginLeft: '600px !important' }}></i> */}
</div>
                        {/* <div className='smallBox mt-xl-5'>
                            <div class="small-box  yellow">
                            </div>
                            <p className='mx-3 big mt-1 ' style={{ fontWeight: 'bold' }}>eMandi TV</p>
                            <input type="text" class="form-control mt-2" onChange={(e)=>{this.filterTVNews(e)}} id="exampleInputPassword1" placeholder='search news' style={{ height: '35px', marginLeft: '70%', width: '17%' }} />
                        </div> */}

                            {/* <input type="text" class="form-control mt-2 input-css-1 " onChange={(e)=>{this.filterTVNews(e)}} id="exampleInputPassword1" placeholder='Search Videos' style={{ height: '35px', marginLeft: '70%', width: '17%' }} 
                            />  */}
                            {/* <i class="fa fa-search"></i> */}

                        </div>
                            <div class="row">
                            {
                                this.state.listData.map((item)=> 
                                        <div class="box" style={{width:"330px",height:"286px",padding:"20px", 
                                            // marginLeft: '-21px',
                                            marginTop: '50px'
                                            }}>
                                            <img src={item.newsPicturePath} style={{width:"280px",height:"100px",marginBottom:"20px"}} alt="..." />
                                            <div class="card-body">
                                                <p class="card-text" style={{fontWeight:"500"}}>{item.topic}</p>
                                                <p className='fullNews' style={{color:"rgb(4, 178, 61)",fontWeight:"500",cursor:"pointer",fontSize:"11pt"}} onClick={() => this.readFullNews(item.id)}>Read full news</p>
                                            </div>
                                        </div>
                                )
                            }
                            </div>
                        </div>

                    </div>
                    <Footer />
                </Scrollbars>
            </div>
        )
    }
}

export default NewEmandiNews


// margin-left: -21px;
// margin-top: 30px;
// }