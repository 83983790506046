import React, { Component } from 'react';
import { history, fireAjaxRequest } from '../../../utils/Utility';
import image1 from "../../../assets/images/video1.svg";
import image2 from "../../../assets/images/video2.svg";
import image3 from "../../../assets/images/video3.svg";
import './EmandiTv.css';
import { GET_EMANDI_TV_LIST } from '../../../utils/Api';
import { toast } from 'react-toastify';
import img1 from '../../../assets/images/image1.png'
import img2 from '../../../assets/images/image2.png'
import img3 from '../../../assets/images/image3.png'
import { Carousel } from 'react-responsive-carousel';

import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

export class EmandiTv extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            formData: {
            },
            showTvpopUp: false,
            offset: 0,
            limit: 20,
            count: 0,
            emandiData: [],
            current_page: 1,
            pageNumberLimit: 3,
            maxPageNumberLimit: 3,
            minPageNumberLimit: 0,
            options: [{ "label": "All time", "value": "" },
            { "label": "Last 15 days", "value": "15days" },
            { "label": "Last 1 Month", "value": "1Month" },
            { "label": "Last 3 Month", "value": "3Month" },
            { "label": "Last 6 Month", "value": "6Month" },
            { "label": "Last 1 Year", "value": "1Year" }]
        }
        this.getEmandiTVlist();
    }

    getEmandiTVlist() {
        let { formData, offset, limit } = this.state;
        let emandiURL = `${GET_EMANDI_TV_LIST}/get?offset=${offset}&limit=${limit}`;

        if (formData.time) {
            emandiURL = `${emandiURL}&fromDate=${formData.time}`
        }

        (async () => {
            // this.setLoading(true);
            try {

                let apiUrl = `${emandiURL}`;
                const response = await fireAjaxRequest(`${apiUrl}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        // "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem("userInfo")).token}`,
                    },
                });
                // this.setLoading(false);
                if (response) {
                    this.setState({ emandiData: response.data, count: response.count });

                } else {

                }

            } catch (err) {
                // this.setLoading(false);
                //console.log("Failed to load trader Details !");
                toast.error(err.message || "Failed to load !");

            }
        })();
    }
    EmandiTV = () => {
        history.push('/emandiTVNew')
    }
    dateFormat = (date) => {
        var date1 = new Date(date);
        var date2 = new Date();
        var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
        return diffDays + " days ago";

    }
    convertToM=(e)=>
    {
        var views=e;
        if(e >  999 && e<1000000)
        {
            views = e/1000+"K"
        }
        if(e > 999999)
        {
            views = (e/100000).toFixed(0) +"M"
        }
        return views
    }
    render() {
        return (
            <div className='emandiTV' id="emandiTVId">
                <div className='smallBox emandiTVV'>
                    <div className='headerEmandiTV'>
                        <div class="small-box  yellow" style={{ borderRadius: "0px 5px 5px 0px" }}>
                        </div>
                        <p className='mx-3 big' style={{ fontWeight: 'bold', fontSize: '22px', marginBottom: '0px' }}>eTV</p>
                        <p className='mx-1 small box-text p-css' style={{ fontSize: '16px', color: 'black !important',  marginBottom: '0px' }}>| &nbsp; Commodity vyapari ka favourite TV channel</p>
                    </div>
                    <p className="viewAll" style={{color: '#04B23D', cursor: 'pointer' }} onClick={() => this.EmandiTV()}> View All</p>
                </div>
                <div class="mt" style={{ marginBottom: '22vh' }}>
                    <div className='video-slider slider-css' styles={{ marginLeft: "140px", marginBottom: '46px' }}>
                        <Carousel 
                            styles={{ marginBottom: '46px' }}
                        >
                            {
                                this.state.emandiData.map((item) => (
                                    <div className='slider_box' style={{ marginTop: "40px" }}>
                                        <iframe class="" style={{ width: "350px", height: "250px", borderRadius: "15px" }} src={item.content} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                         allowfullscreen="allowfullscreen"
                                         mozallowfullscreen="mozallowfullscreen" 
                                         msallowfullscreen="msallowfullscreen" 
                                         oallowfullscreen="oallowfullscreen" 
                                         webkitallowfullscreen="webkitallowfullscreen"
                                        ></iframe>
                                        <p class="legend" style={{fontWeight: '400'}}><span className='tVTitle'>{item.tittle}</span><span className='lblViews'>{this.convertToM(item.views)} Views | {this.dateFormat(item.uploadedOn)}</span></p>

                                    </div>
                                ))
                            }
                            <div className='slider_box slider-css-1' >
                                <img src={img1} alt='image 1' />
                                
                            </div>
                            <div className='slider_box'>
                                <img src={img2} alt='image 2' />
                                
                            </div>
                            <div className='slider_box'>
                                <img src={img3} alt='image 3' />
                               
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmandiTv