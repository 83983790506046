import React from 'react';
import PropTypes from "prop-types";
import flag from "../../assets/images/flag.png"

//@custom styles
import "./style.scss"

//@primereact components
import { InputText } from 'primereact/inputtext';

//@functional component
const CustomInputText = ({ name, label, value, config = {},customOnKeyPress = null, formName = null, required, className, placeholder, type, showColon, showAstrick, rightIcon, onRightIconClick }) => {
    if (!config.onChange) {
        config.onChange = ((e) => { });
    }

    let _value = value !== undefined ? (label==="IFSC Code"?value.toUpperCase():value) : "";

    const inputKeyPressHandler = (e, filedName = "") => {
        if (customOnKeyPress) {
            customOnKeyPress(e, filedName)
        }
    }
    return (
        type === "wrap" ?
            <div className={`${className || ""} textLabelCls`}>
                <div className="labelCls">
                    {showAstrick === "Left" && <div className="astrickCls"><b>*</b></div>}
                    <div className="labelTextcls"><p> {label} </p></div>
                    {showAstrick === "Right" && <div className="astrickCls"><b>*</b></div>}
                    {showColon ? <div className="divideCls"><p> : </p></div> : ""}
                </div>
                <div className="textInputCls">
                    {(placeholder && placeholder.icon) ? <span className="p-inputgroup-addon">
                        <i className={placeholder.icon} />
                    </span> : ""}

                    <InputText name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config}
                    />
                </div>
            </div>
            :
            type === "form" ?
                <div className={`${className || ""} formCls`}>
                    <div className="labelCls">
                        {showAstrick === "Left" && <div className="astrickCls"><b>*</b></div>}
                        <div className="labelTextcls"><p> {label} </p></div>
                        {showAstrick === "Right" && <div className="astrickCls"><b>*</b></div>}
                    </div>
                    <div className="textInputCls">
                        {(placeholder && placeholder.icon) ? <span className="p-inputgroup-addon">
                            <i className={placeholder.icon} />
                        </span> : ""}
                        <InputText name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config}
                        />
                    </div>
                </div>
                :
                type === "iconField" ?
                    <div className={`${className || ""} iconLabelCls`}>
                        <div className="labelCls">
                            {required ? <div className="astrickCls"><b>*</b></div> : ""}
                            <div className="labelTextcls"><p> {label} </p></div>
                            {showColon ? <div className="divideCls"><p> : </p></div> : ""}
                        </div>
                        <div className="textinputIcon">
                            {(placeholder && placeholder.icon) ? <span className="p-inputgroup-addon">
                                <i className={placeholder.icon} />
                            </span> : ""}

                            {(rightIcon) ?
                                <div className="p-input-icon-right">
                                    <InputText name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config} />
                                    <i className={`pi ${rightIcon}`} onClick={onRightIconClick} />
                                </div>
                                : <InputText name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config}
                                />}
                        </div>
                    </div>
                
                
                :

                type === "mobileNumber" ?
                <div className={`${className || ""} inputtext-content-section`}>
                        {(label) ? <div className="inputtext-label">
                            {required ? <span className="required-field">* </span> : ""}
                            <label>{label}</label>
                        </div> : ""}

                        {(placeholder && placeholder.icon) ? <span className="p-inputgroup-addon">
                            <i className={placeholder.icon} />
                        </span> : ""}
                        <span className="p-input-icon-left">
                            <span>
                                <img src={flag} alt="Flag"/>
                                <p>+91</p>
                            </span>
                            <InputText className={"mobile-input"} name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config}/>

                        </span>
                        {/* <span className="mobile-details-with-image">
                            <img src={flag} alt="Flag"/>
                            <p>+91</p>
                        </span>
                        <InputText className={"mobile-input"} name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config}/> */}

                    </div>
                :

                    <div className={`${className || ""} inputtext-content-section`}>
                        {(label) ? <div className="inputtext-label">
                            {required ? <span className="required-field">* </span> : ""}
                            <label>{label}</label>
                        </div> : ""}

                        {(placeholder && placeholder.icon) ? <span className="p-inputgroup-addon">
                            <i className={placeholder.icon} />
                        </span> : ""}
                        {(rightIcon) ? <span className="p-input-icon-right">
                            <InputText name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} {...config} />
                            <i className={`pi ${rightIcon}`} onClick={onRightIconClick} /></span>
                            : <InputText name={name || ""} value={_value} autoComplete="off" placeholder={(placeholder && placeholder.name) || ""} onKeyUp={(e) => inputKeyPressHandler(e, formName || name)} {...config}
                            />}
                    </div>

    );
};

//@for required/options props
CustomInputText.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.string,
    config: PropTypes.object,
    className: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    showColon: PropTypes.bool,
    showAstrick: PropTypes.string,
    rightIcon: PropTypes.string,
    onRightIconClick: PropTypes.func
};

export default CustomInputText;