import React, { Component } from 'react';

//@primereact components
import { Calendar } from 'primereact/calendar';
import calIcon from "../../assets/images/ic_cal.svg";

//@custom styles
import "./style.scss";

//@class component
class CustomCalener extends Component {
    render() {
        let { name, label, value, onChange, required, dateFormat, showIcon=true, config, className = "" } = this.props
// {console.log(showSeconds,"config")}
        return (<div className="custom-calender-cls">
            {(label) ? <p>
                {required ? <span className="required-field">* </span> : <span className="non-required-field"></span>}{label}
            </p> : ""}
            <div className={`custom-calender ${className}`}>
                <div className="calender-picker"><Calendar dateFormat={dateFormat || "dd/mm/yy"} showOtherMonths={true} monthNavigator={true} yearNavigator={true} yearRange="1940:2050" showOnFocus={true} name={name} value={value} onChange={(e) => onChange(e)} showIcon={showIcon} {...config}  /></div>
                <div><img src={calIcon} alt="calIcon" /></div>
            </div>
        </div>);
    }
}

export default CustomCalener;