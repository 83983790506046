import React, { Component } from 'react';
import "./style.scss";
import { Scrollbars } from 'react-custom-scrollbars';

class NavList extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return ( <div className="naveList">
            <Scrollbars className={"naveList-scrollbar"} autoHide={false} style={{ width: "100%", height: "85vh" }}>
                {this.props.listComps}
            </Scrollbars>
        </div> );
    }
}
 
export default NavList;