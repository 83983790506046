import React from 'react';
import '../style/Details.css';
import '../style/style.css';
import uploadIcon from '../Images/uploadIcon.jpg'
import HeaderWithoutSearch from './HeaderWithoutSearch';
import pdfIcon from '../Images/pdf 1.svg';
import editIcon from '../Images/edit.svg';
import downloadIcon from '../Images/download.svg';
import eyeIcon from '../Images/eye.svg';
import { useLocation } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import fileDownload from 'js-file-download';
import successicon from '../../../assets/images/successicon1.png'
import axios from 'axios';
import {
  GET_EMANDI_CASH_USER_DETAILS, GET_ECASH_REQUESTS,
  GET_ECASH_REQUESTS_APPROVED,
  UPLOAD_FILE, SAVE_ADD_DOCS,
  Download_Order_List, IS_ELIGIBLE
} from "../../../utils/Api";
import { fireAjaxRequest } from "../../../utils/Utility"
import { useState } from 'react';
import vectorHalfRectangle from '../../../assets/icons/vector_half_rectangle.png';
import vectorArrow from '../../../assets/icons/vector_arrow.png';
import vectorDocuments from '../../../assets/icons/vector_documents.png';
import vectorRight from '../../../assets/icons/vector_right.png';
import checkRight from '../../../assets/icons/check_right_icon.PNG';
import vectorRequest from '../../../assets/icons/vector_request.png';
import closeIcon from '../../../assets/icons/close_icon.png'
import { history } from '../../../utils/Utility';
import { toast } from 'react-toastify';
import $ from 'jquery';

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

function Details() {
  const forceUpdate = useForceUpdate();
  var linkArray = [];
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [commentState, setCommentState] = React.useState();
  const [commentArrayState, setCommentArrayState] = React.useState([]);
  const [editDocType, setEditUpload] = useState('')
  const [editDocLink, setEditDocLink] = useState([])
  const [contactNo, setContactNo] = useState('');
  const [traderId, setTraderId] = useState('');
  const [responseId, setResponseId] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [links, setLinks] = useState('');
  const [downloadLinks, setDownloadLinks] = useState('');
  const [sendToLender, setSendToLender] = useState(false);
  const [isRecLtteruploaded, setRecLetterStatus] = useState('');
  const [eCashLimit, setECashLimit] = useState('');
  const [status, setStatus] = useState('');
  const [file, setFile] = useState(null);
  const [recFile, setRecFile] = useState(null);
  const [buyerDetails, setBuyerDetails] = useState(false);

  var temp = '';
  const handleRecLetterChange = (file) => {
    for (var i = 0; i < file.length; i++) {
      setFile(file[i]);
      onClickFile(file[i], "RECOMMENDATION_LETTER", "RECOMMENDATION_LETTER")
      document.getElementById("btnRecSave").style.display = "block";
      document.getElementById("letterText").innerHTML = "<h4> " + file.length + " Files Uploaded</h4>";
    }

  };

  const removeFile = (e) => {
    let arr = editDocLink;
    arr.splice(e, 1)
    setEditDocLink(arr);
    forceUpdate();
  }

  const handleChange = (file) => {
    for (var i = 0; i < file.length; i++) {
      setFile(file[i]);
      onClickFile(file[i], editDocType, editDocType)
    }

  };

  const onTrigger = (event) => {
    this.props.parentCallback(event.target.myname.value);
    event.preventDefault();
  };
  const [eCashRequest, setECashRequest] = React.useState();
  var commentArray = commentArrayState;
  const addMoreClick = (e) => {
    commentArray.push(commentState);
    setCommentArrayState(commentArray);
    forceUpdate()
  }

  const handleOnButtonClick = (e, l, phone, x, name) => {
    setEditUpload(e)
    //setEditDocLink(l)
    setResponseId(x)
    setPartnerName(name);
    getUsername(phone);
  }
  
  const handleSeller = (e) => {
    $("#sellerTab").addClass("activeTab");
    $("#sellerTab").siblings().removeClass("activeTab");
    setBuyerDetails(false);
    $("#lblBreadCrumbs").html("&nbsp; Seller Detail");

  }

  const handleBuyer = (e) => {
    // activeTab
    $("#buyerTab").addClass("activeTab");
    $("#buyerTab").siblings().removeClass("activeTab");
    setBuyerDetails(true);
    $("#lblBreadCrumbs").html("&nbsp; Buyer Detail");
  }

  const getECashRequest = () => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(GET_ECASH_REQUESTS + '/' + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('res----', result)
        setECashRequest(result);
        sessionStorage.setItem("requestID", result.responseId);
        sessionStorage.setItem("traderID", result.traderId);
        sessionStorage.setItem("orgType", result.typeOfOrganization);
        console.log("Result ", result)
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        fetch(IS_ELIGIBLE + "userId=" + result.traderId, requestOptions)
          .then(response => response.text())
          .then(result => {
            result = JSON.parse(result)
            let statuss = result.userHistory.map((u) => u.status)[0]
            let eCashLimitt = result.userHistory.map((u) => u.ecashLimit)
            // setStatus(statuss[0])
            // setECashLimit(eCashLimitt[0])
            // forceUpdate();

            let _2crRequests = result.userHistory.filter(x => x.ecashLimit == "1Crore 25Lakh");
            if (_2crRequests.length > 0) {
              document.getElementById('limit-enhance').style.display = 'none'
            }
            else {
              if (eCashLimitt[0] === '75Lakh') {
                if (statuss === 'System_Approved_With_Risk') {
                  document.getElementById('limit-enhance').style.display = 'block'
                }
                else if (statuss === 'Red_Flagged_With_Risk') {
                  document.getElementById('limit-enhance').style.display = 'block'
                }
                else if (statuss === 'Discrepant') {
                  document.getElementById('limit-enhance').style.display = 'block'
                }
                else if (statuss === 'Approved') {
                  document.getElementById('limit-enhance').style.display = 'block'
                }
                else {
                  document.getElementById('limit-enhance').style.display = 'none'
                }
              }
            }
          }
          )
          .catch(error => console.log('error', error));
      })
      .catch((error) => console.log('error', error));
  };
  const x = eCashRequest == null ? getECashRequest() : ""

  const ECashRequestApproved = (ecashId, EcashStatus) => {
    if (window.confirm('Do you want to approve this request?')) {
      var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', 'Bearer ' + token);

      var raw = JSON.stringify({
        comments: [
          {
            commentHeader: '',
            commentForStatus: '',
            commentBody: '',
          },
        ],
      });
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      fetch(
        GET_ECASH_REQUESTS_APPROVED +
        '/' +
        '?ecashId=' +
        id +
        '&EcashStatus=' +
        'APPROVED',
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          toast.success("Approved Successfully")

          getECashRequest()
        })
        .catch((error) => console.log('error', error));
    }
    else {
      document.getElementById("btnApprovedModalClose").click()
    }
  }

  const ECashRequestRejected = (ecashId, EcashStatus) => {
    var comments = '';
    var remarks = '';
    commentArrayState.map((item) => (
      remarks += item + ","
    ))
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var raw = JSON.stringify({
      comments: [
        {
          commentHeader: 'Rejection',
          commentForStatus: 'Rejected',
          commentBody: remarks,
        },
      ],
    });
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS_APPROVED +
      '/' +
      '?ecashId=' +
      id +
      '&EcashStatus=' +
      'REJECTED',
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {

        toast.success("Rejected Successfully")
        getECashRequest()
      })
      .catch((error) => console.log('error', error));
  }

  const ECashRequestDiscrepent = (ecashId, EcashStatus) => {
    var comments = '';
    var remarks = '';
    commentArrayState.map((item) => (
      remarks += item + ","
    ))

    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', 'Bearer ' + token);

    var raw = JSON.stringify({
      comments: [
        {
          commentHeader: '',
          commentForStatus: '',
          commentBody: remarks,
        },
      ],
    });
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(
      GET_ECASH_REQUESTS_APPROVED +
      '/' +
      '?ecashId=' +
      id +
      '&EcashStatus=' +
      'DISCREPANT',
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {

        toast.success("Discrepent Successfully")
        getECashRequest()
      })
      .catch((error) => console.log('error', error));
  }

  const ECashRequestRecommendedLender = (ecashId, EcashStatus) => {

    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    if (window.confirm('Do you want to Recommend to lender this request?')) {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', 'Bearer ' + token);

      var raw = JSON.stringify({
        comments: [
          {
            commentHeader: '',
            commentForStatus: '',
            commentBody: '',
          },
        ],
      });
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      fetch(
        GET_ECASH_REQUESTS_APPROVED +
        '/' +
        '?ecashId=' +
        id +
        '&EcashStatus=' +
        'RECOMMENDED_TO_LENDER',
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          toast.success("Recommended to lender Successfully");
          //setSendToLender(true)
          getECashRequest()

        })
        .catch((error) => console.log('error', error));
    }
    else {
      document.getElementById("btnModalClose").click();
    }
  }

  const handleInputChange = (e) => {
    setCommentState(e.target.value);
  }

  // const downloadFile = (fileURL, filename) => {
  //   axios.get(fileURL, {
  //     responseType: 'blob',
  //   })
  //   .then((res) => {
  //     fileDownload(res.data, filename)
  //   })
  // }

  const downloadFile = (fileURL, filename) => {
    fetch(fileURL).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      })
    })
  }




  function getAge(date_1, date_2) {

    //convert to UTC
    var date2_UTC = new Date(Date.UTC(date_2.getUTCFullYear(), date_2.getUTCMonth(), date_2.getUTCDate()));
    var date1_UTC = new Date(Date.UTC(date_1.getUTCFullYear(), date_1.getUTCMonth(), date_1.getUTCDate()));


    var yAppendix, mAppendix, dAppendix;


    //--------------------------------------------------------------
    var days = date2_UTC.getDate() - date1_UTC.getDate();
    if (days < 0) {

      date2_UTC.setMonth(date2_UTC.getMonth() - 1);
      days += DaysInMonth(date2_UTC);
    }
    //--------------------------------------------------------------
    var months = date2_UTC.getMonth() - date1_UTC.getMonth();
    if (months < 0) {
      date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
      months += 12;
    }
    //--------------------------------------------------------------
    var years = date2_UTC.getFullYear() - date1_UTC.getFullYear();




    if (years > 1) yAppendix = " years";
    else yAppendix = " year";
    if (months > 1) mAppendix = " months";
    else mAppendix = " month";
    if (days > 1) dAppendix = " days";
    else dAppendix = " day";

    return years + yAppendix + ", " + months + mAppendix + ", and " + days + dAppendix;
  }

  function DaysInMonth(date2_UTC) {
    var monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
    var monthEnd = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth() + 1, 1);
    var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
    return monthLength;
  }


  const vintageDate = getAge(new Date(eCashRequest?.registrationDate), new Date())

  const changeText = (e, prefix, docType) => {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      onClickFile(files[0], prefix, docType)
    };
    input.click();
  }
  const getUsername = (phoneNo) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      "phoneNo": phoneNo
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(GET_EMANDI_CASH_USER_DETAILS, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        setTraderId(result.data.id);
        setContactNo(result.data.contactNo);
        forceUpdate();
      })
      .catch(error => console.log('error', error));

  }

  const onClickFile = (e, prefix, docType) => {
    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    if (e.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.type == "video/mp4") {
      toast.error("Please Upload the correct file format")
    } else if (e.size > 10485760) {
      toast.error("Uploaded file is more than 10MB")
    }
    else {
      let formData = new FormData();

      formData.append("file", e);
      formData.append("prefix", prefix);
      (async () => {
        try {
          const resp = await fireAjaxRequest(`${UPLOAD_FILE}`, {
            method: 'POST',
            headers: {
              "Authorization": `Bearer ${token}`
            },
            body: formData
          });
          console.log(resp)
          if (resp) {
            let arr = editDocLink;
            let obj = []
            obj.push(resp.filePath);
            obj.push(docType);
            arr.push(obj);
            setEditDocLink(arr)
            forceUpdate();
            console.log("Docslink", editDocLink)
          }
        }
        catch (err) {
          toast.error(err.message);
        }
      })();
    }
  }

  const SaveRecDocs = (e, ph) => {


    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    var raw = JSON.stringify({
      "phoneNo": ph
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(GET_EMANDI_CASH_USER_DETAILS, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        setTraderId(result.data.id);
        setContactNo(result.data.contactNo);
        forceUpdate();
        var myHeaders1 = new Headers();
        myHeaders1.append("Content-Type", "application/json");
        myHeaders1.append("Authorization", "Bearer " + token);
        var links = []
        editDocLink.map((item) => {
          links.push(item[0])
        })
        var dLinks = JSON.stringify(links);
        dLinks = JSON.parse(dLinks);

        var raw = JSON.stringify({
          "traderId": result.data.id,
          "ecashRequestId": e,
          "documents": [
            {
              "docType": "RECOMMENDATION_LETTER",
              "docName": "RECOMMENDATION_LETTER",
              "docLink": dLinks
            }
          ]
        });

        var requestOptions = {
          method: 'PUT',
          headers: myHeaders1,
          body: raw,
          redirect: 'follow'
        };

        fetch(SAVE_ADD_DOCS, requestOptions)
          .then(response => response.text())
          .then(result => {
            result = JSON.parse(result)
            if (result.statusCode == "200") {
              document.getElementById("recommendationLetter").style.display = "none";
              toast.success("Successfully updated")
              getECashRequest()

            }
            else {
              toast.error("document not uploaded yet")
            }
          }
          )
          .catch(error => console.log('error', error));
      })
      .catch(error => console.log('error', error));


  }

  const editSaveDocs = (e) => {

    var token = JSON.parse(sessionStorage.getItem('userInfo')).token;
    var myHeaders1 = new Headers();
    myHeaders1.append("Content-Type", "application/json");
    myHeaders1.append("Authorization", "Bearer " + token);
    var links = []
    editDocLink.map((item) => {
      links.push(item[0])
    })
    var dLinks = JSON.stringify(links);
    dLinks = JSON.parse(dLinks);

    var raw = JSON.stringify({
      "traderId": traderId,
      "ecashRequestId": responseId,
      "documents": [
        {
          "docType": editDocType,
          "docName": editDocType,
          "docLink": dLinks
        }
      ]
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders1,
      body: raw,
      redirect: 'follow'
    };

    fetch(SAVE_ADD_DOCS, requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        if (result.statusCode == "200") {
          toast.success("Successfully updated")
          getECashRequest()
        }
        else {
          toast.error("document not uploaded yet")
        }
      }
      )
      .catch(error => console.log('error', error));
  }
  const handleLimitEnhance = (e) => {
    // let elem= document.getElementById("main");
    // elem.style.height="4000px"
    // elem.scrollTo(0,0)
    history.push("../TwoCrore");
  }

  return (
    <div className='eCash-home'>
      <div className="container-fluid details-home">
        <div class="modal" id="myEdit">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body editModal">
                <button type="button" class="btn-close modal-close" style={{ outline: "none", border: "none", marginTop: "5px" }} data-bs-dismiss="modal"></button>
                <h3 style={{ marginBottom: '30px' }}> UPLOAD FILES </h3>
                <div class="mid-edit-section">
                  <FileUploader multiple="true" handleChange={handleChange} name="file" types={fileTypes} />
                  <br />
                  <img class="documents-img" src={vectorDocuments} />
                  <h3 class="drag-drop"> Drag & Drop </h3>
                  <span class="your-files"> Your files here Or Browse to upload </span>
                  <span class="only-jpeg"> Only JPEG, PNG, GIF and PDF files with max size of 15MB </span>
                </div>
                <div style={{ textAlign: "left", marginTop: "20px" }}>
                  <h5 style={{ textAlign: "center" }}>{editDocLink.length} files attached</h5>
                  <br />
                </div>
                {
                  editDocLink.length > 0 ? <button id="btnUpdate btnSave" onClick={editSaveDocs} style={{ width: "140px" }} class="btn btn-sm btn-success btnSave">
                    Save File
                  </button> :
                    <button id="btnUpdate btnSave" disabled style={{ width: "140px" }} class="btn btn-sm btn-success btnSave disabled">
                      Save File
                    </button>
                }

              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="myDownload1">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">

                <iframe id="my_iframe" style={{ height: "600px", width: "100%" }} download="abc" src={downloadLinks + "#toolbar=1"}></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* <HeaderWithoutSearch /> */}
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <a href="#/eCash" style={{ textDecoration: 'none', color: '#757575', paddingLeft: '10px' }}>
              eCash &nbsp;{' '}
            </a>
            <img style={{ height: '15px', paddingTop: '5px' }} src={vectorRight}></img>
            <span id="lblBreadCrumbs" style={{ color: '#0db442' }}> &nbsp; Seller Detail</span>
          </ol>
        </nav>
        <diV className="data-outer-container" id="main">
          <div className="data-container">
            <div className="tabsDiv">
              <div className="tabsWrapper">
                <div className="tab activeTab" id="sellerTab" onClick={(e) => handleSeller(e)}>
                  <p>Seller Details</p>
                </div>
                <div className="tab" id="buyerTab" onClick={(e) => handleBuyer(e)}>
                  <p>Buyer Details</p>
                </div>
                <div className="tab">
                  <p>Get Cash for Suppliers</p>
                </div>
                <div className="tab">
                  <button id="limit-enhance" onClick={(e) => handleLimitEnhance(e)} className="sent-to-lender-btn enhance-limit" style={{ marginRight: "10px", marginBottom: "28px", fontSize: "10pt", display: "none" }}>Enhance Limit to 2 Crore</button>
                </div>
              </div>
            </div>
            {
              buyerDetails === false ?
                <div class="sellerDetails">

                  <div className="details-outer-div">
                    <div className="details-inner-div">


                      <div className="left-properties">

                        <table id="detailtable" style={{ width: "100%" }}>
                          <tr>
                            <th><p>Name of the borrower</p></th>
                            <td>{eCashRequest?.name ? (
                              <p>{eCashRequest?.name}</p>
                            ) : (
                              <p>
                                {'NA'}
                                <br></br>
                              </p>
                            )}</td>
                          </tr>
                          <tr>
                            <th>   <p>Vintage of Entity</p></th>
                            <td>   <p>
                              {eCashRequest?.vintage ? eCashRequest?.vintage : 'NA'}
                            </p></td>
                          </tr>
                          <tr>
                            <th> <p>Business Communication Address</p></th>
                            <td >
                              {eCashRequest?.buisnessAddress ? (
                                <p>{eCashRequest?.buisnessAddress.replaceAll("/", "/ ")}</p>
                              ) : (
                                <p>
                                  {'NA'}
                                  <br></br>
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th> <p>Contact No.</p></th>
                            <td>
                              {eCashRequest?.contactNo ? (
                                <p>{eCashRequest?.contactNo}</p>
                              ) : (
                                <p>
                                  {'NA'}
                                  <br></br>
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th><p>Organisation Type</p></th>
                            <td>

                              {eCashRequest?.typeOfOrganization ? (
                                <p>{eCashRequest?.typeOfOrganization}</p>
                              ) : (
                                <p>
                                  {'NA'}
                                  <br></br>
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>    <p>MSME</p></th>
                            <td>

                              {eCashRequest?.isMsmeRegistered == 'TRUE' ? (
                                <p>YES</p>
                              ) : eCashRequest?.isMsmeRegistered == 'FALSE' ? (
                                <p>NO</p>) : (
                                <p>
                                  {'NA'}
                                  <br></br>
                                </p>
                              )}
                            </td>

                          </tr>
                          <tr>
                            <th>
                              <p>Remarks</p>
                            </th>
                            <td >
                              {
                                eCashRequest?.ecashComments?.map((comment) => (
                                  <p class="remarks">{comment?.commentHeader} <br /> {comment?.commentBody}</p>
                                ))
                              }
                            </td>
                          </tr>
                        </table>

                      </div>

                    </div>
                    <div className="details-inner-div">
                      <div className="left-properties">
                        <p>PAN</p>
                        <p>Date of Incorporation</p>
                        <p>Lender Assigned</p>
                        <p>Limit requested</p>
                        <p>GST registration No.</p>
                        <p>Status</p><br />

                      </div>
                      <div className="rightValues">
                        {eCashRequest?.PAN ? (
                          <p>{eCashRequest?.PAN}</p>
                        ) : (
                          <p>
                            {'NA'}
                            <br></br>
                          </p>
                        )}
                        {eCashRequest?.registrationDate ? (
                          <p>{eCashRequest?.registrationDate}</p>
                        ) : (
                          <p>
                            {'NA'}
                            <br></br>
                          </p>
                        )}
                        {eCashRequest?.assignedLenderName ? (
                          <p>{eCashRequest?.assignedLenderName}</p>
                        ) : (
                          <p>
                            {'NA'}
                            <br></br>
                          </p>
                        )}
                        {eCashRequest?.limitApplied ? (
                          <p>{eCashRequest?.limitApplied}</p>
                        ) : (
                          <p>
                            {'NA'}
                            <br></br>
                          </p>
                        )}
                        {eCashRequest?.GSTIN ? (
                          <p>{eCashRequest?.GSTIN}</p>
                        ) : (
                          <p>
                            {'NA'}
                            <br></br>
                          </p>
                        )}
                        {eCashRequest?.statusOfRequest === 'Recommended_To_Lender' ? (
                          <td>
                            <button className={'statusBtn'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : eCashRequest?.statusOfRequest ===
                          'System_Approved_With_Risk' ? (
                          <td>
                            <button className={'statusBtn'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : eCashRequest?.statusOfRequest ===
                          'Under_Process_With_Risk' ? (
                          <td>
                            <button className={'statusBtnunderProcess'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : eCashRequest?.statusOfRequest ===
                          'Red_Flagged_With_Risk' ? (
                          <td>
                            <button className={'statusBtnunderProcess'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : eCashRequest?.statusOfRequest === 'Incomplete_Proposal' ? (
                          <td>
                            <button className={'statusIncomplete'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : eCashRequest?.statusOfRequest.toLowerCase() === 'rejected' ? (
                          <td>
                            <button className={'statusIncomplete'} >
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                            <br />

                          </td>
                        ) : eCashRequest?.statusOfRequest.toLowerCase() === 'discrepant' ? (
                          <td>
                            <button className={'statusIncomplete'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : eCashRequest?.statusOfRequest.toLowerCase() === 'approved' ? (
                          <td>
                            <button className={'statusBtn'}>
                              {eCashRequest?.statusOfRequest.split('_').join(' ')}
                            </button>
                          </td>
                        ) : ""}


                      </div>
                    </div>
                  </div>

                  <div className="table-container">



                    <table className="ecash-document-table table" style={{ fontSize: "11pt" }}>
                      <thead>
                        <tr className="">
                          <th className="Column1">Document Type</th>
                          <th className="Column2">Documents</th>
                          <th className="Column3">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {
                            ((eCashRequest?.ecashCompanyDocList == null || eCashRequest?.ecashCompanyDocList.length == 0) &&
                              (eCashRequest?.ecashProprietorshipDocList == null || eCashRequest?.ecashProprietorshipDocList.length == 0) &&
                              (eCashRequest?.ecashPartnershipBuisnessDocList?.length == 0 || eCashRequest?.ecashPartnershipBuisnessDocList == null) &&
                              (eCashRequest?.ecashLLPDocList == null || eCashRequest?.ecashLLPDocList.length == 0)
                              && (eCashRequest?.ecashPartners?.length == 0 || eCashRequest?.ecashPartners == null)
                            )
                              ?
                              <>
                                <tr>
                                  <th colSpan="3"> No Documents Found </th>
                                </tr>
                              </>
                              :
                              <>
                                {eCashRequest?.ecashCompanyDocList?.map((item, key) => (
                                  <>

                                    <tr className="document-table-row">
                                      <th title={item?.docType}>
                                        {item?.docType != null ? item?.docType.replaceAll('_', ' ').substring(0, 20) : "Document"}...
                                      </th>                              <td className="document-name">
                                        <img className="pdfIcon" src={pdfIcon}></img>{' '}
                                        {item?.docType}
                                      </td>
                                      <td>
                                        <div className="documents-actions-div">

                                          <div className="edit-download-div">
                                            {

                                              item?.docLink.includes(",")
                                                ?
                                                <>
                                                  {
                                                    item.docLink.split(",").map((p) => (
                                                      <>
                                                        <img src={editIcon} onClick={(t, x, phone, resId, name) => handleOnButtonClick(item.docType, p, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                          data-bs-toggle="modal" data-bs-target="#myEdit" />
                                                        <img src={downloadIcon}
                                                          data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(p, item?.docType)}
                                                        />
                                                        <a href={p} target="blank">
                                                          <img src={eyeIcon}></img>
                                                        </a>
                                                        <span> | </span>
                                                      </>
                                                    ))
                                                  }
                                                  {

                                                  }
                                                </>
                                                : <><img src={editIcon} onClick={(t, link, phone, resId, name) => handleOnButtonClick(item.docType, item.docLink, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                  data-bs-toggle="modal" data-bs-target="#myEdit" /><img src={downloadIcon}
                                                    data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(item?.docLink, item?.docType)}
                                                  />
                                                  <a href={item?.docLink} target="blank">
                                                    <img src={eyeIcon}></img>
                                                  </a>
                                                </>
                                            }
                                          </div>

                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                {eCashRequest?.ecashProprietorshipDocList?.map((item, key) => (
                                  <>
                                    <tr className="document-table-row">
                                      <th title={item?.docType}> {item?.docType != null ? item?.docType.replaceAll('_', ' ').substring(0, 20) : "Document"}... </th>                            <td className="document-name">
                                        <img className="pdfIcon" src={pdfIcon}></img>{' '}
                                        {item?.docType}
                                      </td>
                                      <td>
                                        <div className="documents-actions-div">
                                          <div className="edit-download-div">
                                            {
                                              item?.docLink.includes(",")
                                                ?
                                                <>
                                                  {
                                                    item.docLink.split(",").map((p) => (
                                                      <>
                                                        <img src={editIcon} onClick={(t, x, phone, resId, name) => handleOnButtonClick(item.docType, p, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                          data-bs-toggle="modal" data-bs-target="#myEdit" />
                                                        <img src={downloadIcon}
                                                          data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(p, item?.docType)}
                                                        />
                                                        <a href={p} target="blank">
                                                          <img src={eyeIcon}></img>
                                                        </a>
                                                        <span> | </span>
                                                      </>


                                                    ))

                                                  }
                                                  {

                                                  }
                                                </>
                                                : <><img src={editIcon} onClick={(t, link, phone, resId, name) => handleOnButtonClick(item.docType, item.docLink, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                  data-bs-toggle="modal" data-bs-target="#myEdit" /><img src={downloadIcon}
                                                    data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(item?.docLink, item?.docType)}
                                                  />
                                                  <a href={item?.docLink} target="blank">
                                                    <img src={eyeIcon}></img>
                                                  </a>
                                                </>
                                            }
                                          </div>

                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                {eCashRequest?.ecashPartnershipBuisnessDocList?.map((item, key) => (
                                  <>
                                    <tr className="document-table-row">
                                      <th title={item?.docType}> {item?.docType != null ? item?.docType.replaceAll('_', ' ').substring(0, 20) : "Document"}... </th>                            <td className="document-name">
                                        <img className="pdfIcon" src={pdfIcon}></img>{' '}
                                        {item?.docType}
                                      </td>
                                      <td>
                                        <div className="documents-actions-div">
                                          <div className="edit-download-div">
                                            {

                                              item?.docLink.includes(",")
                                                ?
                                                <>
                                                  {
                                                    item.docLink.split(",").map((p) => (
                                                      <>
                                                        <img src={editIcon} onClick={(t, x, phone, resId, name) => handleOnButtonClick(item.docType, p, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                          data-bs-toggle="modal" data-bs-target="#myEdit" />
                                                        <img src={downloadIcon}
                                                          data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(p, item?.docType)}
                                                        />
                                                        <a href={p} target="blank">
                                                          <img src={eyeIcon}></img>
                                                        </a>
                                                        <span> | </span>
                                                      </>


                                                    ))

                                                  }
                                                  {

                                                  }
                                                </>
                                                : <><img src={editIcon} onClick={(t, link, phone, resId, name) => handleOnButtonClick(item.docType, item.docLink, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                  data-bs-toggle="modal" data-bs-target="#myEdit" /><img src={downloadIcon}
                                                    data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(item?.docLink, item?.docType)}
                                                  />
                                                  <a href={item?.docLink} target="blank">
                                                    <img src={eyeIcon}></img>
                                                  </a>
                                                </>
                                            }
                                          </div>

                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                {eCashRequest?.ecashLLPDocList?.map((item, key) => (
                                  <>
                                    <tr className="document-table-row">
                                      <th title={item?.docType}> {item?.docType != null ? item?.docType.replaceAll('_', ' ').substring(0, 20) : "Document"}... </th>                              <td className="document-name">
                                        <img className="pdfIcon" src={pdfIcon}></img>{' '}
                                        {item?.docType}
                                      </td>
                                      <td>
                                        <div className="documents-actions-div">
                                          <div className="edit-download-div">
                                            {

                                              item?.docLink.includes(",")
                                                ?
                                                <>
                                                  {
                                                    item.docLink.split(",").map((p) => (
                                                      <>

                                                        <img src={editIcon} onClick={(t, x, phone, resId, name) => handleOnButtonClick(item.docType, p, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                          data-bs-toggle="modal" data-bs-target="#myEdit" />
                                                        <img src={downloadIcon}
                                                          data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(p, item?.docType)}
                                                        />
                                                        <a style={{ marginLeft: "10px" }} href={p} target="blank">
                                                          <img src={eyeIcon}></img>
                                                        </a>
                                                        <span> | </span>
                                                      </>
                                                    ))
                                                  }
                                                  {

                                                  }
                                                </>
                                                : <><img src={editIcon} onClick={(t, link, phone, resId, name) => handleOnButtonClick(item.docType, item.docLink, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                  data-bs-toggle="modal" data-bs-target="#myEdit" /><img src={downloadIcon}
                                                    data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(e, x) => downloadFile(item?.docLink, item?.docType)}
                                                  />
                                                  <a href={item?.docLink} target="blank">
                                                    <img src={eyeIcon}></img>
                                                  </a>
                                                </>
                                            }
                                          </div>

                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                {eCashRequest?.ecashPartners?.map((item, key) => (
                                  item.ecashPartnersDocs.map((e) =>
                                    <>
                                      <tr className="document-table-row">
                                        <th title={e?.docType}> {e?.docType != null ? e?.docType.substring(0, 20) : "Document"}... </th>
                                        <td className="document-name">
                                          <img className="pdfIcon" src={pdfIcon}></img>{' '}
                                          {e.docType}
                                        </td>
                                        <td>
                                          <div className="documents-actions-div">
                                            <div className="edit-download-div">
                                              {

                                                e?.docLink?.includes(",")
                                                  ?
                                                  <>
                                                    {
                                                      e.docLink.split(",").map((p) => (
                                                        <>
                                                          <img src={editIcon} onClick={(t, x, phone, resId, name) => handleOnButtonClick(e.docType, p, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                            data-bs-toggle="modal" data-bs-target="#myEdit" />
                                                          <img src={downloadIcon}
                                                            data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(y, x) => downloadFile(p, e?.docType)}
                                                          />
                                                          <a href={p} target="blank">
                                                            <img src={eyeIcon}></img>
                                                          </a>
                                                          <span> | </span>

                                                        </>


                                                      ))

                                                    }

                                                  </>
                                                  : <><img src={editIcon} onClick={(t, link, phone, resId, name) => handleOnButtonClick(e.docType, e.docLink, eCashRequest?.contactNo, eCashRequest?.responseId, eCashRequest?.name)}
                                                    data-bs-toggle="modal" data-bs-target="#myEdit" /><img src={downloadIcon}
                                                      data-bs-toggle="modal" data-bs-target="#myDownload" onClick={(y, x) => downloadFile(e?.docLink, e?.docType)}
                                                    />
                                                    <a href={e?.docLink} target="blank">
                                                      <img src={eyeIcon}></img>
                                                    </a>
                                                  </>
                                              }
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                ))}
                              </>
                          }
                        </>
                      </tbody>
                    </table>
                  </div>
                  <div class="modal" id="myModal">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title h4-css">Add Reason for Rejection</h4>
                          <button type="button" class="btn-close" style={{ outline: "none", border: "none" }} data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body">
                          {
                            commentArrayState.map((item, key) => (
                              <b className='reasonText'>{key + 1}: {item}<br /></b>
                            )
                            )
                          }
                          {
                            commentArrayState.length != 0 ? <br /> : ""

                          }
                          <textarea id="txtRemarks" name="txtRemarks" placeholder='Add Comments' onChange={(e) => handleInputChange(e)} ></textarea>
                        </div>
                        {
                          commentState != null ? <div class="modal-footer">
                            <button type="button" class="btn btn-success submit-button" data-bs-dismiss="modal"
                              onClick={ECashRequestRejected}>Submit</button>
                            <div className='clearfix'></div>
                            <span className='btnAddMore' onClick={(e) => addMoreClick(e)} ><b style={{ marginRight: "5px", fontSize: "14pt", marginTop: "5px" }}>+</b> Add More</span>
                          </div> : <><button type="button" class="btn btn-secondary" style={{ width: "95%", marginLeft: "15px" }} disabled>Submit</button><br /></>
                        }

                      </div>
                    </div>
                  </div>
                  <div class="modal" id="myDiscrepent">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title h4-css">Add Reason for Discrepent</h4>
                          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body">
                          {
                            commentArrayState.map((item, key) => (
                              <b className='reasonText'>{key + 1}.{item}<br /></b>
                            )
                            )
                          }
                          <textarea id="txtRemarks" name="txtRemarks" placeholder='Add Comments' onChange={(e) => handleInputChange(e)} ></textarea>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-success submit-button" data-bs-dismiss="modal" onClick={ECashRequestDiscrepent}>Submit</button>
                          <div className='clearfix'></div>
                          <span className='btnAddMore' onClick={(e) => addMoreClick(e)} ><b>+</b> Add More</span>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="supply-limit-container limit-css">
                    <div className="supply-limit-wrapper">
                      <div className="supply-limit-div limit-css">
                        <p className="" style={{ color: "#2F2F2F" }}>Supply Limit</p>
                        <select name="limit" id="limit" className="limit-css" disabled>
                          <option className="select-limit" value="Select Limit">Select Limit</option>
                          <option className="select-limit" value="75 Lac">75 Lac</option>
                          <option className="select-limit" value="2 Cr">2 Cr</option>
                        </select>
                      </div>
                      <div className="available-limit-div limit-css">
                        <p className="limit-css">Available Supply Limit</p>
                        <div className="update-div limit-css">
                          <input className="update-left limit-css" style={{ outline: "none" }} disabled placeholder={"0.00"} />

                          <div className="update-right limit-css">
                            <p>Update</p>
                          </div>
                        </div>
                      </div>
                      <div className="available-limit-div limit-css">
                        {/* <button id="limit-enhance" onClick={(e) => handleLimitEnhance(e)} className="sent-to-lender-btn enhance-limit" style={{ marginRight: "10px", fontSize:"10pt",display: "none" }}>Enhance Limit to 2 Crore</button>        */}
                      </div>
                    </div>
                  </div>
                  <div class="modal" id="myLender">
                    <div class="modal-dialog">
                      <div class="modal-content">

                        <div class="modal-body">
                          <img src={checkRight} className='checkRight' /><br />
                          <h5 className="msg">Seller Details Sent to Lender!</h5>
                          <hr style={{ width: "70%", color: "#ECECEC", marginLeft: "15%", marginTop: "30px", marginBottom: "30px" }} />
                          <p className='reqPara'>
                            <img src={vectorRequest} /> Request ID <b>#{id}</b>
                          </p>
                        </div>
                        <div class="modal-footer" style={{ borderTop: "none" }}>
                          <button type="button" class="btn btn-success submit-button" id='btnModalClose' data-bs-dismiss="modal" >Ok,Great</button>
                          <div className='clearfix'></div>


                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal" id="myApproved">
                    <div class="modal-dialog">
                      <div class="modal-content">

                        <div class="modal-body">
                          <img src={checkRight} className='checkRight' /><br />
                          <h5 className="msg">Seller Details Approved!</h5>
                          <hr style={{ width: "70%", color: "#ECECEC", marginLeft: "15%", marginTop: "30px", marginBottom: "30px" }} />
                          <p className='reqPara'>
                            <img src={vectorRequest} /> Request ID <b>#{id}</b>
                          </p>
                        </div>
                        <div class="modal-footer" style={{ borderTop: "none" }}>
                          <button type="button" class="btn btn-success submit-button" id="btnApprovedModalClose" data-bs-dismiss="modal" >Ok,Great</button>
                          <div className='clearfix'></div>


                        </div>
                      </div>
                    </div>

                  </div>

                  {
                    eCashRequest?.statusOfRequest.toLowerCase() === 'rejected' || eCashRequest?.statusOfRequest.toLowerCase() === 'approved' ?
                      "" : <div className='recommendationLetter' id="recommendationLetter">
                        <FileUploader multiple="true" handleChange={handleRecLetterChange} name="file" types={fileTypes} />
                        <img src={uploadIcon} className="uploadIcon" />
                        <button id="btnRecSave" onClick={(e, phone) => SaveRecDocs(eCashRequest?.responseId, eCashRequest?.contactNo)} style={{ display: "none", width: "100px", height: "40px", position: "absolute", fontSize: "11pt", marginTop: "50px", marginLeft: "350px" }} class="btn btn-sm btn-success">
                          Save File
                        </button>

                        <div className="recLetterText" id='letterText'>

                          <h3>Recommendation Letter</h3>
                          <h4 style={{ marginTop: "10px" }}>Drag and Drop or <span style={{ color: "#04B23D" }}>Upload</span></h4>
                          <h4 style={{ marginTop: "10px", color: "#757575" }}>Only JPEG, PNG, GIF and PDF files with max size of 15MB</h4>

                        </div>
                        {
                          editDocLink.map((item, key) => (
                            <><a style={{ color: "#000" }} href={item[0]} target='_blank'><i>
                              <img style={{ width: '5%' }} src={pdfIcon} />
                              {item[1]}
                            </i></a>
                            </>
                          ))
                        }
                      </div>
                  }



                  {
                    eCashRequest?.statusOfRequest.toLowerCase() == "approved" ?
                      <div className="footer-btns-container">
                        <button className="discrepent-btn">Discrepent</button>
                        <div className="">
                          <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled id="button-id">
                            Approve
                          </button>
                          <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled>Send to Lender</button>
                          <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled >Reject</button>
                        </div>
                      </div>
                      : eCashRequest?.statusOfRequest.toLowerCase() == "rejected" ?
                        <div className="footer-btns-container">
                          <button className="discrepent-btn">Discrepent</button>
                          <div className="">
                            <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled id="button-id">
                              Approve
                            </button>
                            <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled>Send to Lender</button>
                            <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled >Reject</button>
                          </div>
                        </div>
                        : eCashRequest?.statusOfRequest.toLowerCase() == "recommended_to_lender" ?
                          <div className="footer-btns-container">
                            <button className="discrepent-btn" data-bs-toggle="modal" data-bs-target="#myDiscrepent" >Discrepent</button>
                            <div className="">
                              <button className="sent-to-lender-btn" style={{ marginRight: "10px" }} onClick={ECashRequestApproved} data-bs-toggle="modal" data-bs-target="#myApproved" id="button-id">
                                Approve
                              </button>
                              <button className="btn btn-secondary" style={{ marginRight: "10px" }} disabled>Send to Lender</button>
                              <button className="reject-btn" style={{ marginRight: "10px" }} data-bs-toggle="modal" data-bs-target="#myModal" >Reject</button>
                            </div>
                          </div>
                          : <div className="footer-btns-container">
                            <button className="discrepent-btn" data-bs-toggle="modal" data-bs-target="#myDiscrepent">Discrepent</button>
                            <div className="sent-reject-div">
                              <button className="sent-to-lender-btn" onClick={ECashRequestApproved} data-bs-toggle="modal" data-bs-target="#myApproved" id="button-id">
                                Approve
                              </button>
                              <button className="sent-to-lender-btn" onClick={ECashRequestRecommendedLender} id="btnLender" data-bs-toggle="modal" data-bs-target="#myLender" >Send to Lender</button>
                              <button className="reject-btn" data-bs-toggle="modal" data-bs-target="#myModal">Reject</button>
                            </div>
                          </div>
                  }
                </div>
                :
                <div class="buyerDetails">
                  <div className="">
                    <div className="">


                      <div style={{ padding: "30px" }}>

                        <table className="table">
                          <thead>
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                              <td className='Column1' style={{ width: "50px" }}>S.No#</td>
                              <td className='Column1' style={{ width: "150px" }}>Name</td>
                              <td className='Column1' style={{ width: "150px" }}>Mobile</td>
                              <td className='Column1' style={{ width: "150px" }}>GST No.</td>
                              <td className='Column1' style={{ width: "150px" }}>PAN No.</td>
                              <td className='Column1' style={{ width: "150px" }}>Email</td>
                              <td className='Column1' style={{ width: "150px" }}>Status</td>
                              <td className='Column1' style={{ width: "150px" }}>Action</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Buyer 1</td>
                              <td>+91878458455</td>
                              <td>23DMKPS5762LYU</td>
                              <td>DMKPS5762L</td>
                              <td>buyer1@gmail.com</td>
                              <td>Pending</td>
                              <td >
                                <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#buyerModal" style={{ backgroundColor: "#0DB442", border: "none" }}>Approve</button> &nbsp;
                                <button className='btn btn-danger'>Reject</button>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Buyer 2</td>
                              <td>+91878458455</td>
                              <td>23DMKPS5762LYU</td>
                              <td>DMKPS5762L</td>
                              <td>buyer1@gmail.com</td>
                              <td>Pending</td>
                              <td >
                                <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#buyerModal" style={{ backgroundColor: "#0DB442", border: "none" }}>Approve</button> &nbsp;
                                <button className='btn btn-danger'>Reject</button>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Buyer 3</td>
                              <td>+91878458455</td>
                              <td>23DMKPS5762LYU</td>
                              <td>DMKPS5762L</td>
                              <td>buyer1@gmail.com</td>
                              <td>Pending</td>
                              <td >
                                <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#buyerModal" style={{ backgroundColor: "#0DB442", border: "none" }}>Approve</button> &nbsp;
                                <button className='btn btn-danger'>Reject</button>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Buyer 4</td>
                              <td>+91878458455</td>
                              <td>23DMKPS5762LYU</td>
                              <td>DMKPS5762L</td>
                              <td>buyer1@gmail.com</td>
                              <td>Pending</td>
                              <td >
                                <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#buyerModal" style={{ backgroundColor: "#0DB442", border: "none" }}>Approve</button> &nbsp;
                                <button className='btn btn-danger'>Reject</button>
                              </td>
                            </tr>

                          </tbody>
                        </table>

                      </div>

                    </div>
         
                  </div>

                  {/* Approved Modal Start */}
                  <div class="modal" id="buyerModal">
                    <div class="modal-dialog">
                      <div class="modal-content">

                        <div class="modal-body">
                          <img src={successicon} className='successImage' /><br />
                          <h5 className="msg">Approved Successfully! <br/>Please add this buyer on Orgzit.</h5>
                          <hr style={{ width: "70%", color: "#ECECEC", marginLeft: "15%", marginTop: "30px", marginBottom: "30px" }} />
                        
                        </div>
                        <div class="modal-footer" style={{ borderTop: "none" }}>
                          <button type="button" class="btn btn-success submit-button" id="btnApprovedModalClose" data-bs-dismiss="modal" >Ok,Great</button>
                          <div className='clearfix'></div>


                        </div>
                      </div>
                    </div>

                  </div>
                    {/* Approved Modal End */}

                  

                </div>
            }

          </div>

        </diV >
      </div >
    </div>
  );
}

export default Details;