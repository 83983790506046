import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import "./style.scss";

class CustomAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        let { label, value, suggestions, completeMethod, onChange, field = "name", className, required, name, onSelect, multiple = false, onUnselect, config } = this.props;
        
        return (<div className={`autoComplete-content-section ${className || ""}`}>
            {/* <div className="autoComplete-label">
                {required ? <span className="required-field">* </span> : ""}
                <label >{label}</label>
            </div> */}
            <AutoComplete className={className} name={name} value={value} suggestions={suggestions} completeMethod={completeMethod} field={field} onChange={onChange} onSelect={onSelect} multiple={multiple} onUnselect={onUnselect} {...config} />
        </div>);
    }
}

export default CustomAutoComplete;